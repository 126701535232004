import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconInfo = (props: SharedIconProps) => {
  const fill = props.fill || colors.brand.cobalt
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none">
      <mask id="mask0_13_859" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.000366211H19.9999V19.9993H0V0.000366211Z" fill="white" />
      </mask>
      <g mask="url(#mask0_13_859)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0005 0.000366211C4.4861 0.000366211 0 4.48646 0 9.99985C0 15.5143 4.4861 19.9993 10.0005 19.9993C15.5139 19.9993 20 15.5143 20 9.99985C20 4.48646 15.5139 0.000366211 10.0005 0.000366211ZM10.0007 1.03592C14.9507 1.03592 18.9646 5.04876 18.9646 9.99983C18.9646 14.9509 14.9507 18.9637 10.0007 18.9637C5.04964 18.9637 1.03577 14.9509 1.03577 9.99983C1.03577 5.04876 5.04964 1.03592 10.0007 1.03592Z"
          fill={fill}
        />
      </g>
      <mask id="mask1_13_859" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 20H20V0H0V20Z" fill="white" />
      </mask>
      <g mask="url(#mask1_13_859)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.50439 6.43811H11.066V4.78223H9.50439V6.43811ZM7.20215 13.0897H9.65231V8.45861H7.4714V7.4075H10.9312V13.0897H13.3141V14.1398H7.20215V13.0897Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}
