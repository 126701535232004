import React from 'react'
import {normalizeLocationGroups, normalizeLocations} from '../../../config/utils'
import {useAppState} from '../../../state'
import {MainLayoutWithoutStretch, MainContent, NarrowRightSidebar} from '../../Layout/Layout'
import {HeaderRow, GoBackHeaderTitle} from '../../Molecules/ViewComponents/ViewHeader'
import {Baseline, Flex, Grid, InvisibleContainer} from '../../Layout/Grid'
import {BigLabelInput, Select} from '../../Atoms/Forms'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {Location} from '../../../state/state'
import {useNavigation} from 'react-navi'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {Text, P} from '../../Atoms/Typography'
import SiteOrganisationList from '../../Organisms/SiteOrganisationList'

const EditLocation = ({id}: {id?: string}) => {
  const {state, effects, actions} = useAppState()
  const isSuperUser = state.me?.accessRights.superuser === true
  const location = id ? normalizeLocations(state.chainsById[state.selectedChainId!]).find(lg => lg.id === id) : null
  const locationGroups = state.selectedChainId
    ? normalizeLocationGroups(state.chainsById[state.selectedChainId]).map(loc => ({option: loc.name, id: loc.id}))
    : []
  const {t} = useTranslation('locations')
  const {register, getValues, formState} = useForm<Location>({
    defaultValues: location ? location : undefined,
    mode: 'onChange'
  })
  const nav = useNavigation()

  const disabled = !formState.isValid

  const createLocation = async () => {
    try {
      const newLocation = {
        ...getValues()
      }
      await effects.organisationApi.createLocation(newLocation)
      await actions.updateMe()
      if (state.me?.accessRights.superuser) {
        await actions.getChains()
      }
      nav.navigate(`/settings/locations/`)
    } catch (e) {
      console.error('Error trying to create location', e)
    }
  }

  const updateLocation = async () => {
    if (location) {
      try {
        const newLocation = {...location, ...getValues()}
        await effects.organisationApi.updateLocation(newLocation)
        await actions.updateMe()
        if (state.me?.accessRights.superuser) {
          await actions.getChains()
        }
        nav.navigate(`/settings/locations/`)
      } catch (e) {
        console.error('Error trying to update location', e)
      }
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (location) {
      await updateLocation()
    } else {
      await createLocation()
    }
  }

  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={
              location
                ? `${t('locations:labels.editLocations', 'Edit location')}`
                : `${t('locations:labels.addLocation', 'Add location')}`
            }
            backLabel={t('locations:labels.locations', 'Location settings')}
          />
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <form onSubmit={handleSubmit}>
              <Grid gap="2rem">
                <BigLabelInput
                  required
                  ref={register({required: true})}
                  name="name"
                  labelText={t('locations:labels.locationName', 'Location name')}
                  placeholder={t('locations:placeholders.locationName', 'Location name')}
                />
                <Flex direction="column" childMargin="1rem 0">
                  <Select
                    required
                    returnId={true}
                    label={t('locations:labels.locationGroup', 'Location Group')}
                    options={locationGroups}
                    emptyStr={t('locations:labels.chooseLocationGroup', 'Choose location group')}
                    ref={register({required: true})}
                    nativeProps={{name: 'location group', defaultValue: '', disabled: !isSuperUser}}
                    id="locationGroupId"
                  />
                  <P variant="small">
                    {!isSuperUser &&
                      t(
                        'locations:messages.locationChoisePermanent',
                        'To change the organizational linkages please contact CGI customer support'
                      )}
                  </P>
                </Flex>
              </Grid>
              <ButtonRowWrap>
                <div className="empty"></div>
                <ButtonRowGrid>
                  <Button variant="secondary" negative onClick={() => nav.goBack()}>
                    {t('common:buttons.cancel', 'Cancel')}
                  </Button>
                  <Button variant="secondary" type="submit" disabled={disabled}>
                    {t('common:buttons.save', 'Save')}
                  </Button>
                </ButtonRowGrid>
              </ButtonRowWrap>
            </form>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <NarrowRightSidebar>
        <InvisibleContainer style={{marginTop: '4rem'}}>
          <Baseline margin="1rem">
            <Text level={3}>{t('locations:labels.organisationHierarchy', 'Organisation hierarchy')}</Text>
            <div>
              <SiteOrganisationList data={state.chainsById[state.selectedChainId!]} />
            </div>
          </Baseline>
        </InvisibleContainer>
      </NarrowRightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default EditLocation
