import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Baseline, Grid, Row, InvisibleContainer, GridSpan} from '../../Layout/Grid'
import {LabelInput, Select, LabelCheckbox} from '../../Atoms/Forms'
import {Button, ButtonRowGrid, ButtonRowWrap} from '../../Atoms/Buttons'
import {useUserAccountDetailsForm} from '../../../config/userAccountUtils'
import EditUserAccountSettingsUserGroups from './EditUserAccountSettingsUserGroups'
import {User, UserRole} from '../../../state/state'
import {H2, H6, P, Text2} from '../../Atoms/Typography'
import {IconButton} from '../../Atoms/Buttons'
import {IconCollapse, IconExpand} from '../../../Assets/Icons/TinyIcons'
import {LargeProfileImageFromAsset} from '../../Atoms/ProfileImageLoader'
import {GoBackHeaderTitle, HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import {useAppState} from '../../../state'
import {isEmpty} from 'lodash'
import {MainLayoutWithoutStretch, MainContent, NarrowRightSidebar, AdjustableContainer} from '../../Layout/Layout'
import {colors} from '../../../sharedComponents/colors'
import AnchorModal, {TinyModalContent} from '../../Molecules/Modals/AnchorModal'
import ConfirmModal from '../../Molecules/Modals/ConfirmModal'
import {valueExists} from '../../../config/utils'
import AssetUploaderSmall from '../../Molecules/UploadImage'
import {UserLanguage} from '../../../state/rest'
import {AnyOrgLevel, OffsetCheckboxList, availableKeyValues, parseLevel, role2level} from '../Utils'
import {IconPalette, SharedIcon, Text} from '../../../sharedComponents/components'
import RadioButtonGroup from '../../Atoms/RadioButtonGroup'
interface USSProps {
  data: AnyOrgLevel
  selected: string[]
  onClick: (id: string) => void
}
const UserSiteSelector = ({data, selected, onClick}: USSProps) => {
  const keys = Object.keys(data) as Array<keyof AnyOrgLevel>
  const arr = keys.find(k => availableKeyValues.includes(k)) as 'locationGroups' | 'locations' | 'brands' | 'sites'
  const nextLevel = data[arr]
  const [isOpen, setOpen] = useState(true)
  const isSelected = selected.includes(data.id)

  if (nextLevel) {
    if (data.visible !== false) {
      return (
        <>
          <IconButton
            icon={isOpen ? <IconCollapse /> : <IconExpand />}
            description={data.name}
            buttonProps={{onClick: () => setOpen(o => !o)}}
          />
          {isOpen && (
            <OffsetCheckboxList>
              {nextLevel.map(n => (
                <li key={n.id}>
                  <UserSiteSelector data={n} selected={selected} onClick={onClick} />
                </li>
              ))}
            </OffsetCheckboxList>
          )}
        </>
      )
    } else
      return (
        <>
          {nextLevel.map(n => (
            <UserSiteSelector data={n} selected={selected} onClick={onClick} />
          ))}
        </>
      )
  } else {
    return (
      <LabelCheckbox
        checked={isSelected}
        label={data.name}
        name={data.name}
        flipped={false}
        onClick={() => onClick(data.id)}
      />
    )
  }
}
interface LCProps {
  level: string
  onlySites: boolean
  onClick: (level: UserRole, org: AnyOrgLevel) => void
  data: AnyOrgLevel
  orgState: OrgState[]
  disableNextLevel: boolean
  writableIds: string[]
}
const ManagerLevelCheckbox = ({data, level, onlySites, onClick, orgState, disableNextLevel, writableIds}: LCProps) => {
  const keys = Object.keys(data) as Array<keyof AnyOrgLevel>
  const arr = keys.find(k => availableKeyValues.includes(k)) as 'locationGroups' | 'locations' | 'brands' | 'sites'
  const inState = !!orgState.find(os => os.id === data.id)
  const checked = inState || disableNextLevel
  const showCheckbox = writableIds.includes(data.id)
  if (data.visible !== false) {
    return (
      <>
        {showCheckbox ? (
          <LabelCheckbox
            disabled={disableNextLevel}
            checked={checked}
            flipped={false}
            label={data.name}
            name={arr}
            onClick={() => onClick(level as UserRole, data)}
          />
        ) : (
          <P>{data.name}</P>
        )}
        {!!arr && (
          <OffsetCheckboxList>
            {data[arr]!.map(item => (
              <li key={item.id}>
                <ManagerLevelCheckbox
                  writableIds={writableIds}
                  orgState={orgState}
                  data={item}
                  level={arr}
                  onlySites={onlySites}
                  onClick={onClick}
                  disableNextLevel={checked}
                />
              </li>
            ))}
          </OffsetCheckboxList>
        )}
      </>
    )
  } else {
    const items = data[arr]!
    return (
      <>
        {items.map(item => (
          <ManagerLevelCheckbox
            writableIds={writableIds}
            orgState={orgState}
            data={item}
            level={arr}
            onlySites={onlySites}
            onClick={onClick}
          />
        ))}
      </>
    )
  }
}

ManagerLevelCheckbox.defaultProps = {
  disableNextLevel: false
}

interface FormProps {
  savedData?: User
  isEditingSelf: boolean
}

export interface OrgState {
  level: string
  id: string
}
function resolveManagerRole(level: UserRole) {
  return level !== 'BU' && level !== 'HI'
}

function childIds(data: AnyOrgLevel, first: boolean = false): string[] {
  const keys = Object.keys(data) as Array<keyof AnyOrgLevel>
  const arr = keys.find(k => Array.isArray(data[k])) as 'locationGroups' | 'locations' | 'brands' | 'sites'
  const level = data[arr]

  if (level) {
    return first ? [...level.flatMap(l => childIds(l))] : [data.id, ...level.flatMap(l => childIds(l))]
  } else return [data.id]
}

const EditUserAccountSettingsDetails: FC<FormProps> = ({savedData, isEditingSelf}: FormProps): any => {
  const {t} = useTranslation(['assets', 'users', 'settings'])
  const {state} = useAppState()
  const backAddress = isEditingSelf ? '/settings' : '/settings/users'
  const backLabel = isEditingSelf
    ? t('settings:labels.settingsTitle', 'Settings')
    : t('settings:labels.userAccountSettings', 'User account settings')
  const fc = useUserAccountDetailsForm({saved: savedData, backAddress})

  const [selectedRole, setSelectedRole] = useState<string>(() => {
    if (savedData) {
      const userRoles = savedData.userToSite.map(uts => uts.userRole)
      if (userRoles.includes('BU') || userRoles.includes('HI')) {
        return userRoles[0]
      }
      return 'LM'
    }
    return 'BU'
  })

  const [selectedBuSites, setSelectedBUSites] = useState<string[]>(() => {
    if (savedData) {
      return savedData.userToSite.filter(uts => uts.userRole === 'BU' || uts.userRole === 'HI').map(uts => uts.siteId)
    }
    return []
  })

  // Handler to update selected BU sites
  const handleBUClick = (siteId: string) => {
    setSelectedBUSites(prevSelected => {
      if (prevSelected.includes(siteId)) {
        return prevSelected.filter(id => id !== siteId)
      } else {
        return [...prevSelected, siteId]
      }
    })
  }

  const passwordErrorMessage: string | undefined =
    fc.form.confirmNewPassword !== fc.form.newPassword
      ? t('common:validation.passwordsNotMatching', 'New passwords must match')
      : fc.form.newPassword && fc.form.newPassword.length < 8
      ? t('common:validation.passwordTooShort', 'New password is too short, must be at least 8 characters')
      : fc.form.newPassword && (!/[a-z]/.test(fc.form.newPassword) || !/[A-Z]/.test(fc.form.newPassword))
      ? t('common:validation.passwordFormatWrong', 'New password must contain both lowercase and uppercase letters')
      : undefined

  const originalEmail = (savedData && savedData.email) || ''
  const emailChanged = originalEmail !== fc.form.email
  const emailErrorMessage =
    emailChanged && fc.form.email !== fc.form.confirmEmail
      ? t('common:validation.emailMustMatch', 'Email addresses must match')
      : undefined

  const [isManager, setIsManager] = useState(savedData ? resolveManagerRole(savedData.userToSite[0].userRole) : false)
  const isEditing = !!savedData

  function handleOrgState(data?: User) {
    if (data) {
      return data.userToSite
        .filter(uts => uts.userRole !== 'BU' && uts.userRole !== 'HI')
        .map(uts => ({level: role2level(uts.userRole), id: uts[parseLevel(uts.userRole)]}))
    } else return []
  }
  const handleManagementLevelClick = (level: UserRole, org: AnyOrgLevel) => {
    const newState: OrgState[] = !!orgState.find(orgLevel => orgLevel.id === org.id)
      ? orgState.filter(orgLevel => orgLevel.id !== org.id)
      : [...orgState, {level, id: org.id}]
    const ids = childIds(org, true)
    const removeThese = orgState.filter(s => ids.includes(s.id))
    setOrgState(newState.filter(st => !removeThese.includes(st)))
  }

  const [orgState, setOrgState] = useState<OrgState[]>(handleOrgState(savedData))

  const handleRoleChange = (value: string) => {
    setSelectedRole(value)
    setIsManager(value === 'LM')
    if (value === 'HI' || value === 'BU') {
      setSelectedBUSites([])
    } else {
      setOrgState([])
    }
  }
  const rolesEmpty = isEmpty(orgState) && isEmpty(selectedBuSites)

  const {me} = useAppState().state
  const orgLevels = Object.keys(me!.accessRights.write) as Array<'brands' | 'locations' | 'chains' | 'groups' | 'sites'>
  const writableIds = orgLevels.flatMap(level => me!.accessRights.write[level])
  const chain = state.chainsById[state.selectedChainId as keyof typeof state.chainsById]
  const showResetPassword = (state.me?.accessRights.superuser || writableIds.length > 0) && isEditing && !isEditingSelf
  const showPhotoUpload = !state.me?.accessRights.superuser
  const [isOpen, setOpen] = useState(false)
  const onDelete = async () => {
    if (savedData) {
      fc.remove(savedData.id)
    }
  }
  const [modalOpen, setModalOpen] = useState(false)
  const orgIdLevels = ['siteId', 'locationId', 'locationGroupId', 'brandId', 'chainId'] as Array<
    'siteId' | 'locationId' | 'locationGroupId' | 'brandId' | 'chainId'
  >
  const usersLocationIds = orgIdLevels.flatMap(level =>
    savedData?.userToSite.map(uts => uts[level]).filter(l => l !== null)
  )

  const isEditingAllowed =
    isEditingSelf ||
    state.me?.accessRights.superuser ||
    !isEditing ||
    usersLocationIds?.filter(uli => !writableIds.includes(uli || '')).length === 0

  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
  return (
    <MainLayoutWithoutStretch>
      <ConfirmModal
        heading={t('users:labels.confirmDeleteAccount', 'Delete user account?')}
        subHeading={`${savedData?.firstName} ${savedData?.lastName}`}
        content={t(
          'users:messages.confirmDelete',
          "Are you sure you want to delete this user account? If you choose to proceed, the user account and it's content will be removed"
        )}
        confirm={t('users:labels.confirmCheckbox', 'I confirm that I want to delete user account')}
        actions={[
          {
            label: t('common:actions.cancel', 'Cancel'),
            action: () => setModalOpen(false)
          },
          {
            label: t('common:actions.delete', 'Delete'),
            action: () => onDelete()
          }
        ]}
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      />
      <MainContent variant="white">
        <Baseline>
          <HeaderRow
            modal={
              !isEditingSelf && (
                <AnchorModal anchorMode={'right'} isOpen={isOpen} onClose={() => setOpen(false)}>
                  <TinyModalContent data-cy="task-menu-modal">
                    <Baseline>
                      <IconButton
                        description={t('users:actions.deleteUser', 'Delete user')}
                        buttonProps={{
                          onClick: () => {
                            setOpen(false)
                            setModalOpen(true)
                          }
                        }}
                      />
                    </Baseline>
                  </TinyModalContent>
                </AnchorModal>
              )
            }
          >
            <GoBackHeaderTitle
              label={
                isEditing
                  ? isEditingSelf
                    ? `${t('users:actions.editYourAccount', 'My profile settings')}`
                    : `${t('users:actions.editAccount', 'Edit user account')}`
                  : `${t('users:actions.addAccount', 'Add user account')}`
              }
              path={backAddress}
              backLabel={backLabel}
            />
            {isEditing && !isEditingSelf && isEditingAllowed && <HeaderActions onDelete={() => setModalOpen(true)} />}
          </HeaderRow>

          <InvisibleContainer>
            <Grid>
              <Baseline>
                {showPhotoUpload ? (
                  <>
                    <Row childMargin="1rem">
                      {!fc.asset ? (
                        <SharedIcon icon={IconPalette.Profile} />
                      ) : (
                        <LargeProfileImageFromAsset asset={fc.asset} />
                      )}
                      <Text size="M">{t('common:general.profilePicture', 'Your profile picture')}</Text>
                    </Row>
                    <Row>
                      <AssetUploaderSmall onAssetSaved={fc.onAssetSaved} savedAsset={fc.asset} />
                    </Row>
                  </>
                ) : null}
                <Row childMargin="1rem">
                  <LabelInput
                    placeholder={t('common:general.firstName', 'First name')}
                    autoComplete="given-name"
                    name="firstName"
                    id="firstName"
                    labelText={t('common:general.firstName', 'First name')}
                    ref={fc.register({required: true})}
                    errorMsg={
                      !!fc.errors.firstName
                        ? t('common:validation.firstNameMissing', 'First name is mandatory')
                        : undefined
                    }
                  />
                  <LabelInput
                    placeholder={t('common:general.lastName', 'Last name')}
                    autoComplete="family-name"
                    name="lastName"
                    id="lastName"
                    labelText={t('common:general.lastName', 'Last name')}
                    ref={fc.register({required: true})}
                    errorMsg={
                      !!fc.errors.lastName
                        ? t('common:validation.lastNameMissing', 'Last name is mandatory')
                        : undefined
                    }
                  />
                </Row>

                <LabelInput
                  placeholder={t('common:general.email', 'Email')}
                  type="email"
                  autoComplete="username"
                  name="email"
                  id="email"
                  labelText={t('common:general.email', 'Email')}
                  ref={fc.register({
                    validate: {
                      isEmail: value => {
                        let valueToTest = value.replace(/ /g, '')
                        const regex = EMAIL_REGEX
                        if (!!!valueExists(valueToTest) || regex.test(valueToTest)) {
                          return true
                        } else {
                          return `${t('common:validation.invalidEmail', 'Invalid email')}`
                        }
                      }
                    },
                    required: t('common:validation.emailMandatory', 'Email is mandatory')
                  })}
                  errorMsg={!!fc.errors.email ? fc.errors.email!.message : undefined}
                />
                {emailChanged ? (
                  <div>
                    <LabelInput
                      placeholder={t('users:placeholders.repeatNewEmail', 'Repeat new email')}
                      type="email"
                      autoComplete="off"
                      name="confirmEmail"
                      id="confirmEmail"
                      labelText={t('users:labels.repeatNewEmail', 'Repeat new email')}
                      ref={fc.register({
                        validate: {
                          isEmail: value => {
                            let valueToTest = value.replace(/ /g, '')
                            const regex = EMAIL_REGEX
                            if (!!!valueExists(valueToTest) || regex.test(valueToTest)) {
                              return true
                            } else {
                              return `${t('common:validation.invalidEmail', 'Invalid email')}`
                            }
                          }
                        },
                        required: t('common:validation.emailMandatory', 'Email is mandatory')
                      })}
                      errorMsg={!!fc.errors.confirmEmail ? fc.errors.confirmEmail!.message : undefined}
                    />
                    <H6>
                      {t(
                        'users:messages.emailCorrect',
                        'Please make sure that the new email address is correct, since you will be using it later for login to this service.'
                      )}
                    </H6>
                  </div>
                ) : (
                  undefined
                )}

                <LabelInput
                  placeholder={t('common:general.phoneNumber', 'Phone number')}
                  autoComplete="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  labelText={t('common:general.phoneNumber', 'Phone number')}
                  ref={fc.register({
                    validate: {
                      isPhoneNumber: value => {
                        let valueToTest = value.replace(/ /g, '')
                        const regex = /^(\+){1}(\d){7,15}$/i
                        if (!!!valueExists(valueToTest) || regex.test(valueToTest)) {
                          return true
                        } else {
                          return `${t(
                            'common:validation.phoneFormat',
                            'Phone number must be in international format and start with + character'
                          )}`
                        }
                      }
                    }
                  })}
                  errorMsg={!!fc.errors.phoneNumber ? fc.errors.phoneNumber!.message : undefined}
                />
                <Select
                  options={[
                    {option: t('common:general.lang.english', 'English'), id: UserLanguage.en},
                    {option: t('common:general.lang.finnish', 'Finnish'), id: UserLanguage.fi},
                    {option: t('common:general.lang.swedish', 'Swedish'), id: UserLanguage.sv}
                  ]}
                  id="language"
                  label={t('common:general.language', 'Language')}
                  ref={fc.register}
                  nativeProps={{defaultValue: UserLanguage.en}}
                  returnId
                />

                {!isEditing && (
                  <P>
                    {t(
                      'users:messages.passwordInvitation',
                      'A password is created automatically when an invitation is sent.'
                    )}
                  </P>
                )}
                <div className="empty" />

                {isEditingSelf ? (
                  <>
                    <H2>{t('users:labels.changePassword', 'Change password')}</H2>
                    <div className="empty" />

                    {isEditing && (
                      <LabelInput
                        placeholder={t('users:labels.currentPassword', 'Current password')}
                        type="password"
                        autoComplete="current-password"
                        name="currentPassword"
                        id="currentPassword"
                        labelText={t('users:labels.currentPassword', 'Current password')}
                        ref={fc.register}
                        errorMsg={fc.errors.currentPassword && t(fc.errors.currentPassword.message || '')}
                      />
                    )}
                    <LabelInput
                      placeholder={t('users:labels.newPassword', 'New password')}
                      type="password"
                      autoComplete="new-password"
                      name="newPassword"
                      id="newPassword"
                      labelText={t('users:labels.newPassword', 'New password')}
                      ref={fc.register}
                      onChange={() => {
                        fc.setPasswordEdited(true)
                      }}
                    />
                    <H6>
                      {t(
                        'common:validation.passwordLength',
                        'Your password must contain minimum 8 characters, lowercase letters, and uppercase letters.'
                      )}
                    </H6>
                    <LabelInput
                      placeholder={t('users:labels.repeatNewPassword', 'Repeat new password')}
                      type="password"
                      autoComplete="off"
                      name="confirmNewPassword"
                      id="confirmNewPassword"
                      labelText={t('users:labels.repeatNewPassword', 'Repeat new password')}
                      ref={fc.register}
                      onChange={() => {
                        fc.setPasswordEdited(true)
                      }}
                      errorMsg={passwordErrorMessage}
                    />
                  </>
                ) : (
                  undefined
                )}
              </Baseline>
              {!!savedData && isEditingSelf && <EditUserAccountSettingsUserGroups userData={savedData} />}
              <div className="empty"></div>
            </Grid>
            <ButtonRowWrap>
              {showResetPassword ? (
                <div>
                  <Button variant="secondary" negative onClick={() => fc.handleResetPassword()}>
                    {t('users:labels.resetPassword', 'Reset password')}
                  </Button>
                </div>
              ) : (
                <div className="empty" />
              )}

              <ButtonRowGrid>
                <Button variant="secondary" negative onClick={() => fc.cancel()}>
                  {t('common:buttons.cancel', 'Cancel')}
                </Button>
                <Button
                  disabled={
                    !!passwordErrorMessage ||
                    !!emailErrorMessage ||
                    !!fc.errors.phoneNumber ||
                    !isEmpty(fc.errors) ||
                    rolesEmpty ||
                    !isEditingAllowed
                  }
                  variant="secondary"
                  onClick={() =>
                    isEditing
                      ? fc.update({orgState, selectedBuSites, isManager: !!isManager}, selectedRole)
                      : fc.create({orgState, selectedBuSites, isManager: !!isManager}, selectedRole)
                  }
                >
                  {isEditing
                    ? t('common:buttons.save', 'Save')
                    : t('common:buttons.saveAndInvite', 'Save and send invitation')}
                </Button>

                <GridSpan span={2}>
                  {!isEditingAllowed && (
                    <P>
                      {t(
                        'users:messages.noPermissions',
                        'You do not have sufficient permissions to edit this user. User is a member of organisation that you do not have access to'
                      )}
                    </P>
                  )}
                </GridSpan>
              </ButtonRowGrid>
            </ButtonRowWrap>
          </InvisibleContainer>
        </Baseline>
      </MainContent>
      {!isEditingSelf && (
        <NarrowRightSidebar>
          <AdjustableContainer padding="2rem">
            <Baseline>
              <P variant="small">
                {t(
                  'users:messages.selectUserRole',
                  'Select user role and organisation hierarchy level for the user. The role defines the permissions the user has on selected organisation levels, locations or sites.'
                )}
              </P>
              <RadioButtonGroup
                label={t('users:labels.userRole', 'User role')}
                options={[
                  {value: 'BU', label: t('users:labels.staff', 'Staff')},
                  {value: 'LM', label: t('users:labels.manager', 'Manager')},
                  {value: 'HI', label: t('users:labels.healthInspector', 'Health Inspector')}
                ]}
                selectedValue={selectedRole}
                onChange={handleRoleChange}
              />

              {rolesEmpty && (
                <Text2 style={{color: colors.system.red}}>
                  {t('common:validation.locationRequired', 'You must choose at least one location')}
                </Text2>
              )}
              <div>
                {(selectedRole === 'BU' || selectedRole === 'HI') && (
                  <UserSiteSelector data={chain} selected={selectedBuSites} onClick={handleBUClick} />
                )}
                {selectedRole === 'LM' && (
                  <ManagerLevelCheckbox
                    writableIds={writableIds}
                    data={chain}
                    level="chains"
                    onlySites={!isManager}
                    onClick={handleManagementLevelClick}
                    orgState={orgState}
                  />
                )}
              </div>
            </Baseline>
          </AdjustableContainer>
        </NarrowRightSidebar>
      )}
    </MainLayoutWithoutStretch>
  )
}

export default EditUserAccountSettingsDetails
