import React, {FC, useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useEquipmentTypeForm} from './EquipmentTypeUtils'
import {useAppState} from '../../../state'
import {MainLayoutWithoutStretch, MainContent} from '../../Layout/Layout'
import {Button, IconButton, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {BigLabelInput} from '../../Atoms/Forms'
import {Baseline, InvisibleContainer, Grid} from '../../Layout/Grid'
import {GoBackHeaderTitle, HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import IconSelector from '../../Organisms/IconSelect/IconSelector'
import EditStorageTypes from './EditStorageTypes'
import ConfirmEquipmentDeleteModal from './ConfirmEquipmentDeleteModal'
import {Heading} from '../../Atoms/Typography'
import styled from 'styled-components'
import {IconCloseSmall} from '../../../Assets/Icons/TinyIcons'
import {WasteScaleCategory} from '../../../state/rest'
import {deepCopy} from 'overmind/lib/utils'
import {Helmet} from 'react-navi-helmet-async'
import EditDishwasherTypes from './EditDishwasherTypes'
import EditCoolerTypes from './EditCoolerTypes'

interface WasteScaleCategoryWidgetProps {
  onChange: (items: WasteScaleCategory[]) => void
  savedItems?: WasteScaleCategory[]
  buttonText: string
  placeholderText: string
}
const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  input.todoItem {
    margin-left: 0.5rem;
    margin-right: 1rem;
    outline: none;
    border: none;
    min-width: 200px;
    width: auto;
    &::placeholder {
      color: gray;
    }
  }
  .removeIcon {
  }
`
const TodoList = styled.ul`
  margin: 0;
  padding: 0;
`
// TODO refactor this to own component file -> <EditWasteCategories> similarly as StorageTypes and DishwasherTypes
const WasteScaleCategoryWidget = ({
  onChange,
  savedItems,
  placeholderText,
  buttonText
}: WasteScaleCategoryWidgetProps) => {
  const [wasteScaleCategories, setWasteScaleCategories] = useState<WasteScaleCategory[]>(savedItems || [])
  const todoList = useRef<HTMLUListElement>(null)

  const handleRemove = (index: number) => {
    const list = wasteScaleCategories.filter((_, i) => i !== index)
    setWasteScaleCategories(list)
    onChange(list)
  }
  const handleAdd = () => {
    const list = [...wasteScaleCategories, {name: '', id: ''}]
    setWasteScaleCategories(() => list)
    onChange(list)
  }
  const handleEdit = (index: number, label: string) => {
    const list = [...wasteScaleCategories]
    list[index].name = label
    setWasteScaleCategories(list)
    onChange(list)
  }

  return (
    <Baseline>
      <TodoList ref={todoList}>
        {wasteScaleCategories.map((category, index) => (
          <Item key={category.id + index}>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleAdd()
              }}
            >
              <input
                placeholder={placeholderText}
                aria-label={`edit item: ${category}`}
                className="todoItem"
                value={category.name}
                onChange={e => handleEdit(index, e.target.value)}
                autoFocus
              ></input>
            </form>
            <IconButton
              icon={<IconCloseSmall />}
              buttonProps={{className: 'removeIcon', onClick: () => handleRemove(index)}}
            />
          </Item>
        ))}
      </TodoList>

      <IconButton buttonProps={{onClick: () => handleAdd()}} description={buttonText} />
    </Baseline>
  )
}

interface FormProps {
  id?: string
}

const EditEquipmentType: FC<FormProps> = ({id}: FormProps): any => {
  const {state, effects, actions} = useAppState()
  const {t} = useTranslation('appliances')

  // fetch folder data (if editing)
  // TODO! fetch when loading the route?
  const savedData = id ? state.equipmentTypeById[id] : undefined
  const fc = useEquipmentTypeForm(savedData)
  const isEditing = !!savedData

  const [wasteScaleCategories, setWasteScaleCategories] = useState<WasteScaleCategory[]>(
    deepCopy(state.wasteScaleCategories)
  )
  const handleWasteCategoryChange = (list: WasteScaleCategory[]) => {
    // remove items with no name, interpreted as deleted
    setWasteScaleCategories(list.filter(i => i.name !== ''))
  }

  const handleSave = async () => {
    const orig = state.wasteScaleCategories
    const deleted = orig.filter(o => !wasteScaleCategories.find(w => w.id === o.id))
    const added = wasteScaleCategories.filter(w => w.id === '')
    const modified = wasteScaleCategories.filter(w => !!orig.find(o => o.id === w.id && o.name !== w.name))
    for (const item of deleted) {
      await effects.equipmentApi.deleteWasteScaleCategories(item.id)
    }
    for (const item of added) {
      await effects.equipmentApi.createWasteScaleCategories(item.name)
    }
    for (const item of modified) {
      await effects.equipmentApi.updateWasteScaleCategories(item.id, item.name)
    }
    await actions.getWasteScaleCategories()

    if (isEditing) {
      await fc.update()
    } else {
      await fc.submit()
    }
  }

  return (
    <MainLayoutWithoutStretch>
      {isEditing ? (
        <Helmet title={t('common:routes.editApplianceType', 'Edit appliance type')} />
      ) : (
        <Helmet title={t('common:routes.createApplianceType', 'Add appliance type')} />
      )}
      <ConfirmEquipmentDeleteModal
        isOpen={fc.modalOpen}
        onClose={() => {
          fc.setModalOpen(false)
        }}
        onDelete={fc.remove}
      />
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={
              isEditing
                ? t('appliances:labels.editApplianceType', 'Edit appliance type')
                : t('appliances:labels.newApplianceType', 'New appliance type')
            }
            path={'/appliance/types'}
            backLabel={t('appliances:labels.applianceTypeSettings', 'Appliance type settings')}
          />
          {isEditing && !fc.isFixed && <HeaderActions onDelete={() => fc.setModalOpen(true)} />}
        </HeaderRow>
        <InvisibleContainer>
          <Grid>
            <Baseline>
              {fc.isFixed ? (
                <BigLabelInput
                  labelText={t('appliances:labels.applianceTypeName', 'Appliance type name')}
                  name="name"
                  disabled
                  id="name"
                  value={t(`appliances:equipmentTypes.${savedData?.id}`, savedData?.name) as string}
                />
              ) : (
                <BigLabelInput
                  required
                  placeholder={t('appliances:placeholder.newApplianceTypeName', 'New appliance type name')}
                  labelText={t('appliances:labels.applianceTypeName', 'Appliance type name')}
                  name="name"
                  id="name"
                  ref={fc.register({
                    required: `${t('common:validation.nameRequired', 'Name field is required')}`
                  })}
                  errorMsg={!!fc.errors.name ? fc.errors.name!.message : undefined}
                />
              )}
              <IconSelector disabled={fc.isFixed} onChange={fc.handleIconSelect} iconKey={fc.icon} />

              {fc.showStorageTypeEditor() && <EditStorageTypes controller={fc.sfc} />}
              {fc.showDishwasherTypeEditor() && <EditDishwasherTypes controller={fc.dfc} />}
              {fc.showCoolerTypeEditor() && <EditCoolerTypes controller={fc.cfc} />}
            </Baseline>
            <div className="empty"></div>
            {fc.showWasteScaleCategoryEditor() && (
              <>
                <Baseline>
                  <Heading level={4}>{t('appliances:labels.wasteTypeCategories', 'Waste type categories')}</Heading>
                  <WasteScaleCategoryWidget
                    savedItems={wasteScaleCategories}
                    buttonText={t('appliances:actions.addNewWasteType', 'Add new')}
                    placeholderText={t('appliances:placeholders.newWasteCategory', 'New waste category')}
                    onChange={handleWasteCategoryChange}
                  />
                </Baseline>
                <div className="empty"></div>
              </>
            )}
          </Grid>
          <ButtonRowWrap>
            <div className="empty"></div>
            <ButtonRowGrid>
              <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                {t('common:buttons.cancel', 'Cancel')}
              </Button>
              <Button
                variant="secondary"
                disabled={!fc.formState.isValid || !fc.sfc.formState.isValid || fc.loading}
                // onClick={() => (isEditing ? fc.update() : fc.submit())}
                onClick={handleSave}
              >
                {t('common:buttons.save', 'Save')}
              </Button>
            </ButtonRowGrid>
          </ButtonRowWrap>
        </InvisibleContainer>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

export default EditEquipmentType
