import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconChecklist = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.656 19.3492L41.1405 7.46985L41.9999 8.35878L29.656 21.127L23.2686 14.52L24.1279 13.6311L29.656 19.3492Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.9151 40.3428H11.4692V39.0857H30.9151V40.3428Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.9151 27.7715H11.4692V26.5144H30.9151V27.7715Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.9151 34.0572H11.4692V32.8H30.9151V34.0572Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2H36V14.5714H34.8V3.25714H7.2V44.7429H34.8V18.3429H36V46H6V2Z"
      />
    </svg>
  )
}
