import React, {ReactNode} from 'react'
import {ReactNodeArray} from 'prop-types'
import styled, {css} from 'styled-components'
import {colors} from '../../sharedComponents/colors'

export const A11yListContainer = styled.div`
  border-top: 1px solid ${colors.system.lightGrey_5};
`

export const A11yListItem = styled.div<{offset?: number}>`
  position: relative;
  border: none;
  background-color: transparent;
  min-height: 4rem;
  padding: ${p => `0.25rem 1rem 0.25rem ${p.offset || 2}rem;`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${colors.system.lightGrey_5};
  cursor: pointer;

  &:focus {
    outline: none;
    background-color: ${colors.system.lightGrey_5};
  }
`
const A11IconListItemWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr auto;
  padding: 0;

  margin-bottom: 3px;
  cursor: ${(props: any) => (!!props.onClick ? 'pointer' : 'disabled')};
  .icon {
    display: flex;
    margin-right: 0.5rem;
    svg {
      margin: auto;
      min-height: 40px;
      min-width: 40px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    width: 100%;
    > * {
      margin-top: 0.15rem;
      margin-bottom: 0.15rem;
    }
  }
  .details {
    display: flex;
    align-items: center;
    width: 100%;
    > * {
      margin-top: 0.15rem;
      margin-bottom: 0.15rem;
    }
  }
  .action  {
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
interface IconListItemProps {
  icon: ReactNode
  content: ReactNode
  details?: ReactNode
  onClick?: () => void
  loading?: boolean
  bgColor?: string
  action?: ReactNode
  offset?: number
}
export const A11yIconListItem = (p: IconListItemProps) => {
  // const styleOverride = {paddingLeft: p.offset + 'rem' || '.25rem'}
  return (
    <A11yListItem
      offset={p.offset || 2}
      style={p.loading ? {opacity: 0.5, cursor: 'progress'} : {}}
      onClick={p.onClick ? () => p.onClick!() : undefined}
      data-cy={'accordion-list-item'}
    >
      <A11IconListItemWrapper>
        <div className="icon">{p.icon}</div>
        <div className="content">{p.content}</div>
        <div className="action">{p.action}</div>
      </A11IconListItemWrapper>
    </A11yListItem>
  )
}

export const A11yIconListItemWithDetails = (p: IconListItemProps) => {
  return (
    <A11yListItem
      offset={p.offset || 2}
      style={p.loading ? {opacity: 0.5, cursor: 'progress'} : {}}
      onClick={p.onClick ? () => p.onClick!() : undefined}
    >
      <A11IconListItemWrapper>
        <div className="icon">{p.icon}</div>
        <div className="content">{p.content}</div>
        <div className="details">{p.details}</div>
        <div className="action">{p.action}</div>
      </A11IconListItemWrapper>
    </A11yListItem>
  )
}

interface OffsetListItemProps {
  offset?: number
}
export const OffsetListItem = styled.div<OffsetListItemProps>`
  padding-left: ${props => props.offset || 3.2}rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const IconWithContent = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr;
  width: 100%;
  grid-gap: 0.5rem;
  align-items: center;
  justify-items: baseline;
  margin-left: -8px; /** To offset icon container whitespace */
`

export const UserListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  justify-items: baseline;
`

export const TaskListItem = styled.li`
  background-color: ${colors.system.white};
  border: 1px solid ${colors.system.lightGrey_5};
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  list-style: none;
  margin-top: 1rem;
  cursor: pointer;
  &:first-child {
    margin: 0;
  }
`

export const ListItemContainer = styled.div<ListItemContainerProps>`
  position: relative;
  background: ${colors.system.white};
  border-bottom: 1px solid ${colors.system.lightGrey_5};
  width: 100%;
  padding: 1.25rem 2rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: left;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.hasIcon &&
    !props.compact &&
    css`
      padding: 1.25rem 2rem;
      display: grid;
      grid-template-columns: 3rem 1fr auto;
      grid-column-gap: 1rem;
      align-items: center;
      justify-items: left;
    `}

  ${props =>
    props.compact &&
    css`
      padding: 1.25rem 1rem;
    `}

  ${props =>
    props.compact &&
    props.hasIcon &&
    css`
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: 3rem 1fr auto;
      align-items: center;
      justify-items: left;
    `}
`
const ListIconContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  svg {
    height: 3rem;
    width: 3rem;
  }
`

const ListActionContainer = styled.div`
  align-self: right;
  margin-left: auto;
  margin-right: 0;
`

export const ListItemContentContainer = styled.div`
  width: 100%;
`
interface ListItemContainerProps {
  hasIcon: boolean
  compact: boolean
  onClick?: (e: React.MouseEvent) => void
}
interface ListItemProps {
  children: ReactNode | ReactNodeArray
  icon?: ReactNode
  onClick?: (e: React.MouseEvent) => void
  action?: ReactNode
  compact?: boolean
}
export const ListItem = ({children, compact, icon, action, onClick}: ListItemProps) => {
  return (
    <ListItemContainer onClick={onClick} hasIcon={!!icon} compact={!!compact}>
      {icon ? <ListIconContainer>{icon}</ListIconContainer> : null}
      {children}
      {action ? <ListActionContainer>{action}</ListActionContainer> : null}
    </ListItemContainer>
  )
}

const SelectableContainer = styled.div<ContainerProps>`
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
  &.selected > ${ListItemContainer}:before {
    content: '';
    position: absolute;
    background-color: ${props => (props.variant ? selectVariants[props.variant] : selectVariants['default'])};
    width: 8px;
    height: 100%;
    border-bottom: 1px solid ${colors.system.lightGrey_5};
    left: 0;
    top: 0;
  }
`
type SelectableContainerVariants = {
  default: string
  warn: string
}
const selectVariants: SelectableContainerVariants = {
  default: colors.brand.cobalt,
  warn: colors.system.red
}
interface SelectableContainerProps {
  isSelected: boolean
  variant?: keyof SelectableContainerVariants
  children: ReactNode | ReactNodeArray
  onClick?: (e: React.MouseEvent) => void
}
interface ContainerProps {
  variant?: keyof SelectableContainerVariants
  onClick?: (e: React.MouseEvent) => void
}
export const SelectableListItemContainer = ({isSelected, variant, children, onClick}: SelectableContainerProps) => {
  return (
    <SelectableContainer
      variant={variant}
      className={`accordion-container ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
    >
      {children}
    </SelectableContainer>
  )
}
SelectableListItemContainer.defaultProps = {
  variant: 'default'
}

export const ListContainer = styled.div`
  > ${ListItemContainer}, > ${SelectableContainer} {
    &:first-child {
      border-top: 1px solid ${colors.system.lightGrey_5};
    }
  }
`
