import {AsyncAction} from 'overmind'

export const getGatewayLatestValues: AsyncAction<{siteId: string}, void> = async ({state, effects}, params) => {
  state.v1.settings.gateways.pending = true
  state.v1.settings.gateways.latestValues = []
  state.v1.settings.gateways.signalStrengths = []
  state.v1.settings.gateways.error = null
  try {
    const latestValues = await effects.v1.settings.gateways.getGatewayLatestValues(params.siteId)
    const signalStrengths = await effects.v1.settings.gateways.getGatewaySignalStrengths(params.siteId)
    state.v1.settings.gateways.latestValues = latestValues
    state.v1.settings.gateways.signalStrengths = signalStrengths
  } catch (err) {
    state.v1.settings.gateways.error = err
  } finally {
    state.v1.settings.gateways.pending = false
  }
}
