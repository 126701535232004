import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconNewFolder = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4H2.63H13.1111H13.3774L13.5629 4.1909L18.0356 8.79268H36.1212V10.0527H17.7694H17.5032L17.3177 9.86177L12.8449 5.26H3.26V17.1812L41.5954 17.2206V15.7561H42.8554V45.3677V45.9977H42.2254H2.63H2V45.3677V4.63V4ZM41.5954 18.4806L3.26 18.4412V44.7377H41.5954V18.4806Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5965 10.0525V13.3068H42.8565V10.0525H45.9999V8.79245H42.8565V5.53906H41.5965V8.79245H38.4512V10.0525H41.5965Z"
      />
    </svg>
  )
}
