import React from 'react'
import styled from 'styled-components'

interface RadioButtonGroupProps {
  label: string
  options: {value: string; label: string}[]
  selectedValue: string
  onChange: (value: string) => void
}

const RadioButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
`

const RadioButtonInput = styled.input`
  margin-right: 0.5rem;
`

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({label, options, selectedValue, onChange}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <RadioButtonGroupWrapper>
      <span>{label}</span>
      <RadioButtonContainer>
        {options.map(option => (
          <RadioButtonLabel key={option.value}>
            <RadioButtonInput
              type="radio"
              name={label}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={handleChange} // Ensure onChange handler is provided
            />
            {option.label}
          </RadioButtonLabel>
        ))}
      </RadioButtonContainer>
    </RadioButtonGroupWrapper>
  )
}

export default RadioButtonGroup
