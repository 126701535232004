import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {WeekSchedule} from '../../../state/rest'
import {Label} from '../../Atoms/Forms'
import {Row, Baseline} from '../../Layout/Grid'

import WeekScheduler from './WeekScheduler'
import {v4 as uuid} from 'uuid'

import {IconButton, TextButton} from '../../Atoms/Buttons'
import {IconClose} from '../../../Assets/Icons/TinyIcons'
import {IconAddItem} from '../../../Assets/Icons/LargeIcons'
import {Text} from '../../Atoms/Typography'
import {colors} from '../../../sharedComponents/colors'

interface Props {
  controller: any
}

const WeekScheduleListWrapper = styled.div`
  margin-top: 3rem;
`

const WeekScheduleList = ({controller: sc}: Props) => {
  const {t} = useTranslation('settings')

  return (
    <WeekScheduleListWrapper>
      <Baseline margin="1rem">
        <Label required>{t('settings:labels.alarmRuleSchedule', 'Schedule')}</Label>
        {sc.schedules &&
          sc.schedules.map((schedule: WeekSchedule, index: number) => {
            return (
              <Row justify={'flex-start'} align={'flex-start'} key={uuid()}>
                <WeekScheduler schedule={schedule} onChange={schedule => sc.handleChange(schedule, index)} />
                <IconButton buttonProps={{onClick: () => sc.handleRemove(index)}} icon={<IconClose />} />
              </Row>
            )
          })}
        <Row>
          <TextButton onClick={() => sc.handleAdd()}>
            <Row justify={'flex-start'}>
              <IconAddItem height="1.5rem" />
              <Text level={2} color={colors.brand.cobalt}>
                {t('settings:actions.addSchedule', 'Add another schedule')}
              </Text>
            </Row>
          </TextButton>
        </Row>
      </Baseline>
    </WeekScheduleListWrapper>
  )
}

export default WeekScheduleList
