import React, {useEffect, useState, useCallback} from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'
import {colors} from '../../../sharedComponents/colors'
import {MainLayoutWithoutStretch, MainContent} from '../../Layout/Layout'
import {Baseline, InvisibleContainer} from '../../Layout/Grid'
import {GoBackHeaderTitle, HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import {P, H3} from '../../Atoms/Typography'

import {userApi} from '../../../state/rest'
import {Chain, Site, User, UserRole} from '../../../state/state'
import {Loader} from '../../../Routes'
import {A11yListItem, A11yListContainer, UserListItem} from '../../Atoms/Lists'
import {useAppState} from '../../../state'
import {Helmet} from 'react-navi-helmet-async'
import {getHighestRoleForUser} from '../Utils'
import styled from 'styled-components'
import {getSites} from '../../../config/utils'
import {Text} from '../../../sharedComponents/components'

const TableDivider = styled.div`
  background-color: ${colors.system.grey_5};
  border-radius: 0.5rem;
  height: 2rem;
  padding: 0 1rem;
  line-height: 2rem;
`

const UsersView = () => {
  const nav = useNavigation()
  const {t} = useTranslation(['users', 'settings'])
  const {state} = useAppState()
  const {me} = state
  const [loading, setLoading] = useState(true)
  const chain: Chain = state.chainsById[state.selectedChainId!]
  const sites: Site[] = getSites(chain)

  const handleClick = (id: string) => {
    nav.navigate(`/settings/users/edit/${id}`)
  }

  const [users, setUsers] = useState<User[]>([])
  const setup = useCallback(async () => {
    setLoading(true)
    const usersData = await userApi.getUsers()
    setUsers(usersData.items)
    setLoading(false)
  }, [])

  const separateUsersByRoles = (users: User[]) => {
    const usersWithHighestRoles: User[] = users.map(user => {
      return {
        ...user,
        highestRole: getHighestRoleForUser(user)
      }
    })
    const usersGroupedByRoles: {[key: string]: User[]} = {}

    for (const user of usersWithHighestRoles) {
      const {highestRole} = user
      if (!highestRole) continue

      if (!usersGroupedByRoles[highestRole]) {
        usersGroupedByRoles[highestRole] = []
      }
      usersGroupedByRoles[highestRole].push(user)
    }
    return usersGroupedByRoles
  }
  const usersByRoles = separateUsersByRoles(users.filter(user => user.id !== me?.user.id))

  const getRoleName = (role: UserRole) => {
    switch (role) {
      case 'CM':
        return t('users:labels.chainManager', 'Chain Manager')
      case 'BM':
        return t('users:labels.brandManager', 'Brand Manager')
      case 'GM':
        return t('users:labels.groupManager', 'Group Manager')
      case 'LM':
        return t('users:labels.locationManager', 'Location Manager')
      case 'SM':
        return t('users:labels.siteManager', 'Site Manager')
      case 'HI':
        return t('users:labels.healthInspector', 'Health Inspector')
      default:
        return t('users:labels.basicUser', 'Basic User')
    }
  }

  const sortByRole = (roles: UserRole[]) => {
    const rolesWithRanks: {role: UserRole; rank: number}[] = []
    const ranks = {
      CM: 1,
      BM: 2,
      GM: 3,
      LM: 4,
      SM: 5,
      BU: 6,
      HI: 7
    }
    roles.forEach(role => {
      rolesWithRanks.push({
        role,
        rank: ranks[role]
      })
    })
    return [...rolesWithRanks].sort((a, b) => a.rank - b.rank).map(r => r.role)
  }

  const sortedUserRoles = sortByRole(Object.keys(usersByRoles) as UserRole[])

  useEffect(() => {
    setup()
  }, [setup])

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.userAccountSettings', 'User account settings')} />
      <Loader show={loading} />
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={t('users:labels.userAccountSettings', 'User account settings')}
            path={'/settings'}
            backLabel={t('settings:labels.settingsTitle', 'Settings')}
          />
          <HeaderActions onAdd={() => nav.navigate('/settings/users/add')} />
        </HeaderRow>
        <Baseline>
          <InvisibleContainer>
            <P>{`${t(
              'users:labels.addNewUsersGuide',
              'Add new users and edit user permissions. In user permissions you can select where user works. For managers you can select what sites and other organisation levels they can edit.'
            )}`}</P>
          </InvisibleContainer>
          {sortedUserRoles.map(role => {
            const users = usersByRoles[role]
            return (
              <A11yListContainer key={role}>
                <TableDivider>
                  <Text size="S" strong>
                    {getRoleName(role as UserRole)}
                  </Text>
                </TableDivider>
                {users.length ? (
                  users
                    .filter(user => user.id !== me?.user.id)
                    .map(user => {
                      return <UserList key={user.id} user={user} onUserSelect={handleClick} sites={sites} />
                    })
                ) : (
                  <div>{t('users:messages.noUsersFound', 'Unexpected error: no users found')}</div>
                )}
              </A11yListContainer>
            )
          })}
        </Baseline>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

interface UserListProps {
  user: User
  onUserSelect: (id: string) => void
  sites: Site[]
}

const UserList = ({user, onUserSelect, sites}: UserListProps) => {
  const userSiteIds = user.userToSite.map(us => us.siteId)
  const siteNames = []
  const {t} = useTranslation('common')

  for (const id of userSiteIds) {
    const site = sites.find(site => site.id === id)
    if (site) siteNames.push(site.name)
  }

  const renderSiteNames = (names: string[]) => {
    if (names.length > 2) {
      return `${names[0]}, ${names[1]} & ${names.length - 2} ${t('common:labels.othersAmount', 'others')}`
    }
    return names.join(', ')
  }

  return (
    <A11yListItem onClick={() => onUserSelect(user.id)}>
      <UserListItem>
        <H3>{resolveUserName(user)}</H3>
        <P variant="small">
          <a title={siteNames.join(', ')}>{renderSiteNames(siteNames)}</a>
        </P>
      </UserListItem>
    </A11yListItem>
  )
}

function resolveUserName(user: User) {
  if (!!user.firstName && user.firstName.length > 0 && !!user.lastName && user.lastName.length > 0) {
    return `${user.lastName}, ${user.firstName}`
  } else return user.email
}
export default UsersView
