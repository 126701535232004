import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {colors} from '../../../colors'
import {Text, TextSize} from '../Text'

export const EMPTY_VALUE_TEXT = '-'

export interface DataFieldProps {
  label: string
  value?: number | string | React.ReactNode
  labelSize?: TextSize
  valueSize?: TextSize
}

export const DataField: React.FC<DataFieldProps> = ({label, value, labelSize, valueSize, ...rest}) => {
  if (!labelSize || !valueSize) {
    return null
  }
  const valueElement = React.isValidElement(value) ? value : <Text size={valueSize}>{value ?? EMPTY_VALUE_TEXT}</Text>

  return (
    <DataFieldContainer {...rest}>
      <Text size={labelSize} color={colors.system.grey_50}>
        {label}
      </Text>
      {valueElement}
    </DataFieldContainer>
  )
}

DataField.defaultProps = {
  labelSize: 'S',
  valueSize: 'M'
}

DataField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  labelSize: PropTypes.oneOf<TextSize>(['XXS', 'XS', 'S', 'M', 'L', 'XL']),
  valueSize: PropTypes.oneOf<TextSize>(['XXS', 'XS', 'S', 'M', 'L', 'XL'])
}

const DataFieldContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 0.25rem;
  align-content: start;
`
