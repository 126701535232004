export interface GatewayLatestValues {
  gatewayId: string
  receivedAt: Date
}

interface GatewayLatestValuesForSite {
  latestValues: GatewayLatestValues[]
  signalStrengths: GatewaySignalStrength[]
  pending: boolean
  error: Error | null
}

export const state: GatewayLatestValuesForSite = {
  latestValues: [],
  signalStrengths: [],
  pending: false,
  error: null
}

interface SensorSignalStrength {
  sensorId: string
  signalStrength: string
  receivedAt: string
}
export interface GatewaySignalStrength {
  gatewayId: string
  sensors: SensorSignalStrength[]
}
