import {Helmet} from 'react-navi-helmet-async'
import {MainContent, MainLayoutWithoutStretch} from '../../../Layout/Layout'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ConfirmModalWindow,
  DocumentAddingForm,
  DocumentAddingModalWindow,
  DocumentEditBottomButtons,
  DocumentTypeSelection
} from '../HelperComponents'
import {useNavigation} from 'react-navi'
import {GoBackHeaderTitle, HeaderRow} from '../../../Molecules/ViewComponents/ViewHeader'
import {Baseline, Grid, InvisibleContainer} from '../../../Layout/Grid'
import {AssetData, Document, DocumentFolder, DocumentType} from '../../../../state/rest'
import {useInstructionsDocumentsFolderSelect} from '../../../../config/utils'
import {useAppState} from '../../../../state'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../../../../state/state'
import {Loader} from '../../../../Routes'
import {InstructionsDocument} from 'state/instructions/state'

interface EditInstructionsDocumentGridProps {
  isEditing: boolean
  document: Partial<Document>
  t: any
  showUploader: any
  setShowUploader: any
  handleSelectType: any
  handleAddAsset: any
  setDocument: (document: Partial<Document>) => void
  isDocumentOwner: () => boolean
  folderOptions: {id: string; option: string}[]
  selectedDocumentFolder: DocumentFolder
  selectMethod: (id: string) => void
  internalDocumentFolders: any
}

const EditInstructionsDocumentGrid = ({
  isEditing,
  document,
  t,
  showUploader,
  setShowUploader,
  handleSelectType,
  handleAddAsset,
  setDocument,
  isDocumentOwner,
  folderOptions,
  selectedDocumentFolder,
  selectMethod,
  internalDocumentFolders
}: EditInstructionsDocumentGridProps) => {
  return (
    <Grid>
      <Baseline>
        <DocumentTypeSelection
          isEditing={isEditing}
          document={document}
          t={t}
          showUploader={showUploader}
          toggleShowUploader={setShowUploader}
          handleSelectType={handleSelectType}
        />
        <DocumentAddingModalWindow
          t={t}
          showUploader={showUploader}
          toggleShowUploader={setShowUploader}
          handleAddAsset={handleAddAsset}
          instructions={true}
        />
        <DocumentAddingForm
          isInstructions={true}
          document={document}
          setDocument={setDocument}
          isEditing={isEditing}
          isDocumentOwner={isDocumentOwner}
          folderOptions={folderOptions}
          selectedDocumentFolder={selectedDocumentFolder}
          selectMethod={selectMethod}
          internalDocumentFolders={internalDocumentFolders}
          t={t}
        />
      </Baseline>
      <div className="empty"></div>
    </Grid>
  )
}

interface EditInstructionsDocumentProps {
  id?: string
}

export const EditInstructionsDocument = ({id}: EditInstructionsDocumentProps) => {
  const isEditing = !!id
  const nav = useNavigation()
  const {state, actions} = useAppState()
  const {t} = useTranslation('documents')
  const [modalOpen, setModalOpen] = useState(false)

  const documentFolders = state.v1.instructions.instructionsFolders

  const initialDocument = {
    type: undefined,
    name: '',
    documentFolder: {
      id: documentFolders.length > 0 ? documentFolders[0].id : ''
    },
    asset: undefined,
    url: undefined,
    sites: []
  }

  const [document, setDocument] = useState<Partial<InstructionsDocument>>(initialDocument)
  const [showUploader, setShowUploader] = useState(false)

  const {selectMethod, selectedDocumentFolder, folderOptions, setSelected} = useInstructionsDocumentsFolderSelect(
    document.documentFolder!.id!
  )

  const goBack = () => nav.navigate('/instructions')
  const handleDelete = () => {
    actions.v1.instructions
      .deleteInstructionsDocument(id!)
      .catch(() =>
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('documents:msg.deleteDocumentError', 'Could not delete document'),
          description: t('documents:msg.deleteDocumentErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      )
      .then(goBack)
  }

  useEffect(() => {
    const setup = async () => {
      if (id) {
        const document = await actions.v1.instructions.getInstructionsDocument(id)
        setDocument(document)
        if (document.documentFolder) {
          setSelected(document.documentFolder as any)
        }
      }
      actions.v1.instructions.getInstructionsFolders()
    }

    setup()
  }, [actions.v1.instructions, id, setSelected])

  const handleAddAsset = (asset?: AssetData) => {
    if (asset) {
      setDocument(d => ({...document, type: DocumentType.Internal, name: d.name || asset.name, asset}))
      setShowUploader(!showUploader)
    }
  }
  const handleSelectType = (type: DocumentType) => {
    setDocument(() => ({...document, type}))
  }

  const handleUpdate = () => {
    const documentToBeSent = {
      ...document,
      id: id!,
      type: document.type!,
      name: document.name!,
      url: document.url,
      createdAt: new Date().toISOString(),
      createdBy: state.me!.user,
      documentFolder: selectedDocumentFolder
    } as Partial<InstructionsDocument>
    actions.v1.instructions
      .updateInstructionsDocument(documentToBeSent)
      .catch(() =>
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('documents:msg.updateDocumentError', 'Could not update document'),
          description: t('documents:msg.updateDocumentErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      )
      .then(goBack)
  }

  const handleCreate = () => {
    const documentToBeSent = {
      ...document,
      createdBy: state.me!.user,
      documentFolder: selectedDocumentFolder
    } as Partial<InstructionsDocument>
    actions.v1.instructions
      .createInstructionsDocument(documentToBeSent)
      .catch(() =>
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('documents:msg.createDocumentError', 'Could not create document'),
          description: t('documents:msg.createDocumentErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      )
      .then(goBack)
  }

  const handleCancel = () => {
    goBack()
  }

  const HeaderRowWithGoBack = () => {
    return (
      <HeaderRow>
        <GoBackHeaderTitle
          label={
            isEditing
              ? `${t('documents:instructions.editDocument', 'Edit Instructions Document')}`
              : `${t('documents:instructions.addDocument', 'Add Instructions Document')}`
          }
          path={'/instructions'}
          backLabel={t('documents:labels.documentsTitle', 'Instruction Documents')}
        />
      </HeaderRow>
    )
  }

  const isValid = useCallback((): boolean => {
    const hasName = !!document.name && document.name.trim().length > 0
    const hasFolder = !!document.documentFolder && document.documentFolder.id != ''

    if (document.type === DocumentType.Internal) {
      const hasAsset = !!document.asset
      return hasName && hasAsset && hasFolder
    } else if (document.type === DocumentType.External) {
      const url = document.url || ''
      const hasUrl = url.trim().length > 0 && !!url.match(/^https?:\/\/.+/)
      return hasName && hasUrl && hasFolder
    }
    return false
  }, [document.type, document.name, document.asset, document.url, document.documentFolder])

  const isDocumentOwner = useCallback((): boolean => {
    if (state.me?.accessRights.superuser) {
      return true
    }
    return false
  }, [state.me])

  const isLoading = state.v1.instructions.documentFoldersPending

  return (
    <MainLayoutWithoutStretch>
      {<Loader show={isLoading} />}
      {isEditing ? (
        <Helmet title={t('common:routes.editInstructionsDocument', 'Edit Instructions Document')} />
      ) : (
        <Helmet title={t('common:routes.createDocument', 'Create Instructions Document')} />
      )}
      <ConfirmModalWindow
        t={t}
        setModalOpen={setModalOpen}
        handleDelete={handleDelete}
        modalOpen={modalOpen}
        heading={t('documents:confirmation.deleteDocumentTitle', 'Delete document?')}
        content={t(
          'documents:confirmation.deleteDocumentDescription',
          'Are you sure you want to delete this document?'
        )}
      />
      <MainContent variant="white">
        <HeaderRowWithGoBack />
        <InvisibleContainer>
          <EditInstructionsDocumentGrid
            document={document}
            folderOptions={folderOptions}
            handleAddAsset={handleAddAsset}
            handleSelectType={handleSelectType}
            internalDocumentFolders={documentFolders}
            isDocumentOwner={isDocumentOwner}
            isEditing={isEditing}
            selectMethod={selectMethod}
            selectedDocumentFolder={selectedDocumentFolder}
            setDocument={setDocument}
            setShowUploader={setShowUploader}
            showUploader={showUploader}
            t={t}
          />
          <DocumentEditBottomButtons
            isEditing={isEditing}
            isDocumentOwner={isDocumentOwner}
            isValid={isValid}
            handleUpdate={handleUpdate}
            handleCreate={handleCreate}
            handleCancel={handleCancel}
            setModalOpen={setModalOpen}
            t={t}
          />
        </InvisibleContainer>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}
