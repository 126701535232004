import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconTemperature = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1525 6.27338C12.1525 3.91303 14.0987 2 16.5 2C18.9013 2 20.8475 3.91303 20.8475 6.27338V28.367C23.9029 29.9166 26 33.0432 26 36.6619C26 41.8192 21.7468 46 16.5 46C11.2532 46 7 41.8192 7 36.6619C7 33.0432 9.09707 29.9166 12.1525 28.367V6.27338ZM16.5 3.26619C14.8101 3.26619 13.4407 4.61232 13.4407 6.27338V29.1672L13.0666 29.3369C10.2462 30.6167 8.28814 33.4118 8.28814 36.6619C8.28814 41.1199 11.9647 44.7338 16.5 44.7338C21.0353 44.7338 24.7119 41.1199 24.7119 36.6619C24.7119 33.4118 22.7538 30.6167 19.9334 29.3369L19.5593 29.1672V6.27338C19.5593 4.61232 18.1899 3.26619 16.5 3.26619ZM16.3862 33.6546C14.7166 33.6546 13.3635 35.0008 13.3635 36.6618C13.3635 38.3229 14.7166 39.669 16.3862 39.669C18.0558 39.669 19.4088 38.3229 19.4088 36.6618C19.4088 35.0008 18.0558 33.6546 16.3862 33.6546ZM17.0095 32.4331V17.9375H15.7495V32.435C13.679 32.741 12.0908 34.5167 12.0908 36.6618C12.0908 39.0222 14.0137 40.9352 16.3862 40.9352C18.7587 40.9352 20.6815 39.0222 20.6815 36.6618C20.6815 34.5121 19.0865 32.7334 17.0095 32.4331Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5 10.2622C30.0544 10.2622 27.2622 13.0544 27.2622 16.5C27.2622 19.9456 30.0544 22.7378 33.5 22.7378C36.9444 22.7378 39.7378 19.9456 39.7378 16.5C39.7378 13.0544 36.9444 10.2622 33.5 10.2622ZM26 16.5C26 12.3573 29.3573 9 33.5 9C37.6414 9 41 12.3572 41 16.5C41 20.6428 37.6414 24 33.5 24C29.3573 24 26 20.6427 26 16.5ZM32.8008 15.8046V12.9751H34.0735V15.8046H36.9169V17.0708H34.0735V19.8999H32.8008V17.0708H29.9565V15.8046H32.8008Z"
      />
    </svg>
  )
}
