import _ from 'lodash'
import {baseApi} from '../rest'
import {PerformableTask} from './state'
import {payloadStr2float} from '../../config/utils'
import {EventResponse} from './state'

export const performTaskApi = {
  ...baseApi,
  async getTask(siteId: string, taskId: string, eventId?: string) {
    const request = await this.doRequest<PerformableTask>({
      path: eventId
        ? `/v1/performTasks/site/${siteId}/task/${taskId}?event-id=${eventId}`
        : `/v1/performTasks/site/${siteId}/task/${taskId}`
    })
    const response = await request.get()
    return (response as unknown) as PerformableTask
  },
  async createEvent(payload: any, siteId: string, instance?: string) {
    if (instance) {
      payload.scheduledAt = instance
    }
    const p = payloadStr2float(payload)
    const req = await this.doRequest<any>({
      path: `/v1/events`,
      site: siteId,
      payload: _.omitBy(p, isEmptyValue)
    })
    const resp = await req.post()
    return (resp as unknown) as EventResponse
  },
  async updateEvent(eventId: string, siteId: string, payload: any) {
    const req = await this.doRequest<any>({
      path: `/v1/events/${eventId}`,
      site: siteId,
      payload: _.omitBy(payload, isEmptyValue)
    })
    const resp = await req.put()
    return (resp as unknown) as EventResponse
  }
}

export function isEmptyValue(value: any) {
  if (typeof value === 'string') {
    return value.length === 0
  } else {
    return _.isNil(value)
  }
}
