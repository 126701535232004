import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconCooler = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8155 2V8.42365L15.6842 5.21216L15.0001 6.28759L21.1321 9.49947L15 12.7124L15.6843 13.7877L21.8155 10.5753V17H23.1839V10.5749L29.3157 13.7877L30 12.7124L23.8679 9.49947L29.9999 6.28759L29.3158 5.21216L23.1839 8.42399V2H21.8155Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7049 11.876H35.0647V12.4971V21.5142H36.3451V13.8059C39.6927 17.0897 41.7673 21.5916 41.7673 26.5725C41.7673 36.6156 33.3752 44.7578 23.0238 44.7578C12.6724 44.7578 4.28034 36.6156 4.28034 26.5725C4.28034 21.1501 6.74031 16.2952 10.6238 12.9617L9.7754 12.0313C5.63146 15.5884 3 20.7762 3 26.5725C3 37.3017 11.9653 46 23.0238 46C34.0823 46 43.0476 37.3017 43.0476 26.5725C43.0476 21.3419 40.9057 16.6067 37.4422 13.1182H45V11.876H35.7049Z"
      />
    </svg>
  )
}
