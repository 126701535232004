import React from 'react'
import styled, {css, FlattenSimpleInterpolation} from 'styled-components'
import {colors} from '../../../colors'

const MSize = css`
  font-size: 1.06rem; /*17px;*/
  line-height: 1.375rem; /*22px;*/
  letter-spacing: -0.41px;
`

const SSize = css`
  font-size: 0.875rem; /*14px;*/
  line-height: 1.1875rem; /*19px;*/
  letter-spacing: -0.15px;
`

const fontSizes = {
  S: SSize,
  M: MSize
}

const TagContainer = styled.span<
  TagProps & {
    sizeStyle?: FlattenSimpleInterpolation | null
    backgroundColor: string
  }
>`
  ${props => props.sizeStyle}
  border-radius: 32px;
  padding: 6px 12px;
  text-align: center;
  width: fit-content;

  cursor: ${props => (props.styleType === 'default' || props.styleType === 'active' ? 'pointer' : 'default')};

  border: 1px solid ${props => (props.styleType === 'default' ? colors.brand.cobalt : props.backgroundColor)};

  color: ${props => (props.styleType === 'default' ? colors.brand.cobalt : colors.system.white)};
  background-color: ${props => props.backgroundColor};

  ${props =>
    props.disabled &&
    `{
    cursor: not-allowed;
    background: transparent;
    border: 1px solid ${colors.brand.cobalt};
    color: ${colors.brand.cobalt};
  }`}
`

export type TagProps = React.HTMLAttributes<HTMLSpanElement> & {
  children: React.ReactNode
  size?: 'S' | 'M'
  styleType?: 'default' | 'active' | 'success' | 'error'
  disabled?: boolean
}

export const Tag: React.FC<TagProps> = ({children, size = 'M', styleType = 'default', disabled = false, ...rest}) => {
  const sizeStyle = fontSizes[size]

  let backgroundColor = 'transparent'
  if (styleType === 'active') {
    backgroundColor = colors.brand.cobalt
  }
  if (styleType === 'success') {
    backgroundColor = colors.system.green
  } else if (styleType === 'error') {
    backgroundColor = colors.system.red
  }

  return (
    <TagContainer
      sizeStyle={sizeStyle}
      size={size}
      backgroundColor={backgroundColor}
      styleType={styleType}
      disabled={disabled}
      {...rest}
    >
      {children}
    </TagContainer>
  )
}
