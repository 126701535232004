import React from 'react'
import styled from 'styled-components'
import {H3, Overline, Caption} from '../../Atoms/Typography'
import {compact} from 'lodash'

const TripleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > *:nth-child(2) {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }
`

interface TripleComponentProps {
  subTitles?: string[]
  captions?: string[]
  title: string
}
const TripletComponent = ({subTitles, title, captions}: TripleComponentProps) => {
  return (
    <TripleContainer>
      {subTitles && subTitles.length > 0 && (
        <Overline className="category">
          {compact(subTitles).map((sub: string, index: number) => {
            return `${index === 0 ? `${sub}` : `  •  ${sub}`}`
          })}
        </Overline>
      )}
      <H3 className="name">{title}</H3>
      {captions && captions.length > 0 && (
        <Caption className="time">
          {compact(captions).map((sub: string, index: number) => {
            return `${index === 0 ? `${sub}` : `  •  ${sub}`}`
          })}
        </Caption>
      )}
    </TripleContainer>
  )
}

export default TripletComponent
