export interface GetSiteSuspendedPeriodsParams {
  siteId: string
  showLoadingIndicator: boolean
}

export interface DeleteSiteSuspendedPeriodParams {
  siteId: string
  periodId: string
}

export interface SuspendedPeriodPayload {
  startAt: string
  endAt: string
  alarmsEnabled: boolean
  reason: string
  siteId: string
}

export interface SuspendedPeriod {
  id: string
  siteId: string
  actor: string
  createdAt: Date
  startAt: Date
  endAt: Date
  alarmsEnabled: boolean
  reason: string
}

interface SiteSuspendedPeriodsState {
  suspendedPeriods: SuspendedPeriod[] | null
  error: Error | null
  loading: boolean | null
}

export const state: SiteSuspendedPeriodsState = {
  suspendedPeriods: null,
  error: null,
  loading: null
}
