import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import {Baseline} from './Grid'
import {Input, StyledTextArea} from '../Atoms/Forms'
import LoginBackground from '../../Assets/Images/kettle-background.jpg'

export const AppWrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`

export const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 10vh 1fr auto;
  grid-template-columns: 100vw;
  grid-template-areas:
    'header'
    'maincontent'
    'newsbar';
`

export const InfoMainContentContainer = styled.main`
  grid-area: maincontent;
  display: flex;
  position: relative;
  width: 100vw;
  height: 100%;
  max-height: 100vh;
`

export const InfoHeaderContainer = styled.div`
  box-sizing: border-box;
  background-color: ${colors.brand.cobalt};
  color: ${colors.system.white};
  grid-area: header;
`

export const Newsbar = styled.div`
  position: fixed;
  bottom: 0;
  height: auto;
  width: 100%;
  grid-area: newsbar;
`

export const Notificationbar = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  grid-area: notifications;
`
export const MainContentContainer = styled.main`
  grid-area: maincontent;
  height: calc(100vh - 64px);
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const NoMenuContentContainer = styled.main`
  grid-area: maincontent;
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: auto;
`

type MainContentVariants = {
  default: string
  white: string
}
const mainVariants: MainContentVariants = {
  default: colors.system.lightGrey_5,
  white: colors.system.white
}
interface MainContentProps {
  variant?: keyof MainContentVariants
}
export const MainContent = styled.div<MainContentProps>`
  background-color: ${props => (props.variant ? mainVariants[props.variant] : mainVariants['default'])};
  position: relative;
  width: 100%;
  min-width: 576px;
  padding-bottom: 4rem;
  overflow: scroll;
`
MainContent.defaultProps = {
  variant: 'default'
}

export const MainLayoutWithoutStretch = styled.div`
  display: grid;
  width: 100%;
  min-width: 576px;
  height: calc(100vh - 64px);
  grid-template-columns: 1fr minmax(460px, 336px);
  > ${Baseline} {
    padding-right: 2rem;
  }
`

export const FullScreenMainLayout = styled.div<MainContentProps>`
  background-color: ${props => (props.variant ? mainVariants[props.variant] : mainVariants['default'])};
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
`

export const RightSidebar = styled.aside`
  background-color: ${colors.system.white};
  border-left: 1px solid ${colors.system.lightGrey_5};
  height: auto;
  min-width: 336px;
  max-width: 460px;
  width: 35vw;
  /* min-height: 100%; */
  padding-bottom: 4rem;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
`
export const NarrowRightSidebar = styled.aside`
  background-color: ${colors.system.white};
  border-left: 1px solid ${colors.system.lightGrey_5};
  min-width: 336px;
  max-width: 460px;
  width: 35vw;
  height: auto;
  min-height: calc(100vh - 64px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
`

interface AdjustableContainerProps {
  height?: string
  width?: string
  padding?: string
  margin?: string
}
export const AdjustableContainer = styled.div<AdjustableContainerProps>`
  height: ${props => props.height};
  padding: ${props => props.padding};
  width: ${props => props.width};
  margin: ${props => props.margin};
`
export const Center = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`

export const LoginWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${LoginBackground});
  background-size: cover;
  background-attachment: fixed;
`

export const LoginContentContainer = styled.div`
  position: relative;
  max-width: 464px;
  margin-top: 2rem;

  @media (min-width: 600px) {
    position: relative;
    margin-left: 8vw;
    margin-top: 12vh;
  }
`

interface PaddingProps {
  p?: string
  pt?: string
  pb?: string
  pr?: string
  pl?: string
  px?: string
  py?: string
}

export const Padding = styled.div<PaddingProps>`
  padding-left: ${p => p.pl || p.p || p.px || 0};
  padding-right: ${p => p.pr || p.p || p.px || 0};
  padding-top: ${p => p.pt || p.p || p.py || 0};
  padding-bottom: ${p => p.pb || p.p || p.py || 0};
  width: 100%;
`
export const BorderedListItem = styled.div`
  border-bottom: 1px solid ${colors.system.lightGrey_5};
  ${Input} {
    border: none;
  }
  ${StyledTextArea} {
    border: none;
  }
`
