import {baseApi} from '../../state/rest'
import {InstructionsDocument, InstructionsFolder} from './state'

export const instructionsApi = {
  ...baseApi,
  async getFolders() {
    const req = await this.doRequest<InstructionsFolder[]>({path: '/v1/instructions/folders'})
    const resp = await req.get()
    return resp.data
  },
  async getDocumentFolder(id: string) {
    const req = await this.doRequest<InstructionsFolder>({path: `/v1/instructions/folders/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async createDocumentFolder(payload: Partial<InstructionsFolder>) {
    const req = await this.doRequest({path: '/v1/instructions/folders', payload})
    const resp = await req.post()
    return resp
  },
  async deleteDocumentFolder(id: string) {
    const req = await this.doRequest({path: `/v1/instructions/folders/${id}`})
    const resp = await req.delete()
    return resp
  },
  async updateDocumentFolder(payload: InstructionsFolder) {
    const req = await this.doRequest({path: `/v1/instructions/folders/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async getDocuments() {
    const req = await this.doRequest<InstructionsDocument[]>({path: '/v1/instructions/'})
    const resp = await req.get()
    return resp.data
  },
  async getDocument(id: string) {
    const req = await this.doRequest<InstructionsDocument>({path: `/v1/instructions/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async createDocument(payload: Partial<InstructionsDocument>) {
    const req = await this.doRequest({path: '/v1/instructions/', payload})
    const resp = await req.post()
    return resp
  },
  async deleteDocument(id: string) {
    const req = await this.doRequest({path: `/v1/instructions/${id}`})
    const resp = await req.delete()
    return resp
  },
  async updateDocument(payload: Partial<InstructionsDocument>) {
    const req = await this.doRequest({path: `/v1/instructions/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  }
}
