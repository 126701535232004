import {AsyncAction} from 'overmind'
import {state as defaultState} from './state'

type GeneralTasksByTaskGroupParams = {taskGroupId: string; siteId: string}
export const getGeneralTasksByTaskGroup: AsyncAction<GeneralTasksByTaskGroupParams> = async (
  {state, effects},
  params: GeneralTasksByTaskGroupParams
) => {
  state.v1.generalTasks = defaultState
  state.v1.generalTasks.pending = true
  try {
    const taskGroup = await effects.v1.generalTasks.generalTasksApi.getGeneralTasksByTaskGroup(
      params.taskGroupId,
      params.siteId
    )
    state.v1.generalTasks.data = taskGroup
  } catch (err) {
    state.v1.generalTasks.error = err
  } finally {
    state.v1.generalTasks.pending = false
  }
}
