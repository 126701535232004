import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconTemperatureCold = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1525 6.27338C11.1525 3.91303 13.0987 2 15.5 2C17.9013 2 19.8475 3.91303 19.8475 6.27338V28.367C22.9029 29.9166 25 33.0432 25 36.6619C25 41.8192 20.7468 46 15.5 46C10.2532 46 6 41.8192 6 36.6619C6 33.0432 8.09707 29.9166 11.1525 28.367V6.27338ZM15.5 3.26619C13.8101 3.26619 12.4407 4.61232 12.4407 6.27338V29.1672L12.0666 29.3369C9.24625 30.6167 7.28814 33.4118 7.28814 36.6619C7.28814 41.1199 10.9647 44.7338 15.5 44.7338C20.0353 44.7338 23.7119 41.1199 23.7119 36.6619C23.7119 33.4118 21.7538 30.6167 18.9334 29.3369L18.5593 29.1672V6.27338C18.5593 4.61232 17.1899 3.26619 15.5 3.26619ZM15.3862 33.6546C13.7166 33.6546 12.3635 35.0008 12.3635 36.6618C12.3635 38.3229 13.7166 39.669 15.3862 39.669C17.0558 39.669 18.4088 38.3229 18.4088 36.6618C18.4088 35.0008 17.0558 33.6546 15.3862 33.6546ZM16.0095 32.4331V17.9375H14.7495V32.435C12.679 32.741 11.0908 34.5167 11.0908 36.6618C11.0908 39.0222 13.0137 40.9352 15.3862 40.9352C17.7587 40.9352 19.6815 39.0222 19.6815 36.6618C19.6815 34.5121 18.0865 32.7334 16.0095 32.4331Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3611 8V13.9954L26.6386 10.998L26.0001 12.0018L31.7233 14.9995L26 17.9982L26.6386 19.0019L32.3611 16.0036V22H33.6383V16.0033L39.3614 19.0019L40 17.9982L34.2767 14.9995L39.9999 12.0018L39.3614 10.998L33.6383 13.9957V8H32.3611Z"
      />
    </svg>
  )
}
