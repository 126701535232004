import React from 'react'
import {colors} from '../../sharedComponents/colors'
import styled, {css} from 'styled-components'
import {Text} from '../../sharedComponents/components'

export type SwitchProps = React.InputHTMLAttributes<HTMLInputElement> & {}

export const checkedColors = {
  default: colors.system.green,
  reversed: colors.system.red
}

export const ColoredTogglerButton: React.FC<SwitchProps> = props => {
  return (
    <SwitchContainer>
      <SwitchInput {...props} type="checkbox"></SwitchInput>
      <SwitchToggle checked={props.checked} checkedColor={checkedColors.default}></SwitchToggle>
    </SwitchContainer>
  )
}

export const SwitchContainer = styled.label`
  display: inline-block;
  position: relative;
  width: 6rem;
  height: 2rem;
  background-color: ${colors.system.white};
  border-radius: 1rem;
  cursor: pointer;
  border-color: ${colors.system.grey_2};
  border-width: 0.5px;
  border-style: solid;
`

export const SwitchInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

export const SwitchToggle = styled.span<{checked?: boolean; checkedColor: string}>(
  ({checked, checkedColor}) => css`
    display: inline-block;
    position: absolute;
    top: 0;
    left: ${checked ? '3rem' : '0'};
    width: 3rem;
    height: 2rem;
    background-color: ${checked ? checkedColor : colors.system.grey_1};
    border-radius: 1rem;
    transition-property: left, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  `
)
