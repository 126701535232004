import Backend from 'i18next-locize-backend'
import {initReactI18next} from 'react-i18next'
import i18next from 'i18next'
import {locizePlugin} from 'locize'

const apiKey = process.env.REACT_APP_LOCIZE

const locizeEnv = process.env.REACT_APP_LOCIZE_ENV.toLowerCase()
console.log('locize env:', locizeEnv)

const locizeOptions = {
  projectId: '86390ca7-f7f2-4f80-947d-56ad36a89671',
  referenceLng: 'en',
  version: locizeEnv,
  apiKey,
  onSaved: (lng, ns) => {
    console.log('sent translations to locize', {lng, ns})
  }
}

i18next
  .use(Backend)
  .use(locizePlugin)
  .use(initReactI18next)
  .init({
    ns: ['common'],
    defaultNS: 'common',
    lng: 'en',
    fallbackLng: 'en',
    debug: apiKey !== undefined,
    // debug: true,
    saveMissing: apiKey !== undefined,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    react: {
      bindI18n: 'languageChanged editorSaved',
      wait: true
    }
  })

export default i18next
