import {Alarm, AlarmType} from './state'

export const resolveAlarmIfFound = (alarms: Alarm[], alarmId: string, resolvedAlarm: Alarm) => {
  const alarm = alarms.find(a => a.id === alarmId)
  if (alarm) {
    alarm.resolvedTime = resolvedAlarm.resolvedTime
    alarm.reason = resolvedAlarm.reason ?? ''
    alarm.resolvedBy = resolvedAlarm.resolvedBy ?? ''
    alarm.status = 'resolved'
    alarm.actor = resolvedAlarm.actor
  }
}

export const getIsBatteryAlarm = (alarm: Alarm | null) => alarm?.type === AlarmType.Battery
