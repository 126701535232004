import React, {useState, ReactNode, ReactNodeArray, KeyboardEvent, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import {BorderedListItem} from '../Layout/Layout'

type TabVariants = {
  default: string
  warn: string
}
const tabVariants: TabVariants = {
  default: colors.brand.cobalt,
  warn: colors.system.red
}
type Tab = {
  content: string | ReactNode
}
interface TabNavigatorProps {
  tabs: Tab[]
  content: ReactNodeArray
  headings?: ReactNodeArray
  selectedIndex?: number
  variant?: keyof TabVariants
  onChange?: () => void
}
interface StyledTabNavigatorProps {
  variant?: keyof TabVariants
}
const StyledTabNavigator = styled.div<StyledTabNavigatorProps>`
  position: relative;
  ul.tabs {
    display: flex;
    justify-content: space-around;
  }
  ul.tabs li {
    list-style: none;
    width: 100%;
  }
  ul.tabs li a {
    color: ${colors.brand.cobalt};
    text-decoration: none;
    width: 100%;
    display: block;
    text-align: center;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
  }
  section[aria-hidden='true'] {
    display: none;
  }
  ul.tabs li a[aria-selected='true'] {
    border-bottom: 1px solid ${props => (props.variant ? tabVariants[props.variant] : tabVariants['default'])};
  }
  ul.tabs li a[aria-selected='false'] {
    opacity: 0.5;
  }
  ul.tabs li a:focus {
    outline: none;
  }
`

const TabNavigator = ({tabs, selectedIndex = 0, headings, content, variant, onChange}: TabNavigatorProps) => {
  const [tabIndex, setTabIndex] = useState(selectedIndex)
  const tabsRef = useRef<HTMLUListElement>(null)

  const handleKey = (e: KeyboardEvent<HTMLUListElement>) => {
    e.preventDefault()

    switch (e.key) {
      case 'ArrowDown':
        goForward()
        break
      case 'ArrowUp':
        goBack()
        break
      case 'ArrowLeft':
        goBack()
        break
      case 'ArrowRight':
        goForward()
        break
      default:
        break
    }
  }

  const goBack = () => {
    if (tabIndex > 0) {
      setTabIndex(tabIndex - 1)
    }
  }
  const goForward = () => {
    const len = tabs.length
    if (tabIndex < len - 1) {
      setTabIndex(tabIndex + 1)
    }
  }
  useEffect(() => {
    const liElements = tabsRef.current!.childNodes as NodeListOf<HTMLLIElement>
    try {
      const aEl = liElements[tabIndex].firstChild as HTMLLinkElement
      aEl!.focus()
    } catch {
      console.warn('could not focus')
    }
  }, [tabIndex])

  useEffect(() => {
    setTabIndex(selectedIndex)
  }, [selectedIndex])
  const handleTabChange = (index: number) => {
    setTabIndex(index)
    if (onChange) {
      onChange()
    }
  }
  return (
    <StyledTabNavigator variant={variant}>
      {headings ? headings[tabIndex] : null}

      <BorderedListItem>
        <ul className="tabs" onKeyUp={e => handleKey(e)} ref={tabsRef} role="tablist">
          {tabs.map((tab, index) => (
            <li role="presentation" key={`#tab_${index}`}>
              <a role="tab" aria-selected={tabIndex === index} href={`#tab_`} onClick={() => handleTabChange(index)}>
                {tab.content}
              </a>
            </li>
          ))}
        </ul>
      </BorderedListItem>

      {content.map((item, index) => (
        <section
          role="tabpanel"
          aria-labelledby={`$tab_${index}`}
          key={`$tab_${index}`}
          id={`$tab_${index}`}
          aria-hidden={index !== tabIndex}
        >
          {item}
        </section>
      ))}
    </StyledTabNavigator>
  )
}
TabNavigator.defaultProps = {
  variant: 'default'
}

export default TabNavigator
