import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconPackaging = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20257 22.1735L4.61304 34.0867L7.20257 46H40.7992L43.399 34.0867L40.8108 22.1735H7.20257ZM5.94734 34.0868L8.25087 23.4463H39.7626L42.0662 34.0868L39.7626 44.7272H8.25087L5.94734 34.0868Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 38.719C21.7353 38.719 19.8588 36.9766 19.5482 34.7225H2V33.4497H20.8035V34.0861C20.8035 35.9444 22.24 37.4462 24 37.4462C25.76 37.4462 27.1965 35.9329 27.1965 34.0861V33.4497H46V34.7225H28.4518C28.1412 36.9766 26.2647 38.719 24 38.719Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5183 19.6535L23.483 18.8898C26.3805 15.046 25.0346 13.2641 23.483 11.2022C21.8511 9.05122 20.0147 6.60748 23.483 2L24.5183 2.76367C21.6194 6.60748 22.9653 8.38937 24.5183 10.4513C26.1489 12.615 27.9865 15.0588 24.5183 19.6535Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4229 19.6535L28.3863 18.8898C31.2851 15.046 29.9392 13.2641 28.3863 11.2022C26.7686 9.05122 24.9194 6.60748 28.3863 2L29.4229 2.76367C26.5241 6.60748 27.8699 8.38937 29.4229 10.4513C31.0405 12.615 32.8911 15.0588 29.4229 19.6535Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9883 19.6535L18.953 18.8898C21.8519 15.046 20.506 13.2641 18.953 11.2022C17.3224 9.05122 15.4848 6.60748 18.953 2L19.9883 2.76367C17.0895 6.60748 18.4354 8.38937 19.9883 10.4513C21.606 12.615 23.4566 15.0588 19.9883 19.6535Z"
      />
    </svg>
  )
}
