import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconFolder = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63 2H2V2.63V45.3705V46.0005H2.63H45.3705H46.0005V45.3705V7.6583V7.0283H45.3705H19.2329L14.3891 2.18452L14.2046 2H13.9437H2.63ZM3.26 15.8286V3.26H13.6827L18.5265 8.10377L18.711 8.2883H18.972H44.7405V15.8713L3.26 15.8286ZM3.26 17.0886V44.7405H44.7405V17.1313L3.26 17.0886Z"
      />
    </svg>
  )
}
