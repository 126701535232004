import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAppState} from '../../../state'
import {Site} from '../../../state/state'
import styled from 'styled-components'
import {LabelRadio} from '../../Atoms/Forms'
import {P} from '../../Atoms/Typography'
import {Baseline} from '../../Layout/Grid'

const LocationList = styled.ul`
  margin-top: 4rem;
  padding: 1rem;
`

interface Props {
  controller: any
}
const LocationSelect = ({controller}: Props) => {
  const {me} = useAppState().state
  const {t} = useTranslation('settings')
  const rc = controller

  return me ? (
    <LocationList>
      <Baseline margin="1rem">
        <P variant="small">{t('settings:appliances.selectAppliaceSite', 'Select site of the appliance')}</P>
        <Baseline margin="0.5rem">
          {rc.sites.map((site: Site, index: number) => (
            <LabelRadio
              label={site.name}
              name="siteChoices"
              value={site.id}
              defaultChecked={site.id === controller.selectedSite.id}
              id={`${site.name}_${index}`}
              key={`${site.name}_${index}`}
              ref={rc.register}
            />
          ))}
        </Baseline>
      </Baseline>
    </LocationList>
  ) : (
    <div>{t('settings:appliances.noAccess', 'no access')}</div>
  )
}

export default LocationSelect
