import React from 'react'
import {
  isToday,
  isYesterday,
  format,
  differenceInMinutes,
  differenceInSeconds,
  differenceInDays,
  isAfter,
  isBefore,
  parseISO,
  formatISO,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  formatDistanceStrict
} from 'date-fns'
import fi from 'date-fns/esm/locale/fi'
import sv from 'date-fns/esm/locale/sv'
import enGB from 'date-fns/esm/locale/en-GB'
import enUS from 'date-fns/esm/locale/en-US'
import plPL from 'date-fns/esm/locale/pl'
import i18n from 'i18next'
import {orderBy} from 'lodash'
import {localNumberFormat} from '../../config/utils'
import {UserLanguage} from '../../state/rest'

enum SupportedLocale {
  fi_FI = 'fi-FI',
  sv_SE = 'sv-SE',
  en_GB = 'en-GB',
  en_US = 'en-US',
  pl_PL = 'pl-PL'
}

export function getLocale(locale?: string): Locale {
  const chefsteinLocales: {[key: string]: Locale} = {
    [SupportedLocale.fi_FI]: fi,
    [SupportedLocale.sv_SE]: sv,
    [SupportedLocale.en_GB]: enGB,
    [SupportedLocale.pl_PL]: plPL
  }
  return !!locale && chefsteinLocales.hasOwnProperty(locale) ? chefsteinLocales[locale] : enUS
}
interface TimeLabelProps {
  date: Date
  locale: string
}
export const TimeLabel = ({date, locale}: TimeLabelProps) => {
  if (isToday(date)) {
    return (
      <span>{`${i18n.t('common:dateNames.today', 'Today')} ${format(date, 'p', {locale: getLocale(locale)})}`}</span>
    )
  }
  return <span>{`${format(date, 'P', {locale: getLocale(locale)})}`}</span>
}

export const getHumanizedDate = (date: Date, locale: string) => {
  try {
    if (isToday(date)) {
      return `${i18n.t('common:dateNames.today', 'Today')} ${format(date, 'p', {locale: getLocale(locale)})}`
    }
    if (isYesterday(date)) {
      return `${i18n.t('common:dateNames.yesterday', 'Yesterday')} ${format(date, 'p', {locale: getLocale(locale)})}`
    }
    return format(date, 'P', {locale: getLocale(locale)})
  } catch (e) {
    return ''
  }
}

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

export const getHumanizedDateWithTime = (date: Date, locale: string) => {
  try {
    if (isToday(date)) {
      return `${i18n.t('common:dateNames.today', 'Today')} ${format(date, 'p', {locale: getLocale(locale)})}`
    }
    if (isYesterday(date)) {
      return `${i18n.t('common:dateNames.yesterday', 'Yesterday')} ${format(date, 'p', {locale: getLocale(locale)})}`
    }
    return format(date, 'P p', {locale: getLocale(locale)})
  } catch (e) {
    return ''
  }
}

export const getHumanizedDateString = (date: Date, locale: string) => {
  return format(date, 'P', {locale: getLocale(locale)})
}

export const getHumanizedTimeString = (date: Date, locale: string) => {
  return format(date, 'p', {locale: getLocale(locale)})
}

export const getHumanizedWeekString = (date: Date) => {
  if (isMonday(date)) return i18n.t('common:dateNames.full.monday', 'Monday')
  if (isTuesday(date)) return i18n.t('common:dateNames.full.tuesday', 'Tuesday')
  if (isWednesday(date)) return i18n.t('common:dateNames.full.wednesday', 'Wednesday')
  if (isThursday(date)) return i18n.t('common:dateNames.full.thursday', 'Thursday')
  if (isFriday(date)) return i18n.t('common:dateNames.full.friday', 'Friday')
  if (isSaturday(date)) return i18n.t('common:dateNames.full.saturday', 'Saturday')
  if (isSunday(date)) return i18n.t('common:dateNames.full.sunday', 'Sunday')
}

export const getHumanizedWeekdays = (locale: string) => {
  let currentLocale = getLocale(locale)
  const weekdays = [
    {
      id: 0,
      short: i18n.t('common:dateNames.short.monday', 'Mon'),
      long: i18n.t('common:dateNames.long.monday', 'Monday'),
      order: 0
    },
    {
      id: 1,
      short: i18n.t('common:dateNames.short.tuesday', 'Tue'),
      long: i18n.t('common:dateNames.long.tuesday', 'Tuesday'),
      order: 1
    },
    {
      id: 2,
      short: i18n.t('common:dateNames.short.wednesday', 'Wed'),
      long: i18n.t('common:dateNames.long.wednesday', 'Wednesday'),
      order: 2
    },
    {
      id: 3,
      short: i18n.t('common:dateNames.short.thursday', 'Thu'),
      long: i18n.t('common:dateNames.long.thursday', 'Thursday'),
      order: 3
    },
    {
      id: 4,
      short: i18n.t('common:dateNames.short.friday', 'Fri'),
      long: i18n.t('common:dateNames.long.friday', 'Friday'),
      order: 4
    },
    {
      id: 5,
      short: i18n.t('common:dateNames.short.saturday', 'Sat'),
      long: i18n.t('common:dateNames.long.saturday', 'Saturday'),
      order: 5
    },
    {
      id: 6,
      short: i18n.t('common:dateNames.short.sunday', 'Sun'),
      long: i18n.t('common:dateNames.long.sunday', 'Sunday'),
      order: 6
    }
  ]
  if (!!currentLocale) {
    return orderBy(
      weekdays.map((d, i) => {
        if (currentLocale.options!.weekStartsOn! === 0) {
          // Week starts on sunday
          if (d.order === 6) {
            return {...d, order: 0}
          } else return {...d, order: i + 1}
        } else {
          return {...d}
        }
      }),
      'order',
      'asc'
    )
  }
  return weekdays
}

const LANGUAGE_TO_LOCALE_MAP: {[key: string]: string} = {
  [UserLanguage.fi]: SupportedLocale.fi_FI,
  [UserLanguage.sv]: SupportedLocale.sv_SE,
  [UserLanguage.en]: SupportedLocale.en_GB,
  [UserLanguage.pl]: SupportedLocale.pl_PL
}

export const getDatetimeAgo = (date: Date, language: string) => {
  const locale = LANGUAGE_TO_LOCALE_MAP[language] ?? language
  return formatDistanceStrict(date, new Date(), {locale: getLocale(locale), addSuffix: true})
}

export const getDuration = (
  startDate: Date,
  endDate: Date,
  language: string,
  options?: {
    addSuffix?: boolean
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
    roundingMethod?: 'floor' | 'ceil' | 'round'
  }
) => {
  const locale = LANGUAGE_TO_LOCALE_MAP[language] ?? language
  return formatDistanceStrict(startDate, endDate, {locale: getLocale(locale), ...options})
}

export const minutesToHMM = (mins: number) => {
  let h = Math.floor(mins / 60)
  let m = mins % 60
  let mString = m < 10 ? '0' + m : m
  return `${h}:${mString}`
}

export const minutesToTimeObject = (mins: number) => {
  let d = Math.floor(mins / (60 * 24))
  let h = Math.floor((mins - d * (60 * 24)) / 60)
  let m = mins % 60
  return {
    days: d,
    hours: h,
    minutes: m
  }
}

export const minutesToDurationString = (mins: number, short?: boolean) => {
  const time = minutesToTimeObject(mins)
  const timeStringParts = []

  if (time.days) timeStringParts.push(`${time.days} ${i18n.t('common:dateNames.tiny.day', 'd')}`)
  if (time.hours) timeStringParts.push(`${time.hours} ${i18n.t('common:dateNames.tiny.hour', 'h')}`)
  if (time.minutes) timeStringParts.push(`${time.minutes} ${i18n.t('common:dateNames.tiny.min', 'min')}`)

  if (time.days === 0 && time.hours === 0 && time.minutes === 0) {
    timeStringParts.push(`0 ${i18n.t('common:dateNames.tiny.min', 'min')}`)
  }

  if (short && timeStringParts.length === 3) {
    return `${timeStringParts[0]}  ${timeStringParts[1]}`
  }
  return timeStringParts.join(' ')
}

export const roundMinutes = (minuteStep: number, date: Date) => {
  let ms = 1000 * 60 * minuteStep // convert minutes to ms
  return new Date(Math.round(date.getTime() / ms) * ms)
}

export const getCompletedTimePercent = (current: Date, start: Date, target: Date) => {
  if (isAfter(current, target)) {
    return 100 // target already reached
  }
  if (isBefore(current, start)) {
    return 0 // tracking not started yet
  }
  // calculate completed percentage
  const totalDifference = differenceInSeconds(target, start)
  const remainingDifference = differenceInSeconds(target, current)
  return (1 - remainingDifference / totalDifference) * 100
}

export const getUsedTime = (current: Date, start: Date) => {
  if (isBefore(current, start)) {
    return '0:00' // tracking not started yet
  }
  return minutesToHMM(differenceInMinutes(current, start))
}

export const isOverTime = (current: Date, target: Date) => {
  return isAfter(current, target)
}

export const getTimeDifference = (start: Date, end: Date) => {
  return differenceInMinutes(end, start)
}

export const getTargetLimits = (locale: string, minValue?: number, maxValue?: number, unit?: String) => {
  unit = unit ? unit : ''
  if (!unit.startsWith('°')) {
    unit = ' ' + unit
  } // e.g. display units like '5°C' but '5 KG'
  // TODO! figure better way to get target limits
  if (minValue !== undefined && maxValue !== undefined)
    return `${localNumberFormat(locale, minValue) as string}–${localNumberFormat(locale, maxValue) as string}${unit}`
  if (minValue !== undefined)
    return `${i18n.t('common:labels.over', 'Over')} ${localNumberFormat(locale, minValue) as string}${unit}`
  if (maxValue !== undefined)
    return `${i18n.t('common:labels.under', 'Under')} ${localNumberFormat(locale, maxValue) as string}${unit}`
  return ``
}

export const getFromToTimeString = (from: Date, to: Date, locale: string) => {
  const loc = getLocale(locale)
  if (differenceInDays(from, to) <= 0) {
    return `${format(from, 'p', {locale: loc})}–${format(to, 'p', {locale: loc})} `
  }
  return `${format(from, 'P', {locale: loc})}–${format(from, 'P', {locale: loc})}`
}

// when user selects 23:59:00 as the ending time, function interprets as 24:00:00 to avoid gap between end of date
export const getAlarmEndTime = (time: Date) => {
  const date = getISOTimeFromDate(time)
  return date === '23:59:00' ? '24:00:00' : date
}

export const getAlarmEndTimeView = (time: string) => (time === '24:00:00' ? '23:59:00' : time)

export const parseISOTimeToDate = (timeISO: string, date?: Date) => {
  return parseISO(`${formatISO(date ? date : new Date(), {representation: 'date'})}T${timeISO}`)
}

export const getISOTimeFromDate = (date: Date) => {
  return format(date, 'HH:mm:ss')
}

export const parseStringToDate = (dateString: string) => {
  // safari only accept 2017-01-22T11:57:00 instead of 2017-01-22 11:57:00
  return new Date(dateString.replace(/ /g, 'T'))
}

//Quicksight related functions

export const getLanguageToLocale = (language: string | undefined) => {
  switch (language) {
    case 'en':
      return 'en-US'
    case 'sv':
      return 'sv-SE'
    case 'fi':
      return 'fi-FI'
    default:
      return 'en-US'
  }
}

export const transformLanguageObjToParameterObj = (languageObject: any) => {
  const result = []

  for (const [key, value] of Object.entries(languageObject)) {
    const parameterObject = {
      Name: key,
      Values: [value as string]
    }

    result.push(parameterObject)
  }

  return result
}

export const addFilterChainName = (parameters: any, chainName: string) => {
  const parameterObject = {
    Name: 'chainName',
    Values: [chainName]
  }

  parameters.push(parameterObject)

  return parameters
}
