import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconChain = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4599 2.18597L23.1448 2L22.8314 2.18888L13.6185 7.74166L13.3227 7.91992V8.26469V21.6538V22.2649H13.9352H20.1425H20.755V21.6538V14.055H25.7374V21.6538V22.2649H26.3499H32.5572H33.1697V21.6538V8.26469V7.91618L32.869 7.73875L23.4599 2.18597ZM14.5477 8.60945L23.1514 3.42382L31.9447 8.6132V21.0428H26.9623V13.4439V12.8329H26.3499H20.1425H19.5301V13.4439V21.0428H14.5477V8.60945ZM11.822 25.7351L12.1371 25.9211L21.5463 31.4739L21.8469 31.6513V31.9998V45.3889V46H21.2345H15.0271H14.4147V45.3889V37.7901H9.43226V45.3889V46H8.8198H2.61246H2V45.3889V31.9998V31.655L2.29577 31.4768L11.5086 25.924L11.822 25.7351ZM11.8286 27.1589L3.22492 32.3446V44.7779H8.20734V37.1791V36.568H8.8198H15.0271H15.6396V37.1791V44.7779H20.622V32.3483L11.8286 27.1589ZM35.9751 25.7351L36.2902 25.9211L45.6993 31.4739L46 31.6513V31.9998V45.3889V46H45.3875H39.1802H38.5677V45.3889V37.7901H33.5853V45.3889V46H32.9729H26.7655H26.1531V45.3889V31.9998V31.655L26.4488 31.4768L35.6617 25.924L35.9751 25.7351ZM35.9817 27.1589L27.378 32.3446V44.7779H32.3604V37.1791V36.568H32.9729H39.1802H39.7927V37.1791V44.7779H44.7751V32.3483L35.9817 27.1589Z"
      />
    </svg>
  )
}
