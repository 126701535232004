import React from 'react'
import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import {minutesToDurationString} from '../../Components/Atoms/Utils'
import {Alarm} from '../../state/alarms/state'
import {dateValueFormatter, limitFormatter, Units} from './formatters'
import {TFunction} from 'i18next'
import {EMPTY_VALUE_TEXT, Text} from '../../sharedComponents/components'

const ResolvedAlarmExpandedRowContainer = styled.div`
  display: grid;
  margin: 1rem 0;
  grid-template-columns: 4% 51.7% 25%;
  grid-template-rows: 3.5rem auto;

  grid-template-areas:
    '. reason   resolvedTime  limit'
    '. note     duration      target';
`

const TitleValueLabelColumn = ({title, value}: {title: string; value: string}) => (
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Text size="XS" color={colors.system.grey_50}>
      {title}
    </Text>
    <Text size="S">{value}</Text>
  </div>
)

interface ResolvedAlarmExpandedRowProps {
  t: TFunction
  alarm: Alarm
  locale: string
  units: Units
}

export const ResolvedAlarmExpandedRow: React.FC<ResolvedAlarmExpandedRowProps> = ({t, alarm, locale, units}) => {
  return (
    <ResolvedAlarmExpandedRowContainer>
      <div style={{gridArea: 'reason'}}>
        <TitleValueLabelColumn title={`${t('alarms:label.details.reason', 'Reason')}`} value={alarm.reason} />
      </div>
      <div style={{gridArea: 'note'}}>
        <TitleValueLabelColumn
          title={`${t('alarms:label.details.note', 'Note')}`}
          value={alarm.note || EMPTY_VALUE_TEXT}
        />
      </div>
      <div style={{gridArea: 'duration'}}>
        <TitleValueLabelColumn
          title={`${t('alarms:label.details.duration', 'Duration')}`}
          value={!!alarm.duration ? minutesToDurationString(alarm.duration, true) : EMPTY_VALUE_TEXT}
        />
      </div>
      <div style={{gridArea: 'resolvedTime'}}>
        <TitleValueLabelColumn
          title={`${t('alarms:label.details.resolvedTime', 'Resolved time')}`}
          value={dateValueFormatter(alarm.resolvedTime, locale)}
        />
      </div>
      {(alarm.type as any) !== 'calibration' ? (
        <div style={{gridArea: 'limit'}}>
          <TitleValueLabelColumn
            title={`${t('alarms:label.details.limit', 'Limit')}`}
            value={limitFormatter(t, alarm, units)}
          />
        </div>
      ) : (
        <div style={{gridArea: 'limit'}}>
          <TitleValueLabelColumn
            title={`${t('alarms:label.details.targetDay', 'Target day')}`}
            value={dateValueFormatter(alarm.targetDay, locale)}
          />
        </div>
      )}
      {alarm.measurementTarget ? (
        <div style={{gridArea: 'target'}}>
          <TitleValueLabelColumn
            title={`${t('alarms:label.details.target', 'Target')}`}
            value={alarm.measurementTarget}
          />
        </div>
      ) : (
        <></>
      )}
    </ResolvedAlarmExpandedRowContainer>
  )
}
