import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconCheck = (props: SharedIconProps) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80276 13.7566L19.1857 0.5L20 1.3717L6.80276 15.5L0 8.21731L0.814259 7.3456L6.80276 13.7566Z"
        fill={props.fill || colors.brand.cobalt}
      />
    </svg>
  )
}
