import React from 'react'
import ReactSelect from 'react-select'
import makeAnimated from 'react-select/animated'

export type SelectOption = {
  label: string
  value: string
}

export interface SelectProps {
  options: SelectOption[]
  value?: SelectOption[]
  onChange: (selectedOptions: any) => void // Called when select one or more options
  defaultValue?: SelectOption[] // Initial selected option
  multiple?: boolean
  placeholder?: string // placeholder text in the input field
  showArrow?: boolean // Whether to show the drop-down arrow
  disabled?: boolean
  id?: string // The id of the search input
}

const animatedComponents = makeAnimated()

export const Select: React.FC<SelectProps> = ({
  options,
  multiple,
  placeholder,
  value,
  onChange,
  disabled,
  id,
  ...rest
}) => (
  <ReactSelect
    inputId={id}
    options={options}
    components={animatedComponents}
    isMulti={Boolean(multiple)}
    placeholder={placeholder}
    closeMenuOnSelect={!Boolean(multiple)}
    value={value}
    onChange={onChange}
    isDisabled={disabled}
    {...rest}
  />
)
