import React, {useState} from 'react'
import _ from 'lodash'
import {ValueInputWithRange, IconText, Icon, Text} from '../../../sharedComponents/components'
import {colors} from '../../../sharedComponents/colors'

import InputWithTags from './InputWithTags'
import {useTranslation} from 'react-i18next'
import PerformTaskForm from './PerformTaskForm'
import PerformTaskTitle from './PerformTaskTitle'
import {handleFloatInput, handleWeightValue} from '../../../config/utils'
import {useAppState} from '../../../state'
import {getWasteUnit, WasteTaskType} from '../../../Components/Views/Tasks/CreateWasteTask'
import {EventStatusCode, PerformableTask} from '../../../state/performTask/state'
import {FormContainer} from './PerformCoolerCoolingTask'

const WASTE_AMOUNT_MISTAKE_THRESHOLD_IN_KG = 100

interface PerformWasteTaskProps {
  task: PerformableTask
  handleNumberBlur: (event: any) => void
  handleRemove: () => void
  setValue: any
  onAssetSaved: () => void
  payload: any
}

const PerformWasteTask = ({
  task,
  setValue,
  onAssetSaved,
  payload,
  handleNumberBlur,
  handleRemove
}: PerformWasteTaskProps) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const [isRequired, setIsRequired] = useState(true)
  const handleRemoveTask = () => {
    setIsRequired(false)
    if (handleRemove) {
      handleRemove()
    }
  }

  const isWasteAmountTooMuch =
    (task.wasteTaskType === WasteTaskType.WasteAmount || task.wasteTaskType === WasteTaskType.ProductionAmount) &&
    _.toNumber(payload.value) >= WASTE_AMOUNT_MISTAKE_THRESHOLD_IN_KG

  return (
    <FormContainer>
      <PerformTaskTitle task={task} />
      <ValueInputWithRange
        name="value-input"
        value={payload.value}
        defaultValue={task.value}
        label={t('tasks:labels.measuredValue', 'Measured value')}
        onChange={e => setValue('value', handleFloatInput(e.target.value))}
        onBlur={e => handleNumberBlur(e.target.value)}
        unit={getWasteUnit(state.site!, task.wasteTaskType as any).toLowerCase()}
        maxValue={handleWeightValue(state.site!, task.maxValue)}
        message={{
          success: t('tasks:inputWithRange.withinTarget', 'The value is within target limits'),
          error: t('tasks:inputWithRange.not.withInTarget', 'The value is not within target')
        }}
        reminder={
          isWasteAmountTooMuch && (
            <IconText
              icon={<Icon type="exclamation-circle" fill={colors.system.red} width="1rem" height="1rem" />}
              text={
                <Text size="S" color={colors.system.red}>
                  {t(
                    'tasks:labels.wasteTooMuch.reminder',
                    `Do you mean {{currentValue}} g? That's {{suggestValue}} kg`,
                    {currentValue: payload.value, suggestValue: _.toNumber(payload.value) / 1000}
                  )}
                </Text>
              }
            />
          )
        }
        required={isRequired}
      />
      {task.wasteTaskType === WasteTaskType.WasteAmount && (
        <>
          <InputWithTags
            savedSuggestions={payload.ingredients}
            defaultSuggestions={task.ingredientSuggestions || []}
            onChange={setValue}
            keyName="ingredients"
          />
          <InputWithTags
            label={t('tasks:labels.wasteReason', 'Enter the reason for waste')}
            defaultSuggestions={task.defaultSuggestions || []}
            onChange={setValue}
          />
        </>
      )}
      <PerformTaskForm
        onInputChange={setValue}
        onAssetSaved={onAssetSaved}
        payload={payload}
        handleRemove={
          !!task.scheduleInstance || task.status === EventStatusCode.IN_PROGRESS ? handleRemoveTask : undefined
        }
        setValue={setValue}
        confirm={
          task.scheduleInstance || task.status === EventStatusCode.IN_PROGRESS
            ? t(
                'tasks:labels.confirmRemoveTask',
                'Are you sure you want to delete this task? This will also remove any associated resolved alarms.'
              )
            : undefined
        }
      />
    </FormContainer>
  )
}

export default PerformWasteTask
