import React, {ReactNode} from 'react'
import styled from 'styled-components'

const IconListItemWrapper = styled.div<{bgColor?: string}>`
  display: grid;
  width: 100%;
  grid-template-columns: 60px 1fr;
  background-color: ${p => p.bgColor || 'white'};
  padding: 0.25rem 0.5rem;

  margin-bottom: 3px;
  cursor: ${(props: any) => (!!props.onClick ? 'pointer' : 'disabled')};
  .icon {
    align-self: center;
    display: flex;
  }
  .content {
    align-self: center;
    > * {
      margin-top: 0.15rem;
      margin-bottom: 0.15rem;
    }
  }
`

interface IconListItemProps {
  icon: ReactNode
  content: ReactNode
  onClick?: () => void
  loading?: boolean
  bgColor?: string
}
export const IconListItem = (p: IconListItemProps) => {
  return (
    <IconListItemWrapper
      bgColor={p.bgColor}
      style={p.loading ? {opacity: 0.5, cursor: p.loading ? 'progress' : 'pointer'} : {}}
      onClick={p.onClick ? () => p.onClick!() : undefined}
    >
      <div className="icon">{p.icon}</div>
      <div className="content">{p.content}</div>
    </IconListItemWrapper>
  )
}
