import {baseApi, SensorMetadata} from '../rest'
import {Appliance, ApplianceStatus, CoolingCycle, MeasurementType, SensorRecordResponse} from './state'
import {Alarm} from '../alarms/state'

export const appliancesApi = {
  ...baseApi,
  async getAppliancesForSite(siteId: string): Promise<Appliance[]> {
    const request = await this.doRequest<Appliance[]>({
      path: '/v2/appliances',
      site: siteId
    })
    const response = await request.get()
    return (response as unknown) as Appliance[]
  },
  async getAlarmsForSensor(applianceId: string, sensorRole: string, siteId: string): Promise<Alarm[]> {
    const request = await this.doRequest<Alarm[]>({
      path: `/v1/appliances/${applianceId}/alarms?sensorRole=${sensorRole}`,
      site: siteId
    })
    const response = await request.get()
    return response.data
  },
  async getMetadataForSensor(sensorUuid: string, siteId: string): Promise<SensorMetadata[]> {
    const request = await this.doRequest<SensorMetadata[]>({
      path: `/v1/sensors/${sensorUuid}/metadata`,
      site: siteId
    })
    const response = await request.get()
    return response.data
  },
  async getStatusForAppliance(applianceId: string): Promise<ApplianceStatus[]> {
    const request = await this.doRequest<ApplianceStatus[]>({
      path: `/v1/appliances/${applianceId}/status`
    })
    const response = await request.get()
    return response.data
  },
  async putStatusForAppliance(
    applianceId: string,
    suspended: boolean,
    updatedBy?: string,
    reason?: string
  ): Promise<ApplianceStatus[]> {
    const request = await this.doRequest<ApplianceStatus[]>({
      path: `/v1/appliances/${applianceId}/status`,
      payload: {suspended, updatedBy, reason}
    })
    const response = await request.put()
    return response.data
  },
  async getCoolingCyclesForAppliance(
    applianceId: string,
    offset: number,
    limit: number
  ): Promise<{data: CoolingCycle[]; count?: number}> {
    const request = await this.doRequest<CoolingCycle[]>({
      path: `/v1/appliances/${applianceId}/coolingCycles?offset=${offset}&limit=${limit}`
    })
    const response = await request.get()
    return response
  },
  async getSensorRecordsForAppliance(
    applianceId: string,
    siteId: string,
    from: string,
    to: string,
    measurementType: MeasurementType,
    sensorRole?: string,
    maxCount?: number
  ): Promise<SensorRecordResponse> {
    const sensorRoleParameter = sensorRole ? `&role=${sensorRole}` : ''
    const maxCountParameter = maxCount && maxCount > 0 ? `&maxCount=${maxCount}` : ''
    const request = await this.doRequest<SensorRecordResponse>({
      path: `/v2/appliances/${applianceId}/records?from=${from}&to=${to}&measurementType=${measurementType}${sensorRoleParameter}${maxCountParameter}`,
      site: siteId
    })
    const response = await request.get()
    return (response as unknown) as SensorRecordResponse
  }
}
