import React, {ReactNode, useState, useEffect, useCallback} from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../../../sharedComponents/colors'
import tiny from 'tinycolor2'

type anchorModes = {
  right: string
  left: string
}

interface ModalProps {
  anchorMode: keyof anchorModes
}
const ModalContainer = styled.div<ModalProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;

  ${props =>
    props.anchorMode === 'right' &&
    css`
      right: 0;
    `}

  ${props =>
    props.anchorMode === 'left' &&
    css`
      left: 0;
    `}
`

const ModalWrapper = styled.div`
  max-width: 80vw;
  max-height: 80vh;
  background-color: white;
  box-shadow: 0 2px 6px 2px rgba(129, 129, 129, 0.5);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
`

const ModalContent = styled.div`
  padding: 2rem;

  hr {
    border: transparent;
    border-bottom: 1px solid
      ${tiny(colors.system.lightGrey_5)
        .darken(15)
        .toHexString()};
  }
`

export const TinyModalContent = styled(ModalContent)`
  min-width: 288px;
  padding: 1rem;
`

interface ChildProps {
  children: ReactNode
  isOpen: boolean
  onClose?: () => void
  order?: number
  anchorMode?: keyof anchorModes
}

export default function AnchorModal({isOpen, onClose, children, order, anchorMode = 'left'}: ChildProps) {
  const [modalIsOpen, setIsOpen] = useState(isOpen)

  const closeModal = useCallback(() => {
    setIsOpen(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  useEffect(() => {
    // close modal if user clicks outside it
    function handleClick() {
      if (isOpen === true) {
        closeModal()
      }
    }
    window.addEventListener('click', handleClick)

    return () => {
      return window.removeEventListener('click', handleClick)
    }
  }, [isOpen, closeModal, openModal])

  useEffect(() => {
    if (isOpen === true) {
      openModal()
    } else {
      closeModal()
    }
    return () => {
      // unmount
    }
  }, [isOpen, closeModal, openModal])

  return modalIsOpen ? (
    <ModalContainer
      anchorMode={anchorMode}
      style={{zIndex: order ? 5000 - order : 5000}}
      onClick={event => event.stopPropagation()}
    >
      <ModalWrapper>{children}</ModalWrapper>
    </ModalContainer>
  ) : null
}
