import React from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../state'
import {
  AnyTaskData,
  CoolingTaskResponse,
  EquipmentTemperatureTaskResponse,
  HygieneTaskResponse,
  ManualTaskResponse,
  SingleChoiceTaskResponse,
  TaskTypeKey,
  TemperatureTaskResponse,
  TodoTaskResponse,
  WasteTaskResponse
} from '../../../state/rest'
import {BorderedListItem, MainContent, MainLayoutWithoutStretch} from '../../Layout/Layout'
import {GoBackHeaderTitle, HeaderRow} from '../../Molecules/ViewComponents/ViewHeader'
import SettingsListItem from '../Settings/SettingsListItem'
import CreateCoolingTask from './CreateCoolingTask'
import CreateEquipmentTemperatureTask from './CreateEquipmentTemperatureTask'
import CreateHygieneTask from './CreateHygieneTask'
import CreateManualTask from './CreateManualTask'
import CreateSingleChoiceTask from './CreateSingleChoiceTask'
import CreateTemperatureTask from './CreateTemperatureTask'
import CreateTodoTask from './CreateTodoTask'
import CreateWasteTask from './CreateWasteTask'
import {Helmet} from 'react-navi-helmet-async'
import {IconPalette, SharedIcon} from '../../../sharedComponents/components'

const taskIcons: {[key in TaskTypeKey]: IconPalette} = {
  temperature: IconPalette.Temperature,
  hygiene: IconPalette.SurfaceHygiene,
  todo: IconPalette.Checklist,
  waste: IconPalette.Waste,
  cooling: IconPalette.Cooling,
  automaticCooling: IconPalette.Cooling,
  manual: IconPalette.Manual,
  singlechoice: IconPalette.TaskList,
  equipmentTemperature: IconPalette.Fridge
}

export interface TaskForm {
  name: string
  instructions: string
  description: string
  id: string
  defaultSuggestions?: string
  measurementMethodId?: string
  taskType: {
    id: string
    name: string
  }
}

export const SelectTaskType = () => {
  const {state} = useAppState()
  const nav = useNavigation()
  const {t} = useTranslation('tasks')

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.createTaskSelectType', 'Create new task - select type')} />
      <MainContent variant="white">
        <BorderedListItem>
          <HeaderRow>
            <GoBackHeaderTitle
              label={t('tasks:labels.selectTaskTypeTitle', 'Select task type')}
              path={'/edit/tasks'}
              backLabel={t('tasks:labels.taskSettings', 'Tasks settings')}
            />
          </HeaderRow>
        </BorderedListItem>
        {state.initialData.taskTypes
          .filter(tt => tt.visible === true) // hide Automatic tasks
          .map(tt => (
            <SettingsListItem
              key={tt.id}
              onClick={() => nav.navigate(`/create/task/${tt.id}`)}
              icon={<SharedIcon icon={taskIcons[tt.id]} />}
              description={t(`tasks:taskTypes.${tt.id}.description`, tt.description)}
              title={t(`tasks:taskTypes.${tt.id}.name`, tt.name)}
            />
          ))}
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}
interface SelectorProps {
  taskKey: string
  defaultValues?: AnyTaskData
}

export const CreateTaskSelector = ({taskKey, defaultValues}: SelectorProps) => {
  const {t} = useTranslation()
  const content = () => {
    switch (taskKey) {
      case 'todo':
        return <CreateTodoTask savedData={defaultValues as TodoTaskResponse} />
      case 'temperature':
        return <CreateTemperatureTask savedData={defaultValues as TemperatureTaskResponse} />
      case 'hygiene':
        return <CreateHygieneTask savedData={defaultValues as HygieneTaskResponse} />
      case 'waste':
        return <CreateWasteTask savedData={defaultValues as WasteTaskResponse} />
      case 'singlechoice':
        return <CreateSingleChoiceTask savedData={defaultValues as SingleChoiceTaskResponse} />
      case 'cooling':
        return <CreateCoolingTask savedData={defaultValues as CoolingTaskResponse} />
      case 'manual':
        return <CreateManualTask savedData={defaultValues as ManualTaskResponse} />
      case 'equipmentTemperature':
        return <CreateEquipmentTemperatureTask savedData={defaultValues as EquipmentTemperatureTaskResponse} />
      default:
        return <div>{t('common:errors.undefinedError', 'Something went wrong')}</div>
    }
  }
  return (
    <>
      {!!defaultValues && !!defaultValues.id ? (
        <Helmet title={t('common:routes.editTask', 'Edit task')} />
      ) : !!defaultValues && !defaultValues.id ? (
        <Helmet title={t('common:routes.copyTask', 'Copy task')} />
      ) : (
        <Helmet title={t('common:routes.createTask', 'Create task')} />
      )}
      {content()}
    </>
  )
}
