import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconDocumentLinked = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4558 2L31.642 2.19545L38.8273 9.7379L39.0011 9.92039V10.1724V45.3705V46.0005H38.3711H9.63H9V45.3705V18.63V2.63V2H9.63H31.1858H31.4558ZM30.556 3.26V10.1723V10.8023H31.186H37.7411V44.7405H10.26V18.63V3.26H30.556ZM31.816 4.20484V9.54233H36.9007L31.816 4.20484ZM17.8328 30.5187C16.2715 30.5187 14.9396 31.8719 14.9396 33.587C14.9396 35.3022 16.2715 36.6554 17.8328 36.6554H23.7055C25.2668 36.6554 26.5987 35.3022 26.5987 33.587C26.5987 31.8719 25.2668 30.5187 23.7055 30.5187H22.8648V29.2587H23.7055C26.0195 29.2587 27.8587 31.2343 27.8587 33.587C27.8587 35.9398 26.0195 37.9154 23.7055 37.9154H17.8328C15.5187 37.9154 13.6796 35.9398 13.6796 33.587C13.6796 31.2343 15.5187 29.2587 17.8328 29.2587H18.6734V30.5187H17.8328ZM21.399 29.8886C21.399 28.1735 22.731 26.8203 24.2922 26.8203H30.165C31.7262 26.8203 33.0582 28.1735 33.0582 29.8886C33.0582 31.6038 31.7262 32.9569 30.165 32.9569H29.3243V34.2169H30.165C32.479 34.2169 34.3182 32.2413 34.3182 29.8886C34.3182 27.5359 32.479 25.5603 30.165 25.5603H24.2922C21.9782 25.5603 20.139 27.5359 20.139 29.8886C20.139 32.2413 21.9782 34.2169 24.2922 34.2169H25.1329V32.9569H24.2922C22.731 32.9569 21.399 31.6038 21.399 29.8886Z"
      />
    </svg>
  )
}
