import {CycleLog} from '../../rest'
import {Alarm, AlarmSubType} from '../../alarms/state'

export enum UmbrellaCategory {
  COOKING = 'COOKING',
  CLEANING = 'CLEANING',
  WASTE = 'WASTE',
  OTHER = 'OTHER'
}

enum TaskStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  DONE = 'done',
  IN_PROGRESS = 'in-progress',
  REJECTED = 'rejected'
}

export interface TaskAlarmDetails {
  alarmType: AlarmSubType
  createdAt: string
  startedAt: string
  id: string
  lastMeasurement?: number
  resolvedAt?: string
}

export interface TaskListItemData {
  id: string
  status: TaskStatus
  scheduleInstance: string
  taskGroup: {
    icon: string
    name: string
    umbrellaCategory: UmbrellaCategory
  }
  taskType: {
    id: string
  }
  alarms: TaskAlarmDetails[]
  equipmentName: string
  target: string
  createdAt: string
  updatedAt: string
  name: string
  startTime: string | null
  targetTime: string | null

  subtasks: string[]
  doneSubtasks: string[]

  eventId: string | null

  createdByEquipmentId: string | null
  cycleLog: CycleLog | null
  coolingStartedAt: string | null
}
export interface TaskGroup {
  id: string
  name: string
  icon: string
  fixed: boolean
}

export enum MeasurementMethodOption {
  MANUAL = 'manual',
  DEVICE = 'device'
}

export interface Asset {
  assetMimeType: string[]
  id: string
  downloadURL: string
}
export interface CompletedTask extends TaskListItemData {
  actorName: string | null
  value: string | number | null
  ingredients: string[]
  notes: string

  suggestions: string[]
  asset: Asset
  coolingValues: {
    timestamp: string
    value: string
    measurementMethod: MeasurementMethodOption | null
  }[]

  status: TaskStatus
  assets: Asset[] | null
  wasteTaskType: string
  unit: string
  failedMeasurement: boolean | null

  instructions: string
  description: string
  links: any[]
  eventId: string | null
  choice: string
  measurementMethod: MeasurementMethodOption | null
}

export interface SiteHome {
  lateTasks: TaskListItemData[]
  todaysTasks: TaskListItemData[]
  completedTodayCount: number
  completedTask: TaskListItemData[]
  activeTaskGroups: TaskGroup[]
}

export interface HomeState {
  data: SiteHome
  tasks: {
    count: {
      today: number
      late: number
      inProgress: number
      total: number
    }
    lateTasks: TaskListItemData[]
    todaysTasks: TaskListItemData[]
    inProgressTasks: TaskListItemData[]
  }
  activeAlarms: {
    count: number
    alarms: Alarm[]
  }
  pending: boolean
  error: Error | null
}

export const state: HomeState = {
  data: {
    todaysTasks: [],
    completedTodayCount: 0,
    lateTasks: [],
    completedTask: [],
    activeTaskGroups: []
  },
  tasks: {
    count: {
      today: 0,
      late: 0,
      inProgress: 0,
      total: 0
    },
    lateTasks: [],
    todaysTasks: [],
    inProgressTasks: []
  },
  activeAlarms: {
    count: 0,
    alarms: []
  },
  pending: false,
  error: null
}
