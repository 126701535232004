import React from 'react'
import {colors} from '../../sharedComponents/colors'
import {IconProps} from './Icons'

/**
 * Add icons related to notifying the user here
 */

type IconVariants = {
  default: string
  warn: string
}
const iconVariants: IconVariants = {
  default: colors.brand.cobalt,
  warn: colors.system.red
}

interface NotificationIconProps {
  variant: keyof IconVariants
}

export const IconAlert = ({variant, ...props}: NotificationIconProps & IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill={props.color ? props.color : iconVariants[variant]}>
    <path d="M12 3.34c4.78 0 8.66 3.88 8.66 8.66s-3.88 8.66-8.66 8.66S3.34 16.78 3.34 12 7.22 3.34 12 3.34m0-1c-5.32 0-9.66 4.33-9.66 9.66s4.33 9.66 9.66 9.66 9.66-4.33 9.66-9.66S17.32 2.34 12 2.34z" />
    <path d="M11.5 5.95h1v7.62h-1zM11.5 15.63h1v2.21h-1z" />
  </svg>
)

const defaultProps = {
  width: '24px',
  height: '24px',
  variant: 'default'
}

IconAlert.defaultProps = defaultProps
