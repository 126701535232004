import {AsyncAction} from 'overmind'
import i from 'i18next'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../../state'
import {AllSitesFilters, SiteItem} from './state'

export const getAllSitesOverview: AsyncAction<void> = async ({state, effects, actions}) => {
  state.v1.home.allSites.pending = true
  state.v1.home.allSites.overview = []
  state.v1.home.allSites.error = null
  try {
    const overview = await effects.v1.home.allSites.homeApi.getAllSitesOverview()
    state.v1.home.allSites.overview = overview.locationGroups
    actions.v1.home.allSites.sortAllSitesOverview({filter: AllSitesFilters.NAME})
  } catch (error) {
    state.v1.home.allSites.error = error
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('tasks:msg.getAllSitesOverviewError', 'Could not update overview for all sites!'),
      description: i.t('tasks:msg.getAllSitesOverviewErrorDescription', ''),
      visible: true,
      hideAfterDelay: 6000
    })
  } finally {
    state.v1.home.allSites.pending = false
  }
}

export const sortAllSitesOverview: AsyncAction<{filter: AllSitesFilters}, void> = async (
  {state},
  params: {
    filter: AllSitesFilters
  }
) => {
  const sortBy = (a: SiteItem, b: SiteItem) => {
    if (params.filter === AllSitesFilters.NAME) {
      if (a[params.filter] > b[params.filter]) return 1
      if (a[params.filter] < b[params.filter]) return -1
    }

    if (a[params.filter] > b[params.filter]) return -1
    if (a[params.filter] < b[params.filter]) return 1
    return 0
  }
  state.v1.home.allSites.overview.map(locationGroup =>
    locationGroup.locations.map(location => location.sites.sort(sortBy))
  )
}
