import React from 'react'
import {H1, P} from '../../Atoms/Typography'

export const ErrorMessage = ({message}: {message: string}) => {
  return (
    <div>
      <H1>There was an error</H1>
      <P>{message}</P>
    </div>
  )
}
