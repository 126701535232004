import React, {FC} from 'react'
import EditUserAccountSettingsDetails from './EditUserAccountSettingsDetails'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'

const AddUserView: FC = (): any => {
  const {t} = useTranslation()

  return (
    <>
      <Helmet title={t('common:routes.newUser', 'New user')} />
      <EditUserAccountSettingsDetails isEditingSelf={false} />
    </>
  )
}

export default AddUserView
