import React from 'react'
import {MessagesByStatus} from '../../../state/messages/state'
import MessageCard from './MessageCard'
import {colors} from '../../../sharedComponents/colors'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import styled from 'styled-components'

interface MessageCardCarouselProps {
  messagesByStatus: MessagesByStatus
  onMessageClick: any
}

const MessageCardCarousel = ({messagesByStatus, onMessageClick}: MessageCardCarouselProps) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2
  }

  return (
    <StyledSlider {...settings}>
      {messagesByStatus.unreadMessages.map(msg => (
        <MessageCard key={msg.id} message={msg} onMessageClick={onMessageClick} headingColor={colors.system.red} />
      ))}
      {messagesByStatus.readMessages.map(msg => (
        <MessageCard key={msg.id} message={msg} onMessageClick={onMessageClick} headingColor={colors.brand.cobalt_60} />
      ))}
    </StyledSlider>
  )
}

const StyledSlider = styled(Slider)`
  .slick-prev:before,
  .slick-next:before {
    color: black !important;
  }
`

export default MessageCardCarousel
