import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconHandheldProbe = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4205 7.34521C35.7684 9.60117 37.2301 12.7261 37.2301 16.1864C37.2301 19.6445 35.7684 22.7706 33.4204 25.0255L32.5465 24.0847C34.6592 22.0557 35.9574 19.2623 35.9574 16.1864C35.9574 13.1083 34.6593 10.316 32.5464 8.28581L33.4205 7.34521Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7861 16.1853C10.7861 12.7262 12.2478 9.59999 14.5958 7.34509L15.4697 8.28593C13.357 10.3148 12.0588 13.1083 12.0588 16.1853C12.0588 19.2622 13.3569 22.0546 15.4698 24.0848L14.5957 25.0254C12.2479 22.7694 10.7861 19.6445 10.7861 16.1853Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 16.2985C3 10.7078 5.36125 5.65253 9.16461 2L10.0385 2.94083C6.47041 6.36742 4.27271 11.0899 4.27271 16.2985C4.27271 21.5082 6.47043 26.2307 10.0384 29.6561L9.16469 30.5971C5.36123 26.9456 3 21.8903 3 16.2985Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8369 2C42.639 5.6525 45.0003 10.7077 45.0003 16.2984C45.0003 21.8902 42.6391 26.9455 38.8368 30.5969L37.963 29.6561C41.5298 26.2307 43.7275 21.5082 43.7275 16.2984C43.7275 11.0899 41.5298 6.3673 37.9629 2.94069L38.8369 2Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1719 12.6873C19.1719 9.63926 21.6019 7.16833 24.5996 7.16833C27.5972 7.16833 30.0272 9.63926 30.0272 12.6873V30.5513C30.0272 33.5994 27.5972 36.0703 24.5996 36.0703C21.6019 36.0703 19.1719 33.5994 19.1719 30.5513V12.6873ZM24.5996 8.46245C22.3048 8.46245 20.4446 10.354 20.4446 12.6873V30.5513C20.4446 32.8847 22.3048 34.7762 24.5996 34.7762C26.8943 34.7762 28.7545 32.8846 28.7545 30.5513V12.6873C28.7545 10.354 26.8943 8.46245 24.5996 8.46245Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9997 46L23.9997 35.6471L25.2725 35.6471L25.2725 46L23.9997 46Z"
      />
    </svg>
  )
}
