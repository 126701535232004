import React from 'react'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../state'
import {IconButton, TextButton} from '../../Atoms/Buttons'
import {IconDelete} from '../../../Assets/Icons/TinyIcons'
import {LabelCheckbox, LabelInput} from '../../Atoms/Forms'
import {Baseline, Row} from '../../Layout/Grid'

import {P, H4, SmallUnit, H5} from '../../Atoms/Typography'

import styled from 'styled-components'
import {valueExists} from '../../../config/utils'

const StorageTypeListContainer = styled.div`
  padding-top: 2rem;
`

const StorageTypeList = styled.ul``

const StorageTypeItem = styled.li`
  position: relative;
  width: 100%;
  padding-bottom: 1.25rem;
  list-style: none;

  :not(:first-child) {
    padding-top: 1.25rem;
  }
`

interface Props {
  controller: any
}
const EditStorageTypes = ({controller: fc}: Props) => {
  const {t} = useTranslation('appliances')
  const {site} = useAppState().state
  return (
    <StorageTypeListContainer>
      <Baseline>
        <H4>{t('appliances:labels.alarmLimitsTitle', 'Alarm limits')}</H4>
        <StorageTypeList>
          {fc.fields && fc.fields.length > 0 ? (
            fc.fields.map((st: any, index: number) => {
              const markedForDelete = !!fc.fields[`${index}`]?.markedForDelete
              const inUse = !!fc.fields[`${index}`]?.inUse
              const humidityEnabled = fc.storageTypes.storageTypes[`${index}`]?.humidityEnabled

              return (
                <StorageTypeItem key={st.storageId}>
                  <Baseline>
                    <input
                      type="hidden"
                      name={`storageTypes[${index}].storageId`}
                      defaultValue={st.storageId}
                      ref={fc.register({required: true})}
                    />
                    <LabelInput
                      required
                      type="text"
                      ref={fc.register({
                        required: {
                          value: true,
                          message: t('common:validation.invalidValue', 'Invalid or missing value')
                        }
                      })}
                      name={`storageTypes[${index}].name`}
                      labelText={t('appliances:labels.storageType', 'Storage type')}
                      placeholder={t('appliances:placeholders.defineStorageType', 'Define storage type')}
                      defaultValue={st.name}
                      errorMsg={fc.errors?.storageTypes?.[`${index}`]?.name?.message}
                    />
                    <H5>{t('appliances:labels.temperatureAlarm', 'Temperature alarm')}</H5>
                    <Row childMargin="1rem">
                      <LabelInput
                        required
                        type="number"
                        ref={fc.register({
                          required: {
                            value: true,
                            message: t('common:validation.invalidValue', 'Invalid or missing value')
                          },
                          max: valueExists(fc.storageTypes.storageTypes[`${index}`]?.maxValue)
                            ? {
                                value: fc.storageTypes.storageTypes[`${index}`]?.maxValue,
                                message: t('common:validation.valueTooHigh', 'Value must be lower than max value')
                              }
                            : undefined
                        })}
                        errorMsg={fc.errors?.storageTypes?.[`${index}`]?.minValue?.message}
                        name={`storageTypes[${index}].minValue`}
                        defaultValue={st.minValue}
                        labelText={t('common:general.alarmMin', 'Alarm min')}
                        onBlur={() => fc.triggerValidation(`storageTypes[${index}].maxValue`)}
                      />
                      <LabelInput
                        required
                        type="number"
                        ref={fc.register({
                          required: {
                            value: true,
                            message: t('common:validation.invalidValue', 'Invalid or missing value')
                          },
                          min: valueExists(fc.storageTypes.storageTypes[`${index}`]?.minValue)
                            ? {
                                value: fc.storageTypes.storageTypes[`${index}`]?.minValue,
                                message: t('common:validation.valueTooLow', 'Value must be higher than min value')
                              }
                            : undefined
                        })}
                        errorMsg={fc.errors?.storageTypes?.[`${index}`]?.maxValue?.message}
                        name={`storageTypes[${index}].maxValue`}
                        defaultValue={st.maxValue}
                        labelText={t('common:general.alarmMax', 'Alarm max')}
                        onBlur={() => fc.triggerValidation(`storageTypes[${index}].minValue`)}
                      />
                      <SmallUnit>°{site?.temperatureUnit}</SmallUnit>
                      <LabelInput
                        required
                        type="number"
                        ref={fc.register({
                          required: {
                            value: true,
                            message: t('common:validation.invalidValue', 'Invalid or missing value')
                          },
                          min: {
                            value: 0,
                            message: t(
                              'common:validation.valueMustBeHigherThanOrEqualTo',
                              'Value must be higher than or equal to {{value}}',
                              {value: 0}
                            )
                          }
                        })}
                        errorMsg={fc.errors?.storageTypes?.[`${index}`]?.timeLimit?.message}
                        name={`storageTypes[${index}].timeLimit`}
                        defaultValue={st.timeLimit}
                        labelText={t('appliances:labels.alarmDelay', 'Alarm delay')}
                      />
                      <SmallUnit>min</SmallUnit>
                    </Row>
                    <Baseline>
                      <LabelCheckbox
                        name={`storageTypes[${index}].humidityEnabled`}
                        flipped={false}
                        disabled={false}
                        label={<H5>{t('appliances:labels.humidityAlarm', 'Humidity alarm')}</H5>}
                        ref={fc.register}
                        defaultChecked={st.humidityEnabled}
                      />
                      {humidityEnabled && (
                        <>
                          <Row childMargin="1rem">
                            <LabelInput
                              required
                              type="number"
                              ref={fc.register({
                                required: {
                                  value: true,
                                  message: t('common:validation.invalidValue', 'Invalid or missing value')
                                },
                                min: {
                                  value: 0,
                                  message: t(
                                    'common:validation.valueMustBeHigherThanOrEqualTo',
                                    'Value must be higher than or equal to {{value}}',
                                    {value: 0}
                                  )
                                },
                                max: valueExists(fc.storageTypes.storageTypes[`${index}`]?.humidityMaxValue)
                                  ? {
                                      value: fc.storageTypes.storageTypes[`${index}`]?.humidityMaxValue,
                                      message: t('common:validation.valueTooHigh', 'Value must be lower than max value')
                                    }
                                  : undefined
                              })}
                              errorMsg={fc.errors?.storageTypes?.[`${index}`]?.humidityMinValue?.message}
                              name={`storageTypes[${index}].humidityMinValue`}
                              defaultValue={st.humidityMinValue}
                              labelText={t('common:general.alarmMin', 'Alarm min')}
                              onBlur={() => fc.triggerValidation(`storageTypes[${index}].humidityMaxValue`)}
                            />
                            <LabelInput
                              required
                              type="number"
                              ref={fc.register({
                                required: {
                                  value: true,
                                  message: t('common:validation.invalidValue', 'Invalid or missing value')
                                },
                                min: valueExists(fc.storageTypes.storageTypes[`${index}`]?.humidityMinValue)
                                  ? {
                                      value: Math.max(0, fc.storageTypes.storageTypes[`${index}`]?.humidityMinValue),
                                      message:
                                        fc.storageTypes.storageTypes[`${index}`]?.humidityMaxValue >= 0
                                          ? t('common:validation.valueTooLow', 'Value must be higher than min value')
                                          : t(
                                              'common:validation.valueMustBeHigherThanOrEqualTo',
                                              'Value must be higher than or equal to {{value}}',
                                              {value: 0}
                                            )
                                    }
                                  : undefined
                              })}
                              errorMsg={fc.errors?.storageTypes?.[`${index}`]?.humidityMaxValue?.message}
                              name={`storageTypes[${index}].humidityMaxValue`}
                              defaultValue={st.humidityMaxValue}
                              labelText={t('common:general.alarmMax', 'Alarm max')}
                              onBlur={() => fc.triggerValidation(`storageTypes[${index}].humidityMinValue`)}
                            />
                            <SmallUnit>%</SmallUnit>
                            <LabelInput
                              required
                              type="number"
                              ref={fc.register({
                                required: {
                                  value: true,
                                  message: t('common:validation.invalidValue', 'Invalid or missing value')
                                },
                                min: {
                                  value: 0,
                                  message: t(
                                    'common:validation.valueMustBeHigherThanOrEqualTo',
                                    'Value must be higher than or equal to {{value}}',
                                    {value: 0}
                                  )
                                }
                              })}
                              errorMsg={fc.errors?.storageTypes?.[`${index}`]?.humidityTimeLimit?.message}
                              name={`storageTypes[${index}].humidityTimeLimit`}
                              defaultValue={st.humidityTimeLimit}
                              labelText={t('appliances:labels.alarmDelay', 'Alarm delay')}
                            />
                            <SmallUnit>min</SmallUnit>
                          </Row>
                          <P variant="small">
                            {t(
                              'appliances:messages.humidityDisclaimer',
                              'Applies only if appliance sensor supports humidity measurement.'
                            )}
                          </P>
                        </>
                      )}
                    </Baseline>
                    <div style={{opacity: inUse ? 0.4 : 1}}>
                      <IconButton
                        buttonProps={{
                          onClick: () => fc.handleRemove(index),
                          disabled: inUse && markedForDelete
                        }}
                        icon={<IconDelete />}
                        description={t('common:actions.delete', 'Delete')}
                      />
                    </div>
                    {inUse && markedForDelete ? (
                      <div style={{color: 'red'}}>
                        {t(
                          'appliances:messages.inUse',
                          'Storage type has equipments, and thus cannot be deleted'
                        )}
                      </div>
                    ) : null}
                  </Baseline>
                </StorageTypeItem>
              )
            })
          ) : (
            <P>{t('appliances:messages.noAlarmLimits', 'No defined alarm limits.')}</P>
          )}
        </StorageTypeList>
        <Row>
          <TextButton onClick={() => fc.handleAdd()}>{t('common:actions.addNew', 'Add new')}</TextButton>
        </Row>
      </Baseline>
    </StorageTypeListContainer>
  )
}

export default EditStorageTypes
