import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconRepeat = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9986 1.99988C12.4007 1.99988 2.99585 11.3835 2.99585 22.9633C2.99585 23.0065 2.99898 23.0457 3.00025 23.0616L3.00049 23.0645L3.00147 23.0773C3.00141 23.0758 3.00125 23.0718 3.00125 23.0671H4.26125C4.26125 23.0239 4.25812 22.9847 4.25685 22.9688L4.25662 22.9658L4.25585 22.9557C4.25994 12.0776 13.0968 3.25988 23.9986 3.25988C34.9029 3.25988 43.74 12.0816 43.74 22.9633C43.74 33.8435 34.9029 42.6653 23.9986 42.6653C18.5721 42.6653 13.6722 40.4687 10.1037 36.9299H18.5986V35.6699H8.87978H8.24978V36.2999V46H9.50978V38.1099C13.2762 41.7039 18.3711 43.9253 23.9986 43.9253C35.5965 43.9253 45 34.5417 45 22.9633C45 11.3835 35.5965 1.99988 23.9986 1.99988Z"
      />
    </svg>
  )
}
