import styled from 'styled-components'

export const CenteredLayout = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  padding: 2rem 1rem 0 1rem;
  display: grid;
  grid-template: auto 1fr / minmax(20rem, 102rem);
  justify-content: center;
`
