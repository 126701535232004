import {EventPayload, AssetData, EventStatus} from '../../../state/rest'
import {useAppState} from '../../../state'
import {useState, useCallback, useReducer} from 'react'
import {
  reverseConvertUnit,
  localNumberFormat,
  deLocalizeValue,
  valueExists,
  reverseConvertWeight
} from '../../../config/utils'
import {WasteTaskType} from '../../../Components/Views/Tasks/CreateWasteTask'
import {MeasurementMethodOption} from '../../../state/home/site/state'

export function usePerformingTasks(initialPayload: EventPayload, eventId?: string, afterSubmit?: () => void) {
  const {state, actions} = useAppState()
  const key = `${initialPayload.task.id}-${initialPayload.scheduledAt}-${eventId}`

  const [lastKey, setLastKey] = useState(key)
  const [payload, setPayload] = useReducer<React.Reducer<EventPayload, Partial<EventPayload>>>(
    (a, b) => ({...a, ...b}),
    initialPayload
  )
  const [loading, setLoading] = useState(false)

  // if the opening task is not as the same as pervious one, the data will be initialized for new task
  if (lastKey !== key) {
    setLastKey(key)
    setPayload(initialPayload)
  }

  const taskType = payload.task.taskType?.id
  const taskPayload = {
    id: payload.task.id,
    taskType: {
      id: payload.task.taskType?.id
    }
  }

  const isMeasurableEvent = (type: string) =>
    ['cooling', 'temperature', 'equipmentTemperature', 'hygiene'].includes(type)

  let value: number | undefined = undefined
  if (taskType === 'cooling' || taskType === 'equipmentTemperature' || taskType === 'temperature') {
    value = reverseConvertUnit(state.site!, deLocalizeValue(state.site!.locale, payload.value))
  } else if (taskType === 'waste') {
    if (initialPayload.task.wasteTaskType !== WasteTaskType.CustomerAmount && valueExists(payload.value)) {
      value = reverseConvertWeight(state.site!, deLocalizeValue(state.site!.locale, payload.value))
    } else {
      value = deLocalizeValue(state.site!.locale, payload.value)
    }
  } else {
    value = deLocalizeValue(state.site!.locale, payload.value)
  }

  const handleRemove = async () => {
    setLoading(true)
    if (!!eventId) {
      await actions.v1.performTask.updateEvent({
        id: eventId,
        payload: {
          suggestions: payload.suggestions,
          reportedActor: payload.reportedActor,
          remarks: payload.remarks,
          comment: payload.comment,
          value,
          doneSubtasks: payload.doneSubtasks,
          asset: payload.asset,
          status: EventStatus.REJECTED,
          task: taskPayload
        }
      })
    } else {
      await actions.v1.performTask.createEvent({
        ...payload,
        status: EventStatus.REJECTED,
        task: taskPayload
      })
    }
    setLoading(false)
    if (afterSubmit) {
      afterSubmit()
    }
  }
  const handleSubmit = async () => {
    setLoading(true)
    await actions.v1.performTask.createEvent({
      ...payload,
      value,
      measurementMethod: isMeasurableEvent(taskType) ? MeasurementMethodOption.MANUAL : undefined,
      task: taskPayload
    })
    setLastKey('')
    setLoading(false)

    if (afterSubmit) {
      afterSubmit()
    }
  }

  const handleUpdate = async () => {
    setLoading(true)
    await actions.v1.performTask.updateEvent({
      id: eventId!,
      payload: {
        ingredients: payload.ingredients,
        suggestions: payload.suggestions,
        remarks: payload.remarks,
        comment: payload.comment,
        reportedActor: payload.reportedActor,
        value,
        doneSubtasks: payload.doneSubtasks,
        asset: payload.asset,
        task: taskPayload,
        measurementMethod: isMeasurableEvent(taskType) ? MeasurementMethodOption.MANUAL : undefined,
        incompleteToDo: payload.incompleteToDo
      }
    })
    setLoading(false)
    if (afterSubmit) {
      afterSubmit()
    }
  }
  const onAssetSaved = (asset?: AssetData) => setPayload({asset})
  const handleNumberBlur = (value: string) => {
    if (value.length) setValue('value', localNumberFormat(state.site!.locale, value))
  }
  const setValue = useCallback((key: keyof EventPayload, value: any) => {
    setPayload({[key]: value})
  }, [])

  const handleCancel = () => setPayload(initialPayload)

  return {
    payload,
    handleSubmit,
    handleRemove,
    handleUpdate,
    handleCancel,
    setValue,
    loading,
    onAssetSaved,
    handleNumberBlur
  }
}
