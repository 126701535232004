import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconService = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 15.2V2.6286H11.3333V15.2C11.3333 18.3239 14.0189 20.8572 17.3333 20.8572C20.6465 20.8572 23.3333 18.3238 23.3333 15.2V2.6286H24.6666V15.2C24.6666 19.0182 21.3828 22.1143 17.3333 22.1143C13.2824 22.1143 10 19.0181 10 15.2Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.6665 15.2V2.6286H17.9998V15.2H16.6665Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 15.2004V2.7157H21.3333V15.2004H20Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3335 15.2004V2.7157H24.6668V15.2004H23.3335Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 15.2004V2.7157H11.3333V15.2004H10Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.333 15.2004V2.7157H14.6663V15.2004H13.333Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 44.7429V24H14.667V46H20.0003V24H18.667V44.7429H16.0003Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9998 44.7429V24H32.6665V46H37.9998V24H36.6665V44.7429H33.9998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 3.25714C32.5269 3.25714 31.3333 4.38246 31.3333 5.77143V21.4857H30V5.77143C30 3.68816 31.7905 2 34 2C36.208 2 38 3.688 38 5.77143V45.3714H36.6667V5.77143C36.6667 4.38263 35.472 3.25714 34 3.25714Z"
      />
    </svg>
  )
}
