import {AsyncAction} from 'overmind'

export const getTaskSettings: AsyncAction<void> = async ({state, effects}) => {
  console.log('FETCH NEW TASK SETTINGS')
  state.v1.settings.tasks.pending = true
  state.v1.settings.tasks.data = {}
  state.v1.settings.tasks.error = null
  try {
    const settings = await effects.v1.settings.tasks.getTaskSettings()
    state.v1.settings.tasks.data = settings
  } catch (err) {
    state.v1.settings.tasks.error = err as Error
  } finally {
    state.v1.settings.tasks.pending = false
  }
}
