import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Text, Modal, Button, Input, ModalProps, Icon} from '../../sharedComponents/components'
import {colors} from '../../sharedComponents/colors'
import {useAppState} from '../../state'
import {useTranslation} from 'react-i18next'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../../state/state'
import {ERROR_NOTIFICATION_DURATION_MS} from './appliancesUtils'

export type ApplianceStatusModalFormData = {
  name: string
  reason: string
}

interface ApplianceStatusModalProps extends ModalProps {
  applianceId?: string
  onFormSubmit?: (formData: ApplianceStatusModalFormData) => void
}

export const ApplianceStatusModal: React.FC<ApplianceStatusModalProps> = ({
  isOpen,
  onClose,
  onFormSubmit,
  applianceId,
  ...rest
}) => {
  const {actions} = useAppState()
  const {t} = useTranslation(['appliances', 'common'])
  const [formData, setFormData] = useState({name: '', reason: ''})
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [submittedTimeout, setSubmittedTimeout] = useState<number>()

  const resetModal = () => {
    setFormData({name: '', reason: ''})
    setSubmitting(false)
    setSubmitted(false)
    window.clearTimeout(submittedTimeout)
  }

  useEffect(() => {
    if (submitted) {
      const timeout = window.setTimeout(() => {
        onClose?.()
        resetModal()
      }, 3000)
      setSubmittedTimeout(timeout)
    }
  }, [submitted])

  useEffect(() => {
    if (submitted && isOpen === false) {
      resetModal()
    }
  }, [isOpen])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (event.target.id) {
      case 'applianceStatusModalFormName':
        setFormData({...formData, name: event.target.value})
        break
      case 'applianceStatusModalFormReason':
        setFormData({...formData, reason: event.target.value})
        break
      default:
        break
    }
  }

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (!applianceId) {
      return
    }
    setSubmitting(true)
    try {
      await actions.v1.appliances.putStatusForAppliance({
        applianceId,
        suspended: true,
        updatedBy: formData.name,
        reason: formData.reason
      })
      setSubmitted(true)
      onFormSubmit?.({...formData})
    } catch (err) {
      actions.addNotification({
        id: uuid(),
        type: NotificationType.ERROR,
        title: t('appliances:messages.dataSendErrorTitle', 'Data send error'),
        description: t('appliances:messages.dataSendErrorDescription', 'Sending data failed. Please try again.'),
        visible: true,
        hideAfterDelay: ERROR_NOTIFICATION_DURATION_MS
      })
    }
    setSubmitting(false)
  }

  return (
    <StyledModal
      id="applianceStatusModal"
      isOpen={isOpen}
      onClose={submitting ? undefined : onClose}
      title={t('appliances:labels.applianceStatusList', 'Appliance status')}
      headerLeftItem={
        !submitted && (
          <Button buttonStyle="secondary" buttonSize="small" onClick={onClose} disabled={submitting}>
            {t('common:buttons.cancel', 'Cancel')}
          </Button>
        )
      }
      headerRightItem={
        !submitted && (
          <Button
            buttonStyle="primary"
            buttonSize="small"
            onClick={onSubmit}
            disabled={!formData.name || !formData.reason || submitting}
            form="applianceStatusModalForm"
          >
            {t('common:buttons.submit', 'Submit')}
          </Button>
        )
      }
      width="33.75rem"
      {...rest}
    >
      {submitted ? (
        <ApplianceStatusModalSubmittedContainer>
          <Icon type="check-circle" width="4.5em" height="4.5em" fill={colors.system.green} />
          <Text size="M">
            {t('appliances:messages.submitSuccess', 'The information has been successfully submitted.')}
          </Text>
        </ApplianceStatusModalSubmittedContainer>
      ) : (
        <ApplianceStatusModalForm id="applianceStatusModalForm">
          <ApplianceStatusModalDescription size="M">
            {t('appliances:status.message.addNotes', 'Please add a note below on appliance status.')}
          </ApplianceStatusModalDescription>
          <Input
            id="applianceStatusModalFormName"
            name="applianceStatusModalFormName"
            label={t('appliances:labels.name', 'Name')}
            placeholder={t('appliances:labels.name', 'Name')}
            onChange={onInputChange}
            value={formData.name}
            disabled={submitting}
          />
          <Input
            id="applianceStatusModalFormReason"
            name="applianceStatusModalFormReason"
            label={t('appliances:labels.reason', 'Reason')}
            placeholder={t('appliances:status.message.updatedBy.placeholder', 'e.g. Maintainence break')}
            multiline
            rows={6}
            onChange={onInputChange}
            value={formData.reason}
            disabled={submitting}
          />
        </ApplianceStatusModalForm>
      )}
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`

const ApplianceStatusModalSubmittedContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-content: center;
  justify-items: center;
  gap: 2.5rem;
  width: 100%;
  height: 23.25rem;
`

const ApplianceStatusModalDescription = styled(Text)`
  padding-bottom: 0.5rem;
`

const ApplianceStatusModalForm = styled.form`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  width: 100%;
  padding: 1.5rem;
`
