import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {colors} from '../../../colors'
import {Text, TextProps} from '../../atoms/Text'
import {ApplianceDataValueAndUnit} from './ApplianceDataValueAndUnit'
import {EMPTY_VALUE_TEXT} from '../../atoms/DataField'

export const DIVIDER_TEXT = '/'

export interface ApplianceDataProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number | string | React.ReactNode
  unit?: string
  minValue?: number | string | React.ReactNode
  maxValue?: number | string | React.ReactNode
  minUnit?: string
  maxUnit?: string
  minValueLabelText?: string
  maxValueLabelText?: string
  title?: string
  hasAlarm?: boolean
  disabled?: boolean
  compact?: boolean
  minMaxValueDivider?: string
}

export const ApplianceData: React.FC<ApplianceDataProps> = ({
  value,
  unit,
  minValue,
  maxValue,
  minUnit,
  maxUnit,
  minValueLabelText,
  maxValueLabelText,
  title,
  hasAlarm,
  disabled,
  compact,
  minMaxValueDivider = DIVIDER_TEXT,
  ...rest
}) => {
  const textColor = disabled ? colors.system.grey_1 : colors.brand.cobalt
  const valueColor = hasAlarm ? colors.system.red : textColor
  const titleTextColor = disabled ? colors.system.grey_1 : colors.brand.cobalt
  const hasMinOrMaxValue = minValue !== undefined || maxValue !== undefined
  const minAndMaxValueTextProps: Pick<TextProps, 'size' | 'color'> = {
    size: compact ? 'XS' : 'S',
    color: textColor
  }
  const unitTextSize = compact ? 'S' : 'L'
  const valueTextLevel = compact ? 4 : 2

  return (
    <ApplianceDataContainer {...rest}>
      {title && (
        <Text size="XS" color={titleTextColor}>
          {title}
        </Text>
      )}
      <ApplianceDataValueAndUnit
        value={value}
        unit={unit}
        color={valueColor}
        valueTextLevel={valueTextLevel}
        unitTextSize={unitTextSize}
      />
      {hasMinOrMaxValue && (
        <ApplianceDataMinAndMaxValuesContainer>
          <ApplianceDataMinAndMaxValues>
            <Text {...minAndMaxValueTextProps}>{getValueText(minValue, minUnit ?? unit)}</Text>
            <Text {...minAndMaxValueTextProps}>{minMaxValueDivider}</Text>
            <Text {...minAndMaxValueTextProps}>{getValueText(maxValue, maxUnit ?? unit)}</Text>
          </ApplianceDataMinAndMaxValues>
          <ApplianceDataMinAndMaxLabels>
            <Text {...minAndMaxValueTextProps}>{minValueLabelText}</Text>
            <Text {...minAndMaxValueTextProps}>{maxValueLabelText}</Text>
          </ApplianceDataMinAndMaxLabels>
        </ApplianceDataMinAndMaxValuesContainer>
      )}
    </ApplianceDataContainer>
  )
}

ApplianceData.defaultProps = {
  minValueLabelText: 'Min',
  maxValueLabelText: 'Max',
  hasAlarm: false,
  disabled: false
}

ApplianceData.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValueLabelText: PropTypes.string,
  maxValueLabelText: PropTypes.string,
  title: PropTypes.string,
  hasAlarm: PropTypes.bool,
  disabled: PropTypes.bool
}

export const getValueText = (value?: number | string | React.ReactNode, unit?: string) => {
  return value !== undefined ? `${value}${unit}` : EMPTY_VALUE_TEXT
}

export const ApplianceDataContainer = styled.div`
  display: inline-grid;
  gap: 0.25rem;
  grid-auto-flow: row;
  justify-items: center;
  min-width: 4rem;
`

const ApplianceDataMinAndMaxValuesContainer = styled.div`
  display: inline-grid;
  gap: 0.125rem;
  grid-auto-flow: row;
  justify-items: center;
`

const ApplianceDataMinAndMaxValues = styled.span`
  padding: 0.25rem 0.5rem;
  background: ${colors.system.lightGrey_5};
  border-radius: 2.5rem;
  display: inline-grid;
  gap: 0.25rem;
  grid-template: auto / 1fr auto 1fr;
  justify-items: center;
`

const ApplianceDataMinAndMaxLabels = styled.span`
  display: inline-grid;
  gap: 1rem;
  grid-auto-flow: column;
`
