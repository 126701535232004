import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAppState} from '../../../state'
import styled from 'styled-components'
import {LabelCheckbox} from '../../Atoms/Forms'
import {P} from '../../Atoms/Typography'
import {normalizeSites} from '../../../config/utils'
import {colors} from '../../../sharedComponents/colors'

interface Props {
  controller: any
  cyKey: string
  label: string
}
const LocationsList = ({controller, cyKey, label}: Props) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const rc = controller
  const chain = state.chainsById[state.selectedChainId!]
  const sites = normalizeSites(chain)
  return !!chain ? (
    <RestaurantList>
      <div key={chain.id} data-cy={`${cyKey}-site-selector`}>
        <P variant="small">{label}</P>
        {rc.list.length === 0 && (
          <P variant="small" style={{color: colors.system.red}}>
            {t('tasks:labels.noLocationMessage', 'You must select at least one location for the task')}
          </P>
        )}
        <LabelCheckbox
          flipped={false}
          checked={sites.length === rc.list.length}
          name="selectAll"
          label={t('tasks:labels.all', 'All')}
          onClick={() => rc.toggleAll(sites)}
        />
        {chain.brands.map(brand => {
          return (
            <div key={brand.id}>
              {brand.locationGroups.map(locGroup => {
                return locGroup.locations.length ? (
                  <div key={locGroup.id} className="location-group">
                    {locGroup.locations.map(loc => {
                      return loc.sites.map(site => (
                        <LabelCheckbox
                          flipped={false}
                          label={loc.visible ? `${site.name} - ${loc.name}` : site.name}
                          name={site.id}
                          ref={rc.register}
                          key={site.id}
                        />
                      ))
                    })}
                  </div>
                ) : null
              })}
            </div>
          )
        })}
      </div>
    </RestaurantList>
  ) : (
    <div>{t('common:errors.undefinedError', 'Something went wrong')}</div>
  )
}
const RestaurantList = styled.ul`
  padding: 1rem;
`
export default LocationsList
