import React from 'react'
import {useAppState} from '../../../state'
import EditUserAccountSettingsDetails from './EditUserAccountSettingsDetails'
import {Me} from '../../../state/state'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'

const EditUserAccountSettings = () => {
  const {user} = useAppState().state.me as Me
  const {t} = useTranslation()
  return (
    <>
      <Helmet title={t('common:routes.accountSettings', 'Account Settings')} />
      <EditUserAccountSettingsDetails savedData={user} isEditingSelf={true} />
    </>
  )
}

export default EditUserAccountSettings
