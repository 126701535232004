import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconListSingle = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M33.5999 40.3428H20.3999V39.0857H33.5999V40.3428Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M33.5999 27.7715H20.3999V26.5144H33.5999V27.7715Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M33.5999 34.0572H20.3999V32.8H33.5999V34.0572Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 2H39V46H9V2ZM10.2 3.25714V44.7429H37.8V3.25714H10.2Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5005 28.4006C17.1908 28.4006 17.7505 27.8377 17.7505 27.1434C17.7505 26.4491 17.1908 25.8863 16.5005 25.8863C15.8101 25.8863 15.2505 26.4491 15.2505 27.1434C15.2505 27.8377 15.8101 28.4006 16.5005 28.4006ZM16.5005 29.6577C17.8812 29.6577 19.0005 28.532 19.0005 27.1434C19.0005 25.7548 17.8812 24.6292 16.5005 24.6292C15.1198 24.6292 14.0005 25.7548 14.0005 27.1434C14.0005 28.532 15.1198 29.6577 16.5005 29.6577Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5005 34.6862C17.1908 34.6862 17.7505 34.1234 17.7505 33.4291C17.7505 32.7348 17.1908 32.1719 16.5005 32.1719C15.8101 32.1719 15.2505 32.7348 15.2505 33.4291C15.2505 34.1234 15.8101 34.6862 16.5005 34.6862ZM16.5005 35.9434C17.8812 35.9434 19.0005 34.8177 19.0005 33.4291C19.0005 32.0405 17.8812 30.9148 16.5005 30.9148C15.1198 30.9148 14.0005 32.0405 14.0005 33.4291C14.0005 34.8177 15.1198 35.9434 16.5005 35.9434Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5005 40.9719C17.1908 40.9719 17.7505 40.409 17.7505 39.7147C17.7505 39.0204 17.1908 38.4576 16.5005 38.4576C15.8101 38.4576 15.2505 39.0204 15.2505 39.7147C15.2505 40.409 15.8101 40.9719 16.5005 40.9719ZM16.5005 42.229C17.8812 42.229 19.0005 41.1033 19.0005 39.7147C19.0005 38.3261 17.8812 37.2004 16.5005 37.2004C15.1198 37.2004 14.0005 38.3261 14.0005 39.7147C14.0005 41.1033 15.1198 42.229 16.5005 42.229Z"
      />
    </svg>
  )
}
