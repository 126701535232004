import React from 'react'
import styled from 'styled-components'
import {colors} from '../../../colors'
import {Icon} from '../Icon'

interface TableHeadingProps {
  onClick?: () => void
}

export const TableHeading = styled.th<TableHeadingProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;

  color: ${colors.system.grey_50};
  text-align: left;

  border-top: 1px solid ${colors.system.grey_5};
  border-bottom: 1px solid ${colors.system.grey_5};
  height: 2.5625rem;

  :hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  }
`

export interface SortableTableHeadingProps {
  onClick: () => void
  children: React.ReactNode
}

export const SortableTableHeading = (props: SortableTableHeadingProps) => {
  return (
    <TableHeading onClick={props.onClick}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {props.children} <Icon type="arrow-up-down" width="1rem" height="1rem" />
      </div>
    </TableHeading>
  )
}
