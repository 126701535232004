import React from 'react'
import {A11yAccordion} from '../../Atoms/Accordion'
import DocumentListItem, {NoDocumentsItem, NoDocumentsOrFoldersItem} from './Documents/DocumentListItem'
import {A11yIconListItem, A11yListContainer} from '../../Atoms/Lists'
import {IconFolder, IconFolderLinked} from '../../../Assets/Icons/LargeIcons'
import {openInNewTab} from '../../../config/utils'
import DoubleComponent from '../../Molecules/ListComponents/DoubleComponent'
import {Padding} from '../../Layout/Layout'

import {DocumentGroup, Document, DocumentFolderType} from '../../../state/documents/state'

interface DocumentAccordionProps {
  documentGroups: DocumentGroup[]
  onConfigure?: (id: string) => void
  onClick?: (d: Document) => void
}
const DocumentList = ({documentGroups, onClick, onConfigure}: DocumentAccordionProps) => {
  const openExternal = (dg: DocumentGroup) => {
    if (dg.url) {
      openInNewTab(dg.url)
    }
  }
  return (
    <A11yListContainer>
      {documentGroups && documentGroups.length > 0 ? (
        documentGroups.map(dg =>
          dg.type === DocumentFolderType.External ? (
            <A11yIconListItem
              key={dg.id}
              icon={<IconFolderLinked />}
              onClick={() => openExternal(dg)}
              content={
                <Padding py=".75rem">
                  <DoubleComponent title={dg.name} description={dg.url || dg.description} />
                </Padding>
              }
            />
          ) : (
            <A11yAccordion
              title={dg.name}
              icon={<IconFolder />}
              key={dg.id}
              storeId={dg.id}
              onClick={dg.type === DocumentFolderType.External ? () => openExternal(dg) : undefined}
              description={dg.url || dg.description}
            >
              {dg.documents.length > 0 ? (
                dg.documents.map(d => (
                  <DocumentListItem
                    key={d.id}
                    type={d.type}
                    name={d.name}
                    id={d.id}
                    onClick={() => !!onClick && onClick(d)}
                    onConfigure={onConfigure}
                  />
                ))
              ) : (
                <NoDocumentsItem />
              )}
            </A11yAccordion>
          )
        )
      ) : (
        <NoDocumentsOrFoldersItem />
      )}
    </A11yListContainer>
  )
}

export default DocumentList
