import React from 'react'
import styled, {css} from 'styled-components'
import {Icon} from '../Icon'
import {colors} from '../../../colors'

export interface AvatarProps {
  // The address of the image for an image avatar or image element
  src?: string
  // This attribute defines the alternative text describing the image
  alt: string
  // Custom icon type for an icon avatar
  // icon?: React.ReactNode
  // // This attribute defines the alternative text describing the image
  // The shape of avatar
  shape?: 'circle' | 'square'
  size?: string
  'data-cy'?: string
}

const AvatarImage = styled.img<AvatarProps>`
  object-fit: cover;
  height: ${props => props.size || '2.25rem'};
  width: ${props => props.size || '2.25rem'};
  ${props =>
    (props.shape === 'circle' || !props.shape) &&
    css`
      clip-path: circle(50% at center);
    `}
`

const PlaceholderImage = styled.div<AvatarProps>`
  background-color: ${colors.brand.cobalt};
  height: ${props => props.size || '2.25rem'};
  width: ${props => props.size || '2.25rem'};
  ${props =>
    (props.shape === 'circle' || !props.shape) &&
    css`
      clip-path: circle(50% at center);
    `}
  > svg {
    height: 70%;
    width: 70%;
    margin: 12% 15%;
  }
`

export const Avatar: React.FC<AvatarProps> = props => {
  return props.src ? (
    <AvatarImage {...props} />
  ) : (
    <PlaceholderImage {...props}>
      <Icon type="defaultPhoto" fill={colors.system.white} />
    </PlaceholderImage>
  )
}
