import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {Label} from '../../Atoms/Forms'
import {IconButton, TextButton} from '../../Atoms/Buttons'
import {IconCloseSmall} from '../../../Assets/Icons/TinyIcons'
import {colors} from '../../../sharedComponents/colors'
import {Baseline} from '../../Layout/Grid'

const TagList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const Tag = styled.li`
  border: 1px solid ${colors.system.grey_1};
  background-color: white;
  padding: 0.25rem 0.75rem;
  text-align: center;
  border-radius: 25px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  margin: 0.25rem;
  align-items: center;
  .title {
    margin-right: 1rem;
  }
`

const NakedInput = styled.input`
  margin: 0rem;
  border: none;
  outline: none;
  padding: 0rem;
  font-size: 0.875rem;
  color: ${colors.system.grey_50};
`
interface DefaultTagProps {
  onChange: (items: string[]) => void
  title?: string
  savedItems?: string[]
}
export const DefaultTagsWidget = ({savedItems, onChange, title}: DefaultTagProps) => {
  const {t} = useTranslation('tasks')
  title =
    title ||
    t(
      'tasks:labels.defaultSuggestionsDefaultLabel',
      'Define default suggestions for additional information. These suggestions are shown when completing the task.'
    )
  const [tags, setTags] = useState<string[]>(savedItems || [])
  const handleRemove = (index: number) => setTags(tags.filter((_, i) => i !== index))
  const handleAdd = () => {
    setTags(tags => [...tags, ''])
  }
  useEffect(() => {
    onChange(tags)
  }, [tags, onChange])
  const editTag = (i: number, value: string) => {
    const copy = [...tags]
    copy[i] = value
    setTags(copy)
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setTags([...tags.map(t => t.trim())])
    handleAdd()
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // remove empty
    if (e.target.value.trim().length === 0) {
      setTags(tags.slice(0, tags.length - 1))
    }
  }
  return (
    <Baseline margin="8px">
      <Label>{title}</Label>
      <Baseline margin="8px">
        <TagList>
          {tags.map((tag, i) => {
            const maxWidth = tag.length < 4 ? '4ch' : tag.length + 'ch'

            return (
              <Tag key={i + tag}>
                <form id={`addSuggestion_${i}`} onSubmit={handleSubmit}>
                  <NakedInput
                    type="text"
                    role="textbox"
                    style={{maxWidth}}
                    maxLength={40}
                    id={'addDefault_' + i}
                    className="defaultTag"
                    value={tags[i]}
                    onChange={e => editTag(i, e.target.value)}
                    autoComplete="off"
                    autoFocus
                    onBlur={handleBlur}
                  ></NakedInput>
                </form>
                <IconButton
                  icon={<IconCloseSmall color={colors.system.grey_1} />}
                  buttonProps={{
                    title: 'remove item',
                    className: 'removeIcon',
                    onClick: () => handleRemove(i)
                  }}
                />
              </Tag>
            )
          })}
        </TagList>
        <TextButton weight={600} onClick={handleAdd}>
          {t('tasks:actions.addNewSuggestion', 'Add new')}
        </TextButton>
      </Baseline>
    </Baseline>
  )
}
