import {IconPalette} from '../../sharedComponents/components'
import {parseStringToDate} from '../../Components/Atoms/Utils'
import {TaskListItemData} from '../../state/home/site/state'

type TgIcons = {
  [key: string]: IconPalette
}

export const alarmMapper: TgIcons = {
  FORK: IconPalette.Service,
  PAN: IconPalette.Cooking,
  PLATE: IconPalette.ServingTemp,
  STARS: IconPalette.SurfaceHygiene,
  CLEAN: IconPalette.Cleaning,
  WASTE: IconPalette.Waste,
  TROLLEY: IconPalette.Receiving,
  PACKAGE: IconPalette.Packaging,
  DELIVER: IconPalette.Delivery,
  WRENCH: IconPalette.Maintenance,
  MAGNIFY: IconPalette.Audit,
  QUALITY: IconPalette.Quality,
  THERMOMETER: IconPalette.Temperature,
  CHECKLIST: IconPalette.Checklist,
  LIST: IconPalette.TaskList,
  PEN: IconPalette.Manual,
  TIME: IconPalette.AlarmClock,
  FRIDGE: IconPalette.Fridge,
  DISHWASHER: IconPalette.Dishwasher,
  FREEZE: IconPalette.Freezer,
  OVEN: IconPalette.Oven,
  KETTLE: IconPalette.Kettle,
  WARM: IconPalette.Warm,
  HEATING: IconPalette.Heating,
  COOLER: IconPalette.Cooler,
  ASTERISK: IconPalette.Cooling,
  SENSOR: IconPalette.Sensor,
  SENSOR_MACHINE: IconPalette.SensorMachine,
  HOUSES: IconPalette.Chain,
  SPEECH_BUBBLE: IconPalette.Alarm,
  USERS: IconPalette.Users,
  PROFILE: IconPalette.Profile,
  DOCUMENT: IconPalette.Document,
  DOCUMENTLINKED: IconPalette.DocumentLinked,
  FOLDER: IconPalette.Folder,
  FOLDERLINKED: IconPalette.FolderLinked,
  ALARM: IconPalette.Alarm,
  ADD: IconPalette.AddItem,
  COG: IconPalette.Settings,
  OK: IconPalette.Confirmation,
  default: IconPalette.AlarmClock // Fallback
}

export const taskGroupIconPicker = (icon: string | undefined): IconPalette => {
  const taskIcon = alarmMapper[icon || 'default'] || alarmMapper['default']
  return taskIcon
}

export const getTasksUntilNow = (tasks: TaskListItemData[]) => {
  return tasks.filter(
    // only show relevant tasks based on scheduled time
    (task: any) => !task.scheduleInstance || parseStringToDate(task.scheduleInstance) <= new Date()
  )
}

export const checkInProgressAutomaticCoolingTask = (task: TaskListItemData) =>
  task.status === 'in-progress' && task.taskType.id === 'automaticCooling' && task.cycleLog === null
