import {useState, useCallback} from 'react'
import {WeekSchedule} from '../../../state/rest'
import {getDay} from 'date-fns/esm'
import {parseISOTimeToDate} from '../../Atoms/Utils'

export function useWeekScheduleList(saved?: WeekSchedule[]) {
  const newSchedule = () => ({
    weekdays: [getDay(new Date()) - 1],
    from: '09:00:00',
    to: '17:00:00'
  })

  const [schedules, setSchedules] = useState<WeekSchedule[]>(saved ? saved : [newSchedule()])

  const [isValid, setValidity] = useState<boolean>(true)

  const checkValidity = () => {
    const validSchedules = schedules.filter(schedule => isValidSchedule(schedule))
    setValidity(validSchedules.length === schedules.length)
  }

  const handleAdd = useCallback(() => {
    setSchedules(schedules => [...schedules, newSchedule()])
    checkValidity()
  }, [schedules])

  const handleRemove = useCallback(
    (index: number) => {
      setSchedules(schedules => schedules.filter(sc => schedules.indexOf(sc) !== index))
      checkValidity()
    },
    [schedules]
  )

  const handleChange = useCallback(
    (schedule: WeekSchedule, index: number) => {
      let copy = schedules
      copy[index] = schedule
      setSchedules(copy)
      checkValidity()
    },
    [schedules]
  )

  const isValidSchedule = (schedule: WeekSchedule) =>
    schedule.from && schedule.to && parseISOTimeToDate(schedule.from) < parseISOTimeToDate(schedule.to)

  return {schedules, handleChange, handleAdd, handleRemove, checkValidity, isValid}
}
