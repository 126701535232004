import React, {useState, useCallback, useEffect} from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../../state'
import {Document, DocumentType} from '../../../../state/rest'
import {NotificationType} from '../../../../state/state'
import {MainLayoutWithoutStretch, MainContent} from '../../../Layout/Layout'
import {GoBackHeaderTitle, HeaderRow} from '../../../Molecules/ViewComponents/ViewHeader'
import {useDocumentFolderSelect} from '../../../../config/utils'
import {Helmet} from 'react-navi-helmet-async'
import {v4 as uuid} from 'uuid'
import {Loader} from '../../../../Routes'
import {AssetData} from '../../../../state/rest'
import {
  ConfirmModalWindow,
  DocumentAddingForm,
  DocumentAddingModalWindow,
  DocumentEditBottomButtons,
  DocumentTypeSelection
} from '../HelperComponents'
import SiteSelectionCheckboxStyle from '../../../../Components/Organisms/SiteSelectionCheckboxStyle'
import {Baseline, Grid, InvisibleContainer} from '../../../../Components/Layout/Grid'

const HeaderRowWithGoBack = ({t, isEditing}: any) => {
  return (
    <HeaderRow>
      <GoBackHeaderTitle
        label={
          isEditing
            ? `${t('documents:labels.editDocument', 'Edit document')}`
            : `${t('documents:labels.addDocument', 'Add document')}`
        }
        path={'/documents'}
        backLabel={t('documents:labels.documentsTitle', 'Documents')}
      />
    </HeaderRow>
  )
}

interface EditDocumentProps {
  id?: string
}
const EditDocument = ({id}: EditDocumentProps) => {
  const nav = useNavigation()
  const {state, actions} = useAppState()
  const {t} = useTranslation('documents')
  const currentState = state.me!
  const chain = state.chainsById[state.selectedChainId!]

  let writableIds = currentState.accessRights.read.sites
  writableIds = writableIds.concat(...currentState.accessRights.write.chains)

  const internalDocumentFolders: any = state.v1.documents.internalDocumentFolders

  const initialDocument = {
    type: undefined,
    name: '',
    documentFolder: {
      id: internalDocumentFolders.length > 0 ? internalDocumentFolders[0].id : ''
    },
    asset: undefined,
    url: undefined,
    sites: []
  }

  const [document, setDocument] = useState<Partial<Document>>(initialDocument)
  const [isChainWide, setIsChainWide] = useState(false)
  const [selectedSites, setSelectedSites] = useState([] as string[])
  const [showUploader, setShowUploader] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const {selectMethod, selectedDocumentFolder, folderOptions, setSelected} = useDocumentFolderSelect(
    document.documentFolder!.id!
  )

  // Fetch document folders when rendering. Fetch document if id is provided
  const isLoading = state.v1.documents.documentFoldersPending

  useEffect(() => {
    const setup = async () => {
      // Fetch document if updating existing one
      if (id) {
        const document = await actions.v1.documents.getDocument(id)
        setDocument(document)
        setIsChainWide(document.sites!.length === 0)
        setSelectedSites(document.sites!.length === 0 ? writableIds : document.sites!.map(s => s.siteId))
        if (document.documentFolder) {
          setSelected(document.documentFolder as any)
        }
      }
      actions.v1.documents.getDocumentFolders()
    }
    setup()
  }, [actions.v1.documents, id, setSelected])

  const isEditing = !!id

  const goBack = () => nav.navigate('/documents')

  const handleCreate = () => {
    const documentToBeSent = {
      ...document,
      createdBy: state.me!.user,
      documentFolder: selectedDocumentFolder,
      sites: isChainWide
        ? []
        : selectedSites.map(siteId => ({
            documentId: '',
            siteId: siteId
          }))
    } as Partial<Document>
    actions.v1.documents
      .createDocument(documentToBeSent)
      .catch(() =>
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('documents:msg.createDocumentError', 'Could not create document'),
          description: t('documents:msg.createDocumentErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      )
      .then(goBack)
  }

  const handleUpdate = () => {
    const documentToBeSent = {
      ...document,
      id: id!,
      type: document.type!,
      name: document.name!,
      url: document.url,
      createdAt: new Date().toISOString(),
      createdBy: state.me!.user,
      documentFolder: selectedDocumentFolder,
      sites: isChainWide
        ? []
        : selectedSites.map(siteId => ({
            documentId: '',
            siteId: siteId
          }))
    } as Partial<Document>
    actions.v1.documents
      .updateDocument(documentToBeSent)
      .catch(() =>
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('documents:msg.updateDocumentError', 'Could not update document'),
          description: t('documents:msg.updateDocumentErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      )
      .then(goBack)
  }

  const handleDelete = () => {
    actions.v1.documents
      .deleteDocument(id!)
      .catch(() =>
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('documents:msg.deleteDocumentError', 'Could not delete document'),
          description: t('documents:msg.deleteDocumentErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      )
      .then(goBack)
  }

  const handleCancel = () => {
    goBack()
  }

  const handleSelectType = (type: DocumentType) => {
    setDocument(() => ({...document, type}))
  }

  const handleAddAsset = (asset?: AssetData) => {
    if (asset) {
      setDocument(d => ({...document, type: DocumentType.Internal, name: d.name || asset.name, asset}))
      setShowUploader(!showUploader)
    }
  }

  const isValid = useCallback((): boolean => {
    const hasName = !!document.name && document.name.trim().length > 0
    const hasFolder = !!document.documentFolder

    if (!isChainWide && selectedSites.length === 0) return false

    if (document.type === DocumentType.Internal) {
      const hasAsset = !!document.asset
      return hasName && hasAsset && hasFolder
    } else if (document.type === DocumentType.External) {
      const url = document.url || ''
      const hasUrl = url.trim().length > 0 && !!url.match(/^https?:\/\/.+/)
      return hasName && hasUrl && hasFolder
    }
    return false
  }, [document.type, document.name, document.asset, document.url, document.documentFolder, selectedSites, isChainWide])

  const isDocumentOwner = useCallback((): boolean => {
    if (!document.createdBy) return true // new file

    if (!state.me) {
      return false
    }

    // Check if user is chain manager or not
    const isChainManager = state.me.accessRights.write.chains.length > 0

    // chain managers can edit every document
    if (isChainManager) return true
    else {
      // Basic users can edit their own documents
      return state.me!.user.id === document.createdBy!.id
    }
  }, [state.me, document.createdBy])

  return (
    <MainLayoutWithoutStretch>
      <Loader show={isLoading} />
      {isEditing ? (
        <Helmet title={t('common:routes.editDocument', 'Edit document')} />
      ) : (
        <Helmet title={t('common:routes.createDocument', 'Create document')} />
      )}
      <ConfirmModalWindow
        t={t}
        setModalOpen={setModalOpen}
        handleDelete={handleDelete}
        modalOpen={modalOpen}
        heading={t('documents:confirmation.deleteDocumentTitle', 'Delete document?')}
        content={t(
          'documents:confirmation.deleteDocumentDescription',
          'Are you sure you want to delete this document?'
        )}
      />
      {!isLoading && (
        <MainContent variant="white">
          <HeaderRowWithGoBack t={t} isEditing={isEditing} />
          <InvisibleContainer>
            <Grid>
              <Baseline>
                <DocumentTypeSelection
                  isEditing={isEditing}
                  document={document}
                  t={t}
                  showUploader={showUploader}
                  toggleShowUploader={setShowUploader}
                  handleSelectType={handleSelectType}
                />
                <DocumentAddingModalWindow
                  t={t}
                  showUploader={showUploader}
                  toggleShowUploader={setShowUploader}
                  handleAddAsset={handleAddAsset}
                />
                <DocumentAddingForm
                  isInstructions={false}
                  document={document}
                  setDocument={setDocument}
                  isEditing={isEditing}
                  isDocumentOwner={isDocumentOwner}
                  folderOptions={folderOptions}
                  selectedDocumentFolder={selectedDocumentFolder}
                  selectMethod={selectMethod}
                  internalDocumentFolders={internalDocumentFolders}
                  t={t}
                />
              </Baseline>
              <div className="empty"></div>
            </Grid>
            <DocumentEditBottomButtons
              isEditing={isEditing}
              isDocumentOwner={isDocumentOwner}
              isValid={isValid}
              handleUpdate={handleUpdate}
              handleCreate={handleCreate}
              handleCancel={handleCancel}
              setModalOpen={setModalOpen}
              t={t}
            />
          </InvisibleContainer>
        </MainContent>
      )}
      <SiteSelectionCheckboxStyle
        infoText={t('documents:messages.selectSites', 'Select sites to which this document should be visible.')}
        writableIds={writableIds}
        chain={chain}
        isChainWide={isChainWide}
        setIsChainWide={setIsChainWide}
        selectedSites={selectedSites}
        setSelectedSites={setSelectedSites}
        isLoading={isLoading}
        t={t}
      />
    </MainLayoutWithoutStretch>
  )
}

export default EditDocument
