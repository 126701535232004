import {useAppState} from '../../../state'
import {AssetPayload, AssetType, AssetData} from '../../../state/rest'

import {UploadedAsset, AddAssetHookParams} from './types'

const useAddAsset = (): AddAssetHookParams => {
  const {actions} = useAppState()

  const addAsset = async (assetToBeAdded: UploadedAsset): Promise<AssetData> => {
    const newAsset: AssetPayload = {
      name: assetToBeAdded.name,
      assetPath: `${assetToBeAdded.path}`,
      assetMimeType: assetToBeAdded.mimeType,
      assetSize: assetToBeAdded.size,
      assetType: assetToBeAdded.mimeType.startsWith('image')
        ? AssetType.PHOTO
        : assetToBeAdded.mimeType.startsWith('video')
        ? AssetType.VIDEO
        : AssetType.DOCUMENT,
      instructions: assetToBeAdded.instructions
    }
    console.debug('useAddAsset.ts: addAsset', {newAsset})
    return await actions.createAsset(newAsset)
  }

  return {
    addAsset
  }
}

export default useAddAsset
