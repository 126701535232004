import styled, {css} from 'styled-components'
import {colors} from '../../../colors'

export enum CardWidthType {
  Default = 'Default',
  Wide = 'Wide'
}

export interface CardProps {
  widthType?: CardWidthType
  compact?: boolean
}

export const Card = styled.div<CardProps>(({widthType, compact}) => {
  const isWide = widthType === CardWidthType.Wide

  return css`
    background-color: ${colors.system.white};
    border-radius: ${compact ? 0.5 : 1}rem;
    padding: ${compact ? 1 : 1.5}rem;
    ${compact && 'padding-bottom: 0.75rem;'}
    position: relative;
    height: ${compact ? 13.5 : 17.25}rem;
    min-width: ${isWide ? (compact ? 22.5 : 31) : compact ? 10.5 : 14}rem;
    grid-column: auto / span ${isWide ? 2 : 1};
  `
})
