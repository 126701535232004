import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconExternalLink = (props: SharedIconProps) => {
  const fill = props.fill || colors.brand.cobalt
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none">
      <path d="M19.9996 20H0V0H9.07629V1.22249H1.22322V18.7775H18.7764V10.9535H19.9996V20Z" fill={fill} />
      <path
        d="M13.0279 0V1.22249H17.9208L10.1533 8.96088L11.0218 9.82885L18.777 2.07824V6.96822H20.0002V0H13.0279Z"
        fill={fill}
      />
    </svg>
  )
}
