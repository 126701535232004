import {getDay, getDate, format} from 'date-fns/esm'
import {useState, useEffect, useCallback} from 'react'
import {SchedulePayload, ScheduleResponse} from '../../../state/rest'
import {Schedule, PeriodType} from './Scheduler'

export function useScheduler(saved?: ScheduleResponse) {
  const date = new Date()
  const DATE_FORMAT = 'yyyy-MM-dd' // TODO: Localization
  const TIME_FORMAT = 'HH:mm:ss' // TODO: Localization

  const empty: Schedule = {
    frequency: 1,
    periodType: PeriodType.WEEK,
    weekDays: [getDay(date)],
    days: [getDate(date)],
    dates: [format(date, DATE_FORMAT)],
    startDate: format(date, DATE_FORMAT),
    startTime: format(date, TIME_FORMAT)
  }
  const [scheduledPayload, setSchedulerPayload] = useState<SchedulePayload | null>(null)
  const calculateSchedule = useCallback(
    (s: Schedule) => {
      const payload: SchedulePayload = {
        id: saved ? saved.id : undefined,
        dates: `[${s.startDate},)`,
        period: `${s.frequency} ${s.periodType}`,
        time: s.startTime,
        weekdays: s.periodType === PeriodType.WEEK && s.weekDays ? s.weekDays : undefined,
        monthdays: s.periodType === PeriodType.MONTH && s.days ? s.days : undefined,
        yeardays: s.periodType === PeriodType.YEAR && s.dates ? s.dates : undefined
      }
      return payload
    },
    [saved]
  )
  function reverseSchedule(s?: ScheduleResponse): Schedule | undefined {
    if (!s) {
      return undefined
    }
    const periodType = Object.keys(s.period)[0]
    let payload = {
      startDate: s.dates.substring(1, 11),
      startTime: s.time
    }

    if (periodType === 'days') {
      return {
        ...payload,
        frequency: s.period.days / 7,
        periodType: PeriodType.WEEK,
        weekDays: s.weekdays ? s.weekdays.map(w => parseInt(w)) : undefined
      }
    }
    if (periodType === 'months') {
      return {
        ...payload,
        frequency: s.period.months!,
        periodType: PeriodType.MONTH,
        days: s.monthdays ? s.monthdays.map(m => parseInt(m)) : undefined
      }
    } else {
      return {
        ...payload,
        frequency: s.period.years!,
        periodType: PeriodType.YEAR,
        dates: s.yeardays
      }
    }
  }
  useEffect(() => {
    if (saved) {
      setSchedulerPayload(calculateSchedule(reverseSchedule(saved)!))
    }
  }, [saved, calculateSchedule])
  function toggleShow() {
    if (scheduledPayload) setSchedulerPayload(null)
    else setSchedulerPayload(calculateSchedule(empty))
  }
  const handleScheduler = useCallback(
    (s: Schedule) => {
      setSchedulerPayload(calculateSchedule(s))
    },
    [calculateSchedule]
  )

  return {toggleShow, handleScheduler, reverseSchedule, scheduledPayload}
}
