import React from 'react'
import {Text} from '../../atoms/Text'
import styled from 'styled-components'

export type SingleChoiceProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string
}
export const SingleChoice: React.FC<SingleChoiceProps> = ({label, ...rest}) => {
  return (
    <SingleChoiceContainer>
      <SingleChoiceInput type="radio" {...rest} />
      {label && (
        <label htmlFor={rest.id}>
          <Text size="M">{label}</Text>
        </label>
      )}
    </SingleChoiceContainer>
  )
}

const SingleChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const SingleChoiceInput = styled.input`
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:disabled {
    opacity: 0.5;
  }
`
