import styled from 'styled-components'
import React, {ReactNode} from 'react'
import {Property as CSSProperty} from 'csstype'
import {colors} from '../../sharedComponents/colors'
//import '../../fonts/Industry/Industry-font.css'
//import '../../fonts/FreeSans/FreeSans-font.css'

export const HR = styled.hr`
  border-top: 1px solid lightgray;
`
export const TruncatedText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  text-align: left;
  font-size: 0.875rem;
`

/**
 * Big Number Headers
 */
export const NumberH1 = styled.h1`
  font-family: FavoritStd-Light, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 4rem; /*64px;*/
  line-height: 4rem; /*64px;*/
  letter-spacing: -1px;
`

export const NumberH2 = styled.h2`
  font-family: FavoritStd-Light, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2.5rem; /*40px;*/
  line-height: 2.5rem; /*40px;*/
  letter-spacing: -1px;
`

export const NumberH3 = styled.h3`
  font-family: FavoritStd-Light, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.75rem; /*28px;*/
  line-height: 1.5rem; /*24px;*/
  letter-spacing: -0.78px;
`

export const NumberH4 = styled.h4`
  font-family: FavoritStd-Light, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5rem; /*24px;*/
  line-height: 1.5rem; /*24px;*/
  letter-spacing: -1px;
`
export const UnitH3 = styled.h3`
  font-family: FavoritStd-Light, sans-serif;
  font-weight: 500;
  font-size: 1.5rem; /*24px;*/
  line-height: 1.5rem; /*24px;*/
  letter-spacing: -1px;
`
export const SmallUnit = styled.span`
  font-size: 0.875rem; /* 14px */
  line-height: 1.5rem; /* 24px */
  letter-spacing: 0.25px;
  font-weight: normal;
  display: block;
  margin: 0;
`
export const NumberH5 = styled.h5`
  font-family: FavoritStd-Light, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.125rem; /*18px;*/
  line-height: 1.125rem; /*18px;*/
  letter-spacing: -1px;
`

export const NumberH6 = styled.h6`
  font-family: FavoritStd-Light, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem; /*14px;*/
  line-height: 1.5rem; /*24px;*/
`

const numbers = [NumberH1, NumberH2, NumberH3, NumberH4, NumberH5, NumberH6]
export const Number = (props: HProps) => {
  const validLevel = props.level > 0 && props.level <= numbers.length ? props.level : 1
  const Comp = numbers[validLevel - 1]
  return (
    <Comp data-cy={props['data-cy']} style={{color: props.color, textTransform: props.textTransform}}>
      {props.children}
    </Comp>
  )
}

/**
 * Titles
 */
export const TitleH1 = styled.h1`
  font-family: FavoritStd-Light, sans-serif;
  //text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem; /*48px;*/
  line-height: 3rem; /*64px;*/
`

export const TitleH2 = styled.h2`
  font-family: FavoritStd-Light, sans-serif;
  //text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem; /*32px;*/
  line-height: 2rem; /*32px;*/
`

export const TitleH3 = styled.h3`
  font-family: FavoritStd-Light, sans-serif;
  //text-transform: uppercase;
  font-size: 1.25rem; /*20px;*/
  line-height: 1.5rem; /*24px;*/
  letter-spacing: 0;
`

export const TitleH4 = styled.h4`
  font-family: FavoritStd-Light, sans-serif;
  //text-transform: uppercase;
  font-weight: 400;
  font-size: 1.125rem; /*18px;*/
  line-height: 1.25rem; /*20px;*/
  letter-spacing: 0.11;
`

const titles = [TitleH1, TitleH2, TitleH3, TitleH4]
export const Title = (props: HProps) => {
  const validLevel = props.level > 0 && props.level <= titles.length ? props.level : 1
  const Comp = titles[validLevel - 1]
  return <Comp style={{color: props.color, textTransform: props.textTransform}}>{props.children}</Comp>
}

/**
 * Headings
 */
export const H1 = styled.h1`
  color: ${colors.brand.cobalt};
  font-family: FavoritStd-Light, sans-serif;
  font-weight: normal;
  //text-transform: capitalize;
  font-size: 2.25rem; /*36px;*/
  line-height: 2.25rem; /*36px;*/
  letter-spacing: 0;
`

export const H2 = styled.h2`
  color: ${colors.brand.cobalt};
  font-family: FavoritMonoStd-Regular, monospace;
  font-weight: 400;
  font-size: 2.5vh; /*22px;*/
  line-height: 1.625rem; /*26px;*/
  letter-spacing: 0;
`
export const H3 = styled.h3`
  color: ${colors.brand.cobalt};
  font-family: FavoritStd-Book;
  font-weight: 100;
  font-size: 1.125rem; /*18px;*/
  line-height: 1.5rem; /*24px;*/
  letter-spacing: 0;
`

export const H4 = styled.h4`
  color: ${colors.brand.cobalt};
  font-family: FavoritMonoStd-Regular, monospace;
  font-weight: 500;
  font-size: 1rem; /*16px;*/
  line-height: 1.5rem; /*24px;*/
  letter-spacing: 0;
`

export const H5 = styled.h5`
  color: ${colors.brand.cobalt};
  font-family: FavoritStd-Bold;
  font-weight: 500;
  font-size: 0.875rem; /*14px;*/
  line-height: 1.125rem; /*18px;*/
  letter-spacing: 0.1px;
`

export const H6 = styled.h6`
  color: ${colors.brand.cobalt};
  font-family: FavoritMonoStd-Regular, monospace;
  font-weight: 500;
  font-size: 0.75rem; /*12px;*/
  line-height: 0.875rem; /*14px;*/
  letter-spacing: 0.09px;
  margin: 0;
`

export const HomeSectionHeader = styled.p`
  color: ${colors.brand.cobalt};
  font-family: 'FavoritStd-Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;

  margin-bottom: 8px;
`

const headings = [H1, H2, H3, H4, H5, H6]
export interface HProps {
  color?: string
  disabled?: boolean
  textTransform?: CSSProperty.TextTransform
  level: number
  children: ReactNode
  'data-cy'?: string
  lineBreak?: boolean
}
export const Heading = (props: HProps) => {
  const validLevel = props.level > 0 && props.level <= headings.length ? props.level : 1
  const Comp = headings[validLevel - 1]
  return (
    <Comp
      data-cy={props['data-cy']}
      style={{
        color: props.color,
        textTransform: props.textTransform,
        whiteSpace: props.lineBreak ? 'pre-line' : 'normal',
        fontFamily: 'FavoritStd-Book'
      }}
    >
      {props.children}
    </Comp>
  )
}

export const Bold1 = styled.span`
  font-family: FavoritMonoStd-Regular, monospace;
  font-weight: 500;
  font-size: 1.5rem; /*24px;*/
  line-height: 1.375rem; /*22px;*/
  letter-spacing: 0.3px;
`

const bold = [Bold1]
export interface BoldProps extends TextProps {}
export const Bold = (props: BoldProps) => {
  const validLevel = props.level > 0 && props.level <= bold.length ? props.level : 1
  const Comp = bold[validLevel - 1]
  return (
    <Comp data-cy={props.dataCy} style={{color: props.color, textTransform: props.textTransform}}>
      {props.children}
    </Comp>
  )
}

/**
 * Text - like paragraps, but without margin between lines
 */
export const Text1 = styled.span`
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  letter-spacing: 0.25px;
  font-weight: normal;
  display: block;
  margin: 0;
`

export const Text2 = styled.span`
  font-size: 0.875rem; /* 14px */
  line-height: 1.5rem; /* 24px */
  letter-spacing: 0.25px;
  font-weight: normal;
  display: block;
  margin: 0;
`

export const Text3 = styled.span`
  font-size: 0.75rem; /* 12px */
  line-height: 1.25rem; /* 20px */
  letter-spacing: 0.25px;
  font-weight: normal;
  display: block;
  margin: 0;
`

interface TextProps {
  color?: string
  disabled?: boolean
  textTransform?: CSSProperty.TextTransform
  level: number
  children: ReactNode
  dataCy?: string // cypress identifier
}
const texts = [Text1, Text2, Text3]
export const Text = (props: TextProps) => {
  const validLevel = props.level > 0 && props.level <= texts.length ? props.level : 1
  const Comp = texts[validLevel - 1]
  return (
    <Comp data-cy={props.dataCy} style={{color: props.color, textTransform: props.textTransform}}>
      {props.children}
    </Comp>
  )
}

/**
 * Paragraphs
 */
interface PProps {
  variant?: string
  color?: string
  lineBreak?: boolean
}
export const P = styled.p<PProps>`
  font-size: ${props => (props.variant === 'small' ? '0.75rem' : '1rem')}; /* 12px | 16px */
  line-height: ${props => (props.variant === 'small' ? '1.25rem' : '1.5rem')}; /* 20px | 24px */
  letter-spacing: ${props => (props.variant === 'small' ? '0.25px;' : '0')};
  color: ${props => (props.color ? props.color : colors.brand.cobalt)};
  white-space: ${props => (props.lineBreak ? 'pre-line' : 'normal')};
  margin: 0;
  /** In case we have multiple adjacent p tags, add margin to paragraphs excluding the first */
  & + p {
    margin-top: 1rem;
  }
`

/**
 * Caption and overline
 */
export const Caption = styled.span`
  font-family: FavoritStd-Book;
  font-weight: 100;
  font-size: 0.75rem; /*12px;*/
  line-height: 1rem; /*16px;*/
  letter-spacing: 0.4px;
  margin: 0;
`

export const Overline = styled.span`
  color: ${colors.system.grey_50};
  font-family: FavoritMonoStd-Regular, monospace;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.625rem; /*10px;*/
  line-height: 1rem; /*16px;*/
  letter-spacing: 1.5px;
  margin: 0;
`

/**
 * Extra styles
 */
export const LightText1 = styled.span`
  font-family: FavoritStd-Light, sans-serif;
  font-weight: 300;
  font-size: 3.625rem; /*58px;*/
  line-height: 3.625rem; /*58px;*/
  letter-spacing: 0.41px;
`

export const LightText2 = styled.span`
  /*font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', Helvetica, Arial, 'FreeSans', sans-serif;*/
  font-family: FavoritStd-Light, sans-serif;
  font-weight: 300;
  font-size: 2.125rem; /*34px*/
  line-height: 2.125rem; /*34px;*/
  letter-spacing: 0.41px;
`

export const LightText3 = styled.span`
  /*font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', Helvetica, Arial, 'FreeSans', sans-serif;*/
  font-family: FavoritStd-Light, sans-serif;
  font-weight: 300;
  font-size: 2rem; /*32px;*/
  line-height: 2rem; /*32px;*/
  letter-spacing: 0.41px;
`

export const LightText4 = styled.span`
  /*font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', Helvetica, Arial, 'FreeSans', sans-serif;*/
  font-family: FavoritStd-Light, sans-serif;
  font-weight: 300;
  font-size: 1.25rem; /*20px;*/
  line-height: 1.25rem; /*20px;*/
  letter-spacing: 0.25px;
`

const lightTexts = [LightText1, LightText2, LightText3, LightText4]
export const LightText = (props: HProps) => {
  const validLevel = props.level > 0 && props.level <= lightTexts.length ? props.level : 1
  const Comp = lightTexts[validLevel - 1]
  return <Comp style={{color: props.color, textTransform: props.textTransform}}>{props.children}</Comp>
}

/**
 * Defines Term
 */
export const DL = styled.dl`
  margin: 0;
  padding: 0;
`
export const DT = styled.dt`
  font-family: FavoritStd-Book;
  font-size: 1.25rem; /* 20px; */
  line-height: 2.75rem; /* 44px;*/
  letter-spacing: 0.63;
`
export const DD = styled.dd`
  font-family: FavoritStd-Book;
  font-weight: normal;
  font-size: 1.25rem; /* 20px; */
  line-height: 2.75rem; /* 44px;*/
  letter-spacing: 0.63;
  margin: 0;
`

/*
 * List styles
 */
type ListProps = {
  listStyle?: 'circle' | 'square' | 'upper-roman' | 'lower-alpha' | 'default'
}

export const LI = styled.li<ListProps>`
  /*font-family: 'HelveticaNeue-Lite', 'Helvetica Neue Lite', Helvetica, Arial, 'FreeSans', sans-serif;*/
  font-family: FavoritMonoStd-Regular, monospace;
  font-weight: 300;
  line-height: 1.5rem; /*24px;*/
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  list-style: ${props => props.listStyle || 'none'};
`
export const UL = styled.ul`
  padding: 0 0 0 1rem;
  margin: 0;
  font-size: 1rem; /* 16px; */
`
