import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {MainLayoutWithoutStretch, MainContent, RightSidebar} from '../../../Layout/Layout'
import {Button, ButtonRowGrid, ButtonRowWrap} from '../../../Atoms/Buttons'
import TypeBackHeaderTitle, {MenuRow} from '../../../Molecules/ViewComponents/ViewHeader'
import {AlarmSetting} from '../../../../state/rest'
import {BigLabelInput} from '../../../Atoms/Forms'
import {InvisibleContainer, Grid} from '../../../Layout/Grid'
import {useAlarmRuleForm} from './AlarmSettingsUtils'
import LocationsList from '../../../Organisms/Locations/LocationsList'
import DevicesList from '../../../Organisms/Devices/DevicesList'
import AlarmMethodWidget from './AlarmMethodWidget'
import AlarmRecipientsWidget from './AlarmRecipientsWidget'
import WeekScheduleList from '../../../Organisms/Scheduler/WeekScheduleList'
import {Helmet} from 'react-navi-helmet-async'
import AlarmNotificationDelay from './AlarmNotificationDelay'

interface FormProps {
  defaultValues?: AlarmSetting
}
const EditAlarmRule: FC<FormProps> = ({defaultValues}: FormProps): any => {
  const {t} = useTranslation('alarms')

  // fetch folder data (if editing)
  // TODO! fetch when loading the route?
  const saved = defaultValues ? defaultValues : undefined
  const fc = useAlarmRuleForm(saved)

  const isEditing = !!saved

  return (
    <MainLayoutWithoutStretch>
      {isEditing ? (
        <Helmet title={t('common:routes.editAlarmRule', 'Edit alarm rule')} />
      ) : (
        <Helmet title={t('common:routes.createAlarmRule', 'Create alarm rule')} />
      )}
      <MainContent variant="white">
        <MenuRow showMenu={isEditing} onDelete={fc.remove}>
          <TypeBackHeaderTitle
            label={t('common:backHeaderTitles.alarmRule', 'Alarm rule')}
            path={fc.backPath}
            backLabel={t('common:routes.alarmMessageSettings', 'Alarm message settings')}
            isEditing={isEditing}
          />
        </MenuRow>
        <InvisibleContainer>
          <Grid>
            <div>
              <BigLabelInput
                required
                placeholder={t('alarms:placeholders.newAlarmRule', 'New alarm rule name')}
                labelText={t('alarms:labels.newAlarmRule', 'Alarm rule name')}
                name="name"
                id="name"
                ref={fc.register({required: true})}
                errorMsg={!!fc.errors.name ? t('common:validation.nameRequired', 'Name field is required') : undefined}
              />
              <WeekScheduleList controller={fc.sc} />
            </div>
            <div>
              <AlarmRecipientsWidget controller={fc.uc} />
              <AlarmMethodWidget controller={fc.amc} />
              <AlarmNotificationDelay controller={fc} />
              <DevicesList
                controller={fc.devices}
                cyKey={'alarm-rule-devices'}
                label={t('alarms:labels.selectDeviceTargets', 'Select which device alarms are sent')}
              />
            </div>
          </Grid>
          <ButtonRowWrap>
            <div className="empty"></div>
            <ButtonRowGrid>
              <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                {t('common:buttons.cancel', 'Cancel')}
              </Button>
              <Button
                variant="secondary"
                disabled={
                  !fc.formState.isValid ||
                  fc.loading ||
                  fc.sc.schedules.length === 0 ||
                  fc.amc.selected.length === 0 ||
                  !fc.sc.isValid
                }
                onClick={() => (isEditing ? fc.update() : fc.submit())}
              >
                {t('common:buttons.save', 'Save')}
              </Button>
            </ButtonRowGrid>
          </ButtonRowWrap>
        </InvisibleContainer>
      </MainContent>
      <RightSidebar>
        <LocationsList
          controller={fc.rc}
          cyKey={'alarm-rule'}
          label={t('alarms:labels.selectAlarmTargets', 'Select which sites alarms are sent')}
        />
      </RightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default EditAlarmRule
