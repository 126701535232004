import React, {useState, useRef, ReactNode} from 'react'
import {useAppState} from '../../../state'
import {useTranslation} from 'react-i18next'
import {Grid, Baseline, InvisibleContainer, GridSpan} from '../../Layout/Grid'
import {Select, Toggler, BigLabelInput, Label, LabelInputWrapper} from '../../Atoms/Forms'
import styled from 'styled-components'
import {IconButton, Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {IconCloseSmall} from '../../../Assets/Icons/TinyIcons'
import {useTaskForm, useTaskGroupSelect, useRestaurantList} from '../../../config/utils'
import {colors} from '../../../sharedComponents/colors'
import {TodoTaskResponse} from '../../../state/rest'
import Scheduler from '../../Organisms/Scheduler/Scheduler'
import {MainLayoutWithoutStretch, RightSidebar, MainContent} from '../../Layout/Layout'
import LocationsList from '../../Organisms/Locations/LocationsList'
import {compact} from 'lodash'
import {HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import TaskMenuModal from './TaskMenuModal'
import {P} from '../../Atoms/Typography'
import {CommonInformationFields, TasksGoBackHeaderTitle} from './CommonTasksComponents'

interface FormProps {
  savedData?: TodoTaskResponse
}
const TASK_KEY = 'todo'

const CreateTodoTask = ({savedData}: FormProps) => {
  const {t} = useTranslation('tasks')
  const {state} = useAppState()

  const isEditing = !!savedData && !!savedData.id
  const isDuplicate = !!savedData && !savedData.id

  const saved = savedData
    ? isDuplicate && savedData.name
      ? {
          ...savedData,
          name: `${t('tasks:labels.copyOf', 'Copy of')} ${savedData.name.toLowerCase()}`,
          sites: [state.site!]
        }
      : savedData
    : undefined

  // task form controller hook
  const fc = useTaskForm(TASK_KEY, saved)
  const rc = useRestaurantList(isDuplicate ? undefined : saved ? saved.sites : undefined)

  const {taskGroupOptions, selectTaskGroup, selectedTaskGroup} = useTaskGroupSelect(
    TASK_KEY,
    saved ? saved.taskGroup : undefined
  )

  const [subTasks, setSubtasks] = useState(saved ? saved.subTasks : [])

  const [showUntilCompleted, setShowUntilCompleted] = useState(saved ? saved.showUntilCompleted : false)
  const handleTogglerChange = (value: boolean) => {
    setShowUntilCompleted(value)
  }

  const taskPayload = {
    taskGroup: selectedTaskGroup,
    subTasks: subTasks,
    assets: fc.assets,
    schedule: fc.scheduledPayload,
    sites: rc.list,
    showUntilCompleted: showUntilCompleted
  }

  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow
          modal={
            isEditing && (
              <TaskMenuModal
                isOpen={fc.modalOpen}
                onClose={() => fc.setModalOpen(false)}
                onDelete={fc.editingDisabled ? undefined : fc.remove}
                onDuplicate={fc.duplicate}
              />
            )
          }
        >
          <TasksGoBackHeaderTitle
            label={
              isEditing
                ? t('tasks:labels.editTask.todo', 'Edit checklist task')
                : t('tasks:labels.newTask.todo', 'Add checklist task')
            }
            isEditing={isEditing}
          />
          {isEditing && <HeaderActions onMenu={() => fc.setModalOpen(true)} />}
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <Grid>
              <BigLabelInput
                required
                placeholder={t('tasks:placeholders.newTaskName', 'New task name')}
                name="name"
                id="name"
                labelText={t('tasks:labels.taskName', 'Task name')}
                ref={fc.register({required: `${t('common:validation.requiredField', 'Required field')}`})}
                errorMsg={!!fc.errors.name ? fc.errors.name!.message : undefined}
              ></BigLabelInput>
              <div className="empty"></div>
            </Grid>
            <Grid>
              <Baseline>
                <Select
                  returnId
                  nativeProps={{
                    name: 'taskGroup',
                    onChange(e) {
                      selectTaskGroup(e.target.value)
                    },
                    value: selectedTaskGroup.id
                  }}
                  id="taskGroup"
                  label={t('tasks:labels.taskGroup', 'Task group')}
                  options={taskGroupOptions}
                />

                <div>
                  <LabelInputWrapper>
                    <Label>{t('tasks:labels.addSubtasks', 'Add subtasks')}</Label>
                  </LabelInputWrapper>
                  <TodoWidget
                    onChange={items => {
                      setSubtasks(compact(items.map(s => s.trim())))
                    }}
                    savedTodos={subTasks}
                    buttonText={t('tasks:labels.addSubtask', 'Add a subtask')}
                    placeholderText={t('tasks:placeholders.checklistItem', 'Checklist item')}
                  />
                </div>

                <Toggler
                  label={t('tasks:labels.showUntilCompleted', 'Show until completed')}
                  initiallyChecked={showUntilCompleted}
                  name="isShowUntilCompletedTask"
                  values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
                  onClick={() => handleTogglerChange(!showUntilCompleted)}
                />

                <Toggler
                  label={t('tasks:labels.scheduledTask', 'Scheduled task')}
                  values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
                  initiallyChecked={!!saved && !!saved.schedule}
                  name="isScheduledTask"
                  onClick={() => {
                    fc.toggleShow()
                  }}
                />

                {!!fc.scheduledPayload ? (
                  <Scheduler
                    onChange={fc.handleScheduler}
                    schedule={saved ? fc.reverseSchedule(saved.schedule) : undefined}
                  />
                ) : null}
              </Baseline>

              <Baseline>
                <CommonInformationFields useForm={fc} />
              </Baseline>
            </Grid>

            <ButtonRowWrap>
              <div className="empty"></div>
              <ButtonRowGrid>
                <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                  {t('common:buttons.cancel', 'Cancel')}
                </Button>
                <Button
                  variant="secondary"
                  disabled={!fc.formState.isValid || fc.loading || rc.list.length === 0 || fc.editingDisabled}
                  onClick={() => (isEditing ? fc.update(taskPayload) : fc.submit(taskPayload))}
                >
                  {t('common:buttons.save', 'Save')}
                </Button>

                {fc.editingDisabled && (
                  <GridSpan span={2}>
                    <P>
                      {t(
                        'tasks:messages.taskInUseMessage',
                        'This task is in use for locations you don’t have access to. You can not edit or delete it but you can duplicate the task to create a new one'
                      )}
                    </P>
                  </GridSpan>
                )}
              </ButtonRowGrid>
            </ButtonRowWrap>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <RightSidebar>
        <LocationsList
          controller={rc}
          cyKey={'task'}
          label={t('tasks:labels.selectLocation', 'Select which locations use this task')}
        />
      </RightSidebar>
    </MainLayoutWithoutStretch>
  )
}

const TodoItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  .icon {
    width: 15px;
    height: 15px;
    border: 1px solid gray;
  }
  input.todoItem {
    margin-left: 1rem;
    margin-right: 1rem;
    outline: none;
    border: none;
    min-width: 200px;
    width: auto;
    &::placeholder {
      color: gray;
    }
  }
  .removeIcon {
  }
`

const TodoList = styled.ul`
  margin: 0;
  padding: 0;
`

interface TodoWidgetProps {
  onChange: (items: string[]) => void
  savedTodos?: string[]
  buttonText: string
  placeholderText: string
  icon?: ReactNode
}

const FakeInputButton = styled.button`
  background-color: white;
  border: none;
  color: ${colors.system.grey_1};
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0;
  .icon {
    width: 15px;
    height: 15px;
    border: 1px solid gray;
  }
  .content {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

export const TodoWidget = ({onChange, savedTodos, placeholderText, buttonText, icon}: TodoWidgetProps) => {
  const [todos, setTodos] = useState<string[]>(savedTodos || [])
  const todoList = useRef<HTMLUListElement>(null)
  const handleRemove = (index: number) => {
    const newTodos = todos.filter((_, i) => i !== index)
    setTodos(newTodos)
    onChange(newTodos)
  }
  const handleAdd = () => {
    const newTodos = [...todos, '']
    setTodos(() => newTodos)
    onChange(newTodos)
  }

  const handleEdit = (index: number, label: string) => {
    const newTodos = [...todos]
    newTodos[index] = label
    setTodos(newTodos)

    onChange(newTodos)
  }

  return (
    <Baseline>
      <TodoList ref={todoList}>
        {todos.map((todo, index) => (
          <TodoItem key={todo + index}>
            {icon || <div className="icon" aria-hidden="true"></div>}
            <form
              onSubmit={e => {
                e.preventDefault()
                handleAdd()
              }}
            >
              <input
                placeholder={placeholderText}
                aria-label={`edit item: ${todo}`}
                className="todoItem"
                value={todo}
                onChange={e => handleEdit(index, e.target.value)}
                autoFocus
              ></input>
            </form>
            <IconButton
              icon={<IconCloseSmall />}
              buttonProps={{className: 'removeIcon', onClick: () => handleRemove(index)}}
            />
          </TodoItem>
        ))}
      </TodoList>

      <FakeInputButton onClick={() => handleAdd()}>
        {icon || <div className="icon" aria-hidden="true"></div>}
        <div className="content">{buttonText}</div>
      </FakeInputButton>
    </Baseline>
  )
}

export default CreateTodoTask
