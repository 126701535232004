import React from 'react'
import {useTranslation} from 'react-i18next'
import {AdjustableContainer} from '../../Layout/Layout'
import {useAppState} from '../../../state'
import styled from 'styled-components'
import {Label, LabelCheckbox} from '../../Atoms/Forms'
import {DeviceTypeObject} from '../../../config/utils'
import {Text} from '../../Atoms/Typography'
import {colors} from '../../../sharedComponents/colors'
import {capitalizeFirstLetter} from '../../Atoms/Utils'

interface Props {
  controller: any
  cyKey: string
  label: string
}
const DevicesList = ({controller, cyKey, label}: Props) => {
  const {state} = useAppState()
  const {t} = useTranslation('alarms')
  const chain = state.chainsById[state.selectedChainId!]
  const deviceTypes = controller.DEVICE_TYPES as DeviceTypeObject
  const selections = Object.keys(deviceTypes)

  const translateDevices = (deviceLabel: string) => {
    switch (deviceLabel) {
      case 'storage':
        return t('alarms:labels.deviceType.storage', 'Storage')
      case 'diswasher':
        return t('alarms:labels.deviceType.dishwasher', 'Dishwasher')
      case 'cooler':
        return t('alarms:labels.deviceType.cooler', 'Cooler')
      default:
        return deviceLabel
    }
  }

  return !!chain ? (
    <DeviceSelection>
      <div key={chain.id} data-cy={`${cyKey}-site-selector`}>
        <Label>{label}</Label>
        <AdjustableContainer id={'method-select'} padding="1rem 0rem">
          {selections.map(select => {
            const translated = translateDevices(select)
            return (
              <LabelCheckbox
                flipped={false}
                label={capitalizeFirstLetter(translated)}
                ref={controller.register}
                name={select}
                key={select}
              />
            )
          })}
        </AdjustableContainer>
      </div>
      <Text level={3} color={colors.system.grey_1}>
        {t(
          'alarms:setting.deviceList.disclaimer',
          'Editing the alarms will only affect high, low and duration exceeded alarms of the select appliance types. Other alarms, such as sensor disconnects, will always generate alarm notifications.'
        )}
      </Text>
    </DeviceSelection>
  ) : (
    <div>{t('common:errors.undefinedError', 'Something went wrong')}</div>
  )
}
const DeviceSelection = styled.div`
  margin: 3rem 0 1rem 0;
`
export default DevicesList
