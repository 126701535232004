import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconDelivery = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.507 32.8593C39.4391 32.8593 38.5771 33.7182 38.5771 34.7823C38.5771 35.8464 39.4391 36.7054 40.507 36.7054C41.5748 36.7054 42.4368 35.8464 42.4368 34.7823C42.4368 33.7182 41.5748 32.8593 40.507 32.8593M40.5064 37.9874C38.731 37.9874 37.29 36.5515 37.29 34.7823C37.29 33.013 38.731 31.5771 40.5064 31.5771C42.2819 31.5771 43.7228 33.013 43.7228 34.7823C43.7228 36.5515 42.2819 37.9874 40.5064 37.9874"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5226 32.8717C18.4548 32.8717 17.5928 33.7307 17.5928 34.7948C17.5928 35.8589 18.4548 36.7179 19.5226 36.7179C20.5904 36.7179 21.4524 35.8589 21.4524 34.7948C21.4524 33.7307 20.5776 32.8717 19.5226 32.8717M19.523 37.9997C17.7476 37.9997 16.3066 36.5638 16.3066 34.7946C16.3066 33.0254 17.7476 31.5895 19.523 31.5895C21.2985 31.5895 22.7394 33.0254 22.7394 34.7946C22.7394 36.5638 21.2985 37.9997 19.523 37.9997"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1328 30.1794H46.0007V28.8973H14.1328V30.1794Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0081 14.3589C36.6467 14.3589 42.0631 19.5641 42.3976 26.1025H17.6187C17.9532 19.5641 23.3824 14.3589 30.0081 14.3589V14.3589ZM43.6839 26.0897C43.3494 19.0641 37.7014 13.4359 30.6511 13.1026V10H29.3646V13.1026C22.3143 13.4359 16.6663 19.0641 16.3318 26.0897H14.1318V27.3718H45.9997V26.0897H43.6839Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 23.5129H14.5825V22.2308H2V23.5129Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.49316 19.7564H16.0745V18.4744H7.49316V19.7564Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9736 16.0128H18.9432V14.7307H12.9736V16.0128Z" />
    </svg>
  )
}
