import React, {useState} from 'react'
import {Button, ModalProps} from '../sharedComponents/components'
import styled from 'styled-components'
import {Modal} from '../sharedComponents/components'
import {colors} from '../sharedComponents/colors'
import {Message} from '../state/messages/state'
import {useTranslation} from 'react-i18next'
import LoadingWithPuff from '../Components/Atoms/LoadingWithPuff'
import {Heading} from '../Components/Atoms/Typography'
import {useAppState} from '../state'

interface MessageModalProps extends ModalProps {
  message: Message | null
}

export const MessageModal: React.FC<MessageModalProps> = ({isOpen, onClose, message, ...rest}) => {
  const {t} = useTranslation(['messages', 'common'])
  const {state, actions} = useAppState()

  const isRead = state.v1.messages.messagesByStatus.readMessages.find(msg => msg.id === message?.id)

  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onButtonClick = async (id: string, isRead: boolean) => {
    setIsLoading(true)
    await actions.v1.messages.changeMessageStatus({isRead, id})
    setIsLoading(false)
    onClose?.()
  }

  const resetModal = () => {
    setSubmitting(false)
  }

  const onResetAndClose = () => {
    resetModal()
    onClose?.()
  }

  return (
    <Modal
      id="messageModal"
      minHeight="80vh"
      maxHeight="80vh"
      maxWidth="80vh"
      headerHeight="10vh"
      isOpen={isOpen}
      onClose={submitting ? undefined : onResetAndClose}
      headerLeftItem={
        <Button
          buttonStyle="secondary"
          style={{fontSize: '2vh', lineHeight: '2vh'}}
          buttonSize="small"
          onClick={onResetAndClose}
          disabled={submitting}
        >
          {t('common:buttons.cancel', 'Cancel')}
        </Button>
      }
      headerBackgroundColor={!isRead ? colors.system.red : colors.brand.cobalt_60}
      {...rest}
    >
      {isLoading || message === null ? (
        <LoadingWithPuff />
      ) : (
        <MessageModalContainer>
          <MessageContainer>
            <HeaderContainer>
              <Heading data-cy="card-heading" level={2} color={colors.brand.black}>
                {message.heading}
              </Heading>
            </HeaderContainer>
            <ContentContainer>{message.content}</ContentContainer>
          </MessageContainer>
          <ButtonContainer>
            <Button
              buttonStyle="primary"
              buttonSize="medium"
              onClick={() => onButtonClick(message.id, !isRead)}
              data-cy="mark-message-read-or-unread"
              style={{fontSize: '2.2vh', lineHeight: '2.5vh'}}
            >
              {!isRead
                ? t('messages:modal.buttons.markAsRead', 'Mark message read')
                : t('messages:modal.buttons.markAsUnread', 'Mark message unread')}
            </Button>
          </ButtonContainer>
        </MessageModalContainer>
      )}
    </Modal>
  )
}

const HeaderContainer = styled.div`
  border-bottom: 1px ${colors.system.grey_5} solid;
  padding-bottom: 1vh;
  height: 10vh;
`

const ContentContainer = styled.div`
  text-align: left;
  padding: 1rem;
  overflow-y: scroll;
  height: 40vh;
  white-space: pre-line;
`

const MessageModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures button stays at the bottom */
  padding: 1rem;
  height: 70vh;
  border: 0.5px solid white;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Align the button to the bottom-right */
  padding-top: 1rem;
  margin-top: auto;
  height: 8vh;
`

const MessageContainer = styled.div`
  padding: 1rem 1.5rem;
  flex-grow: 1; /* Ensure message content takes available space */
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  height: 50vh;
`
