import React from 'react'
import styled from 'styled-components'
import {CGIWhiteLogo} from '../../../Assets/Icons/BrandIcons'

export const LoginLogo = () => {
  return (
    <LogoWrapper>
      <CGIWhiteLogo width="22em" />
    </LogoWrapper>
  )
}

const LogoWrapper = styled.div`
  margin-bottom: 2.5rem;
`

export const TitleWrapper = styled.div`
  margin-bottom: 2.5rem;

  > * + * {
    margin-top: 1rem;
  }
`
