import React from 'react'
import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import {Property as CSSProperty} from 'csstype'
import {UnitH3, SmallUnit} from '../Atoms/Typography'

export const Grid = styled.div<{columnWidth?: string; gap?: string; vgap?: string; responsive?: boolean}>`
  display: grid;
  grid-gap: ${props => (props.vgap ? props.vgap : '2rem')};
  grid-column-gap: ${props => (props.gap ? props.gap : '2rem')};
  grid-template-columns: repeat(auto-fit, minmax(${props => (props.columnWidth ? props.columnWidth : '300px')}, 1fr));
  align-items: flex-start;
`
export const GridSpan = styled.div<{span: number}>`
  grid-column: span ${props => props.span};
`

GridSpan.defaultProps = {
  span: 1
}
interface RowProps extends React.CSSProperties {
  justify?: CSSProperty.JustifyContent
  align?: CSSProperty.JustifyContent
  margin?: CSSProperty.Margin<'rem'>
  childMargin?: string
}
export const Row = styled.div<RowProps>`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: ${p => p.justify};
  align-items: ${p => p.align || 'flex-start'};
  justify: flex-start;
  margin: ${props => (props.margin ? props.margin : '')};
  ${UnitH3} {
    margin-top: 2.5rem;
    align-self: flex-start;
  }
  ${SmallUnit} {
    margin-top: 2.5rem;
    align-self: flex-start;
  }
  > * + * {
    margin-left: ${p => p.childMargin};
  }
`
export const IconRow = styled.div<RowProps>`
  display: flex;
  justify-content: ${p => p.justify};

  > * svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`
export const Baseline = styled.div<{margin?: string}>`
  position: relative;
  > * + * {
    margin-top: ${p => p.margin || '2em'};
  }
`

export const TitleRow = styled.div`
  position: relative;
  margin: 1.5rem 0;
  padding: 0 2rem;
`

interface ContainerProps {
  height?: number
  width?: number
}
const Container = styled.div<ContainerProps>`
  /* display: inline-block; */
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  background-color: ${colors.system.lightGrey_5};
  padding: 0 2rem;
  border-radius: 5px;
`

export const InvisibleContainer = styled(Container)`
  background-color: transparent;
  border-radius: 0;
  margin: 2rem 0;
`

interface FlexTypes {
  direction?: CSSProperty.FlexDirection
  wrap?: CSSProperty.FlexWrap
  childMargin?: CSSProperty.Margin<'rem'>
  justify?: CSSProperty.JustifyContent
  alignItems?: CSSProperty.JustifyContent
}
export const Flex = styled.div<FlexTypes>`
  display: flex;
  width: 100%;

  flex-direction: ${props => props.direction};
  flex-wrap: ${props => props.wrap};
  justify-content: ${props => props.justify};
  align-items: ${props => props.alignItems};
  > * {
    margin: ${props => props.childMargin};
  }
`
Flex.defaultProps = {
  direction: 'row',
  wrap: 'nowrap',
  justify: 'start'
}
