import {Alarm} from '../alarms/state'
import {AnyAlarmData, EquipmentTypeKey, EventBase, SensorRole, SensorMetadata} from '../rest'

interface AppliancesState {
  appliances: {
    data: {
      appliances?: Appliance[]
    }
    loading: boolean
    error?: Error
  }
  sensorMetadata: {
    data: {
      sensorId?: string
      sensorMetadata?: SensorMetadata[]
    }
    loading: boolean
    error?: Error
  }
  sensorAlarms: {
    data: {
      sensorId?: string
      sensorAlarms?: Alarm[]
    }
    loading: boolean
    error?: Error
  }
  sensorRecords: {
    [key in MeasurementType]: {
      data: {
        sensorId?: string
        records?: SensorRecord[]
        statistics?: SensorRecordStatistics
      }
      loading: boolean
      error?: Error
    }
  }
  coolingCycles: {
    data: {
      applianceId?: string
      coolingCycles?: CoolingCycle[]
      count?: number
    }
    loading: boolean
    error?: Error
  }
  applianceStatuses: {
    data: {
      applianceId?: string
      applianceStatuses?: ApplianceStatus[]
    }
    loading: boolean
    error?: Error
  }
}

export const state: AppliancesState = {
  appliances: {
    data: {},
    loading: false,
    error: undefined
  },
  sensorMetadata: {
    data: {},
    loading: false,
    error: undefined
  },
  sensorAlarms: {
    data: {},
    loading: false,
    error: undefined
  },
  sensorRecords: {
    temperature: {
      data: {},
      loading: false,
      error: undefined
    },
    relativeHumidity: {
      data: {},
      loading: false,
      error: undefined
    },
    batteryLevel: {
      data: {},
      loading: false,
      error: undefined
    },
    weight: {
      data: {},
      loading: false,
      error: undefined
    }
  },
  coolingCycles: {
    data: {},
    loading: false,
    error: undefined
  },
  applianceStatuses: {
    data: {},
    loading: false,
    error: undefined
  }
}

export enum MeasurementType {
  temperature = 'temperature',
  relativeHumidity = 'relativeHumidity',
  batteryLevel = 'batteryLevel',
  weight = 'weight'
}

export interface SensorValue {
  value: number
  timestamp: string
  receivedAt?: string
  signalMetadata?: {
    rssi?: number
    snr?: number
  }
}

export interface Appliance {
  id: string
  name: string
  type: EquipmentTypeKey
  suspended: boolean
  sensors?: Sensor[]
  icon: string
  unresolvedAlarmCount?: number
}

export interface Sensor {
  id: string
  role: SensorRole
  measurementTypes?: MeasurementTypes
  // Cooler
  isCooling?: boolean
  // Cooler, dishwasher
  cycleStartedAt?: string
  cycleEndedAt?: string
  context?: {
    // Nokeval
    sensorNumber?: string | null
    gatewayNumber?: string | null
    // LoRaWAN
    devEui?: string
  } | null
}

interface MeasurementTypes {
  [MeasurementType.temperature]?: TemperatureMeasurementType | null
  [MeasurementType.relativeHumidity]?: RelativeHumidityMeasurementType | null
  [MeasurementType.batteryLevel]?: BatteryLevelMeasurementType | null
  [MeasurementType.weight]?: WeightMeasurementType | null
}

interface MeasurementTypeBase {
  unit?: string
  latestValue?: SensorValue
  hasActiveAlarm?: boolean
}

export interface TemperatureMeasurementType extends MeasurementTypeBase {
  type: MeasurementType.temperature
  alarmRule?: RangeAlarmRule | CoolerAlarmRule
}

export interface RelativeHumidityMeasurementType extends MeasurementTypeBase {
  type: MeasurementType.relativeHumidity
  alarmRule?: RangeAlarmRule
}

interface BatteryLevelMeasurementType extends MeasurementTypeBase {
  type: MeasurementType.batteryLevel
  alarmRule?: null
}

interface WeightMeasurementType extends MeasurementTypeBase {
  type: MeasurementType.weight
  alarmRule?: null
}

export type RangeAlarmRule = {
  minValue: number
  maxValue: number
  duration: number
}

export type CoolerAlarmRule = {
  minValue: number
  maxValue: number
  targetValue: number
  maxTime: number
  idleLimit: number
}

export type ApplianceStatus = {
  id: string
  reason: string
  updatedBy: string
  startDate: string
  endDate?: string
}

export type CoolingCycle = {
  id: string
  startedAt: string
  endedAt: string
  firstMeasurement: number
  lastMeasurement: number
  startLimit: number
  targetLimit: number
  event?: EventBase
  alarms: AnyAlarmData[]
}

export type SensorRecord = {
  sensorUuid: string
  sensorId: string
  gatewayId: string
  timestamp: string
  receivedAt: string
  value: number
  unit: 'celcius'
  measurementType: 'temperature'
}

export type SensorRecordStatistics = {
  average: number
  min: number
  max: number
}

export type SensorRecordResponse = {
  records: SensorRecord[]
  statistics: SensorRecordStatistics
}
