import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconLateTask = (props: SharedIconProps) => {
  const fill = props.fill || colors.brand.cobalt
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0006 1.17701C5.12611 1.17701 1.1764 5.12682 1.1764 10C1.1764 14.8732 5.12611 18.823 10.0006 18.823C14.8738 18.823 18.8236 14.8733 18.8236 10C18.8236 5.12677 14.8738 1.17701 10.0006 1.17701ZM0 10C0 4.47702 4.4765 0.000610352 10.0006 0.000610352C15.5235 0.000610352 20 4.47706 20 10C20 15.523 15.5235 19.9994 10.0006 19.9994C4.4765 19.9994 0 15.523 0 10Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40674 5.23901H10.5831V9.99658L15.721 14.5289L14.9428 15.4111L9.40674 10.5275V5.23901Z"
        fill={fill}
      />
    </svg>
  )
}
