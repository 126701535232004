import React from 'react'
import {colors} from '../../../colors'
import {SwitchContainer, SwitchInput, SwitchLabel, SwitchToggle, checkedColors} from '../Switch/Switch'

export type SwitchReversedProps = React.InputHTMLAttributes<HTMLInputElement>

export const SwitchReversed: React.FC<SwitchReversedProps> = props => {
  return (
    <SwitchContainer>
      <SwitchInput {...props} type="checkbox"></SwitchInput>
      <SwitchToggle checked={props.checked} checkedColor={checkedColors.reversed}></SwitchToggle>
      <SwitchLabel color={props.checked ? colors.system.grey_1 : colors.system.white}>On</SwitchLabel>
      <SwitchLabel color={props.checked ? colors.system.white : colors.system.grey_1}>Off</SwitchLabel>
    </SwitchContainer>
  )
}
