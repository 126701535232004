import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconCooking = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54297 20.9226V38.3865C9.54297 40.7941 11.5167 42.7525 13.943 42.7525H34.0573C36.4835 42.7525 38.4573 40.7941 38.4573 38.3865V20.9226H9.54297ZM34.0571 44H13.9428C10.8238 44 8.28564 41.4814 8.28564 38.3866V19.6752H39.7142V38.3866C39.7142 41.4814 37.176 44 34.0571 44V44Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7145 39.0102H15.2002C14.1605 39.0102 13.3145 38.1707 13.3145 37.1391V29.0308H14.5716V37.1391C14.5716 37.4821 14.8532 37.7628 15.2002 37.7628H17.7145V39.0102Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25684 27.7835H8.28541V25.2886H3.25684V27.7835ZM2 29.0308H9.54286V24.0411H2V29.0308Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7144 27.7835H44.7429V25.2886H39.7144V27.7835ZM38.457 29.0308H45.9999V24.0411H38.457V29.0308Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0438 17.3399H24.4077C24.4077 13.6151 24.4065 8.97841 27.3771 5.94716C29.8738 3.40116 33.9934 3.34503 36.5593 5.82117C38.6612 7.85073 38.709 11.1988 36.6636 13.2858C35.8477 14.1191 34.7527 14.5856 33.5836 14.6018C32.4182 14.6143 31.3057 14.1814 30.4671 13.3718C29.7732 12.7007 29.3822 11.8013 29.3684 10.8383C29.3558 9.87656 29.7204 8.96593 30.3967 8.27611C30.9599 7.70104 31.7167 7.37671 32.5263 7.36673C33.3322 7.36798 34.1003 7.65738 34.6823 8.21748C35.1714 8.68901 35.4442 9.32145 35.453 9.99631C35.463 10.6724 35.2066 11.3111 34.7314 11.7951L33.83 10.9256C34.0714 10.6799 34.2009 10.3568 34.1958 10.0138C34.1908 9.67073 34.0525 9.35139 33.8061 9.11188C33.4654 8.78381 33.0116 8.61915 32.5439 8.61416C32.07 8.6204 31.6275 8.80876 31.2969 9.14556C30.8556 9.59713 30.6155 10.1922 30.6255 10.8209C30.6343 11.4508 30.8895 12.0383 31.3446 12.4774C31.943 13.055 32.7702 13.3269 33.566 13.3544C34.4007 13.3432 35.1802 13.0101 35.7622 12.4163C37.3236 10.8221 37.2884 8.26488 35.683 6.71558C33.615 4.71969 30.2924 4.7646 28.2785 6.81787C25.6636 9.48486 25.6649 13.6588 25.6649 17.3399H25.0438Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8578 17.492H20.6007L20.6019 17.0242C20.6107 14.7526 20.622 12.178 18.6823 10.8519C17.0832 9.76044 14.8907 10.1596 13.7895 11.7463C13.3821 12.3326 13.2288 13.0412 13.3595 13.7422C13.489 14.4433 13.8863 15.052 14.4771 15.4562C14.9221 15.7606 15.4589 15.8766 15.9932 15.778C16.525 15.6807 16.9876 15.3838 17.2944 14.9423C17.5207 14.6154 17.6061 14.2212 17.5345 13.832C17.4616 13.4428 17.2403 13.1035 16.9122 12.879L17.625 11.8524C18.2309 12.2665 18.637 12.889 18.7703 13.6063C18.9035 14.3235 18.7464 15.0495 18.329 15.6495C17.8324 16.3656 17.0844 16.8471 16.2208 17.0042C15.3609 17.1614 14.4872 16.9768 13.7643 16.4828C12.8969 15.8903 12.3136 14.9971 12.1225 13.968C11.9314 12.9401 12.1564 11.8985 12.7548 11.0378C14.2483 8.88724 17.2265 8.34212 19.3963 9.82531C21.8817 11.5243 21.8691 14.5768 21.8591 17.0292L21.8578 17.492Z"
      />
    </svg>
  )
}
