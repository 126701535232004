import {AsyncAction} from 'overmind'

export const getTaskGroups: AsyncAction<void> = async ({state, effects}) => {
  state.v1.settings.taskGroups.pending = true
  state.v1.settings.taskGroups.data = []
  state.v1.settings.taskGroups.error = null
  try {
    const taskGroups = await effects.v1.settings.taskGroups.getTaskGroups()
    state.v1.settings.taskGroups.data = taskGroups
  } catch (err) {
    state.v1.settings.taskGroups.error = err
  } finally {
    state.v1.settings.taskGroups.pending = false
  }
}
