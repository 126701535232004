import React from 'react'

/**
 * Add logo and other branding related icons here
 */

export const LogoRectangle = (props: any) => (
  <svg width="auto" height="auto" viewBox="0 0 1804 968" preserveAspectRatio="xMidYMid meet" {...props} fill="none">
    <path
      d="M0.299805 212.83V730.78H1803.7V212.8C1803.68 95.3899 1708.5 0.209951 1591.09 0.199951H212.91C95.4898 0.209951 0.299805 95.3999 0.299805 212.83Z"
      fill="black"
    />
    <path
      d="M0.299805 755.17C0.299805 872.6 95.4998 967.8 212.93 967.8H1591.07C1708.49 967.8 1803.68 872.62 1803.7 755.2V730.77H0.299805V755.17Z"
      fill="#FF8200"
    />
    <path
      d="M608.92 813.31H648.6C658.16 813.31 662.79 817.83 662.79 827.5V836.34C662.79 842.51 661.15 846.52 655.39 848.47C661.66 849.91 663.61 854.95 663.61 860.6V871.08C663.61 880.64 658.99 885.27 649.42 885.27H608.92V813.31ZM643.77 841.78C645.21 841.78 645.72 841.26 645.72 839.83V829.45C645.72 827.91 645.21 827.5 643.77 827.5H625.99V841.79H643.77V841.78ZM644.59 871.08C646.03 871.08 646.54 870.57 646.54 869.13V857.1C646.54 855.56 646.03 855.15 644.59 855.15H625.98V871.08H644.59Z"
      fill="black"
    />
    <path
      d="M686.01 861.83L662.78 813.31H681.28L694.64 844.56L707.9 813.31H726.2L703.07 861.83V885.27H686.01V861.83Z"
      fill="black"
    />
    <path
      d="M748.39 813.31H797.94V828.11H765.46V842.09H791.47V856.69H765.46V885.27H748.4V813.31H748.39Z"
      fill="black"
    />
    <path
      d="M860.43 885.27H842.65L830.62 860.5H820.65V885.27H803.59V813.31H843.68C853.24 813.31 857.87 817.83 857.87 827.5V845.49C857.87 853.61 854.68 858.03 848 859.26L860.43 885.27ZM838.84 846.31C840.28 846.31 840.79 845.9 840.79 844.36V829.76C840.79 828.22 840.28 827.81 838.84 827.81H820.64V846.31H838.84Z"
      fill="black"
    />
    <path
      d="M866.49 813.31H916.04V828.11H883.56V841.17H909.57V855.77H883.56V870.47H916.04V885.27H866.49V813.31Z"
      fill="black"
    />
    <path
      d="M923.42 813.31H964.54C974.1 813.31 978.73 817.83 978.73 827.5V871.08C978.73 880.64 974.1 885.27 964.54 885.27H923.42V813.31ZM959.71 870.46C961.15 870.46 961.66 869.95 961.66 868.51V830.06C961.66 828.52 961.15 828.11 959.71 828.11H940.49V870.46H959.71Z"
      fill="black"
    />
    <path
      d="M987.56 813.31H1006.58L1024.26 853.4L1041.84 813.31H1060.75V885.27H1044.1V841.58L1029.61 876.12H1018.3L1003.81 841.58V885.27H987.57V813.31H987.56Z"
      fill="black"
    />
    <path
      d="M1109.36 870.05H1086.44L1082.33 885.26H1064.65L1086.44 813.3H1109.98L1131.88 885.26H1113.48L1109.36 870.05ZM1105.45 855.56L1097.95 827.39L1090.34 855.56H1105.45Z"
      fill="black"
    />
    <path
      d="M1135.77 813.31H1153.14L1178.53 855.56V813.31H1195.08V885.27H1179.25L1152.32 840.66V885.27H1135.77V813.31Z"
      fill="black"
    />
    <path
      d="M216.53 377.31C216.53 307.21 257.9 254.21 325.95 254.21C375.53 254.21 415.88 283.62 418.27 331.15H394.33C391.94 296.27 361.85 276.44 327.65 276.44H324.23C269.52 276.44 240.46 322.26 240.46 377.31C240.46 432.36 269.52 478.18 324.23 478.18H327.65C361.84 478.18 391.94 458.35 394.33 423.47H418.27C415.88 471 375.53 500.41 325.95 500.41C257.9 500.41 216.53 447.4 216.53 377.31Z"
      fill="white"
    />
    <path
      d="M455.89 257.63H478.12V355.09C489.74 334.92 510.95 322.61 539.67 322.61C577.62 322.61 597.8 348.26 597.8 384.16V497H575.57V391C575.57 358.17 561.9 341.42 534.54 341.42H531.12C504.79 341.42 478.12 365.01 478.12 404.68V497H455.89V257.63Z"
      fill="white"
    />
    <path
      d="M749.96 394.4C748.94 363.97 731.5 341.4 704.48 341.4H701.06C679.52 341.4 654.55 353.71 649.77 394.4H749.96ZM626.87 411.16C626.87 351.32 663.8 322.6 703.12 322.6C744.84 322.6 772.19 355.76 772.19 399.53V413.21H649.09C649.78 461.43 674.74 481.6 702.78 481.6H704.83C728.08 481.6 743.13 471 749.62 448.09H771.85C764.33 482.28 738 500.41 703.12 500.41C660.37 500.41 626.87 469.63 626.87 411.16Z"
      fill="white"
    />
    <path
      d="M824.86 344.82H785.53V326.02H824.86V288.4C824.86 266.18 835.12 257.63 857.34 257.63H900.08V276.44H860.76C850.5 276.44 847.08 279.86 847.08 290.12V326.03H891.53V344.83H847.08V497H824.86V344.82Z"
      fill="white"
    />
    <path
      d="M902.479 445.7H924.71C927.1 469.29 945.229 481.6 968.819 481.6H972.24C996.18 481.6 1010.88 469.63 1010.88 450.83C1010.88 437.5 1003.7 429.29 980.79 424.5L955.83 419.37C929.84 413.9 909.33 401.93 909.33 372.86C909.33 340.38 938.05 322.6 970.53 322.6C1014.3 322.6 1033.11 349.27 1034.82 377.31H1012.59C1010.54 358.5 995.49 341.4 972.24 341.4H968.819C944.879 341.4 931.549 355.76 931.549 372.52C931.549 384.83 940.779 393.38 962.669 398.17L989.339 403.98C1013.62 409.11 1033.11 420.05 1033.11 449.12C1033.11 481.6 1008.14 500.41 970.53 500.41C926.42 500.41 904.189 477.16 902.479 445.7Z"
      fill="white"
    />
    <path
      d="M1086.8 466.21V344.82H1047.47V326.02H1086.8V270.62H1109.02V326.02H1151.76V344.82H1109.02V464.5C1109.02 474.76 1112.44 478.17 1122.7 478.17H1156.89V496.98H1119.28C1097.05 496.99 1086.8 488.44 1086.8 466.21Z"
      fill="white"
    />
    <path
      d="M1295.38 394.4C1294.36 363.97 1276.92 341.4 1249.9 341.4H1246.48C1224.94 341.4 1199.97 353.71 1195.19 394.4H1295.38ZM1172.29 411.16C1172.29 351.32 1209.22 322.6 1248.54 322.6C1290.26 322.6 1317.61 355.76 1317.61 399.53V413.21H1194.51C1195.2 461.43 1220.15 481.6 1248.2 481.6H1250.25C1273.5 481.6 1288.55 471 1295.04 448.09H1317.27C1309.75 482.28 1283.42 500.41 1248.54 500.41C1205.79 500.41 1172.29 469.63 1172.29 411.16Z"
      fill="white"
    />
    <path
      d="M1354.88 326.02H1377.11V496.99H1354.88V326.02ZM1353.17 266.18H1378.82V295.25H1353.17V266.18Z"
      fill="white"
    />
    <path
      d="M1418.13 326.02H1440.36V354.74C1450.27 335.93 1472.16 322.6 1500.2 322.6C1534.39 322.6 1560.04 344.14 1560.04 385.17V496.99H1537.81V392.01C1537.81 357.81 1524.13 341.4 1496.78 341.4H1494.73C1457.8 341.4 1440.36 374.57 1440.36 411.5V496.98H1418.13V326.02Z"
      fill="white"
    />
    <path
      d="M1614.99 299H1623.29C1625.97 299 1628.13 298.43 1629.75 297.28C1631.36 296.14 1632.18 294.54 1632.18 292.48C1632.18 290.5 1631.47 289.12 1630.05 288.33C1628.62 287.54 1626.38 287.14 1623.3 287.14H1615V299H1614.99ZM1606.7 322.11V279.45H1626.25C1630.67 279.45 1634.15 280.54 1636.68 282.71C1639.21 284.89 1640.47 287.75 1640.47 291.3C1640.47 294.14 1639.6 296.48 1637.86 298.29C1636.12 300.11 1633.83 301.33 1630.99 301.96C1636.92 301.96 1639.88 304.13 1639.88 308.48V322.11H1631.58V310.85C1631.58 307.69 1629.81 306.11 1626.25 306.11H1614.99V322.11H1606.7ZM1622.1 334.55C1631.82 334.55 1639.78 331.41 1645.98 325.13C1652.18 318.85 1655.28 310.93 1655.28 301.37C1655.28 291.57 1652.18 283.5 1645.98 277.13C1639.78 270.77 1631.81 267.6 1622.1 267.6C1612.39 267.6 1604.52 270.76 1598.52 277.07C1592.52 283.39 1589.52 291.49 1589.52 301.37C1589.52 310.93 1592.54 318.85 1598.58 325.13C1604.62 331.41 1612.47 334.55 1622.1 334.55ZM1652.92 331.95C1644.62 340.01 1634.35 344.03 1622.1 344.03C1609.86 344.03 1599.68 340.03 1591.59 332C1583.49 323.98 1579.45 313.77 1579.45 301.37C1579.45 288.81 1583.54 278.56 1591.71 270.61C1599.89 262.67 1610.02 258.71 1622.11 258.71C1634.44 258.71 1644.73 262.68 1652.98 270.61C1661.23 278.55 1665.36 288.8 1665.36 301.37C1665.36 313.69 1661.21 323.88 1652.92 331.95Z"
      fill="white"
    />
  </svg>
)

export const LogoSquare = (props: any) => (
  <svg width="auto" height="auto" viewBox="0 0 969 969" preserveAspectRatio="xMidYMid meet" {...props} fill="none">
    <path
      d="M0.870117 213.26V731.21H968.47V213.23C968.45 95.82 873.27 0.640005 755.86 0.630005H213.48C96.0501 0.640005 0.870117 95.84 0.870117 213.26Z"
      fill="black"
    />
    <path
      d="M0.870117 755.61C0.870117 873.04 96.0701 968.24 213.5 968.24H755.85C873.27 968.24 968.46 873.06 968.48 755.64V731.21H0.870117V755.61Z"
      fill="#FF8200"
    />
    <path
      d="M191.591 805.39H231.271C240.831 805.39 245.461 809.91 245.461 819.58V828.42C245.461 834.59 243.821 838.6 238.061 840.55C244.331 841.99 246.281 847.03 246.281 852.68V863.16C246.281 872.72 241.651 877.35 232.091 877.35H191.591V805.39ZM226.441 833.86C227.881 833.86 228.391 833.34 228.391 831.91V821.53C228.391 819.99 227.881 819.58 226.441 819.58H208.661V833.87H226.441V833.86ZM227.261 863.16C228.701 863.16 229.211 862.65 229.211 861.21V849.18C229.211 847.64 228.701 847.23 227.261 847.23H208.651V863.16H227.261Z"
      fill="black"
    />
    <path
      d="M268.67 853.91L245.44 805.39H263.94L277.3 836.64L290.56 805.39H308.86L285.73 853.91V877.35H268.67V853.91Z"
      fill="black"
    />
    <path
      d="M331.061 805.39H380.611V820.19H348.131V834.17H374.141V848.77H348.131V877.35H331.071V805.39H331.061Z"
      fill="black"
    />
    <path
      d="M443.101 877.34H425.321L413.291 852.57H403.321V877.34H386.261V805.38H426.351C435.911 805.38 440.541 809.9 440.541 819.57V837.56C440.541 845.68 437.351 850.1 430.671 851.33L443.101 877.34ZM421.511 838.38C422.951 838.38 423.461 837.97 423.461 836.43V821.83C423.461 820.29 422.951 819.88 421.511 819.88H403.311V838.38H421.511Z"
      fill="black"
    />
    <path
      d="M449.15 805.39H498.7V820.19H466.22V833.25H492.23V847.85H466.22V862.55H498.7V877.35H449.15V805.39Z"
      fill="black"
    />
    <path
      d="M506.091 805.39H547.211C556.771 805.39 561.401 809.91 561.401 819.58V863.16C561.401 872.72 556.771 877.35 547.211 877.35H506.091V805.39ZM542.381 862.54C543.821 862.54 544.331 862.03 544.331 860.59V822.14C544.331 820.6 543.821 820.19 542.381 820.19H523.161V862.54H542.381Z"
      fill="black"
    />
    <path
      d="M570.221 805.39H589.241L606.921 845.48L624.501 805.39H643.411V877.35H626.761V833.66L612.271 868.2H600.961L586.471 833.66V877.35H570.231V805.39H570.221Z"
      fill="black"
    />
    <path
      d="M692.021 862.13H669.101L664.991 877.34H647.311L669.101 805.38H692.641L714.541 877.34H696.141L692.021 862.13ZM688.121 847.64L680.621 819.47L673.011 847.64H688.121Z"
      fill="black"
    />
    <path
      d="M718.431 805.39H735.801L761.191 847.64V805.39H777.741V877.35H761.911L734.981 832.74V877.35H718.431V805.39Z"
      fill="black"
    />
    <path
      d="M127.9 392.49C127.9 355.95 149.47 328.32 184.94 328.32C210.79 328.32 231.82 343.65 233.07 368.43H220.59C219.35 350.25 203.66 339.91 185.83 339.91H184.05C155.53 339.91 140.38 363.8 140.38 392.49C140.38 421.19 155.53 445.07 184.05 445.07H185.83C203.65 445.07 219.34 434.73 220.59 416.55H233.07C231.82 441.33 210.79 456.66 184.94 456.66C149.46 456.66 127.9 429.03 127.9 392.49Z"
      fill="white"
    />
    <path
      d="M252.67 330.1H264.26V380.9C270.32 370.38 281.37 363.97 296.34 363.97C316.13 363.97 326.64 377.34 326.64 396.05V454.87H315.05V399.61C315.05 382.5 307.92 373.76 293.66 373.76H291.88C278.15 373.76 264.25 386.06 264.25 406.74V454.87H252.66V330.1H252.67Z"
      fill="white"
    />
    <path
      d="M405.971 401.4C405.441 385.54 396.341 373.77 382.261 373.77H380.481C369.251 373.77 356.241 380.19 353.741 401.4H405.971ZM341.801 410.13C341.801 378.94 361.051 363.96 381.551 363.96C403.301 363.96 417.561 381.25 417.561 404.06V411.19H353.391C353.751 436.32 366.761 446.84 381.381 446.84H382.451C394.571 446.84 402.411 441.31 405.801 429.37H417.391C413.471 447.19 399.741 456.64 381.561 456.64C359.271 456.66 341.801 440.61 341.801 410.13Z"
      fill="white"
    />
    <path
      d="M445.011 375.55H424.511V365.75H445.011V346.14C445.011 334.56 450.361 330.1 461.941 330.1H484.221V339.9H463.721C458.371 339.9 456.591 341.68 456.591 347.03V365.75H479.761V375.55H456.601V454.87H445.021V375.55H445.011Z"
      fill="white"
    />
    <path
      d="M485.48 428.14H497.07C498.32 440.44 507.77 446.86 520.06 446.86H521.841C534.321 446.86 541.98 440.62 541.98 430.82C541.98 423.87 538.24 419.59 526.29 417.1L513.281 414.43C499.731 411.58 489.04 405.34 489.04 390.19C489.04 373.26 504.01 363.99 520.951 363.99C543.771 363.99 553.571 377.89 554.461 392.51H542.87C541.8 382.71 533.961 373.79 521.841 373.79H520.06C507.58 373.79 500.63 381.28 500.63 390.01C500.63 396.43 505.44 400.88 516.85 403.38L530.75 406.41C543.4 409.08 553.57 414.79 553.57 429.94C553.57 446.87 540.561 456.68 520.951 456.68C497.961 456.66 486.37 444.54 485.48 428.14Z"
      fill="white"
    />
    <path
      d="M581.561 438.83V375.55H561.061V365.75H581.561V336.87H593.141V365.75H615.421V375.55H593.141V437.94C593.141 443.29 594.921 445.07 600.271 445.07H618.091V454.88H598.481C586.901 454.88 581.561 450.42 581.561 438.83Z"
      fill="white"
    />
    <path
      d="M690.29 401.4C689.76 385.54 680.66 373.77 666.58 373.77H664.8C653.57 373.77 640.56 380.19 638.06 401.4H690.29ZM626.12 410.13C626.12 378.94 645.37 363.96 665.87 363.96C687.62 363.96 701.88 381.25 701.88 404.06V411.19H637.71C638.07 436.32 651.08 446.84 665.7 446.84H666.77C678.89 446.84 686.73 441.31 690.12 429.37H701.71C697.79 447.19 684.06 456.64 665.88 456.64C643.59 456.66 626.12 440.61 626.12 410.13Z"
      fill="white"
    />
    <path d="M721.31 365.75H732.9V454.87H721.31V365.75ZM720.41 334.56H733.78V349.71H720.41V334.56Z" fill="white" />
    <path
      d="M754.281 365.75H765.87V380.72C771.04 370.92 782.45 363.97 797.06 363.97C814.88 363.97 828.25 375.2 828.25 396.59V454.88H816.66V400.16C816.66 382.33 809.531 373.78 795.271 373.78H794.201C774.951 373.78 765.86 391.07 765.86 410.32V454.88H754.271V365.75H754.281Z"
      fill="white"
    />
    <path
      d="M856.9 351.67H861.23C862.63 351.67 863.75 351.37 864.6 350.77C865.44 350.18 865.86 349.34 865.86 348.27C865.86 347.24 865.49 346.52 864.75 346.11C864.01 345.7 862.84 345.49 861.23 345.49H856.9V351.67ZM852.58 363.71V341.47H862.77C865.08 341.47 866.89 342.04 868.21 343.17C869.53 344.3 870.19 345.8 870.19 347.65C870.19 349.13 869.74 350.35 868.83 351.29C867.92 352.24 866.73 352.87 865.25 353.2C868.34 353.2 869.88 354.33 869.88 356.6V363.7H865.55V357.83C865.55 356.18 864.63 355.36 862.77 355.36H856.9V363.7H852.58V363.71ZM860.61 370.2C865.68 370.2 869.83 368.56 873.06 365.29C876.29 362.02 877.91 357.89 877.91 352.9C877.91 347.79 876.29 343.58 873.06 340.27C869.83 336.95 865.68 335.3 860.61 335.3C855.55 335.3 851.45 336.95 848.32 340.24C845.19 343.54 843.63 347.76 843.63 352.91C843.63 357.89 845.21 362.02 848.35 365.3C851.5 368.56 855.58 370.2 860.61 370.2ZM876.67 368.84C872.34 373.04 866.99 375.14 860.61 375.14C854.23 375.14 848.92 373.05 844.7 368.87C840.48 364.69 838.37 359.37 838.37 352.9C838.37 346.35 840.5 341.01 844.76 336.87C849.02 332.73 854.31 330.66 860.6 330.66C867.03 330.66 872.39 332.73 876.69 336.87C880.99 341.01 883.14 346.35 883.14 352.9C883.16 359.33 880.99 364.64 876.67 368.84Z"
      fill="white"
    />
  </svg>
)

export const CGIWhiteLogo = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Chefstein" viewBox="0 0 245 75.5" {...props}>
    <defs>
      <clipPath id="clippath">
        <path
          d="M0 0h245v75.5H0z"
          style={{
            fill: 'none',
            strokeWidth: 0
          }}
        />
      </clipPath>
      <style>{'.cls-3,.cls-4{stroke-width:0}.cls-3{fill:#fff}.cls-4{fill:#e11d38}'}</style>
    </defs>
    <g
      style={{
        clipPath: 'url(#clippath)'
      }}
    >
      <path
        d="M101.8 41.3c-.8.8-1.5 1.2-2.6 1.2-2.1 0-3.4-1.8-3.4-4.6s1.3-4.5 3.4-4.5 1.6.3 2.2 1l.2.2 1.2-1.3-.2-.2c-.5-.6-1.7-1.5-3.4-1.5-3.3 0-5.5 2.6-5.5 6.4s2.2 6.4 5.4 6.4 2.8-.6 3.8-1.8l.2-.2-1.2-1.3-.2.2ZM112 36.7h-5v-4.9h-2v12.3h2v-5.5h5v5.5h2V31.8h-2v4.9zM119 38.6h4.5v-1.8H119v-3.2h5.3v-1.8H117v12.3h7.5v-1.8H119v-3.7zM126.8 44.1h2v-5.3h4.5v-1.7h-4.5v-3.5h5.3v-1.8h-7.3v12.3zM140.8 37.3l-1.7-.7c-1.1-.4-1.8-.8-1.8-1.7s.7-1.5 1.9-1.5 1.8.4 2.5 1l.2.2 1.1-1.4-.2-.2c-1-1-2.3-1.5-3.7-1.5-2.3 0-4 1.4-4 3.4s.8 2.5 2.5 3.3l1.7.8c1.2.5 1.8.8 1.8 1.8s-.9 1.7-2.2 1.7-2.3-.5-3.1-1.3l-.2-.2-1.2 1.4v.2c1.3 1.2 2.8 1.8 4.5 1.8 2.5 0 4.3-1.5 4.3-3.6s-1.4-2.9-2.6-3.4h.2ZM144 33.6h3.5v10.5h2.1V33.6h3.5v-1.8H144v1.8zM157 38.6h4.5v-1.8H157v-3.2h5.3v-1.8H155v12.3h7.5v-1.8H157v-3.7zM164.8 31.8h2v12.3h-2zM176.8 31.8v6.4c0 .9 0 1.8.1 2.6-.3-.5-.5-1-.8-1.5l-4.3-7.5h-2v12.3h1.9v-6.5c0-.8 0-1.7-.1-2.5.3.6.5 1.1.8 1.5l4.3 7.5h2V31.8h-1.9Z"
        className="cls-3"
      />
      <path
        d="M77.2 29.7c.2 0 .4.2.4.4v15.1c0 .2-.2.4-.4.4s-.4-.2-.4-.4V30.1c0-.2.2-.4.4-.4Z"
        style={{
          strokeWidth: 0,
          fill: '#a8a7a7'
        }}
      />
      <g id="CGI_logo" data-name="CGI logo">
        <path
          d="M15.9 29.2c-5.5 0-8.8 4.3-8.8 8.6s4.2 8.6 8.9 8.6 6-1.3 8.4-3.5v6.4c-2.6 1.5-6.1 2.5-8.9 2.5-7.9 0-14.5-6.4-14.5-14s6.7-14 14.5-14 6.6.9 8.9 2.1v6.3c-2.9-1.9-5.9-3-8.5-3ZM41.2 51.7c-7.9 0-14.6-6.2-14.6-14s6.7-14 15-14 6.8.8 9.1 1.9v6.3c-2.6-1.5-6-2.8-9-2.8-5.5 0-9 4.3-9 8.6s4.2 8.7 9.1 8.7 2 0 3.3-.6v-5h-4.5v-5.3h10.2v14.1c-2.9 1.3-6.2 2-9.6 2ZM55.2 51.1V24.3h5.7v26.8h-5.7Z"
          className="cls-4"
        />
      </g>
    </g>
  </svg>
)

export const CGIBrownLogo = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Chefstein" viewBox="0 0 245 75.5" {...props}>
    <defs>
      <clipPath id="clippath">
        <path
          d="M0 0h245v75.5H0z"
          style={{
            fill: 'none',
            strokeWidth: 0
          }}
        />
      </clipPath>
      <style>{'.cls-3,.cls-4{stroke-width:0}.cls-3{fill:#333}.cls-4{fill:#e11d38}'}</style>
    </defs>
    <g
      style={{
        clipPath: 'url(#clippath)'
      }}
    >
      <path
        d="M101.77 41.31c-.76.83-1.54 1.2-2.55 1.2-2.09 0-3.39-1.75-3.39-4.58s1.35-4.5 3.44-4.5c.86 0 1.59.32 2.23.99l.19.19 1.15-1.31-.15-.17a4.593 4.593 0 0 0-3.44-1.54c-3.28 0-5.49 2.56-5.49 6.37s2.17 6.37 5.4 6.37c1.51 0 2.76-.59 3.81-1.8l.15-.17-1.17-1.27-.18.2ZM111.98 36.74H107v-4.93h-2.01v12.31H107v-5.54h4.98v5.54h2.01V31.81h-2.01v4.93zM119.01 38.56h4.47v-1.78h-4.47v-3.19h5.29v-1.78H117v12.31h7.48v-1.78h-5.47v-3.78zM126.75 44.12h2.02v-5.27h4.5v-1.78h-4.5v-3.48h5.29v-1.78h-7.31v12.31zM140.8 37.34l-1.7-.74c-1.05-.43-1.79-.81-1.79-1.71s.74-1.46 1.93-1.46c.92 0 1.78.36 2.49 1.03l.19.19 1.14-1.39-.16-.16c-.96-.96-2.27-1.5-3.67-1.5-2.29 0-3.96 1.42-3.96 3.38 0 1.48.83 2.55 2.53 3.27l1.72.76c1.18.52 1.8.84 1.8 1.84s-.85 1.66-2.22 1.66c-1.15 0-2.3-.49-3.13-1.35l-.19-.2-1.17 1.37.15.16c1.13 1.21 2.62 1.84 4.32 1.84 2.52 0 4.28-1.49 4.28-3.62s-1.38-2.87-2.58-3.38ZM143.98 33.59h3.56v10.53h2.02V33.59h3.58v-1.78h-9.16v1.78zM157.03 38.56h4.46v-1.78h-4.46v-3.19h5.29v-1.78h-7.31v12.31h7.49v-1.78h-5.47v-3.78zM164.77 31.81h2.01v12.31h-2.01zM176.76 31.81v6.41c0 .86.05 1.75.11 2.62-.26-.53-.52-1.05-.8-1.54l-4.29-7.49h-2.02v12.31h1.92v-6.46c0-.84-.05-1.7-.11-2.55.28.56.54 1.07.8 1.52l4.29 7.49h2.02V31.81h-1.92Z"
        className="cls-3"
      />
      <rect
        width={0.88}
        height={15.96}
        x={76.76}
        y={29.68}
        rx={0.44}
        ry={0.44}
        style={{
          strokeWidth: 0,
          fill: '#a8a7a7'
        }}
      />
      <g id="CGI_logo">
        <path
          d="M15.9 29.18c-5.46 0-8.81 4.26-8.81 8.57 0 5.18 4.22 8.57 8.85 8.57 3.07 0 5.98-1.35 8.45-3.55v6.41c-2.59 1.55-6.14 2.51-8.89 2.51C7.61 51.69 1 45.28 1 37.74c0-7.97 6.65-13.95 14.54-13.95 3.03 0 6.57.92 8.89 2.07v6.3c-2.91-1.91-5.86-2.99-8.53-2.99ZM41.16 51.7c-7.93 0-14.62-6.18-14.62-13.95S33.19 23.8 41.52 23.8c3.03 0 6.77.8 9.08 1.87v6.26c-2.63-1.51-6.02-2.75-8.96-2.75-5.46 0-9 4.26-9 8.57 0 5.06 4.18 8.73 9.08 8.73 1.04 0 2.03-.08 3.31-.56V40.9h-4.46v-5.3h10.2v14.1c-2.91 1.31-6.18 1.99-9.6 1.99ZM55.22 51.14V24.37h5.74v26.77h-5.74Z"
          className="cls-4"
        />
      </g>
    </g>
  </svg>
)
