import {AsyncAction} from 'overmind'
import {GetTaskTemplateParams, TaskTemplate} from './state'

export const getTaskTemplates: AsyncAction<GetTaskTemplateParams, TaskTemplate | undefined> = async (
  {state, effects},
  params: GetTaskTemplateParams
) => {
  try {
    const res = await effects.v1.taskTemplates.tasksFromTemplatesApi.getTaskTemplates(params.siteIds, params.language)
    state.v1.taskTemplates.template = res
    return res
  } catch (err) {
    state.v1.taskTemplates.error = err as Error
  }
}

export const generateTasksFromTemplate: AsyncAction<{template: TaskTemplate}, any> = async (
  {state, effects},
  params: {template: TaskTemplate}
) => {
  try {
    return effects.v1.taskTemplates.tasksFromTemplatesApi.createTasksFromTemplate(params.template)
  } catch (err) {
    state.v1.taskTemplates.error = err as Error
  }
}
