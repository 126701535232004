import {WasteTaskType} from '../../Components/Views/Tasks/CreateWasteTask'

interface ScheduleObject {
  dates: string
  period: string
  time: string
  weekdays: number[]
  monthdays: number[]
  yeardays: string[]
}

interface TemperatureTaskPaylod {
  name: string
  maxValue: number
  minValue: number
  taskGroup: string
  taskType: string
  defaultSuggestions: string[]
  sites: string[]
  alarmsEnabled: boolean
  measurementMethod: string
}

interface WasteTaskPaylod {
  name: string
  maxValue?: number
  taskGroup: string
  taskType: string
  defaultSuggestions?: string[]
  sites: string[]
  alarmsEnabled?: boolean
  wasteTaskType: WasteTaskType
}

interface TodoTaskPaylod {
  name: string
  taskGroup: string
  taskType: string
  subTasks: string[]
  sites: string[]
  schedule?: ScheduleObject
}

interface HygieneTaskPayload {
  name: string
  taskGroup: string
  taskType: string
  sites: string[]
  alarmsEnabled: boolean
  measurementMethod: string
}

interface CoolingTaskPayload {
  name: string
  maxValue: number
  minValue: number
  timeLimit: number
  taskGroup: string
  taskType: string
  sites: string[]
  measurementMethod: string
}

export type GetTaskTemplateParams = {
  siteIds: string
  language: string
}

export type PossibleTaskPayloads =
  | CoolingTaskPayload
  | TodoTaskPaylod
  | WasteTaskPaylod
  | TemperatureTaskPaylod
  | HygieneTaskPayload

export interface TaskTemplate {
  tasks: PossibleTaskPayloads[]
}

interface TemplateState {
  template: TaskTemplate | null
  error: Error | null
}

export const state: TemplateState = {
  template: null,
  error: null
}
