import React, {useRef, useEffect} from 'react'
import ReactDOM from 'react-dom'
import styled, {css} from 'styled-components'
import tinycolor from 'tinycolor2'
import {colors} from '../../../colors'
import {Title} from '../../atoms/Title'

/**
 * TODO: Accessibility (focus-trap, moving focus to modal header / back to element that opened modal)
 */

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean
  onClose?: () => void
  title?: string
  headerLeftItem?: React.ReactNode
  headerRightItem?: React.ReactNode
  headerBackgroundColor?: string
  width?: string
  minWidth?: string
  maxWidth?: string
  height?: string
  minHeight?: string
  maxHeight?: string
  children?: React.ReactNode
  headerHeight?: string
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  headerLeftItem,
  headerRightItem,
  headerBackgroundColor,
  children,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  headerHeight,
  ...rest
}) => {
  const modalRootRef = useRef<HTMLDivElement>(document.createElement('div'))
  const overlayRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const modalRootElement = modalRootRef.current
    document.body.appendChild(modalRootElement)
    return () => {
      document.body.removeChild(modalRootElement)
    }
  }, [])

  const onOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Prevent clicking anything behind the overlay
    event.stopPropagation()
    // Call onClose only if the overlay is clicked and not an element in front of the overlay
    if (event.target === overlayRef.current) {
      onClose?.()
    }
  }

  const showHeader = title || headerLeftItem || headerRightItem

  return isOpen
    ? ReactDOM.createPortal(
        <ModalOverlay ref={overlayRef} role="dialog" aria-modal="true" onClick={onOverlayClick} {...rest}>
          <ModalContainer
            width={width}
            minWidth={minWidth}
            maxWidth={maxWidth}
            height={height}
            minHeight={minHeight}
            maxHeight={maxHeight}
          >
            {showHeader && (
              <ModalHeader headerBackgroundColor={headerBackgroundColor} headerHeight={headerHeight}>
                {headerLeftItem ? (
                  <ModalHeaderLeftItemContainer>{headerLeftItem}</ModalHeaderLeftItemContainer>
                ) : (
                  <div />
                )}
                {title && (
                  <Title level={6} strong as="h2">
                    {title}
                  </Title>
                )}
                {headerRightItem ? (
                  <ModalHeaderRightItemContainer>{headerRightItem}</ModalHeaderRightItemContainer>
                ) : (
                  <div />
                )}
              </ModalHeader>
            )}
            {children}
          </ModalContainer>
        </ModalOverlay>,
        modalRootRef.current
      )
    : null
}

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-items: center;
  align-items: start;
  background-color: ${tinycolor(colors.brand.cobalt)
    .setAlpha(0.65)
    .toString()};
  z-index: 9999;
  padding: 5rem 1rem 1rem 1rem;
`

const ModalContainer = styled.div<{
  width?: string
  minWidth?: string
  maxWidth?: string
  height?: string
  minHeight?: string
  maxHeight?: string
}>(
  ({width, minWidth, maxWidth, height, minHeight, maxHeight}) => css`
    ${width && `width: ${width};`}
    ${minWidth && `min-width: ${minWidth};`}
    max-width: ${maxWidth ?? '57.5rem'};
    ${height && `height: ${height};`}
    ${minHeight && `min-height: ${minHeight};`}
    ${maxHeight && `max-height: ${maxHeight};`}
    background-color: ${colors.system.white};
    border-radius: 1rem;
    overflow: hidden;
    border: 0.5px solid white;
  `
)

const ModalHeader = styled.div<{headerBackgroundColor?: string; headerHeight?: string}>(
  ({headerBackgroundColor, headerHeight}) => css`
    padding: 1rem;
    background-color: ${headerBackgroundColor ?? colors.system.lightGrey_5};
    display: grid;
    grid-template: auto / 1fr 6fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 1rem;
    height: ${headerHeight ?? headerHeight};
    ${!headerBackgroundColor && `border-bottom: 1px solid ${colors.system.grey_5};`}
  `
)

const ModalHeaderLeftItemContainer = styled.div`
  justify-self: start;
`

const ModalHeaderRightItemContainer = styled.div`
  justify-self: end;
`
