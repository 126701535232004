import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconSurfaceHygiene = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.47266V45.9999H38.8882V22.799H37.6113V44.7356H3.27685V10.737H25.66V9.47266H2Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.989 20.536L28.8775 21.5601L29.913 23.4314L30.9472 21.5601L32.837 20.536L30.9472 19.5119L29.913 17.6406L28.8775 19.5119L26.989 20.536ZM29.9254 26.0739L27.9462 22.4958L24.3328 20.5361L27.9462 18.5763L29.9254 14.9982L31.9045 18.5763L35.518 20.5361L31.9045 22.4958L29.9254 26.0739Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4282 14.0245L34.8147 15.9842L38.4282 17.944L40.4073 21.5221L42.3864 17.944L45.9999 15.9842L42.3864 14.0245L40.4073 10.4451L38.4282 14.0245ZM39.3604 17.0083L37.4707 15.9841L39.3604 14.96L40.3947 13.0887L41.4289 14.96L43.3187 15.9841L41.4289 17.0083L40.3947 18.8795L39.3604 17.0083Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9381 2L30.9589 5.57939L27.3455 7.53788L30.9589 9.49764L32.9381 13.0758L34.9184 9.49764L38.5307 7.53788L34.9184 5.57939L32.9381 2ZM31.891 8.56196L30.0013 7.53783L31.891 6.5137L32.9253 4.64246L33.9595 6.5137L35.8493 7.53783L33.9595 8.56196L32.9253 10.4332L31.891 8.56196Z"
      />
    </svg>
  )
}
