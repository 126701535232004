import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigation} from 'react-navi'
import {colors} from '../../sharedComponents/colors'
import {AlarmCard, Icon, SharedIcon, Text, Title} from '../../sharedComponents/components'
import {useAppState} from '../../state'
import {Alarm} from '../../state/alarms/state'
import {PerformableTask} from '../../state/performTask/state'
import {getHumanizedDateWithTime} from '../../Components/Atoms/Utils'
import {camelCase} from 'lodash'
import {
  alarmIconPicker,
  alarmLimitUnit,
  alarmStatus,
  alarmSubtypeToTranslation,
  alarmTypeToTranslation,
  alarmUnit,
  alarmValueFormatter,
  Units
} from '../../pages/alarms/formatters'
import styled from 'styled-components'

interface ActiveAlarmsProps {
  setTask: any
  setSelectedAlarmId: any
  activeAlarms: {
    count: number
    alarms: Alarm[]
  }
  task: PerformableTask | null
}

const ActiveAlarms = ({setTask, setSelectedAlarmId, activeAlarms, task}: ActiveAlarmsProps) => {
  const {t} = useTranslation(['home', 'tasks', 'alarms'])
  const {state, actions} = useAppState()
  const nav = useNavigation()
  const locale = state.site!.locale
  const site = state.site!

  const units: Units = {
    temperature: state.site!.temperatureUnit,
    weight: state.site!.weightUnit
  }

  const onAlarmResolveButtonClick = async (task: any, alarmId?: string) => {
    if (task) {
      const {id, eventId, scheduleInstance, alarms} = task
      const data = await actions.v1.performTask.getTask({
        siteId: state.site!.id,
        taskId: id,
        eventId: eventId
      })
      if (data) {
        setTask({...data, alarms, scheduleInstance})
      }
    }
    if (alarmId) {
      setSelectedAlarmId(alarmId)
    }
  }

  return (
    <>
      <ActiveAlarmTitle data-cy="alarm-container-title">
        <Title level={5} strong>
          {t('home:labels.activeAlarm.title', 'Active alarms')}
        </Title>
        {activeAlarms.count !== 0 && (
          <Link size="M" style={{textAlign: 'center'}} onClick={() => nav.navigate('/alarms')} strong>
            {`${t('home:labels.activeAlarm.viewAll', 'View all')} (${activeAlarms.count})`}
          </Link>
        )}
      </ActiveAlarmTitle>
      {activeAlarms.count === 0 ? (
        <NoAlarmPlaceHolder>
          <Icon type="happy-face" height="4em" width="4em" fill={colors.system.grey_3} />
          <Text size="M" color={colors.system.grey_50}>
            {t('home:labels.activeAlarm.noAlarms.placeholder', 'All is good today, keep up the good work.')}
          </Text>
        </NoAlarmPlaceHolder>
      ) : (
        activeAlarms.alarms.map(alarm => (
          <AlarmCard
            key={alarm.id}
            title={alarm.name}
            timestamp={getHumanizedDateWithTime(new Date(alarm.triggeredTime), locale)}
            titleIcon={
              <SharedIcon icon={alarmIconPicker(alarm.type)} width="2.4em" height="2.4em" fill={colors.system.white} />
            }
            condition={alarmSubtypeToTranslation(t, alarm.type, alarm.subtype) || alarmTypeToTranslation(t, alarm.type)}
            value={alarmValueFormatter(alarm.measuredValue, alarm.type, alarm.subtype, site)}
            unit={alarmUnit(t, alarm.type, alarm.subtype, alarm.unit, units)}
            minValue={alarm.limits?.minValue ?? undefined}
            maxValue={alarm.limits?.maxValue ?? undefined}
            minUnit={alarmLimitUnit(t, alarm.type, alarm.subtype, alarm.unit, units)}
            maxUnit={alarmLimitUnit(t, alarm.type, alarm.subtype, alarm.unit, units)}
            onClick={() => onAlarmResolveButtonClick(task, alarm.id)}
            data-cy={`alarm-card-${camelCase(alarm.name)}`}
            disabled={!alarm.isResolvable}
            description={!alarm.isResolvable ? alarmStatus(t, alarm.status, alarm.isResolvable, alarm.type) : undefined}
          />
        ))
      )}
    </>
  )
}

const ActiveAlarmTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NoAlarmPlaceHolder = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.2rem 1rem;
  border: 1px solid ${colors.system.grey_4};
  border-radius: 0.5rem;
  align-items: center;
`
const Link = styled(Text)`
  :hover {
    text-decoration: underline;
    color: ${colors.brand.cobalt};
  }
`

export default ActiveAlarms
