import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-navi-helmet-async'
import {ErrorMessage} from '../../Components/Molecules/Errors/ErrorMessage'
import {Loader} from '../../Routes'
import {useAppState} from '../../state'
import {Text, Title, Tabs, TabPane, SelectOption} from '../../sharedComponents/components'
import {CompletedTaskFilterView, CompletedTasksView, CompletedTaskFilter} from './CompletedTasks'
import {TodoTasksView, TodoFilterView, TodoFilters} from './TodoTasks'
import styled from 'styled-components'
import {useNavigation} from 'react-navi'
import {OrgLevelType} from '../../state/state'
import {CenteredLayout} from '../common/centeredLayout'
import {checkPeriodValidity} from '../utils'

export const PAGE_LIMIT = 25
export const PAGE_LIMIT_LATE = 3
export const IN_PROGRESS_AUTOMATIC_COOLING_TASKS_UPDATE_INTERVAL = 30 * 1000

enum TaskViewType {
  TODO = 'todo',
  COMPLETED = 'completed'
}

export enum TaskType {
  LATE = 'late',
  TODAY = 'today',
  COMPLETED = 'completed'
}

export const TasksPage = () => {
  const {state, actions} = useAppState()
  const {t} = useTranslation('tasks')
  const navigation = useNavigation()
  const siteId = state.site!.id

  const [selectedPaneKey, setSelectedPaneKey] = useState<string>(TaskViewType.TODO)

  useEffect(() => {
    // Redirect to home page if site is not selected because atm there is no tasks page for chain
    if (state.orgLevel?.type !== OrgLevelType.SITE) {
      navigation.navigate('/')
    }
  }, [state.orgLevel, navigation])

  useEffect(() => {
    actions.v1.tasks.getTasksForSite({siteId, limit: PAGE_LIMIT, limitLate: PAGE_LIMIT_LATE})
  }, [actions.v1.tasks, siteId])

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      actions.v1.tasks.updateInProgressAutomaticCoolingTasksForSite({siteId})
    }, IN_PROGRESS_AUTOMATIC_COOLING_TASKS_UPDATE_INTERVAL)
    return () => {
      window.clearInterval(intervalId)
    }
  }, [])

  const {initialLoading, initialLoadingError, completedTasks, todoTasks} = state.v1.tasks

  const getShowingText = (selectedTab: string): string => {
    const part =
      selectedTab === TaskViewType.COMPLETED
        ? completedTasks.data.tasks.length
        : todoTasks.data.lateTasks.tasks.length +
          todoTasks.data.todaysTasks.tasks.length +
          todoTasks.data.inProgressTasks.tasks.length
    const total =
      selectedTab === TaskViewType.COMPLETED
        ? completedTasks.data.count
        : todoTasks.data.lateTasks.count + todoTasks.data.todaysTasks.count + todoTasks.data.inProgressTasks.count
    return t('tasks:labels.showingItemsOutOfTotal', {part, total})
  }

  const tabBarExtraContent = {
    left: (
      <div style={{width: '20%'}}>
        <Title level={3}>{t('tasks:labels.headingTaskList', 'Tasks')}</Title>
      </div>
    ),
    right: (
      <div style={{width: '65%'}}>
        <Text size="S">{getShowingText(selectedPaneKey)}</Text>
      </div>
    )
  }

  if (initialLoading) {
    return <Loader show={initialLoading} />
  } else if (initialLoadingError) {
    return <ErrorMessage message={initialLoadingError.message} />
  } else {
    return (
      <CenteredLayout>
        <Helmet title={t('common:routes.tasks', 'Tasks')} />
        <div data-cy="task-page-tabs">
          <Tabs
            defaultActiveKey={TaskViewType.TODO}
            tabBarExtraContent={tabBarExtraContent}
            onChangeKey={setSelectedPaneKey}
          >
            <TabPane tab={<Text size="M">{t('tasks:labels.tabHeadingToDo', 'To do')}</Text>} tabKey={TaskViewType.TODO}>
              <MainContent locale={state.site!.locale || 'fi-FI'} type={TaskViewType.TODO} />
            </TabPane>
            <TabPane
              tab={
                <Text data-cy="completed-tasks-tab" size="M">
                  {t('tasks:labels.tabHeadingCompleted', 'Completed')}
                </Text>
              }
              tabKey={TaskViewType.COMPLETED}
            >
              <MainContent locale={state.site!.locale || 'fi-FI'} type={TaskViewType.COMPLETED} />
            </TabPane>
          </Tabs>
        </div>
      </CenteredLayout>
    )
  }
}

interface MainContentProps {
  type: TaskViewType
  locale: string
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20% auto;
  grid-template-rows: calc(100vh - 12rem);
  grid-template-areas: 'filters table';
`

const MainContent = (props: MainContentProps) => {
  const {state, actions} = useAppState()
  const {t} = useTranslation('tasks')

  const [todoTaskFilter, setTodoTaskFilter] = useState<TodoFilters>({
    taskGroups: [],
    taskTypes: []
  })
  const [completedTaskFilter, setCompletedTaskFilter] = useState<CompletedTaskFilter>({
    taskGroups: [],
    taskTypes: [],
    userAccounts: [],
    measurementValue: [],
    startDate: new Date(2020, 0, 1),
    endDate: new Date(),
    media: false
  })

  const todayTaskOffset = state.v1.tasks.todoTasks.data.todaysTasks.tasks.length
  const lateTaskOffset = state.v1.tasks.todoTasks.data.lateTasks.tasks.length
  const completedTaskOffset = state.v1.tasks.completedTasks.data.tasks.length

  const applyFilters = (setting: {
    type: TaskViewType
    offset?: number
    limit?: number
    todoTaskType?: TaskType
    limitLate?: number
    offsetLate?: number
  }) => {
    const siteId = state.site!.id
    const {type, offset, limit, todoTaskType} = setting
    if (type === TaskViewType.TODO) {
      const {taskTypes, taskGroups} = todoTaskFilter
      actions.v1.tasks.getTasksForSiteWithPagination({
        siteId,
        options: {
          limit: limit || PAGE_LIMIT,
          offset: offset || 0,
          completed: false,
          ...(!todoTaskType && {limitLate: PAGE_LIMIT_LATE, offsetLate: 0}),
          ...(taskTypes.length > 0 ? {taskTypes: taskTypes.map((taskTypes: SelectOption) => taskTypes.value)} : {}),
          ...(taskGroups.length > 0 ? {taskGroups: taskGroups.map((taskGroup: SelectOption) => taskGroup.value)} : {})
        },
        type: todoTaskType
      })
    }
    if (type === TaskViewType.COMPLETED) {
      const {taskTypes, userAccounts, taskGroups, startDate, endDate, measurementValue, media} = completedTaskFilter
      actions.v1.tasks.getTasksForSiteWithPagination({
        siteId,
        options: {
          limit: limit || PAGE_LIMIT,
          offset: offset || 0,
          completed: true,
          ...(taskTypes.length > 0 ? {taskTypes: taskTypes.map((taskTypes: SelectOption) => taskTypes.value)} : {}),
          ...(userAccounts.length > 0
            ? {completedByUsers: userAccounts.map((users: SelectOption) => users.value)}
            : {}),
          ...(taskGroups.length > 0 ? {taskGroups: taskGroups.map((taskGroup: SelectOption) => taskGroup.value)} : {}),
          ...(checkPeriodValidity(startDate, endDate)
            ? {from: startDate.toISOString(), to: endDate.toISOString()}
            : {}),
          ...(measurementValue.length > 0
            ? {
                succeededMeasurements: !!measurementValue.find(item => item.value === 'succeededMeasurements'),
                failedMeasurements: !!measurementValue.find(item => item.value === 'failedMeasurements')
              }
            : {}),
          ...(media ? {media: true} : null)
        }
      })
    }
  }

  const deleteEvent = (taskId: string, eventId: string) => {
    const confirmation = window.confirm(t('tasks:confirmation.confirmEventDelete', 'Are you sure?'))
    if (confirmation) actions.v1.tasks.deleteTaskEvent({taskId, eventId})
  }

  const loadMoreData = (type: TaskType) => {
    if (type === TaskType.COMPLETED) {
      applyFilters({type: TaskViewType.COMPLETED, offset: completedTaskOffset, limit: PAGE_LIMIT})
    }
    if (type === TaskType.TODAY) {
      applyFilters({
        type: TaskViewType.TODO,
        offset: todayTaskOffset,
        limit: PAGE_LIMIT,
        todoTaskType: TaskType.TODAY
      })
    }
    if (type === TaskType.LATE) {
      applyFilters({
        type: TaskViewType.TODO,
        offset: lateTaskOffset,
        limit: PAGE_LIMIT,
        todoTaskType: TaskType.LATE
      })
    }
  }

  const resetFilters = (type: TaskViewType) => {
    const siteId = state.site!.id
    if (type === TaskViewType.TODO) {
      setTodoTaskFilter({
        taskGroups: [],
        taskTypes: []
      })
    }
    if (type === TaskViewType.COMPLETED) {
      setCompletedTaskFilter({
        taskGroups: [],
        taskTypes: [],
        userAccounts: [],
        measurementValue: [],
        startDate: new Date(2020, 0, 1),
        endDate: new Date(),
        media: false
      })
    }
    actions.v1.tasks.getTasksForSiteWithPagination({
      siteId,
      options: {
        limit: PAGE_LIMIT,
        offset: 0,
        ...(type === TaskViewType.TODO && {
          limitLate: PAGE_LIMIT_LATE,
          offsetLate: 0
        }),
        completed: type === TaskViewType.COMPLETED
      }
    })
  }

  return (
    <Grid>
      <aside style={{gridArea: 'filters', padding: '2rem 2rem 0 0'}}>
        {props.type === TaskViewType.TODO && (
          <TodoFilterView
            resetFilters={() => resetFilters(TaskViewType.TODO)}
            selectedFilter={todoTaskFilter}
            setFilters={(key, value) => setTodoTaskFilter({...todoTaskFilter, [key]: value})}
            applyFilters={() => {
              applyFilters({type: TaskViewType.TODO})
            }}
          />
        )}
        {props.type === TaskViewType.COMPLETED && (
          <CompletedTaskFilterView
            locale={props.locale}
            resetFilters={() => resetFilters(TaskViewType.COMPLETED)}
            selectedFilter={completedTaskFilter}
            setFilters={(key, value) => {
              setCompletedTaskFilter({...completedTaskFilter, [key]: value})
            }}
            applyFilters={() => {
              applyFilters({type: TaskViewType.COMPLETED})
            }}
          />
        )}
      </aside>
      <div style={{gridArea: 'table'}}>
        {props.type === TaskViewType.TODO && (
          <TodoTasksView
            locale={props.locale}
            loadMoreTodaysTasks={() => {
              loadMoreData(TaskType.TODAY)
            }}
            loadMoreLateTasks={() => {
              loadMoreData(TaskType.LATE)
            }}
          />
        )}
        {props.type === TaskViewType.COMPLETED && (
          <CompletedTasksView
            locale={props.locale}
            loadMoreCompletedTasks={() => {
              loadMoreData(TaskType.COMPLETED)
            }}
            deleteEvent={deleteEvent}
          />
        )}
      </div>
    </Grid>
  )
}
