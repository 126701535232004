import {UmbrellaCategory} from '../home/site/state'

interface UnscheduledTask {
  id: string
  name: string
  taskType: string
  description: string
  createdAt: string
}

export interface UnscheduledTaskGroup {
  id: string
  name: string
  icon: string
  fixed: boolean

  tasks: UnscheduledTask[]
  umbrellaCategory: UmbrellaCategory
}

interface UnscheduledTaskGroupState {
  data: UnscheduledTaskGroup[]
  pending: boolean
  error: Error | null
}

export const state: UnscheduledTaskGroupState = {
  data: [],
  pending: false,
  error: null
}
