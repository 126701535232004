import {useEffect, useState} from 'react'
import {useForm, useFieldArray} from 'react-hook-form'
import {useAppState} from '../../../state'
import {EquipmentType, StorageSensorType, StorageType} from '../../../state/rest'
import {startsWith} from 'lodash'
import {v4 as uuid} from 'uuid'
import {localTempValue} from '../../../config/utils'

type StorageTypesForm = {
  storageTypes: (StorageType & {storageId: string; humidityEnabled: boolean})[]
}

export function useStorageTypeList(typeKey?: string) {
  const {state, actions, effects} = useAppState()

  const storageByType = typeKey ? state.storageTypes.filter(st => st.equipmentType!.id === typeKey) : []
  const [removeIds, setRemoveIds] = useState<String[]>([])
  const typesInUse = state.equipments.map(e => e?.storageType?.id)

  const {watch, errors, register, control, triggerValidation, formState, unregister} = useForm<StorageTypesForm>({
    defaultValues: {
      storageTypes: storageByType.map(type => {
        return {
          storageId: type.id,
          name: type.name,
          minValue: localTempValue(type.minValue, state.site!),
          maxValue: localTempValue(type.maxValue, state.site!),
          timeLimit: type.timeLimit,
          inUse: typesInUse.includes(type.id),
          humidityEnabled: type.sensorType === StorageSensorType.TEMPERATURE_HUMIDITY,
          humidityMinValue: type.humidityMinValue,
          humidityMaxValue: type.humidityMaxValue,
          humidityTimeLimit: type.humidityTimeLimit
        }
      })
    },
    mode: 'onChange'
  })
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'storageTypes'
  })

  const storageTypes = watch({nest: true})

  useEffect(() => {
    storageTypes.storageTypes.forEach((storageType, i) => {
      if (!storageType.humidityEnabled) {
        unregister([
          `storageTypes[${i}].humidityMinValue`,
          `storageTypes[${i}].humidityMaxValue`,
          `storageTypes[${i}].humidityTimeLimit`
        ])
      }
    })
  }, [storageTypes.storageTypes])

  const handleAdd = () => {
    append({
      storageId: `new_${uuid()}`, // hack to track what items are new
      name: '',
      minValue: 0,
      maxValue: 0,
      timeLimit: 0,
      humidityEnabled: false,
      humidityMinValue: 0,
      humidityMaxValue: 0,
      humidityTimeLimit: 0
    })
    triggerValidation()
  }

  const isNew = (type: any) => {
    return startsWith(type.storageId, 'new_') // hack to track what items are new
  }

  const handleRemove = (index: number) => {
    fields[index].markedForDelete = true
    if (fields[index].inUse) {
      setRemoveIds([...removeIds])
      return
    }
    const typeToRemove = storageTypes.storageTypes[index]
    if (typeToRemove && !isNew(typeToRemove)) {
      const copy = [...removeIds]
      copy.push(`${typeToRemove.storageId}`)
      setRemoveIds(copy)
    }
    remove(index)
  }

  const saveStorageTypes = async (equipmentType: EquipmentType) => {
    const typesToUpdate = storageTypes.storageTypes.filter(type => {
      return !isNew(type)
    })
    const typesToCreate = storageTypes.storageTypes.filter(type => {
      return isNew(type)
    })
    await Promise.all(
      typesToCreate.map(async type => {
        return createStorageType(type, equipmentType)
      })
    )
    await Promise.all(
      typesToUpdate.map(async type => {
        return updateStorageType(type, equipmentType)
      })
    )
    await Promise.all(
      removeIds.map(async id => {
        return removeStorageType(id)
          .then(() => {
            const idx = storageTypes.storageTypes.findIndex(st => st.storageId === id)
            remove(idx)
          })
          .catch(ex => {
            const idx = storageTypes.storageTypes.findIndex(st => st.storageId === id)
            if (idx >= 0) {
              fields[idx].inUse = true
            }
            throw ex
          })
      })
    )
    actions.getStorageTypes()
  }

  const removeAllStorageTypes = async () => {
    const editIds = storageTypes.storageTypes
      .filter(type => {
        return !isNew(type)
      })
      .map(type => {
        return type.storageId
      })

    const idsToRemove = [...editIds, ...removeIds]

    Promise.all(idsToRemove.map(async id => removeStorageType(id))).then(() => {
      actions.getStorageTypes()
    })
  }

  const createAllStorageTypes = async (equipmentType: EquipmentType) => {
    const typesToCreate = storageTypes.storageTypes.filter(type => {
      return isNew(type)
    })

    Promise.all(
      typesToCreate.map(async type => {
        return createStorageType(type, equipmentType)
      })
    ).then(() => {
      actions.getStorageTypes()
    })
  }

  const createStorageType = async (type: any, equipmentType: EquipmentType) => {
    return effects.equipmentApi.createStorageType({
      name: type.name,
      minValue: type.minValue,
      maxValue: type.maxValue,
      timeLimit: type.timeLimit,
      equipmentType: equipmentType,
      sensorType: type.humidityEnabled ? StorageSensorType.TEMPERATURE_HUMIDITY : StorageSensorType.TEMPERATURE,
      humidityMinValue: type.humidityMinValue,
      humidityMaxValue: type.humidityMaxValue,
      humidityTimeLimit: type.humidityTimeLimit
    })
  }

  const updateStorageType = async (type: any, equipmentType: EquipmentType) => {
    return effects.equipmentApi.updateStorageType({
      id: type.storageId,
      name: type.name,
      minValue: type.minValue,
      maxValue: type.maxValue,
      timeLimit: type.timeLimit,
      equipmentType: equipmentType,
      sensorType: type.humidityEnabled ? StorageSensorType.TEMPERATURE_HUMIDITY : StorageSensorType.TEMPERATURE,
      humidityMinValue: type.humidityMinValue,
      humidityMaxValue: type.humidityMaxValue,
      humidityTimeLimit: type.humidityTimeLimit
    })
  }

  const removeStorageType = async (id: any) => {
    return effects.equipmentApi.deleteStorageType(id)
  }

  return {
    register,
    fields,
    errors,
    formState,
    handleAdd,
    handleRemove,
    storageTypes,
    saveStorageTypes,
    createAllStorageTypes,
    removeAllStorageTypes,
    triggerValidation
  }
}
