import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconAlarmClock = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.2658L11.9385 3L12.7521 3.97456L2.81357 12.2403L2 11.2658ZM35.2476 3.97456L45.1861 12.2403L45.9997 11.2658L36.0611 3L35.2476 3.97456ZM5.58439 25.3493C5.58439 15.1961 13.829 6.96694 24.0012 6.96694C34.1721 6.96694 42.418 15.1961 42.418 25.3493C42.418 35.5012 34.1721 43.7316 24.0012 43.7316C13.829 43.7316 5.58439 35.5012 5.58439 25.3493ZM24.0012 5.6984C13.1271 5.6984 4.31348 14.4955 4.31348 25.3493C4.31348 36.2017 13.127 45.0001 24.0012 45.0001C34.874 45.0001 43.6889 36.2018 43.6889 25.3493C43.6889 14.4955 34.874 5.6984 24.0012 5.6984ZM24.6357 12.5577H23.3647V26.3746L35.5152 34.7699L36.2386 33.7269L24.6357 25.7099V12.5577Z"
      />
    </svg>
  )
}
