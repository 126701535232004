import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Site} from '../../state/state'
import {useAppState} from '../../state'
import {useTranslation} from 'react-i18next'
import {ErrorMessage} from '../../Components/Molecules/Errors/ErrorMessage'
import {colors} from '../../sharedComponents/colors'

import {
  Text,
  DatePicker,
  Input,
  Table,
  ColumnDef,
  SharedIcon,
  IconPalette,
  Button,
  SwitchReversed
} from '../../sharedComponents/components'

import {SuspendedPeriod, SuspendedPeriodPayload} from '../../state/settings/suspendedPeriods/state'
import LoadingWithPuff from '../../Components/Atoms/LoadingWithPuff'
import {getDuration} from '../../Components/Atoms/Utils'
import {TFunction} from 'i18next'
import RemoveSuspendedPeriodModal from '../../modals/RemoveSuspendedPeriodModal'

export const formatClosingPeriod = (start: Date, end: Date, locale: string) => {
  const startAt = new Date(start).toLocaleDateString(locale)
  const endAt = new Date(end).toLocaleDateString(locale)
  return `${startAt} - ${endAt}`
}

export const SiteSuspendedPeriods = ({site}: {site: Site}) => {
  const {state, actions} = useAppState()
  const {t, i18n} = useTranslation('settings')
  const siteId = site!.id
  const locale = state.site!.locale || 'fi-FI'
  const language = i18n.language
  const [selectedPeriod, setSelectedPeriod] = useState<SuspendedPeriod | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    const getPeriods = async () => {
      await actions.v1.settings.suspendedPeriods.getSiteSuspendedPeriods({siteId, showLoadingIndicator: true})
    }
    getPeriods()
  }, [actions.v1.settings.suspendedPeriods, siteId])
  const {suspendedPeriods, error, loading} = state.v1.settings.suspendedPeriods
  const closingDates = suspendedPeriods
    ? suspendedPeriods.map(period => [new Date(period.startAt), new Date(period.endAt)])
    : []

  const handlePeriodDelete = async (periodId: string) => {
    await actions.v1.settings.suspendedPeriods.deleteSiteSuspendedPeriod({siteId, periodId})
    setSelectedPeriod(undefined)
    setErrorMessage(null)
    if (siteId === state.site?.id) {
      actions.notifyIfSiteSuspended()
    }
  }

  const setSelectedPeriodById = (id: string) => {
    const selected = suspendedPeriods?.find(period => period.id === id)
    if (selected) {
      setSelectedPeriod(selected)
    }
  }

  if (loading) {
    return <LoadingWithPuff />
  } else if (error) {
    return <ErrorMessage message={error.message} />
  } else {
    return (
      <Grid>
        {selectedPeriod && (
          <RemoveSuspendedPeriodModal
            handleRemove={handlePeriodDelete}
            period={selectedPeriod}
            locale={locale}
            isOpen={Boolean(selectedPeriod)}
            onClose={() => setSelectedPeriod(undefined)}
          />
        )}
        <NewSuspendedPeriodForm
          locale={locale}
          siteId={siteId}
          closingDates={closingDates}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
        <div style={{gridArea: 'table'}}>
          <SectionTitle size="XL" data-cy={`suspended-period-history-title`}>
            {t('settings:title.history', 'History')}
          </SectionTitle>
          {suspendedPeriods?.length === 0 ? (
            <Text size="S" data-cy="suspended-period-empty-placeholder">
              {t('settings:description.noClosingPeriod', 'Closing periods you define will appear here.')}
            </Text>
          ) : (
            <FormattedTable
              columnDefs={getColumnDefs(language, locale, t, setSelectedPeriodById)}
              data={suspendedPeriods ?? []}
            />
          )}
        </div>
      </Grid>
    )
  }
}

const getColumnDefs = (language: string, locale: string, t: TFunction, openModal: (id: string) => void) => {
  const calculateClosingDuration = (start: any, end: any) => {
    const startAt = new Date(start)
    const endAt = new Date(end)
    return getDuration(startAt, endAt, language, {unit: 'day'})
  }

  const columnDefs = [
    {
      title: t('settings:labels.columnNameClosingPeriod', 'Closing period'),
      dataKeys: ['startAt', 'endAt'],
      widthPercentage: '20%',
      component: (props: any) => (
        <Text size="S" strong={props.selected}>
          {formatClosingPeriod(props.values[0], props.values[1], locale)}
        </Text>
      )
    },
    {
      title: t('settings:labels.columnNameDuration', 'Duration'),
      dataKeys: ['startAt', 'endAt'],
      widthPercentage: '10%',
      component: (props: any) => (
        <Text size="S" strong={props.selected}>
          {`${calculateClosingDuration(props.values[0], props.values[1])}`}
        </Text>
      )
    },
    {
      title: t('settings:labels.columnNameAddedBy', 'Added by'),
      dataKeys: ['actor'],
      widthPercentage: '20%',
      component: (props: any) => (
        <Text size="S" strong={props.selected}>
          {props.values[0]}
        </Text>
      )
    },
    {
      title: t('settings:labels.columnNameReason', 'Reason'),
      dataKeys: ['reason'],
      widthPercentage: '45%',
      component: (props: any) => (
        <Text size="S" strong={props.selected}>
          {props.values[0]}
        </Text>
      )
    },
    {
      title: t('settings:labels.columnNameAlarmsEnabled', 'Appliance alarms enabled?'),
      dataKeys: ['alarmsEnabled'],
      widthPercentage: '15%',
      component: (props: any) => (
        <Text size="S" strong={props.selected} textTransform="capitalize">
          {props.values[0] === true
            ? t<string>('settings:alarmsEnabled.yes', 'yes')
            : t<string>('settings:alarmsEnabled.no', 'no')}
        </Text>
      )
    },
    {
      title: undefined,
      dataKeys: ['id'],
      widthPercentage: '4rem',
      component: (props: any) => (
        <div
          data-cy="delete-suspended-period"
          style={{paddingLeft: '1rem', cursor: 'pointer'}}
          onClick={() => openModal(props.values[0])}
        >
          <SharedIcon icon={IconPalette.Waste} height="2em" width="2em" fill={colors.brand.cobalt} />
        </div>
      )
    }
  ] as ColumnDef[]
  return columnDefs
}

type DatePicker = {
  startDate: Date
  endDate: Date
}

const NewSuspendedPeriodForm = ({
  locale,
  siteId,
  closingDates,
  setErrorMessage,
  errorMessage
}: {
  locale: string
  siteId: string
  closingDates: Date[][] | []
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>
  errorMessage: string | null
}) => {
  const getDefaultFormState = (siteId: string) => {
    const now = new Date()
    const dayFromNow = new Date(now.setDate(now.getDate() + 1))
    return {
      defaultForm: {
        reason: '',
        startAt: new Date().toISOString(),
        endAt: dayFromNow.toISOString(),
        alarmsEnabled: false,
        siteId: siteId
      },
      startDate: new Date(),
      endDate: dayFromNow
    }
  }
  const formState = getDefaultFormState(siteId)
  const {state, actions} = useAppState()
  const {t} = useTranslation('settings')
  const [formData, setFormData] = useState<SuspendedPeriodPayload>(formState.defaultForm)
  const [datePicker, setDatePicker] = useState<DatePicker>({startDate: formState.startDate, endDate: formState.endDate})
  const [submitting, setSubmitting] = useState<boolean>(false)

  const areDatesSelected = datePicker.startDate && datePicker.endDate
  const noOverlapMessage = t(
    'settings:description.noOverlapMessage',
    'You cannot add a closing period that overlaps with existing periods. Pick new dates or remove an existing period.'
  )
  const inValidDatesMessage = t(
    'settings:description.inValidDatesMessage',
    'Starting date for the suspended period must be before the ending date. Select new dates for this period.'
  )

  const switchAlarmsEnabled = () => {
    const alarmsEnabled = formData.alarmsEnabled
    setFormData({...formData, alarmsEnabled: !alarmsEnabled})
  }

  const changeReason = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({...formData, reason: event.target.value})
  }

  const areOverlappingPeriods = (existingPeriods: Date[][], selectedDates: DatePicker) => {
    const periodsOverlap = (existing: Date[], selected: DatePicker) => {
      return selected.endDate >= existing[0] && selected.startDate <= existing[1]
    }

    for (const period of existingPeriods) {
      if (periodsOverlap(period, selectedDates)) {
        return true
      }
    }
    return false
  }

  const onDateChange = (name: string, value: Date) => {
    setDatePicker({...datePicker, [name]: value})
  }

  const submit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const start = datePicker.startDate
    const end = datePicker.endDate

    // set start to 00:00:00 and end to 23:59:59:999 to make sure that the whole day is included
    start.setHours(0, 0, 0, 0)
    end.setHours(23, 59, 59, 999)

    const overlappingPeriods =
      closingDates.length === 0 || !areDatesSelected ? false : areOverlappingPeriods(closingDates, datePicker)

    if (overlappingPeriods) {
      setErrorMessage(noOverlapMessage)
    } else if (start >= end) {
      setErrorMessage(inValidDatesMessage)
    } else {
      setSubmitting(true)
      const payload = {
        ...formData,
        alarmsEnabled: !formData.alarmsEnabled, // when toggle is checked it has value of true,
        startAt: start.toISOString(),
        endAt: end.toISOString()
      }
      await actions.v1.settings.suspendedPeriods.newSiteSuspendedPeriod(payload)
      resetForm()
      setSubmitting(false)
      setErrorMessage(null)
      if (siteId === state.site?.id) {
        actions.notifyIfSiteSuspended()
      }
    }
  }

  const resetForm = () => {
    setFormData(formState.defaultForm)
    setDatePicker({startDate: formState.startDate, endDate: formState.endDate})
    setErrorMessage(null)
  }

  const isformDisabled = () => {
    const isReason = formData.reason !== undefined && formData.reason.trim().length !== 0
    return submitting || !isReason || !areDatesSelected
  }

  // TODO: update SwitchReversed so that you can inject the on/off text as disabled/enabled with translations
  return (
    <aside style={{gridArea: 'insert'}}>
      <SectionTitle size="XL" data-cy={`suspended-period-form-title`}>
        {t('settings:title.addPeriod', 'Add new closing periods')}
      </SectionTitle>
      <InputField data-cy="closing-period" style={{marginBottom: '2rem'}}>
        <label htmlFor="closingPeriod">
          <Text size="S">{t('settings:labels.columnNameClosingPeriod', 'Closing period')}</Text>
        </label>
        <DatePicker
          id="closingPeriod"
          onChange={onDateChange}
          startDate={datePicker.startDate}
          endDate={datePicker.endDate}
          locale={locale}
        />
      </InputField>
      {errorMessage && (
        <Text size="S" style={{color: colors.system.red, margin: '1rem 0 1rem 0', display: 'block'}}>
          {errorMessage}
        </Text>
      )}
      <InputField>
        <label htmlFor="instructions">
          <Text size="S">{t('settings:labels.columnNameSiteOperations', 'Site operations')}</Text>
        </label>
        <Text size="XS" light>
          {t(
            'settings:descriptions.siteOperations',
            'Generating alarms from appliance sensors and late tasks in Chefstein during closing period.'
          )}
        </Text>
      </InputField>
      <SwitchRow>
        <Text size="S">{t('settings:labels.columnNameScheduledTasks', 'Scheduled tasks')}</Text>
        <Text size="S">{t('settings:description.scheduledTasksConfig', 'Off')}</Text>
      </SwitchRow>
      <SwitchRow>
        <SwitchTitle>
          <Text size="S">{t('settings:labels.columnNameApplianceAlarms', 'Appliance alarms')}</Text>
        </SwitchTitle>
        <SwitchReversed
          checked={formData.alarmsEnabled}
          onChange={switchAlarmsEnabled}
          style={{float: 'right'}}
          disabled={submitting}
        />
      </SwitchRow>
      <ReasonInput>
        <Input
          id="closingPeriodReason"
          name="closingPeriodReason"
          label={t('settings:labels.columnNameReason', 'Reason')}
          placeholder={t('settings:input.reasonPlaceholder', 'Enter reason for closing the site.')}
          multiline
          rows={3}
          onChange={changeReason}
          value={formData.reason}
          disabled={submitting}
          required
          data-cy={`suspended-period-input-reason`}
        />
      </ReasonInput>
      <Button
        style={{float: 'right'}}
        buttonStyle="primary"
        buttonSize="small"
        onClick={submit}
        disabled={isformDisabled()}
        data-cy={`suspended-period-submit-button`}
      >
        {t('settings:button.addPeriod', 'Add period')}
      </Button>
      <Button
        style={{float: 'right', marginRight: '1rem'}}
        buttonStyle="secondary"
        buttonSize="small"
        onClick={resetForm}
        disabled={submitting}
        data-cy={`suspended-period-reset-button`}
      >
        {t('settings:button.reset', 'Reset')}
      </Button>
    </aside>
  )
}

const FormattedTable = styled(Table)`
  thead th {
    height: auto;
    border: none;
    font-size: 0.875rem;
  }

  th:first-child {
    padding: 0 0.5rem 0 0.5rem;
  }

  tbody tr {
    border-top: none;
  }

  td:first-child {
    padding-left: 0.5rem;
  }
`

const SwitchRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem 0;
`

const SwitchTitle = styled.div`
  line-height: 2em;
`

const ReasonInput = styled.div`
  margin: 4rem 0 2rem 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-template-rows: calc(100vh - 12rem);
  grid-template-areas: 'insert table';
  gap: 2.5rem;
`

const SectionTitle = styled(Text)`
  display: block;
  margin-bottom: 1.25rem;
`

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 0.25rem;
`
