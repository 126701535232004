import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Button, RightButtonRow} from '../../../Atoms/Buttons'
import CenteredModal, {WideModalContent} from '../../../Molecules/Modals/Modals'
import {Heading} from '../../../Atoms/Typography'
import {Baseline, Row} from '../../../Layout/Grid'
import {TextButton} from '../../../Atoms/Buttons'
import styled from 'styled-components'
import {colors} from '../../../../sharedComponents/colors'

import {Recipient, alarmsApi} from '../../../../state/rest'

import {Checkbox} from '../../../Atoms/Forms'
import {findIndex, pick} from 'lodash'

import {getRecipientName} from './AlarmSettingsUtils'
import AddRecipientForm from './AddRecipientForm'
import {v4 as uuid} from 'uuid'

// TODO! make table code more reusable
const TableContainer = styled.div`
  overflow: auto;
  max-height: 30vh;

  table {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  col {
    display: table-column;
  }
  colgroup {
    display: table-column-group;
  }
  tr {
    display: table-row;
    border: 1px solid black;
    border-bottom: 2px solid black;
    padding: 5px;
    border-bottom-width: 1px;
    margin-bottom: 0;
    vertical-align: inherit;
    border-color: inherit;
  }
  table > tr {
    vertical-align: middle;
  }
  th,
  td {
    display: table-cell;
    vertical-align: inherit;
    padding: 10px;
    text-align: left;
  }
  th {
    font-size: 11px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 50;
  }
  td {
    display: table-cell;
    text-align: left;
    font-size: 14px;
    border-bottom: none;

    &:last-child {
      border-bottom: none;
    }
  }

  .warn {
    color: ${colors.system.red};
  }
`

const AddRecipientContainer = styled.div`
  hr {
    margin: 1.5rem 0;
    border: 0;
    border-bottom: 1px solid ${colors.system.lightGrey_5};
  }
  > :not(hr) {
    margin: 1rem;
  }
`

interface RecipientOption {
  id: string
  isExt: boolean
  isNew: boolean
  selected: boolean
  recipient: Recipient
}

interface ModalProps {
  isOpen: boolean
  onCancel: () => void
  onSave: (recipients: Recipient[], extRecipients: Recipient[]) => void
  recipients?: Recipient[]
  extRecipients?: Recipient[]
}

const RECIPIENT_PROPS = ['id', 'name', 'firstName', 'lastName', 'email', 'phoneNumber', 'language']

const RecipientModal = ({isOpen, onCancel, onSave, recipients, extRecipients}: ModalProps) => {
  const {t} = useTranslation('alarms')
  const [addVisible, setAddVisible] = useState(false)
  const [recipientOptions, setRecipientOptions] = useState<RecipientOption[]>([])

  const includesRecipient = (recipient: Recipient, list?: Recipient[]) => {
    return (
      findIndex(list || [], r => {
        return r.id === recipient.id
      }) !== -1
    )
  }

  const setup = useCallback(async () => {
    const usersData = await alarmsApi.getRecipients()
    const userRecipients = usersData.items.map(user => {
      return pick(user, RECIPIENT_PROPS) as Recipient
    })
    const options = [...userRecipients, ...extRecipients].map(recipient => {
      return {
        id: recipient.id,
        isNew: false,
        isExt: includesRecipient(recipient, extRecipients),
        selected: includesRecipient(recipient, recipients) || includesRecipient(recipient, extRecipients),
        recipient: recipient
      }
    })
    setRecipientOptions(options)
  }, [recipients, extRecipients])

  useEffect(() => {
    setup()
  }, [setup])

  const selectMethod = useCallback(
    (id: string) => {
      setRecipientOptions(
        recipientOptions.map((option: RecipientOption, index: number) => {
          if (option.id === id) {
            return {...option, selected: !option.selected}
          }
          return option
        })
      )
    },
    [recipientOptions]
  )

  const {register, watch} = useForm({
    defaultValues: [],
    mode: 'onChange'
  })
  watch()

  const handleSave = useCallback(() => {
    if (onSave) {
      const newRecipients = recipientOptions
        .filter(o => {
          return o.selected && !o.isExt
        })
        .map(o => {
          return o.recipient
        })
      const newExtRecipients = recipientOptions
        .filter(o => {
          return o.selected && o.isExt
        })
        .map(o => {
          return o.recipient
        })
      onSave(newRecipients, newExtRecipients)
    }
  }, [onSave, recipientOptions])

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  const handleAddExtRecipient = useCallback(
    (recipient: Recipient) => {
      let copy = recipientOptions
      copy.push({
        id: uuid(),
        isNew: true,
        isExt: true,
        selected: true,
        recipient: recipient
      })
      setRecipientOptions(copy)
      setAddVisible(false)
    },
    [recipientOptions]
  )

  return (
    <CenteredModal isOpen={isOpen} data-cy="recipient-modal">
      <WideModalContent>
        <Baseline>
          <Heading level={3}>{t('alarms:labels.selectRecipients', 'Select recipients for alarm messages')}</Heading>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>SMS</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {recipientOptions.map((option: RecipientOption, index: number) => {
                  return (
                    <tr key={option.id}>
                      <td>
                        <Checkbox
                          name={option.id}
                          ref={register}
                          checked={option.selected}
                          onClick={() => selectMethod(option.id)}
                        />
                      </td>
                      <td>
                        {getRecipientName(option.recipient) ? (
                          getRecipientName(option.recipient)
                        ) : (
                          <span className="warn">{`(${t('alarms:labels.noName', 'no name')})`}</span>
                        )}
                      </td>
                      <td>
                        {option.recipient.phoneNumber ? (
                          option.recipient.phoneNumber
                        ) : (
                          <span className="warn">{`(${t('alarms:labels.noNumber', 'no number')})`}</span>
                        )}
                      </td>
                      <td>
                        {option.recipient.email ? (
                          option.recipient.email
                        ) : (
                          <span className="warn">{`(${t('alarms:labels.noAddress', 'no address')})`}</span>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </TableContainer>
        </Baseline>
        {!addVisible ? (
          <Row margin={'1.5rem 0'}>
            <TextButton onClick={() => setAddVisible(s => !s)}>
              {t('alarms:actions.addNewRecipient', 'Add new recipient')}
            </TextButton>
          </Row>
        ) : (
          <AddRecipientContainer>
            <hr />
            <div>
              <AddRecipientForm onCancel={() => setAddVisible(s => !s)} onAdd={handleAddExtRecipient} />
            </div>
            <hr />
          </AddRecipientContainer>
        )}
        <Baseline>
          <RightButtonRow>
            <Button variant="secondary" negative onClick={() => handleCancel()}>
              {t('common:buttons.cancel', 'Cancel')}
            </Button>
            <Button variant="secondary" onClick={() => handleSave()}>
              {t('common:buttons.save', 'Save')}
            </Button>
          </RightButtonRow>
        </Baseline>
      </WideModalContent>
    </CenteredModal>
  )
}

export default RecipientModal
