import React, {useEffect} from 'react'
import {useNavigation} from 'react-navi'
import {useAppState} from '../../../../state'
import {useTranslation} from 'react-i18next'
import {MainContent, MainLayoutWithoutStretch} from '../../../Layout/Layout'
import {Helmet} from 'react-navi-helmet-async'
import {GoBackHeaderTitle, HeaderRow} from '../../../Molecules/ViewComponents/ViewHeader'
import {Baseline, InvisibleContainer} from '../../../Layout/Grid'
import {IconButton} from '../../../Atoms/Buttons'
import {IconAddItem} from '../../../../Assets/Icons/LargeIcons'
import DocumentFolderList from '../FolderList'

export const InstructionsFolderSettings = () => {
  const nav = useNavigation()
  const {state, actions} = useAppState()
  const {t} = useTranslation('documents')

  const handleEdit = (id: string) => {
    nav.navigate(`/instructions/folders/${id}`)
  }

  useEffect(() => {
    const setup = async () => {
      await actions.v1.instructions.getInstructionsFolders()
    }
    setup()
  }, [actions.v1.instructions])

  const {instructionsFolders} = state.v1.instructions

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.instructionsFolderSettings', 'Instructions Folders')} />
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={t('documents:instructions.foldertitle', 'Instruction Folders')}
            path="/instructions"
            backLabel={t('documents:instructions.title', 'Instructions')}
          />
        </HeaderRow>
        <DocumentFolderList folders={instructionsFolders} onClick={handleEdit} />
        <Baseline>
          <InvisibleContainer>
            <IconButton
              icon={<IconAddItem />}
              description={t('documents:actions.addNewFolder', 'Add new folder')}
              buttonProps={{
                onClick: () => nav.navigate('/create/instructionsFolder')
              }}
            />
          </InvisibleContainer>
        </Baseline>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}
