import React, {ReactNode} from 'react'
import {ReactNodeArray} from 'prop-types'
import styled from 'styled-components'
import {CardContainer} from '../../Layout/Card'

const InfoCardContainer = styled(CardContainer)`
  width: 192px;
  height: 192px;
  display: inline-grid;
  grid-template-columns: 1fr;
`

interface InfoCardProps {
  children: ReactNode | ReactNodeArray
}
const SquareInfoCard = ({children}: InfoCardProps) => {
  return <InfoCardContainer>{children}</InfoCardContainer>
}

SquareInfoCard.defaultProps = {
  size: 1
}

export default SquareInfoCard
