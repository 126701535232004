import React, {FC, useEffect, useState} from 'react'
import {useNavigation} from 'react-navi'
import {useAppState} from '../../../state'
import {useTranslation} from 'react-i18next'
import {MainContent, MainLayoutWithoutStretch} from '../../../Components/Layout/Layout'
import {Helmet} from 'react-navi-helmet-async'
import {GoBackHeaderTitle, HeaderRow} from '../../../Components/Molecules/ViewComponents/ViewHeader'
import {Baseline, Grid, InvisibleContainer} from '../../../Components/Layout/Grid'
import {BigLabelInput, Label, LabelTextArea} from '../../../Components/Atoms/Forms'
import {DatePicker, IconText, Text} from '../../../sharedComponents/components'
import {Button, ButtonRowGrid, ButtonRowWrap} from '../../../Components/Atoms/Buttons'
import SiteSelectionCheckboxStyle from '../../../Components/Organisms/SiteSelectionCheckboxStyle'
import {useMessageSettingsForm} from './MessageSettingsUtils'
import {Message} from '../../../state/messages/state'
import {IconDelete} from '../../../Assets/Icons/TinyIcons'
import {ConfirmModalWindow} from '../DocumentsAndInstructions/HelperComponents'

const MAX_LENGTH_CONTENT = 5000
const MAX_LENGTH_HEADING = 100

type DatePicker = {
  startDate: Date
  endDate: Date
}

interface MessageSchedulerProps {
  t: any
  datePicker: DatePicker
  handleDateChange: (name: string, value: Date) => void
  locale: string
}

const MessageScheduler = ({t, datePicker, handleDateChange, locale}: MessageSchedulerProps) => {
  return (
    <Baseline>
      <Label required>{t('messages:labels.activePeriod', 'Active Period')}</Label>
      <DatePicker
        id="activePeriod"
        onChange={handleDateChange}
        startDate={datePicker.startDate}
        minDateForEndingDate={datePicker.startDate}
        minDateForStartingDate={new Date()}
        endDate={datePicker.endDate}
        locale={locale}
      />
    </Baseline>
  )
}

interface ButtonRowProps {
  t: any
  isEditing: boolean
  disableSave: boolean
  cancel: () => void
  submit: () => Promise<void>
  update: () => Promise<void>
  setModalOpen: (isModalOpen: boolean) => void
}

const ButtonRow = React.memo(({t, isEditing, disableSave, submit, cancel, update, setModalOpen}: ButtonRowProps) => {
  return (
    <ButtonRowWrap>
      {isEditing ? (
        <IconText
          onClick={() => setModalOpen(true)}
          icon={<IconDelete />}
          text={t('messages:actions.deleteMessage', 'Delete Message')}
        />
      ) : (
        <div className="empty" />
      )}
      <ButtonRowGrid>
        <Button name="cancel-button" negative variant="secondary" disabled={false} onClick={cancel}>
          {t('common:buttons.cancel', 'Cancel')}
        </Button>
        <Button variant="secondary" disabled={disableSave} onClick={isEditing ? update : submit}>
          {t('common:buttons.save', 'Save')}
        </Button>
      </ButtonRowGrid>
    </ButtonRowWrap>
  )
})

interface HeaderRowWithGoBackProps {
  t: any
  isEditing: boolean
}

const HeaderRowWithGoBack = React.memo(({t, isEditing}: HeaderRowWithGoBackProps) => {
  return (
    <HeaderRow>
      <GoBackHeaderTitle
        label={
          isEditing
            ? `${t('messages:labels.editMessage', 'Edit message')}`
            : `${t('messages:labels.addMessage', 'Add message')}`
        }
        path={'/settings/messages'}
        backLabel={t('settings:labels.messageSettings', 'Message Settings')}
      />
    </HeaderRow>
  )
})

const getStartDate = () => {
  const startDate = new Date()
  startDate.setHours(0, 0, 0, 0)
  return startDate.toISOString()
}

const getEndDate = () => {
  const endDate = new Date()
  endDate.setDate(endDate.getDate() + 1)
  endDate.setHours(23, 59, 59, 999)
  return endDate.toISOString()
}

interface FormProps {
  messageId?: string
}

const EditMessageView: FC<FormProps> = ({messageId}: FormProps): any => {
  const nav = useNavigation()
  const {state, actions} = useAppState()
  const {t} = useTranslation('messages')
  const currentState = state.me
  const chain = state.chainsById[state.selectedChainId!]
  let writableIds = currentState?.accessRights?.read.sites
  writableIds = writableIds?.concat(...(currentState?.accessRights?.write.chains ?? []))

  const [modalOpen, setModalOpen] = useState(false)
  const [defaultValues, setDefaultValues] = useState<Message>({
    content: '',
    heading: '',
    startDate: getStartDate(),
    endDate: getEndDate(),
    createdAt: '',
    createdBy: '',
    sites: [],
    latestUpdatedAt: '',
    latestUpdateBy: '',
    id: '',
    readStatuses: []
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fc = useMessageSettingsForm(defaultValues)
  fc.register('sites', {required: true})
  fc.register('startDate', {required: true})
  fc.register('endDate', {required: true})

  useEffect(() => {
    const setup = async () => {
      try {
        if (messageId) {
          const message = await actions.v1.messages.getMessage(messageId)
          await actions.v1.messages.getReadCountForMessage(messageId)
          setDefaultValues({
            content: message.content,
            heading: message.heading,
            startDate: message.startDate,
            endDate: message.endDate,
            createdAt: message.createdAt,
            createdBy: message.createdBy,
            sites: message.sites,
            latestUpdateBy: message.latestUpdateBy,
            latestUpdatedAt: message.latestUpdatedAt,
            id: message.id,
            readStatuses: message.readStatuses
          })
        }
      } catch (error) {
        console.error('Failed to fetch message', error)
      } finally {
        setIsLoading(false)
      }
    }
    setIsLoading(true)
    setup()
  }, [messageId])

  const locale = state.me?.user.language || 'fi-FI'

  const [isChainWide, setIsChainWide] = useState(false)

  const handleDateChange = (name: string, value: Date) => {
    const adjustedDate = new Date(value)
    if (name === 'startDate') {
      adjustedDate.setHours(0, 0, 0, 0)
    } else if (name === 'endDate') {
      adjustedDate.setHours(23, 59, 59, 999)
    }
    setDefaultValues({...defaultValues, [name]: adjustedDate.toISOString()})
    fc.setValue(name, adjustedDate.toISOString())
  }

  const handleSiteChange = (updatedSelectedSites: string[]) => {
    const finalSelectedSites = updatedSelectedSites.filter(
      siteId => !currentState?.accessRights.write.chains.includes(siteId)
    )
    setDefaultValues({...defaultValues, sites: finalSelectedSites})
    fc.setValue('sites', finalSelectedSites)
  }

  const isEditing = defaultValues.id != ''
  const title = isEditing
    ? t('common:routes.editMessage', 'Edit message')
    : t('common:routes.createMessage', 'Create message')

  const readCount = state.v1.messages.readCountPerMessage.find(msg => msg.messageId === messageId)?.readCount || 0
  const disableButton = defaultValues.content === '' || defaultValues.heading === '' || defaultValues.sites.length === 0
  return (
    <MainLayoutWithoutStretch>
      <Helmet title={title} />
      <ConfirmModalWindow
        t={t}
        setModalOpen={setModalOpen}
        handleDelete={fc.remove}
        modalOpen={modalOpen}
        heading={t('messages:confirmation.deleteMessageTitle', 'Delete Message?')}
        content={t('messages:confirmation.deleteMessageDescription', 'Are you sure you want to delete this message?')}
      />
      {!isLoading && (
        <>
          <MainContent variant="white">
            <HeaderRowWithGoBack t={t} isEditing={isEditing} />
            <InvisibleContainer>
              <Baseline>
                <Grid>
                  <div>
                    <BigLabelInput
                      maxLength={MAX_LENGTH_HEADING}
                      required
                      placeholder={t('messages:placeholders.newMessageTitle', 'New message title')}
                      name="heading"
                      id="heading"
                      labelText={t('messages:labels.messageTitle', 'Message Title')}
                      defaultValue={defaultValues.heading}
                      ref={fc.register({required: true})}
                      onChange={e => {
                        setDefaultValues({...defaultValues, heading: e.target.value})
                        fc.setValue('heading', e.target.value)
                      }}
                      errorMsg={
                        !!fc.errors.heading
                          ? t('common:validation.titleRequired', 'Title field is required')
                          : undefined
                      }
                    />
                    <Label>{`${fc.form.heading?.length || 0} / ${MAX_LENGTH_HEADING}`}</Label>
                  </div>
                  {
                    <div className="empty">
                      {isEditing
                        ? t('messages:labels.readCount', {
                            defaultValue: 'Read by {{readCount}} user',
                            readCount: readCount
                          })
                        : ''}
                    </div>
                  }
                </Grid>
                <Baseline>
                  <Grid>
                    <div>
                      <LabelTextArea
                        required
                        name="content"
                        labelText={t('messages:labels.messageContent', {
                          defaultValue: 'Message content (max {{length}} characters)',
                          length: MAX_LENGTH_CONTENT
                        })}
                        defaultValue={defaultValues.content}
                        ref={fc.register({required: true})}
                        errorMsg={
                          !!fc.errors.content
                            ? t('common:validation.contentRequired', 'Content field is required')
                            : undefined
                        }
                        maxLength={MAX_LENGTH_CONTENT}
                        onChange={e => {
                          setDefaultValues({...defaultValues, content: e.target.value})
                          fc.setValue('content', e.target.value)
                        }}
                      />
                      <Label>{`${fc.form.content?.length || 0} / ${MAX_LENGTH_CONTENT}`}</Label>
                    </div>
                    <div className="empty" />
                  </Grid>
                </Baseline>
                <Baseline>
                  <Grid>
                    <MessageScheduler
                      t={t}
                      datePicker={{
                        startDate: new Date(defaultValues.startDate),
                        endDate: new Date(defaultValues.endDate)
                      }}
                      handleDateChange={handleDateChange}
                      locale={locale}
                    />
                    <div className="empty" />
                  </Grid>
                </Baseline>
              </Baseline>
              <ButtonRow
                t={t}
                disableSave={disableButton}
                isEditing={isEditing}
                update={fc.update}
                submit={fc.submit}
                cancel={fc.cancel}
                setModalOpen={setModalOpen}
              />
            </InvisibleContainer>
          </MainContent>
          <SiteSelectionCheckboxStyle
            infoText={t('messages:messages.selectSites', 'Select sites to which this message should be visible.')}
            writableIds={writableIds || []}
            chain={chain}
            isLoading={false}
            isChainWide={isChainWide}
            selectedSites={defaultValues.sites}
            setIsChainWide={setIsChainWide}
            setSelectedSites={handleSiteChange}
            t={t}
          />
        </>
      )}
    </MainLayoutWithoutStretch>
  )
}

export default EditMessageView
