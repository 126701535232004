import {AsyncAction} from 'overmind'
import {InstructionsDocument, InstructionsFolder} from './state'

export const getInstructionsFolders: AsyncAction = async ({state, effects}) => {
  state.v1.instructions.instructionsFolders = []
  state.v1.instructions.documentFoldersError = null
  state.v1.instructions.documentFoldersPending = true
  try {
    const data = await effects.v1.instructions.getFolders()
    state.v1.instructions.instructionsFolders = data
  } catch (err) {
    state.v1.instructions.documentFoldersError = err
  } finally {
    state.v1.instructions.documentFoldersPending = false
  }
}

export const getInstructionsFolder: AsyncAction<string, InstructionsFolder> = async ({state, effects}, id: string) => {
  const data = await effects.v1.instructions.getDocumentFolder(id)
  return data
}

export const createInstructionsFolder: AsyncAction<Partial<InstructionsFolder>> = async (
  {actions, effects},
  payload
) => {
  await effects.v1.instructions.createDocumentFolder(payload)
}

export const deleteInstructionsFolder: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.v1.instructions.deleteDocumentFolder(id)
}

export const updateInstructionsFolder: AsyncAction<InstructionsFolder> = async ({actions, effects}, payload) => {
  await effects.v1.instructions.updateDocumentFolder(payload)
}

export const getInstructionsDocuments: AsyncAction = async ({state, effects}) => {
  state.v1.instructions.documents = []
  state.v1.instructions.documentsError = null
  state.v1.instructions.documentsPending = true
  try {
    const data = await effects.v1.instructions.getDocuments()
    state.v1.instructions.documents = data
  } catch (err) {
    state.v1.instructions.documentsError = err
  } finally {
    state.v1.instructions.documentsPending = false
  }
}

export const getInstructionsDocument: AsyncAction<string, InstructionsDocument> = async (
  {state, effects},
  id: string
) => {
  const data = await effects.v1.instructions.getDocument(id)
  return data
}

export const createInstructionsDocument: AsyncAction<Partial<InstructionsDocument>> = async (
  {actions, effects},
  payload
) => {
  await effects.v1.instructions.createDocument(payload)
}

export const deleteInstructionsDocument: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.v1.instructions.deleteDocument(id)
}

export const updateInstructionsDocument: AsyncAction<Partial<InstructionsDocument>> = async (
  {actions, effects},
  payload
) => {
  await effects.v1.instructions.updateDocument(payload)
}
