import {baseApi} from '../../rest'
import {ApplianceSetting, AvailableSensorsResponseBody, UpdateApplianceSettingsPayload} from './state'

export const applianceSettingsApi = {
  ...baseApi,
  async getAvailableSensorsForSite(siteId: string) {
    const req = await this.doRequest<any>({
      path: `/v1/applianceSettings/availableSensors`,
      site: siteId
    })
    const resp = (await req.get()) as unknown
    return resp as {data: AvailableSensorsResponseBody}
  },
  async getApplianceSettingOfSite(applianceId: string, siteId: string) {
    const req = await this.doRequest<any>({
      path: `/v1/applianceSettings/${applianceId}`,
      site: siteId
    })
    const resp = (await req.get()) as unknown
    return resp as {data: {items: ApplianceSetting}}
  },
  async updateApplianceSetting(applianceId: string, siteId: string, payload: UpdateApplianceSettingsPayload) {
    const req = await this.doRequest<any>({
      path: `/v1/applianceSettings/${applianceId}`,
      site: siteId,
      payload
    })
    const resp = (await req.put()) as unknown
    return resp as {data: {items: ApplianceSetting}}
  }
}
