import React, {useReducer, useEffect, useCallback, useState} from 'react'
import styled from 'styled-components'
import {TimePicker, LabelCheckbox} from '../../Atoms/Forms'
import {colors} from '../../../sharedComponents/colors'
import {Row, Baseline} from '../../Layout/Grid'
import SelectWeekdayWidget from './SelectWeekWidget'
import {getDay} from 'date-fns'
import {useAppState} from '../../../state'
import {WeekSchedule} from '../../../state/rest'
import {
  getLocale,
  getAlarmEndTime,
  getAlarmEndTimeView,
  parseISOTimeToDate,
  getISOTimeFromDate
} from '../../Atoms/Utils'
import {} from 'date-fns'
import {useTranslation} from 'react-i18next'

const SelectPeriodRow = styled(Row)`
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > * {
    margin-right: 0.5rem;
  }
`
const SelectAllDayRow = styled(Row)`
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 50%;

  > * {
    margin-right: 0.5rem;
  }
`

enum ActionType {
  CHANGE_WEEKDAYS = 'change_weekdays',
  CHANGE_FROM_TIME = 'change_from_time',
  CHANGE_TO_TIME = 'change_to_time'
}

// Reducer to handle state updating
const schedulerReducer = (state: WeekSchedule, action: dispatchAction) => {
  switch (action.type) {
    case ActionType.CHANGE_WEEKDAYS:
      return {...state, weekdays: action.payload}
    case ActionType.CHANGE_FROM_TIME:
      return {...state, from: action.payload}
    case ActionType.CHANGE_TO_TIME:
      return {...state, to: action.payload}
    default:
      return state
  }
}

interface dispatchAction {
  type: ActionType
  payload: any
}

interface SchedulerProps {
  schedule?: WeekSchedule
  onChange?: (schedule: WeekSchedule) => void
}
const WeekScheduler = ({schedule, onChange}: SchedulerProps) => {
  const {t} = useTranslation('alarms')
  const date = new Date()
  const {site} = useAppState().state
  const [allDay, setAllDay] = useState(schedule?.from === '00:00:00' && schedule.to === '24:00:00')
  const [schedulingError, setSchedulingError] = useState<boolean>(false)

  // backend requires weekdays to start from monday, so move sun to 6 and rest to n-1
  const handleWeekDays = (date: Date) => (date.getDay() === 0 ? 6 : getDay(date) - 1)
  const [scheduler, dispatch] = useReducer(
    schedulerReducer,
    schedule
      ? schedule
      : {
          weekdays: [handleWeekDays(date)],
          from: getISOTimeFromDate(date),
          to: getISOTimeFromDate(date)
        }
  )

  // event handlers
  const weekDaysChanged = (weekDays: number[]) => {
    dispatch({type: ActionType.CHANGE_WEEKDAYS, payload: weekDays})
  }
  const fromTimeChanged = (time: Date) => {
    dispatch({type: ActionType.CHANGE_FROM_TIME, payload: getISOTimeFromDate(time)})
  }
  const toTimeChanged = (time: Date) => {
    dispatch({type: ActionType.CHANGE_TO_TIME, payload: getAlarmEndTime(time)})
  }
  const changeTimeToAllDay = () => {
    dispatch({type: ActionType.CHANGE_FROM_TIME, payload: '00:00:00'})
    dispatch({type: ActionType.CHANGE_TO_TIME, payload: '24:00:00'})
  }

  // inform when the schedule gets updated
  const handleChange = useCallback(
    (schedule: WeekSchedule) => {
      if (onChange) {
        onChange(schedule)
      }
    },
    [onChange]
  )

  const SchedulerErrorMessage = styled.div.attrs({
    className: 'error'
  })`
    color: ${colors.system.red};
  `

  useEffect(() => {
    if (scheduler.from === '00:00:00' && scheduler.to === '00:00:00') {
      scheduler.to = '24:00:00'
      setAllDay(true)
    }
    setSchedulingError(parseISOTimeToDate(scheduler.from) >= parseISOTimeToDate(scheduler.to))
    handleChange(scheduler)
    return () => {
      // cleanup
    }
  }, [scheduler, handleChange, schedulingError])

  return (
    <Baseline>
      {schedulingError ? (
        <SchedulerErrorMessage>
          {t('settings:error.alarmSetting', 'Scheduled starting time must be before ending time.')}
        </SchedulerErrorMessage>
      ) : null}
      <SelectPeriodRow>
        <TimePicker
          locale={getLocale(site!.locale)}
          date={parseISOTimeToDate(scheduler.from!)}
          onChange={fromTimeChanged}
          disabled={allDay}
          minTime={parseISOTimeToDate('00:00:01')}
          maxTime={parseISOTimeToDate(getAlarmEndTimeView(scheduler.to)!)}
        />
        <span>{'-'}</span>
        <TimePicker
          locale={getLocale(site!.locale)}
          date={parseISOTimeToDate(getAlarmEndTimeView(scheduler.to)!)}
          onChange={toTimeChanged}
          disabled={allDay}
          minTime={parseISOTimeToDate(scheduler.from!)}
          maxTime={parseISOTimeToDate('23:59:59')}
        />
        <SelectAllDayRow>
          <LabelCheckbox
            flipped={false}
            label={t('alarms:labels.allDay', 'All day')}
            name="allDay"
            checked={allDay}
            onClick={() => {
              if (!allDay) {
                changeTimeToAllDay()
              } else {
                scheduler.from = '09:00:00'
                scheduler.to = '17:00:00'
              }
              setAllDay(!allDay)
            }}
          />
        </SelectAllDayRow>
      </SelectPeriodRow>

      <SelectWeekdayWidget selectedWeekDays={scheduler.weekdays} onChange={weekDaysChanged}></SelectWeekdayWidget>
    </Baseline>
  )
}

export default WeekScheduler
