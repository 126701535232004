import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconWaste = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89697 8.71191H39.3935V42.8584C39.3935 44.5935 38.0126 46 36.3093 46H10.9811C9.2778 46 7.89697 44.5935 7.89697 42.8584V8.71191ZM9.13111 9.96906V42.8584C9.13111 43.8992 9.9594 44.7429 10.9811 44.7429H36.3093C37.331 44.7429 38.1593 43.8992 38.1593 42.8584V9.96906H9.13111Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0701 16.4575V39.3061H15.8101V16.4575H17.0701Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.2878 16.4575V39.3061H23.0278V16.4575H24.2878Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.5061 16.4575V39.3061H30.2461V16.4575H31.5061Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M45.0002 9.96869H3V8.71155H45.0002V9.96869Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0005 3.25714C20.592 3.25714 17.8298 6.07081 17.8298 9.54286H16.5957C16.5957 5.37651 19.9104 2 24.0005 2C28.0907 2 31.4054 5.37651 31.4054 9.54286H30.1712C30.1712 6.07081 27.4091 3.25714 24.0005 3.25714Z"
      />
    </svg>
  )
}
