import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {Text, H3} from '../../../Atoms/Typography'
import {IconSettings} from '../../../../Assets/Icons/TinyIcons'
import {IconDocument, IconDocumentLinked} from '../../../../Assets/Icons/LargeIcons'
import {colors} from '../../../../sharedComponents/colors'
import {IconButton} from '../../../Atoms/Buttons'
import {AccordionItem} from '../../../Atoms/Accordion'
import {DocumentType} from '../../../../state/documents/state'
import {A11yIconListItem, OffsetListItem} from '../../../Atoms/Lists'

const ListItem = AccordionItem

const NoItemContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: start;
`

interface DocumentItemProps {
  id: string
  type: string
  name: string
  onConfigure?: (id: string) => void
  onClick?: (id: string) => void
}

const DocumentListItem = ({id, type, name, onClick, onConfigure}: DocumentItemProps) => {
  const handleConfigure = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onConfigure) {
      e.preventDefault()
      e.stopPropagation()
      if (onConfigure) onConfigure(id)
    }
  }
  const handleClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  return (
    <A11yIconListItem
      offset={5.3}
      icon={type === DocumentType.Internal ? <IconDocument /> : <IconDocumentLinked />}
      onClick={handleClick}
      content={<H3>{name}</H3>}
      action={
        <IconButton
          buttonProps={{
            onClick: handleConfigure
          }}
          icon={<IconSettings color={colors.brand.cobalt} />}
        />
      }
    />
  )
}

export const NoDocumentsItem = () => {
  const {t} = useTranslation('documents')
  return (
    <ListItem>
      <NoItemContent>
        <OffsetListItem offset={3.6}>
          <Text level={1}>{t('documents:messages.noDocuments', 'No documents')}</Text>
        </OffsetListItem>
      </NoItemContent>
    </ListItem>
  )
}

export const NoDocumentsOrFoldersItem = () => {
  const {t} = useTranslation('documents')
  return (
    <ListItem>
      <NoItemContent>
        <Text level={1}>{t('documents:messages.noDocumentsOrFolder', 'No documents or folders.')}</Text>
        <Text level={1}>
          {t(
            'documents:messages.createNewFolders',
            'Create new folders from the folder settings and then add new documents.'
          )}
        </Text>
      </NoItemContent>
    </ListItem>
  )
}

export default DocumentListItem
