import {baseApi} from '../../rest'
import {TaskGroup} from './state'

interface TaskGroupResponse {
  items: TaskGroup[]
}

export const tasksApi = {
  ...baseApi,
  async getTaskGroups(): Promise<TaskGroup[]> {
    const request = await this.doRequest<TaskGroupResponse>({
      path: '/v1/settings/taskgroups'
    })
    const response = await request.get()
    return response.data.items
  }
}
