import {ISignUpResult} from 'amazon-cognito-identity-js'
import {CognitoUser} from '@aws-amplify/auth'
// import Amplify from '@aws-amplify/core'
import {v4 as uuid} from 'uuid'
import Auth from '@aws-amplify/auth'

export interface CognitoUserExtended extends CognitoUser {
  challengeName: string // cognito typescript definitions does not contain this (yet)
  email: string // pass on email for kludge fix for force reset password
}

/**
 * authentication effects
 */

const UserPoolId = process.env.REACT_APP_COGNITO_USERPOOL
const ClientId = process.env.REACT_APP_COGNITO_CLIENTID
const AwsRegion = process.env.REACT_APP_AWS_REGION

Auth.configure({
  userPoolId: UserPoolId,
  userPoolWebClientId: ClientId,
  region: AwsRegion
})

const signUp = (email: string, password: string) => {
  const username = uuid() // generate a random username (will not be used in reality, but email is used in sign-in)
  return Auth.signUp({username, password, attributes: {email}}) as Promise<ISignUpResult>
}
const getCurrentUser = async () => {
  try {
    const user = (await Auth.currentAuthenticatedUser()) as CognitoUser
    return user
  } catch (e) {
    return null
  }
}
const logout = () => Auth.signOut()

const getToken = async () => {
  const session = await Auth.currentSession()
  const token = session.getIdToken()
  return token.getJwtToken()
}
const authenticate = (username: string, password: string) => {
  return Auth.signIn({username, password}) as Promise<CognitoUserExtended>
}
const mfaAuthenticate = (user: CognitoUser, code: string) => {
  try {
    return Auth.confirmSignIn(user, code) as Promise<CognitoUser>
  } catch (err) {
    console.log('Error confirming MFA')
    throw err
  }
}
const changePassword = async (currentPassword: string, newPassword: string) => {
  const user = await getCurrentUser()
  return Auth.changePassword(user, currentPassword as string, newPassword as string) as Promise<string>
}

const currentCredentials = async () => {
  await Auth.currentSession()
  return Auth.essentialCredentials(await Auth.currentCredentials())
}

const completeForceResetPassword = async (
  user: CognitoUser,
  newPassword: string,
  email: string
): Promise<CognitoUser> => {
  const requiredAttributes = null // not needed since email is already added to cognito earlier
  try {
    return await Auth.completeNewPassword(user, newPassword, requiredAttributes)
  } catch (err) {
    const name = err.name ? err.name.toLowerCase() : ''
    const message = err.message ? err.message.toLowerCase() : ''
    if (
      name.indexOf('typeerror') > -1 ||
      message.indexOf('illegal invocation') > -1 ||
      message.indexOf('does not implement interface storage') > -1
    ) {
      // TODO aws auth does not work currently correctly, bug? after completeNewPassword() will fail during setting tokens into local storage
      // thus we will here silently ignore the error and assume the password reset was ok => do explicitly an extra authenticate call below.
      // this can be removed later if/when completeNewPassword() will work as it should.
      console.debug('kludge bugfix: ignore this error and do explicit authenticate with email [%s]', email)
      return await authenticate(email, newPassword)
    }
    throw err
  }
}

const forgotPassword = (username: string) => {
  return Auth.forgotPassword(username)
}

const confirmPassword = (username: string, password: string, code: string) => {
  return Auth.forgotPasswordSubmit(username, code, password)
}

const authApi = {
  authenticate,
  getCurrentUser,
  logout,
  signUp,
  getToken,
  changePassword,
  forgotPassword,
  confirmPassword,
  currentCredentials,
  completeForceResetPassword,
  mfaAuthenticate
}

export default authApi
