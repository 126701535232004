import React, {useState, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import tiny from 'tinycolor2'
import {slice} from 'lodash'

import {Row} from '../../Layout/Grid'
import {Label} from '../../Atoms/Forms'
import {colors} from '../../../sharedComponents/colors'

const MonthWidgetWrapper = styled.div``

const MonthPickerWrapper = styled.div`
  margin: 0.4rem;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
  display: inline-block;
`
const MonthPickerRow = styled.div`
  white-space: nowrap;
  text-align: center;
`

const MonthPickerDay = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  &:hover {
    background-color: #f0f0f0;
  }
  &.selected {
    background-color: ${colors.brand.cobalt};
    font-weight: bold;
    color: white;
    &:hover {
      background-color: ${tiny(colors.brand.cobalt)
        .darken(2)
        .toHexString()};
    }
  }
  &.extra-days {
    color: ${colors.system.grey_1};
    &.selected {
      color: ${colors.system.white};
    }
  }
`

const possibleDates = () => {
  return [...Array<number>(31)].map((arr, i) => i + 1)
}

interface MonthWidgetProps {
  days?: number[]
  onChange?: (days: number[]) => void
}
const SelectMonthWidget = ({days, onChange}: MonthWidgetProps) => {
  const {t} = useTranslation('tasks')
  const [selected, setSelected] = useState(days ? days : [])

  const handleSelect = useCallback(
    (value: number, prevSelected: number[]) => {
      let newSelected
      if (!prevSelected.includes(value)) {
        newSelected = [...prevSelected, value]
      } else {
        newSelected = prevSelected.filter(s => s !== value)
      }

      setSelected(newSelected)
      if (onChange) {
        onChange(newSelected)
      }
    },
    [onChange]
  )

  return (
    <MonthWidgetWrapper>
      <Row>
        <Label>{t('tasks:labels.selectDay', 'Select days')}</Label>
      </Row>

      <MonthPickerWrapper>
        <DateRow
          dates={slice(possibleDates(), 0, 7)}
          selected={selected}
          onClick={(date: number) => handleSelect(date, selected)}
        />
        <DateRow
          dates={slice(possibleDates(), 7, 14)}
          selected={selected}
          onClick={(date: number) => handleSelect(date, selected)}
        />
        <DateRow
          dates={slice(possibleDates(), 14, 21)}
          selected={selected}
          onClick={(date: number) => handleSelect(date, selected)}
        />
        <DateRow
          dates={slice(possibleDates(), 21, 28)}
          selected={selected}
          onClick={(date: number) => handleSelect(date, selected)}
        />
        <DateRow
          dates={slice(possibleDates(), 28, 31)}
          selected={selected}
          className="extra-days"
          onClick={(date: number) => handleSelect(date, selected)}
        />
      </MonthPickerWrapper>
      <Row>
        <Label>{t('tasks:labels.mayNotOccur', '* May not occur in every month')}</Label>
      </Row>
    </MonthWidgetWrapper>
  )
}

interface DateRowProps {
  dates: number[]
  selected: number[]
  className?: string
  onClick: (dateNumber: number) => void
}
const DateRow = ({dates, selected, className, onClick}: DateRowProps) => {
  const handleSelect = (value: number) => {
    onClick(value)
  }
  return (
    <MonthPickerRow>
      {dates.map(date => (
        <MonthPickerDay
          key={date}
          onClick={() => handleSelect(date)}
          className={`date-box ${className} ${selected.includes(date) ? 'selected' : null}`}
        >
          {date}
        </MonthPickerDay>
      ))}
    </MonthPickerRow>
  )
}

export default SelectMonthWidget
