import {AsyncAction} from 'overmind'
import {DocumentFolder, Document} from './state'

export const getDocuments: AsyncAction = async ({state, effects}) => {
  state.v1.documents.documents = []
  state.v1.documents.documentsError = null
  state.v1.documents.documentsPending = true
  try {
    const data = await effects.v1.documents.getDocuments()
    state.v1.documents.documents = data.items
  } catch (err) {
    state.v1.documents.documentsError = err
  } finally {
    state.v1.documents.documentsPending = false
  }
}

export const getDocument: AsyncAction<string, Document> = async ({state, effects}, id: string) => {
  const data = await effects.v1.documents.getDocument(id)
  return data
}

export const createDocument: AsyncAction<Partial<Document>> = async ({actions, effects}, payload) => {
  await effects.v1.documents.createDocument(payload)
}

export const deleteDocument: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.v1.documents.deleteDocument(id)
}

export const updateDocument: AsyncAction<Partial<Document>> = async ({actions, effects}, payload) => {
  await effects.v1.documents.updateDocument(payload)
}

export const getDocumentFolders: AsyncAction = async ({state, effects}) => {
  state.v1.documents.documentFolders = []
  state.v1.documents.documentFoldersError = null
  state.v1.documents.documentFoldersPending = true
  try {
    const data = await effects.v1.documents.getDocumentFolders()
    state.v1.documents.documentFolders = data.items
  } catch (err) {
    state.v1.documents.documentFoldersError = err
  } finally {
    state.v1.documents.documentFoldersPending = false
  }
}

export const getDocumentFolder: AsyncAction<string, DocumentFolder> = async ({state, effects}, id: string) => {
  const data = await effects.v1.documents.getDocumentFolder(id)
  return data
}

export const createDocumentFolder: AsyncAction<Partial<DocumentFolder>> = async ({actions, effects}, payload) => {
  await effects.v1.documents.createDocumentFolder(payload)
}

export const deleteDocumentFolder: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.v1.documents.deleteDocumentFolder(id)
}

export const updateDocumentFolder: AsyncAction<DocumentFolder> = async ({actions, effects}, payload) => {
  await effects.v1.documents.updateDocumentFolder(payload)
}
