import React from 'react'
import {IconProps} from './Icons'

/**
 * Add icons related to main navigation menu here
 */

export const IconMenuSettings = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M13.84 21.16h-3.68l-.4-2.42a7.27 7.27 0 01-2.48-1.44l-2.29.87-1.84-3.19 1.9-1.55c-.1-.48-.15-.96-.15-1.43s.05-.95.15-1.43l-1.9-1.55 1.84-3.19 2.29.87C8 6.06 8.85 5.57 9.77 5.27l.4-2.42h3.68l.4 2.42c.91.3 1.76.79 2.48 1.43l2.29-.87 1.84 3.19-1.9 1.55c.1.48.15.96.15 1.43s-.05.95-.15 1.43l1.9 1.55-1.84 3.19-2.29-.87c-.72.64-1.57 1.13-2.48 1.43l-.41 2.43zm-2.83-1h1.98l.36-2.2.31-.09c.97-.27 1.87-.79 2.6-1.5l.23-.22 2.09.79.99-1.71-1.73-1.42.08-.31c.13-.51.19-1.01.19-1.5s-.07-.99-.19-1.5l-.08-.31 1.73-1.41-.99-1.71-2.09.79-.23-.22a6.131 6.131 0 00-2.6-1.5l-.31-.09-.36-2.2h-1.98l-.36 2.2-.31.09c-.98.27-1.87.79-2.6 1.5l-.22.21-2.09-.79-.99 1.72 1.73 1.41-.08.31a6.048 6.048 0 000 3l.08.31-1.73 1.42.99 1.71 2.09-.79.23.22c.72.71 1.62 1.22 2.6 1.5l.31.09.35 2.2z" />
    <path d="M12 15.97c-2.19 0-3.97-1.78-3.97-3.97S9.81 8.03 12 8.03s3.97 1.78 3.97 3.97-1.78 3.97-3.97 3.97zm0-6.94a2.97 2.97 0 100 5.94 2.97 2.97 0 000-5.94z" />
  </svg>
)

const defaultProps = {
  width: '24px',
  height: '24px',
  color: 'inherit'
}

IconMenuSettings.defaultProps = defaultProps
