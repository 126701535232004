import React, {useState, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {uniqueId} from 'lodash'
import {format, parseISO} from 'date-fns'

import {DatePicker} from '../../Atoms/Forms'
import {Row} from '../../Layout/Grid'
import {Label} from '../../Atoms/Forms'
import {IconCloseSmall} from '../../../Assets/Icons/TinyIcons'
import {IconButton, TextButton} from '../../Atoms/Buttons'
import {useAppState} from '../../../state'
import {getLocale} from '../../Atoms/Utils'

const YearWidgetWrapper = styled.div``
const DateList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const DateListItem = styled.li`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;

  > button {
    margin: auto 8px;
  }
`
const DATE_FORMAT = 'yyyy-MM-dd' // TODO: Localization

interface SelectedDate {
  date: Date
  id: string
}
interface YearWidgetProps {
  dates?: string[]
  onChange?: (dates: string[]) => void
}
const SelectYearWidget = ({dates, onChange}: YearWidgetProps) => {
  const {t} = useTranslation('tasks')
  const {site} = useAppState().state
  const [selectedDates, setSelectedDates] = useState<SelectedDate[]>(
    (dates ? dates : []).map((dateString: string) => {
      return {
        date: parseISO(dateString),
        id: uniqueId()
      }
    })
  )

  const handleChange = (date: Date, id: string) => {
    console.debug(`Date changed: ${date} with id ${id}`)
    const newDates = selectedDates.map(d => {
      if (d.id === id) {
        d = {...d, date: date}
      }
      return d
    })
    setSelectedDates(newDates)
    handleOnChange(newDates)
  }

  const handleRemove = (id: string) => {
    let newDates = selectedDates.filter(d => d.id !== id)
    setSelectedDates(newDates)
    handleOnChange(newDates)
  }

  const handleAdd = () => {
    let newDates = [
      ...selectedDates,
      {
        date: new Date(),
        id: uniqueId()
      }
    ]
    setSelectedDates(newDates)
    handleOnChange(newDates)
  }

  const handleOnChange = useCallback(
    (newDates: SelectedDate[]) => {
      if (onChange) {
        onChange(
          newDates.map(selectedDate => {
            return format(selectedDate.date, DATE_FORMAT)
          })
        )
      }
    },
    [onChange]
  )

  return (
    <YearWidgetWrapper>
      <Row>
        <Label>{t('tasks:labels.selectDates', 'Select dates')}</Label>
      </Row>

      <DateList>
        {selectedDates.map(d => (
          <DateListItem key={d.id}>
            <DatePicker
              date={d.date}
              onChange={(date: Date) => handleChange(date, d.id)}
              locale={getLocale(site!.locale)}
            ></DatePicker>
            <IconButton
              icon={<IconCloseSmall />}
              buttonProps={{className: 'removeIcon', onClick: () => handleRemove(d.id)}}
            />
          </DateListItem>
        ))}
      </DateList>

      <Row>
        <TextButton onClick={() => handleAdd()}>{t('tasks:actions.addNewDate', 'Add new date')}</TextButton>
      </Row>
    </YearWidgetWrapper>
  )
}

export default SelectYearWidget
