import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {Text} from '../../atoms/Text'
import {Icon} from '../../atoms/Icon'

import {colors} from '../../../colors'
import {getValueText} from '../ApplianceData'

export function isInRange(value: number, min?: number, max?: number) {
  if (min && max) {
    return value >= min && value <= max
  } else if (min && !max) {
    return value >= min
  } else if (!min && max) {
    return value <= max
  }
  return undefined
}

export type ValueInputWithRangeProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value: string | null
  minValue?: number
  maxValue?: number
  unit: string
  message?: {
    success: string
    error: string
  }
  reminder?: ReactNode
  label?: string
}
export const ValueInputWithRange: React.FC<ValueInputWithRangeProps> = ({
  minValue,
  maxValue,
  unit,
  value,
  message,
  reminder,
  label,
  ...rest
}) => {
  let textColor = colors.brand.cobalt
  let valueIsInRange
  // only caculate valueIsInRange when there are input value and at least one boundery value
  if (value && (minValue || maxValue)) {
    // convert local string(123,23) to number(123.23)
    // however it might now work if some countries use comma for 3-digit divider
    const valueInNumber = parseFloat(value.replace(',', '.'))
    valueIsInRange = isInRange(valueInNumber, minValue, maxValue)
    if (valueIsInRange) {
      textColor = colors.system.green
    } else {
      textColor = colors.system.red
    }
  }

  return (
    <div>
      {label && (
        <label htmlFor={rest.id}>
          <Text size="M">{label + (rest.required ? ' *' : '')}</Text>
        </label>
      )}
      <ValueInputWithRangeContainer>
        <ValueInputWithRangeWapper>
          <Text size="XS" color={colors.system.grey_50}>
            {minValue ? `Min ${getValueText(minValue, unit)}` : ''}
          </Text>
          <div>
            <ValueInputWithUnit>
              <ValueInputContainer>
                <IconWapper valueIsInRange={valueIsInRange}>
                  <Icon
                    type={valueIsInRange ? 'check-circle' : 'exclamation-circle'}
                    width="1.4em"
                    height="1.4em"
                    fill={textColor}
                  />
                </IconWapper>
                <ValueInput placeholder="__" color={textColor} value={value} {...rest} />
              </ValueInputContainer>
              <Text size="M" color={textColor}>
                {unit}
              </Text>
            </ValueInputWithUnit>
          </div>
          <Text size="XS" color={colors.system.grey_50}>
            {maxValue ? `${getValueText(maxValue, unit)} Max` : ''}
          </Text>
        </ValueInputWithRangeWapper>
        {reminder}
        <MessageWapper valueIsInRange={valueIsInRange} message={message}>
          <Text size="S" color={valueIsInRange ? colors.system.green : colors.system.red}>
            {valueIsInRange ? message?.success : message?.error}
          </Text>
        </MessageWapper>
      </ValueInputWithRangeContainer>
    </div>
  )
}

const ValueInputWithUnit = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
`

const ValueInputWithRangeContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  width: 100%;
  justify-content: center;
`

const IconWapper = styled.div<{valueIsInRange: boolean | undefined}>`
  position: relative;
  top: 1rem;
  background-color: white;
  visibility: ${props => (typeof props.valueIsInRange === 'boolean' ? 'visible' : 'hidden')};
`

const MessageWapper = styled.div<{
  valueIsInRange: boolean | undefined
  message?: {
    success: string
    error: string
  }
}>`
  text-align: center;
  visibility: ${props => (typeof props.valueIsInRange === 'boolean' && props.message ? 'visible' : 'hidden')};
`

const ValueInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ValueInputWithRangeWapper = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr 4rem;
  gap: 2rem;
  align-items: center;
  min-height: 2.56rem;
`

const ValueInput = styled.input`
  width: 8rem;
  text-align: center;

  border: 1px solid ${props => (props.value ? props.color : colors.system.grey_5)};
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  color: ${props => props.color};
  font-size: 2.5rem; /*40px;*/
  line-height: 3rem; /*48px;*/
  font-weight: 700;
  letter-spacing: 0.35px;
  font-family: 'FavoritStd-Bold'

  ::placeholder {
    color: ${colors.system.grey_50};
  }

  /* Hide Arrows From Input Number */
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;
`
