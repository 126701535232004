import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconIconList = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.6251 40.3428H21.6626V39.0857H34.6251V40.3428Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.6251 27.7715H21.6626V26.5144H34.6251V27.7715Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.6251 34.0572H21.6626V32.8H34.6251V34.0572Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 2H39V46H9V2ZM10.2 3.25714V44.7429H37.8V3.25714H10.2Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4406 27.8976L18.5738 24.7465L19.4577 25.6354L15.4409 29.6751L13.2114 27.4345L14.095 26.5453L15.4406 27.8976Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4406 34.1576L18.5738 31.0065L19.4577 31.8954L15.4409 35.9351L13.2114 33.6945L14.095 32.8053L15.4406 34.1576Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4406 40.4174L18.5738 37.2662L19.4577 38.1552L15.4409 42.1949L13.2114 39.9543L14.095 39.0651L15.4406 40.4174Z"
      />
    </svg>
  )
}
