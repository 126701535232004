import React, {FC, Fragment, ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {H1, P} from '../../Atoms/Typography'
import {Button, ButtonRowWrap} from '../../Atoms/Buttons'
import {Heading} from '../../Atoms/Typography'
import TabNavigator from '../../Molecules/TabNavigator'
import {Baseline, Row, GridSpan} from '../../Layout/Grid'
import AssetUploader from './AssetUploader'
import useAddAsset from './useAddAsset'
import AssetsList from './AssetsList'
import {includes, findIndex} from 'lodash'
import {AssetData} from '../../../state/rest'

import {AddAssetProps, AssetCategory} from './types'

const AddAsset: FC<AddAssetProps> = (props): ReactElement => {
  const [assetSelected, setSelectedAsset] = useState<AssetData>()
  const [selectedTab, setSelectedTab] = useState(0)
  const fc = useAddAsset()
  const {t} = useTranslation('assets')

  //TODO: create endpoints calling specific category of assets to showcase in the list
  const assets = [] as AssetData[]

  // with this filter it is possible to limit the amount of "ready documents to select" list
  const filterAssetsByCategory = (assets: AssetData[], category: AssetCategory) => {
    switch (category) {
      case AssetCategory.task:
        return assets.filter(asset => asset.tasks && asset.tasks.length > 0)
      default:
        return assets
    }
  }

  const assetOptions = props.assetCategory ? filterAssetsByCategory(assets, props.assetCategory) : assets

  const onClick = (asset: AssetData): void => {
    setSelectedAsset(asset)
  }

  const handleAssetSaved = (asset: AssetData): void => {
    if (asset) {
      if (props.tabContents.length > 1) {
        setSelectedTab(findIndex(props.tabContents, ['assetType', asset.assetType]))
      }
      setSelectedAsset(asset)
    }
  }

  const hasAssets = () => {
    const types = props.tabContents.map(tabContent => tabContent.assetType)
    const assetsOfType = assetOptions.filter(asset => includes(types, asset.assetType))
    return assetsOfType.length > 0
  }

  const hasMultipleAssetTypes = () => {
    return props.tabContents.length > 1
  }

  // TODO: needs more work in terms of what assets we want to show in the asset list.
  // Disabled this for the time being.
  const showAssetList = () => {
    return false
  }

  return (
    <Fragment>
      <Baseline>
        <H1>{props.title}</H1>
        <P>{props.label}</P>
        <AssetUploader
          acceptedTypes={props.acceptedTypes}
          maxFiles={1}
          multiple={false}
          handleAddAsset={fc.addAsset}
          onAssetSaved={handleAssetSaved}
          instructions={props.instructions}
        />
        {showAssetList() && hasAssets() && hasMultipleAssetTypes() ? (
          <TabNavigator
            selectedIndex={selectedTab}
            tabs={props.tabContents.map(tabContent => ({content: <Heading level={6}>{tabContent.title}</Heading>}))}
            content={props.tabContents.map(tabContent => (
              <AssetsList
                assets={assetOptions}
                assetType={tabContent.assetType}
                onClick={onClick}
                selected={assetSelected}
              />
            ))}
          />
        ) : null}
        {showAssetList() && hasAssets() && !hasMultipleAssetTypes() ? (
          <AssetsList
            assets={assetOptions}
            assetType={props.tabContents[0].assetType}
            onClick={onClick}
            selected={assetSelected}
          />
        ) : null}
        {!showAssetList() && assetSelected && (
          <AssetsList
            assets={[assetSelected]}
            assetType={props.tabContents[selectedTab].assetType}
            selected={assetSelected}
          />
        )}
        <ButtonRowWrap>
          <GridSpan span={2}>
            <Row justify="flex-end" childMargin="1rem">
              <Button variant="secondary" negative={true} onClick={e => props.handleClose(false)}>
                {t('common:buttons.cancel', 'Cancel')}
              </Button>
              <Button
                variant="secondary"
                onClick={e => props.handleAddSelected(assetSelected)}
                disabled={!assetSelected ? true : false}
              >
                {t('assets:labels.addSelectedFile', 'Add Selected File')}
              </Button>
            </Row>
          </GridSpan>
        </ButtonRowWrap>
      </Baseline>
    </Fragment>
  )
}

export default AddAsset
