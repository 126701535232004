import React, {ReactNode, useState, useEffect} from 'react'
import styled, {css} from 'styled-components'
import {Icon} from '../../atoms/Icon'

export interface DropdownProps {
  // The dropdown menu
  overlay: ReactNode
  children: ReactNode

  order?: number
  anchorMode?: keyof anchorModes
  // the distance between the dropdown modal and the dropdown menu from the top edge
  top?: string

  expandIconColor?: string
}

type anchorModes = {
  right: string
  left: string
}

interface ModalProps {
  anchorMode?: keyof anchorModes
  top?: string
}

const ModalContainer = styled.div<ModalProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  top: ${props => props.top || '2rem'};

  ${props =>
    props.anchorMode === 'right' &&
    css`
      right: 0;
    `}

  ${props =>
    props.anchorMode === 'left' &&
    css`
      left: 0;
    `}
`

const ModalWrapper = styled.div`
  max-width: 80vw;
  max-height: 80vh;
  background-color: white;
  box-shadow: 0 2px 6px 2px rgba(129, 129, 129, 0.5);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
`

const DropdownEntry = styled.div`
  cursor: pointer;
  display: flex;
  > svg {
    margin-left: 1rem;
  }
`

const DropdownContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
`

export const Dropdown = (props: DropdownProps) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // close modal if user clicks outside it
    function handleClick() {
      if (modalIsOpen === true) {
        setIsOpen(false)
      }
    }
    window.addEventListener('click', handleClick)

    return () => {
      return window.removeEventListener('click', handleClick)
    }
  }, [modalIsOpen])

  function handleMenuItemsClick(event: React.MouseEvent) {
    event.stopPropagation()
    setIsOpen(false)
  }

  const renderExpandIcon = () => {
    if (props.expandIconColor) {
      return modalIsOpen ? (
        <Icon type="arrowhead-up-xsmall" fill={props.expandIconColor} />
      ) : (
        <Icon type="arrowhead-down-xsmall" fill={props.expandIconColor} />
      )
    }
    return null
  }

  return (
    <DropdownContainer>
      <DropdownEntry onClick={() => setIsOpen(true)}>
        {props.children}
        {renderExpandIcon()}
      </DropdownEntry>
      {modalIsOpen && (
        <ModalContainer
          anchorMode={props.anchorMode}
          top={props.top}
          style={{zIndex: props.order ? 5000 - props.order : 5000}}
          onClick={handleMenuItemsClick}
        >
          <ModalWrapper>{props.overlay}</ModalWrapper>
        </ModalContainer>
      )}
    </DropdownContainer>
  )
}
