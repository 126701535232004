import {AsyncAction} from 'overmind'
import {
  GetSiteSuspendedPeriodsParams,
  DeleteSiteSuspendedPeriodParams,
  SuspendedPeriod,
  SuspendedPeriodPayload
} from './state'

export const getSiteSuspendedPeriods: AsyncAction<
  GetSiteSuspendedPeriodsParams,
  SuspendedPeriod[] | undefined
> = async ({state, effects}, params: GetSiteSuspendedPeriodsParams) => {
  try {
    if (params.showLoadingIndicator) {
      state.v1.settings.suspendedPeriods.loading = true
    }
    const res = await effects.v1.settings.suspendedPeriods.siteSuspendedPeriodsApi.getSiteSuspendedPeriods(
      params.siteId
    )
    state.v1.settings.suspendedPeriods.suspendedPeriods = res
    return res
  } catch (err) {
    state.v1.settings.suspendedPeriods.error = err as Error
  } finally {
    if (params.showLoadingIndicator) {
      state.v1.settings.suspendedPeriods.loading = false
    }
  }
}

const updatedSuspendedPeriods = (newPeriod: SuspendedPeriod, oldPeriods: SuspendedPeriod[]) => {
  const periods = [...oldPeriods]
  periods.unshift(newPeriod)
  return periods
}

export const newSiteSuspendedPeriod: AsyncAction<SuspendedPeriodPayload, SuspendedPeriod | undefined> = async (
  {state, effects},
  params: SuspendedPeriodPayload
) => {
  try {
    const res = await effects.v1.settings.suspendedPeriods.siteSuspendedPeriodsApi.newSiteSuspendedPeriod(params)
    state.v1.settings.suspendedPeriods.suspendedPeriods = state.v1.settings.suspendedPeriods.suspendedPeriods
      ? updatedSuspendedPeriods(res, state.v1.settings.suspendedPeriods.suspendedPeriods)
      : [res]
    return res
  } catch (err) {
    state.v1.settings.suspendedPeriods.error = err as Error
  }
}

export const deleteSiteSuspendedPeriod: AsyncAction<DeleteSiteSuspendedPeriodParams, void> = async (
  {state, effects},
  params: DeleteSiteSuspendedPeriodParams
) => {
  try {
    await effects.v1.settings.suspendedPeriods.siteSuspendedPeriodsApi.deleteSiteSuspendedPeriod(
      params.siteId,
      params.periodId
    )
    state.v1.settings.suspendedPeriods.suspendedPeriods = state.v1.settings.suspendedPeriods.suspendedPeriods
      ? state.v1.settings.suspendedPeriods.suspendedPeriods.filter(period => period.id !== params.periodId)
      : []
  } catch (error) {
    state.v1.settings.suspendedPeriods.error = error as Error
  }
}
