import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconFreezer = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2H46V46H2V2ZM3.25714 3.25714V44.7429H44.7429V3.25714H3.25714Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3716 25.261V7.65723H24.6287V25.261H23.3716Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6519 10.9057L26.5659 8.96216L27.2635 10.008L24.3494 11.9516L23.6519 10.9057Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6592 15.6527L28.7066 12.2408L29.4106 13.2824L24.3632 16.6942L23.6592 15.6527Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6516 11.9517L20.7363 10.0082L21.4337 8.96216L24.349 10.9057L23.6516 11.9517Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6373 16.6942L18.5898 13.2824L19.2939 12.2408L24.3413 15.6527L23.6373 16.6942Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.6287 22.6956V40.2993H23.3716V22.6956H24.6287Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.349 37.0508L21.4337 38.9943L20.7363 37.9483L23.6516 36.0048L24.349 37.0508Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3413 32.3034L19.2939 35.7152L18.5898 34.6737L23.6373 31.2618L24.3413 32.3034Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3494 36.0048L27.2635 37.9483L26.5659 38.9942L23.6519 37.0506L24.3494 36.0048Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3632 31.2618L29.4106 34.6737L28.7066 35.7152L23.6592 32.3034L24.3632 31.2618Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7964 25.1644L9.55103 16.3619L10.1796 15.2732L25.425 24.0757L24.7964 25.1644Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5038 17.7439L12.2788 14.2477L13.5334 14.167L13.7584 17.6631L12.5038 17.7439Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6197 20.1097L16.1885 14.0339L17.4425 13.9449L17.8737 20.0207L16.6197 20.1097Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4101 18.2672L10.2698 19.8198L9.71265 18.6928L12.853 17.1403L13.4101 18.2672Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5105 20.6503L12.0319 23.3154L11.4819 22.1849L16.9606 19.5198L17.5105 20.6503Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2038 22.792L38.4492 31.5945L37.8206 32.6832L22.5752 23.8807L23.2038 22.792Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4962 30.2122L35.7213 33.7083L34.4667 33.789L34.2417 30.2929L35.4962 30.2122Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3807 27.8453L31.8119 33.9224L30.5579 34.0113L30.1267 27.9343L31.3807 27.8453Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5898 29.6892L37.7302 28.1366L38.2873 29.2635L35.147 30.8161L34.5898 29.6892Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4893 27.3053L35.9679 24.6401L36.5178 25.7706L31.0392 28.4358L30.4893 27.3053Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.425 23.8807L10.1796 32.6832L9.55103 31.5945L24.7964 22.792L25.425 23.8807Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.853 30.8161L9.71265 29.2635L10.2698 28.1366L13.4101 29.6892L12.853 30.8161Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9606 28.4358L11.4819 25.7706L12.0319 24.6401L17.5105 27.3053L16.9606 28.4358Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7584 30.2929L13.5334 33.789L12.2788 33.7083L12.5038 30.2122L13.7584 30.2929Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8737 27.9343L17.4425 34.0113L16.1885 33.9224L16.6197 27.8453L17.8737 27.9343Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5752 24.0757L37.8206 15.2732L38.4492 16.3619L23.2038 25.1644L22.5752 24.0757Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.147 17.1403L38.2873 18.6928L37.7302 19.8198L34.5898 18.2672L35.147 17.1403Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0392 19.5198L36.5178 22.1849L35.9679 23.3154L30.4893 20.6503L31.0392 19.5198Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2417 17.6631L34.4667 14.167L35.7213 14.2477L35.4962 17.7439L34.2417 17.6631Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1267 20.0207L30.5579 13.9449L31.8119 14.0339L31.3807 20.1097L30.1267 20.0207Z"
      />
    </svg>
  )
}
