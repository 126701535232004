import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconInfoDisplay = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0006 12.3528C18.6494 12.3528 14.2941 16.7083 14.2941 22.0582C14.2941 27.4092 18.6493 31.7636 24.0006 31.7636C29.3507 31.7636 33.706 27.4093 33.706 22.0582C33.706 16.7082 29.3507 12.3528 24.0006 12.3528ZM13 22.0582C13 15.9934 17.9347 11.0587 24.0006 11.0587C30.0654 11.0587 35.0001 15.9934 35.0001 22.0582C35.0001 28.1241 30.0654 33.0577 24.0006 33.0577C17.9347 33.0577 13 28.1241 13 22.0582Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4556 18.1403H25.1734V16.3188H23.4556V18.1403ZM20.9238 25.4571H23.619V20.3629H21.22V19.2066H25.0259V25.4571H27.647V26.6122H20.9238V25.4571Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M44.7059 7.28H3.29412V36.72H44.7059V7.28ZM2 6V38H46V6H2Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34 42H14V41H34V42Z" />
    </svg>
  )
}
