import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconLate = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3.42018C12.6331 3.42018 3.42018 12.6345 3.42018 24C3.42018 35.3655 12.6331 44.5798 24 44.5798C35.3655 44.5798 44.5798 35.3655 44.5798 24C44.5798 12.6345 35.3655 3.42018 24 3.42018ZM2 24C2 11.8502 11.8487 2 24 2C36.1498 2 46 11.8502 46 24C46 36.1498 36.1498 46 24 46C11.8487 46 2 36.1498 2 24Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2896 9.67969H24.7097V24.4041L37.6754 33.3795L36.8671 34.5472L23.2896 25.1483V9.67969Z"
      />
    </svg>
  )
}
