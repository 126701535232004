import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconQuality = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3.21992C16.9405 3.21992 11.2174 8.95494 11.2174 16.0291C11.2174 23.1032 16.9405 28.8382 24 28.8382C31.0595 28.8382 36.7826 23.1032 36.7826 16.0291C36.7826 8.95494 31.0595 3.21992 24 3.21992ZM10 16.0291C10 8.2812 16.2682 2 24 2C31.7318 2 38 8.2812 38 16.0291C38 20.9844 35.436 25.3398 31.5649 27.8357L34.9418 43.5408L29.6863 40.9154L30.2295 39.8236L33.2182 41.3167L30.458 28.4796C29.8471 28.7984 29.2098 29.0735 28.5502 29.3005L26.4414 46L20.4715 39.8326L13.1559 44.3159L15.3017 27.3235L15.776 27.3836C12.2757 24.8334 10 20.6975 10 16.0291ZM16.4619 27.8529L14.6816 41.9509L20.6672 38.2827L25.5516 43.3287L27.2762 29.6719C26.2252 29.9244 25.1282 30.0582 24 30.0582C21.2247 30.0582 18.6379 29.2489 16.4619 27.8529Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8384 20.1247L31.5062 10.4357L32.3671 11.2982L21.8383 21.8501L16.3582 16.3572L17.2191 15.4947L21.8384 20.1247Z"
      />
    </svg>
  )
}
