import React, {useEffect, useState, useCallback} from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../state'

import {MainLayoutWithoutStretch, MainContent} from '../../Layout/Layout'
import {Row} from '../../Layout/Grid'
import {GoBackHeaderTitle, HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import {P, H3} from '../../Atoms/Typography'

import {groupBy} from 'lodash'
import {Equipment, equipmentApi} from '../../../state/rest'
import {getSites} from '../../../config/utils'
import {Site} from '../../../state/state'
import {Loader} from '../../../Routes'
import {A11yAccordion} from '../../Atoms/Accordion'
import {A11yListContainer, A11yIconListItem} from '../../Atoms/Lists'
import {EmptyEquipmentList} from '../Home/NoContent'
import {Helmet} from 'react-navi-helmet-async'
import {taskGroupIconPicker} from '../../../pages/tasks/utils'
import {SharedIcon} from '../../../sharedComponents/components'

interface SiteEquipment {
  site: Site
  equipments: Equipment[]
}

const EquipmentSettingsView = () => {
  const nav = useNavigation()
  const {state} = useAppState()
  const {t} = useTranslation(['appliances', 'settings'])
  const chain = state.chainsById[state.selectedChainId!]

  const [loading, setLoading] = useState(true)

  const handleClick = (id: string, siteId?: string) => {
    nav.navigate(`edit/appliance/${siteId}/${id}`)
  }

  const [siteEquipments, setSiteEquipments] = useState<SiteEquipment[]>([])
  const setup = useCallback(async () => {
    setLoading(true)
    const sites = getSites(chain)
    // Note: This call does not update global state with the response, only this view
    const items = (await equipmentApi.getEquipmentSettings()).items || []
    const equipments = []
    for (const site of sites) {
      const filtered = items.filter(eq => site.id === eq.site?.id)
      equipments.push({site, equipments: filtered})
    }
    setSiteEquipments(equipments)
    setLoading(false)
  }, [chain])

  useEffect(() => {
    setup()
  }, [setup])

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.applianceSettings', 'Appliance settings')} />
      <Loader show={loading} />
      <MainContent variant="white">
        <HeaderRow
          description={t(
            'appliances:messages.addNew',
            'Add new appliance to kitchen, and view and edit the details of existing appliances. If appliance is monitored with automatic sensor, you can edit sensor settings here.'
          )}
        >
          <GoBackHeaderTitle
            label={t('appliances:labels.applianceSettings', 'Appliance settings')}
            path={'/settings'}
            backLabel={t('settings:labels.settingsTitle', 'Settings')}
          />
          <HeaderActions
            onConfigure={() => nav.navigate('/appliance/types')}
            onAdd={() => nav.navigate('/create/appliance')}
          />
        </HeaderRow>

        <A11yListContainer>
          {siteEquipments.length ? (
            siteEquipments.map(se => {
              if (se.equipments.length > 0) {
                return (
                  <SiteEquipmentList
                    key={se.site.id}
                    site={se.site}
                    equipments={se.equipments}
                    onEquipmentSelect={handleClick}
                  />
                )
              } else {
                return null
              }
            })
          ) : (
            <EmptyEquipmentList></EmptyEquipmentList>
          )}
        </A11yListContainer>
      </MainContent>
      {/*
      <RightSidebar>
        <p>Test</p>
      </RightSidebar>
      */}
    </MainLayoutWithoutStretch>
  )
}

interface SELProps {
  site: Site
  equipments: Equipment[]
  onEquipmentSelect: (id: string, siteId?: string) => void
}

const SiteEquipmentList = ({site, equipments, onEquipmentSelect}: SELProps) => {
  const byGroupId = groupBy(equipments, eq => eq.equipmentType!.id)
  const {t} = useTranslation('appliances')
  return (
    <A11yAccordion title={site.name} storeId={site.name} openByDefault={false}>
      {Object.keys(byGroupId).map(groupid => (
        <A11yAccordion
          disableSelectedBorder
          storeId={`${site.name}:${groupid}`}
          offset={4}
          title={t(
            `appliances:equipmentTypes.${byGroupId[groupid][0].equipmentType?.id}`,
            byGroupId[groupid][0].equipmentType!.name!
          )}
          key={groupid}
          openByDefault={false}
        >
          {byGroupId[groupid].map(eq => (
            <A11yIconListItem
              offset={5}
              onClick={() => onEquipmentSelect(eq.id, eq.site?.id)}
              key={eq.id}
              icon={<SharedIcon icon={taskGroupIconPicker(byGroupId[groupid][0].equipmentType!.icon)} />}
              content={
                <Row justify="space-between">
                  <H3>{eq.name}</H3>
                  {eq.storageType && <P variant="small">{eq.storageType.name}</P>}
                </Row>
              }
            ></A11yIconListItem>
          ))}
        </A11yAccordion>
      ))}
    </A11yAccordion>
  )
}

export default EquipmentSettingsView
