import React, {ReactNode, useState} from 'react'
import {ReactNodeArray} from 'prop-types'
import {IconCollapse} from '../../Assets/Icons/TinyIcons'
import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import {ListItem, A11yListItem} from './Lists'
import DoubleComponent from '../Molecules/ListComponents/DoubleComponent'
import {Padding} from '../Layout/Layout'

export const AccordionItem = styled(ListItem)`
  width: 100%;
  min-height: 64px;
  border-bottom: 1px solid ${colors.system.lightGrey_5};
  background: ${colors.system.white};
`

const DefaultAccordionHeader = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  /* min-height: 70px; */
  align-items: center;
  .icon {
    display: flex;
    margin-right: 0.5rem;
    svg {
      margin: auto;
      min-height: 40px;
      min-width: 40px;
    }
  }
  &.open {
    &::before {
      content: '';
      height: 100%;
      width: 8px;
      background-color: ${colors.brand.cobalt};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
`

const AccordionToggler = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  justify-self: flex-end;
  margin-left: 1rem;
`

const accordionIsOpen = (tag: string | undefined, openByDefault: boolean = true) => {
  if (openByDefault) {
    return tag ? !getAccordionStates().includes(tag) : true
  }
  return tag ? getAccordionStates().includes(tag) : false
}

const toggleAccordionStateInLocalStorage = (tag: string) => {
  if (getAccordionStates().includes(tag)) {
    removeAccordionState(tag)
  } else {
    storeAccordionState(tag)
  }
}

const getAccordionStates = () => {
  const storage = window.localStorage.getItem('accordion_states')
  return storage ? (JSON.parse(storage) as string[]) : []
}

const removeAccordionState = (tag: string) => {
  const states = getAccordionStates().filter(t => t !== tag)
  window.localStorage.setItem('accordion_states', JSON.stringify(states))
}

const storeAccordionState = (tag: string) => {
  const states = [...getAccordionStates(), tag]
  window.localStorage.setItem('accordion_states', JSON.stringify(states))
}

interface AccordionProps {
  title: string
  description?: string
  icon?: ReactNode
  children: ReactNode | ReactNodeArray
  header?: ReactNode
  onClick?: () => void // custom click handler
  // if you want the accordion to save its state to localstorage, use this. Must be unique
  storeId?: string
  disableSelectedBorder?: boolean
  offset?: number
  openByDefault?: boolean
}
export const A11yAccordion = ({
  disableSelectedBorder,
  icon,
  description,
  children,
  header,
  storeId,
  title,
  offset,
  openByDefault = true
}: AccordionProps) => {
  const [isOpen, setOpen] = useState(accordionIsOpen(storeId, openByDefault))

  const handleToggle = () => {
    if (storeId) {
      toggleAccordionStateInLocalStorage(storeId)
    }
    setOpen(s => !s)
  }
  return (
    <>
      <A11yListItem offset={offset || 2} onClick={handleToggle} data-cy={'accordion-header'} data-cy-open={isOpen}>
        {header ? (
          <Padding py=".75rem">
            <DefaultAccordionHeader className={isOpen && !disableSelectedBorder ? 'open' : ''}>
              {header}
            </DefaultAccordionHeader>
          </Padding>
        ) : (
          <Padding py=".75rem">
            <DefaultAccordionHeader className={isOpen && !disableSelectedBorder ? 'open' : ''}>
              {!!icon && <div className="icon">{icon}</div>}
              <DoubleComponent description={description} title={title} />
            </DefaultAccordionHeader>
          </Padding>
        )}
        <AccordionToggler style={{transform: isOpen ? 'none' : 'rotate(180deg)'}}>
          <IconCollapse />
        </AccordionToggler>
      </A11yListItem>
      {isOpen && children}
    </>
  )
}
