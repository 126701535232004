import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconHandheldAtp = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.766 32.6853V14.8426C13.766 12.869 12.1042 11.2691 10.0543 11.2691C8.00435 11.2691 6.34256 12.869 6.34256 14.8426V32.6853C6.34256 34.6588 8.00435 36.2588 10.0543 36.2588C12.1042 36.2588 13.766 34.6588 13.766 32.6853ZM10.0543 9.97656C7.26288 9.97656 5 12.1552 5 14.8426V32.6853C5 35.3727 7.26288 37.5513 10.0543 37.5513C12.8457 37.5513 15.1085 35.3727 15.1085 32.6853V14.8426C15.1085 12.1552 12.8457 9.97656 10.0543 9.97656Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42209 46.0001L9.42209 36.8085L10.7646 36.8085L10.7646 46.0001L9.42209 46.0001Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7002 15.2882L27.2757 19.8154L31.978 22.295L27.2757 24.7746L24.7002 29.3019L22.1247 24.7746L17.4224 22.295L22.1247 19.8154L24.7002 15.2882ZM24.7002 17.979L23.1137 20.7677L20.2172 22.295L23.1137 23.8224L24.7002 26.6111L26.2866 23.8224L29.1832 22.295L26.2866 20.7677L24.7002 17.979Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7222 10.6332L38.2977 15.1621L43 17.6417L38.2977 20.1212L35.7222 24.6485L33.1467 20.1212L28.4443 17.6417L33.1466 15.1621L35.7222 10.6332ZM35.7222 13.3247L34.1358 16.1143L31.2392 17.6417L34.1357 19.169L35.7222 21.9577L37.3086 19.169L40.2052 17.6417L37.3086 16.1143L35.7222 13.3247Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8669 2L30.4438 6.52851L35.1448 9.00667L30.4438 11.4864L27.8669 16.0133L25.2916 11.4864L20.5889 9.00667L25.2916 6.52851L27.8669 2ZM27.8672 4.69083L26.2806 7.48083L23.3844 9.00704L26.2806 10.5342L27.8672 13.3232L29.4549 10.5342L32.3501 9.00704L29.4549 7.48083L27.8672 4.69083Z"
      />
    </svg>
  )
}
