import {orderBy, sortBy} from 'lodash'
import {AsyncAction} from 'overmind'
import i from 'i18next'
import {TaskListItemData} from './state'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../../state'
import {checkInProgressAutomaticCoolingTask, getTasksUntilNow} from '../../../pages/tasks/utils'

const sortByUnscheduledFirst = (tasks: TaskListItemData[]) =>
  // sort rule:
  // 1. unscheduled tasks appear first, scheduled tasks follow
  // 2. Within the unscheduled tasks, sort by createdAt time ascendingly
  // 3. Within the scheduled tasks, sort by scheduled time ascendingly
  orderBy(tasks, [t => t.scheduleInstance || '', 'name'], ['asc'])
const sortByScheduleNewestFirst = (tasks: TaskListItemData[]) => sortBy(tasks, t => t.scheduleInstance).reverse()
const sortByUpdatedAt = (tasks: TaskListItemData[]) => orderBy(tasks, t => t.updatedAt, ['desc'])

type GetHomePageForSiteParams = {
  siteId: string
  shouldUpdatePending: boolean
}
export const getHomePageForSite: AsyncAction<GetHomePageForSiteParams> = async (
  {state, effects},
  params: GetHomePageForSiteParams
) => {
  state.v1.home.site.pending = params.shouldUpdatePending
  state.v1.home.site.error = null

  try {
    const siteHome = await effects.v1.home.site.siteHomeApi.getSiteHome(params.siteId)
    state.v1.home.site.data = {
      ...siteHome,
      todaysTasks: sortByUnscheduledFirst(siteHome.todaysTasks),
      lateTasks: sortByScheduleNewestFirst(siteHome.lateTasks),
      completedTask: sortByUpdatedAt(siteHome.completedTask)
    }
  } catch (err) {
    state.v1.home.site.error = err as Error
  } finally {
    state.v1.home.site.pending = false
  }
}

export const getInitialHomeData: AsyncAction<any, void> = async (
  {state, effects, actions},
  params: {siteId: string; onlyRefreshData?: boolean}
) => {
  if (!params.onlyRefreshData) {
    state.v1.home.site.pending = true
  }
  state.v1.home.site.error = null
  try {
    const {tasks, activeAlarms} = await effects.v1.home.site.siteHomeApi.getInitialHomeData(params.siteId!)
    state.v1.home.site = {
      ...state.v1.home.site,
      tasks: {
        lateTasks: tasks.lateTasks,
        inProgressTasks: tasks.inProgressTasks,
        todaysTasks: getTasksUntilNow(tasks.todaysTasks),
        count: tasks.count
      },
      activeAlarms
    }
  } catch (err) {
    state.v1.home.site.error = err as Error
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('msg:genericApiError', 'Something is wrong'),
      description: i.t('msg:tryAgainLater', 'Please refresh your browser window and try later if needed.'),
      visible: true,
      hideAfterDelay: 6000
    })
  } finally {
    state.v1.home.site.pending = false
  }
}

export const updateHomeInProgressAutomaticCoolingTasks: AsyncAction<any, any> = async (
  {state, effects},
  params: {siteId: string}
) => {
  try {
    const inProgressTasks = state.v1.home.site.tasks.inProgressTasks
    const inProgressAutomaticCoolingTasksExist = inProgressTasks.filter(checkInProgressAutomaticCoolingTask).length > 0
    if (!inProgressAutomaticCoolingTasksExist) {
      return
    }
    const response = await effects.v1.tasks.tasksApi.getInProgressTasksForSite(params.siteId, 0, 50, [
      'automaticCooling'
    ])
    const inProgressAutomaticCoolingTasks: TaskListItemData[] = response.tasks
    const inProgressAutomaticCoolingTasksMap = Object.assign(
      {},
      ...inProgressAutomaticCoolingTasks.map(task => ({[task.eventId!]: task}))
    ) as {[eventId: string]: TaskListItemData}
    state.v1.home.site.tasks.inProgressTasks = inProgressTasks.map(task => {
      const inProgressAutomaticCoolingTask = inProgressAutomaticCoolingTasksMap[task.eventId!]
      return inProgressAutomaticCoolingTask ?? task
    })
  } catch (err) {}
}
