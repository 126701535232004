import React from 'react'
import {Title, P} from '../../Atoms/Typography'
import {LabelInput} from '../../Atoms/Forms'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InvisibleContainer, Baseline, Row} from '../../Layout/Grid'
import {Button, TextButton} from '../../Atoms/Buttons'
import {useAppState} from '../../../state'
import {useNavigation} from 'react-navi'
import {colors} from '../../../sharedComponents/colors'
import {LoginLogo, TitleWrapper} from './LoginComponents'
import {Helmet} from 'react-navi-helmet-async'

interface Form {
  email: string
}
const ForgotPassword = () => {
  const navi = useNavigation()
  // navi.getRoute().then(r => r.state)
  const fc = useForm<Form>({mode: 'onChange'})
  const {actions} = useAppState()
  const {t} = useTranslation('auth')

  const handleSubmit = () => {
    const {email} = fc.watch()
    actions
      .forgotPassword({email})
      .then(() => {
        navi.navigate('/login/forgot/confirm', {state: {email}})
      })
      .catch(e => {
        console.error('ForgotPassword handleSubmit error', e)
        if (e.code === 'LimitExceededException') {
          fc.setError('email', 'custom', 'Too many attempts, please try again later')
        } else {
          fc.setError('password', 'custom', t('common:general.unknownError', 'Unknown error'))
        }
      })
  }

  return (
    <InvisibleContainer>
      <Helmet title={t('common:routes.forgotPassword', 'Forgot password?')} />
      <LoginLogo />
      <TitleWrapper>
        <Title level={2} color={colors.system.white}>
          {t('auth:labels.forgotPassword', 'Forgot password?')}
        </Title>
      </TitleWrapper>
      <Baseline>
        <P color={colors.system.white}>
          {t(
            'auth:messages.loginInstructions',
            'Enter the email you use for CGI Chefstein, and we’ll send you instructions to reset your password.'
          )}
        </P>
        <LabelInput
          negative={true}
          autoComplete="username"
          type="email"
          labelText={t('auth:labels.emailAddress', 'Email address')}
          name="email"
          id="email"
          ref={fc.register({
            required: `${t('common:validation.emailRequired', 'Email address is mandatory')}`,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
              message: `${t('common:validation.emailInvalid', 'Invalid email address')}`
            }
          })}
          errorMsg={!!fc.errors.email ? fc.errors.email!.message : undefined}
        />
        <Row>
          <TextButton negative={true} onClick={() => navi.navigate('/login')}>
            {t('auth:labels.backToLogin', 'Back to login')}
          </TextButton>
        </Row>
        <Button disabled={!fc.formState.isValid} onClick={handleSubmit}>
          {t('auth:labels.requestPasswordReset', 'Request password reset')}
        </Button>
      </Baseline>
    </InvisibleContainer>
  )
}

export default ForgotPassword
