import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {Heading} from '../../Atoms/Typography'
import {IconRow} from '../../Layout/Grid'
import {UserToSite} from '../../../state/state'
import {useAppState} from '../../../state'
import {IconUsers} from '../../../Assets/Icons/LargeIcons'

const EditUserAccountSettingsUserGroups: FC<any> = ({userData}: any): any => {
  const {t} = useTranslation('users')
  const {state} = useAppState()
  const chains = state.chains ? state.chains : []
  const brands = chains.flatMap(c => c.brands)
  const groups = brands.flatMap(b => b.locationGroups)
  const locations = groups.flatMap(g => g.locations)
  const sites = locations.flatMap(l => l.sites)

  const renderRoleRow = (userToSite: UserToSite) => {
    let place: string = '?'
    let role: string = '?'
    switch (userToSite.userRole) {
      case 'CM':
        role = t('users:labels.chainManager', 'Chain manager')
        const chain = chains.find(c => c.id === userToSite.chainId)
        place = (chain && chain.name) || '?'
        break
      case 'BM':
        role = t('users:labels.brandManager', 'Brand manager')
        const brand = brands.find(b => b.id === userToSite.brandId)
        place = (brand && brand.name) || '?'
        break
      case 'GM':
        role = t('users:labels.groupManager', 'Group manager')
        const group = groups.find(s => s.id === userToSite.locationGroupId)
        place = (group && group.name) || '?'
        break
      case 'LM':
        role = t('users:labels.locationManager', 'Location manager')
        const location = locations.find(s => s.id === userToSite.locationId)
        place = (location && location.name) || '?'
        break
      case 'SM':
        role = t('users:labels.siteManager', 'Site manager')
        const site = sites.find(s => s.id === userToSite.siteId)
        place = (site && site.name) || '?'
        break
      case 'BU':
        role = t('users:labels.user', 'User')
        const zite = sites.find(s => s.id === userToSite.siteId)
        place = (zite && zite.name) || '?'
        break
      case 'HI':
        role = t('users:labels.healthInspector', 'Health Inspector')
        const Hsite = sites.find(s => s.id === userToSite.siteId)
        place = (Hsite && Hsite.name) || '?'
        break
    }
    return (
      <IconRow style={{marginTop: '.5rem'}}>
        <IconUsers /> {place} &ndash; {role}
      </IconRow>
    )
  }

  return (
    <div>
      <Heading level={6}>{t('users:labels.belongsToGroups', 'Account belongs to the following user groups')}</Heading>
      <div style={{marginTop: '1rem'}}>{userData.userToSite.map((e: UserToSite) => renderRoleRow(e))}</div>
    </div>
  )
}

export default EditUserAccountSettingsUserGroups
