import {useState, useCallback} from 'react'
import {useForm, useFieldArray} from 'react-hook-form'
import {useAppState} from '../../../state'
import {DishwasherType} from '../../../state/rest'
import {localTempValue} from '../../../config/utils'
import {Site} from '../../../state/state'
import {useTranslation} from 'react-i18next'

export function useDishwasherTypeList() {
  const {state, actions, effects} = useAppState()

  const valueExists = (value?: number): boolean => {
    return !(value === undefined || value === null)
  }

  const {watch, errors, register, control, formState} = useForm({
    defaultValues: {
      dishwasherTypes: state.dishwasherTypes.map((type: DishwasherType) => {
        console.debug('DishwasherTypeUtils.useDishwasherTypeList', {type})
        const data = {
          dishwasherTypeId: type.id,
          sequenceType: type.sequenceType,
          rinseMinValue: valueExists(type.rinseMinValue) ? localTempValue(type.rinseMinValue!, state.site!) : undefined,
          rinseMaxValue: valueExists(type.rinseMaxValue) ? localTempValue(type.rinseMaxValue!, state.site!) : undefined,
          washMinValue: valueExists(type.washMinValue) ? localTempValue(type.washMinValue!, state.site!) : undefined,
          washMaxValue: valueExists(type.washMaxValue) ? localTempValue(type.washMaxValue!, state.site!) : undefined,
          prewashMinValue: valueExists(type.prewashMinValue)
            ? localTempValue(type.prewashMinValue!, state.site!)
            : undefined,
          prewashMaxValue: valueExists(type.prewashMaxValue)
            ? localTempValue(type.prewashMaxValue!, state.site!)
            : undefined
        }
        return data
      })
    },
    mode: 'onChange'
  })
  const {fields} = useFieldArray({
    control,
    name: 'dishwasherTypes'
  })

  const dishwasherTypes = watch({nest: true})

  const saveDishwasherTypes = async () => {
    await Promise.all(
      dishwasherTypes.dishwasherTypes.map(type => {
        return updateDishwasherType(type)
      })
    )
    await actions.getDishwasherTypes()
  }

  const updateDishwasherType = (type: any) => {
    return effects.equipmentApi.updateDishwasherType({
      id: type.dishwasherTypeId,
      sequenceType: type.sequenceType,
      rinseMinValue: type.rinseMinValue,
      rinseMaxValue: type.rinseMaxValue,
      washMinValue: type.washMinValue,
      washMaxValue: type.washMaxValue,
      prewashMinValue: type.prewashMinValue,
      prewashMaxValue: type.prewashMaxValue
    })
  }

  return {
    register,
    fields,
    errors,
    formState,
    dishwasherTypes,
    saveDishwasherTypes
  }
}

function optionalLocalTempValue(selectedSite: Site, value?: number): number | undefined {
  if (value === undefined || value === null) return undefined
  return localTempValue(value, selectedSite)
}

export function useDishwasherTypeSelect(selectedSite: Site, saved?: Partial<DishwasherType>, defaultOption?: string) {
  const {state} = useAppState()
  const {t} = useTranslation('appliances')
  const dishwasherTypeOptions = state.dishwasherTypes.map((o: DishwasherType) => {
    const name = t(`appliances:labels.dishwasherSequenceType.${o.sequenceType}`, `${o.sequenceType}`)
    const ranges = []
    const rinseMinValue = optionalLocalTempValue(selectedSite, o.rinseMinValue)
    const rinseMaxValue = optionalLocalTempValue(selectedSite, o.rinseMaxValue)
    const washMinValue = optionalLocalTempValue(selectedSite, o.washMinValue)
    const washMaxValue = optionalLocalTempValue(selectedSite, o.washMaxValue)
    const prewashMinValue = optionalLocalTempValue(selectedSite, o.prewashMinValue)
    const prewashMaxValue = optionalLocalTempValue(selectedSite, o.prewashMaxValue)
    const unit = `°${selectedSite.temperatureUnit}`
    switch (o.sequenceType) {
      case 'RINSE':
        if (rinseMinValue) {
          ranges.push(`${rinseMinValue}–${rinseMaxValue}${unit}`)
        }
        break
      case 'WASH-RINSE':
        if (washMinValue && rinseMaxValue) {
          ranges.push(`${washMinValue}–${washMaxValue}${unit}`)
          ranges.push(`${rinseMinValue}–${rinseMaxValue}${unit}`)
        }
        break
      case 'PREWASH-WASH-RINSE':
        if (prewashMinValue && washMinValue && rinseMaxValue) {
          ranges.push(`${prewashMinValue}–${prewashMaxValue}${unit}`)
          ranges.push(`${washMinValue}–${washMaxValue}${unit}`)
          ranges.push(`${rinseMinValue}–${rinseMaxValue}${unit}`)
        }
        break
      default:
        console.error(`unsupported dishwasher sequency type: ${o.sequenceType}`)
    }
    const rangeInfo = ranges.length > 0 ? `(${ranges.join(', ')})` : ''
    return {
      option: `${name} ${rangeInfo}`,
      id: o.id
    }
  })
  const defaultOptionIndex = dishwasherTypeOptions.findIndex(tg => tg.id === defaultOption)
  const defaultIndex = defaultOptionIndex !== -1 ? defaultOptionIndex : 0
  const [selected, setSelected] = useState(!!saved ? saved : state.dishwasherTypes[defaultIndex])
  const selectMethod = useCallback(
    (id: string) => {
      const dishwasherType = state.dishwasherTypeById[id]
      console.debug('DishwasherType selectMethod', id, dishwasherType)
      setSelected(dishwasherType)
    },
    [state.dishwasherTypeById]
  )
  return {selectedDishwasherType: selected, selectDishwasherType: selectMethod, dishwasherTypeOptions}
}
