import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'
import {colors} from '../../../colors'

export type ButtonStyle = 'primary' | 'secondary'
export type ButtonSize = 'medium' | 'small'

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  buttonStyle?: ButtonStyle
  buttonSize?: ButtonSize
}

const buttonStyle = css<ButtonProps>(
  ({buttonStyle, buttonSize}) => `
  font-family: FavoritMonoStd-Regular;
  font-size: ${buttonSize === 'medium' ? '1.0625rem' : '0.875rem'};
  line-height: ${buttonSize === 'medium' ? '1.375rem' : '1.1875rem'};

  // remove the default outline from a button
  outline: none;
  border: 0;

  text-align: center;

  padding: ${buttonSize === 'medium' ? '0.75rem 2rem' : '0.5rem 1.5rem'};
  border-radius: 40px;
  border-width: 1px;
  border-style: solid;

  background-color: ${buttonStyle === 'primary' ? colors.brand.cobalt : 'transparent'};
  color: ${buttonStyle === 'primary' ? colors.system.white : colors.brand.cobalt};
  border-color: ${buttonStyle === 'primary' ? 'transparent' : colors.brand.cobalt};

  :hover {
    background-color: ${buttonStyle === 'primary' ? colors.system.white : 'transparent'};
    color: ${buttonStyle === 'primary' ? colors.brand.cobalt : colors.brand.cobalt};
    border-color: ${buttonStyle === 'primary' ? 'transparent' : colors.system.grey_4};
  }

  :focus {
    background-color: ${buttonStyle === 'primary' ? colors.system.white : 'transparent'};
    color: ${buttonStyle === 'primary' ? colors.brand.cobalt : colors.brand.cobalt};
    border-color: transparent;

    box-shadow: 0px 0px 0px 2px ${colors.system.blue};
    outline-style: none;
  }

  :disabled {
    cursor: not-allowed;

    background-color: ${buttonStyle === 'primary' ? colors.brand.cobalt_20 : 'transparent'};
    color: ${
      buttonStyle === 'primary'
        ? colors.system.lightGrey_5
        : tinycolor(colors.brand.cobalt)
            .setAlpha(0.2)
            .toString()
    };
    border-color: ${
      buttonStyle === 'primary'
        ? 'transparent'
        : tinycolor(colors.brand.cobalt)
            .setAlpha(0.2)
            .toString()
    };
  }
`
)

export const Button = styled.button<ButtonProps>`
  ${buttonStyle}
`

Button.defaultProps = {
  buttonStyle: 'primary',
  buttonSize: 'medium'
}

Button.propTypes = {
  buttonStyle: PropTypes.oneOf<ButtonStyle>(['primary', 'secondary']),
  buttonSize: PropTypes.oneOf<ButtonSize>(['medium', 'small'])
}

export const SubmitButton = styled.input.attrs<ButtonProps>({type: 'submit'})`
  ${buttonStyle} :hover {
    cursor: pointer;
  }
`

SubmitButton.defaultProps = {
  buttonStyle: 'primary',
  buttonSize: 'medium'
}

SubmitButton.propTypes = {
  buttonStyle: PropTypes.oneOf<ButtonStyle>(['primary', 'secondary']),
  buttonSize: PropTypes.oneOf<ButtonSize>(['medium', 'small'])
}
