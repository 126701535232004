import React from 'react'
import styled, {css} from 'styled-components'

const getHeadingStyles = (props: TitleProps) => css`
  color: ${props.color};
  text-transform: ${props.textTransform};
  white-space: ${props.lineBreak ? 'pre-line' : 'normal'};
  font-style: ${props.italic ? 'italic' : 'normal'};
  font-family: ${props.strong ? 'FavoritStd-Bold, sans-serif' : 'FavoritStd-Light, sans-serif'};
  font-weight: 300;
  letter-spacing: 0.35px;
`

const H1 = styled.h1<TitleProps>`
  ${getHeadingStyles}
  font-size: 2.5rem; /*40px;*/
  line-height: 3rem; /*48px;*/
`

const H2 = styled.h2<TitleProps>`
  ${getHeadingStyles}
  font-size: 2.125rem; /*34px;*/
  line-height: 2.5625rem; /*41px;*/
`

const H3 = styled.h3<TitleProps>`
  ${getHeadingStyles}
  font-size: 1.75rem; /*28px;*/
  line-height: 2.125rem; /*34px;*/
`

const H4 = styled.h4<TitleProps>`
  ${getHeadingStyles}
  font-size: 1.375rem; /*22px;*/
  line-height: 1.75rem; /*28px;*/
`

const H5 = styled.h5<TitleProps>`
  ${getHeadingStyles}
  font-size: 1.25rem; /*20px;*/
  line-height: 1.5rem; /*24px;*/
`

const H6 = styled.h6<TitleProps>`
  ${getHeadingStyles}
  font-size: 1.0625rem; /*17px;*/
  line-height: 1.375rem; /*22px;*/
`

const H7 = styled.h6<TitleProps>`
  ${getHeadingStyles}
  font-size: 0.75rem; /*12px;*/
  line-height: 1rem; /*16px;*/
`

const headings = [H1, H2, H3, H4, H5, H6, H7]

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  color?: string
  disabled?: boolean
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize'
  level: number
  lineBreak?: boolean

  strong?: boolean
  italic?: boolean
  'data-cy'?: string // attribute to the element gives us a targeted selector that's only used for testing
  as?: keyof JSX.IntrinsicElements
}

const DEFAULT_HEADING_LEVEL = 1

export const Title: React.FC<TitleProps> = props => {
  const titleLevel = props.level > 0 && props.level <= headings.length ? props.level : DEFAULT_HEADING_LEVEL
  const Heading = headings[titleLevel - 1]
  // @ts-ignore
  return <Heading {...props} />
}
