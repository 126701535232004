import {useAppState} from '../../../state'
import {Message} from '../../../state/messages/state'
import {useNavigation} from 'react-navi'
import {useForm} from 'react-hook-form'
import {useEffect, useState} from 'react'

export const useMessageSettingsForm = (saved?: Partial<Message>) => {
  const {actions} = useAppState()
  const {navigate} = useNavigation()

  const backPath = '/settings/messages'

  async function goBack() {
    navigate(backPath)
  }

  const {register, watch, errors, triggerValidation, formState, setValue, getValues, reset} = useForm({
    defaultValues: saved,
    mode: 'onChange'
  })

  const [loading, setLoading] = useState(false)
  const form = watch()

  useEffect(() => {
    if (saved) {
      reset(saved)
    }
  }, [saved, reset])

  const update = async () => {
    try {
      setLoading(true)
      await actions.v1.messages.updateMessage({
        id: saved!.id!,
        heading: form.heading as string,
        content: form.content as string,
        startDate: getValues().startDate as string,
        endDate: getValues().endDate as string,
        sites: getValues().sites as string[]
      })
      goBack()
    } catch (err) {
      console.error(`Error updating message, reason: ${(err as Error).message}`)
    } finally {
      setLoading(false)
    }
  }

  const submit = async () => {
    try {
      setLoading(true)

      await actions.v1.messages.createMessage({
        heading: form.heading as string,
        content: form.content as string,
        startDate: form.startDate as string,
        endDate: form.endDate as string,
        sites: form.sites as string[]
      })
      goBack()
    } catch (err) {
      console.error(`Error creating message, reason: ${(err as Error).message}`)
    } finally {
      setLoading(false)
    }
  }

  const cancel = () => {
    goBack()
  }

  const remove = async () => {
    try {
      setLoading(true)
      await actions.v1.messages.deleteMessage(saved!.id!)
      goBack()
    } catch (err) {
      console.error(`Error creating message, reason: ${(err as Error).message}`)
    } finally {
      setLoading(false)
    }
  }

  return {
    register,
    update,
    submit,
    remove,
    cancel,
    form,
    errors,
    triggerValidation,
    hasErrors: !formState.isValid,
    formState,
    loading,
    backPath,
    setValue
  }
}
