import React from 'react'
import TodaysTasksInfo from '../../Organisms/Info/TodaysTasksInfo'
import OpenAlarmsInfo from '../../Organisms/Info/OpenAlarmsInfo'
import {useAppState} from '../../../state'
import {useNavigation} from 'react-navi'
import styled from 'styled-components'
import {getTasksUntilNow} from '../../../pages/tasks/utils'

const StatusWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StatusInfoList = styled.div<QuickStatusProps>`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  justify-content: space-evenly;
  align-items: flex-start;
  grid-template-columns: repeat(2, auto);
  margin: 0 0.5rem;

  > * {
    /*transform: scale3d(${props => props.scale}, ${props => props.scale}, 1);*/
    -webkit-transform: scale(${props => props.scale}, ${props => props.scale});
    transform: scale(${props => props.scale}, ${props => props.scale});
  }
`

interface QuickStatusProps {
  scale?: number
}

const QuickStatus = ({scale = 1}: QuickStatusProps) => {
  const {state} = useAppState()
  const navi = useNavigation()
  const todaysTasks = getTasksUntilNow(state.v1.home.site.data.todaysTasks)
  const todaysDoneTasksCount = state.v1.home.site.data.completedTodayCount

  const getTotalTasks = () => {
    return todaysTasks.length + todaysDoneTasksCount
  }

  const getTotalAlarms = () => {
    return state.v1.home.site.activeAlarms.count
  }

  return (
    <StatusWrapper>
      <StatusInfoList scale={scale}>
        <OpenAlarmsInfo alarms={getTotalAlarms()} onClick={() => navi.navigate('/alarms')} />
        <TodaysTasksInfo total={getTotalTasks()} completed={todaysDoneTasksCount} />
      </StatusInfoList>
    </StatusWrapper>
  )
}

export default QuickStatus
