import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconWasteLevel = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path d="M45 8.67129H39.5284H31.4788C31.0101 4.91517 27.8357 2 24.0023 2C20.1666 2 16.9946 4.91517 16.5259 8.67129H7.76155H3V10.1935H7.76155V42.7419C7.76155 44.5389 9.20486 46 10.98 46H36.3076C38.0828 46 39.5261 44.5389 39.5261 42.7419V10.1935H44.9977V8.67129H45ZM24 3.52218C27.005 3.52218 29.5041 5.75613 29.9612 8.67129H18.0388C18.4959 5.75848 20.995 3.52218 24 3.52218ZM36.3099 44.4802H10.98C10.0333 44.4802 9.26519 43.7003 9.26519 42.7442V29.0986C10.7015 28.3516 12.8248 28.4221 14.1265 29.3147C15.0965 29.9818 16.3402 30.3131 17.584 30.3131C18.8277 30.3131 20.0738 29.9795 21.0414 29.3147L21.2549 29.1667C22.6889 28.3446 24.9073 28.3939 26.2485 29.3147C28.1884 30.6466 31.2259 30.6466 33.1657 29.3147C34.4675 28.4221 36.5884 28.3493 38.0248 29.0963V42.7442C38.0248 43.7003 37.2544 44.4802 36.3099 44.4802ZM38.0248 27.4378C36.18 26.7707 33.892 26.9751 32.3234 28.0533C30.9056 29.0258 28.5109 29.0258 27.0931 28.0533C25.1533 26.7214 22.1158 26.7214 20.1759 28.0533L19.9624 28.2013C18.5261 29.0234 16.3101 28.9741 14.9688 28.0533C13.3979 26.9751 11.1076 26.7707 9.26287 27.4378V10.1935H38.0224V27.4378H38.0248Z" />
    </svg>
  )
}
