import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {H3, Caption} from '../../Atoms/Typography'

const DoubleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > *:nth-child(2) {
    display: block;
    margin-top: 0.5rem;
  }
`
interface DoubleComponentProps {
  title: string
  description?: string | ReactNode
  onClick?: () => void
}
const DoubleComponent = ({title, description, onClick}: DoubleComponentProps) => {
  return (
    <DoubleContainer onClick={onClick ? onClick : undefined}>
      <H3 className="title">{title}</H3>
      {description ? <Caption className="description">{description}</Caption> : null}
    </DoubleContainer>
  )
}

export default DoubleComponent
