import React, {useState} from 'react'
import {SortableTableHeading, TableHeading} from '../../atoms/TableHeadings'
import {Icon} from '../../atoms/Icon'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'
import camelCase from 'lodash/camelCase'

export interface ColumnComponentProps {
  values: any[]
  selected: boolean
}

export interface ColumnDef {
  title: string
  dataKeys: string[]
  component: React.FunctionComponent<ColumnComponentProps>
  widthPercentage?: string
  sortFn?: SortFn
}

export interface Expandable {
  // Expanded container render for each row
  expandedRowRender: (rowData: any) => React.ReactNode
  lazyLoading?: boolean
}

export type TableData = {
  [key: string]: any
}

export type SortOrder = 'ascending' | 'descending'

export type SortFn = (data: TableData[], sortKey: string, order: SortOrder) => TableData[]

export interface TableProps {
  data?: TableData[] // data can be empty if only table header is needed
  columnDefs: ColumnDef[]
  expandable?: Expandable
  lastRow?: React.ReactNode
  hideHeader?: boolean // Whether to show table header
  onClickRow?: (rowData: any) => void
  rowHoverBackgroundColor?: string
  rowExpandedBackgroundColor?: string
}

interface RowProps {
  expanded: boolean
  expandable?: boolean
  isClickable?: boolean
  hoverBackgroundColor?: string
  expandedBackgroundColor?: string
}

const Row = styled.tr<RowProps>(
  ({expanded, expandable, isClickable, hoverBackgroundColor, expandedBackgroundColor}) => css`
    height: 3rem;
    border-top: 1px solid ${colors.system.grey_5};
    border-bottom: 1px solid ${colors.system.grey_5};
    > td {
      padding: 0.75rem 1rem 0.75rem 0;
      overflow-wrap: break-word;
    }
    ${expanded && expandedBackgroundColor && `background-color: ${expandedBackgroundColor};`}
    ${(expandable || isClickable) && 'cursor: pointer;'}

    ${hoverBackgroundColor &&
      `
    :hover {
      background-color: ${hoverBackgroundColor};
    }
    `}
  `
)

interface ExpandableRowProps {
  visible: boolean
  expandedBackgroundColor?: string
}

const ExpandableRow = styled.tr<ExpandableRowProps>(
  ({visible, expandedBackgroundColor}) => css`
    border-top: 1px solid ${colors.system.grey_5};
    border-bottom: 1px solid ${colors.system.grey_5};
    display: ${visible ? 'contents' : 'none'};
    ${expandedBackgroundColor &&
      `
    > td {
      background-color: ${expandedBackgroundColor};
    }
    `}
  `
)

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
`

const renderColGroup = (props: TableProps) => (
  <colgroup>
    {props.columnDefs.map((def, index) => (
      <Column key={index} widthPercentage={def.widthPercentage} />
    ))}
    {props.expandable && <Column key="expandable" widthPercentage="5%" />}
  </colgroup>
)

interface Sort {
  key: string
  order: SortOrder
  func: SortFn
}

export const Table: React.FC<TableProps> = ({
  data,
  columnDefs,
  expandable,
  lastRow,
  hideHeader,
  onClickRow,
  rowHoverBackgroundColor,
  rowExpandedBackgroundColor,
  ...rest
}) => {
  const [sort, setSort] = useState<Sort | null>(null)
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  let sortedData = data
  if (sortedData && sort) {
    sortedData = sort.func(sortedData, sort.key, sort.order)
  }

  const handleClickRow = (index: number, item: TableData) => {
    if (onClickRow) {
      onClickRow({...item, index})
    }

    if (expandedRow === index) {
      setExpandedRow(null)
    } else {
      setExpandedRow(index)
    }
  }

  return (
    <TableContainer {...rest}>
      {!hideHeader && (
        <TableHeader className="tableHeader">
          <StyledTable>
            {renderColGroup({data, columnDefs, expandable, lastRow})}
            <thead>
              <tr>
                {columnDefs.map((def, index) => {
                  const sortFn = def.sortFn
                  if (sortFn) {
                    return (
                      <SortableTableHeading
                        key={index}
                        onClick={() => {
                          setSort({
                            func: sortFn,
                            key: def.dataKeys[0],
                            order: sort?.order === 'ascending' ? 'descending' : 'ascending'
                          })
                        }}
                      >
                        {def.title}
                      </SortableTableHeading>
                    )
                  } else {
                    return <TableHeading key={index}>{def.title}</TableHeading>
                  }
                })}
              </tr>
            </thead>
          </StyledTable>
        </TableHeader>
      )}
      {sortedData && (
        <TableBody className="tableBody" id="tableBody">
          <StyledTable>
            {renderColGroup({data, columnDefs, expandable, lastRow})}
            <tbody>
              {sortedData.map((item, rowIndex) => {
                const expandedRowContent = expandable?.expandedRowRender(sortedData?.[rowIndex])
                const hasExpandedRowContent = Boolean(expandedRowContent)
                const expanded = expandedRow === rowIndex
                const shouldRenderExpandedRow = expandable?.lazyLoading === true ? expanded : true

                return (
                  <React.Fragment key={rowIndex}>
                    <Row
                      onClick={() => handleClickRow(rowIndex, item)}
                      isClickable={!!onClickRow || hasExpandedRowContent}
                      expandable={hasExpandedRowContent}
                      hoverBackgroundColor={rowHoverBackgroundColor}
                      expandedBackgroundColor={rowExpandedBackgroundColor}
                      expanded={expanded}
                    >
                      {columnDefs.map((def, columnIndex) => {
                        const values = def.dataKeys.map(dataKey => item[dataKey])
                        return (
                          <td key={`${columnIndex}-${def.dataKeys}`} data-cy={`table-row-${camelCase(def.title)}`}>
                            <def.component key={`${def.dataKeys}-${def.title}`} values={values} selected={expanded} />
                          </td>
                        )
                      })}
                      {expandable && (
                        <td>
                          {hasExpandedRowContent && (
                            <Icon
                              type={expanded ? 'arrowhead-up-xsmall' : 'arrowhead-down-xsmall'}
                              height="24px"
                              width="24px"
                            />
                          )}
                        </td>
                      )}
                    </Row>
                    {hasExpandedRowContent && shouldRenderExpandedRow && (
                      <ExpandableRow
                        visible={expanded}
                        expandedBackgroundColor={rowExpandedBackgroundColor}
                        data-cy="table-expanded-row"
                      >
                        <td colSpan={columnDefs.length + 1}>{expandedRowContent}</td>
                      </ExpandableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </StyledTable>
          {lastRow && <LastRow>{lastRow}</LastRow>}
        </TableBody>
      )}
    </TableContainer>
  )
}

const TableContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template: auto 1fr / auto;
`

const TableHeader = styled.div``

const TableBody = styled.div`
  height: 100%;
  overflow-y: auto;
`

const Column = styled.col<Pick<ColumnDef, 'widthPercentage'>>`
  width: ${props => props.widthPercentage ?? 'auto'};
`

const LastRow = styled.div`
  text-align: center;
  min-height: 2rem;
`
