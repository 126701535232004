import React, {useState, ButtonHTMLAttributes} from 'react'
import {useTranslation} from 'react-i18next'
import {GridSpan, Baseline} from '../../Layout/Grid'
import {H1} from '../../Atoms/Typography'
import {IconButton, Button} from '../../Atoms/Buttons'
import CenteredModal, {SmallModalContent} from '../../Molecules/Modals/Modals'
import {colors} from '../../../sharedComponents/colors'
import styled from 'styled-components'
import {alarmMapper, taskGroupIconPicker} from '../../../pages/tasks/utils'
import {SharedIcon} from '../../../sharedComponents/components'

const IconList = styled.div`
  display: flex;
  flex-wrap: wrap;
  > svg {
    margin: 0.5rem;
  }
`

interface TGProps {
  iconKey?: string
  onChange?: (iconKey: string) => void
  disabled?: boolean
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>
}

export default function IconSelector({iconKey, onChange, disabled, buttonProps}: TGProps) {
  const [isOpen, setOpen] = useState(false)
  const {t} = useTranslation()
  const [selectedIcon, setSelectedIcon] = useState(iconKey ? iconKey : Object.keys(alarmMapper)[0])
  const handleIconSelect = (key: string) => {
    setSelectedIcon(key)
    toggleModal()
    if (onChange) {
      onChange(key)
    }
  }
  const toggleModal = () => setOpen(!isOpen)
  return (
    <>
      <>
        <GridSpan span={2}>
          <IconButton
            buttonProps={{
              ...buttonProps,
              disabled: disabled,
              onClick: toggleModal
            }}
            icon={
              <SharedIcon
                icon={taskGroupIconPicker(selectedIcon)}
                fill={disabled ? colors.system.grey_1 : colors.brand.cobalt}
              />
            }
            description={t('common:actions.changeIcon', 'Change icon')}
          ></IconButton>
        </GridSpan>
      </>
      <CenteredModal isOpen={isOpen}>
        <SmallModalContent data-cy="taskgroup-icon-selection">
          <Baseline>
            <H1>{t('common:actions.chooseIcon', 'Choose icon')}</H1>
            <div data-cy="task-group-icons">
              <IconList>
                {Object.keys(alarmMapper).map(key => (
                  <IconButton
                    key={key}
                    buttonProps={{onClick: () => handleIconSelect(key)}}
                    icon={<SharedIcon icon={taskGroupIconPicker(key)} fill={colors.brand.cobalt} />}
                  />
                ))}
              </IconList>
            </div>
            <Button variant="secondary" negative onClick={toggleModal}>
              {t('common:buttons.cancel', 'Cancel')}
            </Button>
          </Baseline>
        </SmallModalContent>
      </CenteredModal>
    </>
  )
}
