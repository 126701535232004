import {LabelCheckbox} from '../../Components/Atoms/Forms'
import {P} from '../../Components/Atoms/Typography'
import {Baseline} from '../../Components/Layout/Grid'
import {AdjustableContainer, NarrowRightSidebar} from '../../Components/Layout/Layout'
import {OrgState} from '../../Components/Views/Users/EditUserAccountSettingsDetails'
import {AnyOrgLevel, availableKeyValues, OffsetCheckboxList} from '../../Components/Views/Utils'
import React, {forwardRef, Ref, useState} from 'react'

interface ManagerLevelCheckboxProps {
  isChainWide: boolean
  setIsChainWide: (isChainWide: boolean) => void
  onClick: (data: string, isChainToggled: boolean) => void
  handleLocationClick: (siteIds: string[], locId: string) => void
  data: AnyOrgLevel
  selectedLocations: string[]
  selectedSites: OrgState[]
  disableNextLevel?: boolean
  writableIds: string[]
  isAllowedToEditSite: boolean
}

const isChecked = (selectedSites: string[], isChainWide: boolean, selectedLocations: string[], dataId: string) => {
  if (isChainWide || selectedSites.includes(dataId) || selectedLocations.includes(dataId)) return true
  return false
}

const ManagerLevelCheckbox = ({
  data,
  isChainWide,
  setIsChainWide,
  onClick,
  handleLocationClick,
  selectedLocations,
  selectedSites,
  disableNextLevel,
  writableIds,
  isAllowedToEditSite
}: ManagerLevelCheckboxProps) => {
  const keys = Object.keys(data) as Array<keyof AnyOrgLevel>
  const arr = keys.find(k => availableKeyValues.includes(k)) as 'locationGroups' | 'locations' | 'brands' | 'sites'
  const checked = isChecked(
    selectedSites.map(s => s.id),
    isChainWide,
    selectedLocations,
    data.id
  )

  const showCheckbox = writableIds.includes(data.id) || data.sites
  if (data.visible !== false) {
    return (
      <>
        {showCheckbox ? (
          <LabelCheckbox
            disabled={isAllowedToEditSite ? disableNextLevel : true}
            checked={checked}
            readOnly
            flipped={false}
            label={data.name}
            name={arr}
            onClick={() => {
              if (data.brands) {
                return onClick(data.id, true)
              } else if (data.sites) {
                const siteIds = data.sites.map(site => site.id)
                return handleLocationClick(siteIds, data.id)
              } else {
                return onClick(data.id, false)
              }
            }}
          />
        ) : (
          <P>{data.name}</P>
        )}
        {!!arr && (
          <OffsetCheckboxList>
            {data[arr]!.map(item => (
              <li key={item.id}>
                <ManagerLevelCheckbox
                  writableIds={writableIds}
                  selectedLocations={selectedLocations}
                  selectedSites={selectedSites}
                  isChainWide={isChainWide}
                  setIsChainWide={setIsChainWide}
                  data={item}
                  onClick={onClick}
                  handleLocationClick={handleLocationClick}
                  disableNextLevel={checked}
                  isAllowedToEditSite={isAllowedToEditSite}
                />
              </li>
            ))}
          </OffsetCheckboxList>
        )}
      </>
    )
  } else {
    const items = data[arr]!
    return (
      <>
        {items.map(item => (
          <ManagerLevelCheckbox
            key={item.id}
            writableIds={writableIds}
            selectedLocations={selectedLocations}
            selectedSites={selectedSites}
            isChainWide={isChainWide}
            setIsChainWide={setIsChainWide}
            data={item}
            onClick={onClick}
            handleLocationClick={handleLocationClick}
            isAllowedToEditSite={isAllowedToEditSite}
          />
        ))}
      </>
    )
  }
}

interface SiteSelectionProps {
  infoText: string
  writableIds: string[]
  chain: AnyOrgLevel
  isChainWide: boolean
  setIsChainWide: any
  isLoading: boolean
  selectedSites: string[]
  setSelectedSites: any
  t: any
}

const SiteSelectionCheckboxStyle = ({
  infoText,
  writableIds,
  chain,
  isLoading,
  selectedSites,
  isChainWide,
  setSelectedSites,
  setIsChainWide,
  t
}: SiteSelectionProps) => {
  const [selectedLocations, setSelectedLocations] = useState([] as string[])

  const handleManagementLevelClick = (id: string, isChainToggled: boolean) => {
    if (isChainToggled && isChainWide) {
      const updatedSelectedSites: string[] = []
      const updatedLocations: string[] = []
      setSelectedSites(updatedSelectedSites)
      setSelectedLocations(updatedLocations)
    } else if (isChainToggled && !isChainWide) {
      const updatedSelectedSites: string[] = writableIds
      const updatedLocations: string[] = []
      setSelectedSites(updatedSelectedSites)
      setSelectedLocations(updatedLocations)
    } else {
      const updatedSelectedSites = selectedSites.includes(id)
        ? selectedSites.filter(siteId => siteId !== id)
        : [...selectedSites, id]
      setSelectedSites(updatedSelectedSites)
    }
    if (isChainToggled) {
      setIsChainWide(!isChainWide)
    }
  }

  const handleLocationClick = (siteIds: string[], locId: string) => {
    const updatedLocations = selectedLocations.includes(locId)
      ? selectedLocations.filter(locationId => locationId !== locId)
      : [...selectedLocations, locId]
    setSelectedLocations(updatedLocations)

    if (updatedLocations.includes(locId)) {
      const updatedSelectedSites = Array.from(new Set([...selectedSites, ...siteIds]))
      setSelectedSites(updatedSelectedSites)
    } else {
      const updatedSelectedSites = selectedSites.filter(siteId => !siteIds.includes(siteId))
      setSelectedSites(updatedSelectedSites)
    }
  }

  return (
    <>
      {!isLoading && (
        <NarrowRightSidebar>
          <AdjustableContainer padding="2rem">
            <Baseline>
              <P variant="small">{infoText}</P>
            </Baseline>
            <ManagerLevelCheckbox
              writableIds={writableIds}
              data={chain}
              isChainWide={isChainWide}
              setIsChainWide={setIsChainWide}
              onClick={handleManagementLevelClick}
              handleLocationClick={handleLocationClick}
              selectedLocations={selectedLocations}
              selectedSites={selectedSites.map(id => ({level: 'sites', id}))}
              isAllowedToEditSite={true}
            />
          </AdjustableContainer>
        </NarrowRightSidebar>
      )}
    </>
  )
}

export default SiteSelectionCheckboxStyle
