import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconMaintenance = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.35048 8.54207C3.86977 4.71517 7.6138 2 12.0047 2C17.7393 2 22.3883 6.62655 22.3883 12.3303C22.3883 13.3705 22.2272 14.3688 21.9395 15.3113L44.6969 38.3115C44.6974 38.3121 44.6979 38.3126 44.6985 38.3132C46.4519 40.0593 46.4269 42.9178 44.6644 44.6713C42.9014 46.4253 40.0269 46.4496 38.2719 44.7035L38.2683 44.6999L15.7663 21.9568C14.6002 22.4092 13.3331 22.6606 12.0047 22.6606C7.6139 22.6606 3.86977 19.9468 2.35045 16.1184L2.00013 15.2357H11.6802V9.42489H2L2.35048 8.54207ZM3.96464 8.13361H12.9781V16.527H3.96449C5.48471 19.4066 8.51276 21.3693 12.0047 21.3693C13.3067 21.3693 14.5405 21.0935 15.6603 20.6028L16.0702 20.4232L39.1897 43.7905C39.1902 43.791 39.1908 43.7916 39.1913 43.7921C40.4295 45.0221 42.4808 45.0175 43.7466 43.7582C45.013 42.4983 45.0171 40.4563 43.7791 39.2246L43.7754 39.221L20.4558 15.6525L20.5873 15.2741C20.9092 14.3471 21.0903 13.3607 21.0903 12.3303C21.0903 7.3396 17.0223 3.29129 12.0047 3.29129C8.51298 3.29129 5.48496 5.25484 3.96464 8.13361Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.874 40.7931C41.6196 40.54 41.2094 40.5409 40.9571 40.7925L40.9565 40.7931C40.7025 41.0459 40.7025 41.4532 40.9565 41.7059L40.9571 41.7065C41.2094 41.9581 41.6196 41.959 41.874 41.7059C42.1281 41.4532 42.1281 41.0459 41.874 40.7931ZM40.0384 39.8803C40.7986 39.1225 42.0313 39.1234 42.7918 39.88C43.5527 40.6371 43.5527 41.862 42.7918 42.619C42.0312 43.3757 40.7982 43.3765 40.0381 42.6184L40.4976 42.1624L40.0387 42.619C39.2779 41.8621 39.2778 40.6374 40.0384 39.8803Z"
      />
    </svg>
  )
}
