import {baseApi} from '../rest'
import {UnscheduledTaskGroup} from './state'

export const api = {
  ...baseApi,
  async getUnscheduledTaskGroups(siteId: string) {
    const request = await this.doRequest<UnscheduledTaskGroup[]>({
      path: `/v2/home/site/${siteId}/unscheduledTaskGroups`
    })
    const response = await request.get()
    return (response as unknown) as UnscheduledTaskGroup[]
  }
}
