import React, {ReactNode, useState, useEffect, useCallback} from 'react'
import {createPortal} from 'react-dom'
import styled from 'styled-components'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`
const ModalWrapper = styled.div`
  max-width: 90%;
  max-height: 90%;
  background-color: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
`

export const SmallModalContent = styled.div`
  padding: 2rem;
  width: 368px;
`

export const LargeModalContent = styled.div`
  width: 520px;
  padding: 2rem;
`
export const WideModalContent = styled.div`
  padding: 2rem;
  width: 900px;
`

interface ChildProps {
  children: ReactNode
  isOpen: boolean
  order?: number
  closeParentModal?: () => void
}

export default function CenteredModal({isOpen, children, order, closeParentModal}: ChildProps) {
  const [modalIsOpen, setIsOpen] = useState(isOpen)

  const closeModal = useCallback(() => {
    setIsOpen(false)
    closeParentModal?.()
  }, [])

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  useEffect(() => {
    // close modal if user clicks outside it
    function handleClick() {
      if (isOpen === true) {
        closeModal()
      }
    }
    window.addEventListener('click', handleClick)
    return () => {
      return window.removeEventListener('click', handleClick)
    }
  }, [isOpen, closeModal])

  useEffect(() => {
    if (isOpen === true) {
      openModal()
    } else {
      closeModal()
    }
    return () => {
      // unmount
    }
  }, [isOpen, closeModal, openModal])

  return modalIsOpen
    ? createPortal(
        <ModalOverlay style={{zIndex: order ? 1000 - order : 1000}}>
          <ModalWrapper
            onClick={event => {
              event.stopPropagation()
            }}
          >
            {children}
          </ModalWrapper>
        </ModalOverlay>,

        document.getElementById('portal')!
      )
    : null
}
