import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconSensorMachine = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.575 12.9542C38.5404 15.9196 40.377 20.0127 40.377 24.5292C40.377 29.0456 38.5404 33.14 35.575 36.1054L34.684 35.2145C37.4235 32.475 39.117 28.6971 39.117 24.5292C39.117 20.3614 37.4236 16.5847 34.684 13.8452L35.575 12.9542ZM23.9999 20.6604C21.8636 20.6604 20.1306 22.3924 20.1306 24.5298C20.1306 26.6668 21.8634 28.3978 23.9999 28.3978C26.1376 28.3978 27.8692 26.667 27.8692 24.5298C27.8692 22.3923 26.1374 20.6604 23.9999 20.6604ZM18.8706 24.5298C18.8706 21.6963 21.168 19.4004 23.9999 19.4004C26.8332 19.4004 29.1292 21.6964 29.1292 24.5298C29.1292 27.3633 26.833 29.6578 23.9999 29.6578C21.1682 29.6578 18.8706 27.3634 18.8706 24.5298ZM12.4245 12.9543C9.46051 15.9196 7.62256 20.0126 7.62256 24.5304C7.62256 29.0458 9.46051 33.14 12.4245 36.1053L13.3156 35.2146C10.5772 32.4749 8.88256 28.6969 8.88256 24.5304C8.88256 20.3614 10.5772 16.5847 13.3156 13.8451L12.4245 12.9543ZM31.6004 16.9293C33.5462 18.8752 34.753 21.564 34.753 24.5305C34.753 27.4943 33.5462 30.1845 31.6004 32.1303L30.7094 31.2394C32.4294 29.5194 33.493 27.1458 33.493 24.5305C33.493 21.9126 32.4294 19.5402 30.7094 17.8203L31.6004 16.9293ZM16.3997 16.9293C14.4539 18.8752 13.2471 21.564 13.2471 24.5292C13.2471 27.4943 14.4538 30.1844 16.3997 32.1303L17.2907 31.2394C15.5707 29.5194 14.5071 27.1458 14.5071 24.5292C14.5071 21.9127 15.5707 19.5403 17.2907 17.8203L16.3997 16.9293Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62842 45.3714H45.3713V2.62854H2.62842V45.3714Z"
        stroke={props.fill || colors.brand.cobalt}
        fill="none"
      />
    </svg>
  )
}
