import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconDragItem = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path d="M20.3699 11.9999C20.3699 13.852 18.852 15.3699 16.9999 15.3699V16.6299C19.5479 16.6299 21.6299 14.5479 21.6299 11.9999H20.3699ZM16.9999 8.63C18.852 8.63 20.3699 10.1479 20.3699 11.9999H21.6299C21.6299 9.45204 19.5479 7.37 16.9999 7.37V8.63ZM13.63 11.9999C13.63 10.1479 15.1479 8.63 16.9999 8.63V7.37C14.452 7.37 12.37 9.45204 12.37 11.9999H13.63ZM16.9999 15.3699C15.1479 15.3699 13.63 13.852 13.63 11.9999H12.37C12.37 14.5479 14.452 16.6299 16.9999 16.6299V15.3699ZM13.63 23.9994C13.63 22.1473 15.1479 20.6294 16.9999 20.6294V19.3694C14.452 19.3694 12.37 21.4514 12.37 23.9994H13.63ZM16.9999 27.3693C15.1479 27.3693 13.63 25.8514 13.63 23.9994H12.37C12.37 26.5473 14.452 28.6293 16.9999 28.6293V27.3693ZM20.3699 23.9994C20.3699 25.8514 18.852 27.3693 16.9999 27.3693V28.6293C19.5479 28.6293 21.6299 26.5473 21.6299 23.9994H20.3699ZM16.9999 20.6294C18.852 20.6294 20.3699 22.1473 20.3699 23.9994H21.6299C21.6299 21.4514 19.5479 19.3694 16.9999 19.3694V20.6294ZM16.9999 31.3696C14.452 31.3696 12.37 33.4516 12.37 35.9995H13.63C13.63 34.1475 15.1479 32.6296 16.9999 32.6296V31.3696ZM21.6299 35.9995C21.6299 33.4516 19.5479 31.3696 16.9999 31.3696V32.6296C18.852 32.6296 20.3699 34.1475 20.3699 35.9995H21.6299ZM16.9999 40.6295C19.5479 40.6295 21.6299 38.5475 21.6299 35.9995H20.3699C20.3699 37.8516 18.852 39.3695 16.9999 39.3695V40.6295ZM12.37 35.9995C12.37 38.5475 14.452 40.6295 16.9999 40.6295V39.3695C15.1479 39.3695 13.63 37.8516 13.63 35.9995H12.37Z" />
      <path d="M34.3699 11.9999C34.3699 13.852 32.852 15.3699 30.9999 15.3699V16.6299C33.5479 16.6299 35.6299 14.5479 35.6299 11.9999H34.3699ZM30.9999 8.63C32.852 8.63 34.3699 10.1479 34.3699 11.9999H35.6299C35.6299 9.45204 33.5479 7.37 30.9999 7.37V8.63ZM27.63 11.9999C27.63 10.1479 29.1479 8.63 30.9999 8.63V7.37C28.452 7.37 26.37 9.45204 26.37 11.9999H27.63ZM30.9999 15.3699C29.1479 15.3699 27.63 13.852 27.63 11.9999H26.37C26.37 14.5479 28.452 16.6299 30.9999 16.6299V15.3699ZM27.63 23.9994C27.63 22.1473 29.1479 20.6294 30.9999 20.6294V19.3694C28.452 19.3694 26.37 21.4514 26.37 23.9994H27.63ZM30.9999 27.3693C29.1479 27.3693 27.63 25.8514 27.63 23.9994H26.37C26.37 26.5473 28.452 28.6293 30.9999 28.6293V27.3693ZM34.3699 23.9994C34.3699 25.8514 32.852 27.3693 30.9999 27.3693V28.6293C33.5479 28.6293 35.6299 26.5473 35.6299 23.9994H34.3699ZM30.9999 20.6294C32.852 20.6294 34.3699 22.1473 34.3699 23.9994H35.6299C35.6299 21.4514 33.5479 19.3694 30.9999 19.3694V20.6294ZM30.9999 31.3696C28.452 31.3696 26.37 33.4516 26.37 35.9995H27.63C27.63 34.1475 29.1479 32.6296 30.9999 32.6296V31.3696ZM35.6299 35.9995C35.6299 33.4516 33.5479 31.3696 30.9999 31.3696V32.6296C32.852 32.6296 34.3699 34.1475 34.3699 35.9995H35.6299ZM30.9999 40.6295C33.5479 40.6295 35.6299 38.5475 35.6299 35.9995H34.3699C34.3699 37.8516 32.852 39.3695 30.9999 39.3695V40.6295ZM26.37 35.9995C26.37 38.5475 28.452 40.6295 30.9999 40.6295V39.3695C29.1479 39.3695 27.63 37.8516 27.63 35.9995H26.37Z" />
    </svg>
  )
}
