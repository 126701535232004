import React from 'react'
import NotYetImplentedView from '../NotYetImplemented'
import EditSite from './EditSite'
import EditLocation from './EditLocation'
import EditLocationGroup from './EditLocationGroup'
import EditChainView from './EditChain'
import EditBrand from './EditBrand'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'

const EditOrganisationLevel = ({type, id}: {type: string; id: string}) => {
  const {t} = useTranslation()
  switch (type) {
    case 'chain':
      return (
        <>
          <Helmet title={t('common:routes.editChain', 'edit chain')} />
          <EditChainView id={id} />
        </>
      )
    case 'brands':
      return (
        <>
          <Helmet title={t('common:routes.editBrand', 'Edit brand')} />
          <EditBrand id={id} />
        </>
      )
    case 'site':
      return (
        <>
          <EditSite id={id} />
        </>
      )
    case 'locations':
      return (
        <>
          <Helmet title={t('common:routes.editLocation', 'Edit location')} />
          <EditLocation id={id} />
        </>
      )
    case 'locationGroups':
      return (
        <>
          <Helmet title={t('common:routes.editLocationGroup', 'Edit location group')} />
          <EditLocationGroup id={id} />
        </>
      )
    default:
      return (
        <>
          <NotYetImplentedView />
        </>
      )
  }
}

export default EditOrganisationLevel
