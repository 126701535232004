import React from 'react'
import EditUserAccountSettingsDetails from './EditUserAccountSettingsDetails'
import {userApi} from '../../../state/rest'
import {Loader} from '../../../Routes'
import Errors400 from '../Errors/ErrorPages'
import {useData} from '../../../config/utils'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'

interface UsersEditProps {
  id: string
}

const UsersEditView = ({id}: UsersEditProps) => {
  const fetchUser = () => userApi.getUser(id)
  const {loading, data, error} = useData(fetchUser)
  const {t} = useTranslation()
  return loading ? (
    <Loader show />
  ) : error ? (
    <Errors400 />
  ) : data !== null ? (
    <>
      <Helmet title={t('common:routes.userAccountSettings', 'User account settings')} />
      <EditUserAccountSettingsDetails savedData={data} isEditingSelf={false} />
    </>
  ) : null
}

export default UsersEditView
