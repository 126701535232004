import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconServingTemp = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8666 1.83875C30.831 3.45541 29.9498 4.95141 29.7787 6.43634C29.6152 7.8553 30.125 9.14602 30.5683 10.2686L30.5915 10.3274C31.0613 11.5176 31.4489 12.5329 31.3247 13.6117C31.2051 14.6494 30.5868 15.8722 28.7049 17.3668L29.4885 18.3535C31.5241 16.7369 32.4053 15.2409 32.5764 13.7559C32.7399 12.337 32.2301 11.0462 31.7868 9.92367L31.7635 9.86481C31.2937 8.67467 30.9061 7.65933 31.0304 6.58055C31.15 5.54288 31.7683 4.32005 33.6502 2.82542L32.8666 1.83875ZM35.7592 8.83661C35.9302 7.35167 36.8115 5.85568 38.847 4.23901L39.6307 5.22569C37.7488 6.72032 37.1304 7.94315 37.0109 8.98082C36.8866 10.0596 37.2742 11.0749 37.744 12.2651L37.7672 12.3239C38.2106 13.4465 38.7203 14.7372 38.5569 16.1562C38.3858 17.6411 37.5045 19.1371 35.469 20.7538L34.6853 19.7671C36.5672 18.2725 37.1856 17.0496 37.3051 16.012C37.4294 14.9332 37.0418 13.9179 36.572 12.7277L36.5488 12.6689C36.1054 11.5463 35.5957 10.2556 35.7592 8.83661ZM44.4667 6.47253C42.4311 8.0892 41.5499 9.58519 41.3788 11.0701C41.2153 12.4891 41.725 13.7798 42.1684 14.9024L42.1916 14.9612C42.6614 16.1514 43.049 17.1667 42.9248 18.2455C42.8052 19.2832 42.1869 20.506 40.305 22.0006L41.0886 22.9873C43.1242 21.3706 44.0054 19.8746 44.1765 18.3897C44.34 16.9707 43.8302 15.68 43.3869 14.5575L43.3636 14.4986C42.8938 13.3085 42.5062 12.2931 42.6305 11.2143C42.7501 10.1767 43.3684 8.95384 45.2503 7.45921L44.4667 6.47253ZM24.5576 17.9574V22.4763C34.37 22.8018 42.2655 30.6974 42.5907 40.5098H46.0001V41.7698H2V40.5098H5.26373C5.589 30.6972 13.4849 22.8014 23.2976 22.4763V17.9574H24.5576ZM41.33 40.5098C40.9984 31.1842 33.3338 23.7259 23.9272 23.7259C14.5207 23.7259 6.85609 31.1842 6.52448 40.5098H41.33ZM46.0001 45.6535H2V44.3935H46.0001V45.6535Z"
      />
    </svg>
  )
}
