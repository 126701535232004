import React from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'
import {Text} from '../../atoms/Text'
import {IconPalette, SharedIcon, SmallIconPalette} from '../../atoms/Icon'
import {ApplianceDataProps, ApplianceDataValueAndUnit, getValueText} from '../ApplianceData'
import {DataField, DataFieldProps} from '../../atoms/DataField'

export interface ApplianceDetailsDataProps extends Omit<ApplianceDataProps, 'value' | 'minValue' | 'maxValue'> {
  titleIcon?: IconPalette | SmallIconPalette
  value?: (number | string) | React.ReactNode
  minValue?: (number | string) | React.ReactNode
  maxValue?: (number | string) | React.ReactNode
  expanded?: boolean
  onExpandButtonClick?: React.MouseEventHandler<HTMLSpanElement>
  valueInfo?: string
  metadata?: DataFieldProps[]
  hasTitleAlarm?: boolean
  compact?: boolean
  applianceType?: string
}

export const ApplianceDetailsData: React.FC<ApplianceDetailsDataProps> = ({
  title,
  titleIcon,
  hasAlarm,
  value,
  unit,
  minValue,
  minUnit,
  maxValue,
  maxUnit,
  minValueLabelText = 'Min',
  maxValueLabelText = 'Max',
  expanded,
  onExpandButtonClick,
  valueInfo,
  metadata,
  hasTitleAlarm,
  compact,
  applianceType,
  ...rest
}) => {
  const titleColor = hasTitleAlarm ?? hasAlarm ? colors.system.red : colors.brand.cobalt
  const valueColor = hasAlarm ? colors.system.red : colors.brand.cobalt

  return (
    <ApplianceDetailsDataContainer {...rest}>
      {(titleIcon || title) && applianceType !== 'waste-scale' && (
        <ApplianceDetailsDataTitle>
          {titleIcon && <SharedIcon icon={titleIcon} width="0.75em" height="0.75em" fill={titleColor} />}
          <Text size="S" color={titleColor}>
            {title}
          </Text>
        </ApplianceDetailsDataTitle>
      )}
      <ApplianceDetailsDataContent expanded={expanded} compact={compact}>
        <ApplianceDetailsDataContentValuesAndInfo>
          <ApplianceDetailsDataContentValues>
            {React.isValidElement(minValue) ? (
              minValue
            ) : (
              <ApplianceDetailsDataContentValuesMinValue size={compact ? 'XS' : 'S'} color={colors.system.grey_50}>
                {minValueLabelText} {getValueText(minValue as string | number | undefined, minUnit ?? unit)}
              </ApplianceDetailsDataContentValuesMinValue>
            )}
            {React.isValidElement(value) ? (
              value
            ) : (
              <ApplianceDataValueAndUnit
                value={value as string | number | undefined}
                unit={unit}
                color={valueColor}
                valueTextLevel={compact ? 2 : 1}
                unitTextSize="XL"
              />
            )}
            {React.isValidElement(maxValue) ? (
              maxValue
            ) : (
              <ApplianceDetailsDataContentValuesMaxValue size={compact ? 'XS' : 'S'} color={colors.system.grey_50}>
                {getValueText(maxValue as string | number | undefined, maxUnit ?? unit)} {maxValueLabelText}
              </ApplianceDetailsDataContentValuesMaxValue>
            )}
          </ApplianceDetailsDataContentValues>
          {valueInfo && (
            <ValueInfoText size={compact ? 'XS' : 'S'} color={colors.system.grey_50}>
              {valueInfo}
            </ValueInfoText>
          )}
        </ApplianceDetailsDataContentValuesAndInfo>
        {expanded && (
          <ApplianceDetailsDataMetadataContent>
            {metadata?.map((m, i) => (
              <DataField key={i} label={m.label} value={m.value} />
            ))}
          </ApplianceDetailsDataMetadataContent>
        )}
      </ApplianceDetailsDataContent>
      <ApplianceDetailsDataCornerTriangle onClick={onExpandButtonClick}>
        <ApplianceDetailsDataCornerTriangleIcon
          icon={expanded ? SmallIconPalette.Close : SmallIconPalette.Info}
          width="1.25em"
          height="1.25em"
        />
      </ApplianceDetailsDataCornerTriangle>
    </ApplianceDetailsDataContainer>
  )
}

const ApplianceDetailsDataContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
`

const ApplianceDetailsDataTitle = styled.div`
  display: grid;
  height: 2rem;
  padding: 0 1rem;
  border: 1px solid ${colors.system.grey_5};
  border-radius: 3rem;
  grid-auto-flow: column;
  gap: 0.625rem;
  align-items: center;
  background-color: ${colors.system.white};
  position: relative;
  top: 1rem;
  margin-top: -1rem;
`

const ApplianceDetailsDataContent = styled.div<{expanded?: boolean; compact?: boolean}>(
  ({expanded, compact}) => css`
    border: 1px solid ${colors.system.grey_5};
    border-radius: 0.5rem;
    width: 100%;
    max-height: ${expanded ? '21rem' : '11rem'};
    background-color: ${colors.system.white};
    transition: max-height 0.3s ease-out;
    padding: ${compact ? '1.5rem 1rem 0.75rem 1rem' : '2rem 1.5rem 1rem 1.5rem'};
    display: grid;
    grid-auto-flow: row;
    align-content: start;
    justify-items: center;
    gap: 1rem;
  `
)

const ApplianceDetailsDataContentValuesAndInfo = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  justify-items: center;
  width: 100%;
`

const ApplianceDetailsDataContentValues = styled.div`
  display: grid;
  grid-template: auto / 1fr auto 1fr;
  gap: 2rem;
  align-items: center;
  width: 100%;
  min-height: 2.56rem;
`

const ApplianceDetailsDataContentValuesMinValue = styled(Text)`
  display: grid;
  justify-content: end;
`

const ApplianceDetailsDataContentValuesMaxValue = styled(Text)`
  display: grid;
  justify-content: start;
`

const ValueInfoText = styled(Text)`
  min-height: 1rem;
`

const ApplianceDetailsDataMetadataContent = styled.div`
  display: grid;
  grid-template: auto auto / 1fr 2fr 1fr 1fr;
  width: 100%;
  gap: 0.5rem 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${colors.system.grey_5};
`

const ApplianceDetailsDataCornerTriangle = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 4rem 4rem;
  border-color: transparent transparent ${colors.system.grey_5} transparent;
  cursor: pointer;
`

const ApplianceDetailsDataCornerTriangleIcon = styled(SharedIcon)`
  position: absolute;
  right: 0.5rem;
  top: 2.25rem;
`
