import React from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'

import {MainLayoutWithoutStretch, MainContent} from '../../../Layout/Layout'
import {GoBackHeaderTitle, HeaderRow, HeaderActions} from '../../../Molecules/ViewComponents/ViewHeader'

import AlarmNotifications from './AlarmNotifications'
import {Helmet} from 'react-navi-helmet-async'

const AlarmSettingsView = () => {
  const {t} = useTranslation(['alarms', 'settings'])
  const nav = useNavigation()

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.alarmMessageSettings', 'Alarm message settings')} />
      <MainContent variant="white">
        <HeaderRow
          description={t(
            'alarms:labels.alarmSettingsGuide',
            'Here you can define who is notified about the alarms with emails or text messages.'
          )}
        >
          <GoBackHeaderTitle
            path={'/settings'}
            label={t('alarms:labels.alarmMessageSettings', 'Alarm message settings')}
            backLabel={t('settings:labels.settingsTitle', 'Settings')}
          />
          <HeaderActions onAdd={() => nav.navigate('/settings/alarms/create')} />
        </HeaderRow>
        <AlarmNotifications />
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

export default AlarmSettingsView
