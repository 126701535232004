import React from 'react'
import {Puff} from 'svg-loaders-react'

const LoadingWithPuff = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100px'
    }}
  >
    <div>
      <Puff stroke="black" />
    </div>
  </div>
)

export default LoadingWithPuff
