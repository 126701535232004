import {CompletedTask, TaskListItemData} from '../home/site/state'

export interface TasksState {
  completedTasks: {
    data: {
      count: number
      tasks: CompletedTask[]
    }
    loadingMoreTasks: boolean
    error: Error | null
  }
  todoTasks: {
    data: {
      lateTasks: {
        count: number
        tasks: TaskListItemData[]
        loadingMoreTasks: boolean
        error: Error | null
      }
      todaysTasks: {
        count: number
        tasks: TaskListItemData[]
        loadingMoreTasks: boolean
        error: Error | null
      }
      inProgressTasks: {
        count: number
        tasks: TaskListItemData[]
        error: Error | null
      }
    }
    loadingMoreTasks: boolean
    error: Error | null
  }
  filters: {
    userAccounts: {
      id: string
      name: string
    }[]
    taskTypes: string[]
    taskGroups: {
      id: string
      name: string
    }[]
  }
  initialLoading: boolean
  initialLoadingError: Error | null
}

export const state: TasksState = {
  completedTasks: {
    data: {
      count: 0,
      tasks: []
    },
    loadingMoreTasks: false,
    error: null
  },
  todoTasks: {
    data: {
      lateTasks: {
        count: 0,
        tasks: [],
        loadingMoreTasks: false,
        error: null
      },
      todaysTasks: {
        count: 0,
        tasks: [],
        loadingMoreTasks: false,
        error: null
      },
      inProgressTasks: {
        count: 0,
        tasks: [],
        error: null
      }
    },
    loadingMoreTasks: false,
    error: null
  },
  filters: {
    userAccounts: [],
    taskTypes: [],
    taskGroups: []
  },
  initialLoading: false,
  initialLoadingError: null
}
