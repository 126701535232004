import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import React, {ReactNode, useEffect} from 'react'
import {useAppState} from '../../state'
import {Text, Title} from './Typography'
import {Button} from './Buttons'
import {Notification} from '../../state/state'
import {colors} from '../../sharedComponents/colors'
import {taskGroupIconPicker} from '../../pages/tasks/utils'
import {SharedIcon} from '../../sharedComponents/components'

const NotificationListWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  box-sizing: border-box;
`
export const NotificationList = () => {
  const {state} = useAppState()
  const notifications = state.notificationList
  return (
    <NotificationListWrapper>
      {notifications.length > 0 &&
        notifications.map(n => {
          return n.action ? (
            <ConfirmNotification notification={n} key={n.id} />
          ) : (
            <InfoNotification notification={n} key={n.id} />
          )
        })}
    </NotificationListWrapper>
  )
}

const NotificationSliderWrapper = styled.div`
  height: 4.5rem;
  position: relative;

  &.open {
    animation-name: slideIn;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  &.closed {
    animation-name: slideOut;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  @keyframes slideIn {
    0% {
      margin-top: -4.5rem;
      visibility: hidden;
    }
    100% {
      margin-top: 0;
      visibility: visible;
    }
  }

  @keyframes slideOut {
    0% {
      margin-top: 0;
      visibility: visible;
    }
    100% {
      margin-top: -4.5rem;
      visibility: hidden;
    }
  }
`

const LargeNotificationSliderWrapper = styled.div`
  height: 6.6rem;
  position: relative;
  &.open {
    animation-name: slideInLarge;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  &.closed {
    animation-name: slideOutLarge;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  @keyframes slideInLarge {
    0% {
      margin-top: -6.6rem;
      visibility: hidden;
    }
    100% {
      margin-top: 0;
      visibility: visible;
    }
  }

  @keyframes slideOutLarge {
    0% {
      margin-top: 0;
      visibility: visible;
    }
    100% {
      margin-top: -6.6rem;
      visibility: hidden;
    }
  }
`

interface NotificationSliderProps {
  visible: boolean
  children: ReactNode
  dataCy?: string
}
const NotificationSlider = (props: NotificationSliderProps) => {
  return (
    <NotificationSliderWrapper className={props.visible ? 'open' : 'closed'} data-cy={props.dataCy}>
      {props.children}
    </NotificationSliderWrapper>
  )
}

const LargeNotificationSlider = (props: NotificationSliderProps) => {
  return (
    <LargeNotificationSliderWrapper className={props.visible ? 'open' : 'closed'} data-cy={props.dataCy}>
      {props.children}
    </LargeNotificationSliderWrapper>
  )
}

type NotificationTheme = {
  bgColor: string
  color: string
  border: string
}

type NotificationItemVariant = {
  default: NotificationTheme
  info: NotificationTheme
  error: NotificationTheme
  notice: NotificationTheme
}
const notificationVariants: NotificationItemVariant = {
  default: {bgColor: `${colors.system.white}`, color: `${colors.brand.cobalt}`, border: `${colors.brand.cobalt}`},
  info: {bgColor: `${colors.system.white}`, color: `${colors.brand.cobalt}`, border: `${colors.system.green}`},
  error: {bgColor: `${colors.system.white}`, color: `${colors.brand.cobalt}`, border: `${colors.system.red}`},
  notice: {bgColor: `${colors.system.white}`, color: `${colors.brand.cobalt}`, border: `${colors.brand.signalOrange}`}
}
interface NotificationProps {
  variant?: keyof NotificationItemVariant
  dataCy?: string
}

const SmallNotificationItem = styled.div<NotificationProps>`
  box-sizing: border-box;
  height: 4.5rem;
  width: 100%;
  color: ${props =>
    props.variant ? notificationVariants[props.variant].color : notificationVariants['default'].color};
  background-color: ${props =>
    props.variant ? notificationVariants[props.variant].bgColor : notificationVariants['default'].bgColor};
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${colors.system.lightGrey_5};

  &:before {
    content: '';
    position: absolute;
    background-color: ${props =>
      props.variant ? notificationVariants[props.variant].border : notificationVariants['default'].border};
    width: 8px;
    height: 100%;
    border-bottom: 1px solid ${colors.system.lightGrey_5};
    left: 0;
    top: 0;
  }

  > svg {
    color: ${props =>
      props.variant ? notificationVariants[props.variant].color : notificationVariants['default'].color};
  }
`

const SmallNotificationContent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 1rem;
  align-items: center;
  text-align: start;
`

export function useAutoHide(notification: Notification) {
  const {actions} = useAppState()
  const close = () => {
    actions.hideNotification(notification)
  }
  useEffect(() => {
    if (notification.hideAfterDelay) {
      console.debug('Notification timer start', notification.hideAfterDelay)
      setTimeout(() => {
        console.debug('Notification timer up')
        close()
      }, notification.hideAfterDelay)
    }
  }, [notification.hideAfterDelay])
}

interface NotificationItemProps {
  notification: Notification
}
export const InfoNotification = ({notification}: NotificationItemProps) => {
  useAutoHide(notification)
  return (
    <NotificationSlider visible={notification.visible} dataCy={`notification-${notification.type}`}>
      <SmallNotificationItem variant={notification.type}>
        <SmallNotificationContent>
          {notification.icon && <SharedIcon icon={taskGroupIconPicker(notification.icon)} />}
          <Title level={3}>{notification.title}</Title>
          <Text level={1}>{notification.description}</Text>
        </SmallNotificationContent>
      </SmallNotificationItem>
    </NotificationSlider>
  )
}

const LargeNotificationItem = styled.div<NotificationProps>`
  box-sizing: border-box;
  height: 6.6rem;
  width: 100%;
  color: ${props =>
    props.variant ? notificationVariants[props.variant].color : notificationVariants['default'].color};
  background-color: ${props =>
    props.variant ? notificationVariants[props.variant].bgColor : notificationVariants['default'].bgColor};
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.system.lightGrey_5};

  &:before {
    content: '';
    position: absolute;
    background-color: ${props =>
      props.variant ? notificationVariants[props.variant].border : notificationVariants['default'].border};
    width: 8px;
    height: 100%;
    border-bottom: 1px solid ${colors.system.lightGrey_5};
    left: 0;
    top: 0;
  }

  > svg {
    color: ${props =>
      props.variant ? notificationVariants[props.variant].color : notificationVariants['default'].color};
  }
`

const LargeNotificationContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  align-items: center;
`

const LargeNotificationTextContent = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
  text-align: start;
`

const NotificationButtonContainer = styled.div`
  > * {
    margin-left: 1rem;
  }
`

export const ConfirmNotification = ({notification}: NotificationItemProps) => {
  const {t} = useTranslation()
  const {actions} = useAppState()
  useAutoHide(notification)

  const handleClose = () => {
    actions.hideNotification(notification)
  }

  return (
    <LargeNotificationSlider visible={notification.visible} dataCy={`notification-${notification.type}`}>
      <LargeNotificationItem variant={notification.type}>
        <LargeNotificationContent>
          {notification.icon && <SharedIcon icon={taskGroupIconPicker(notification.icon)} />}
          <LargeNotificationTextContent>
            <Title level={3}>{notification.title}</Title>
            <Text level={1}>{notification.description}</Text>
          </LargeNotificationTextContent>
        </LargeNotificationContent>
        <NotificationButtonContainer>
          {notification.action!.showClose && (
            <Button variant="secondary" negative={true} onClick={handleClose}>
              {t('common:buttons.close', 'Close')}
            </Button>
          )}
          {notification.action!.action && (
            <Button variant="secondary" onClick={notification.action!.action}>
              {notification.action!.text}
            </Button>
          )}
        </NotificationButtonContainer>
      </LargeNotificationItem>
    </LargeNotificationSlider>
  )
}
