import React from 'react'
import {useTranslation} from 'react-i18next'
import ConfirmModal from '../../Molecules/Modals/ConfirmModal'

interface ConfirmProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}
export const ConfirmEquipmentDeleteModal = ({isOpen, onClose, onDelete}: ConfirmProps) => {
  const {t} = useTranslation('appliances')

  return (
    <ConfirmModal
      heading={t('appliances:confirmation.deleteAppliance', 'Delete appliance?')}
      content={t('appliances:confirmation.message', 'Are you sure you want to delete this appliance?')}
      actions={[
        {
          label: t('common:general.cancel', 'Cancel'),
          action: () => onClose()
        },
        {
          label: t('common:general.delete', 'Delete'),
          action: () => onDelete()
        }
      ]}
      isOpen={isOpen}
      closeModal={onClose}
    />
  )
}

export default ConfirmEquipmentDeleteModal
