import React from 'react'
import styled from 'styled-components'

import {colors} from '../../../colors'
import {Text} from '../../index'

export interface LanguageOption {
  description: string
  onClick: () => void
}

export interface LanguageSelectorProps {
  languageOptions: LanguageOption[]
  selectedLanguage: string
}

const LanguageSelectorModal = styled.div`
  min-width: 245px;
  color: ${colors.brand.cobalt};
  padding: 1rem;
`

const SelectItem = styled.div`
  margin: 0 3rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LanguageSelector = ({languageOptions}: LanguageSelectorProps) => {
  return (
    <LanguageSelectorModal data-cy="language-selector-modal">
      {languageOptions.map(option => (
        <SelectItem key={option.description}>
          <Text size="M" onClick={option.onClick}>
            {' '}
            {option.description}{' '}
          </Text>
        </SelectItem>
      ))}
    </LanguageSelectorModal>
  )
}
