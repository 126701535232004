import React from 'react'
import {useTranslation} from 'react-i18next'
import {BorderedListItem, AdjustableContainer} from '../../Layout/Layout'
import {H3, Text} from '../../Atoms/Typography'
import styled from 'styled-components'

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
  > * + * {
    margin-top: 0.25rem;
  }
`

export const AllTasksDone = () => {
  const {t} = useTranslation('tasks')
  return (
    <BorderedListItem>
      <AdjustableContainer height={'200px'}>
        <StatusContainer>
          <H3>{t('tasks:messages.goodJob', 'Good job!')}</H3>
          <Text level={1}>{t('tasks:messages.allDone', 'All of todays tasks are done.')}</Text>
        </StatusContainer>
      </AdjustableContainer>
    </BorderedListItem>
  )
}

export const NoTasks = () => {
  const {t} = useTranslation('tasks')
  return (
    <BorderedListItem>
      <AdjustableContainer height={'200px'}>
        <StatusContainer>
          <H3>{t('tasks:messages.noTasksToday', 'No tasks for today')}</H3>
        </StatusContainer>
      </AdjustableContainer>
    </BorderedListItem>
  )
}

export const EmptyTaskList = () => {
  const {t} = useTranslation('home')
  return (
    <BorderedListItem>
      <AdjustableContainer height={'200px'}>
        <StatusContainer>
          <H3>{t('home:messages.noTasksAdded', 'No tasks added yet')}</H3>
        </StatusContainer>
      </AdjustableContainer>
    </BorderedListItem>
  )
}

export const EmptyEquipmentList = () => {
  const {t} = useTranslation('home')
  return (
    <BorderedListItem>
      <AdjustableContainer height={'200px'}>
        <StatusContainer>
          <H3>{t('home:messages.noAppliancesAdded', 'No appliances added yet')}</H3>
        </StatusContainer>
      </AdjustableContainer>
    </BorderedListItem>
  )
}
