import {AsyncAction} from 'overmind'
import {
  GetAvailableSensorsParams,
  GetApplianceSettingParams,
  UpdateApplianceSettingParams,
  AvailableSensorsResponseBody,
  ApplianceSetting
} from './state'

export const getAvailableSensors: AsyncAction<
  GetAvailableSensorsParams,
  AvailableSensorsResponseBody | undefined
> = async ({state, effects}, params: GetAvailableSensorsParams) => {
  try {
    state.v1.settings.appliances.loading = true
    const response = await effects.v1.settings.appliances.applianceSettingsApi.getAvailableSensorsForSite(params.siteId)
    state.v1.settings.appliances.availableSensors.data = response.data
    return response.data
  } catch (err) {
    state.v1.settings.appliances.error = err as Error
  } finally {
    state.v1.settings.appliances.loading = false
  }
}

export const getApplianceSetting: AsyncAction<
  GetApplianceSettingParams,
  {items: ApplianceSetting} | undefined
> = async ({state, effects}, params: GetApplianceSettingParams) => {
  try {
    state.v1.settings.appliances.loading = true
    const response = await effects.v1.settings.appliances.applianceSettingsApi.getApplianceSettingOfSite(
      params.applianceId,
      params.siteId
    )
    state.v1.settings.appliances.applianceSetting.data = response.data
    return response.data
  } catch (err) {
    state.v1.settings.appliances.error = err as Error
  } finally {
    state.v1.settings.appliances.loading = false
  }
}

export const updateApplianceSetting: AsyncAction<
  UpdateApplianceSettingParams,
  {items: ApplianceSetting} | undefined
> = async ({state, effects}, params: UpdateApplianceSettingParams) => {
  try {
    state.v1.settings.appliances.loading = true
    const response = await effects.v1.settings.appliances.applianceSettingsApi.updateApplianceSetting(
      params.applianceId,
      params.siteId,
      params.payload
    )
    state.v1.settings.appliances.applianceSetting.data = response.data
    return response.data
  } catch (err) {
    state.v1.settings.appliances.error = err as Error
  } finally {
    state.v1.settings.appliances.loading = false
  }
}
