import {MainContent, MainLayoutWithoutStretch} from '../../../Components/Layout/Layout'
import {GoBackHeaderTitle, HeaderActions, HeaderRow} from '../../../Components/Molecules/ViewComponents/ViewHeader'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigation} from 'react-navi'
import {Helmet} from 'react-navi-helmet-async'
import {useAppState} from '../../../state'
import MessageSettingsList from './MessageSettingsList'

const MessageSettingsView = () => {
  const {t} = useTranslation(['messages', 'settings'])
  const nav = useNavigation()
  const {state, actions} = useAppState()

  useEffect(() => {
    const setup = async () => {
      actions.v1.messages.getAllMessagesForAdmin()
    }
    setup()
  }, [])

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.messageSettings', 'Message Settings')} />
      <MainContent variant="white">
        <HeaderRow
          description={t(
            'messages:labels.messageSettingsGuide',
            'Here you can create, edit and delete messages for everyone to see.'
          )}
        >
          <GoBackHeaderTitle
            path={'/settings'}
            label={t('messages:labels.messageSettings', 'Message Settings')}
            backLabel={t('settings:labels.settingsTitle', 'Settings')}
          />
          <HeaderActions onAdd={() => nav.navigate('/settings/messages/create')} />
        </HeaderRow>
        <MessageSettingsList />
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

export default MessageSettingsView
