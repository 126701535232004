import {baseApi} from '../../rest'
import {SuspendedPeriod, SuspendedPeriodPayload} from './state'

export const siteSuspendedPeriodsApi = {
  ...baseApi,
  async getSiteSuspendedPeriods(siteId: string) {
    const req = await this.doRequest<any>({
      path: `/v1/locationSettings/suspendedPeriods/site/${siteId}`
    })
    const resp = (await req.get()) as unknown
    return resp as SuspendedPeriod[]
  },
  async newSiteSuspendedPeriod(payload: SuspendedPeriodPayload) {
    const req = await this.doRequest<any>({
      path: `/v1/locationSettings/suspendedPeriods`,
      payload
    })
    const resp = (await req.post()) as unknown
    return resp as SuspendedPeriod
  },
  async deleteSiteSuspendedPeriod(siteId: string, periodId: string) {
    const req = await this.doRequest<any>({
      path: `/v1/locationSettings/suspendedPeriods/site/${siteId}/periods/${periodId}`
    })
    const resp = (await req.delete()) as unknown
    return resp
  }
}
