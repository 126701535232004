export interface GeneralTask {
  id: string
  name: string
  target: string
  taskType: {
    id: string
  }
  taskGroup: {
    name: string
  }
  equipmentName: string
}

export interface TaskGroup {
  name: string
  icon: string
  tasks: GeneralTask[]
}

interface TaskGroupState {
  data: TaskGroup
  pending: boolean
  error: Error | null
}

export const state: TaskGroupState = {
  data: {
    name: '',
    icon: '',
    tasks: []
  },
  pending: false,
  error: null
}
