import React from 'react'
import {useAppState} from '../../state'
import {useTranslation} from 'react-i18next'
import {InputField} from '../tasks/CompletedTasks'
import {alarmTypeToTranslation} from './formatters'
import {Button, DatePicker, Select, SelectOption} from '../../sharedComponents/components'

export interface AlarmsFilters {
  alarmTypes: SelectOption[]
  completedBy: SelectOption[]
  startDate: Date
  endDate: Date
}

export interface AlarmsFilterViewProps {
  locale: string
  selectedFilter: AlarmsFilters
  setFilters: (key: string, value: any) => void
  resetFilters: () => void
  applyFilters: () => void
}

export const AlarmsFilterView = ({
  resetFilters,
  applyFilters,
  selectedFilter,
  setFilters,
  locale
}: AlarmsFilterViewProps) => {
  const {state} = useAppState()
  const {t} = useTranslation(['alarms', 'tasks'])
  const {filters} = state.v1.alarms

  return (
    <>
      <InputField>
        <label htmlFor="triggeredTime">{t('alarms:labels.filter.triggeredTimeHeading', 'Triggered time')}</label>
        <DatePicker
          id="triggeredTime"
          onChange={setFilters}
          startDate={selectedFilter.startDate}
          endDate={selectedFilter.endDate}
          locale={locale}
        />
      </InputField>
      <InputField>
        <label htmlFor="alarmTypes">{t('alarms:labels.filter.alarmTypeHeading', 'Alarm type')}</label>
        <Select
          id="alarmTypes"
          onChange={value => setFilters('alarmTypes', value)}
          value={selectedFilter.alarmTypes}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={filters.alarmTypes.map(v => ({value: v, label: alarmTypeToTranslation(t, v)}))}
          multiple
        />
      </InputField>
      {/* <InputField>
        <label htmlFor="completedBy">{t('tasks:labels.columnNameCompletedBy', 'Completed By')}</label>
        <Select
          id="completedBy"
          onChange={(value: any) => setFilters('completedBy', value)}
          value={selectedFilter.completedBy}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={filters.completedBy.map(value => ({value: value.id, label: value.name}))}
          multiple
        />
      </InputField> */}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button buttonStyle="secondary" buttonSize="small" onClick={resetFilters}>
          {t('tasks:buttons.resetFilters', 'Reset')}
        </Button>
        <Button buttonStyle="primary" buttonSize="small" onClick={applyFilters}>
          {t('tasks:buttons.applyFilters', 'Apply filters')}
        </Button>
      </div>
    </>
  )
}
