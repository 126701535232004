import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconWarm = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 24.4296H43V25.0681C43 35.464 34.4941 43.8902 24 43.8902C13.5072 43.8902 5 35.464 5 25.0681V24.4296ZM6.3007 25.7067C6.63994 35.1013 14.4349 42.6131 24 42.6131C33.5664 42.6131 41.3601 35.1014 41.6993 25.7067H6.3007Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.9528 45.9995H17V44.7395H30.9528V45.9995Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8181 22.4301V13.6985H17.0781V22.4301H15.8181Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5867 22.4298V8.02148H20.8467V22.4298H19.5867Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3555 22.4299V2H24.6155V22.4299H23.3555Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.1248 22.4304V11.8176H28.3848V22.4304H27.1248Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.8938 22.4305V15.119H32.1538V22.4305H30.8938Z" />
    </svg>
  )
}
