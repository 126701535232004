import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconArrowheadDownSmall = (props: SharedIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props} fill="none">
      <path d="M2 7L12 17L22 7" stroke={props.fill || colors.brand.cobalt} />
    </svg>
  )
}
