import React, {useState, useEffect, useRef} from 'react'
import {colors} from '../../sharedComponents/colors'
import {useAppState} from '../../state'
import {AdjustableContainer, Center} from '../Layout/Layout'
import {Text} from './Typography'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Asset} from '../../state/home/site/state'

interface VideoPlayerProps {
  id: string
  containerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

const Video = styled.video`
  display: block;
`
export const VideoPlayer = ({id, containerProps}: VideoPlayerProps) => {
  const {actions} = useAppState()
  const [url, setUrl] = useState<string | null>(null)
  useEffect(() => {
    try {
      actions.getDownloadUrl(id).then(d => setUrl(d.downloadURL))
    } catch (e) {
      console.error(e)
    }
  }, [id, actions])

  const container = useRef<HTMLDivElement>(null)
  const width = container.current ? container.current.clientWidth : '300px'

  return (
    <div ref={container} {...containerProps}>
      {url ? (
        <Video controls width={width}>
          <source src={url}></source>
        </Video>
      ) : null}
    </div>
  )
}
interface SmartAssetProps {
  asset: Asset
}
export const SmartAsset = ({asset}: SmartAssetProps) => {
  if (asset && asset.assetMimeType) {
    return asset.assetMimeType.includes('image') ? <SmartImage id={asset.id} /> : <VideoPlayer id={asset.id} />
  }
  return <></>
}

interface SmartImageProps {
  id: string
}
export const SmartImage = ({id}: SmartImageProps) => {
  const {actions} = useAppState()
  const [url, setUrl] = useState<string | null>(null)
  useEffect(() => {
    try {
      actions.getDownloadUrl(id).then(d => setUrl(d.downloadURL))
    } catch (e) {
      console.error(e)
    }
  }, [id, actions])

  return url ? <img style={{width: '100%'}} src={url} alt="an alternative text" /> : <AssetPlaceholder />
}

const AssetPlaceholder = () => {
  const {t} = useTranslation()
  return (
    <AdjustableContainer height="300px" style={{backgroundColor: colors.system.lightGrey_5}}>
      <Center>
        <Text level={1}>{t('common:general.loading', 'Loading...')}</Text>
      </Center>
    </AdjustableContainer>
  )
}
