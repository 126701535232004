import {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigation} from 'react-navi'
import {useRestaurantSelect} from '../../../config/utils'
import {useAppState} from '../../../state'
import {Equipment, EquipmentTypeKey, FixedEquipmentTypes, SensorChannel, SensorRole} from '../../../state/rest'
import {Site} from '../../../state/state'
import {useStorageTypeSelect} from './EquipmentTypeUtils'
import {useDishwasherTypeSelect} from './DishwasherTypeUtils'
import {useCoolerTypeSelect} from './CoolerTypeUtils'
import {AvailableSensorDTO, LoraWANSensors, UpdateEquipmentSensor} from 'state/settings/appliances/state'

interface PossibleFormFields {
  id?: string
  name: string
  model: string
  installationYear: number
  sensorEnabled: boolean
  suspended: boolean
  wasteScaleCategory: string // actually the id
  dishwasherType: string // actually the id
  coolerType: string // actually the id
  alarmDelayCycles?: number
}
export const useEquipmentForm = (typeKey: string, saved?: Partial<Equipment>) => {
  const {state, actions} = useAppState()
  const [modalOpen, setModalOpen] = useState(false)
  const {navigate} = useNavigation()

  const isEditing = !!saved

  const backPath = isEditing ? '/appliance' : '/create/appliance'

  async function goBack() {
    navigate('/appliance')
  }

  const rc = useRestaurantSelect(saved ? saved.site : undefined)

  const {setValue, register, watch, errors, triggerValidation, formState} = useForm<Partial<PossibleFormFields>>({
    defaultValues: {
      ...saved,
      wasteScaleCategory: saved?.wasteScaleCategory?.id,
      dishwasherType: saved?.dishwasherType?.id,
      coolerType: saved?.coolerType?.id,
      alarmDelayCycles: saved?.dishwasherCycleInfo?.alarmDelayCycles
    },
    mode: 'onChange'
  })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    register({name: 'suspended'})
    setValue('suspended', saved?.suspended || false)
  }, [])
  const selectedEquipmentType = saved && saved.equipmentType ? saved.equipmentType : state.equipmentTypeById[typeKey]
  const {storageTypeOptions, selectStorageType, selectedStorageType} = useStorageTypeSelect(
    rc.selectedSite!,
    typeKey,
    saved ? saved.storageType : undefined
  )
  const {dishwasherTypeOptions, selectDishwasherType, selectedDishwasherType} = useDishwasherTypeSelect(
    rc.selectedSite!,
    saved ? saved.dishwasherType : undefined
  )
  const {coolerTypeOptions, selectCoolerType, selectedCoolerType} = useCoolerTypeSelect(
    rc.selectedSite!,
    saved ? saved.coolerType : undefined
  )

  const showStorageTypeSelect = () => {
    return selectedEquipmentType.id === 'storage' || selectedEquipmentType.id === 'freezer'
  }
  const showWasteCategoriesSelect = () => {
    return selectedEquipmentType.id === 'waste-scale'
  }
  const showDishwasherTypeSelect = () => {
    return selectedEquipmentType.id === 'dishwasher'
  }
  const showCoolerTypeSelect = () => {
    return selectedEquipmentType.id === 'cooler'
  }
  const showPrewash = () => {
    const sequenceType = selectedDishwasherType?.sequenceType || ''
    const min = selectedDishwasherType?.prewashMinValue
    return !!sequenceType.match(/PREWASH/) && min !== null
  }
  const showWash = () => {
    const sequenceType = selectedDishwasherType?.sequenceType || ''
    const min = selectedDishwasherType?.washMinValue
    return !!sequenceType.match(/(^|[^E])WASH/) && min != null // a bit stupid since just looking for 'WASH' would match PREWASH too
  }
  const showRinse = () => {
    const sequenceType = selectedDishwasherType?.sequenceType || ''
    const min = selectedDishwasherType?.rinseMinValue
    return !!sequenceType.match(/RINSE/) && min !== null
  }
  const showCoolerSlow = () => {
    const cycleType = selectedCoolerType?.cycleType || ''
    const target = selectedCoolerType?.slowCycleTargetValue
    return !!cycleType.match(/SLOW/) && target !== null
  }
  const showCoolerFast = () => {
    const cycleType = selectedCoolerType?.cycleType || ''
    const target = selectedCoolerType?.fastCycleTargetValue
    return !!cycleType.match(/FAST/) && target !== null
  }

  const [sensorEnabled, setSensorEnabled] = useState(
    saved && saved.sensorEnabled !== undefined ? saved.sensorEnabled : false
  )

  const {selectedSensors, selectedSensor, selectSensor, resetSelectedSensors, sensorOptions} = useSensorSelect(
    rc.selectedSite,
    saved
  )
  const requiredSensorsSelected = () => {
    if (!sensorEnabled) return true
    let numberOfRequiredSensors = 0
    if (showPrewash()) numberOfRequiredSensors++
    if (showWash()) numberOfRequiredSensors++
    if (showRinse()) numberOfRequiredSensors++
    if (numberOfRequiredSensors === 0) {
      numberOfRequiredSensors = 1
    }
    return selectedSensors && selectedSensors.length >= numberOfRequiredSensors
  }

  const [selectedAlarmSensorRoles, setSelectedAlarmSensorRoles] = useState(
    saved?.dishwasherCycleInfo?.alarmSensorRoles || []
  )
  const isAlarmSensorRoleSelected = (role: SensorRole) => {
    return selectedAlarmSensorRoles.indexOf(role) > -1
  }

  const form = watch()

  const update = async () => {
    const getUpdatedEquipmentSensors = (dtos: AvailableSensorDTO[]) => {
      if (dtos.length === 0) {
        return undefined
      }
      return dtos.map<UpdateEquipmentSensor>(dto => ({
        id: dto.id,
        role: Boolean(dto.role) ? dto.role : null,
        devEui: dto.devEui || undefined
      }))
    }

    // TODO: though appliance device settings (dishwasherType/coolerType) are not touched, the state updates them.
    // This results in payload having f.ex. with dishwasher a dishwasherType and coolerType
    const payload = {
      name: form.name!,
      model: form.model,
      equipmentType: selectedEquipmentType ? selectedEquipmentType.id : undefined,
      installationYear: typeof form.installationYear === 'number' ? form.installationYear : undefined,
      sensorEnabled: typeof form.sensorEnabled === 'boolean' ? form.sensorEnabled : undefined,
      storageType:
        selectedEquipmentType.id === EquipmentTypeKey.STORAGE || selectedEquipmentType.id === EquipmentTypeKey.FREEZER
          ? selectedStorageType?.id
          : undefined,
      wasteScaleCategory: selectedEquipmentType.id === EquipmentTypeKey.WASTE ? form.wasteScaleCategory : undefined,
      dishwasherType: selectedEquipmentType.id === EquipmentTypeKey.DISHWASHER ? selectedDishwasherType?.id : undefined,
      dishwasherCycleInfo:
        selectedEquipmentType.id === FixedEquipmentTypes.Dishwasher && selectedAlarmSensorRoles.length > 0
          ? {alarmDelayCycles: form.alarmDelayCycles, alarmSensorRoles: selectedAlarmSensorRoles}
          : undefined,
      coolerType: selectedEquipmentType.id === EquipmentTypeKey.COOLER ? selectedCoolerType?.id : undefined,
      sensors: form.sensorEnabled ? getUpdatedEquipmentSensors(selectedSensors) : []
    }

    try {
      await actions.v1.settings.appliances.updateApplianceSetting({
        applianceId: saved!.id!,
        siteId: rc.selectedSite!.id,
        payload
      })
      goBack()
    } catch (error) {
      const err = error as any
      console.error(`Error updating appliance, reason: ${err?.message || err}`)
    }
  }

  const submit = async () => {
    try {
      setLoading(true)
      await actions.createEquipment({
        name: form.name,
        model: form.model,
        installationYear: form.installationYear ? form.installationYear : 0,
        sensorEnabled: form.sensorEnabled ? form.sensorEnabled : false,
        sensors:
          selectedSensors && form.sensorEnabled
            ? selectedSensors.map(sensor => ({
                ...sensor,
                role: Boolean(sensor.role) ? sensor.role : null
              }))
            : [],
        equipmentType: selectedEquipmentType ? selectedEquipmentType.id : undefined,
        storageType:
          selectedEquipmentType.id === EquipmentTypeKey.STORAGE ||
          (selectedEquipmentType.id === EquipmentTypeKey.FREEZER && selectedStorageType)
            ? selectedStorageType.id
            : undefined,
        site: rc.selectedSite,
        suspended: form.suspended,
        wasteScaleCategory:
          selectedEquipmentType.id === FixedEquipmentTypes.WasteScale ? form.wasteScaleCategory : undefined,
        dishwasherType:
          selectedEquipmentType.id === FixedEquipmentTypes.Dishwasher && selectedDishwasherType
            ? selectedDishwasherType.id
            : undefined,
        dishwasherCycleInfo:
          selectedEquipmentType.id === FixedEquipmentTypes.Dishwasher && selectedDishwasherType
            ? {alarmDelayCycles: form.alarmDelayCycles, alarmSensorRoles: selectedAlarmSensorRoles}
            : undefined,
        coolerType:
          selectedEquipmentType.id === FixedEquipmentTypes.Cooler && selectedCoolerType
            ? selectedCoolerType.id
            : undefined
      })
      goBack()
    } catch (error) {
      const err = error as any
      console.error(`Error creating appliance, reason: ${err?.message || err}`)
    } finally {
      setLoading(false)
    }
  }

  const remove = async () => {
    try {
      setLoading(true)
      const s = saved?.site?.id ? saved.site.id : ''
      await actions.deleteEquipment({applianceId: saved?.id!, siteId: s})
      goBack()
    } catch (error) {
      const err = error as any
      console.error(`Error deleting appliance, reason: ${err?.message || err}`)
    } finally {
      setLoading(false)
    }
  }

  const cancel = () => {
    goBack()
  }

  const toggleAlarmSensorRole = (role: SensorRole) => {
    if (selectedAlarmSensorRoles.indexOf(role) === -1) {
      setSelectedAlarmSensorRoles(selectedAlarmSensorRoles.concat(role))
    } else {
      setSelectedAlarmSensorRoles(selectedAlarmSensorRoles.filter(r => r !== role))
    }
  }

  return {
    register,
    update,
    submit,
    remove,
    cancel,
    form,
    errors,
    triggerValidation,
    hasErrors: !formState.isValid,
    formState,
    loading,
    backPath,
    storageTypeOptions,
    dishwasherTypeOptions,
    coolerTypeOptions,
    selectStorageType,
    selectDishwasherType,
    selectCoolerType,
    selectedStorageType,
    selectedDishwasherType,
    selectedCoolerType,
    selectedEquipmentType,
    sensorEnabled,
    setSensorEnabled,
    selectedSensors,
    selectSensor,
    resetSelectedSensors,
    selectedSensor,
    sensorOptions,
    requiredSensorsSelected,
    modalOpen,
    setModalOpen,
    showStorageTypeSelect,
    showWasteCategoriesSelect,
    showDishwasherTypeSelect,
    showCoolerTypeSelect,
    showPrewash,
    showWash,
    showRinse,
    showCoolerSlow,
    showCoolerFast,
    restaurantController: rc,
    setValue,
    isAlarmSensorRoleSelected,
    toggleAlarmSensorRole
  }
}

const useSensorSelect = (site?: Site, appliance?: Partial<Equipment>) => {
  const {actions} = useAppState()
  const [availableSensors, setAvailableSensors] = useState<AvailableSensorDTO[]>([])
  const [selected, setSelected] = useState<AvailableSensorDTO[]>([])

  const getOptionTitle = (dto: AvailableSensorDTO) =>
    dto.devEui ? `LoraWan: ${dto.devEui}` : `Nokeval: ${dto.sensorNumber} (${dto.channel})`

  const sensorOptions = availableSensors.map(dto => ({
    option: getOptionTitle(dto),
    id: `${dto.id}`
  }))

  const selectMethod = useCallback(
    (id: string, role: SensorRole) => {
      const selectedOne = availableSensors.find(sensor => sensor.id === id)
      if (!selectedOne) return // weird, selected id which not in the options

      let selectedArray
      if (role === SensorRole.singleSensor || role === SensorRole.default) {
        selectedArray = selectedOne.devEui
          ? [{...selectedOne, role: SensorRole.default}]
          : [{...selectedOne, role: role}]
      } else {
        const previouslySelected = selected ? selected : []
        // If same role is already selected, remove the sensor from selected and set to role as null
        const freedSensor = previouslySelected.find(sensor => sensor.role === role)
        const freedSensorArray = freedSensor ? [{...freedSensor, role: null}] : []
        const newAvailableSensors = availableSensors
          .filter(sensor => sensor.id !== freedSensor?.id)
          .concat(freedSensorArray)
          .filter(sensor => sensor.id !== selectedOne.id)
          .concat([{...selectedOne, role: role}])
        setAvailableSensors(newAvailableSensors)

        selectedArray = previouslySelected
          .filter(sensor => sensor.id !== freedSensor?.id && sensor.id !== selectedOne.id)
          .concat([{...selectedOne, role: role}])
      }
      setSelected(selectedArray)
    },
    [availableSensors, selected]
  )

  const resetSelectedSensors = () => {
    setSelected([])
  }

  const selectedSensor = useCallback(
    (role: SensorRole) => {
      return selected?.find(sensor => (role === SensorRole.singleSensor ? !sensor.role : sensor.role === role))
    },
    [selected]
  )

  const getAvailableSensorDTO = (
    sensorChannels: SensorChannel[] | undefined,
    sensors: LoraWANSensors[] | undefined
  ) => {
    const channelDTOs = sensorChannels
      ? sensorChannels.map<AvailableSensorDTO>(channel => ({
          id: channel.id,
          channel: channel.channel,
          devEui: null,
          sensorNumber: channel.sensor.sensorId,
          role: channel?.sensorChannelRole || null
        }))
      : []
    const sensorDTOs = sensors
      ? sensors.map<AvailableSensorDTO>(sensor => ({
          id: sensor.wirelessDeviceId,
          channel: null,
          devEui: sensor.devEui,
          sensorNumber: null,
          role: sensor.role || null
        }))
      : []
    return channelDTOs.concat(sensorDTOs)
  }

  useEffect(() => {
    const update = async () => {
      let applianceSelectedSensors = null
      if (site && appliance?.id) {
        const applianceSensorChannels = appliance?.sensorChannels || []
        const applianceSensors = appliance?.sensors || []
        applianceSelectedSensors = getAvailableSensorDTO(applianceSensorChannels, applianceSensors)
        setSelected(applianceSelectedSensors)
      }
      if (site) {
        const response = await actions.v1.settings.appliances.getAvailableSensors({siteId: site.id})
        if (applianceSelectedSensors !== null) {
          const all = applianceSelectedSensors.concat(response?.items || [])
          setAvailableSensors(all)
        } else {
          setAvailableSensors(response?.items || [])
        }
      }
    }
    update()
  }, [site, appliance])

  return {
    selectedSensors: selected,
    selectSensor: selectMethod,
    resetSelectedSensors,
    selectedSensor,
    sensorOptions
  }
}
