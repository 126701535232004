import React, {FC, useState, ReactElement} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

import {Baseline} from '../../Layout/Grid'
import {IconVideo} from '../../../Assets/Icons/TinyIcons'
import {IconText} from '../../Atoms/IconText'
import {IconButton} from '../../Atoms/Buttons'
import {IconCloseSmall} from '../../../Assets/Icons/TinyIcons'
import CenteredModal, {WideModalContent} from '../../Molecules/Modals/Modals'

import AddAsset from './AddAsset'
import {AssetWidgetProps, AssetTabNavigatorProps, AssetCategory} from './types'
import {AssetData, AssetType} from '../../../state/rest'

const AssetItem = styled.li`
  display: flex;
  align-items: center;
  .icon {
    width: 15px;
    height: 15px;
    border: 1px solid gray;
  }
  input.assetItem {
    margin-left: 1rem;
    margin-right: 1rem;
    outline: none;
    border: none;
    min-width: 150px;
    &::placeholder {
      color: gray;
    }
  }
  .removeIcon {
  }
`

const AssetList = styled.ul`
  margin: 0;
  padding: 0;
`

export const AssetGroup = styled.div`
  padding-left: 1rem;
`

const AssetWidget: FC<AssetWidgetProps> = (props): ReactElement => {
  const {t} = useTranslation('assets')
  const [showUploader, toggleShowUploader] = useState(false)

  const onAddClick = (asset?: AssetData) => {
    if (asset) {
      props.handleAdd(asset)
      toggleShowUploader(!showUploader)
    }
  }

  const createAssetList = () => {
    return (
      <AssetList>
        {props.savedAssets.map(asset => (
          <AssetItem key={asset.id}>
            {asset.name}
            <IconButton
              icon={<IconCloseSmall />}
              buttonProps={{
                className: 'removeIcon',
                onClick: () => {
                  props.handleRemove(asset.id)
                }
              }}
            />
          </AssetItem>
        ))}
      </AssetList>
    )
  }
  const tabContents: AssetTabNavigatorProps[] = [
    {title: t('assets:labels.videos', 'Videos'), assetType: AssetType.VIDEO},
    {title: t('assets:labels.photos', 'Photos'), assetType: AssetType.PHOTO}
  ]

  return (
    <Baseline>
      <IconText
        icon={<IconVideo />}
        text={
          !props.savedAssets.length
            ? t('assets:labels.addImageOrVideo', 'Add image or video')
            : createAssetList()
        }
        onClick={e => toggleShowUploader(!props.savedAssets.length && !showUploader)}
      />
      <CenteredModal isOpen={showUploader}>
        <WideModalContent>
          <AddAsset
            label={t('assets:messages.selectPhotoGuide', {
              defaultValue:
                'Select from existing photos and videos, or upload a new file. You can use e.g. jpg, png, gif or mp4 file formats.'
            })}
            title={t('assets:labels.addImageOrVideo', 'Add image or video')}
            acceptedTypes="image/*, video/*, video/mp4, video/x-m4v"
            tabContents={tabContents}
            handleClose={toggleShowUploader}
            handleAddSelected={onAddClick}
            assetCategory={AssetCategory.task}
          />
        </WideModalContent>
      </CenteredModal>
    </Baseline>
  )
}

export default AssetWidget
