import React from 'react'
import styled from 'styled-components'
import {Title, SharedIcon} from '../../../sharedComponents'
import {colors} from '../../../sharedComponents/colors'

import {taskGroupIconPicker} from '../utils'
import {PerformableTask} from '../../../state/performTask/state'
import CoolingProgress, {getCoolingDurationInMinutes, isCoolingInProgress} from './CoolingProgress'

interface Props {
  task: PerformableTask
}

const PerformTaskTitle = ({task}: Props) => {
  const isInProgressCooling =
    isCoolingInProgress(task.taskType.id, task.status) &&
    (task.taskType.id === 'automaticCooling' ? !task.cycleLog : true)
  const isCoolingTask = task.taskType.id === 'cooling' || task.taskType.id === 'automaticCooling'
  const taskTitle = isCoolingTask ? `${task.name} - ${getCoolingDurationInMinutes(task)} min` : task.name

  return (
    <TaskTitleContainer>
      <TaskTitle>
        <SharedIcon
          icon={taskGroupIconPicker(task.taskGroup.icon)}
          height="2.5em"
          width="2.5em"
          fill={colors.brand.cobalt}
        />
        <Title level={5} strong>
          {taskTitle}
        </Title>
      </TaskTitle>
      {isInProgressCooling && (
        <CoolingProgressContainer>
          <CoolingProgress
            automaticCoolingEndTime={task.cycleLog?.endedAt}
            startTime={task.coolingStartedAt || task.startTime!}
            targetTime={task.targetTime!}
            timeLimit={task.coolingTimeLimit!}
            latestValue={task.latestValue?.value}
            alarms={task.alarms}
          />
        </CoolingProgressContainer>
      )}
    </TaskTitleContainer>
  )
}

const TaskTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${colors.system.lightGrey_5};
`

const TaskTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const CoolingProgressContainer = styled.div`
  margin-left: 1rem;
`

export default PerformTaskTitle
