import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {colors} from '../../../sharedComponents/colors'

import {ProgressBar} from '../../Atoms/ProgressBar'
import {Text, Title} from '../../../sharedComponents/components'

const AlarmInfoContainer = styled.div<AlarmsInfoContainerProps>`
  text-align: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  display: inline-grid;
  grid-template-rows: 12.5rem auto;
  grid-row-gap: 0.5rem;
  width: 12.5rem;
  color: ${colors.brand.cobalt};
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
`

interface AlarmsInfoContainerProps {
  onClick?: (e: React.MouseEvent) => void
}

const ProgressBarContainer = styled.div`
  position: relative;
  width: 11.25rem;
  height: 11.25rem;
  margin-top: auto;
  margin-bottom: 0.4rem;
`
const ProgressBarContent = styled.div`
  display: inline-grid;
  grid-template-rows: 2.75rem 1rem;
  grid-template-columns: 1fr;
  grid-row-gap: 0.75rem;
  justify-content: center;
  align-items: center;

  & > *:first-child {
    grid-column: 1 / 3;
    grid-row: 1;
  }
`

interface AlarmsInfoProps {
  alarms: number
  onClick?: (e: React.MouseEvent) => void
}
const AlarmsInfo = ({alarms, onClick}: AlarmsInfoProps) => {
  const {t} = useTranslation('home')

  const getPercentage = () => {
    if (alarms > 0) {
      return 100
    }
    return 0
  }

  return (
    <AlarmInfoContainer onClick={onClick}>
      <ProgressBarContainer>
        <ProgressBar
          percentage={getPercentage()}
          strokeWidth={4}
          pathColor={colors.system.red}
          trailColor={colors.system.lightGrey_10}
        >
          <ProgressBarContent>
            <Title level={1} data-cy="alarm-count">
              {alarms}
            </Title>
            <>
              <Text size="M">{t('home:labels.activeAlarms', 'active')}</Text>
            </>
          </ProgressBarContent>
        </ProgressBar>
      </ProgressBarContainer>
      <Title level={4}>{t('home:labels.alarmsTitle', 'Alarms')}</Title>
    </AlarmInfoContainer>
  )
}
AlarmsInfo.defaultProps = {
  variant: 'default'
}

export default AlarmsInfo
