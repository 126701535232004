import React, {useState} from 'react'
import {
  Text,
  Table,
  ColumnDef,
  Select,
  Button,
  SelectOption,
  Icon,
  SharedIcon,
  SmallIconPalette
} from '../../sharedComponents/components'
import {colors} from '../../sharedComponents/colors'

import {isToday} from 'date-fns'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {useAppState} from '../../state'
import {InputField} from './CompletedTasks'
import {getHumanizedTimeString, parseStringToDate, getHumanizedDateWithTime} from '../../Components/Atoms/Utils'
import {TaskModal} from './TaskModal'
import {PerformableTask} from '../../state/performTask/state'
import CoolingProgress, {isCoolingInProgress, CoolingWarning} from './GeneralTasks/CoolingProgress'
import {translateTaskGroupName, translateTaskType, hasCoolingEventTarget} from '../../config/utils'
import {TaskGroupIcon, BlockLabelText} from '../home/Home'

interface TodoTasksViewProps {
  locale: string
  loadMoreLateTasks: () => void
  loadMoreTodaysTasks: () => void
}

export const hasInprogressTodo = (subtasks?: string[], doneSubtasks?: string[]) =>
  subtasks && subtasks.length > 0 && doneSubtasks && doneSubtasks.length > 0

export const TodoTasksView = (props: TodoTasksViewProps) => {
  const {state, actions} = useAppState()
  const {t} = useTranslation('tasks')

  const {locale, loadMoreLateTasks, loadMoreTodaysTasks} = props
  const {todoTasks} = state.v1.tasks

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [task, setTask] = useState<PerformableTask | null>(null)

  const todoTasksColumnDefs = [
    {
      title: undefined,
      dataKeys: [
        'taskGroup',
        'taskType',
        'status',
        'coolingStartedAt',
        'startTime',
        'targetTime',
        'alarms',
        'cycleLog',
        'latestValue',
        'coolingTimeLimit'
      ],
      widthPercentage: '4.5rem',
      component: (props: any) => (
        // TODO: replace 40+ task group icons in the shared components (same in the task setting page)
        <div style={{display: 'flex', justifyContent: 'center', paddingLeft: '1rem'}}>
          {isCoolingInProgress(props?.values[1].id, props?.values[2]) && !props.values[7] ? (
            <CoolingProgress
              startTime={props.values[3] || props.values[4]}
              automaticCoolingEndTime={props.values[7]?.endedAt}
              targetTime={props.values[5]}
              timeLimit={props.values[9]}
              alarms={props.values[6]}
              circleWidth="3em"
              iconWidth="2em"
              fontSize="XXS"
              temperatureValue={props.values[8]?.value}
            />
          ) : (
            <TaskGroupIcon taskGroup={props.values[0]} />
          )}
        </div>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskGroup', 'Task group'),
      dataKeys: ['taskGroup'],
      widthPercentage: '15%',
      component: (props: any) => (
        <Text size="S" strong={props.selected} textTransform="capitalize">
          {translateTaskGroupName(props.values[0].name)}
        </Text>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskName', 'Name'),
      dataKeys: [
        'name',
        'taskType',
        'status',
        'targetTime',
        'coolingTimeLimit',
        'alarms',
        'suggestions',
        'subtasks',
        'doneSubtasks'
      ],
      component: (props: any) => (
        <div>
          <div style={{margin: '0.25rem 0'}}>
            <Text size="S" strong={props.selected}>
              {props.values[0]}
              {props.values[4] && ` - ${props.values[4]} min`}
            </Text>
            {hasCoolingEventTarget(props.values[1], props.values[6]) && (
              <BlockLabelText size="XS">{props.values[6][0]}</BlockLabelText>
            )}
          </div>
          {isCoolingInProgress(props?.values[1].id, props?.values[2]) && (
            <CoolingWarning
              taskTypeId={props?.values[1].id}
              targetTime={props?.values[3]}
              alarms={props?.values[5]}
              hasMeasurementTarget={hasCoolingEventTarget(props.values[1], props.values[6])}
            />
          )}
          {hasInprogressTodo(props.values[7], props.values[8]) && (
            <InprogressTodo subtasks={props.values[7]} doneSubtasks={props.values[8]} selected={props.selected} />
          )}
        </div>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskType', 'Type'),
      dataKeys: ['taskType'],
      widthPercentage: '15%',
      component: (props: any) => (
        <Text size="S" strong={props.selected} textTransform="capitalize">
          {props.values ? translateTaskType(props.values[0].id) : '-'}
        </Text>
      )
    },
    {
      title: t('tasks:labels.columnNameDateTime', 'Date & time'),
      dataKeys: ['scheduleInstance', 'createdAt'],
      widthPercentage: '12%',
      component: (props: any) => (
        <Text size="S" strong={props.selected}>
          {isToday(parseStringToDate(props.values[0] || props.values[1]))
            ? getHumanizedTimeString(parseStringToDate(props.values[0] || props.values[1]), locale)
            : getHumanizedDateWithTime(parseStringToDate(props.values[0] || props.values[1]), locale)}
        </Text>
      )
    }
  ] as ColumnDef[]

  const handleClickRow = async (task: any) => {
    const {id, eventId, scheduleInstance} = task
    const data = await actions.v1.performTask.getTask({
      siteId: state.site!.id,
      taskId: id,
      eventId: eventId
    })
    if (data) {
      setTask({...data, scheduleInstance})
      setIsModalOpen(true)
    }
  }

  return (
    <div style={{height: '100%', marginTop: '-1px'}}>
      {!!task && <TaskModal task={task} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
      <div>
        <Table columnDefs={todoTasksColumnDefs} />
      </div>
      <div style={{height: '100%', overflow: 'scroll'}}>
        <TableDivider>
          <Text size="S" strong>
            {`${t('tasks:taskList.lateTasks.title', 'Late')} (${todoTasks.data.lateTasks.count})`}
          </Text>
        </TableDivider>
        <div>
          <Table
            columnDefs={todoTasksColumnDefs}
            data={todoTasks.data.lateTasks.tasks}
            lastRow={RenderLastRow(todoTasks.data.lateTasks, loadMoreLateTasks)}
            onClickRow={handleClickRow}
            rowHoverBackgroundColor={colors.system.white}
            hideHeader
            data-cy="table-lateTasks"
          />
        </div>
        {todoTasks.data.inProgressTasks.count > 0 && (
          <>
            <TableDivider>
              <Text size="S" strong>
                {`${t('tasks:taskList.inProgressTasks.title', 'In Progress')} (${
                  todoTasks.data.inProgressTasks.count
                })`}
              </Text>
            </TableDivider>
            <div>
              <Table
                columnDefs={todoTasksColumnDefs}
                data={todoTasks.data.inProgressTasks.tasks}
                onClickRow={handleClickRow}
                rowHoverBackgroundColor={colors.system.white}
                hideHeader
                data-cy="table-inProgressTasks"
              />
            </div>
          </>
        )}
        <TableDivider>
          <Text size="S" strong>
            {`${t('tasks:taskList.todaysTasks.title', 'Today')} (${todoTasks.data.todaysTasks.count})`}
          </Text>
          <Text size="S" style={{paddingRight: `calc(12% - 73px)`}}>
            {new Date().toLocaleDateString(locale)}
          </Text>
        </TableDivider>
        <div>
          <Table
            columnDefs={todoTasksColumnDefs}
            data={todoTasks.data.todaysTasks.tasks}
            lastRow={RenderLastRow(todoTasks.data.todaysTasks, loadMoreTodaysTasks)}
            onClickRow={handleClickRow}
            rowHoverBackgroundColor={colors.system.white}
            hideHeader
            data-cy="table-todaysTasks"
          />
        </div>
      </div>
    </div>
  )
}

export const TableDivider = styled.div`
  background-color: ${colors.system.grey_5};
  border-radius: 0.5rem;
  height: 2rem;
  padding: 0 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export interface TodoFilters {
  taskTypes: SelectOption[]
  taskGroups: SelectOption[]
}

interface TodoFilterViewProps {
  selectedFilter: TodoFilters
  setFilters: (key: string, value: any) => void
  resetFilters: () => void
  applyFilters: () => void
}

export const TodoFilterView = (props: TodoFilterViewProps) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const {filters} = state.v1.tasks
  const {resetFilters, applyFilters, selectedFilter, setFilters} = props

  return (
    <>
      <InputField data-cy="filter-taskGroups">
        <label htmlFor="taskGroups">
          <Text size="S">{t('tasks:labels.columnNameTaskGroup', 'Task group')}</Text>
        </label>
        <Select
          id="taskGroups"
          onChange={value => setFilters('taskGroups', value)}
          value={selectedFilter.taskGroups}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={filters.taskGroups.map(value => ({value: value.id, label: translateTaskGroupName(value.name)}))}
          multiple
        />
      </InputField>
      <InputField data-cy="filter-taskTypes">
        <label htmlFor="taskTypes">
          <Text size="S">{t('tasks:labels.columnNameTaskType', 'Type')}</Text>
        </label>
        <Select
          id="taskTypes"
          onChange={value => setFilters('taskTypes', value)}
          value={selectedFilter.taskTypes}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={filters.taskTypes.map(value => ({value: value, label: translateTaskType(value)}))}
          multiple
        />
      </InputField>
      <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '0.5rem'}}>
        <Button buttonStyle="secondary" buttonSize="small" onClick={resetFilters} data-cy="button-reset">
          {t('tasks:buttons.resetFilters', 'Reset')}
        </Button>
        <Button buttonStyle="primary" buttonSize="small" onClick={applyFilters} data-cy="button-apply">
          {t('tasks:buttons.applyFilters', 'Apply filters')}
        </Button>
      </div>
    </>
  )
}

const RenderLastRow = (lateOrTodayTasks: any, loadMoreData: any) => {
  const {t} = useTranslation('tasks')
  const {count, tasks, loadingMoreTasks} = lateOrTodayTasks
  const loading = loadingMoreTasks
  const hasMoreTasks = tasks && count > tasks.length
  if (!loading && hasMoreTasks) {
    return (
      <div style={{margin: '0.5rem 0'}}>
        <LoadMoreButton onClick={loadMoreData} />
      </div>
    )
  } else if (loading) {
    return (
      <div style={{margin: '1.5rem 0'}}>
        <Text size="S">{t('tasks:labels.loading', 'Loading...')}</Text>
      </div>
    )
  } else if (!hasMoreTasks) {
    return (
      <div style={{margin: '1.5rem 0'}}>
        <Text size="S">{t('tasks:labels.noMoreLoading', 'No more to load')}</Text>
      </div>
    )
  } else {
    return null
  }
}

export interface InprogressTodoProps {
  subtasks: string[]
  doneSubtasks: string[]
  selected: boolean
}

export const InprogressTodo = (props: InprogressTodoProps) => {
  const IconContainer = styled.div`
    margin: 0 0.5rem 0 0;
  `

  return (
    <div style={{display: 'flex'}}>
      <IconContainer>
        <SharedIcon icon={SmallIconPalette.Checkedbox} width="0.75em" height="0.75em" fill={colors.brand.cobalt} />
      </IconContainer>
      <Text size="S" strong={props.selected}>
        {`${props.doneSubtasks.length}/${props.subtasks.length}`}
      </Text>
    </div>
  )
}

export const LoadMoreButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = props => {
  const {t} = useTranslation('common')

  return (
    <Button buttonSize="small" buttonStyle="secondary" {...props}>
      <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        <Icon type="arrowhead-down-small" />
        <Text size="S">{t('common:labels.loadMore', 'Load more')}</Text>
      </div>
    </Button>
  )
}
