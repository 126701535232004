import React from 'react'
import {A11yListContainer, A11yIconListItem} from '../../Atoms/Lists'

import {Chain} from '../../../state/state'
import {getSites} from '../../../config/utils'
import DoubleComponent from '../../Molecules/ListComponents/DoubleComponent'
import {IconSettings} from '../../../Assets/Icons/TinyIcons'
import {IconChain} from '../../../Assets/Icons/LargeIcons'
import {colors} from '../../../sharedComponents/colors'
import {Row} from '../../Layout/Grid'
import {IconButton} from '../../Atoms/Buttons'

interface ChainListProps {
  onConfigure?: (id: string) => void
  chains: Chain[]
  onSelectChain: (id: string) => void
}
const ChainList = ({onConfigure, chains, onSelectChain}: ChainListProps) => {
  return (
    <>
      <A11yListContainer>
        {chains
          .filter(c => getSites(c).length > 0)
          .map(c => (
            <A11yIconListItem
              icon={<IconChain />}
              onClick={() => onSelectChain(c.id)}
              key={c.id}
              action={
                <IconButton
                  buttonProps={{
                    onClick: e => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (onConfigure) {
                        onConfigure(c.id)
                      }
                    }
                  }}
                  icon={<IconSettings color={colors.system.grey_1} />}
                />
              }
              content={
                <Row justify="space-between" align="center" style={{minHeight: 60}}>
                  <DoubleComponent title={c.name} description={c.description} />
                </Row>
              }
            />
          ))}
      </A11yListContainer>
    </>
  )
}

export default ChainList
