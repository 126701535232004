import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigation} from 'react-navi'
import {useAppState} from '../../../../state'
import {AlarmSetting, WeekSchedule} from '../../../../state/rest'

import {ListItem, A11yListContainer} from '../../../Atoms/Lists'
import TripletComponent from '../../../Molecules/ListComponents/TripletComponent'

import {getFromToTimeString, getAlarmEndTimeView, parseISOTimeToDate, getHumanizedWeekdays} from '../../../Atoms/Utils'

import {getAlarmMethodName, getRecipientsNames} from './AlarmSettingsUtils'
import {find, orderBy} from 'lodash'

const AlarmNotifications = () => {
  const {state} = useAppState()
  const {alarmSettings} = state

  return (
    <A11yListContainer>
      {alarmSettings.length
        ? alarmSettings.map(alarmSetting => {
            return <NotificationListItem settings={alarmSetting} key={alarmSetting.id}></NotificationListItem>
          })
        : null}
    </A11yListContainer>
  )
}

interface ANLIProps {
  settings: AlarmSetting
}
const NotificationListItem = ({settings}: ANLIProps) => {
  const nav = useNavigation()
  const {state} = useAppState()
  const {t} = useTranslation('alarms')
  const weekdays = getHumanizedWeekdays(state.site!.locale)

  const getAlarmMethods = () => {
    return settings.alarmMethods.map(method => {
      return getAlarmMethodName(method)
    })
  }

  const getWeekdays = (schedule: WeekSchedule) => {
    if (schedule.weekdays) {
      return orderBy(
        schedule.weekdays.map(day => {
          return find(weekdays, ['id', day])!
        }),
        'order',
        'asc'
      )
        .map(day => {
          return day!.short
        })
        .join(', ')
    }
    return ''
  }

  const getTimePeriod = (schedule: WeekSchedule) => {
    if (schedule.from === '00:00:00' && schedule.to === '24:00:00') {
      return t('alarms:labels.allDay', 'All day')
    }
    const locale = 'fi-FI' // TODO tricky to guess which locale to use if alarm is allocated to multiple sites
    return getFromToTimeString(
      parseISOTimeToDate(schedule.from!),
      parseISOTimeToDate(getAlarmEndTimeView(schedule.to!)),
      locale
    )
  }

  const getAlarmSchedules = () => {
    const scheduleStrings: string[] = []

    settings.schedules.forEach(schedule => {
      scheduleStrings.push(getWeekdays(schedule))
      scheduleStrings.push(getTimePeriod(schedule))
    })

    return scheduleStrings
  }

  return (
    <ListItem onClick={() => nav.navigate(`/settings/alarms/edit/${settings!.id!}`)}>
      <>
        <TripletComponent
          title={settings.name}
          subTitles={getAlarmMethods()}
          captions={[
            getRecipientsNames([...settings.recipients, ...settings.extRecipients], 2),
            ...getAlarmSchedules()
          ]}
        />
      </>
    </ListItem>
  )
}

export default AlarmNotifications
