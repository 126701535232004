import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconBarcode = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 2V11.5532H3.25714V3.25716H11.8598V2H2Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.6152 2V3.25716H44.7439V11.5532H45.9998V2H36.6152Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 36.3098V46H11.8598V44.7441H3.25714V36.3098H2Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44.743 36.3098V44.7441H36.6143V46H46.0001V36.3098H44.743Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1711 34.2425H11.4283V13.7583H10.1711V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6853 34.2425H13.9424V13.7583H12.6853V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1997 34.2425H16.4569V13.7583H15.1997V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.9712 34.2425H20.2283V13.7583H18.9712V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5142 34.2425H27.7713V13.7583H26.5142V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.5701 34.2425H37.8285V13.7583H36.5701V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.0283 34.2425H31.5426V13.7583H29.0283V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.8 34.2425H35.3143V13.7583H32.8V34.2425Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7429 34.2425H25.2572V13.7583H22.7429V34.2425Z" />
    </svg>
  )
}
