import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconLocation = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3525 16.135C10.3525 9.03141 16.4633 3.27446 24.0007 3.27446C31.538 3.27446 37.6475 9.03133 37.6475 16.135C37.6475 18.6377 36.7884 21.6598 35.4571 24.8232C34.1304 27.9758 32.3567 31.2182 30.5755 34.1466C28.7953 37.0732 27.0142 39.6756 25.6776 41.5466C25.0096 42.4819 24.4532 43.2336 24.0643 43.751L24.0006 43.8355L23.937 43.751C23.548 43.2336 22.9916 42.4819 22.3235 41.5466C20.9868 39.6756 19.2055 37.0732 17.4252 34.1466C15.6438 31.2182 13.8699 27.9758 12.5431 24.8232C11.2117 21.6598 10.3525 18.6377 10.3525 16.135ZM23.4561 45.3042C23.4563 45.3044 23.4564 45.3045 24.0007 44.9263L24.545 45.3045L24.0007 46L23.4561 45.3042ZM23.4561 45.3042L24.0007 44.9263C24.545 45.3045 24.5457 45.3036 24.5458 45.3034L24.5562 45.2901L24.5878 45.2494C24.6155 45.2137 24.6563 45.1608 24.7094 45.0917C24.8156 44.9535 24.9707 44.75 25.1679 44.4877C25.5623 43.9631 26.1249 43.2029 26.7997 42.2583C28.1487 40.3697 29.9483 37.7406 31.7489 34.7803C33.5485 31.8218 35.3555 28.5219 36.7144 25.293C38.0687 22.0747 39 18.8761 39 16.135C39 8.32754 32.285 2 24.0007 2C15.7164 2 9 8.32746 9 16.135C9 18.8761 9.93135 22.0747 11.2858 25.293C12.6448 28.522 14.452 31.8219 16.2518 34.7804C18.0525 37.7406 19.8523 40.3697 21.2015 42.2583C21.8763 43.2029 22.4389 43.9631 22.8334 44.4877C23.0306 44.75 23.1858 44.9535 23.2919 45.0917C23.345 45.1609 23.3859 45.2137 23.4136 45.2494L23.4452 45.2901L23.4561 45.3042ZM19.4279 16C19.4279 13.4744 21.4756 11.4279 24 11.4279C26.5257 11.4279 28.5721 13.4743 28.5721 16C28.5721 18.5244 26.5256 20.5721 24 20.5721C21.4757 20.5721 19.4279 18.5243 19.4279 16ZM24 10C20.6872 10 18 12.6855 18 16C18 19.3129 20.6871 22 24 22C27.3145 22 30 19.3128 30 16C30 12.6857 27.3143 10 24 10Z"
      />
    </svg>
  )
}
