import {AsyncAction} from 'overmind'
import {v4 as uuid} from 'uuid'
import {PerformableTask, AlarmEntity, EventResponse} from './state'
import {Notification, NotificationType} from '../state'

import i from 'i18next'
type GetTaskParams = {siteId: string; taskId: string; eventId?: string}
export const getTask: AsyncAction<GetTaskParams, PerformableTask | undefined> = async (
  {actions, effects},
  params: GetTaskParams
) => {
  try {
    const task = await effects.v1.performTask.performTaskApi.getTask(params.siteId, params.taskId, params.eventId)
    return task
  } catch (err) {
    await i.loadNamespaces('msg')
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('msg:genericFormError', 'Cannot save the data'),
      description: i.t('msg:genericFormErrorDescription', 'Please check your input and retry.'),
      visible: true,
      hideAfterDelay: 6000
    })
  }
}

export const createEvent: AsyncAction<any, EventResponse | undefined> = async ({state, actions, effects}, payload) => {
  try {
    const e = await effects.v1.performTask.performTaskApi.createEvent(payload, state.site!.id, payload.scheduledAt)
    await actions.handleEventStateUpdate(e as any)
    await notifyOnAlarms(e.alarms, actions.addNotification)
    return e
  } catch (err) {
    await i.loadNamespaces('msg')
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('msg:genericFormError', 'Cannot save the data'),
      description: i.t('msg:genericFormErrorDescription', 'Please check your input and retry.'),
      visible: true,
      hideAfterDelay: 6000
    })
  }
}
export const updateEvent: AsyncAction<{id: string; payload: any}> = async (
  {state, actions, effects},
  {id, payload}
) => {
  try {
    const e = await effects.v1.performTask.performTaskApi.updateEvent(id, state.site!.id, payload)
    await actions.handleEventStateUpdate(e as any)
    await notifyOnAlarms(e.alarms, actions.addNotification)
  } catch (err) {
    await i.loadNamespaces('msg')
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('msg:genericFormError', 'Cannot save the data'),
      description: i.t('msg:genericFormErrorDescription', 'Please check your input and retry.'),
      visible: true,
      hideAfterDelay: 6000
    })
  }
}

const notifyOnAlarms = async (alarms: AlarmEntity[], notify: (value: Notification) => void) => {
  if (alarms?.length > 0) {
    await i.loadNamespaces('msg')
    notify({
      id: uuid(),
      type: NotificationType.DEFAULT,
      title: i.t('msg:alarmActivated', 'Alarm activated'),
      description: i.t('msg:alarmCreated', 'Task activated an alarm!'),
      visible: true,
      action: {
        text: i.t('common:buttons.resolveAlarm', 'Resolve alarm'),
        action: () => CallbackNavigateToAlarms,
        showClose: true
      }
    })
  }
}

const CallbackNavigateToAlarms = () => {
  console.debug('Callback: navigate to alarms')
  window.location.href = '/alarms' // TODO why is this refreshing the whole app instead just navigating to alarms...? data refresh?
}
