import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-navi-helmet-async'
import {ErrorMessage} from '../../Components/Molecules/Errors/ErrorMessage'
import {Loader as PageLoader} from '../../Routes'
import {useAppState} from '../../state'
import {useNavigation} from 'react-navi'
import {isApplianceCoolingInProgress} from '../appliances/appliancesUtils'
import {useTranslation} from 'react-i18next'
import {NoAppliancePlaceHolder} from '../appliances/appliances'
import {AppliancesList, getOrderedAppliances} from './AppliancesList'
import {ListLayoutAppliances} from './InfoView'

const APPLIANCES_DATA_POLLINTERVAL_MS = 15000

interface InfoAppliancesPageProps {
  applianceId?: string
}

export const InfoAppliancesPage: React.FC<InfoAppliancesPageProps> = props => {
  const {state, actions} = useAppState()
  const {t} = useTranslation('appliances')
  const navigation = useNavigation()
  const [currentSiteId, setCurrentSiteId] = useState(state.site?.id)
  const [scale, setScale] = useState(1)

  const siteId = state.site?.id
  const appliances = state.v1.appliances.appliances.data.appliances
  const appliancesError = state.v1.appliances.appliances.error

  useEffect(() => {
    // 1016px is the default best height for benchmarking
    setScale(window.innerHeight / 1016)
  }, [])

  useEffect(() => {
    if (state.appUpdated) {
      console.info('detected state.appUpdated, reload in 10 seconds...')
      setTimeout(() => {
        window.location.reload()
      }, 10 * 1000)
    }
  }, [state.appUpdated])

  useEffect(() => {
    const fetchData = async () => {
      if (!state.site?.id) {
        return
      }
      await actions.v1.appliances.getAppliancesForSite({siteId: state.site.id})
      const fetchedAppliances = state.v1.appliances.appliances.data.appliances
      if (!fetchedAppliances) {
        return
      }
    }
    // Site has changed
    if (state.site?.id && currentSiteId !== state.site?.id) {
      actions.v1.appliances.resetAppliances()
      setCurrentSiteId(state.site.id)
      // Close appliance details card by changing URL path
      navigation.navigate('/appliances')
    }
    fetchData()
    const intervalId = window.setInterval(() => {
      fetchData()
    }, APPLIANCES_DATA_POLLINTERVAL_MS)
    return () => {
      window.clearInterval(intervalId)
    }
  }, [actions.v1.appliances, siteId])

  if (!appliances) {
    return <PageLoader show={!appliances} />
  } else if (!appliances && appliancesError) {
    return <ErrorMessage message={appliancesError.message} />
  } else if (appliances.length === 0) {
    return <NoAppliancePlaceHolder />
  } else {
    const activeAppliances = getOrderedAppliances(appliances) // small cards with one sensor go first
    const alarmedAppliances = activeAppliances.filter(
      appliance =>
        appliance.sensors?.some(sensor =>
          Boolean(
            sensor.measurementTypes?.temperature?.hasActiveAlarm ||
              sensor.measurementTypes?.relativeHumidity?.hasActiveAlarm
          )
        ) && !isApplianceCoolingInProgress(appliance)
    )
    const coolingInProgressAppliances = activeAppliances.filter(appliance => isApplianceCoolingInProgress(appliance))
    const otherAppliances = activeAppliances.filter(
      appliance =>
        appliance.sensors?.every(
          sensor =>
            !Boolean(
              sensor.measurementTypes?.temperature?.hasActiveAlarm ||
                sensor.measurementTypes?.relativeHumidity?.hasActiveAlarm
            )
        ) && !isApplianceCoolingInProgress(appliance)
    )
    const getOtherAppliancesRows = () => {
      if (alarmedAppliances.length > 0 && coolingInProgressAppliances.length > 0) {
        return 1
      } else if (alarmedAppliances.length === 0 && coolingInProgressAppliances.length === 0) {
        return 3
      } else {
        return 2
      }
    }

    return (
      <ListLayoutAppliances scale={scale}>
        <Helmet title={t('common:routes.infoScreen', 'Info Screen')} />
        {alarmedAppliances.length > 0 && (
          <AppliancesList
            title={t('appliances:infoScreen.labels.alertAppliances', 'Alert appliances')}
            appliances={alarmedAppliances}
            scale={scale}
            paginationPosition="top"
          />
        )}
        {coolingInProgressAppliances.length > 0 && (
          <AppliancesList
            title={t('appliances:infoScreen.labels.coolingInProgress', 'Cooling in progress')}
            appliances={coolingInProgressAppliances}
            scale={scale}
            paginationPosition="top"
          />
        )}
        <AppliancesList
          title={t('appliances:info.labels.otherAppliances', 'Other appliances')}
          appliances={otherAppliances}
          scale={scale}
          rows={getOtherAppliancesRows()}
          paginationPosition="top"
        />
      </ListLayoutAppliances>
    )
  }
}
