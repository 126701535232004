import {User} from '../state'
import {AssetData} from '../rest'
import {Derive} from 'overmind'

export type InstructionsDocument = {
  id: string
  type: string
  name: string
  createdAt: string
  createdBy: User
  asset?: Partial<AssetData>
  url?: string
  documentFolder?: Partial<InstructionsFolder>
}

export type InstructionsFolder = {
  id: string
  name: string
  description?: string
}

export type InstructionsDocumentGroup = {
  name: string
  id: string
  description?: string
  documents: InstructionsDocument[]
}

type InstructionsState = {
  documents: InstructionsDocument[]
  documentsPending: boolean
  documentsError: Error | null
  instructionsFolders: InstructionsFolder[]
  documentFoldersPending: boolean
  documentFoldersError: Error | null
  documentGroups: Derive<InstructionsState, InstructionsDocumentGroup[]>
  documentById: Derive<InstructionsState, Record<string, Document>>
  documentFolderById: Derive<InstructionsState, Record<string, InstructionsFolder>>
}

export const state: InstructionsState = {
  documents: [],
  documentsPending: false,
  documentsError: null,
  instructionsFolders: [],
  documentFoldersPending: false,
  documentFoldersError: null,
  documentGroups: ({documents, instructionsFolders}) => {
    return instructionsFolders.map(folder => {
      const documentList = documents.filter(document => {
        return document.documentFolder!.id === folder.id
      })
      return {
        id: folder.id,
        name: folder.name,
        description: folder.description,
        documents: documentList
      }
    })
  },
  documentById: ({documents}) => {
    return documents.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  documentFolderById: ({instructionsFolders}) => {
    return instructionsFolders.reduce((a, b) => ({...a, [b.id]: b}), {})
  }
}
