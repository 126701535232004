import {AsyncAction} from 'overmind'
import {Message} from './state'

export const getMessages: AsyncAction<string> = async ({state, effects}, site) => {
  state.v1.messages.messagesByStatus = {
    readMessages: [],
    unreadMessages: []
  }
  state.v1.messages.messagesError = null
  try {
    const data = await effects.v1.messages.getMessages(site)
    state.v1.messages.messagesByStatus = data
  } catch (err) {
    state.v1.messages.messagesError = err as Error
  }
}

export const getMessage: AsyncAction<string, Message> = async ({state, effects}, id) => {
  const message = await effects.v1.messages.getMessage(id)

  return message
}

export const createMessage: AsyncAction<Partial<Message>> = async ({state, effects}, payload) => {
  await effects.v1.messages.createMessage(payload)
}

export const updateMessage: AsyncAction<Partial<Message>> = async ({state, effects}, payload) => {
  await effects.v1.messages.updateMessage(payload)
}

export const deleteMessage: AsyncAction<string> = async ({state, effects}, id) => {
  await effects.v1.messages.deleteMessage(id)
}

export const getAllMessagesForAdmin: AsyncAction = async ({state, effects}) => {
  state.v1.messages.messages = []
  state.v1.messages.allMessagesError = null
  try {
    const data = await effects.v1.messages.getAllMessagesForAdmin()
    state.v1.messages.messages = data
  } catch (error) {
    state.v1.messages.allMessagesError = error as Error
  }
}

export const getReadCountForMessage: AsyncAction<string> = async ({state, effects}, id) => {
  const index = state.v1.messages.readCountPerMessage.findIndex(item => item.messageId === id)
  const readCountMapping = await effects.v1.messages.getReadCountForMessage(id)

  if (index !== -1) {
    state.v1.messages.readCountPerMessage[index] = readCountMapping
  } else {
    state.v1.messages.readCountPerMessage = [...state.v1.messages.readCountPerMessage, readCountMapping]
  }
}

export const changeMessageStatus: AsyncAction<any> = async ({state, effects}, {isRead, id}: any) => {
  const payload = {isRead}
  await effects.v1.messages.markMessageRead(id, payload)

  if (isRead) {
    const readMessage = state.v1.messages.messagesByStatus.unreadMessages.find(msg => msg.id === id)
    if (readMessage) {
      state.v1.messages.messagesByStatus.readMessages = [
        ...state.v1.messages.messagesByStatus.readMessages,
        readMessage
      ]
      state.v1.messages.messagesByStatus.unreadMessages = state.v1.messages.messagesByStatus.unreadMessages.filter(
        msg => msg.id !== id
      )
    }
  } else {
    const unReadMessage = state.v1.messages.messagesByStatus.readMessages.find(msg => msg.id === id)
    if (unReadMessage) {
      state.v1.messages.messagesByStatus.unreadMessages = [
        ...state.v1.messages.messagesByStatus.unreadMessages,
        unReadMessage
      ]
      state.v1.messages.messagesByStatus.readMessages = state.v1.messages.messagesByStatus.readMessages.filter(
        msg => msg.id !== id
      )
    }
  }
}
