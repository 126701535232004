export type Message = {
  id: string
  heading: string
  content: string
  startDate: string
  endDate: string
  createdAt: string
  createdBy: string
  latestUpdatedAt: string
  latestUpdateBy: string
  readStatuses: MessageReadStatus[]
  sites: string[]
}

export type MessageReadStatus = {
  messageId: string
  userId: string
  readAt: string
  isRead: boolean
}

export type MessageToSites = {
  messageId: string
  siteId: string
}

export type MessageReadCountMapping = {
  messageId: string
  readCount: number
}

export type MessagesByStatus = {
  readMessages: Message[]
  unreadMessages: Message[]
}

type MessageState = {
  messages: Message[]
  messagesPending: boolean
  messagesError: Error | null
  allMessagesError: Error | null
  messagesByStatus: MessagesByStatus
  readCountPerMessage: MessageReadCountMapping[]
}

export const state: MessageState = {
  messages: [],
  messagesPending: false,
  messagesError: null,
  allMessagesError: null,
  messagesByStatus: {
    readMessages: [],
    unreadMessages: []
  },
  readCountPerMessage: []
}
