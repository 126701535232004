import React from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'
import {Title, P} from '../../Atoms/Typography'
import {InvisibleContainer, Baseline} from '../../Layout/Grid'
import {Button} from '../../Atoms/Buttons'
import {colors} from '../../../sharedComponents/colors'
import {LoginLogo, TitleWrapper} from './LoginComponents'
import {Helmet} from 'react-navi-helmet-async'

const ForgotPasswordComplete = () => {
  const navi = useNavigation()
  const {t} = useTranslation('auth')

  return (
    <InvisibleContainer>
      <Helmet title={t('common:routes.forgotResetComplete', 'Forgot password reset complete')} />
      <LoginLogo />
      <TitleWrapper>
        <Title level={2} color={colors.system.white}>
          {t('auth:labels.forgotPassword', 'Forgot password?')}
        </Title>
      </TitleWrapper>
      <Baseline>
        <P color={colors.system.white}>
          {t(
            'auth:messages.resetSuccesfull',
            'Your password has now been reset succesfully. You can now log in with the new password.'
          )}
        </P>
        <Button onClick={() => navi.navigate('/login')}>{t('auth:actions.returnToLogin', 'Return to log in')}</Button>
      </Baseline>
    </InvisibleContainer>
  )
}

export default ForgotPasswordComplete
