import {
  SensorChannel,
  EquipmentType,
  DishwasherType,
  DishwasherCycleInfo,
  CoolerType,
  StorageType,
  SensorRole
} from 'state/rest'
import {Site} from '../../state'

export interface GetAvailableSensorsParams {
  siteId: string
}
export interface GetApplianceSettingParams {
  siteId: string
  applianceId: string
}

export interface UpdateApplianceSettingParams {
  payload: UpdateApplianceSettingsPayload
  applianceId: string
  siteId: string
}

export interface AvailableSensorDTO {
  id: string
  channel: string | null
  sensorNumber: string | null
  devEui: string | null
  role: SensorRole | null
}

export interface AvailableSensorsResponseBody {
  items: AvailableSensorDTO[]
}

//TODO: update this
export interface LoraWANSensors {
  wirelessDeviceId: string
  devEui: string
  role: SensorRole
}

export interface ApplianceSetting {
  id: string
  name: string
  model?: string
  installationYear?: number
  sensorEnabled: boolean
  equipmentType?: Partial<EquipmentType>
  storageType?: Partial<StorageType>
  sensorChannels?: SensorChannel[]
  site?: Site
  suspended: boolean
  wasteScaleCategory?: {name?: string; id?: string}
  dishwasherType?: Partial<DishwasherType>
  dishwasherCycleInfo?: Partial<DishwasherCycleInfo>
  coolerType?: Partial<CoolerType>
  sensors?: LoraWANSensors[]
}

export interface UpdateApplianceSettingsPayload {
  name?: string
  model?: string
  installationYear?: number
  sensorEnabled?: boolean
  storageType?: string
  wasteScaleCategory?: string
  dishwasherType?: string
  dishwasherCycleInfo?: Partial<DishwasherCycleInfo>
  coolerType?: string
  sensors?: UpdateEquipmentSensor[]
}

export interface UpdateEquipmentSensor {
  id: string
  role?: string | null
  devEui?: string
}

interface ApplianceSettingsState {
  applianceSetting: {data: {items: ApplianceSetting | null}}
  availableSensors: {data: AvailableSensorsResponseBody}
  loading: boolean
  error: Error | null
}

export const state: ApplianceSettingsState = {
  applianceSetting: {
    data: {items: null}
  },
  availableSensors: {
    data: {items: []}
  },
  loading: false,
  error: null
}
