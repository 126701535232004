import * as React from 'react'
import {IconText} from '../../molecules/IconText'
import styled from 'styled-components'
import {colors} from '../../../colors'
import {Text, Icon} from '../../index'
import {Avatar} from '../../atoms/Avatar'
import {Dropdown} from '../../molecules/Dropdown'
import {SiteSelector, SiteSelectorProps} from '../../molecules/SiteSelector'
import {UserSelector, UserSelectorProps} from '../../molecules/UserSelector'
import {LanguageSelector, LanguageSelectorProps} from '../../molecules/LanguageSelector'

export interface Route {
  title: string
  icon: string
  onClick: () => void
  isActive: boolean
}

export interface MenuProps {
  routes: Route[]
  switch?: {
    text: string
    link: string
  }
  siteSelect?: SiteSelectorProps
  user: UserSelectorProps
  language: LanguageSelectorProps
}

export const MenuContainer = styled.div<MenuProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${colors.brand.cobalt};
  height: 4rem;
  z-index: 5000;
`

export const MenuWrapper = styled.div`
  width: 70%;
  position: relative;
  display: flex;
  gap: 2rem;
  flex-direction: row;
  margin: auto 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
  hr {
    border: 1px solid ${colors.brand.cobalt};
    margin: 2rem;
  }
  a {
    color: ${colors.system.white};
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 0.29;
    text-decoration: none;
    > div {
      width: 5rem;
    }

    &.active {
      color: ${colors.brand.cobalt};
      > div {
        background-color: ${colors.system.white};
      }
    }
    &:hover {
      color: ${colors.system.white};
    }
    &.active:hover {
      color: ${colors.brand.cobalt};
    }
  }
`

export const UserWrapper = styled.div`
  width: 23%;
  justify-content: space-between;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-left: auto;
  margin-right: 2rem;
  height: 4rem;

  > *:not(:first-child) {
    margin-left: 1.25rem;
  }
`

const VersionSwitch = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  gap: 0.5rem;

  border-left: 1px solid ${colors.system.white};
  padding-left: 1.5rem;
  height: 2rem;

  :hover {
    text-decoration: underline;
  }
`

export const Menu = (props: MenuProps) => {
  return (
    <MenuContainer {...props}>
      <MenuWrapper>
        {props.routes.map(route => (
          <IconText
            key={route.title}
            icon={
              <Icon
                type={`${route.icon}${route.isActive ? '-reverse' : ''}`}
                fill={colors.system.white}
                width="1.25em"
                height="1.25em"
              />
            }
            text={
              <Text size="XS" color={colors.system.white} strong={route.isActive}>
                {route.title}
              </Text>
            }
            onClick={route.onClick}
            isColumn
            isActive={route.isActive}
            data-cy={`menu-${route.title}`}
          />
        ))}
        {props.switch && (
          <VersionSwitch href={props.switch.link}>
            <Text size="M" color={colors.system.white}>
              {props.switch.text}
            </Text>
            <Icon type="external-link" fill={colors.system.white} />
          </VersionSwitch>
        )}
      </MenuWrapper>
      <UserWrapper>
        {props.siteSelect && (
          <Dropdown
            overlay={
              <SiteSelector
                chain={props.siteSelect.chain}
                locationGroups={props.siteSelect.locationGroups}
                onClick={props.siteSelect.onClick}
                selectedSiteName={props.siteSelect.selectedSiteName}
                chainSelectText={props.siteSelect.chainSelectText}
              />
            }
            anchorMode="right"
            expandIconColor={colors.system.white}
            top="4rem"
          >
            <Text size="M" color={colors.system.white} data-cy="site-selector">
              {props.siteSelect.chain.name} - {props.siteSelect.selectedSiteName}
            </Text>
          </Dropdown>
        )}
        <Dropdown
          overlay={<LanguageSelector {...props.language} />}
          anchorMode="right"
          expandIconColor={colors.system.white}
          top="4rem"
        >
          <Text size="M" color={colors.system.white} data-cy="language-selector">
            {props.language.selectedLanguage}
          </Text>
        </Dropdown>
        {props.user && (
          <Dropdown overlay={<UserSelector {...props.user} />} anchorMode="right" top="4rem">
            <Avatar src={props.user.photoUrl} alt="profile picture" data-cy="user-selector" />
          </Dropdown>
        )}
      </UserWrapper>
    </MenuContainer>
  )
}
