import React from 'react'
import {useNavigation} from 'react-navi'

import {MainLayoutWithoutStretch, MainContent} from '../Layout/Layout'
import {Baseline, InvisibleContainer} from '../Layout/Grid'
import {GoBackHeaderTitle, HeaderRow} from '../Molecules/ViewComponents/ViewHeader'
import {P, H1} from '../Atoms/Typography'
import {Button} from '../Atoms/Buttons'

interface Props {
  title?: string
}

const NotYetImplentedView = ({title}: Props) => {
  const nav = useNavigation()
  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle label={title || 'Title'} backLabel="Back" />
        </HeaderRow>
        <Baseline>
          <InvisibleContainer>
            <P>
              <H1>Coming soon!</H1>
            </P>
            <P>This section of the service is not yet ready. We are working on it.</P>
            <P>
              <Button variant="secondary" onClick={() => nav.goBack()}>
                Back
              </Button>
            </P>
          </InvisibleContainer>
        </Baseline>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

export default NotYetImplentedView
