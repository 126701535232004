import React, {Suspense, ReactNode, Fragment, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Routes, {Loader} from './Routes'
import * as serviceWorker from './serviceWorker'
import {Provider} from 'overmind-react'
import {appState, useAppState} from './state'
import {GlobalStyle} from './GlobalStyle'
import HelmetProvider from 'react-navi-helmet-async'
import './config/int' // i18n init
import * as Sentry from '@sentry/browser'
import ky from 'ky'
import Errors400, {SystemDown, ErrorPage} from './Components/Views/Errors/ErrorPages'
import styled from 'styled-components'
import {colors} from './sharedComponents/colors'
import {H1} from './Components/Atoms/Typography'
import {Button, BaseButton} from './Components/Atoms/Buttons'
import {Baseline, Grid} from './Components/Layout/Grid'
import TagManager, {TagManagerArgs} from 'react-gtm-module'
import {LayoutError, nuke} from './config/utils'
import {ErrorView} from './state/state'

// new test commit comment

const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-5B5S577',
  //dataLayerName: 'PageDataLayer',
  events: {
    pageView: 'pageView'
  },
  auth:
    !!process.env.REACT_APP_GTM_AUTH && !!process.env.REACT_APP_GTM_AUTH.length
      ? process.env.REACT_APP_GTM_AUTH
      : undefined,
  preview:
    !!process.env.REACT_APP_GTM_ENV && !!process.env.REACT_APP_GTM_ENV.length
      ? process.env.REACT_APP_GTM_ENV
      : undefined
}
console.log('gtm data: ', {tagManagerArgs})
TagManager.initialize(tagManagerArgs)

const sentry_url = process.env.REACT_APP_SENTRY_URL

const sentry_env = process.env.REACT_APP_SENTRY_ENV || 'local'
console.log('env: ', process.env.NODE_ENV)
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: sentry_url,
    environment: sentry_env
  })
}

const errorResolver = (err: ErrorView) => {
  switch (err) {
    case ErrorView.BACKEND_UPDATING:
      return (
        <ErrorPage
          heading={`Chefstein is under maintenance`}
          paragraph={`Wait for a few moments and refresh your browser`}
          cta={
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Reload browser
            </Button>
          }
        />
      )

    case ErrorView.SYSTEM_DOWN:
      return (
        <ErrorPage
          heading={`Chefstein not responding`}
          paragraph={`We are experiencing technical difficulties, please wait for a few moments and try again.`}
        />
      )

    case ErrorView.UPDATE_REQUIRED:
      return (
        <ErrorPage
          heading={`Update available soon`}
          paragraph={`We are updating Chefstein. Wait for a few moments and refresh your browser`}
          cta={
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Reload browser
            </Button>
          }
        />
      )
    case ErrorView.UNAUTHORIZED:
      return (
        <ErrorPage
          heading={`Unauthorized`}
          paragraph={`There was an issue with your user priviledges. Try to logout and login again. If the problem persists, contact support at servicedesk.cgiaromi.fi@cgi.com`}
          cta={
            <Button
              onClick={() => {
                nuke()
              }}
            >
              Logout
            </Button>
          }
        />
      )
    default:
      return (
        <ErrorPage
          heading={`Unknown error`}
          paragraph={`We are experiencing technical difficulties. Support team has been notified. Try to logout and login again. If the problem persists, contact support at servicedesk.cgiaromi.fi@cgi.com.`}
          cta={
            <Button
              onClick={() => {
                nuke()
              }}
            >
              Reload browser
            </Button>
          }
        />
      )
  }
}

const AppBootstrapper = (p: {children: ReactNode}) => {
  const {state, actions} = useAppState()
  useEffect(() => {
    function checkHash() {
      if (process.env.NODE_ENV !== 'development') {
        ky.get('/hash.txt')
          .text()
          .then(actions.setAppCurrentVersion)
          .catch(console.log)
      } else {
        actions.setAppCurrentVersion('development-test-hash')
      }
    }
    checkHash()
    const id = setInterval(checkHash, 60 * 1000)

    console.log(`App version: ${process.env.REACT_APP_VERSION}`)
    const goOffline = () => actions.setOnlineStatus(false)
    const goOnline = () => actions.setOnlineStatus(true)
    window.addEventListener('online', goOnline)
    window.addEventListener('offline', goOffline)
    return () => {
      window.removeEventListener('offline', goOffline)
      window.removeEventListener('online', goOnline)
      clearInterval(id)
    }
  }, [actions])
  console.log('appStateMachine', state.appStateMachine.current)
  switch (state.appStateMachine.current) {
    case 'STARTUP':
      return <Loader show />
    case 'LOADING':
      return <Loader show />
    case 'UNAUTHENTICATING':
      return <Loader show />
    case 'AUTHENTICATING':
      return <Loader show />
    case 'AUTHENTICATED':
      return <Loader show />
    case 'ERROR':
      return errorResolver(state.errorView)
    // currently both ready and unauthenticated are handled within the router. TODO: refactor login to it's own router
    case 'READY':
      return <Fragment>{p.children}</Fragment>
    case 'UNAUTHENTICATED':
      return <Fragment>{p.children}</Fragment>
    default:
      return <SystemDown />
  }
}
class EB extends React.Component<{children: ReactNode}, {error: any | null}> {
  constructor(props: {children: ReactNode}) {
    super(props)
    this.state = {error: null}
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.

    return {error}
  }
  async componentDidCatch(e: any, b: any) {
    let originalMsg: any
    if (e instanceof Error) {
      console.log('is error')
    }
    if (e instanceof ky.HTTPError) {
      console.log('is http error')
      originalMsg = await e.response.json()
    }
    if (e instanceof LayoutError) {
      console.log('is layout error')
      originalMsg = e.originalError ? await e.originalError.response.json() : undefined
    }

    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope(scope => {
        scope.setExtras({stack: b, originalMsg})
        Sentry.captureException(e)
      })
    }
  }
  forceLogout = () => {
    localStorage.clear()
    window.location.reload()
  }
  render() {
    if (
      this.state.error !== null &&
      this.state.error instanceof ky.HTTPError &&
      this.state.error.response &&
      this.state.error.response.status === 404
    ) {
      return <Errors400 />
    } else if (this.state.error !== null && this.state.error instanceof LayoutError) {
      return (
        <>
          <ErrorModal>{this.state.error.message}</ErrorModal>
          <DontCrashEB>{this.props.children}</DontCrashEB>
        </>
      )
    } else if (this.state.error !== null) {
      return (
        <>
          <ErrorModal
            action={
              <Button variant="secondary" negative onClick={this.forceLogout}>
                Log out
              </Button>
            }
          >
            An unknown error occurred and the support team has been notified. Try to log out and log in again to see if
            the problem persists. Please contact support at servicedesk.cgiaromi.fi@cgi.com if you encounter this
            message again or continue having problems!
          </ErrorModal>
        </>
      )
    }
    return this.props.children
  }
}

// A safequard component for catching infinitely looping errors. After layout error we might have some other crash in the component, wrapping with this, should remove that problem
class DontCrashEB extends React.Component<{children: ReactNode}, {error: any | null}> {
  constructor(props: {children: ReactNode}) {
    super(props)
    this.state = {error: null}
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.

    return {error}
  }
  componentDidCatch(e: any, b: any) {
    console.log('Safeguard caught an error', e, b)
  }
  forceLogout = () => {
    localStorage.clear()
    window.location.reload()
  }
  render() {
    return !!this.state.error ? (
      <ErrorModal
        action={
          <Button variant="secondary" negative onClick={this.forceLogout}>
            Log out
          </Button>
        }
      >
        An unknown error occurred and the support team has been notified. Try to log out and log in again to see if the
        problem persists. Please contact support at servicedesk.cgiaromi.fi@cgi.com if you encounter this message again
        or continue having problems!
      </ErrorModal>
    ) : (
      this.props.children
    )
  }
}
const ErrorModalWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${colors.system.red};
  padding: 2rem;
  color: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  div.content {
    max-width: 800px;
  }
  ${BaseButton} {
    background-color: white;
    border: none;
  }
`
const ErrorModal = ({children, action}: {children: ReactNode; action?: ReactNode}) => {
  const [show, setShow] = useState(true)
  return show ? (
    <ErrorModalWrap>
      <div className="content">
        <Baseline>
          <H1>Something went wrong</H1>
          <Grid>
            <p>{children}</p>
            {action || (
              <Button variant="secondary" negative onClick={() => setShow(false)}>
                Close
              </Button>
            )}
          </Grid>
        </Baseline>
      </div>
    </ErrorModalWrap>
  ) : null
}

ReactDOM.render(
  <HelmetProvider>
    <Suspense fallback={<Loader show />}>
      <Provider value={appState}>
        <GlobalStyle />
        <EB>
          <AppBootstrapper>
            <Routes />
          </AppBootstrapper>
        </EB>
      </Provider>
    </Suspense>
  </HelmetProvider>,
  document.getElementById('react-root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
