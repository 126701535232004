import React, {FC, ReactElement, ReactNode} from 'react'
import styled from 'styled-components'

import {AssetsListProps} from './types'
import {AssetData, AssetType} from '../../../state/rest'
import {ListContainer, SelectableListItemContainer, ListItem} from '../../Atoms/Lists'
import {IconDocument, IconPhoto, IconVideo} from '../../../Assets/Icons/TinyIcons'
import {orderBy} from 'lodash'

const AssetListContainer = styled(ListContainer)`
  max-height: 288px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
`

const AssetListItem = styled(ListItem)``

const AssetListContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: left;
`

const AssetsList: FC<AssetsListProps> = (props): ReactElement | null => {
  const assetsOfType = orderBy(
    props.assets.filter(asset => {
      return asset.assetType === props.assetType
    }),
    ['createDateTime'],
    ['desc']
  )

  const getAssetIcon = (type: AssetType): ReactNode => {
    switch (type) {
      case AssetType.DOCUMENT: {
        return <IconDocument />
      }
      case AssetType.PHOTO: {
        return <IconPhoto />
      }
      case AssetType.VIDEO: {
        return <IconVideo />
      }
    }
    return null
  }

  const handleClick = (asset: AssetData) => {
    if (props.onClick) {
      props.onClick(asset)
    }
  }

  return (
    <AssetListContainer>
      {assetsOfType
        ? assetsOfType.map(asset => (
            <SelectableListItemContainer key={`container-${asset.id}`} isSelected={props.selected?.id === asset.id}>
              <AssetListItem icon={getAssetIcon(asset.assetType)} key={asset.id} onClick={() => handleClick(asset)}>
                <AssetListContent>{asset.name}</AssetListContent>
              </AssetListItem>
            </SelectableListItemContainer>
          ))
        : null}
    </AssetListContainer>
  )
}

export default AssetsList
