import React, {useState} from 'react'
import styled from 'styled-components'
import {IconButton} from '../Atoms/Buttons'
import {IconCollapse, IconExpand} from '../../Assets/Icons/TinyIcons'
import {P} from '../Atoms/Typography'
import {availableKeyValues} from './../../Components/Views/Utils'

interface AnyOrgLevel {
  name: string
  description?: string
  visible?: boolean
  locationGroups?: AnyOrgLevel[]
  locations?: AnyOrgLevel[]
  brands?: AnyOrgLevel[]
  sites?: AnyOrgLevel[]
  id: string
}
const OffsetCheckboxList = styled.ul`
  margin-left: 28px;
  list-style: none;
  margin-top: 4px;
`
interface USSProps {
  data: AnyOrgLevel
}
const SiteOrganisationList = ({data}: USSProps) => {
  const keys = Object.keys(data) as Array<keyof AnyOrgLevel>
  const arr = keys.find(k => availableKeyValues.includes(k)) as 'locationGroups' | 'locations' | 'brands' | 'sites'
  const nextLevel = data[arr]
  const [isOpen, setOpen] = useState(true)
  if (nextLevel) {
    if (data.visible !== false) {
      return (
        <>
          <IconButton
            icon={isOpen ? <IconCollapse /> : <IconExpand />}
            description={data.name}
            buttonProps={{onClick: () => setOpen(o => !o)}}
          />
          {isOpen && (
            <OffsetCheckboxList>
              {nextLevel.map(n => (
                <li key={n.id}>
                  <SiteOrganisationList data={n} />
                </li>
              ))}
            </OffsetCheckboxList>
          )}
        </>
      )
    } else
      return (
        <>
          {nextLevel.map(n => (
            <SiteOrganisationList data={n} />
          ))}
        </>
      )
  } else {
    return <P style={{marginLeft: '1.5rem', marginTop: 4}}>{data.name}</P>
  }
}
export default SiteOrganisationList
