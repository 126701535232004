import {localTempValue} from './../../../config/utils'
import {useState, useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigation} from 'react-navi'
import {useAppState} from '../../../state'
import {EquipmentType, StorageSensorType} from '../../../state/rest'
import {Chain, Site} from '../../../state/state'
import {useStorageTypeList} from './StorageTypeUtils'
import {useDishwasherTypeList} from './DishwasherTypeUtils'
import {useCoolerTypeList} from './CoolerTypeUtils'

interface PossibleFormFields {
  id?: string
  name: string
  fixed: boolean
  description?: string
  icon?: string
  chain: Partial<Chain>
}
export const useEquipmentTypeForm = (saved?: EquipmentType) => {
  const {state, actions, effects} = useAppState()
  const {me} = state
  const [modalOpen, setModalOpen] = useState(false)

  const {navigate} = useNavigation()

  async function goBack() {
    navigate('/appliance/types')
  }

  const {register, watch, errors, triggerValidation, formState} = useForm<Partial<PossibleFormFields>>({
    defaultValues: saved,
    mode: 'onChange'
  })
  const [loading, setLoading] = useState(false)

  const isFixed = saved ? saved!.fixed! : false
  const typeKey = saved ? saved!.id! : undefined

  const [icon, setIcon] = useState(saved ? saved.icon : undefined)
  const handleIconSelect = (key: string): void => {
    setIcon(key)
  }

  const sfc = useStorageTypeList(typeKey)
  const dfc = useDishwasherTypeList()
  const cfc = useCoolerTypeList()

  const showStorageTypeEditor = () => {
    return saved && (saved!.id === 'storage' || saved!.id === 'freezer')
  }
  const showWasteScaleCategoryEditor = () => {
    return saved && saved!.id === 'waste-scale'
  }
  const showDishwasherTypeEditor = () => {
    return saved && saved!.id === 'dishwasher'
  }
  const showCoolerTypeEditor = () => {
    return saved && saved!.id === 'cooler'
  }

  const form = watch()
  const update = async () => {
    try {
      setLoading(true)
      if (!isFixed) {
        await effects.equipmentApi.updateEquipmentType({
          id: saved!.id!,
          fixed: saved!.fixed!,
          name: form.name!,
          description: form.description,
          icon: icon,
          chain: saved!.chain
        })
      } else {
        // fixed type: cannot modify the equipment type
        if (showStorageTypeEditor()) {
          await sfc.saveStorageTypes(saved!)
        }
        if (showDishwasherTypeEditor()) {
          await dfc.saveDishwasherTypes()
        }
        if (showCoolerTypeEditor()) {
          await cfc.saveCoolerTypes()
        }
      }
      await actions.getEquipmentTypes()
      goBack()
    } catch (err) {
      console.error(`Error updating appliance type ${saved!.id!}`, err)
      actions.addNotificationGenericFormError()
    } finally {
      setLoading(false)
    }
  }
  const submit = async () => {
    try {
      setLoading(true)
      await effects.equipmentApi.createEquipmentType({
        fixed: false,
        name: form.name,
        description: form.description,
        icon: icon,
        chain: me!.org.chains[0]
      })
      await actions.getEquipmentTypes()
      goBack()
    } catch (err) {
      console.error(`Error creating appliance type, reason: ${err.message}`, err)
      actions.addNotificationGenericFormError()
    } finally {
      setLoading(false)
    }
  }
  const remove = async () => {
    try {
      setLoading(true)
      await actions.deleteEquipmentType(saved!.id!)
      goBack()
    } catch (err) {
      console.error(`Error deleting appliance type, reason: ${err.message}`, err)
      actions.addNotificationGenericFormError()
    } finally {
      setLoading(false)
    }
  }

  const cancel = () => {
    goBack()
  }

  return {
    register,
    update,
    submit,
    remove,
    cancel,
    form,
    errors,
    hasErrors: !formState.isValid || !sfc.formState.isValid,
    triggerValidation,
    formState,
    loading,
    handleIconSelect,
    icon,
    isFixed,
    typeKey,
    modalOpen,
    setModalOpen,
    showStorageTypeEditor,
    showWasteScaleCategoryEditor,
    showDishwasherTypeEditor,
    showCoolerTypeEditor,
    sfc,
    dfc,
    cfc
  }
}

export function useStorageTypeSelect(
  selectedSite: Site,
  typeKey: string,
  saved?: Partial<EquipmentType>,
  defaultOption?: string
) {
  const {state} = useAppState()
  const storageByType = state.storageTypes.filter(st => st.equipmentType!.id === typeKey)
  const storageTypeOptions = storageByType.map(o => {
    const min = localTempValue(o.minValue, selectedSite)
    const max = localTempValue(o.maxValue, selectedSite)
    const unit = `°${selectedSite.temperatureUnit}`
    const temperatureLimits = `${min}–${max}${unit}`
    const hasHumidity = o.sensorType === StorageSensorType.TEMPERATURE_HUMIDITY
    const humidityLimits = hasHumidity ? `, ${o.humidityMinValue}–${o.humidityMaxValue}%` : ''
    return {
      option: `${o.name} (${temperatureLimits}${humidityLimits})`,
      id: o.id
    }
  })
  const defaultOptionIndex = storageTypeOptions.findIndex(tg => tg.id === defaultOption)
  const defaultIndex = defaultOptionIndex !== -1 ? defaultOptionIndex : 0
  const [selected, setSelected] = useState(!!saved ? saved : storageByType[defaultIndex])
  const selectMethod = useCallback(
    (id: string) => {
      setSelected(state.storageTypeById[id])
    },
    [state.storageTypeById]
  )
  return {selectedStorageType: selected, selectStorageType: selectMethod, storageTypeOptions}
}
