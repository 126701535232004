import styled from 'styled-components'
import React, {
  forwardRef,
  InputHTMLAttributes,
  ButtonHTMLAttributes,
  Ref,
  SelectHTMLAttributes,
  ReactNode,
  useState,
  useEffect,
  useCallback,
  LabelHTMLAttributes
} from 'react'
import {colors} from '../../sharedComponents/colors'
import DefaultDatePicker, {ReactDatePickerProps} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import tiny from 'tinycolor2'
import {roundMinutes} from './Utils'
import {isEqual} from 'date-fns'
import {IconText} from './IconText'
import {IconButton} from './Buttons'
import {enGB} from 'date-fns/locale'

export const StyledLabel = styled.label<LabelProps>`
  position: relative;
  font-weight: normal;
  font-size: 0.75rem; /*12px;*/
  line-height: 1rem; /*16px;*/
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 1rem;
  color: ${props => (props.negative ? colors.system.white : colors.brand.cobalt)};
  &.required {
    &:after {
      position: absolute;
      content: '*';
      margin-left: 4px;
      font-size: 16px;
    }
  }
`
interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  children?: ReactNode
  negative?: boolean
  required?: boolean
}
export const Label = ({children, negative, required, ...p}: LabelProps) => {
  return (
    <StyledLabel className={required === true ? 'required' : undefined} negative={negative} {...p}>
      {children}
    </StyledLabel>
  )
}

export const Input = styled.input`
  color: ${colors.brand.cobalt};
  border: 1px solid ${colors.system.lightGrey_5};
  background-color: ${colors.system.lightGrey_5};
  font-size: 1rem;
  padding: 0.75rem;
  padding-left: 1rem;
  width: 100%;
  position: relative;

  &::placeholder {
    color: ${colors.system.grey_1};
  }
  &:hover {
    border-color: ${colors.system.grey_1};
  }
  &[type='number'] {
    background-color: transparent;
    border-color: ${colors.system.grey_1};
  }
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    width: 1em;
    border-left: 1px solid ${colors.system.lightGrey_5};
    opacity: 1; /* shows Spin Buttons per default (Chrome >= 39) */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cg%3E%3Cpolygon points='11.62,20.03 6.77,15.19 7.48,14.48 11.62,18.61 15.75,14.48 16.46,15.19 '/%3E%3Cpolygon points='15.75,9.67 11.62,5.54 7.48,9.67 6.77,8.97 11.62,4.13 16.46,8.97 '/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat, repeat;
    background-position: right -0.25em top 50%, 0 0;
    background-size: 1.5rem auto, 50%;
  }
  &[type='number']::-webkit-inner-spin-button:hover,
  &[type='number']::-webkit-inner-spin-button:active {
    border-color: ${colors.system.grey_1};
    opacity: 0.8;
  }
  &:disabled {
    background-color: transparent;
    border-color: ${colors.system.lightGrey_5};
    cursor: not-allowed;
  }
`

const InputErrorMessage = styled.div.attrs({
  className: 'error'
})`
  color: ${colors.system.red};
  font-size: 0.8rem;
  padding-top: 0.5rem;
`

export const LabelInputWrapper = styled.div`
  label {
    display: block;
    /* margin-left: 1rem; */
  }
`
interface LabelInputProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string
  negative?: boolean
  errorMsg?: string
}
export const LabelInput = forwardRef((p: LabelInputProps, ref: Ref<HTMLInputElement>) => {
  return (
    <LabelInputWrapper>
      {!!p.labelText && (
        <Label negative={p.negative} htmlFor={p.id} className={p.required === true ? 'required' : undefined}>
          {p.labelText}
        </Label>
      )}
      <Input autoComplete={p.autoComplete || 'off'} {...p} ref={ref} aria-label={p.labelText || p.name} />
      {!!p.errorMsg && <InputErrorMessage>{p.errorMsg}</InputErrorMessage>}
    </LabelInputWrapper>
  )
})

export const BigLabelInput = styled(LabelInput)`
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed gray;
  font-family: FavoritStd-Light;
  font-size: 22px;
  font-weight: bold;
  padding: 0.25rem 0.75rem;
  color: ${colors.brand.cobalt};

  div {
    grid-column: span 2;
  }
  &:disabled {
    color: ${colors.system.grey_1};
  }
`

export const BigInput = styled(Input)`
  background-color: transparent;
  border-bottom: 1px dashed gray;
  div {
    grid-column: span 2;
  }
`
export const StyledRadio = styled.input`
  margin-right: 0.5rem;
`
interface CBProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | React.ReactNode
  vertical?: boolean
  flipped?: boolean
}
interface ToggleProps {
  values: [string, string]
  label: string
  initiallyChecked?: boolean
  checked?: boolean
  name: string
  onClick?: (state: boolean) => void
  disabled?: boolean
}

const TogglerButton = styled.button`
  cursor: pointer;
  flex-direction: row;
  display: flex;
  border-radius: 20px;
  border: 1px solid ${colors.system.grey_1};
  width: max-content;
  padding: 0;
  background-color: ${colors.system.white};
  font-size: 0.75rem;
  span {
    display: flex;
    height: 30px;
    padding: 0.25rem 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  &[aria-checked='true'] span.on,
  &[aria-checked='false'] span.off {
    background-color: ${props => (props.disabled ? colors.system.grey_50 : colors.system.grey_50)};
    color: white;
  }
  &[aria-checked='false'] span.on,
  &[aria-checked='true'] span.off {
    color: ${colors.system.grey_1};
  }
  :disabled {
    opacity: 0.5;
  }
  :disabled span {
    cursor: not-allowed;
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Toggler = forwardRef(
  ({name, values, label, onClick, initiallyChecked, checked, disabled}: ToggleProps, ref: Ref<HTMLInputElement>) => {
    const [state, setState] = useState<boolean>(initiallyChecked !== undefined ? initiallyChecked : !!checked)
    useEffect(() => {
      if (checked !== undefined) {
        setState(!!checked)
      }
    }, [checked])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const newState = !state
      setState(newState)
      /**
       * TODO: reconsider on how to implement this without dom lookup. useForm function ref makes this a bit difficult
       * https://react-hook-form.com/faq should help with this
       */
      const el: HTMLInputElement = document.getElementById(name)! as HTMLInputElement
      el.click()
      if (onClick) {
        onClick(newState)
      }
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // setState(!state)
      // /**
      //  * TODO: reconsider on how to implement this without dom lookup. useForm function ref makes this a bit difficult
      //  * https://react-hook-form.com/faq should help with this
      //  */
      // const el: HTMLInputElement = document.getElementById(name)! as HTMLInputElement
      // el.click()
      // if (onClick) {
      //   onClick(state)
      // }
    }
    return (
      <ToggleWrapper>
        <Label htmlFor={name}>{label}</Label>
        <input
          style={{opacity: 0, height: 0, width: 0}}
          tabIndex={-1}
          type="checkbox"
          name={name}
          id={name}
          checked={state}
          ref={ref}
          onChange={handleChange}
        />

        <TogglerButton
          role="switch"
          type="button"
          aria-checked={state}
          onClick={handleClick}
          disabled={disabled}
          data-cy={`toggler-${name}`}
        >
          <span className="off">{values[0]}</span>
          <span className="on">{values[1]}</span>
        </TogglerButton>
      </ToggleWrapper>
    )
  }
)

export const StyledCheckbox = styled.input`
  position: relative;
  vertical-align: middle;
  appearance: none;
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s ease-out;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpath d='M21.16,21.16H2.84V2.84h18.31V21.16z M3.84,20.16h16.31V3.84H3.84V20.16z'/%3E%3C/svg%3E%0A");
  &:checked {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpolygon points='20.16,20.16 3.84,20.16 3.84,3.84 20.16,3.84 20.16,7.56 12.22,15.5 6.83,10.11 6.12,10.82 12.22,16.91 24.06,5.07 23.35,4.37 21.16,6.56 21.16,2.84 2.84,2.84 2.84,21.16 21.16,21.16 21.16,12.92 20.16,12.92 '/%3E%3C/svg%3E%0A");
  }
  &:active {
    border: 1px solid #34495e;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`
export const StyledCheckboxLabel = styled(Label)`
  vertical-align: middle;
  font-family: FavoritStd-Book;
  font-weight: normal;
  font-size: 1rem;
  color: ${colors.brand.cobalt};
  letter-spacing: 0.11px;
  margin: 0;
`
const CheckboxText = styled.span``
interface LCWProps {
  flipped?: boolean
}
const LabelCheckboxWrapper = styled.div<LCWProps>`
  display: block;
  position: relative;

  &:not(.vertical) {
    margin: 0.5rem 0;
    ${StyledCheckboxLabel} {
      position: relative;
      display: grid;
      grid-template-columns: ${p => (p.flipped ? '1fr 50px' : 'auto 1fr')};
      grid-gap: 1rem;
      align-items: center;
    }
    ${StyledCheckbox} {
      order: ${p => p.flipped && 2};
      justify-self: ${p => (p.flipped ? 'center' : 'left')};
    }
    ${CheckboxText} {
      order: ${p => p.flipped && 1};
    }
  }

  &.vertical {
    margin-bottom: 0;
    height: 100%;
    ${StyledCheckboxLabel} {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: grid;
      grid-template-areas: 'top' 'bottom';
      grid-gap: 0.5rem;
      justify-items: center;
      align-items: center;
    }
    ${StyledCheckbox} {
      grid-area: top;
    }
    ${CheckboxText} {
      grid-area: bottom;
      /*font-family: 'HelveticaNeue-Bold';*/
      font-family: FavoritMonoStd-Regular, monospace;
      font-weight: bold;
      font-size: 0.625rem;
      color: ${colors.system.grey_1};
      letter-spacing: 0.07px;
      line-height: 0.625rem;
    }
  }
`
export const LabelCheckbox = forwardRef(
  ({flipped = true, label, vertical = false, ...rest}: CBProps, ref: Ref<HTMLInputElement>) => {
    return (
      <LabelCheckboxWrapper
        flipped={flipped}
        className={vertical ? 'vertical' : ''}
        style={{opacity: rest.disabled ? 0.5 : 1}}
      >
        <StyledCheckboxLabel>
          <StyledCheckbox ref={ref} {...rest} type="checkbox" />
          <CheckboxText>{label}</CheckboxText>
        </StyledCheckboxLabel>
      </LabelCheckboxWrapper>
    )
  }
)

interface SCBProps {
  name: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  checked?: boolean
}
export const Checkbox = forwardRef(
  ({name, onClick, disabled = false, checked}: SCBProps, ref: Ref<HTMLInputElement>) => {
    return (
      <StyledCheckbox
        checked={checked}
        disabled={disabled}
        ref={ref}
        type="checkbox"
        onClick={onClick}
        onChange={() => ({})}
        name={name}
      />
    )
  }
)

const LabelRadioWrapper = styled.div`
  display: block;
  ${StyledLabel} {
    font-size: 1rem;
  }
`

interface RProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string
}
/**
 * Remember that name property has to be identical between all radio buttons
 */
export const LabelRadio = forwardRef(({value, defaultChecked, label, name, id}: RProps, ref: Ref<HTMLInputElement>) => {
  return (
    <LabelRadioWrapper>
      <Label>
        <StyledRadio name={name} id={id} type="radio" defaultChecked={defaultChecked} value={value} ref={ref} /> {label}{' '}
      </Label>
    </LabelRadioWrapper>
  )
})
export const StyledCheckboxButton = styled.button`
  border-radius: 50px;
  border: 1px solid ${colors.system.grey_50};
  padding: 1rem;
  font-size: 0.75rem;
  background-color: ${colors.system.grey_50};
  color: white;
  &[aria-checked='false'] {
    border: 1px solid ${colors.system.grey_1};
    background-color: ${colors.system.white};
    color: ${colors.system.grey_1};
  }
`
interface CheckBoxButtonProps {
  checked?: boolean
  children: ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const CheckboxButton = ({checked, children, onClick}: CheckBoxButtonProps) => {
  const [state, setState] = useState(checked)

  useEffect(() => {
    const update = () => {
      setState(checked)
    }
    update()
    return () => {
      // unmount
    }
  }, [checked, setState])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setState(!state)
    if (onClick) {
      onClick(event)
    }
  }
  return (
    <StyledCheckboxButton type="button" role="switch" onClick={handleClick} aria-checked={state ? 'true' : 'false'}>
      {children}
    </StyledCheckboxButton>
  )
}

export const StyledTagCheckboxButton = styled.button`
  border-radius: 24px;
  padding: 0.25rem 0.5rem;
  font-family: FavoritStd-Book;
  font-weight: 400;
  font-size: 0.75rem; /*12px;*/
  line-height: 1rem; /*16px;*/
  letter-spacing: 0.4px;
  text-align: center;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid ${colors.brand.cobalt};
  background-color: ${colors.brand.cobalt};
  color: white;
  &[aria-checked='false'] {
    border: 1px solid ${colors.system.grey_1};
    background-color: ${colors.system.white};
    color: ${colors.brand.cobalt};
  }
  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
`
export const TagCheckboxButton = ({
  checked,
  children,
  onClick,
  ...p
}: CheckBoxButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const [state, setState] = useState(checked)

  useEffect(() => {
    const update = () => {
      setState(checked)
    }
    update()
    return () => {
      // unmount
    }
  }, [checked, setState])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setState(!state)
    if (onClick) {
      onClick(event)
    }
  }
  return (
    <StyledTagCheckboxButton
      type="button"
      role="switch"
      onClick={handleClick}
      aria-checked={state ? 'true' : 'false'}
      {...p}
    >
      {children}
    </StyledTagCheckboxButton>
  )
}

const CustomSelect = styled.select`
  display: block;
  font-size: 16px;
  color: #444;
  padding: 0.75rem 1.4rem 0.75rem 0.75rem;
  width: 100%;
  max-width: 100%;
  margin: 0;
  border: 1px solid ${colors.system.lightGrey_5};
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpolygon points='12,17.03 2.99,8.03 3.7,7.32 12,15.62 20.3,7.32 21.01,8.03 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.5em auto, 100%;
  padding-left: 1rem;
  &::-ms-expand {
    display: none;
  }
  &:hover:not([disabled]) {
    border-color: ${colors.system.grey_1};
  }
  &:focus:not([disabled]) {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  & option {
    font-weight: normal;
  }
  padding-right: 3em;
`

export interface Option {
  option: string
  id: string
}

interface SelectProps {
  options: Option[]
  label?: string
  id: string
  emptyStr?: string
  required?: boolean
  returnId?: boolean
  /**
   * I'm not sure if this is right typing for this
   * */
  nativeProps?: SelectHTMLAttributes<HTMLSelectElement>
  errorMsg?: string
}

export const Select = forwardRef(
  (
    {options, label, id, nativeProps, emptyStr, required, returnId, errorMsg}: SelectProps,
    ref: Ref<HTMLSelectElement>
  ) => {
    const empty = !!emptyStr ? (
      <option key="empty" disabled value="">
        {emptyStr}
      </option>
    ) : (
      undefined
    )
    const rest = options.map(o => (
      <option key={o.id} value={returnId === true ? o.id : undefined}>
        {o.option}
      </option>
    ))
    return (
      <LabelInputWrapper>
        {label ? (
          <Label required={required} htmlFor={id}>
            {label}
          </Label>
        ) : null}
        <CustomSelect {...nativeProps} id={id} name={id} ref={ref} aria-label={label ? undefined : id}>
          {[empty, ...rest]}
        </CustomSelect>
        {!!errorMsg && <InputErrorMessage>{errorMsg}</InputErrorMessage>}
      </LabelInputWrapper>
    )
  }
)

const TextAreaWrapper = styled.div``
export const StyledTextArea = styled.textarea`
  border: 1px solid ${colors.system.lightGrey_5};
  padding: 1rem 0.5rem;
  border: 1px solid ${colors.system.lightGrey_5};
  background-color: ${colors.system.lightGrey_5};
  min-height: 20vmin;
  width: 100%;
  margin: 0;
  display: block;
  resize: none;
  outline: none;
  &::placeholder {
    color: ${colors.system.grey_50};
  }
  &:disabled {
    cursor: not-allowed;
  }
`

interface LabelTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  labelText?: string
  errorMsg?: string
}
export const LabelTextArea = forwardRef(({labelText, ...p}: LabelTextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
  return (
    <LabelInputWrapper>
      <Label htmlFor={p.id} className={p.required ? 'required' : undefined}>
        {labelText || p.name}
      </Label>
      <StyledTextArea {...p} ref={ref} aria-label={labelText || p.name}></StyledTextArea>
      {!!p.errorMsg && <InputErrorMessage>{p.errorMsg}</InputErrorMessage>}
    </LabelInputWrapper>
  )
})

interface StyledTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {}
export const TextArea = forwardRef(({...p}: StyledTextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
  return (
    <TextAreaWrapper>
      <StyledTextArea {...p} ref={ref}></StyledTextArea>
    </TextAreaWrapper>
  )
})

interface FileInputProps {
  label?: string
  id: string
  icon: ReactNode
  text?: string
}
export const FileInput = forwardRef(({...p}: FileInputProps, ref: Ref<HTMLTextAreaElement>) => {
  const handleFakeUpload = () => {
    // TODO! actually handle the upload!
    const el = document.getElementById(p.id)!
    el.click()
  }

  if (!p.text) {
    return (
      <>
        <IconButton buttonProps={{onClick: () => handleFakeUpload()}} icon={p.icon} />
        <input style={{display: 'none'}} id={p.id} type="file" name={p.id} />
      </>
    )
  }

  return (
    <>
      <IconText onClick={handleFakeUpload} icon={p.icon} text={p.text} />
      <input style={{display: 'none'}} id={p.id} type="file" name={p.id} />
    </>
  )
})

const TimePickerWrapper = styled.div`
  .react-datepicker-popper {
    .react-datepicker__time-container {
      width: 96px;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 96px;
    }
  }
`
const TimeInput = styled(DefaultDatePicker)`
  color: ${props => (props.disabled ? colors.system.grey_50 : colors.brand.cobalt)};
  border-radius: 4px;
  border: 1px solid ${colors.system.lightGrey_5};
  font-size: 1rem;
  padding: 0.75rem;
  padding-left: 1rem;
  width: 100%;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M12,21.17c-4.72,0-8.56-3.84-8.56-8.56c0-4.72,3.84-8.56,8.56-8.56s8.56,3.84,8.56,8.56C20.56,17.33,16.72,21.17,12,21.17 z M12,5.04c-4.17,0-7.56,3.39-7.56,7.56c0,4.17,3.39,7.56,7.56,7.56s7.56-3.39,7.56-7.56C19.56,8.43,16.17,5.04,12,5.04z'/%3E%3Cpolygon points='16.24,17.24 11.5,13.05 11.5,8.52 12.5,8.52 12.5,12.6 16.9,16.49 '/%3E%3C/g%3E%3Cg%3E%3Crect x='18.54' y='2.35' transform='matrix(0.6402 -0.7682 0.7682 0.6402 2.943 16.4532)' width='1' height='5.48'/%3E%3Crect x='2.23' y='4.58' transform='matrix(0.7686 -0.6397 0.6397 0.7686 -2.1043 4.3516)' width='5.48' height='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7rem top 50%, 0 0;
  background-size: 1.5rem auto, 100%;
  cursor: default;
  &::placeholder {
    color: ${colors.system.lightGrey_5};
  }
  &:hover {
    border-color: ${colors.system.grey_1};
  }
`
interface TimeInputProps {
  date?: Date
  onChange?: (date: Date) => void
  locale: Locale
  minTime?: Date
  maxTime?: Date
  disabled?: boolean
}
export const TimePicker = ({date, onChange, locale, minTime, maxTime, disabled}: TimeInputProps) => {
  const [startDate, setStartDate] = useState(date ? date : new Date())
  const handleDateChange = useCallback(
    (newDate: Date) => {
      setStartDate(newDate)
      if (onChange) {
        onChange(newDate)
      }
    },
    [onChange]
  )

  useEffect(() => {
    if (startDate) {
      let roundedDate = roundMinutes(1, startDate)
      if (!isEqual(startDate, roundedDate)) {
        handleDateChange(roundedDate)
      }
    }
    return () => {
      // unmount
    }
  }, [startDate, handleDateChange])

  return (
    <TimePickerWrapper>
      <TimeInput
        selected={startDate}
        disabled={disabled}
        onChange={(date: Date) => handleDateChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        timeCaption="Time"
        dateFormat="p"
        minTime={minTime}
        maxTime={maxTime}
        locale={locale}
      />
    </TimePickerWrapper>
  )
}

TimePicker.defaultProps = {
  locale: enGB
}
const DatePickerWrapper = styled.div`
  .react-datepicker__day--selected {
    background-color: ${colors.brand.cobalt};
    &:hover {
      background-color: ${tiny(colors.brand.cobalt)
        .darken(2)
        .toHexString()};
    }
  }
`
const DateInput = styled(DefaultDatePicker)`
  color: ${colors.brand.cobalt};
  border-radius: 4px;
  border: 1px solid ${colors.system.lightGrey_5};
  font-size: 1rem;
  padding: 0.75rem;
  padding-right: 3rem;
  padding-left: 1rem;
  width: 100%;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' xml:space='preserve'%3E%3Cpath d='M16.33,4.54V3.25h-1v1.29H8.25V3.25h-1v1.29H2.79V20.9h18V4.54H16.33z M19.79,5.54v2.52h-16V5.54H19.79z M3.79,19.9V9.07h16V19.9H3.79z'/%3E%3Cg%3E%3Crect x='6.54' y='11.51' width='2' height='2'/%3E%3Crect x='10.79' y='11.51' width='2' height='2'/%3E%3Crect x='15.04' y='11.51' width='2' height='2'/%3E%3C/g%3E%3Cg%3E%3Crect x='6.54' y='15.46' width='2' height='2'/%3E%3Crect x='10.79' y='15.46' width='2' height='2'/%3E%3Crect x='15.04' y='15.46' width='2' height='2'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7rem top 50%, 0 0;
  background-size: 1.5em auto, 100%;
`
interface DateInputProps {
  date?: Date
  onChange?: (date: Date) => void
  locale: Locale
  label?: string
}
export const DatePicker = ({
  date,
  onChange,
  locale = enGB,
  label,
  ...rest
}: DateInputProps & Partial<ReactDatePickerProps>) => {
  const [startDate, setStartDate] = useState(date ? date : new Date())

  const handleDateChange = useCallback(
    (newDate: Date) => {
      setStartDate(newDate)
      if (onChange) {
        onChange(newDate)
      }
    },
    [onChange]
  )

  return (
    <DatePickerWrapper>
      {!!label && <Label style={{display: 'block'}}>{label}</Label>}
      <DateInput
        {...rest}
        selected={rest.selected || startDate}
        onChange={(date: Date) => handleDateChange(date)}
        locale={locale}
        dateFormat={'P'}
      />
    </DatePickerWrapper>
  )
}
DatePicker.defaultProps = {
  locale: enGB
}
