import React, {useState} from 'react'
import {useAppState} from '../../../state'
import styled from 'styled-components'
import {getHumanizedWeekdays} from '../../Atoms/Utils'

import {CheckboxButton} from '../../Atoms/Forms'

const WeekWidgetWrapper = styled.div`
  > button {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.25rem;
    padding: 0;
    border-radius: 50%;
  }
`

interface SelectWeekdayProps {
  selectedWeekDays?: number[]
  onChange?: (weekDays: number[]) => void
}

const SelectWeekdayWidget = ({selectedWeekDays, onChange}: SelectWeekdayProps) => {
  const {state} = useAppState()
  const [selectedWeekdays, setSelectedWeekdays] = useState<number[]>(selectedWeekDays ? selectedWeekDays : [])
  const weekdays = getHumanizedWeekdays(state.site!.locale)

  const handleClick = (id: number) => {
    // toggle selection
    let currentSelected = selectedWeekdays
    if (currentSelected.includes(id)) {
      currentSelected = currentSelected.filter(item => item !== id)
    } else {
      currentSelected = [...currentSelected, id]
    }

    // set state and call callback
    setSelectedWeekdays(currentSelected)
    if (onChange) {
      onChange(currentSelected)
    }
  }

  return (
    <WeekWidgetWrapper>
      {weekdays.map(wd => (
        <CheckboxButton checked={selectedWeekdays.includes(wd.id)} key={wd.id} onClick={() => handleClick(wd.id)}>
          {wd.short}
        </CheckboxButton>
      ))}
    </WeekWidgetWrapper>
  )
}

export default SelectWeekdayWidget
