import MessageCardCarousel from '../../Components/Views/Messages/MessageCardCarousel'
import React from 'react'
import {MessagesByStatus} from '../../state/messages/state'
import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import {Icon, Text, Title} from '../../sharedComponents/components'
import {useTranslation} from 'react-i18next'

interface ActiveMessagesProps {
  messagesByStatus: MessagesByStatus
  onMessageClick: any
}

const ActiveMessages = ({messagesByStatus, onMessageClick}: ActiveMessagesProps) => {
  const {t} = useTranslation(['home', 'tasks', 'alarms'])
  return (
    <>
      <ActiveMessagesTitle data-cy="alarm-container-title">
        <Title level={5} strong>
          {t('home:labels.activeMessage.title', 'Active messages')}
        </Title>
        <Title level={6} strong>
          ({t('home:labels.activeMessage.unread', 'Unread')} {messagesByStatus.unreadMessages.length})
        </Title>
      </ActiveMessagesTitle>
      <CarouselContainer>
        {messagesByStatus.readMessages.length == 0 && messagesByStatus.unreadMessages.length == 0 ? (
          <NoAlarmPlaceHolder>
            <Icon type="happy-face" height="4em" width="4em" fill={colors.system.grey_3} />
            <Text size="M" color={colors.system.grey_50}>
              {t('home:labels.activeMessages.noMessages.placeholder', 'There are no messages currently.')}
            </Text>
          </NoAlarmPlaceHolder>
        ) : (
          <MessageCardCarousel messagesByStatus={messagesByStatus} onMessageClick={onMessageClick} />
        )}
      </CarouselContainer>
    </>
  )
}

export default ActiveMessages

const NoAlarmPlaceHolder = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.2rem 1rem;
  border: 1px solid ${colors.system.grey_4};
  border-radius: 0.5rem;
  align-items: center;
`
const CarouselContainer = styled.div`
  min-width: 30%;
  width: 50vmin;
  padding-bottom: 30px;
  position: relative;
`

const ActiveMessagesTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -5%;
`
