import {baseApi} from '../../rest'
import {AllSitesOverview} from './state'

export const homeApi = {
  ...baseApi,
  async getAllSitesOverview(): Promise<AllSitesOverview> {
    const request = await this.doRequest({path: '/v1/home/overview/sites/all'})
    const response = await request.get()
    return (response as unknown) as AllSitesOverview
  }
}
