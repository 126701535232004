import React, {useEffect, useState} from 'react'
import {useAppState} from '../../../state'
import {NewsTickerType, NotificationType} from '../../../state/state'
import {useTranslation} from 'react-i18next'
import {Row, Baseline} from '../../Layout/Grid'
import {Button} from '../../Atoms/Buttons'
import {Heading} from '../../Atoms/Typography'
import {Toggler, LabelInput, Select} from '../../Atoms/Forms'
import {useForm} from 'react-hook-form'
import i from 'i18next'
import {v4 as uuid} from 'uuid'

interface NewsTickerForm {
  id: string
  type: NewsTickerType
  enabled: boolean
  content: string
}

const EditNewsTicker = () => {
  const {effects, state, actions} = useAppState()
  const {t} = useTranslation('admin')

  // TODO with current implementation one can simply edit the 1st (and only) news ticker.
  // but later on, one probably you add/delete multiple tickers here.
  const newsTickers = state.newsTickers
  const firstTicker = newsTickers && newsTickers.length > 0 ? newsTickers[0] : null
  let [togglerKey, setTogglerKey] = useState(0) // local state just to contain random key for toggler component (needed for re-rendering it after form reset)

  const fc = useForm<NewsTickerForm>({
    defaultValues: firstTicker ? {...firstTicker} : undefined,
    mode: 'onChange'
  })

  useEffect(() => {
    if (firstTicker) {
      fc.setValue('type', firstTicker.type)
      fc.setValue('content', firstTicker.content)
      fc.setValue('enabled', !!firstTicker.enabled)
    }
  }, [firstTicker])

  const handleNewsTickerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!firstTicker) return
    try {
      await effects.adminApi.updateNewsTicker({
        ...fc.getValues(),
        id: firstTicker.id
      })

      await i.loadNamespaces('msg')
      actions.addNotification({
        id: uuid(),
        type: NotificationType.INFO,
        title: i.t('msg:newsTickerUpdated', 'News ticker changes saved'),
        description: i.t('msg:newsTickerUpdatedDescription', ''),
        visible: true,
        hideAfterDelay: 1500
      })

      fc.reset(fc.getValues()) // reset form with new values
      await actions.getNewsTickers()
    } catch (e) {
      console.error(e)
      await i.loadNamespaces('msg')
      actions.addNotification({
        id: uuid(),
        type: NotificationType.ERROR,
        title: i.t('msg:newsTickerError', 'Could not update news ticker'),
        description: i.t('msg:newsTickerErrorDescription', ''),
        visible: true,
        hideAfterDelay: 6000
      })
    }
  }

  return (
    <>
      {newsTickers && newsTickers.length > 0 ? (
        <form onSubmit={handleNewsTickerSubmit}>
          <Baseline margin="2rem">
            <Heading level={5}>{t('admin:labels.newsTicker', 'News ticker')}</Heading>
            <Select
              required
              returnId
              id="type"
              emptyStr={t('admin:labels.selectTickerType', 'Select ticker type')}
              label={t('admin:labels.tickerType', 'Ticker type')}
              ref={fc.register}
              options={[{option: 'Info', id: 'INFO'}]}
            />
            <LabelInput
              required
              name="content"
              labelText={t('admin:labels.tickerText', 'Ticker text')}
              type="text"
              ref={fc.register({required: t('common:validation.requiredField', 'This field is required') as string})}
              errorMsg={fc.errors.content?.message}
            />
            <Toggler
              key={togglerKey}
              values={[t('common:togglerValues.off', 'Off'), t('common:togglerValues.on', 'On')]}
              label={t('admin:labels.tickerStatus', 'Ticker status')}
              initiallyChecked={!!firstTicker!.enabled}
              name="enabled"
              ref={fc.register}
            />
            <Row childMargin="1rem">
              <Button
                variant="secondary"
                negative
                disabled={!fc.formState.dirty}
                onClick={() => {
                  fc.reset()
                  setTogglerKey(new Date().getTime()) // force re-rendering
                }}
              >
                {t('common:buttons.cancel', 'Cancel')}
              </Button>
              <Button variant="secondary" type="submit" disabled={!fc.formState.dirty}>
                {t('common:buttons.save', 'Save')}
              </Button>
            </Row>
          </Baseline>
        </form>
      ) : null}
    </>
  )
}

export default EditNewsTicker
