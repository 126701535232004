import {baseApi} from '../../rest'
import {GatewayLatestValues, GatewaySignalStrength} from './state'

export const gatewayApi = {
  ...baseApi,
  async getGatewayLatestValues(siteId: string): Promise<GatewayLatestValues[]> {
    const request = await this.doRequest<GatewayLatestValues[]>({
      path: `/v1/settings/gateway/latest?siteId=${siteId}`
    })
    const response = await request.get()
    return response.data
  },
  async getGatewaySignalStrengths(siteId: string): Promise<GatewaySignalStrength[]> {
    const request = await this.doRequest<GatewaySignalStrength[]>({
      path: `/v1/settings/gateway/signalStrengths?siteId=${siteId}`
    })
    const response = await request.get()
    return response.data
  }
}
