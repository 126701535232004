import React from 'react'
import {Button} from '../../../sharedComponents/components'
import styled from 'styled-components'
import {colors} from '../../../sharedComponents/colors'
import {Message} from '../../../state/messages/state'

interface MessageCardProps {
  message: Message
  headingColor: string
  onMessageClick: any
}

const MessageCard = ({message, headingColor, onMessageClick}: MessageCardProps) => {
  return (
    <CardContainer
      onClick={() => {
        onMessageClick(message)
      }}
    >
      <HeadingContainer backgroundColor={headingColor}>
        <StyledHeading data-cy="card-heading" color={colors.system.white}>
          {message.heading}
        </StyledHeading>
      </HeadingContainer>
      <ContentContainer>
        <EllipsisText data-cy="card-content" lines={7}>
          {message.content}{' '}
        </EllipsisText>
      </ContentContainer>
    </CardContainer>
  )
}

const CardContainer = styled(Button)`
  border: 1px solid #ccc;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 23vmin;
  height: 230px;
  background-color: ${colors.system.white};
  text-align: left;
  position: relative;
`

const HeadingContainer = styled.div<{backgroundColor: string}>`
  padding: 16px; /* Padding for the heading */
  background-color: ${({backgroundColor}) => backgroundColor}; /* Dynamic background color */
  height: 30%;
  justify-content: baseline;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
  position: relative;
  text-overflow: ellipsis;
`

const StyledHeading = styled.h4`
  font-family: 'FavoritMonoStd-Regular';
  font-weight: 500;
  font-size: 0.875rem; /*14px;*/
  line-height: 1.2rem; /*18px;*/
  letter-spacing: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 100%;
`

const ContentContainer = styled.div`
  padding: 16px;
  width: 100%;
  height: 70%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`
const EllipsisText = styled.div<{lines: number}>`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(6 * 1.4em);
  width: 100%;
  line-height: 1.4;
  height: 100%;
  max-width: 100%;
  font-size: 0.875rem;
  font-style: 'normal';
  font-family: 'FavoritStd-Book';
  color: ${colors.brand.black};
  white-space: pre-line;
`

export default MessageCard
