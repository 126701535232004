import React from 'react'
import {Title, P, H6} from '../../Atoms/Typography'
import {LabelInput} from '../../Atoms/Forms'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InvisibleContainer, Baseline, Row} from '../../Layout/Grid'
import {Button, TextButton} from '../../Atoms/Buttons'
import {useAppState} from '../../../state'
import {useNavigation} from 'react-navi'
import {colors} from '../../../sharedComponents/colors'
import {LoginLogo, TitleWrapper} from './LoginComponents'
import {Helmet} from 'react-navi-helmet-async'

interface Form {
  password: string
  confirmPassword: string
  code: string
}
interface Creds {
  email: string
}
const ForgotPasswordConfirm = ({email}: Creds) => {
  const navi = useNavigation()
  const fc = useForm<Form>({mode: 'onChange'})
  const {actions} = useAppState()
  const {t} = useTranslation(['users', 'auth'])

  const {password, confirmPassword, code} = fc.watch()
  const codeErrorMessage =
    code && code.length < 6 ? t('common:validation.resetCodeTooShort', 'Code too short') : undefined
  const passwordErrorMessage: string | undefined =
    confirmPassword !== password
      ? t('common:validation.passwordsNotMatching', 'New passwords must match')
      : password && confirmPassword.length < 8
      ? t('common:validation.passwordTooShort', 'New password is too short, must be at least 8 characters')
      : password && (!/[a-z]/.test(password) || !/[A-Z]/.test(password))
      ? t('common:validation.passwordFormatWrong', 'New password must contain both lowercase and uppercase letters')
      : undefined

  const handleConfirm = () => {
    const {password, code} = fc.watch()
    actions
      .confirmPassword({email, password, code})
      .then(() => {
        navi.navigate('/login/forgot/complete')
      })
      .catch(e => {
        console.error(e)
        //TODO! display invalid code in the input field instead of toasting
      })
  }

  return (
    <InvisibleContainer>
      <Helmet title={t('common:routes.confirmForgotPassworReset', 'Confirm forgot password reset')} />
      <LoginLogo />
      <TitleWrapper>
        <Title level={2} color={colors.system.white}>
          {t('auth:labels.forgotPassword', 'Forgot password?')}
        </Title>
      </TitleWrapper>
      <Baseline>
        <P color={colors.system.white}>
          {t(
            'auth:messages.enterConfirmation',
            'Enter confirmation code sent via email and new password to reset your password.'
          )}
        </P>
        <LabelInput
          negative={true}
          autoComplete="one-time-code"
          labelText={t('auth:labels.code', 'Code')}
          name="code"
          id="code"
          type="text"
          ref={fc.register({required: true})}
          errorMsg={codeErrorMessage}
        />
        <H6 style={{color: 'white'}}>
          {t(
            'common:validation.passwordLength',
            'Your password must contain minimum 8 characters, lowercase letters, and uppercase letters.'
          )}
        </H6>
        <LabelInput
          negative={true}
          autoComplete="new-password"
          labelText={t('users:labels.newPassword', 'New password')}
          name="password"
          id="password"
          type="password"
          ref={fc.register({required: true})}
          errorMsg={passwordErrorMessage}
        />
        <LabelInput
          negative={true}
          autoComplete="new-password"
          labelText={t('users:labels.repeatNewPassword', 'Repeat new password')}
          name="confirmPassword"
          id="confirmPassword"
          type="password"
          ref={fc.register({required: true})}
        />
        <Row>
          <TextButton negative={true} onClick={() => navi.navigate('/login')}>
            {t('auth:labels.backToLogin', 'Back to login')}
          </TextButton>
        </Row>
        <Button
          disabled={!!codeErrorMessage || !!passwordErrorMessage || !fc.formState.isValid}
          onClick={handleConfirm}
        >
          {t('common:buttons.resetPassword', 'Reset password')}
        </Button>
      </Baseline>
    </InvisibleContainer>
  )
}

export default ForgotPasswordConfirm
