import {useState} from 'react'
import {IFileWithMeta, StatusValue, IUploadParams} from 'react-dropzone-uploader'

import {useAppState} from '../../../state'
import {UploadedAsset, AssetUploaderHookParams, AssetUploaderHookReturnProps} from './types'
const comp = require('browser-image-resizer').readAndCompressImage

const config = {
  quality: 0.5,
  maxWidth: 1024,
  maxHeight: 1024,
  autoRotate: true,
  debug: true,
  mimeType: 'image/jpeg'
}

const useAssetUploader = (props: AssetUploaderHookParams): AssetUploaderHookReturnProps => {
  const {actions} = useAppState()
  const [assetName, setAssetName] = useState('')

  const getUploadParams = async ({file, meta}: IFileWithMeta): Promise<IUploadParams> => {
    let resizedImage = file
    if (meta.type.includes('image')) {
      resizedImage = await comp(file, config)
      meta.type = 'image/jpeg' // resizer converts image always to JPEG
    }
    // Get S3 signed Url from backend
    const fileUploadPayload = {
      fileType: meta.type,
      fileName: meta.name,
      instructions: props.instructions ? true : false
    }
    const {uploadURL, filePath} = await actions.createUploadUrl(fileUploadPayload)
    console.debug('getUploadParams', {fileUploadPayload, uploadURL, filePath})

    setAssetName(filePath)

    return {
      method: 'PUT',
      body: resizedImage,
      meta: meta,
      url: uploadURL
    }
  }

  const onChangeStatus = async (
    fileWithMeta: IFileWithMeta,
    status: StatusValue,
    filesWithMeta: IFileWithMeta[]
  ): Promise<void> => {
    console.debug(`File status changed to ${status}`, {fileWithMeta})
    // When status of a file changes to StatusValue.done create an asset to the database
    if (status === 'done') {
      const asset: UploadedAsset = {
        name: fileWithMeta.meta.name,
        path: `${assetName}`,
        mimeType: fileWithMeta.meta.type,
        size: fileWithMeta.meta.size,
        instructions: props.instructions
      }
      await props.addAsset(asset).then(asset => {
        props.onAssetSaved(asset)
      })
      fileWithMeta.remove()
    }
  }

  return {
    getUploadParams,
    onChangeStatus
  }
}

export default useAssetUploader
