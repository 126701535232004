import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconAlarm = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3.25714C12.5438 3.25714 3.25714 12.5438 3.25714 24C3.25714 35.4562 12.5438 44.7429 24 44.7429C35.4562 44.7429 44.7429 35.4562 44.7429 24C44.7429 12.5438 35.4562 3.25714 24 3.25714ZM2 24C2 11.8495 11.8495 2 24 2C36.1505 2 46 11.8495 46 24C46 36.1505 36.1505 46 24 46C11.8495 46 2 36.1505 2 24Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.5725 11.625V28.125H23.3125V11.625H24.5725Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.5725 32.25V36.375H23.3125V32.25H24.5725Z" />
    </svg>
  )
}
