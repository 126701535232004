import * as React from 'react'
import styled from 'styled-components'

const IconTextWrapper = styled.div`
  display: grid;
  grid-template-rows: 30px 1fr;
  align-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  padding: 0.25rem;
  background-color: transparent;
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  outline: none;
  .icon {
    align-content: center;
    display: grid;
  }
  .active {
    .text {
      background-color: red;
      color: red;
    }
  }
`

const IconTextRow = styled(IconTextWrapper)`
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;
  text-align: left;
  .icon {
    display: grid;
    align-content: center;
    justify-items: center;
  }
  .text {
    justify-self: start;
  }
`
const IconTextColumn = styled(IconTextWrapper)`
  grid-template-rows: auto 1fr;
  grid-row-gap: 0.3rem;

  .icon {
    align-content: center;
    display: grid;
  }
  .text {
    font-size: 0.9rem;
    white-space: nowrap;
  }
`

export interface IconTextProps {
  /**
   * Icon file. Can be any component
   */
  icon: React.ReactNode
  /**
   * Text to show next to or under the icon
   */
  text: React.ReactNode
  /**
   * Size of Text
   */
  textSize?: string
  /**
   * use this to set text under the icon
   */
  isColumn: boolean
  /**
   * if item should be clickable
   */
  onClick?: (e: React.MouseEvent) => void
  /**
   * is the item active
   */
  isActive?: boolean

  'data-cy'?: string
}

export const IconText = (props: IconTextProps) => {
  return props.isColumn ? (
    <IconTextColumn
      onClick={props.onClick}
      className={props.isActive ? 'active' : undefined}
      data-cy={props['data-cy']}
    >
      <span className="icon">{props.icon}</span>
      <span className="text">{props.text}</span>
    </IconTextColumn>
  ) : (
    <IconTextRow onClick={props.onClick} className={props.isActive ? 'active' : undefined} data-cy={props['data-cy']}>
      <span className="icon">{props.icon}</span>
      <span className="text">{props.text}</span>
    </IconTextRow>
  )
}

IconText.defaultProps = {
  isColumn: false,
  isActive: false
}
