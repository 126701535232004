import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {Title, Button, Modal, ModalProps} from '../../sharedComponents/components'
import {colors} from '../../sharedComponents/colors'

import PerformTodoTask from './GeneralTasks/PerformTodoTask'
import PerformTemperatureTask from './GeneralTasks/PerformTemperatureTask'
import PerformHygieneTask from './GeneralTasks/PerformHygieneTask'
import PerformWasteTask from './GeneralTasks/PerformWasteTask'
import PerformSingleChoiceTask from './GeneralTasks/PerformSingleChoiceTask'
import PerformCoolingTask from './GeneralTasks/PerformCoolingTask'
import PerformCoolerCoolingTask from './GeneralTasks/PerformCoolerCoolingTask'
import PerformManualTask from './GeneralTasks/PerformManualTask'
import PerformEquipmentTemperatureTask from './GeneralTasks/PerformEquipmentTemperatureTask'
import TaskInstruction from './GeneralTasks/TaskInstruction'
import {usePerformingTasks} from './GeneralTasks/usePerformingTasks'
import {translateTaskGroupName} from '../../config/utils'
import {getTaskUmbrellaColor} from '../home/UnscheduledTaskModal'
import {EventPayload} from 'state/rest'

interface ContentModalProps {
  task: any
  payload: EventPayload
  setValue: (key: keyof EventPayload, value: any) => void
  onAssetSaved: () => void
  handleSubmit: () => Promise<void>
  handleUpdate: () => Promise<void>
  handleRemove: () => Promise<void>
  handleNumberBlur: (value: string) => void
  t: any
}

const ContentForModal = ({
  task,
  payload,
  setValue,
  onAssetSaved,
  handleSubmit,
  handleRemove,
  handleUpdate,
  handleNumberBlur,
  t
}: ContentModalProps) => {
  let content
  let isInputInvalid = false
  if (task) {
    const type = task.taskType.id
    const invalidMeasurementTarget =
      !payload.suggestions || payload.suggestions.length === 0 || payload.suggestions[0].length === 0
    payload.incompleteToDo = task.taskType.id === 'todo' && task.subtasks && task.subtasks.length > 0
    switch (type) {
      case 'todo':
        content = (
          <PerformTodoTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            handleRemove={handleRemove}
          />
        )
        break
      case 'temperature':
        content = (
          <PerformTemperatureTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleRemove={handleRemove}
            handleNumberBlur={handleNumberBlur}
          />
        )
        isInputInvalid = !payload.value || invalidMeasurementTarget
        break
      case 'equipmentTemperature':
        content = (
          <PerformEquipmentTemperatureTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleNumberBlur={handleNumberBlur}
            handleRemove={handleRemove}
          />
        )
        isInputInvalid = !payload.value
        break
      case 'hygiene':
        content = (
          <PerformHygieneTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleNumberBlur={handleNumberBlur}
            handleRemove={handleRemove}
          />
        )
        isInputInvalid = !payload.value
        break
      case 'waste':
        content = (
          <PerformWasteTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleNumberBlur={handleNumberBlur}
            handleRemove={handleRemove}
          />
        )
        isInputInvalid = !payload.value
        break
      case 'singlechoice':
        content = (
          <PerformSingleChoiceTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleRemove={handleRemove}
          />
        )
        isInputInvalid = !payload.choice
        break
      case 'cooling':
        content = (
          <PerformCoolingTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleUpdate={handleUpdate}
            handleNumberBlur={handleNumberBlur}
            handleRemove={handleRemove}
          />
        )
        isInputInvalid = !payload.value || invalidMeasurementTarget
        break
      case 'automaticCooling':
        content = (
          <PerformCoolerCoolingTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleRemove={handleRemove}
            handleUpdate={handleUpdate}
          />
        )
        isInputInvalid = invalidMeasurementTarget
        break
      case 'manual':
        content = (
          <PerformManualTask
            task={task}
            setValue={setValue}
            onAssetSaved={onAssetSaved}
            payload={payload}
            handleNumberBlur={handleNumberBlur}
            handleRemove={handleRemove}
          />
        )
        isInputInvalid = !payload.value
        break
      default:
        content = <div>{t('tasks:messages.unsupported', 'Unsupported type')}</div>
        break
    }
  } else {
    content = null
  }

  return {content, isInputInvalid}
}
interface TaskModalProps extends ModalProps {
  task: any
  onFormSubmit?: (formData: any) => void
}
export const TaskModal: React.FC<TaskModalProps> = ({isOpen, onClose, task, onFormSubmit, ...rest}) => {
  const {t} = useTranslation()
  const onSubmit = () => onClose?.()

  const initialPayload = {
    task: task,
    choice: '',
    value: task.value ? task.value : undefined, // 0.0 for equipmentTemperature
    reportedActor: undefined,
    asset: task.asset as any, // from cooling task
    remarks: task.notes,
    suggestions: task.suggestions,
    scheduledAt: task.scheduleInstance,
    incompleteToDo: undefined,
    ingredients: task.ingredients
  }

  const {
    loading,
    setValue,
    onAssetSaved,
    payload,
    handleUpdate,
    handleSubmit,
    handleRemove,
    handleNumberBlur
  } = usePerformingTasks(initialPayload, task.eventId || undefined, onSubmit)

  const {content, isInputInvalid} = ContentForModal({
    task,
    payload,
    setValue,
    onAssetSaved,
    handleSubmit,
    handleUpdate,
    handleRemove,
    handleNumberBlur,
    t
  })

  if (!content) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={translateTaskGroupName(task.taskGroup.name)}
      height="90%"
      maxWidth="1000px"
      headerLeftItem={
        <Button buttonStyle="secondary" buttonSize="small" data-cy="perform-task-cancel" onClick={onClose}>
          {t('common:buttons.cancel', 'Cancel')}
        </Button>
      }
      headerRightItem={
        <Button
          buttonStyle="primary"
          buttonSize="small"
          data-cy="perform-task-save"
          data-testid="submit-button"
          onClick={
            task.status === 'in-progress' || (task.status === 'active' && task.taskType.id === 'automaticCooling')
              ? handleUpdate
              : handleSubmit
          }
          disabled={isInputInvalid || loading}
        >
          {task.taskType.id === 'todo' && task.subtasks && task.subtasks.length > 0
            ? t('tasks:actions.saveIncomplete', 'Save Incomplete')
            : t('common:buttons.save')}
        </Button>
      }
      headerBackgroundColor={getTaskUmbrellaColor(task.taskGroup.umbrellaCategory)}
    >
      <ModalContent>
        <InstructionArea>
          <Title level={5} strong>
            {t('tasks:modal.instruction.title', 'Instructions')}
          </Title>
          <TaskInstruction data={task} />
        </InstructionArea>
        <TaskFormArea>{content}</TaskFormArea>
      </ModalContent>
    </Modal>
  )
}

const InstructionArea = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  background-color: ${colors.system.lightGrey_5};
  border-radius: 1rem;
  height: 100%;
  overflow: auto;
  padding: 1.5rem;
`

const TaskFormArea = styled.div`
  height: 100%;
  overflow: auto;
`

const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem;
  height: 90%;
`
