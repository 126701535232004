import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconUsers = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2902 17.2704C29.2073 17.2704 26.7009 19.784 26.7009 22.8757C26.7009 25.9674 29.2073 28.481 32.2902 28.481C35.3731 28.481 37.8795 25.9674 37.8795 22.8757C37.8657 19.784 35.3593 17.2704 32.2902 17.2704M32.29 29.7251C28.5166 29.7251 25.4475 26.6459 25.4475 22.863C25.4475 19.08 28.5166 16.0009 32.29 16.0009C36.0621 16.0009 39.1325 19.08 39.1325 22.863C39.1325 26.6459 36.0496 29.7251 32.29 29.7251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8711 44.7432H44.6971C44.1958 38.233 38.8697 33.2322 32.2903 33.2322C25.711 33.2322 20.3724 38.233 19.8711 44.7432V44.7432ZM46 46.0001H18.5674L18.5799 45.3591C18.7804 37.8573 24.7958 31.9755 32.2762 31.9755C39.7591 31.9755 45.7744 37.8573 45.975 45.3591L46 46.0001Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9158 22.5988C19.6449 22.0207 17.2764 19.1803 17.2764 15.8624C17.2764 12.0794 20.3467 9.00024 24.1189 9.00024C27.1641 9.00024 29.7959 10.986 30.6481 13.9395L29.445 14.2914C28.7432 11.8783 26.6002 10.257 24.1063 10.257C21.0234 10.257 18.517 12.7706 18.517 15.8624C18.517 18.5771 20.4595 20.8896 23.1288 21.3671L22.9158 22.5988Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5496 38.3844L10.2964 38.3467C10.4844 31.1578 16.2491 25.2886 23.4174 24.9995L23.4676 26.2563C16.9509 26.5202 11.725 31.8491 11.5496 38.3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6194 15.5986C11.3485 15.0204 8.97998 12.1801 8.97998 8.86212C8.97998 5.07916 12.0503 2 15.8225 2C18.8176 2 21.512 4.03602 22.3642 6.93922L21.1611 7.29113C20.4593 4.87807 18.3164 3.2568 15.8225 3.2568C12.7396 3.2568 10.2332 5.7704 10.2332 8.86212C10.2332 11.5768 12.1757 13.8893 14.845 14.3669L14.6194 15.5986Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2532 31.3711L2 31.3334C2.18798 24.1445 7.95272 18.2753 15.121 17.9862L15.1712 19.243C8.65451 19.5195 3.42865 24.8496 3.2532 31.3711"
      />
    </svg>
  )
}
