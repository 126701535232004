import * as React from 'react'
import styled from 'styled-components'
import tiny from 'tinycolor2'

import {Text} from '../../atoms/Text'
import {colors} from '../../../colors'

export interface SuspendedPeriod {
  id: string
  siteId: string
  actor: string
  createdAt: Date
  startAt: Date
  endAt: Date
  alarmsEnabled: boolean
  reason: string
}

export interface Site {
  id: string
  address?: string
  city?: string
  contactEmail?: string
  contactName?: string
  contactPhone?: string
  internalId?: string
  country?: string
  description?: string
  postalCode?: string
  state?: string
  locale: string //  "fi-FI"
  name: string //  "Kamppi"
  temperatureUnit: string //  "C"
  timeZone: string //  "Europe/Helsinki"
  weightUnit: string //  "G"
  suspendedPeriods: SuspendedPeriod[]
}

export interface Location {
  id: string
  name: string
  sites: Site[]
  visible: boolean
}

export interface LocationGroup {
  id: string
  name: string
  locations: Location[]
  visible: boolean
}

export interface Chain {
  id: string
  name: string
  description?: string
  brands: Brand[]
}

export interface Brand {
  id: string
  name: string
  locationGroups: LocationGroup[]
  visible: boolean
}

export interface SiteSelectorProps {
  locationGroups: LocationGroup[]
  chain: Chain
  selectedSiteName: string
  chainSelectText: string
  onClick: (a: {id: string; name: string; type: OrgLevelType}) => void
}

export enum OrgLevelType {
  ORG = 'org',
  CHAIN = 'chain',
  BRAND = 'brand',
  LOCATION_GROUP = 'group',
  LOCATION = 'location',
  SITE = 'site'
}

const SiteText = styled.div`
  margin: 0 3rem 1rem 3rem;
  text-align: left;
`

const SiteSelectorModal = styled.div`
  min-width: 336px;
`

const Divider = styled.hr`
  margin: 1rem 0 0.5rem 0;
  border: transparent;
  border-bottom: 1px solid
    ${tiny(colors.system.lightGrey_5)
      .darken(15)
      .toHexString()};
`

const LocationGroupSection = styled.div`
  margin: 1rem 1.5rem;
`

export const SiteSelector = (props: SiteSelectorProps) => {
  const {chain, locationGroups, onClick, chainSelectText} = props
  return (
    <SiteSelectorModal data-cy="site-selector-modal">
      <LocationGroupSection>
        <Text size="S" onClick={() => onClick({id: chain.id, name: chain.name, type: OrgLevelType.CHAIN})}>
          {chainSelectText}
        </Text>
      </LocationGroupSection>
      <Divider />
      {locationGroups.map((locationGroup, key) => (
        <div key={locationGroup.id}>
          <LocationGroupSection>
            {locationGroup.visible && (
              <Text
                size="S"
                onClick={() =>
                  onClick({
                    id: locationGroup.id,
                    name: locationGroup.name,
                    type: OrgLevelType.LOCATION_GROUP
                  })
                }
              >
                {locationGroup.name}
              </Text>
            )}
          </LocationGroupSection>
          {locationGroup.locations.map(location => (
            <div key={location.id}>
              {location.sites.map(site => (
                <SiteText key={site.id}>
                  <Text
                    size="S"
                    onClick={() => onClick({id: site.id, name: site.name, type: OrgLevelType.SITE})}
                    key={site.id}
                  >
                    {location.visible ? location.name + ' - ' + site.name : site.name}
                  </Text>
                </SiteText>
              ))}
            </div>
          ))}
          {key !== locationGroups.length - 1 && <Divider />}
        </div>
      ))}
    </SiteSelectorModal>
  )
}
