import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconDocument = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2H31.4485L39 10.1714V46H9V2ZM30.6002 10.8H37.8V44.7429H10.2V3.25714H30.6002V10.8ZM31.8002 4.14632L36.9514 9.54283H31.8002V4.14632ZM14.3996 40.3428H33.5996V39.0857H14.3996V40.3428ZM33.5996 27.7715H14.3996V26.5144H33.5996V27.7715ZM14.3996 34.0572H33.5996V32.8H14.3996V34.0572Z"
      />
    </svg>
  )
}
