import {baseApi} from '../../state/rest'
import {Message, MessageReadCountMapping, MessagesByStatus} from './state'

export const messagesApi = {
  ...baseApi,
  async getMessage(id: string) {
    const req = await this.doRequest<Message>({path: `/v1/messages/admin/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async getMessages(site: string) {
    const req = await this.doRequest<MessagesByStatus>({path: '/v1/messages', site})
    const resp = await req.get()
    return resp.data
  },
  async createMessage(payload: Partial<Message>) {
    const req = await this.doRequest({path: '/v1/messages', payload})
    const resp = await req.post()
    return resp
  },
  async updateMessage(payload: Partial<Message>) {
    const req = await this.doRequest({path: `/v1/messages/${payload.id}`, payload})
    const resp = await req.put()
    return resp
  },
  async deleteMessage(id: string) {
    const req = await this.doRequest({path: `/v1/messages/${id}`})
    const resp = await req.delete()
    return resp
  },
  async getAllMessagesForAdmin() {
    const req = await this.doRequest<Message[]>({path: '/v1/messages/admin'})
    const resp = await req.get()
    return resp.data
  },
  async getReadCountForMessage(id: string) {
    const req = await this.doRequest<MessageReadCountMapping>({path: `/v1/messages/${id}/readStatus`})
    const resp = await req.get()
    return resp.data
  },
  async markMessageRead(id: string, payload: object) {
    const req = await this.doRequest({path: `/v1/messages/${id}/readStatus`, payload})
    const resp = await req.put()
    return resp
  }
}
