import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconCloseSmall = (props: SharedIconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00018 8.23817L0.761827 0L0 0.76181L8.23835 8.99998L0 17.2382L0.761827 18L9.00018 9.76179L17.2382 17.9996L18 17.2378L9.76201 8.99998L18 0.762169L17.2382 0.000358462L9.00018 8.23817Z"
        fill={props.fill || colors.brand.cobalt}
      />
    </svg>
  )
}
