export interface TaskSettings {
  [siteName: string]: {
    [taskGroupName: string]: TaskSettingItem[]
  }
}

interface TaskSettingItem {
  id: string
  name: string
  icon: string
  siteExamples: string[]
  siteAmount: number
  target: string
  type: string
}

interface SettingsState {
  data: TaskSettings
  pending: boolean
  error: Error | null
}

export const state: SettingsState = {
  data: {},
  pending: false,
  error: null
}
