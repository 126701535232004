import React from 'react'
import styled from 'styled-components'
import {colors} from '../../../colors'
import {Text} from '../../atoms/Text'
import {Button, ButtonProps} from '../../atoms/Button'
import {ApplianceData} from '../ApplianceData'

interface AlarmCardProps extends ButtonProps {
  value?: number | string
  unit?: string
  minValue?: number | string
  maxValue?: number | string
  minUnit?: string
  maxUnit?: string
  condition?: string
  title?: string
  titleIcon?: JSX.Element
  description?: string
  timestamp?: string
  disabled?: boolean
}

export const AlarmCard: React.FC<AlarmCardProps> = ({
  value,
  unit,
  minValue,
  maxValue,
  minUnit,
  maxUnit,
  condition,
  title,
  titleIcon,
  timestamp,
  description,
  disabled,
  ...rest
}) => {
  return (
    <AlarmCardContainer {...rest} disabled={disabled}>
      <IconContainer>{titleIcon}</IconContainer>
      <AlarmInfo>
        <AlarmDetails>
          <AlarmTextContainer>
            <Text size="S" color={colors.system.grey_1} textTransform="uppercase">
              {condition}
            </Text>
            <Text size="M" style={{paddingBottom: '0.5rem'}}>
              {title}
            </Text>
            <Text size="S">{timestamp}</Text>
          </AlarmTextContainer>
          <ApplianceData
            value={value}
            unit={unit}
            minValue={minValue}
            maxValue={maxValue}
            minUnit={minUnit}
            maxUnit={maxUnit}
            hasAlarm
            compact
          />
        </AlarmDetails>
        {description && (
          <Text size="S" color={colors.system.grey_50} style={{textAlign: 'left'}}>
            {description}
          </Text>
        )}
      </AlarmInfo>
    </AlarmCardContainer>
  )
}

const AlarmCardContainer = styled(Button)<AlarmCardProps>`
  display: grid;
  grid-template-columns: 4rem calc(100% - 4rem);
  justify-content: start;
  align-items: start;
  min-height: 7rem;
  width: auto;
  border-radius: 0.5rem;
  border: 0;
  padding: 0;
  background-color: ${colors.system.white};
  color: ${colors.brand.cobalt};

  :disabled {
    color: ${colors.brand.cobalt};
    background-color: ${colors.system.grey_5};
  }
`

const AlarmDetails = styled.div`
  display: flex;
  padding-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`

const AlarmInfo = styled.div`
  width: 100%;
  padding: 0.5rem;
  padding-top: 0rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const AlarmTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

const IconContainer = styled.div`
  height: 100%;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 0.5rem;
  padding-top: 1rem;
  background-color: ${colors.system.red};
`
