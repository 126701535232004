import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconFridge = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5.14286C9 3.40636 10.344 2 12 2H36C37.656 2 39 3.40636 39 5.14286V15.8286H9V5.14286ZM12 3.25714C11.0064 3.25714 10.2 4.10107 10.2 5.14286V14.5714H37.8V5.14286C37.8 4.10107 36.9936 3.25714 36 3.25714H12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18.3429H39V42.8572C39 44.5922 37.6562 46 36 46H12C10.3438 46 9 44.5922 9 42.8572V18.3429ZM10.2 19.6V42.8572C10.2 43.8979 11.0066 44.7429 12 44.7429H36C36.9934 44.7429 37.8 43.8979 37.8 42.8572V19.6H10.2Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9998 11.4282V6.39966H15.2498V11.4282H13.9998Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2498 22.7423V40.3423H13.9998V22.7423H15.2498Z" />
    </svg>
  )
}
