import React, {useState} from 'react'
import {useAppState} from '../../../state'
import {useTranslation} from 'react-i18next'
import {Baseline, Grid, InvisibleContainer, GridSpan} from '../../Layout/Grid'
import {useRestaurantList, useTaskForm, useTaskGroupSelect} from '../../../config/utils'
import {SingleChoiceTaskResponse} from '../../../state/rest'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {BigLabelInput, Select, Toggler} from '../../Atoms/Forms'
import {MainLayoutWithoutStretch, RightSidebar, MainContent} from '../../Layout/Layout'
import LocationsList from '../../Organisms/Locations/LocationsList'
import Scheduler from '../../Organisms/Scheduler/Scheduler'
import {TodoWidget} from './CreateTodoTask'
import styled from 'styled-components'
import {HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import TaskMenuModal from './TaskMenuModal'
import {P} from '../../Atoms/Typography'
import {CommonInformationFields, TasksGoBackHeaderTitle} from './CommonTasksComponents'

interface FormProps {
  savedData?: SingleChoiceTaskResponse
}
const TASK_KEY = 'singlechoice'

const FakeRadioIcon = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid gray;
  border-radius: 10px;
`
const CreateSingleChoiceTask = ({savedData}: FormProps) => {
  const {t} = useTranslation('tasks')
  const {state} = useAppState()

  const isEditing = !!savedData && !!savedData.id
  const isDuplicate = !!savedData && !savedData.id

  const saved = savedData
    ? isDuplicate && savedData.name
      ? {
          ...savedData,
          name: `${t('tasks:labels.copyOf', 'Copy of')} ${savedData.name.toLowerCase()}`,
          sites: [state.site!]
        }
      : savedData
    : undefined

  // task form controller hook
  const fc = useTaskForm(TASK_KEY, saved)
  const rc = useRestaurantList(isDuplicate ? undefined : saved ? saved.sites : undefined)

  const {taskGroupOptions, selectTaskGroup, selectedTaskGroup} = useTaskGroupSelect(
    TASK_KEY,
    saved ? saved.taskGroup : undefined
  )

  const [choices, setChoices] = useState(saved && saved.choices ? saved.choices : [])
  const taskPayload = {
    taskGroup: selectedTaskGroup,
    choices,
    schedule: fc.scheduledPayload,
    sites: rc.list,
    assets: fc.assets
  }

  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow
          modal={
            isEditing && (
              <TaskMenuModal
                isOpen={fc.modalOpen}
                onClose={() => fc.setModalOpen(false)}
                onDelete={fc.editingDisabled ? undefined : fc.remove}
                onDuplicate={fc.duplicate}
              />
            )
          }
        >
          <TasksGoBackHeaderTitle
            label={
              isEditing
                ? t('tasks:labels.editTask.singlechoice', 'Edit single-choice list task')
                : t('tasks:labels.newTask.singlechoice', 'Add single-choice list task')
            }
            isEditing={isEditing}
          />
          {isEditing && <HeaderActions onMenu={() => fc.setModalOpen(true)} />}
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <Grid>
              <BigLabelInput
                required
                placeholder={t('tasks:placeholders.newTaskName', 'New task name')}
                name="name"
                id="name"
                labelText={t('tasks:labels.taskName', 'Task name')}
                ref={fc.register({
                  required: `${t('common:validation.nameRequired', 'Name field is required')}`
                })}
                errorMsg={!!fc.errors.name ? fc.errors.name!.message : undefined}
              ></BigLabelInput>
              <div className="empty"></div>
            </Grid>
            <Grid>
              <Baseline>
                <Select
                  returnId
                  nativeProps={{
                    name: 'taskGroup',
                    onChange(e) {
                      selectTaskGroup(e.target.value)
                    },
                    value: selectedTaskGroup.id
                  }}
                  id="taskGroup"
                  label={t('tasks:labels.taskGroup', 'Task group')}
                  options={taskGroupOptions}
                />
                <Baseline>
                  <div>
                    <p>{t('tasks:labels.addAlternatives', 'Add alternatives*')}</p>
                    <TodoWidget
                      onChange={setChoices}
                      savedTodos={choices}
                      buttonText={t('tasks:actions.addAlternativeButton', 'Add an alternative')}
                      placeholderText={t('tasks:placeholders.alternative', 'Alternative')}
                      icon={<FakeRadioIcon />}
                    />
                  </div>
                </Baseline>
                <Toggler
                  label={t('tasks:labels.scheduledTask', 'Scheduled task')}
                  values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
                  initiallyChecked={!!saved && !!saved.schedule}
                  name="isScheduledTask"
                  onClick={() => {
                    fc.toggleShow()
                  }}
                />
                {!!fc.scheduledPayload ? (
                  <Scheduler
                    onChange={fc.handleScheduler}
                    schedule={saved ? fc.reverseSchedule(saved.schedule) : undefined}
                  />
                ) : null}
              </Baseline>
              <Baseline>
                <CommonInformationFields useForm={fc} />
              </Baseline>
            </Grid>
            <ButtonRowWrap>
              <div className="empty"></div>
              <ButtonRowGrid>
                <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                  {t('common:buttons.cancel', 'Cancel')}
                </Button>
                <Button
                  variant="secondary"
                  disabled={
                    !fc.formState.isValid ||
                    fc.loading ||
                    rc.list.length === 0 ||
                    fc.editingDisabled ||
                    (choices || []).length === 0
                  }
                  onClick={() => (isEditing ? fc.update(taskPayload) : fc.submit(taskPayload))}
                >
                  {t('common:buttons.save', 'Save')}
                </Button>

                {fc.editingDisabled && (
                  <GridSpan span={2}>
                    <P>
                      {t(
                        'tasks:messages.taskInUseMessage',
                        'This task is in use for locations you don’t have access to. You can not edit or delete it but you can duplicate the task to create a new one'
                      )}
                    </P>
                  </GridSpan>
                )}
              </ButtonRowGrid>
            </ButtonRowWrap>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <RightSidebar>
        <LocationsList
          controller={rc}
          cyKey={'task'}
          label={t('tasks:labels.selectLocation', 'Select which locations use this task')}
        />
      </RightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default CreateSingleChoiceTask
