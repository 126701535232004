import React from 'react'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../state'
import {LabelInput, Label} from '../../Atoms/Forms'
import {Baseline, Row} from '../../Layout/Grid'
import {P, H4, SmallUnit} from '../../Atoms/Typography'
import styled from 'styled-components'

const DishwasherTypeListContainer = styled.div`
  padding-top: 2rem;
`

const DishwasherTypeList = styled.ul``

const DishwasherTypeItem = styled.li`
  position: relative;
  width: 100%;
  padding-bottom: 1.25rem;
  list-style: none;

  :not(:first-child) {
    padding-top: 1.25rem;
  }
`
const DishwasherTypeName = styled.div`
  margin-top: 1rem;
  font-family: 'HelveticaNeue-Medium', 'Helvetica Neue Medium', Helvetica, Arial, 'FreeSans', sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
`
const DishwasherTypeSequenceType = styled.div`
  margin-top: 1rem;
`

interface Props {
  controller: any
}
const EditDishwasherTypes = ({controller: fc}: Props) => {
  const {t} = useTranslation(['appliances'])
  const {site} = useAppState().state
  return (
    <DishwasherTypeListContainer>
      <Baseline>
        <H4>{t('appliances:labels.alarmLimitsTitle', 'Alarm limits')}</H4>
        <DishwasherTypeList>
          {fc.fields && fc.fields.length > 0 ? (
            fc.fields.map((dishwasherType: any, index: number) => {
              const hasPrewash = dishwasherType.sequenceType === 'PREWASH-WASH-RINSE'
              const hasWash = dishwasherType.sequenceType === 'WASH-RINSE' || hasPrewash
              const hasRinse = dishwasherType.sequenceType === 'RINSE' || hasWash || hasPrewash
              return (
                <DishwasherTypeItem key={dishwasherType.dishwasherTypeId}>
                  <Baseline margin={'1rem'}>
                    <input
                      type="hidden"
                      name={`dishwasherTypes[${index}].dishwasherTypeId`}
                      defaultValue={dishwasherType.dishwasherTypeId}
                      ref={fc.register({required: true})}
                    />
                    <input
                      type="hidden"
                      name={`dishwasherTypes[${index}].sequenceType`}
                      defaultValue={dishwasherType.sequenceType}
                      ref={fc.register({required: true})}
                    />
                    <DishwasherTypeName>
                      {t(
                        `appliances:labels.dishwasherSequenceType.${dishwasherType.sequenceType}`,
                        `${dishwasherType.sequenceType}`
                      )}
                    </DishwasherTypeName>
                    {hasPrewash && (
                      <>
                        <DishwasherTypeSequenceType>
                          <Label>{t('appliances:labels.dishwasherSequence.Prewash', 'Prewash')}</Label>
                        </DishwasherTypeSequenceType>
                        <Row childMargin="1rem" align="flex-end">
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.dishwasherTypes?.[`${index}`]?.prewashMinValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`dishwasherTypes[${index}].prewashMinValue`}
                            defaultValue={dishwasherType.prewashMinValue}
                            labelText={t('common:general.alarmMin', 'Alarm min')}
                          />
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.dishwasherTypes?.[`${index}`]?.prewashMaxValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`dishwasherTypes[${index}].prewashMaxValue`}
                            defaultValue={dishwasherType.prewashMaxValue}
                            labelText={t('common:general.alarmMax', 'Alarm max')}
                          />
                          <SmallUnit>°{site?.temperatureUnit}</SmallUnit>
                        </Row>
                      </>
                    )}
                    {hasWash && (
                      <>
                        <DishwasherTypeSequenceType>
                          <Label>{t('appliances:labels.dishwasherSequence.Wash', 'Wash')}</Label>
                        </DishwasherTypeSequenceType>
                        <Row childMargin="1rem" align="flex-end">
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.dishwasherTypes?.[`${index}`]?.washMinValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`dishwasherTypes[${index}].washMinValue`}
                            defaultValue={dishwasherType.washMinValue}
                            labelText={t('common:general.alarmMin', 'Alarm min')}
                          />
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.dishwasherTypes?.[`${index}`]?.washMaxValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`dishwasherTypes[${index}].washMaxValue`}
                            defaultValue={dishwasherType.washMaxValue}
                            labelText={t('common:general.alarmMax', 'Alarm max')}
                          />
                          <SmallUnit>°{site?.temperatureUnit}</SmallUnit>
                        </Row>
                      </>
                    )}
                    {hasRinse && (
                      <>
                        <DishwasherTypeSequenceType>
                          <Label>{t('appliances:labels.dishwasherSequence.Rinse', 'Rinse')}</Label>
                        </DishwasherTypeSequenceType>
                        <Row childMargin="1rem" align="flex-end">
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.dishwasherTypes?.[`${index}`]?.rinseMinValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`dishwasherTypes[${index}].rinseMinValue`}
                            defaultValue={dishwasherType.rinseMinValue}
                            labelText={t('common:general.alarmMin', 'Alarm min')}
                          />
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.dishwasherTypes?.[`${index}`]?.rinseMaxValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`dishwasherTypes[${index}].rinseMaxValue`}
                            defaultValue={dishwasherType.rinseMaxValue}
                            labelText={t('common:general.alarmMax', 'Alarm max')}
                          />
                          <SmallUnit>°{site?.temperatureUnit}</SmallUnit>
                          {/* TODO refactor this to <TemperatureUnit site={site} /> and replace eveywhere */}
                        </Row>
                      </>
                    )}
                  </Baseline>
                </DishwasherTypeItem>
              )
            })
          ) : (
            <P>{t('appliances:messages.noAlarmLimits', 'No defined alarm limits.')}</P>
          )}
        </DishwasherTypeList>
      </Baseline>
    </DishwasherTypeListContainer>
  )
}

export default EditDishwasherTypes
