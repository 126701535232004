import React, {ReactNode} from 'react'
import {ReactNodeArray} from 'prop-types'
import styled from 'styled-components'
import {colors} from '../../../sharedComponents/colors'
import {CardContainer} from '../../Layout/Card'

interface InfoCardContainerProps {
  size?: number
  clickable: boolean
}
const InfoCardContainer = styled(CardContainer)`
  position: relative;
  min-width: ${(props: InfoCardContainerProps) =>
    `${(props.size ? props.size : 1) * 10.25 + (props.size ? props.size - 1 : 0) * 1}rem`};
  height: 12rem;
  display: inline-grid;
  grid-template-columns: repeat(${(props: InfoCardContainerProps) => (props.size ? props.size : 1)}, 1fr);
  grid-column-gap: 0.5rem;
  grid-column: auto / span ${(props: InfoCardContainerProps) => (props.size ? props.size : 1)};
  cursor: ${(props: InfoCardContainerProps) => (props.clickable ? 'pointer' : 'default')};

  > div:not(:first-child) {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: -0.25rem;
      width: 2px;
      background-color: ${colors.system.lightGrey_5};
    }
  }
`

interface InfoCardProps {
  size?: number
  children: ReactNode | ReactNodeArray
  onClick?: () => void
}
const InfoCard = ({size, children, onClick}: InfoCardProps) => {
  return (
    <InfoCardContainer onClick={() => (onClick ? onClick() : {})} size={size} clickable={!!onClick}>
      {children}
    </InfoCardContainer>
  )
}

InfoCard.defaultProps = {
  size: 1
}

export default InfoCard
