import React from 'react'
import {normalizeBrands} from '../../../config/utils'
import {useAppState} from '../../../state'
import {MainLayoutWithoutStretch, MainContent, NarrowRightSidebar} from '../../Layout/Layout'
import {HeaderRow, GoBackHeaderTitle} from '../../Molecules/ViewComponents/ViewHeader'
import {Baseline, Grid, InvisibleContainer} from '../../Layout/Grid'
import {BigLabelInput} from '../../Atoms/Forms'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {Brand} from '../../../state/state'
import {useNavigation} from 'react-navi'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {Text} from '../../Atoms/Typography'
import SiteOrganisationList from '../../Organisms/SiteOrganisationList'

const EditBrand = ({id}: {id: string}) => {
  const {state, effects, actions} = useAppState()
  const brand = normalizeBrands(state.chainsById[state.selectedChainId!]).find(lg => lg.id === id)
  const {t} = useTranslation('locations')
  const {register, getValues} = useForm<Brand>({defaultValues: brand})
  const nav = useNavigation()
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await effects.organisationApi.updateBrand({...brand, ...getValues()})
    await actions.updateMe()
    if (state.me?.accessRights.superuser) {
      await actions.getChains()
    }
    nav.goBack()
  }

  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={`${t('locations:labels.editBrand', 'Edit brand')}`}
            backLabel={t('locations:labels.locations', 'Location settings')}
          />
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <form onSubmit={handleSubmit}>
              <Grid gap="2rem">
                <BigLabelInput
                  ref={register}
                  name="name"
                  labelText={t('locations:labels.brandName', 'Brand name')}
                  placeholder={t('locations:labels.brandName', 'Brand name')}
                />
                <div className="empty"></div>
              </Grid>
              <ButtonRowWrap>
                <div className="empty"></div>
                <ButtonRowGrid>
                  <Button variant="secondary" negative onClick={() => nav.goBack()}>
                    {t('common:buttons.cancel', 'Cancel')}
                  </Button>
                  <Button variant="secondary" type="submit">
                    {t('common:buttons.save', 'Save')}
                  </Button>
                </ButtonRowGrid>
              </ButtonRowWrap>
            </form>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <NarrowRightSidebar>
        <InvisibleContainer style={{marginTop: '4rem'}}>
          <Baseline margin="1rem">
            <Text level={3}>{t('locations:labels.organisationHierarchy', 'Organisation hierarchy')}</Text>
            <div>
              <SiteOrganisationList data={state.chainsById[state.selectedChainId!]} />
            </div>
          </Baseline>
        </InvisibleContainer>
      </NarrowRightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default EditBrand
