import {useState, useCallback} from 'react'
import {useForm, useFieldArray} from 'react-hook-form'
import {useAppState} from '../../../state'
import {CoolerType} from '../../../state/rest'
import {localTempValue} from '../../../config/utils'
import {Site} from '../../../state/state'
import {useTranslation} from 'react-i18next'

export function useCoolerTypeList() {
  const {state, actions, effects} = useAppState()

  const valueExists = (value?: number): boolean => {
    return !(value === undefined || value === null)
  }

  const {watch, errors, register, control, formState} = useForm({
    defaultValues: {
      coolerTypes: state.coolerTypes.map(type => {
        console.debug('CoolerTypeUtils.useCoolerTypeList', {type})
        const data = {
          coolerTypeId: type.id,
          cycleType: type.cycleType,
          fastCycleTargetValue: valueExists(type.fastCycleTargetValue)
            ? localTempValue(type.fastCycleTargetValue!, state.site!)
            : undefined,
          fastCycleStartValue: valueExists(type.fastCycleStartValue)
            ? localTempValue(type.fastCycleStartValue!, state.site!)
            : undefined,
          fastCycleTimeLimit: valueExists(type.fastCycleTimeLimit)
            ? localTempValue(type.fastCycleTimeLimit!, state.site!)
            : undefined,
          slowCycleTargetValue: valueExists(type.slowCycleTargetValue)
            ? localTempValue(type.slowCycleTargetValue!, state.site!)
            : undefined,
          slowCycleStartValue: valueExists(type.slowCycleStartValue)
            ? localTempValue(type.slowCycleStartValue!, state.site!)
            : undefined,
          slowCycleTimeLimit: valueExists(type.slowCycleTimeLimit)
            ? localTempValue(type.slowCycleTimeLimit!, state.site!)
            : undefined
        }
        return data
      })
    },
    mode: 'onChange'
  })
  const {fields} = useFieldArray({
    control,
    name: 'coolerTypes'
  })

  const coolerTypes = watch({nest: true})

  const saveCoolerTypes = async () => {
    await Promise.all(
      coolerTypes.coolerTypes.map(type => {
        return updateCoolerType(type)
      })
    )
    await actions.getCoolerTypes()
  }

  const updateCoolerType = (type: any) => {
    return effects.equipmentApi.updateCoolerType({
      id: type.coolerTypeId,
      cycleType: type.cycleType,
      fastCycleTargetValue: type.fastCycleTargetValue,
      fastCycleStartValue: type.fastCycleStartValue,
      fastCycleTimeLimit: type.fastCycleTimeLimit,
      slowCycleTargetValue: type.slowCycleTargetValue,
      slowCycleStartValue: type.slowCycleStartValue,
      slowCycleTimeLimit: type.slowCycleTimeLimit
    })
  }

  return {
    register,
    fields,
    errors,
    formState,
    coolerTypes,
    saveCoolerTypes
  }
}

function optionalLocalTempValue(selectedSite: Site, value?: number): number | undefined {
  if (value === undefined || value === null) return undefined
  return localTempValue(value, selectedSite)
}

export function useCoolerTypeSelect(selectedSite: Site, saved?: Partial<CoolerType>, defaultOption?: string) {
  const {state} = useAppState()
  const {t} = useTranslation('appliances')
  const coolerTypeOptions = state.coolerTypes.map((o: CoolerType) => {
    const name = t('appliances:equipmentTypes.cooler', 'Cooler')
    const ranges = []
    // const fastCycleTargetValue = optionalLocalTempValue(selectedSite, o.fastCycleTargetValue)
    // const fastCycleStartValue = optionalLocalTempValue(selectedSite, o.fastCycleStartValue)
    const fastCycleTimeLimit = optionalLocalTempValue(selectedSite, o.fastCycleTimeLimit)
    // const slowCycleTargetValue = optionalLocalTempValue(selectedSite, o.slowCycleTargetValue)
    // const slowCycleStartValue = optionalLocalTempValue(selectedSite, o.slowCycleStartValue)
    const slowCycleTimeLimit = optionalLocalTempValue(selectedSite, o.slowCycleTimeLimit)
    //const unit = `°${selectedSite.temperatureUnit}`
    switch (o.cycleType) {
      case 'SLOW':
        if (slowCycleTimeLimit) {
          //ranges.push(`${slowCycleTargetValue}–${slowCycleStartValue}${unit} ${slowCycleTimeLimit} min`)
          ranges.push(`${slowCycleTimeLimit} min`)
        }
        break
      case 'FAST':
        if (fastCycleTimeLimit) {
          //ranges.push(`${fastCycleTargetValue}–${fastCycleStartValue}${unit} ${fastCycleTimeLimit} min`)
          ranges.push(`${fastCycleTimeLimit} min`)
        }
        break
      case 'SLOW-FAST':
        if (slowCycleTimeLimit && fastCycleTimeLimit) {
          //ranges.push(`${slowCycleTargetValue}–${slowCycleStartValue}${unit} ${slowCycleTimeLimit} min`)
          //ranges.push(`${fastCycleTargetValue}–${fastCycleStartValue}${unit} ${fastCycleTimeLimit} min`)
          ranges.push(`${slowCycleTimeLimit} min`)
          ranges.push(`${fastCycleTimeLimit} min`)
        }
        break
      default:
        console.error(`unsupported cooler cycle type: ${o.cycleType}`)
    }
    const rangeInfo = ranges.length > 0 ? `(${ranges.join(', ')})` : ''
    return {
      option: `${name} ${rangeInfo}`,
      id: o.id
    }
  })
  const defaultOptionIndex = coolerTypeOptions.findIndex(opt => opt.id === defaultOption)
  const defaultIndex = defaultOptionIndex !== -1 ? defaultOptionIndex : 0
  const [selected, setSelected] = useState(!!saved ? saved : state.coolerTypes[defaultIndex])
  const selectMethod = useCallback(
    (id: string) => {
      const coolerType = state.coolerTypeById[id]
      console.debug('CoolerType selectMethod', id, coolerType)
      setSelected(coolerType)
    },
    [state.coolerTypeById]
  )
  return {selectedCoolerType: selected, selectCoolerType: selectMethod, coolerTypeOptions}
}
