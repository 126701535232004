import React from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'
import {Tag} from '../../atoms/Tag'
import {Text} from '../../atoms/Text'

export interface ApplianceStateTagProps {
  label?: string
  disabled?: boolean
  compact?: boolean
}

export const ApplianceStateTag: React.FC<ApplianceStateTagProps> = ({label, disabled, compact, ...rest}) => {
  const textColor = disabled ? colors.system.grey_1 : colors.brand.cobalt
  return (
    <ApplianceStateTagContainer compact={compact} {...rest}>
      <ApplianceStateTagIndicator backgroundColor={disabled ? colors.system.grey_3 : colors.system.green} />
      <Text size={compact ? 'XS' : 'M'} color={textColor}>
        {label || (disabled ? 'OFF' : 'ON')}
      </Text>
    </ApplianceStateTagContainer>
  )
}

const ApplianceStateTagContainer = styled(Tag)<{compact?: boolean}>(
  ({compact}) => css`
    background: ${colors.system.lightGrey_5};
    border: none;
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;
    align-items: center;
    ${compact && 'padding: 0.25rem 0.5rem;'}
  `
)

const ApplianceStateTagIndicator = styled.div<{backgroundColor?: string}>(
  ({backgroundColor}) => css`
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.625rem;
    background: ${backgroundColor || colors.system.green};
  `
)
