import {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigation} from 'react-navi'
import {useAppState} from '../../../../state'
import {AlarmSetting, AlarmMethod, Recipient} from '../../../../state/rest'
import {useDeviceList, useRestaurantList} from '../../../../config/utils'
import {useWeekScheduleList} from '../../../Organisms/Scheduler/useWeekScheduleList'
import i18n from 'i18next'

export const useAlarmRuleForm = (saved?: Partial<AlarmSetting>) => {
  const {actions} = useAppState()
  const {navigate} = useNavigation()

  const backPath = '/settings/alarms'

  async function goBack() {
    navigate('/settings/alarms')
  }

  const {register, watch, errors, triggerValidation, formState} = useForm({
    defaultValues: saved,
    mode: 'onChange'
  })
  const [loading, setLoading] = useState(false)
  const form = watch()

  const rc = useRestaurantList(saved ? saved.sites : undefined)
  const equipmentTypes = saved?.equipmentTypes
  const devices = useDeviceList(equipmentTypes ? equipmentTypes : [])

  const amc = useAlarmMethodSelect(saved ? saved.alarmMethods : undefined)
  const uc = useRecipientSelect(saved ? saved.recipients : undefined, saved ? saved.extRecipients : undefined)
  const sc = useWeekScheduleList(saved ? saved.schedules : undefined)

  const update = async () => {
    try {
      setLoading(true)
      await actions.updateAlarmSetting({
        id: saved!.id!,
        name: form.name!,
        delayInMin: form.delayInMin,
        alarmMethods: amc.selected,
        schedules: sc.schedules,
        recipients: uc.recipients,
        extRecipients: uc.extRecipients,
        sites: rc.list,
        equipmentTypes: devices.getSelectedDevicesArray
      })
      goBack()
    } catch (err) {
      console.error(`Error updating alarm setting, reason: ${err.message}`)
    } finally {
      setLoading(false)
    }
  }
  const submit = async () => {
    try {
      setLoading(true)
      await actions.createAlarmSetting({
        name: form.name,
        alarmMethods: amc.selected,
        delayInMin: form.delayInMin,
        schedules: sc.schedules,
        recipients: uc.recipients,
        extRecipients: uc.extRecipients,
        sites: rc.list,
        equipmentTypes: devices.getSelectedDevicesArray
      })
      goBack()
    } catch (err) {
      console.error(`Error creating alarm setting, reason: ${err.message}`)
    } finally {
      setLoading(false)
    }
  }

  const remove = async () => {
    try {
      setLoading(true)
      await actions.deleteAlarmSetting(saved!.id!)
      goBack()
    } catch (err) {
      console.error(`Error deleting alarm setting, reason: ${err.message}`)
    } finally {
      setLoading(false)
    }
  }

  const cancel = () => {
    goBack()
  }

  return {
    register,
    update,
    submit,
    remove,
    cancel,
    form,
    errors,
    triggerValidation,
    hasErrors: !formState.isValid,
    formState,
    loading,
    backPath,
    rc,
    amc,
    sc,
    uc,
    devices
  }
}

export const getAlarmMethodName = (methodKey: string) => {
  switch (methodKey) {
    // disable push notification option until it's available
    // case AlarmMethod.PUSH: {
    //   return i18n.t('alarms:labels.notification', 'Notification')
    // }
    case AlarmMethod.SMS: {
      return i18n.t('alarms:labels.textMessage', 'Text message')
    }
    default: {
      return i18n.t('alarms:labels.email', 'Email')
    }
  }
}

export const getRecipientName = (user: Recipient) => {
  if (user.name) {
    return user.name
  } else if (user.firstName || user.lastName) {
    return `${user.firstName ? `${user.firstName} ` : ``}${user.lastName ? `${user.lastName}` : ``}`
  }
  return ''
}

export const getRecipientsNames = (recipients: Recipient[], limit?: number) => {
  //const recipients = [...settings.recipients, ...settings.extRecipients]
  limit = limit ? limit : Infinity // don't cut off if no limits
  if (recipients.length <= limit) {
    return recipients
      .map(recipient => {
        return getRecipientName(recipient)
      })
      .join(', ')
  } else if (recipients.length > limit) {
    return `${getRecipientName(recipients[0])}, ${recipients.length - 1} ${i18n.t(
      'common:labels.othersAmount',
      'others'
    )}`
  }
  return i18n.t('alarms:labels.noRecipients', 'No recipients')
}

export function useAlarmMethodSelect(saved?: string[]) {
  const methodOptions = Object.keys(AlarmMethod)
  const [selectedMethods, setSelectedMethods] = useState<string[]>(saved ? saved : [AlarmMethod.EMAIL])

  const selectMethod = useCallback(
    (id: string) => {
      if (selectedMethods.includes(id)) {
        setSelectedMethods(selectedMethods.filter(t => t !== id))
      } else {
        setSelectedMethods([...selectedMethods, id])
      }
    },
    [selectedMethods]
  )

  return {selected: selectedMethods, selectAlarmMethod: selectMethod, methodOptions}
}

export function useRecipientSelect(saved?: Recipient[], extSaved?: Recipient[]) {
  const [isOpen, setIsOpen] = useState(false)

  const [recipients, setRecipients] = useState(saved ? saved : [])
  const [extRecipients, setExtRecipients] = useState(extSaved ? extSaved : [])

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const handleSave = (newRecipients: Recipient[], newExtRecipients: Recipient[]) => {
    setRecipients(newRecipients)
    setExtRecipients(newExtRecipients)
    setIsOpen(false)
  }

  return {
    recipients,
    extRecipients,
    handleSave,
    isOpen,
    setIsOpen,
    toggleModal
  }
}
