import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconHeating = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0205 21.8936H18.2805V13.3881H17.0205V21.8936Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.7065 21.8938H21.9665V7.86877H20.7065V21.8938Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.377 21.8936H25.637V2H24.377V21.8936Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.0488 21.8937H29.3088V11.5656H28.0488V21.8937Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.7319 21.8937H32.9919V14.77H31.7319V21.8937Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.6011 46H15.9455L13.267 41.2314L10.6011 46Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9448 46H21.303L18.6233 41.2314L15.9448 46Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3647 46H26.7104L24.0432 41.2314L21.3647 46Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.7104 46H32.0674L29.3877 41.2314L26.7104 46Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.0669 46H37.4113L34.7441 41.2314L32.0669 46Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6659 24.9952L5.31855 27.5805H2V32.751H6.61503L8.12278 38.7501H39.876L41.385 32.751H46V27.5805H42.6928L43.3454 24.9952H4.6659ZM7.92158 32.7382L7.16834 29.7651L6.28809 26.2621H41.7245L40.4154 31.4714L40.1011 32.7253L38.9064 37.4447H9.10489L7.92158 32.7382ZM42.3785 28.8732H44.7552V31.4572H41.7246L42.3785 28.8732ZM3.2561 28.8732H5.63278L6.28794 31.4572H3.2561V28.8732Z"
      />
    </svg>
  )
}
