import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconPhoto = (props: SharedIconProps) => {
  return (
    <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
      <path d="M11.99 17.95c-2.3 0-4.18-1.87-4.18-4.17S9.68 9.6 11.99 9.6c2.3 0 4.18 1.87 4.18 4.18 0 2.3-1.88 4.17-4.18 4.17zm0-7.35c-1.75 0-3.18 1.42-3.18 3.18 0 1.75 1.42 3.17 3.18 3.17a3.18 3.18 0 003.18-3.17c0-1.75-1.43-3.18-3.18-3.18z" />
      <path d="M20.16 10.56v9.6H3.84V8.77h2.31l2.9-2.93h6.28v-1h-6.7l-2.9 2.93H2.84v13.39h18.32v-10.6zM23.9 4.84h-2.74V2.1h-1v2.74h-2.74v1h2.74v2.74h1V5.84h2.74z" />
    </svg>
  )
}
