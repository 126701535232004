import {baseApi} from '../rest'
import {TaskGroup} from './state'

export const generalTasksApi = {
  ...baseApi,
  async getGeneralTasksByTaskGroup(taskGroupId: string, siteId: string): Promise<TaskGroup> {
    const request = await this.doRequest<TaskGroup>({
      path: `/v1/generalTasks/taskGroup/${taskGroupId}?site-id=${siteId}`
    })
    const response = await request.get()
    return (response as unknown) as TaskGroup
  }
}
