import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconDishwasher = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06766 7.14484C8.02672 7.14484 7.18195 7.98876 7.18195 9.03055C7.18195 10.0713 8.02693 10.9163 9.06766 10.9163C10.1084 10.9163 10.9534 10.0713 10.9534 9.03055C10.9534 7.98876 10.1086 7.14484 9.06766 7.14484ZM5.9248 9.03055C5.9248 7.29405 7.33283 5.8877 9.06766 5.8877C10.8025 5.8877 12.2105 7.29405 12.2105 9.03055C12.2105 10.7656 10.8027 12.1734 9.06766 12.1734C7.33263 12.1734 5.9248 10.7656 5.9248 9.03055Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.221 7.14484C38.18 7.14484 37.3353 7.98876 37.3353 9.03055C37.3353 10.0713 38.1802 10.9163 39.221 10.9163C40.2617 10.9163 41.1067 10.0713 41.1067 9.03055C41.1067 7.98876 40.2619 7.14484 39.221 7.14484ZM36.0781 9.03055C36.0781 7.29405 37.4862 5.8877 39.221 5.8877C40.9558 5.8877 42.3638 7.29405 42.3638 9.03055C42.3638 10.7656 40.956 12.1734 39.221 12.1734C37.4859 12.1734 36.0781 10.7656 36.0781 9.03055Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0496 7.14484C30.0087 7.14484 29.1639 7.98876 29.1639 9.03055C29.1639 10.0713 30.0089 10.9163 31.0496 10.9163C32.0903 10.9163 32.9353 10.0713 32.9353 9.03055C32.9353 7.98876 32.0905 7.14484 31.0496 7.14484ZM27.9067 9.03055C27.9067 7.29405 29.3148 5.8877 31.0496 5.8877C32.7844 5.8877 34.1925 7.29405 34.1925 9.03055C34.1925 10.7656 32.7846 12.1734 31.0496 12.1734C29.3146 12.1734 27.9067 10.7656 27.9067 9.03055Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8782 7.14484C21.8373 7.14484 20.9925 7.98876 20.9925 9.03055C20.9925 10.0713 21.8375 10.9163 22.8782 10.9163C23.9189 10.9163 24.7639 10.0713 24.7639 9.03055C24.7639 7.98876 23.9191 7.14484 22.8782 7.14484ZM19.7354 9.03055C19.7354 7.29405 21.1434 5.8877 22.8782 5.8877C24.613 5.8877 26.0211 7.29405 26.0211 9.03055C26.0211 10.7656 24.6132 12.1734 22.8782 12.1734C21.1432 12.1734 19.7354 10.7656 19.7354 9.03055Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2H46V46H2V2ZM3.25714 3.25714V44.7429H44.7429V3.25714H3.25714Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5429 25.8852C29.113 25.8852 27.1429 27.8553 27.1429 30.2852C27.1429 32.7162 29.1129 34.6852 31.5429 34.6852C33.9729 34.6852 35.9429 32.7162 35.9429 30.2852C35.9429 27.8553 33.9728 25.8852 31.5429 25.8852ZM25.8857 30.2852C25.8857 27.161 28.4187 24.6281 31.5429 24.6281C34.6671 24.6281 37.2 27.161 37.2 30.2852C37.2 33.4107 34.667 35.9423 31.5429 35.9423C28.4188 35.9423 25.8857 33.4107 25.8857 30.2852Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5431 20.8568C26.3362 20.8568 22.1146 25.0784 22.1146 30.2853C22.1146 35.4934 26.3362 39.7139 31.5431 39.7139C36.7501 39.7139 40.9717 35.4934 40.9717 30.2853C40.9717 25.0784 36.75 20.8568 31.5431 20.8568ZM20.8574 30.2853C20.8574 24.3841 25.6419 19.5996 31.5431 19.5996C37.4443 19.5996 42.2289 24.3841 42.2289 30.2853C42.2289 36.1878 37.4443 40.971 31.5431 40.971C25.642 40.971 20.8574 36.1878 20.8574 30.2853Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02881 20.8567H18.3431V25.2567C18.3431 28.3822 15.81 30.9138 12.686 30.9138C9.56187 30.9138 7.02881 28.3822 7.02881 25.2567V20.8567ZM8.28595 22.1138V25.2567C8.28595 27.6877 10.256 29.6567 12.686 29.6567C15.1159 29.6567 17.086 27.6877 17.086 25.2567V22.1138H8.28595Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.65723 38.4567H17.7144V39.7138H7.65723V38.4567Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7998 39.0857V32.2532H12.0569V39.0857H10.7998Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3145 39.0857V32.2532H14.5716V39.0857H13.3145Z" />
    </svg>
  )
}
