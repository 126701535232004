import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import {Property as CSSProperty} from 'csstype'

export const CardContainer = styled.div`
  background-color: ${colors.system.white};
  border-radius: 10px;
  padding: 1rem;
`

export const CardList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1rem;

  ${CardContainer} {
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  }
`

interface CardGridProps {
  minWidth?: CSSProperty.MinBlockSize<string>
}
export const CardGrid = styled.div<CardGridProps>`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(${props => (props.minWidth ? props.minWidth : '10rem')}, 1fr));
`
