import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconAlertTriangle = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1534 8.16808C26.8972 4.2268 21.1028 4.2268 18.8466 8.16808L3.95623 34.1787C1.76879 37.9997 4.55704 42.7879 9.10968 42.7879H38.8903C43.443 42.7879 46.2312 37.9997 44.0438 34.1787L29.1534 8.16808ZM17.8197 7.55711C20.5348 2.8143 27.4652 2.8143 30.1803 7.55711L45.0707 33.5677C47.7592 38.264 44.2833 44 38.8903 44H9.10968C3.71668 44 0.240822 38.264 2.92934 33.5677L17.8197 7.55711ZM24.6692 15.515V30.0605H23.4092V15.515H24.6692ZM24.6692 37.3334V33.697H23.4092V37.3334H24.6692Z"
      />
    </svg>
  )
}
