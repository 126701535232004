import {Tabs, TabPane, Text, EMPTY_VALUE_TEXT, DatePicker} from '../../sharedComponents/components'
import {colors} from '../../sharedComponents/colors'
import React, {useState, useEffect, useRef} from 'react'
import {subHours, differenceInHours, previousMonday, isMonday} from 'date-fns'
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryAxis,
  VictoryLabel,
  VictoryArea,
  VictoryCursorContainer,
  VictoryCursorContainerProps,
  ClipPathProps,
  VictoryLabelProps
} from 'victory'
import {Puff} from 'svg-loaders-react'
import {getHumanizedDateString, getHumanizedTimeString} from '../../Components/Atoms/Utils'
import {useAppState} from '../../state'
import styled from 'styled-components'
import {EquipmentTypeKey} from '../../state/rest'
import {
  Appliance,
  CoolerAlarmRule,
  MeasurementType,
  RangeAlarmRule,
  RelativeHumidityMeasurementType,
  Sensor,
  TemperatureMeasurementType
} from '../../state/appliances/state'
import {assertUnreachable, convertUnit, localNumberFormat, tempUnitStr} from '../../config/utils'
import {useTranslation} from 'react-i18next'
import sortedIndex from 'lodash/sortedIndex'
import reverse from 'lodash/reverse'
import tiny from 'tinycolor2'
import {Alarm} from '../../state/alarms/state'
import {alarmUnit, alarmValueFormatter, dateValueFormatter, Units} from '../alarms/formatters'
import {TFunction} from 'i18next'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../../state/state'
import {ERROR_NOTIFICATION_DURATION_MS} from './appliancesUtils'
import {checkPeriodValidity} from '../utils'

const CHART_DEFAULT_WIDTH = 736
const CHART_DEFAULT_HEIGHT = 282
const ONE_HOUR = 1
const THREE_HOURS = 3
const SIX_HOURS = 6
const TWELVE_HOURS = 12
const DAY_IN_HOURS = 24
const SEVEN_DAYS_IN_HOURS = 7 * 24
const THIRTY_DAYS_IN_HOURS = 30 * 24

enum SensorDataChartPeriodTabKey {
  SIX_HOURS = 'six_hours',
  TWELVE_HOURS = 'twelve_hours',
  TWENTY_FOUR_HOURS = 'twenty_four_hours',
  SEVEN_DAYS = 'seven_days',
  THIRTY_DAYS = 'thirty_days',
  UNSELECTED = 'unselected'
}

export type SensorDataChartRecord = {
  y: number
  x: Date
}

export interface SensorDataChartProps {
  appliance: Appliance
  sensor: Sensor
  measurementType: MeasurementType
}

type CustomPeriod = {
  startDate: Date
  endDate: Date
}

export const SensorDataChart: React.FC<SensorDataChartProps> = ({appliance, sensor, measurementType}) => {
  const {state, actions} = useAppState()
  const {t} = useTranslation(['appliances'])
  const containerRef = useRef<HTMLDivElement>(null)
  const [now, setNow] = useState<Date | undefined>(new Date())
  const [selectedPeriodTabKey, setSelectedPeriodTabKey] = useState<SensorDataChartPeriodTabKey>(
    SensorDataChartPeriodTabKey.SIX_HOURS
  )
  const [customPeriod, setCustomPeriod] = useState<CustomPeriod>({startDate: undefined!, endDate: undefined!})

  const loadData = (from: Date, to: Date, measurementType: MeasurementType) => {
    return actions.v1.appliances.getSensorRecordsForAppliance({
      applianceId: appliance.id,
      siteId: state.site?.id!,
      from: from.toISOString(),
      to: to.toISOString(),
      measurementType,
      sensorRole: sensor.role
    })
  }

  const showDataLoadErrorNotification = (err: unknown) => {
    const isValidationError = (err as {error: string}).error === 'Payload validation error.'
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: t('appliances:messages.dataLoadErrorTitle', 'Data load error'),
      description: isValidationError
        ? t(
            'appliances:messages.chartDataLoadValidationErrorDescription',
            'Loading data failed. Up to 30 days of data can be loaded at a time.'
          )
        : t('appliances:messages.dataLoadErrorDescription', 'Loading data failed. Please try reloading the page.'),
      visible: true,
      hideAfterDelay: ERROR_NOTIFICATION_DURATION_MS
    })
  }

  useEffect(() => {
    const loadInitialData = async () => {
      const now = new Date()
      setNow(now)
      try {
        await loadData(subHours(now, SIX_HOURS), now, measurementType)
      } catch (err) {
        showDataLoadErrorNotification(err)
      }
    }
    loadInitialData()
  }, [])

  const getUnresolvedAlarms = (data: SensorDataChartRecord[]) => {
    if (data.length === 0) {
      return undefined
    }
    const oldestRecordTimestamp = data[data.length - 1].x.toISOString()
    const newestRecordTimestamp = data[0].x.toISOString()
    const unresolvedAlarms =
      state.v1.appliances.sensorAlarms.data.sensorId === sensor.id
        ? state.v1.appliances.sensorAlarms.data.sensorAlarms?.filter(alarm => {
            return (
              !alarm.resolvedTime &&
              alarm.triggeredTime >= oldestRecordTimestamp &&
              alarm.triggeredTime <= newestRecordTimestamp
            )
          })
        : undefined
    return unresolvedAlarms
  }

  const onPeriodChange = async (startDate: Date, endDate: Date, periodTabKey: SensorDataChartPeriodTabKey) => {
    try {
      if (checkPeriodValidity(startDate, endDate, true)) {
        await loadData(startDate, endDate, measurementType)
        setSelectedPeriodTabKey(periodTabKey)
        setCustomPeriod(
          periodTabKey === SensorDataChartPeriodTabKey.UNSELECTED
            ? {startDate, endDate}
            : {startDate: undefined!, endDate: undefined!}
        )
        setNow(periodTabKey === SensorDataChartPeriodTabKey.UNSELECTED ? undefined : endDate)
      }
    } catch (err) {
      showDataLoadErrorNotification(err)
    }
  }

  const getAverageSensorDataPeriodText = () => {
    if (
      selectedPeriodTabKey === SensorDataChartPeriodTabKey.UNSELECTED &&
      customPeriod &&
      customPeriod.startDate &&
      customPeriod.endDate
    ) {
      const startDate = customPeriod.startDate
      const endDate = customPeriod.endDate
      if (
        startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear()
      ) {
        return getHumanizedDateString(startDate, state.site!.locale)
      }
      return `${getHumanizedDateString(startDate, state.site!.locale)} - ${getHumanizedDateString(
        endDate,
        state.site!.locale
      )}`
    }
    return `${t('appliances:labels.last', 'Last')} ${getPeriodLabel(t, selectedPeriodTabKey)}`
  }

  const sensorRecordsRoot = state.v1.appliances.sensorRecords[measurementType]

  const sensorRecords = sensorRecordsRoot.data.sensorId === sensor.id ? sensorRecordsRoot.data.records : undefined
  const averageValue =
    sensorRecordsRoot.data.sensorId === sensor.id ? sensorRecordsRoot.data.statistics?.average : undefined
  const chartData = sensorRecords?.map(record => ({y: record.value, x: new Date(record.timestamp)})) || []

  const unresolvedSensorAlarms = getUnresolvedAlarms(chartData)
  const singleColorLine = appliance.type === EquipmentTypeKey.DISHWASHER || appliance.type === EquipmentTypeKey.COOLER

  let measurementTypeData: RelativeHumidityMeasurementType | TemperatureMeasurementType | null = null
  switch (measurementType) {
    case MeasurementType.temperature:
      measurementTypeData = sensor.measurementTypes?.temperature || null
      break
    case MeasurementType.relativeHumidity:
      measurementTypeData = sensor.measurementTypes?.relativeHumidity || null
      break
  }

  let chartComponent
  if (sensorRecordsRoot.loading) {
    chartComponent = (
      <SensorDataChartExtraContainer>
        <Puff stroke="black" />
      </SensorDataChartExtraContainer>
    )
  } else if (chartData.length === 0) {
    chartComponent = (
      <SensorDataChartExtraContainer>
        <p>{t('appliances:messages.noData', 'No sensor data available for selected dates')}</p>
      </SensorDataChartExtraContainer>
    )
  } else {
    chartComponent = (
      <SensorDataChartContent>
        <AverageValue
          periodText={getAverageSensorDataPeriodText()}
          measurementType={measurementType}
          averageValue={averageValue}
        />
        <Chart
          isCooling={appliance.type === EquipmentTypeKey.COOLER}
          measurementType={measurementType}
          data={chartData}
          now={now}
          width={containerRef.current?.offsetWidth}
          alarmRule={measurementTypeData?.alarmRule}
          alarms={unresolvedSensorAlarms}
          singleColorLine={singleColorLine}
          customPeriod={customPeriod}
        />
      </SensorDataChartContent>
    )
  }

  return (
    <SensorDataChartContainer ref={containerRef}>
      <SensorDataChartPeriodHeader onChange={onPeriodChange} />
      {chartComponent}
    </SensorDataChartContainer>
  )
}

const SensorDataChartContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1rem;
`

const SensorDataChartExtraContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 1.5rem;
  justify-content: center;
`

interface AverageValueProps {
  periodText: string
  measurementType: MeasurementType
  averageValue?: number
}

const AverageValue: React.FC<AverageValueProps> = ({averageValue, measurementType, periodText}) => {
  const {t} = useTranslation(['appliances'])
  const {state} = useAppState()

  const getLabelText = () => {
    switch (measurementType) {
      case MeasurementType.temperature:
        return t('appliances:labels.averageTemperature', 'Average temperature')
      case MeasurementType.relativeHumidity:
        return t('appliances:labels.averageRelativeHumidity', 'Average humidity (RH)')
      default:
        return ''
    }
  }

  const getValueText = () => {
    if (typeof averageValue !== 'number') {
      return EMPTY_VALUE_TEXT
    }

    switch (measurementType) {
      case MeasurementType.temperature:
        return `${localNumberFormat(state.site!.locale, convertUnit(state.site!, averageValue))}${tempUnitStr(
          state.site!.temperatureUnit!
        )}`
      case MeasurementType.relativeHumidity:
        return `${localNumberFormat(state.site!.locale, averageValue)}%`
      default:
        return EMPTY_VALUE_TEXT
    }
  }

  return (
    <AverageValueContainer>
      <AverageValueLabelContainer>
        <Text size="XS" color={colors.system.grey_50} textTransform="uppercase">
          {getLabelText()}
        </Text>
        <Text size="S">{periodText}</Text>
      </AverageValueLabelContainer>
      <Text size="XL" strong>
        {getValueText()}
      </Text>
    </AverageValueContainer>
  )
}

const AverageValueContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: ${colors.system.lightGrey_5};
  border-radius: 0.5rem;
`

const AverageValueLabelContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 0.25rem;
`

type OrientationTypes = 'top' | 'bottom' | 'left' | 'right'

type TooltipPositionProps = {
  orientation: OrientationTypes
  dx: number
  dy: number
}

interface ChartProps {
  width?: number
  measurementType: MeasurementType
  data: SensorDataChartRecord[]
  now?: Date
  alarmRule?: RangeAlarmRule | CoolerAlarmRule | null
  alarms?: Alarm[]
  singleColorLine?: boolean
  isCooling: boolean
  customPeriod?: CustomPeriod
}

const Chart: React.FC<ChartProps> = ({
  width,
  measurementType,
  data,
  now,
  alarmRule,
  alarms,
  singleColorLine,
  isCooling,
  customPeriod
}) => {
  const {state} = useAppState()
  const {t} = useTranslation(['appliances', 'alarms'])
  const [alarmData, setAlarmData] = useState<SensorDataChartRecord[]>([])
  const [minValue, setMinValue] = useState<number>()
  const [maxValue, setMaxValue] = useState<number>()
  const [timestampsMsReversed, setTimetampsMsReversed] = useState<number[]>([])
  const [activeIndex, setActiveIndex] = useState<number>()
  const minLimitValue = alarmRule?.minValue
  const maxLimitValue = alarmRule?.maxValue
  const defaultTooltipPositionProps: TooltipPositionProps = {
    orientation: 'top',
    dx: 0,
    dy: -8
  }
  const [tooltipPositionProps, setTooltipPositionProps] = useState<TooltipPositionProps>(defaultTooltipPositionProps)

  const units: Units = {
    temperature: state.site?.temperatureUnit!,
    weight: state.site?.weightUnit!
  }

  const latestDate = customPeriod?.endDate || new Date()

  useEffect(() => {
    if (data) {
      const values = data.map(d => d.y)
      // Store min and max data values for calculating linear gradient for the chart line
      setMinValue(Math.min(...values))
      setMaxValue(Math.max(...values))
      // Store data timestamps in epoch milliseconds in ascending order for binary search
      setTimetampsMsReversed(reverse(data.map(d => d.x.getTime())))
      // Calculate y-value for alarms for showing them on the chart line. Alarms may not have y-value so they are given one by linear interpolation
      // TODO: The chart line uses more complex interpolation and alarm point may not be put on the chart line
      if (alarms && data.length > 1 && alarms.length > 0) {
        const tempAlarmData: SensorDataChartRecord[] = []
        // TODO: Inefficient to loop all alarms for all data points but shouldn't be a problem for few hundred items
        for (let i = 0; i < data.length - 1; ++i) {
          const firstRecord = data[i]
          const secondRecord = data[i + 1]
          for (const alarm of alarms) {
            if (
              alarm.triggeredTime <= firstRecord.x.toISOString() &&
              alarm.triggeredTime > secondRecord.x.toISOString()
            ) {
              const alarmStartedAtDate = new Date(alarm.triggeredTime)
              const position =
                (alarmStartedAtDate.getTime() - secondRecord.x.getTime()) /
                (firstRecord.x.getTime() - secondRecord.x.getTime())
              const interpolatedAlarmValue = interpolateValue(secondRecord.y, firstRecord.y, position)
              tempAlarmData.push({y: interpolatedAlarmValue, x: alarmStartedAtDate})
            }
          }
        }
        setAlarmData(tempAlarmData)
      }
    }
  }, [data, alarms])

  // Linear interpolation
  const interpolateValue = (value1: number, value2: number, position: number) => {
    return value1 * (1 - position) + value2 * position
  }

  // Returns pre-defined time ticks
  // Tick interval is 3 hours if there is less than 24 hours of data and otherwise the interval is 24 hours
  // TODO: This adjusts dynamically if there is less than 24 hours of data but then again if e.g. 24 hours period is selected and there is not enough data (because data collection started recently or data is missing) the chart would show shorter period than specified
  const getTimeTickValues = () => {
    if (data.length === 0) {
      return undefined
    }
    const dateRight = customPeriod?.startDate || data[data.length - 1].x
    // Calculate a good tick interval based on the time range of the data
    const diffHours = differenceInHours(latestDate, dateRight, {roundingMethod: 'ceil'})
    let tickHours: number = SEVEN_DAYS_IN_HOURS
    switch (true) {
      case diffHours <= SIX_HOURS:
        tickHours = ONE_HOUR
        break
      case diffHours <= DAY_IN_HOURS:
        tickHours = THREE_HOURS
        break
      case diffHours <= SEVEN_DAYS_IN_HOURS:
        tickHours = DAY_IN_HOURS
        break
      default:
        tickHours = SEVEN_DAYS_IN_HOURS
        break
    }
    // Find the latest anchor tick
    let latestTickDate: number
    if (tickHours < SEVEN_DAYS_IN_HOURS) {
      const hours = latestDate.getHours()
      const latestHour = hours - (hours % tickHours)
      latestTickDate = new Date(latestDate).setHours(latestHour, 0, 0, 0)
    } else {
      // Week starts with Monday
      const latestMonday = isMonday(latestDate) ? new Date(latestDate) : previousMonday(latestDate)
      latestTickDate = latestMonday.setHours(0, 0, 0, 0)
    }
    // Calculate the number of ticks to be shown
    const tickCount = Math.round(diffHours / tickHours)
    return Array.from(Array(tickCount).keys()).map(i => subHours(latestTickDate, i * tickHours))
  }

  const getTimeTickFormat = (tick: number) => {
    if (data.length === 0) {
      return ''
    }
    const dateRight = customPeriod?.startDate || data[data.length - 1].x
    const diffHours = differenceInHours(latestDate, dateRight)
    const formatFunc = diffHours <= DAY_IN_HOURS ? getHumanizedTimeString : getHumanizedDateString
    return formatFunc(new Date(tick), state.site?.locale!)
  }

  const getValueTickFormat = (tick: number) => {
    return getFormattedValue(tick) || ''
  }

  // Returns data to visualize green area between min and max limits
  const getLimitAreaData = () => {
    if (!data) {
      return []
    }
    let y: number | undefined
    let _y0: number | undefined
    if (isCooling && alarmRule) {
      _y0 = minLimitValue ?? minLimitValue
      y = 'targetValue' in alarmRule ? alarmRule.targetValue : undefined
    } else {
      _y0 = minLimitValue ?? minValue
      y = maxLimitValue ?? maxValue
    }

    return [{x: latestDate, y, _y0}, ...data.map(d => ({x: d.x, y, _y0}))]
  }

  const getFormattedUnit = () => {
    switch (measurementType) {
      case MeasurementType.temperature:
        return tempUnitStr(state.site?.temperatureUnit!)
      case MeasurementType.relativeHumidity:
        return '%'
      default:
        return ''
    }
  }

  const getFormattedValue = (value: number) => {
    return localNumberFormat(state.site?.locale!, convertUnit(state.site!, value))
  }

  const getTooltipPositionProps = (
    datum: SensorDataChartRecord,
    props: VictoryCursorContainerProps
  ): TooltipPositionProps => {
    if (!datum || data.length === 0) {
      return defaultTooltipPositionProps
    }
    const propsWithDomain = props as VictoryCursorContainerProps & {domain: {x: Date[]; y: number[]}}
    const minTimeMs = propsWithDomain.domain.x[0].getTime()
    const timeRange = propsWithDomain.domain.x[1].getTime() - minTimeMs
    const timeRatio = (datum.x.getTime() - minTimeMs) / timeRange
    const minValue = propsWithDomain.domain.y[0]
    const valueRange = propsWithDomain.domain.y[1] - minValue
    const valueRatio = (datum.y - minValue) / valueRange

    switch (true) {
      case timeRatio < 0.1:
        return {
          orientation: 'right',
          dx: 8,
          dy: 0
        }
      case timeRatio > 0.9:
        return {
          orientation: 'left',
          dx: -8,
          dy: 0
        }
      case valueRatio > 0.8:
        return {
          orientation: 'bottom',
          dx: 0,
          dy: 8
        }
      default:
        return defaultTooltipPositionProps
    }
  }

  /**
   * Finds the index of the data point that is closest to the mouse cursor on x-axis using binary search (lodash.sortedIndex)
   * TODO: This doesn't exactly find the data point closest to the mouse cursor. For that one should compare the x values of the two data points adjacent to the found index and select one that is closer to the mouse cursor's x value (or x^2 + y^2 (pythagoras) for the two dimensional closest point for example when user wants to examine a spike which would otherwise require precision).
   */
  const onCursorChange = (value: unknown, props: VictoryCursorContainerProps) => {
    if (!value) {
      return
    }
    const cursorValue = value as {x: number; y: number}
    const cursorX = props.scale?.x?.(cursorValue.x)
    const cursorY = props.scale?.y?.(cursorValue.y)
    // Check if cursor is on an alarm point
    let cursorAlarm: SensorDataChartRecord | undefined = undefined
    alarmData?.forEach(alarm => {
      const alarmX = props.scale?.x?.(alarm.x.getTime())
      const alarmY = props.scale?.y?.(alarm.y)
      // Simplified distance comparison
      if (
        cursorX &&
        cursorY &&
        alarmX &&
        alarmY &&
        Math.abs(cursorX - alarmX) <= 4 &&
        Math.abs(cursorY - alarmY) <= 4
      ) {
        cursorAlarm = alarm
      }
    })
    if (cursorAlarm) {
      setActiveIndex(undefined)
      // Change the orientation of the tooltip at the edges of the chart
      setTooltipPositionProps(getTooltipPositionProps(cursorAlarm, props))
    } else {
      if (!timestampsMsReversed || timestampsMsReversed.length === 0) {
        return
      }
      // Calculate the index in the original data array
      const index = timestampsMsReversed.length - sortedIndex(timestampsMsReversed, cursorValue.x)
      const clampedIndex = index >= timestampsMsReversed.length ? timestampsMsReversed.length - 1 : index
      setActiveIndex(clampedIndex)
      // Change the orientation of the tooltip at the edges of the chart
      setTooltipPositionProps(getTooltipPositionProps(data[clampedIndex], props))
    }
  }

  const getTooltipProps = (isAlarm: boolean = false) => {
    const tooltipLabelStyle = {
      fill: colors.system.grey_50,
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'FavoritStd-Book',
      textAnchor: 'start'
    }

    return {
      cornerRadius: 4,
      pointerLength: 8,
      pointerWidth: 14,
      flyoutPadding: {top: 12, right: 12, bottom: 26, left: 12},
      flyoutStyle: {
        stroke: colors.system.grey_5,
        strokeWidth: 0.5,
        fill: colors.system.white,
        filter: `drop-shadow(0 0 16px ${tiny('#d1d1d6')
          .setAlpha(0.5)
          .toRgbString()})`,
        lineHeight: 2
      },
      labelComponent: <CustomTooltipLabel />,
      style: [
        {
          ...tooltipLabelStyle,
          paddingTop: 0
        },
        {
          ...tooltipLabelStyle,
          fill: isAlarm ? colors.system.red : colors.brand.cobalt,
          fontSize: 19,
          fontFamily: 'FavoritStd-Bold',
          paddingTop: 4
        },
        {
          ...tooltipLabelStyle,
          paddingTop: 8
        },
        {
          ...tooltipLabelStyle,
          fill: colors.brand.cobalt,
          fontSize: 14,
          paddingTop: 4
        }
      ]
    }
  }

  const getTooltipLabels = (value: string, timestamp: string) => {
    return [
      t('alarms:labels.tableHeading.measuredValue', 'Measured value').toUpperCase(),
      value,
      t('alarms:labels.time', 'Time').toUpperCase(),
      dateValueFormatter(timestamp, state.site?.locale!)
    ]
  }

  const showLimitArea = typeof minLimitValue === 'number' || typeof maxLimitValue === 'number'
  const tickLabelStyles = {
    fontSize: 14,
    fontFamily: 'FavoritStd-Book',
    color: colors.brand.cobalt,
    fontWeight: 400
  }

  return (
    <VictoryChart
      containerComponent={<VictoryCursorContainer onCursorChange={onCursorChange} cursorComponent={<></>} />}
      domainPadding={{
        y: 8,
        x: 8
      }}
      padding={{top: 32, right: 30, bottom: 26, left: 48}}
      style={{parent: {height: CHART_DEFAULT_HEIGHT}}}
      height={CHART_DEFAULT_HEIGHT}
      width={width || CHART_DEFAULT_WIDTH}
    >
      {showLimitArea && (
        <VictoryArea data={getLimitAreaData()} style={{data: {fill: colors.system.green, opacity: 0.1}}} />
      )}
      <VictoryAxis
        dependentAxis
        style={{
          axis: {stroke: 'none'},
          tickLabels: tickLabelStyles,
          axisLabel: {angle: 0, ...tickLabelStyles}
        }}
        axisLabelComponent={<VictoryLabel dy={-131} dx={1} />}
        label={`(${getFormattedUnit()})`}
        tickFormat={getValueTickFormat}
        crossAxis={false}
      />
      <VictoryAxis
        style={{
          grid: {
            stroke: colors.system.grey_5
          },
          axis: {stroke: 'none'},
          tickLabels: tickLabelStyles
        }}
        tickValues={getTimeTickValues()}
        tickFormat={getTimeTickFormat}
        offsetY={27}
      />
      <VictoryLine
        data={data ?? []}
        interpolation="monotoneX"
        style={{
          data: {
            stroke: singleColorLine ? colors.system.grey_1 : 'url(#applianceSensorDataChartLineColor)',
            strokeWidth: 1.5
          }
        }}
      />
      {data?.[0] && now && (
        <VictoryScatter
          data={data && data[0] ? [data[0]] : []}
          size={4}
          style={{
            data: {fill: colors.system.grey_1}
          }}
        />
      )}
      {activeIndex !== undefined && (
        <VictoryScatter
          data={data && data[activeIndex] ? [data[activeIndex]] : []}
          size={4}
          style={{
            data: {fill: colors.system.grey_1}
          }}
          labels={({datum}) => {
            if (!datum) {
              return []
            }
            const value = `${getFormattedValue(datum.y)}${getFormattedUnit()}`
            const timestamp = (datum.x as Date).toISOString()

            return getTooltipLabels(value, timestamp)
          }}
          labelComponent={<VictoryTooltip {...tooltipPositionProps} {...getTooltipProps()} active />}
        />
      )}
      {alarms && alarms.length > 0 && (
        <VictoryScatter
          data={alarmData}
          size={4}
          style={{
            data: {fill: colors.system.red}
          }}
          labels={({datum, index}) => {
            if (!datum) {
              return []
            }
            const alarm = alarms[index]
            const value = `${alarmValueFormatter(
              alarm.measuredValue,
              alarm.type,
              alarm.subtype,
              state.site!
            )}${alarmUnit(t, alarm.type, alarm.subtype, alarm.unit, units)}`
            const timestamp = (datum.x as Date).toISOString()

            return getTooltipLabels(value, timestamp)
          }}
          labelComponent={<VictoryTooltip {...tooltipPositionProps} {...getTooltipProps(true)} />}
        />
      )}
      <CustomLinearGradient minValue={minValue} maxValue={maxValue} minLimit={minLimitValue} maxLimit={maxLimitValue} />
    </VictoryChart>
  )
}

const CustomTooltipLabel: React.FC<VictoryLabelProps> = props => {
  const texts = props.text as string[]
  const styles = props.style as React.CSSProperties[]

  const getTexts = () => {
    return texts.map((text, i) => {
      const style = styles[i]
      const fontSize = (style?.fontSize as number) ?? 0
      const paddingTop = (style?.paddingTop as number) ?? 0
      const dy = fontSize + paddingTop

      return (
        <tspan key={i} style={style} x={props.x} dx={0} dy={dy} textAnchor={style.textAnchor}>
          {text}
        </tspan>
      )
    })
  }

  return (
    <text x={props.x} y={props.y! - 32}>
      {getTexts()}
    </text>
  )
}

interface CustomLinearGradientProps extends ClipPathProps {
  minValue?: number
  maxValue?: number
  minLimit?: number
  maxLimit?: number
}

const CustomLinearGradient: React.FC<CustomLinearGradientProps> = props => {
  const minValueY = props.scale.y(props.minValue)
  const maxValueY = props.scale.y(props.maxValue)
  const valueRangeY = minValueY - maxValueY
  const hasMinLimit = typeof props.minLimit === 'number'
  const hasMaxLimit = typeof props.maxLimit === 'number'
  const isMinValueOutOfMinLimit = hasMinLimit && props.minValue! < props.minLimit!
  const isMinValueOutOfMaxLimit = hasMinLimit && props.minValue! > props.maxLimit!
  const isMaxValueOutOfMaxLimit = hasMaxLimit && props.maxValue! > props.maxLimit!
  const isMaxValueOutOfMinLimit = hasMaxLimit && props.maxValue! < props.minLimit!

  const minLimitY = hasMinLimit && props.scale.y(props.minLimit)
  const maxLimitY = hasMaxLimit && props.scale.y(props.maxLimit)
  const minPercentage =
    isMinValueOutOfMinLimit && !isMaxValueOutOfMinLimit ? ((minLimitY - maxValueY) / valueRangeY) * 100 : undefined
  const maxPercentage =
    isMaxValueOutOfMaxLimit && !isMinValueOutOfMaxLimit ? ((maxLimitY - maxValueY) / valueRangeY) * 100 : undefined

  return (
    <defs>
      <linearGradient id="applianceSensorDataChartLineColor" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop
          offset="0%"
          stopColor={isMaxValueOutOfMaxLimit || isMaxValueOutOfMinLimit ? colors.system.red : colors.system.grey_1}
        />
        {typeof maxPercentage === 'number' && (
          <>
            <stop offset={`${maxPercentage}%`} stopColor={colors.system.red} />
            <stop offset={`${maxPercentage}%`} stopColor={colors.system.grey_1} />
          </>
        )}
        {typeof minPercentage === 'number' && (
          <>
            <stop offset={`${minPercentage}%`} stopColor={colors.system.grey_1} />
            <stop offset={`${minPercentage}%`} stopColor={colors.system.red} />
          </>
        )}
        <stop
          offset="100%"
          stopColor={isMinValueOutOfMinLimit || isMinValueOutOfMaxLimit ? colors.system.red : colors.system.grey_1}
        />
      </linearGradient>
    </defs>
  )
}

interface SensorDataChartPeriodHeaderProps {
  onChange?: (startDate: Date, endDate: Date, periodTabKey: SensorDataChartPeriodTabKey) => void
}

const SensorDataChartPeriodHeader: React.FC<SensorDataChartPeriodHeaderProps> = ({onChange}) => {
  const {t} = useTranslation(['appliances'])
  const [selectedTabKey, setSelectedTabKey] = useState<SensorDataChartPeriodTabKey>(
    SensorDataChartPeriodTabKey.SIX_HOURS
  )
  const [customPeriod, setCustomPeriod] = useState<CustomPeriod>({startDate: undefined!, endDate: undefined!})

  const onTabChange = (tabKey: string) => {
    const key = tabKey as SensorDataChartPeriodTabKey
    setSelectedTabKey(key)
    setCustomPeriod({startDate: undefined!, endDate: undefined!})
    const to = new Date()
    switch (key) {
      case SensorDataChartPeriodTabKey.SIX_HOURS:
        return onChange?.(subHours(to, SIX_HOURS), to, SensorDataChartPeriodTabKey.SIX_HOURS)
      case SensorDataChartPeriodTabKey.TWELVE_HOURS:
        return onChange?.(subHours(to, TWELVE_HOURS), to, SensorDataChartPeriodTabKey.TWELVE_HOURS)
      case SensorDataChartPeriodTabKey.TWENTY_FOUR_HOURS:
        return onChange?.(subHours(to, DAY_IN_HOURS), to, SensorDataChartPeriodTabKey.TWENTY_FOUR_HOURS)
      case SensorDataChartPeriodTabKey.SEVEN_DAYS:
        return onChange?.(subHours(to, SEVEN_DAYS_IN_HOURS), to, SensorDataChartPeriodTabKey.SEVEN_DAYS)
      case SensorDataChartPeriodTabKey.THIRTY_DAYS:
        return onChange?.(subHours(to, THIRTY_DAYS_IN_HOURS), to, SensorDataChartPeriodTabKey.THIRTY_DAYS)
      case SensorDataChartPeriodTabKey.UNSELECTED:
        return
      default:
        assertUnreachable(key)
        return
    }
  }

  const onCustomPeriodChange = (name: string, date: Date) => {
    setSelectedTabKey(SensorDataChartPeriodTabKey.UNSELECTED)
    const updatedCustomPeriod = {...customPeriod, [name]: date}
    setCustomPeriod(updatedCustomPeriod)
    onChange?.(updatedCustomPeriod.startDate, updatedCustomPeriod.endDate, SensorDataChartPeriodTabKey.UNSELECTED)
    //Add on  chane
  }

  return (
    <SensorDataChartPeriodHeaderContainer>
      <SensorDataChartPeriodHeaderTabsContainer>
        <Text size="S">{t('appliances:labels.temperatureChartPeriodTabsHeader', 'View the last')}</Text>
        <SensorDataChartPeriodHeaderTabs activeKey={selectedTabKey} onChangeKey={onTabChange}>
          <TabPane
            tab={<Text size="S">{getPeriodLabel(t, SensorDataChartPeriodTabKey.SIX_HOURS)}</Text>}
            tabKey={SensorDataChartPeriodTabKey.SIX_HOURS}
          />
          <TabPane
            tab={<Text size="S">{getPeriodLabel(t, SensorDataChartPeriodTabKey.TWELVE_HOURS)}</Text>}
            tabKey={SensorDataChartPeriodTabKey.TWELVE_HOURS}
          />
          <TabPane
            tab={<Text size="S">{getPeriodLabel(t, SensorDataChartPeriodTabKey.TWENTY_FOUR_HOURS)}</Text>}
            tabKey={SensorDataChartPeriodTabKey.TWENTY_FOUR_HOURS}
          />
          <TabPane
            tab={<Text size="S">{getPeriodLabel(t, SensorDataChartPeriodTabKey.SEVEN_DAYS)}</Text>}
            tabKey={SensorDataChartPeriodTabKey.SEVEN_DAYS}
          />
          <TabPane
            tab={<Text size="S">{getPeriodLabel(t, SensorDataChartPeriodTabKey.THIRTY_DAYS)}</Text>}
            tabKey={SensorDataChartPeriodTabKey.THIRTY_DAYS}
          />
        </SensorDataChartPeriodHeaderTabs>
      </SensorDataChartPeriodHeaderTabsContainer>
      <SensorDataChartPeriodHeaderTabsContainer>
        <Text size="S">{t('appliances:labels.temperatureChartCustomPeriodHeader', 'Choose custom period')}</Text>
        <SensorDataChartPeriodHeaderDatePicker
          id="applianceTemperatureChartCustomPeriod"
          onChange={onCustomPeriodChange}
          startDate={customPeriod.startDate}
          endDate={customPeriod.endDate}
          maxDays={29}
        />
      </SensorDataChartPeriodHeaderTabsContainer>
    </SensorDataChartPeriodHeaderContainer>
  )
}

const getPeriodLabel = (t: TFunction, key: SensorDataChartPeriodTabKey) => {
  switch (key) {
    case SensorDataChartPeriodTabKey.SIX_HOURS:
      return t('appliances:labels.temperatureChartPeriodSixHoursTab', '6 hours')
    case SensorDataChartPeriodTabKey.TWELVE_HOURS:
      return t('appliances:labels.temperatureChartPeriodTwelveHoursTab', '12 hours')
    case SensorDataChartPeriodTabKey.TWENTY_FOUR_HOURS:
      return t('appliances:labels.temperatureChartPeriodTwentyFourHoursTab', '24 hours')
    case SensorDataChartPeriodTabKey.SEVEN_DAYS:
      return t('appliances:labels.temperatureChartPeriodSevenDaysTab', '7 days')
    case SensorDataChartPeriodTabKey.THIRTY_DAYS:
      return t('appliances:labels.temperatureChartPeriodThirtyDaysTab', '30 days')
    case SensorDataChartPeriodTabKey.UNSELECTED:
      return ''
    default:
      assertUnreachable(key)
      return
  }
}

const SensorDataChartPeriodHeaderContainer = styled.div`
  display: grid;
  grid-template: auto / 1fr auto;
`

const SensorDataChartPeriodHeaderTabsContainer = styled.div`
  padding: 0 0.5rem;
  display: grid;
  grid-auto-flow: row;
  gap: 0.125rem;
  justify-content: start;
`

const SensorDataChartPeriodHeaderTabs = styled(Tabs)`
  header {
    border: 1px solid ${colors.system.grey_5};
    border-radius: 0.5rem;
    padding: 0 0.25rem;

    ul.tabs li.tab a {
        padding: 0.5rem 1rem;

        span {
          white-space: pre;
        }
      }
    }
  }
`

// Increase DatePicker calendar z-index in order to show it on top of chart tooltip
// Force align calendar on left to prevent it to be cropped
const SensorDataChartPeriodHeaderDatePicker = styled(DatePicker)`
  div.react-datepicker-wrapper {
    padding: 0 0.5rem 0 0;
  }
  div.react-datepicker__input-container {
    border: 1px solid ${colors.system.grey_5};
    border-radius: 0.5rem;
  }

  div.react-datepicker {
    z-index: 100;
    left: initial !important;
    right: 0 !important;
    top: 100% !important;
    bottom: initial !important;
  }

  button.react-calendar__tile:disabled {
    background-color: ${colors.system.grey_3};
    cursor: not-allowed;
  }
`

const SensorDataChartContent = styled.div`
  display: grid;
  align-content: start;
  justify-items: start;
  grid-auto-flow: row;
  gap: 1rem;
  padding-top: 1.5rem;
`
