import React from 'react'
import {useTranslation} from 'react-i18next'
import AnchorModal, {TinyModalContent} from '../Modals/AnchorModal'
import {Baseline} from '../../Layout/Grid'
import {IconButton} from '../../Atoms/Buttons'

interface MenuProps {
  isOpen: boolean
  onClose?: () => void
  onDelete: () => void
}
const HeaderMenuModal = ({isOpen, onClose, onDelete}: MenuProps) => {
  const {t} = useTranslation()

  return (
    <>
      <AnchorModal anchorMode={'right'} isOpen={isOpen} onClose={onClose}>
        <TinyModalContent data-cy="alarm-setting-menu-modal">
          <Baseline>
            <IconButton
              description={t('common:actions.deleteRule', 'Delete rule')}
              buttonProps={{onClick: () => onDelete()}}
            />
          </Baseline>
        </TinyModalContent>
      </AnchorModal>
    </>
  )
}

export default HeaderMenuModal
