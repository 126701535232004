import {AsyncAction} from 'overmind'
import {state as defaultState} from './state'

type UnscheduledTasksParams = {
  siteId: string
}

export const getUnscheduledTasks: AsyncAction<UnscheduledTasksParams> = async (
  {state, effects},
  params: UnscheduledTasksParams
) => {
  state.v1.unscheduledTasks = defaultState
  state.v1.unscheduledTasks.pending = true
  try {
    state.v1.unscheduledTasks.data = await effects.v1.unscheduledTasks.api.getUnscheduledTaskGroups(params.siteId)
  } catch (err) {
    state.v1.unscheduledTasks.error = err
  } finally {
    state.v1.unscheduledTasks.pending = false
  }
}
