import {Derive, Statemachine, statemachine} from 'overmind'
import {CognitoUser} from '@aws-amplify/auth'
import {CognitoUserExtended} from './auth'
import {
  BaseQuery,
  TaskGroup,
  AllTasks,
  PossibleAlarm,
  AlarmSetting,
  TaskType,
  AssetData,
  MeasurementMethod,
  TaskTypeKey,
  EquipmentType,
  Equipment,
  StorageType,
  SensorChannel,
  WasteScaleCategory,
  DishwasherType,
  CoolerType,
  DevicesForSite
} from './rest'
import {SuspendedPeriod} from './settings/suspendedPeriods/state'

export enum ErrorView {
  UPDATE_REQUIRED, // backend api version is higher than app api version
  SYSTEM_DOWN, // backend not responding
  OFFLINE, // device offline
  BACKEND_UPDATING, // backend apiversion lower
  UNAUTHORIZED, // 401 or 403
  DEFAULT // unknown error
}

export type State = {
  appStateMachine: Statemachine<
    | 'STARTUP'
    | 'AUTHENTICATING'
    | 'AUTHENTICATED'
    | 'UNAUTHENTICATED'
    | 'UNAUTHENTICATING'
    | 'LOADING'
    | 'READY'
    | 'ERROR'
  >
  errorView: ErrorView
  selectedChainId?: string
  notifications: {[id: string]: Notification}
  notificationList: Derive<State, Notification[]>
  initialData: BaseQuery
  initLoading: boolean
  refreshSessionTaskId: number | null
  currentUser: CognitoUser | null
  isLoggedIn: Derive<State, boolean>
  isAuthenticating: boolean
  authenticationError: string | undefined
  forcePasswordResetUser: CognitoUserExtended | undefined
  isOnline: boolean
  appUpdated: boolean
  todaysTasks: AllTasks[]
  activeAlarms: PossibleAlarm[]
  resolvedAlarms: PossibleAlarm[]
  alarmSettings: AlarmSetting[]
  doesApiMatch: boolean | null
  equipments: Equipment[]
  storages: Equipment[]
  freezers: Equipment[]
  dishwashers: Equipment[]
  coolers: Equipment[]
  storageData: Derive<State, Equipment[]> // TODO rename this to equipmentData?
  equipmentById: Derive<
    State,
    {
      [key: string]: Equipment
    }
  >
  equipmentTypes: EquipmentType[]
  equipmentTypeById: Derive<
    State,
    {
      [key: string]: EquipmentType
    }
  >
  storageTypes: StorageType[]
  storageTypeById: Derive<
    State,
    {
      [key: string]: StorageType
    }
  >
  dishwasherTypes: DishwasherType[]
  dishwasherTypeById: Derive<
    State,
    {
      [key: string]: DishwasherType
    }
  >
  coolerTypes: CoolerType[]
  coolerTypeById: Derive<
    State,
    {
      [key: string]: CoolerType
    }
  >
  sensorChannels: SensorChannel[]
  freeSensorChannels: SensorChannel[]
  gatewaysWithSensorsForSites: DevicesForSite[]
  sensorModels: string[]
  wasteScaleCategories: WasteScaleCategory[]
  sensorChannelById: Derive<
    State,
    {
      [key: string]: SensorChannel
    }
  >
  newsTickers: NewsTicker[]
  enabledNewsTickers: Derive<State, NewsTicker[]>
  newsTickerVisible: boolean
  me?: Me
  chains: Chain[]
  chainsById: Derive<
    State,
    {
      [K: string]: Chain
    }
  >
  site?: Site
  orgLevel?: OrgLevel
  locationGroup?: LocationGroup
  taskGroupById: Derive<
    State,
    {
      [key: string]: TaskGroup
    }
  >
  fixedTaskGroups: Derive<State, TaskGroup[]>
  customTaskGroups: Derive<State, TaskGroup[]>
  taskTypeById: Derive<
    State,
    {
      [K in TaskTypeKey]?: TaskType
    }
  >
  measurementMethodById: Derive<
    State,
    {
      [key: string]: MeasurementMethod
    }
  >
  coolerById: Derive<
    State,
    {
      [key: string]: Equipment
    }
  >
}

// Generated by https://quicktype.io

export interface Me {
  user: User
  org: Org
  accessRights: AccessRights
}

export interface AccessRights {
  read: Read
  write: Write
  superuser: boolean
}

export interface Read {
  sites: string[]
}

export interface Write {
  sites: string[]
  locations: string[]
  groups: string[]
  brands: string[]
  chains: string[]
}

export enum OrgLevelType {
  ORG = 'org',
  CHAIN = 'chain',
  BRAND = 'brand',
  LOCATION_GROUP = 'group',
  LOCATION = 'location',
  SITE = 'site'
}

export interface OrgLevel {
  type: OrgLevelType
  id: string
  name: string
}

export interface Org {
  chains: Chain[]
}

export interface Chain {
  id: string
  name: string
  description?: string
  brands: Brand[]
  wastePrice?: number
}

export interface Brand {
  id: string
  name: string
  locationGroups: LocationGroup[]
  visible: boolean
}

export interface LocationGroup {
  id: string
  name: string
  locations: Location[]
  visible: boolean
}

export interface Location {
  id: string
  name: string
  sites: Site[]
  visible: boolean
}

export interface Site {
  id: string
  address?: string
  city?: string
  contactEmail?: string
  contactName?: string
  contactPhone?: string
  internalId?: string
  country?: string
  description?: string
  postalCode?: string
  state?: string
  locale: string //  "fi-FI"
  name: string //  "Kamppi"
  temperatureUnit: string //  "C"
  timeZone: string //  "Europe/Helsinki"
  weightUnit: string //  "G"
  suspendedPeriods: SuspendedPeriod[]
  actorSuggestions: string[]
}

export interface User {
  id: string
  email: string
  language: string
  firstName: string
  lastName: string
  phoneNumber: string
  photo: AssetData
  userToSite: UserToSite[]
  highestRole?: UserRole
}

export interface UserToSite {
  id: string
  userId: string
  siteId: string
  locationId: string
  locationGroupId: string
  brandId: string
  chainId: string
  userRole: UserRole
}
export type UserRole = 'CM' | 'BM' | 'GM' | 'LM' | 'SM' | 'BU' | 'HI'
export interface ChainPayload {
  chain?: {
    name: string
    description?: string
  }
  brand?: {
    name: string
  }

  locationGroup?: {
    name: string
  }

  location?: {
    name: string
  }
  site?: {
    name: string
    temperatureUnit: 'C' | 'F'
    weightUnit: 'G' | 'lbs'
    timeZone: string
    locale: string
  }
}

export enum NewsTickerType {
  Info = 'INFO'
}

export interface NewsTicker {
  id: string
  type: NewsTickerType
  enabled: boolean
  content: string
  createdAt?: Date
  updatedAt?: Date
}

export enum NotificationType {
  DEFAULT = 'default',
  INFO = 'info',
  ERROR = 'error',
  NOTICE = 'notice'
}

export interface Notification {
  id: string
  type: NotificationType
  title: string
  description?: string
  visible: boolean
  icon?: string
  hideAfterDelay?: number
  action?: NotificationAction
}

export interface NotificationAction {
  text: string
  action: (e: React.MouseEvent<HTMLButtonElement>) => void
  showClose: boolean
}

export const state: State = {
  errorView: ErrorView.DEFAULT,
  notifications: {},
  notificationList: ({notifications}) => {
    return Object.values(notifications)
  },
  initialData: {
    taskGroups: [],
    taskTypes: [],
    measurementMethods: []
  },
  doesApiMatch: null,
  initLoading: true,
  refreshSessionTaskId: null,
  isAuthenticating: false,
  authenticationError: undefined,
  forcePasswordResetUser: undefined,
  currentUser: null,
  todaysTasks: [],
  activeAlarms: [],
  resolvedAlarms: [],
  alarmSettings: [],
  appStateMachine: statemachine({
    initial: 'STARTUP',
    states: {
      STARTUP: ['AUTHENTICATING', 'ERROR'],
      AUTHENTICATING: ['AUTHENTICATED', 'UNAUTHENTICATED', 'ERROR'],
      AUTHENTICATED: ['UNAUTHENTICATING', 'READY', 'ERROR', 'LOADING'],
      LOADING: ['READY', 'ERROR', 'UNAUTHENTICATED'],
      UNAUTHENTICATED: ['AUTHENTICATING'],
      UNAUTHENTICATING: ['UNAUTHENTICATED', 'AUTHENTICATED'],
      READY: ['UNAUTHENTICATING', 'ERROR', 'AUTHENTICATING'],
      ERROR: ['AUTHENTICATING', 'STARTUP']
    }
  }),
  storages: [],
  freezers: [],
  dishwashers: [],
  coolers: [],
  storageData: state => {
    return [...state.storages, ...state.freezers, ...state.dishwashers, ...state.coolers]
  },
  equipments: [],
  equipmentById: ({equipments}) => {
    return equipments.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  equipmentTypes: [],
  equipmentTypeById: ({equipmentTypes}) => {
    return equipmentTypes.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  storageTypes: [],
  storageTypeById: ({storageTypes}) => {
    return storageTypes.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  dishwasherTypes: [],
  dishwasherTypeById: ({dishwasherTypes}) => {
    return dishwasherTypes.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  coolerTypes: [],
  coolerTypeById: ({coolerTypes}) => {
    return coolerTypes.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  sensorChannels: [],
  freeSensorChannels: [],
  gatewaysWithSensorsForSites: [],
  sensorModels: [],
  wasteScaleCategories: [],
  sensorChannelById: ({sensorChannels}) => {
    return sensorChannels.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  me: undefined,
  newsTickers: [],
  enabledNewsTickers: ({newsTickers}) => {
    return newsTickers?.filter((t: NewsTicker) => t.enabled) || []
  },
  newsTickerVisible: false,
  chains: [],
  chainsById: ({chains}) => {
    return chains.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  site: undefined,
  orgLevel: undefined,
  isLoggedIn: ({currentUser}) => {
    return !!currentUser
  },
  isOnline: navigator.onLine,
  appUpdated: false,
  taskGroupById: ({initialData}) => {
    return initialData.taskGroups.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  fixedTaskGroups: ({initialData}) => {
    return initialData.taskGroups.filter(tg => {
      return tg.fixed === true
    })
  },
  customTaskGroups: ({initialData}) => {
    return initialData.taskGroups.filter(tg => {
      return tg.fixed !== true
    })
  },
  taskTypeById: ({initialData}) => {
    return initialData.taskTypes.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  measurementMethodById: ({initialData}) => {
    return initialData.measurementMethods.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  coolerById: ({coolers}) => {
    return coolers.reduce((a, b) => ({...a, [b.id]: b}), {})
  }
}
