import React from 'react'
import {Text} from '../../atoms/Text'
import styled from 'styled-components'

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string
}
export const Checkbox: React.FC<CheckboxProps> = ({label, ...rest}) => {
  return (
    <CheckboxContainer>
      <CheckboxInput type="checkbox" {...rest} />
      {label && (
        <label htmlFor={rest.id}>
          <Text size="M">{label}</Text>
        </label>
      )}
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`

const CheckboxInput = styled.input`
  flex-shrink: 0;
  position: relative;
  vertical-align: middle;
  appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s ease-out;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpath d='M21.16,21.16H2.84V2.84h18.31V21.16z M3.84,20.16h16.31V3.84H3.84V20.16z'/%3E%3C/svg%3E%0A");
  &:checked {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpolygon points='20.16,20.16 3.84,20.16 3.84,3.84 20.16,3.84 20.16,7.56 12.22,15.5 6.83,10.11 6.12,10.82 12.22,16.91 24.06,5.07 23.35,4.37 21.16,6.56 21.16,2.84 2.84,2.84 2.84,21.16 21.16,21.16 21.16,12.92 20.16,12.92 '/%3E%3C/svg%3E%0A");
  }
  &:active {
    border: 1px solid #34495e;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`
