import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconHumidity = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1525 6.27338C13.1525 3.91303 15.0987 2 17.5 2C19.9013 2 21.8475 3.91303 21.8475 6.27338V28.367C24.9029 29.9166 27 33.0432 27 36.6619C27 41.8192 22.7468 46 17.5 46C12.2532 46 8 41.8192 8 36.6619C8 33.0432 10.0971 29.9166 13.1525 28.367V6.27338ZM17.5 3.26619C15.8101 3.26619 14.4407 4.61232 14.4407 6.27338V29.1672L14.0666 29.3369C11.2462 30.6167 9.28814 33.4118 9.28814 36.6619C9.28814 41.1199 12.9647 44.7338 17.5 44.7338C22.0353 44.7338 25.7119 41.1199 25.7119 36.6619C25.7119 33.4118 23.7538 30.6167 20.9334 29.3369L20.5593 29.1672V6.27338C20.5593 4.61232 19.1899 3.26619 17.5 3.26619ZM17.3862 33.6546C15.7166 33.6546 14.3635 35.0008 14.3635 36.6618C14.3635 38.3229 15.7166 39.669 17.3862 39.669C19.0558 39.669 20.4088 38.3229 20.4088 36.6618C20.4088 35.0008 19.0558 33.6546 17.3862 33.6546ZM18.0095 32.4331V17.9375H16.7495V32.435C14.679 32.741 13.0908 34.5167 13.0908 36.6618C13.0908 39.0222 15.0137 40.9352 17.3862 40.9352C19.7587 40.9352 21.6815 39.0222 21.6815 36.6618C21.6815 34.5121 20.0865 32.7334 18.0095 32.4331Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6873 9.20039C37.6677 9.17455 37.648 9.14863 37.6283 9.12265C36.9335 8.20658 36.1727 7.20364 35.4964 6.1237C34.8282 7.18582 34.0785 8.17571 33.3933 9.08052C33.3732 9.10706 33.3531 9.13352 33.3331 9.15991C32.5369 10.2112 31.8447 11.1291 31.3413 12.0016C30.835 12.8789 30.625 13.5377 30.625 14.0548C30.625 16.3112 32.6796 18.381 35.5 18.381C38.3204 18.381 40.375 16.3112 40.375 14.0548C40.375 13.541 40.167 12.8878 39.665 12.0183C39.1655 11.1531 38.4785 10.2434 37.6873 9.20039ZM34.5829 4.4866C33.8643 5.77019 32.9401 6.9907 32.0391 8.18061L32.0359 8.18481C30.475 10.2458 29 12.1933 29 14.0548C29 17.333 31.9158 20 35.5 20C39.0842 20 42 17.333 42 14.0548C42 12.2004 40.535 10.2691 38.9838 8.22432L38.9819 8.22186C38.0718 7.02175 37.1381 5.7907 36.4138 4.48906C36.3854 4.43807 36.3574 4.38696 36.3296 4.33575C36.185 4.0685 36.0495 3.79824 35.9251 3.52462C35.9251 3.5247 35.925 3.52454 35.9251 3.52462C35.8797 3.42481 35.8357 3.3243 35.7933 3.22358C35.7466 3.11218 35.6442 3 35.5 3C35.356 3 35.2538 3.11179 35.2069 3.22281C35.1643 3.32331 35.1202 3.42339 35.0745 3.52307C35.0746 3.52292 35.0745 3.52322 35.0745 3.52307C34.9495 3.79603 34.813 4.06638 34.6675 4.33352C34.6396 4.38464 34.6115 4.43567 34.5829 4.4866Z"
      />
    </svg>
  )
}
