import {Asset} from '../home/site/state'
import {CycleLog, EventStatus, SensorValue} from '../rest'
import {Alarm} from '../../pages/tasks/GeneralTasks/CoolingProgress'
import {AlarmSubType} from '../alarms/state'

export interface PerformableTask {
  id: string
  scheduleInstance: string
  taskGroup: {
    icon: string
    name: string
  }
  taskType: {
    id: string
  }
  equipmentName: string
  target: string
  createdAt: string
  updatedAt: string
  name: string
  startTime: string | null
  targetTime: string | null

  subtasks: string[]
  doneSubtasks: string[]
  value: string | number | readonly string[] | undefined

  eventId: string | null

  status: string

  minValue: number
  maxValue: number
  suggestions: string[]
  defaultSuggestions: string[]
  unit: string
  wasteTaskType: string

  ingredientSuggestions: string[]
  choices: string[]
  notes: string
  asset: Asset
  choice: string
  instructions: string | null
  description: string | null

  createdByEquipmentId: string | null
  cycleLog: CycleLog | null
  alarms?: Alarm[]
  latestValue: SensorValue | null
  coolingStartedAt: string | null
  coolingTimeLimit: number | null
  actorSuggestions: string[]
  subTasksWithActors: Subtask[]
}

export interface AlarmEntity {
  alarmThreshold: number
  alarmType: AlarmSubType
  alarmValue: number
  createdAt: string
  endedAt: string | null
  id: string
  lastMeasurement: number | null
  reason: string | null
  resolution: string | null
  resolvedAt: string | null
  resolvedBy: string | null
  startedAt: string
  type: string
}

export interface EventResponse {
  alarms: AlarmEntity[]
  asset: string | null
  actor: string | null
  comment: string | null
  choise: string | null
  createdAt: string
  updatedAt: string | null
  cycleLog: CycleLog | null
  id: string
  photoUrl: string | null
  remarks: string | null
  reportedActor: string | null
  scheduledAt: string | null
  siteId: string
  status: EventStatus
  suggestions: string[]
  task: {id: string; name: string; taskType: string; taskGroup: string}
  value: number
  videoUrl: string | null
  coolingStartedAt: string | null
  coolingValues: {id: string; timestamp: string; value: number}[]
  doneSubtasks: string[]
  ingredients: string[]
}

export interface Subtask {
  id: string
  event: string
  subtaskName: string
  completedBy: string
}
//added new status codes as per the values coming from backend
export enum EventStatusCode {
  REJECTED = 'rejected',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  NOT_STARTED = 'not-start'
}
