import React from 'react'
import {IconFolder, IconFolderLinked, IconSettings} from '../../../Assets/Icons/LargeIcons'
import {IconButton} from '../../../Components/Atoms/Buttons'
import {ListContainer, ListItem} from '../../../Components/Atoms/Lists'
import styled from 'styled-components'
import {colors} from '../../../sharedComponents/colors'
import DoubleComponent from '../../../Components/Molecules/ListComponents/DoubleComponent'
import {DocumentFolder, DocumentFolderType} from '../../../state/documents/state'
import {InstructionsFolder} from '../../../state/instructions/state'

const ItemContent = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`

interface DocumentItemProps {
  icon: any
  name: string
  description?: string
  url?: string
  onClick?: () => void
}
const DocumentFolderListItem = ({icon, name, description, url, onClick}: DocumentItemProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  //type === DocumentFolderType.Internal ? <IconFolder /> : <IconFolderLinked />

  return (
    <ListItem
      icon={icon}
      onClick={handleClick}
      action={<IconButton icon={<IconSettings color={colors.system.grey_1} />} />}
    >
      <ItemContent>
        <DoubleComponent title={name} description={url || description} />
      </ItemContent>
    </ListItem>
  )
}

type FolderType = DocumentFolder | InstructionsFolder

interface DocumentFolderListProps {
  folders: FolderType[]
  onClick?: (folderId: string) => void
}

export const DocumentFolderList = ({folders, onClick}: DocumentFolderListProps) => {
  const handleClick = (id: string) => {
    if (onClick) {
      onClick(id)
    }
  }

  const getURL = (folder: FolderType) => {
    if ('url' in folder) {
      return folder.url
    }
    return undefined
  }

  const getIcon = (folder: FolderType) => {
    if ('type' in folder) {
      return folder.type === DocumentFolderType.External ? <IconFolderLinked /> : <IconFolder />
    }
    return <IconFolder />
  }

  return (
    <ListContainer>
      {folders && folders.length > 0
        ? folders.map(df => {
            const icon = getIcon(df)
            return (
              <DocumentFolderListItem
                icon={icon}
                key={df.id}
                name={df.name}
                description={df.description}
                url={getURL(df)}
                onClick={() => handleClick(df.id)}
              />
            )
          })
        : null}
    </ListContainer>
  )
}

export default DocumentFolderList
