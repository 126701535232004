import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconKettle = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3.27064C20.0338 3.27064 16.9202 5.96533 16.6496 9.23209H31.3503C31.0798 5.96533 27.9662 3.27064 24 3.27064ZM24 2C19.4838 2 15.6549 5.13017 15.3857 9.23209H5.14417V10.5027H8.28518V16.8382H5.14405H2.62995V16.8383H2V17.4737V21.2769V21.9122H2.62995H8.28518V40.2937C8.28518 43.445 10.8186 46 13.9433 46H34.0561C37.1809 46 39.7143 43.445 39.7143 40.2937V21.9122H45.37H46V21.2769V17.4737V16.8383H45.37V16.8382H41.5989H39.7143V10.5027H42.8557V9.23209H32.6142C32.345 5.13017 28.5161 2 24 2ZM9.54516 21.9122H9.54509V40.2937C9.54509 42.7433 11.5144 44.7294 13.9433 44.7294H34.0561C36.4851 44.7294 38.4544 42.7433 38.4544 40.2937V10.5028H9.54509V16.8383H9.54516V17.4737V21.2769V21.9122ZM3.25991 18.109H8.28518V20.6416H3.25991V18.109ZM14.5735 39.0256V28.8835H13.3136V39.0256C13.3136 40.0779 14.1592 40.9286 15.2006 40.9286H17.7147V39.658H15.2006C14.8542 39.658 14.5735 39.3753 14.5735 39.0256ZM39.7147 20.6416V18.109H44.7401V20.6416H39.7147Z"
      />
    </svg>
  )
}
