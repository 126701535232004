import React from 'react'
import styled from 'styled-components'

import InputWithTags from './InputWithTags'
import PerformTaskForm from './PerformTaskForm'
import PerformTaskTitle from './PerformTaskTitle'
import {EventStatusCode, PerformableTask} from '../../../state/performTask/state'
import {useTranslation} from 'react-i18next'

interface PerformCoolerCoolingTaskProps {
  task: PerformableTask
  setValue: any
  onAssetSaved: () => void
  payload: any
  handleUpdate: () => void
  handleRemove: () => void
}

const PerformCoolerCoolingTask = ({
  task,
  setValue,
  onAssetSaved,
  payload,
  handleRemove
}: PerformCoolerCoolingTaskProps) => {
  const {t} = useTranslation('tasks')
  return (
    <FormContainer>
      <PerformTaskTitle task={task} />
      <InputWithTags
        defaultSuggestions={task.defaultSuggestions || []}
        onChange={setValue}
        savedSuggestions={task.suggestions || []}
      />
      <PerformTaskForm
        onAssetSaved={onAssetSaved}
        onInputChange={setValue}
        payload={payload}
        handleRemove={task.scheduleInstance || task.status === EventStatusCode.IN_PROGRESS ? handleRemove : undefined}
        setValue={setValue}
        confirm={
          task.scheduleInstance || task.status === EventStatusCode.IN_PROGRESS
            ? t(
                'tasks:labels.confirmRemoveTask',
                'Are you sure you want to delete this task? This will also remove any associated resolved alarms.'
              )
            : undefined
        }
      />
    </FormContainer>
  )
}

export const FormContainer = styled.form`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
`

export default PerformCoolerCoolingTask
