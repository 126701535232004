import React from 'react'
import {SharedIconProps} from '..'

export const IconSensor = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6164 14.1399C36.0685 16.6698 37.5801 20.1566 37.5801 24.0009C37.5801 27.8426 36.0685 31.3308 33.6163 33.8595L32.6461 32.9703C34.8671 30.68 36.2467 27.5092 36.2467 24.0009C36.2467 20.4901 34.8671 17.3205 32.646 15.0289L33.6164 14.1399Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4214 24.0001C10.4214 20.1572 11.9329 16.669 14.3852 14.1403L15.3553 15.0295C13.1344 17.3197 11.7547 20.4905 11.7547 24.0001C11.7547 27.5097 13.1343 30.6793 15.3555 32.9709L14.3851 33.8599C11.933 31.33 10.4214 27.8432 10.4214 24.0001Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9997 20.0177C21.9234 20.0177 20.1751 21.7641 20.1751 24.0009C20.1751 26.2374 21.9232 27.9827 23.9997 27.9827C26.0751 27.9827 27.8243 26.2373 27.8243 24.0009C27.8243 21.7642 26.0748 20.0177 23.9997 20.0177ZM18.8418 24.0009C18.8418 21.1211 21.1142 18.7214 23.9997 18.7214C26.8839 18.7214 29.1576 21.121 29.1576 24.0009C29.1576 26.8809 26.8836 29.279 23.9997 29.279C21.1144 29.279 18.8418 26.8808 18.8418 24.0009Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 24C2 17.7592 4.454 12.1009 8.43092 8L9.40106 8.88924C5.65542 12.7517 3.33333 18.0926 3.33333 24C3.33333 29.9087 5.65545 35.2495 9.40098 39.1106L8.43099 40C4.45397 35.9003 2 30.242 2 24Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5704 8C43.546 12.1009 46 17.7591 46 23.9999C46 30.2419 43.546 35.9002 39.5703 39.9998L38.6002 39.1106C42.3445 35.2495 44.6666 29.9086 44.6666 23.9999C44.6666 18.0925 42.3445 12.7516 38.6001 8.88909L39.5704 8Z"
      />
    </svg>
  )
}
