import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconTaskList = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69994 10.7101L14.8152 3L15.6724 4.00563L6.69991 12.5303L2 8.06462L2.8573 7.05903L6.69994 10.7101Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69994 26.9449L14.8152 19.2347L15.6724 20.2404L6.69991 28.765L2 24.2994L2.8573 23.2938L6.69994 26.9449Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69994 43.1799L14.8152 35.4697L15.6724 36.4754L6.69991 45L2 40.5344L2.8573 39.5288L6.69994 43.1799Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.0002 8.9143H19.0884V7.5614H46.0002V8.9143Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.0002 25.1493H19.0884V23.7964H46.0002V25.1493Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.0002 41.384H19.0884V40.0311H46.0002V41.384Z" />
    </svg>
  )
}
