export const colors = {
  system: {
    blue: '#007AFF',
    green: '#34C759',
    red: '#FF3B30',
    yellow: '#FFCC00',
    orange: '#FF9500',
    tale: '#5AC8FA',

    grey_1: '#8E8E93',
    grey_2: '#AEAEB2',
    grey_3: '#C7C7CC',
    grey_4: '#D1D1D6',
    grey_5: '#E5E5EA',

    darkGrey_85: '#262626',
    grey_50: '#7F7F7F',
    lightGrey_25: '#BFBFBF',
    lightGrey_10: '#E6E6E6',
    lightGrey_5: '#F2F2F2',
    white: '#FFFFFF'
  },
  brand: {
    signalOrange: '#FF941F',
    citrone: '#EDBD33',
    tabrizBlue: '#40A8F5',
    midGreen: '#637A4A',
    brightRed: '#F35828',
    candyPink: '#EDC7C9',
    purple: '#543080',
    lavendel: '#BAABCF',

    black: '#000000',
    cobalt: '#2E3B47',
    mossGreen: '#5C5E52',
    smoke: '#827D7D',
    realGrey: '#B8BAB2',
    stone: '#E0C7A3',
    mist: '#EBE3CF',
    paleGrey: '#F2EDE5',

    cobalt_60: '#7D848E',
    cobalt_20: '#CBCED4'
  }
}
