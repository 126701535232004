import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconCompletedCycle = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7049 11.876H35.0647V12.4971V21.5142H36.3451V13.8059C39.6927 17.0897 41.7673 21.5916 41.7673 26.5725C41.7673 36.6156 33.3752 44.7578 23.0238 44.7578C12.6724 44.7578 4.28034 36.6156 4.28034 26.5725C4.28034 21.1501 6.74031 16.2952 10.6238 12.9617L9.7754 12.0313C5.63146 15.5884 3 20.7762 3 26.5725C3 37.3017 11.9653 46 23.0238 46C34.0823 46 43.0476 37.3017 43.0476 26.5725C43.0476 21.3419 40.9057 16.6067 37.4422 13.1182H45V11.876H35.7049Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3874 13.8793L32.8719 2L33.7313 2.88893L21.3874 15.6572L15 9.05019L15.8594 8.16126L21.3874 13.8793Z"
      />
    </svg>
  )
}
