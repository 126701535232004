import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Text, TextSize} from '../../atoms/Text'
import {Title} from '../../atoms/Title'

export const NO_DATA_TEXT = '--'

export interface ApplianceDataValueAndUnitProps {
  value?: number | string | React.ReactNode
  unit?: string
  color?: string
  unitTextSize?: TextSize
  valueTextLevel?: number
}

export const ApplianceDataValueAndUnit: React.FC<ApplianceDataValueAndUnitProps> = ({
  value,
  unit,
  color,
  unitTextSize,
  valueTextLevel,
  ...rest
}) => {
  const valueText = value ?? NO_DATA_TEXT

  return (
    <ApplianceDataValueAndUnitContainer {...rest}>
      <Title level={valueTextLevel ?? 4} strong color={color} as="span">
        {valueText}
      </Title>
      {value !== undefined && value !== null && unit && (
        <Text size={unitTextSize ?? 'L'} strong color={color}>
          {unit}
        </Text>
      )}
    </ApplianceDataValueAndUnitContainer>
  )
}

ApplianceDataValueAndUnit.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  color: PropTypes.string,
  unitTextSize: PropTypes.oneOf<TextSize>(['XXS', 'XS', 'S', 'M', 'L', 'XL']),
  valueTextLevel: PropTypes.number
}

const ApplianceDataValueAndUnitContainer = styled.div`
  display: inline-grid;
  gap: 0.125rem;
  grid-auto-flow: column;
  align-items: start;
`
