import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../state'
import styled from 'styled-components'
import {colors} from '../../../sharedComponents/colors'
import {Title} from '../../Atoms/Typography'
import tiny from 'tinycolor2'
import {v4 as uuid} from 'uuid'
import {NewsTicker} from '../../../state/state'

const NewsWidget = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 96px 1fr;
  grid-template-rows: 3rem;
`

const NewsType = styled.div`
  position: relative;
  height: 100%;
  background-color: ${colors.system.grey_5};
  color: ${colors.brand.cobalt};

  > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const NewsContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${tiny(colors.system.lightGrey_5)
    .setAlpha(0.8)
    .toRgbString()};
  box-sizing: content-box;
  overflow: hidden;
`
interface NewsScrollerProps {
  contentLength: number
}
const NewsScroller = styled.div<NewsScrollerProps>`
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 0;
  box-sizing: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-name: ticker;
  animation-duration: ${props => `${Math.max(15, props.contentLength * 0.1)}s`};

  @keyframes ticker {
    0% {
      opacity: 0;
    }
    2% {
      opacity: 1;
    }
    4% {
      transform: translateX(0);
    }
    95% {
      transform: translateX(-100%);
      opacity: 1;
    }
    97% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(1000%);
      opacity: 0;
    }
  }
`

const NewsContent = styled.div`
  padding: 0 0.5rem;
`

const NewsTickerComponent = () => {
  const {state, actions} = useAppState()
  const {t} = useTranslation()
  const [firstTicker, setFirstTicker] = useState<NewsTicker>()

  const showOrHide = (visible: boolean) => {
    actions.setNewsTickerVisible(visible)
  }

  // set ticker visible from component mounts
  useEffect(() => {
    const news: NewsTicker[] = (state.enabledNewsTickers as unknown) as NewsTicker[]
    const first = news && news.length > 0 ? news[0] : undefined
    actions.setNewsTickerVisible(!!first)
  }, [])

  // when ticker is changed (or shown/hide), start a new timer to show or hide it again
  useEffect(() => {
    const news: NewsTicker[] = (state.enabledNewsTickers as unknown) as NewsTicker[]
    const first = news && news.length > 0 ? news[0] : undefined
    setFirstTicker(first)

    const timer = state.newsTickerVisible
      ? setTimeout(() => showOrHide(false), 1000 * 60 * 2) // hide after 2 minutes
      : setTimeout(() => showOrHide(true), 1000 * 60 * 28) // show after 28 minutes
    return () => {
      clearTimeout(timer)
    }
  }, [state.enabledNewsTickers, state.newsTickerVisible])

  return (
    <>
      {firstTicker && state.newsTickerVisible ? (
        <NewsWidget key={uuid()}>
          <NewsType>
            <Title level={4}>{t(`common:newsTickerTypes.${firstTicker.type}`, `${firstTicker.type}`)}</Title>
          </NewsType>
          <NewsContainer>
            <NewsScroller contentLength={firstTicker.content.length}>
              <NewsContent>{firstTicker.content}</NewsContent>
            </NewsScroller>
          </NewsContainer>
        </NewsWidget>
      ) : null}
    </>
  )
}

export default NewsTickerComponent
