import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconPower = (props: SharedIconProps) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.625 18.7145V5H20.375V18.7145H19.625Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7364 8.97769C23.7361 8.97761 23.7366 8.97776 23.7364 8.97769C24.0076 9.05925 24.2763 9.14967 24.5403 9.24822C27.2204 10.2484 29.5132 12.1101 31.0596 14.5597C32.7585 17.2509 33.4429 20.4715 32.9875 23.6318C32.532 26.7921 30.9672 29.6806 28.5795 31.7683C26.1919 33.856 23.1412 35.0032 19.9861 34.9998C16.8311 34.9964 13.7828 33.8427 11.3995 31.7499C9.0162 29.6571 7.45739 26.7653 7.00853 23.604C6.55967 20.4427 7.2508 17.2236 8.95535 14.5361C10.5069 12.0898 12.8035 10.233 15.4857 9.23854C15.7502 9.14046 16.0185 9.05077 16.2902 8.96973L16.5413 9.8323C13.7197 10.6739 11.2921 12.5162 9.70289 15.0219C8.11371 17.5275 7.46936 20.5288 7.88784 23.4761C8.30632 26.4234 9.75962 29.1195 11.9816 31.0706C14.2036 33.0218 17.0456 34.0974 19.9871 34.1006C22.9286 34.1037 25.7728 33.0341 27.9988 31.0878C30.2249 29.1414 31.6838 26.4484 32.1085 23.502C32.5331 20.5556 31.895 17.5529 30.3111 15.0439C28.7276 12.5356 26.3047 10.6885 23.486 9.84047C23.4852 9.84022 23.4843 9.83997 23.4835 9.83972L23.7364 8.97769Z"
      />
    </svg>
  )
}
