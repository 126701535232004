import React, {useState} from 'react'
import styled from 'styled-components'
import {Input, Tag} from '../../../sharedComponents/components'

import {EventPayload} from '../../../state/rest'
import {useTranslation} from 'react-i18next'

interface InputWithTagsProps {
  defaultSuggestions: string[]
  onChange: (key: keyof EventPayload, selected: string[]) => void
  value?: string
  label?: string
  keyName?: keyof EventPayload
  savedSuggestions?: string[]
  multiple?: boolean
  placeholder?: string
  required?: boolean
}

const InputWithTags = ({
  keyName,
  defaultSuggestions,
  onChange,
  savedSuggestions,
  value,
  label,
  placeholder,
  multiple = false,
  required
}: InputWithTagsProps) => {
  let initialSuggestions = savedSuggestions
    ? savedSuggestions.map(ss => defaultSuggestions.findIndex(ds => ds === ss))
    : []
  if (initialSuggestions && initialSuggestions.length > 0 && !multiple) {
    initialSuggestions = initialSuggestions.slice(0, 1)
    if (initialSuggestions[0] === -1) {
      initialSuggestions = []
    }
  }

  const [selected, setSelected] = useState<number[]>(initialSuggestions)
  const [textValue, setTextValue] = useState(
    value ? value : savedSuggestions?.filter(ss => !defaultSuggestions.includes(ss)).join(',') || ''
  )

  const {t} = useTranslation('tasks')

  const handleSelect = (index: number) => {
    let selectedIndices = []
    if (!selected.includes(index)) {
      selectedIndices = multiple ? [...selected, index] : [index]
    } else {
      selectedIndices = selected.filter(s => s !== index)
    }
    setSelected(selectedIndices)
    const selectedTags = selectedIndices.map(s => defaultSuggestions[s])
    onChange(keyName || 'suggestions', selectedTags)
    setTextValue('')
  }

  const handleText = (e: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value)
    const selectedTags = selected.map(s => defaultSuggestions[s])
    onChange(keyName || 'suggestions', selectedTags.concat(e.target.value))
  }
  const defaultLabelText = t('tasks:labels.measurementTarget', 'What are you measuring?')
  return (
    <Input
      name={'customSuggestion' + label}
      id={'customSuggestion' + label}
      label={label || defaultLabelText}
      placeholder={placeholder || defaultLabelText}
      onChange={handleText}
      value={textValue}
      disabled={selected.length > 0}
      bottomArea={
        <TagsContainer>
          {defaultSuggestions.map((ds, index) => (
            <Tag
              disabled={textValue.length > 0}
              styleType={selected.includes(index) ? 'active' : 'default'}
              key={index}
              onClick={() => handleSelect(index)}
            >
              {ds}
            </Tag>
          ))}
        </TagsContainer>
      }
      required={required}
    />
  )
}

const TagsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`
export default InputWithTags
