import React from 'react'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../state'
import {LabelInput} from '../../Atoms/Forms'
import {Baseline, Row} from '../../Layout/Grid'
import {P, H4, SmallUnit} from '../../Atoms/Typography'
import styled from 'styled-components'

const CoolerTypeListContainer = styled.div`
  padding-top: 2rem;
`

const CoolerTypeList = styled.ul``

const CoolerTypeItem = styled.li`
  position: relative;
  width: 100%;
  padding-bottom: 1.25rem;
  list-style: none;

  :not(:first-child) {
    padding-top: 1.25rem;
  }
`
const CoolerTypeName = styled.div`
  margin-top: 1rem;
  font-family: 'HelveticaNeue-Medium', 'Helvetica Neue Medium', Helvetica, Arial, 'FreeSans', sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
`
// const CoolerTypeCycleType = styled.div`
//   margin-top: 1rem;
// `

interface Props {
  controller: any
}
const EditCoolerTypes = ({controller: fc}: Props) => {
  const {t} = useTranslation(['appliances', 'tasks'])
  const {site} = useAppState().state
  return (
    <CoolerTypeListContainer>
      <Baseline>
        <H4>{t('appliances:labels.alarmLimitsTitle', 'Alarm limits')}</H4>
        <CoolerTypeList>
          {fc.fields && fc.fields.length > 0 ? (
            fc.fields.map((coolerType: any, index: number) => {
              const hasSlow = coolerType.cycleType === 'SLOW' || coolerType.cycleType === 'SLOW-FAST'
              const hasFast = coolerType.cycleType === 'FAST' || coolerType.cycleType === 'SLOW-FAST'
              const timeLimits = []
              if (hasSlow) timeLimits.push(coolerType.slowCycleTimeLimit)
              if (hasFast) timeLimits.push(coolerType.fastCycleTimeLimit)
              return (
                <CoolerTypeItem key={coolerType.coolerTypeId}>
                  <Baseline margin={'1rem'}>
                    <input
                      type="hidden"
                      name={`coolerTypes[${index}].coolerTypeId`}
                      defaultValue={coolerType.coolerTypeId}
                      ref={fc.register({required: true})}
                    />
                    <input
                      type="hidden"
                      name={`coolerTypes[${index}].cycleType`}
                      defaultValue={coolerType.cycleType}
                      ref={fc.register({required: true})}
                    />
                    <CoolerTypeName>
                      {t('appliances:equipmentTypes.cooler', 'Cooler') + ` (${timeLimits.join(', ')} min)`}
                    </CoolerTypeName>
                    {hasSlow && (
                      <>
                        {/*<CoolerTypeCycleType><Label>Fast</Label></CoolerTypeCycleType>*/}
                        <Row childMargin="1rem" align="flex-end">
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.coolerTypes?.[`${index}`]?.slowCycleStartValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`coolerTypes[${index}].slowCycleStartValue`}
                            defaultValue={coolerType.slowCycleStartValue}
                            labelText={t('appliances:labels.coolerStartTemperature', 'Start temp.')}
                          />
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.coolerTypes?.[`${index}`]?.slowCycleTargetValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`coolerTypes[${index}].slowCycleTargetValue`}
                            defaultValue={coolerType.slowCycleTargetValue}
                            labelText={t('appliances:labels.coolerTargetTemperature', 'Target temp.')}
                          />
                          <SmallUnit>°{site?.temperatureUnit}</SmallUnit>
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.coolerTypes?.[`${index}`]?.slowCycleTimeLimit
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`coolerTypes[${index}].slowCycleTimeLimit`}
                            defaultValue={coolerType.slowCycleTimeLimit}
                            labelText={t('tasks:labels.duration', 'Duration')}
                          />
                          <SmallUnit>min</SmallUnit>
                        </Row>
                      </>
                    )}
                    {hasFast && (
                      <>
                        {/*<CoolerTypeCycleType><Label>Fast</Label></CoolerTypeCycleType>*/}
                        <Row childMargin="1rem" align="flex-end">
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.coolerTypes?.[`${index}`]?.fastCycleStartValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`coolerTypes[${index}].fastCycleStartValue`}
                            defaultValue={coolerType.fastCycleStartValue}
                            labelText={t('appliances:labels.coolerStartTemperature', 'Start temp.')}
                          />
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.coolerTypes?.[`${index}`]?.fastCycleTargetValue
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`coolerTypes[${index}].fastCycleTargetValue`}
                            defaultValue={coolerType.fastCycleTargetValue}
                            labelText={t('appliances:labels.coolerTargetTemperature', 'Target temp.')}
                          />
                          <SmallUnit>°{site?.temperatureUnit}</SmallUnit>
                          <LabelInput
                            required
                            onChange={fc.handleChange}
                            type="number"
                            ref={fc.register({required: true})}
                            errorMsg={
                              fc.errors?.coolerTypes?.[`${index}`]?.fastCycleTimeLimit
                                ? t('common:validation.invalidValue', 'Invalid or missing value')
                                : undefined
                            }
                            name={`coolerTypes[${index}].fastCycleTimeLimit`}
                            defaultValue={coolerType.fastCycleTimeLimit}
                            labelText={t('tasks:labels.duration', 'Duration')}
                          />
                          <SmallUnit>min</SmallUnit>
                        </Row>
                      </>
                    )}
                  </Baseline>
                </CoolerTypeItem>
              )
            })
          ) : (
            <P>{t('appliances:messages.noAlarmLimits', 'No defined alarm limits.')}</P>
          )}
        </CoolerTypeList>
      </Baseline>
    </CoolerTypeListContainer>
  )
}

export default EditCoolerTypes
