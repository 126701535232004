import React from 'react'
import {useTranslation} from 'react-i18next'
import styled, {css} from 'styled-components'

import {Number, Heading} from '../../Atoms/Typography'
import {ProgressBar} from '../../Atoms/ProgressBar'
import {IconAlert} from '../../../Assets/Icons/NotificationIcons'
import {IconArrowSmallRight} from '../../../Assets/Icons/TinyIcons'

const AlarmInfoContainer = styled.div<AlarmsInfoContainerProps>`
  text-align: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  display: inline-grid;
  grid-template-rows: 9.5rem 1rem;
  grid-row-gap: 0.5rem;
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  ${props =>
    props.variant === 'quick' &&
    css`
      grid-template-rows: 5.5rem 1.5rem;
      grid-row-gap: 0.75;
    `};
`

interface AlarmsInfoContainerProps {
  onClick?: (e: React.MouseEvent) => void
  variant?: keyof AlarmsInfoVariants
}

const NotificationRow = styled.div`
  position: absolute;
  top: 0;
  left: -0.75rem;
`

const ProgressBarContainer = styled.div<ProgressBarProps>`
  position: relative;
  width: 9.5rem;
  height: 9.5rem;
  margin: auto;

  ${props =>
    props.variant === 'quick' &&
    css`
      width: 5.5rem;
      height: 5.5rem;
    `}
`

interface ProgressBarProps {
  variant?: keyof AlarmsInfoVariants
}
const ProgressBarContent = styled.div<ProgressBarProps>`
  display: inline-grid;
  justify-content: center;
  align-items: center;

  ${props =>
    props.variant === 'default' &&
    css`
      grid-template-rows: 2.75rem 1rem;
      grid-template-columns: 1fr 0.5rem;
      grid-row-gap: 0.5rem;

      & > *:first-child {
        grid-column: 1 / 3;
        grid-row: 1;
      }
    `}

  ${props =>
    props.variant === 'active' &&
    css`
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
    `}

  ${props =>
    props.variant === 'quick' &&
    css`
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
    `}
`

type AlarmsInfoVariants = {
  default: string
  active: string
  quick: string
}
interface AlarmsInfoProps {
  alarms: number
  onClick?: (e: React.MouseEvent) => void
  variant?: keyof AlarmsInfoVariants
}
const AlarmsInfo = ({alarms, variant, onClick}: AlarmsInfoProps) => {
  const {t} = useTranslation('alarms')

  const getPercentage = () => {
    if (alarms > 0) {
      return 100
    }
    return 0
  }

  return (
    <AlarmInfoContainer variant={variant} onClick={onClick}>
      <ProgressBarContainer variant={variant}>
        {alarms > 0 && variant === 'default' ? (
          <NotificationRow>
            <IconAlert />
          </NotificationRow>
        ) : null}
        <ProgressBar percentage={getPercentage()}>
          <ProgressBarContent variant={variant}>
            {variant !== 'quick' ? (
              <Number data-cy="alarm-count" level={2}>
                {alarms}
              </Number>
            ) : null}
            {variant === 'quick' ? (
              <Number data-cy="alarm-count" level={4}>
                {alarms}
              </Number>
            ) : null}
            {variant === 'default' ? (
              <>
                <Heading level={6}>{t('alarms:labels.alarmsTitle', 'Alarms')}</Heading>
                <IconArrowSmallRight />
              </>
            ) : null}
          </ProgressBarContent>
        </ProgressBar>
      </ProgressBarContainer>
      {variant === 'active' ? (
        <Heading level={6}>{t('alarms:labels.activeAlarms', 'Active alarms')}</Heading>
      ) : null}
      {variant === 'quick' ? (
        <Heading level={5}>{t('alarms:labels.activeAlarms', 'Active alarms')}</Heading>
      ) : null}
    </AlarmInfoContainer>
  )
}
AlarmsInfo.defaultProps = {
  variant: 'default'
}

export default AlarmsInfo
