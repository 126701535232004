import {User} from '../state'
import {Derive} from 'overmind'
import {AssetData} from '../rest'
import {Chain} from '../state'

export enum DocumentFolderType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export enum DocumentType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export type Document = {
  id: string
  type: string
  name: string
  description?: string
  createdAt: string
  createdBy: User
  asset?: Partial<AssetData>
  url?: string
  documentFolder?: Partial<DocumentFolder>
  sites: DocumentToSites[] | undefined
}

export type DocumentToSites = {
  id: string
  siteId: string
  documentId: string
}

export type DocumentGroup = {
  type: string
  name: string
  id: string
  description?: string
  url?: string
  chain?: Partial<Chain>
  documents: Document[]
}

export type DocumentFolder = {
  id: string
  type: string
  name: string
  url?: string
  description?: string
  chain?: Partial<Chain>
}

type DocumentState = {
  documents: Document[]
  documentsPending: boolean
  documentsError: Error | null
  documentFolders: DocumentFolder[]
  documentFoldersPending: boolean
  documentFoldersError: Error | null
  internalDocumentFolders: Derive<DocumentState, DocumentFolder[]>
  documentGroups: Derive<DocumentState, DocumentGroup[]>
  documentById: Derive<DocumentState, Record<string, Document>>
  documentFolderById: Derive<DocumentState, Record<string, DocumentFolder>>
}

export const state: DocumentState = {
  documents: [],
  documentsPending: false,
  documentsError: null,
  documentFolders: [],
  documentFoldersPending: false,
  documentFoldersError: null,
  internalDocumentFolders: ({documentFolders}) => {
    return documentFolders.filter(folder => folder.type === DocumentFolderType.Internal)
  },
  documentGroups: ({documents, documentFolders}) => {
    return documentFolders.map(folder => {
      const documentList = documents.filter(document => {
        return document.documentFolder!.id === folder.id
      })
      return {
        id: folder.id,
        type: folder.type,
        name: folder.name,
        url: folder.url,
        description: folder.description,
        documents: documentList
      }
    })
  },
  documentById: ({documents}) => {
    return documents.reduce((a, b) => ({...a, [b.id]: b}), {})
  },
  documentFolderById: ({documentFolders}) => {
    return documentFolders.reduce((a, b) => ({...a, [b.id]: b}), {})
  }
}
