import React from 'react'
import {useTranslation} from 'react-i18next'
import {GoBackHeaderTitle} from '../../Molecules/ViewComponents/ViewHeader'
import {Label, LabelInputWrapper, LabelTextArea} from '../../Atoms/Forms'
import AssetWidget, {AssetGroup} from '../Assets/AssetWidget'
import TaskLinks from './TaskLinks'

export interface AnyObject {
  [key: string]: any
}

export const TasksGoBackHeaderTitle = ({label, isEditing}: {label: string; isEditing: boolean}) => {
  const {t} = useTranslation('tasks')
  return (
    <GoBackHeaderTitle
      label={label}
      path={isEditing ? '/edit/tasks' : '/create/task'}
      backLabel={
        isEditing
          ? t('tasks:labels.taskSettings', 'Tasks settings')
          : t('tasks:labels.selectTaskTypeTitle', 'Select task type')
      }
    />
  )
}

export const CommonInformationFields = ({useForm}: {useForm: AnyObject}) => {
  const {t} = useTranslation(['tasks', 'assets'])
  const fc = useForm

  const MAX_LENGTHS = {
    instructions: 5000,
    description: 1000
  }

  return (
    <>
      <LabelInputWrapper>
        <Label>{t('assets:labels.addImagesTitle', 'Add images, videos or links for task information')}</Label>
      </LabelInputWrapper>
      <AssetGroup>
        <AssetWidget handleRemove={fc.removeAsset} handleAdd={fc.addSelectedAsset} savedAssets={fc.assets} />
        <TaskLinks
          ref={fc.register({required: true})}
          hasLinks={!!fc.form.linkDescription}
          hasErrors={!!fc.errors.linkDescription || !!fc.errors.linkUrl}
        />
      </AssetGroup>
      <LabelTextArea
        name="instructions"
        labelText={t('tasks:labels.taskInstructions', {
          defaultValue: 'Instructions (max {{length}} characters)',
          length: MAX_LENGTHS.instructions
        })}
        id="instructions"
        ref={fc.register}
        maxLength={MAX_LENGTHS.instructions}
      />
      <Label>{`${fc.form.instructions?.length || 0} / ${MAX_LENGTHS.instructions}`}</Label>
      <LabelTextArea
        name="description"
        labelText={t('tasks:labels.taskDescription', {
          defaultValue: 'What you need (max {{length}} characters)',
          length: MAX_LENGTHS.description
        })}
        id="description"
        ref={fc.register}
        maxLength={MAX_LENGTHS.description}
      />
      <Label>{`${fc.form.description?.length || 0} / ${MAX_LENGTHS.description}`}</Label>
    </>
  )
}
