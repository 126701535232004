import {differenceInSeconds, addSeconds} from 'date-fns'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../state'
import {Appliance} from '../../state/appliances/state'
import {isCoolingTimeExceeded} from '../tasks/GeneralTasks/CoolingProgress'
import {getApplianceData} from './appliancesUtils'
import {ApplianceDataCycleProgress, ApplianceDataCycleProgressType} from '../../sharedComponents/components'

export const UPDATE_INTERVAL_MS = 1000
export const SECONDS_IN_MINUTE = 60
export const DEFAULT_TIME_SECS = 0

interface ApplianceCoolingProgressProps {
  appliance: Appliance
  cycleProgressType?: ApplianceDataCycleProgressType
  onClick?: React.MouseEventHandler<HTMLDivElement>
  hasAlarm?: boolean
}

export const ApplianceCoolingProgress: React.FC<ApplianceCoolingProgressProps> = ({
  appliance,
  cycleProgressType = ApplianceDataCycleProgressType.small,
  onClick,
  hasAlarm
}) => {
  const {state} = useAppState()
  const {t} = useTranslation('appliances')
  const [now, setNow] = useState(new Date())

  const data = getApplianceData(t, appliance, state.site)[0]

  const startedAt = data?.cycleStartedAt
  const endedAt = data?.cycleEndedAt
  const maxTime = typeof data?.maxTime === 'number' ? data.maxTime * SECONDS_IN_MINUTE : DEFAULT_TIME_SECS
  const startedTime = new Date(startedAt || now)
  const targetEndTime = new Date(typeof maxTime === 'number' ? addSeconds(startedTime, maxTime) : now)
  const coolingTimeExceeded = !endedAt && isCoolingTimeExceeded(targetEndTime)
  const coolingTargetDuration = differenceInSeconds(targetEndTime, startedTime)
  const start = coolingTimeExceeded ? targetEndTime : startedTime
  const end = endedAt ? new Date(endedAt) : now
  const passedTime = differenceInSeconds(end, start)
  const progressValue = coolingTimeExceeded ? coolingTargetDuration : passedTime
  const targetValue = cycleProgressType === ApplianceDataCycleProgressType.small ? data?.targetValue : undefined
  const targetValueLabelText = `${t('appliances:labels.target', 'Target')}:`

  useEffect(() => {
    if (endedAt) {
      return
    }
    const intervalId = window.setInterval(() => {
      setNow(new Date())
    }, UPDATE_INTERVAL_MS)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [])

  return (
    <ApplianceDataCycleProgress
      key={data?.sensorUuid}
      applianceDataCycleProgressType={cycleProgressType}
      value={data?.value as number | string | undefined}
      unit={data?.unit}
      targetValueLabelText={targetValueLabelText}
      targetValue={targetValue as number | undefined}
      progressValue={progressValue}
      passedTime={passedTime}
      coolingTimeExceeded={coolingTimeExceeded}
      maxTimeSecs={maxTime}
      onClick={onClick}
      compact
      hasAlarm={hasAlarm}
    />
  )
}
