import React, {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import styled from 'styled-components'

import useAssetUploader from './useAssetUploader'
import {AssetUploaderProps} from './types'

const DropzoneContainer = styled.div`
  .dzu-dropzone {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }
  }
`

const AssetUploader: FC<AssetUploaderProps> = (props): ReactElement => {
  const {t} = useTranslation('assets')
  const fc = useAssetUploader({
    addAsset: props.handleAddAsset,
    onAssetSaved: props.onAssetSaved,
    instructions: props.instructions
  })

  return (
    <DropzoneContainer>
      <Dropzone
        inputContent={t('assets:labels.uploadInstructions', 'Drag or click to upload new file')}
        autoUpload={true}
        accept={props.acceptedTypes}
        multiple={props.multiple}
        maxFiles={props.maxFiles}
        getUploadParams={fc.getUploadParams}
        onChangeStatus={fc.onChangeStatus}
      />
    </DropzoneContainer>
  )
}

export default AssetUploader
