import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import tiny from 'tinycolor2'
import {colors} from '../../../colors'
import {Text} from '../Text'

export enum ProgressType {
  linear = 'linear',
  circular = 'circular'
}

interface ProgressProps extends React.HTMLAttributes<HTMLDivElement> {
  progressType?: ProgressType
  value: number
  min: number
  max: number
  label?: string | number | React.ReactNode
  minLabel?: string | number | React.ReactNode
  maxLabel?: string | number | React.ReactNode
  color?: string
  backgroundColor?: string
  strokeWidth?: string
  circleWidth?: string
}

export const Progress: React.FC<ProgressProps> = ({
  progressType,
  label,
  value,
  min,
  max,
  minLabel,
  maxLabel,
  color,
  backgroundColor,
  strokeWidth,
  circleWidth,
  ...rest
}) => {
  const percentage = getPercentage(value, min, max)
  const valueLabelElement = getValueLabelElement(percentage, label, progressType)
  const minLabelElement = getLabelElement(minLabel)
  const maxLabelElement = getLabelElement(maxLabel)

  return (
    <ProgressContainer {...rest}>
      {progressType === ProgressType.linear && (
        <>
          {label && <ProgressBarValueLabelContainer>{valueLabelElement}</ProgressBarValueLabelContainer>}
          <ProgressBar
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin={min}
            aria-valuemax={max}
            color={color}
            backgroundColor={backgroundColor}
            strokeWidth={strokeWidth}
            percentage={percentage}
          />
          {(minLabel || maxLabel) && (
            <ProgressBarLabelContainer>
              {minLabel ? minLabelElement : <span />}
              {maxLabel ? maxLabelElement : <span />}
            </ProgressBarLabelContainer>
          )}
        </>
      )}
      {progressType === ProgressType.circular && (
        <ProgressCircleContainer circleWidth={circleWidth}>
          {label && <ProgressCircleLabelContainer>{valueLabelElement}</ProgressCircleLabelContainer>}
          <ProgressCircle circleWidth={circleWidth} percentage={percentage}>
            <ProgressCircleLeft
              circleWidth={circleWidth}
              strokeWidth={strokeWidth}
              color={color}
              percentage={percentage}
            />
            <ProgressCircleRight
              circleWidth={circleWidth}
              strokeWidth={strokeWidth}
              color={color}
              percentage={percentage}
            />
          </ProgressCircle>
          <ProgressCircleBackground strokeWidth={strokeWidth} backgroundColor={backgroundColor} />
        </ProgressCircleContainer>
      )}
    </ProgressContainer>
  )
}

Progress.defaultProps = {
  progressType: ProgressType.linear,
  color: colors.system.blue,
  backgroundColor: colors.system.grey_5,
  strokeWidth: '0.5rem',
  circleWidth: '4rem'
}

Progress.propTypes = {
  progressType: PropTypes.oneOf<ProgressType>([ProgressType.linear, ProgressType.circular]),
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  label: PropTypes.node,
  minLabel: PropTypes.node,
  maxLabel: PropTypes.node,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  strokeWidth: PropTypes.string,
  circleWidth: PropTypes.string
}

const getPercentage = (value: number, min: number, max: number) => {
  return (Math.min(Math.max(value, min), max) / (max - min)) * 100
}

const getValueLabelElement = (
  percentage: number,
  label?: string | number | React.ReactNode,
  progressType?: ProgressType
) => {
  if (!label) {
    return null
  } else if (React.isValidElement(label)) {
    return label
  } else if (progressType === ProgressType.linear) {
    return (
      <ProgressBarValueLabel size="XS" color={colors.system.grey_50} percentage={percentage}>
        {label}
      </ProgressBarValueLabel>
    )
  } else if (progressType === ProgressType.circular) {
    return (
      <Text size="M" color={colors.system.grey_50}>
        {label}
      </Text>
    )
  } else {
    return null
  }
}

const getLabelElement = (label?: string | number | React.ReactNode) => {
  if (!label) {
    return null
  } else if (React.isValidElement(label)) {
    return label
  } else {
    return (
      <ProgressBarLabel size="XS" color={colors.system.grey_50}>
        {label}
      </ProgressBarLabel>
    )
  }
}

const ProgressContainer = styled.div``

const ProgressBarValueLabelContainer = styled.div`
  padding-bottom: 0.25rem;
`

const ProgressBarLabelContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding-top: 0.25rem;
`

const ProgressBarLabel = styled(Text)`
  display: grid;
  justify-content: center;
  width: 2rem; // Label content can overflow its container
`

const ProgressBarValueLabel = styled(ProgressBarLabel)<{percentage: number}>(
  ({percentage}) => css`
    position: relative;
    left: calc(${percentage}% - (2rem * ${percentage / 100}));
  `
)

const ProgressBar = styled.div<{
  color?: string
  backgroundColor?: string
  strokeWidth?: string
  percentage: number
}>(
  ({color, backgroundColor, strokeWidth, percentage}) => css`
    background-color: ${backgroundColor};
    height: ${strokeWidth};
    border-radius: ${strokeWidth};
    margin: 0 1rem;
    overflow: hidden;

    :after {
      content: '';
      display: block;
      background-color: ${color};
      height: 100%;
      // TODO: box-shadow doesn't work with overflow: hidden which is needed to clip border-radius
      // box-shadow: 0.0625rem 0 0.5rem ${tiny(colors.system.blue)
        .setAlpha(0.35)
        .toString()};
      width: ${percentage}%;
    }
  `
)

const ProgressCircleContainer = styled.div<{circleWidth?: string}>(
  ({circleWidth}) => css`
    position: relative;
    width: ${circleWidth};
    height: ${circleWidth};
  `
)

const ProgressCircle = styled.div<{circleWidth?: string; percentage: number}>(
  ({circleWidth, percentage}) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    clip: ${percentage <= 50
      ? `rect(0, ${circleWidth}, ${circleWidth}, calc(${circleWidth} / 2))`
      : 'clip: rect(auto, auto, auto, auto)'};
  `
)

const ProgressCircleBackground = styled.div<{strokeWidth?: string; backgroundColor?: string}>(
  ({strokeWidth, backgroundColor}) => css`
    width: 100%;
    height: 100%;
    border: ${strokeWidth} solid ${backgroundColor};
    border-radius: 50%;
  `
)

const ProgressCircleLabelContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  align-items: center;
  justify-items: center;
`

const getProgressCircleStyles = ({
  circleWidth,
  strokeWidth,
  color
}: {
  circleWidth?: string
  strokeWidth?: string
  color?: string
}) => css`
  width: 100%;
  height: 100%;
  border: ${strokeWidth} solid ${color};
  border-radius: 50%;
  clip: rect(0, calc(${circleWidth} / 2), ${circleWidth}, 0);
  position: absolute;
  left: 0;
  top: 0;
`

const ProgressCircleLeft = styled.div<{
  circleWidth?: string
  strokeWidth?: string
  color?: string
  percentage: number
}>(
  ({circleWidth, strokeWidth, color, percentage}) => css`
    ${getProgressCircleStyles({circleWidth, strokeWidth, color})}
    transform: rotate(${percentage * 3.6}deg);
  `
)

const ProgressCircleRight = styled.div<{
  circleWidth?: string
  strokeWidth?: string
  color?: string
  percentage: number
}>(
  ({circleWidth, strokeWidth, color, percentage}) => css`
    ${getProgressCircleStyles({circleWidth, strokeWidth, color})}
    ${percentage <= 50 ? 'display: none;' : 'transform: rotate(180deg);'}
  `
)
