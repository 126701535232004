import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import AnchorModal, {TinyModalContent} from '../../../Molecules/Modals/AnchorModal'
import {Baseline} from '../../../Layout/Grid'
import {IconButton} from '../../../Atoms/Buttons'

interface LocationSettingProps {
  isOpen: boolean
  onClose: () => void
  onSiteAdd: () => void
  onLocationAdd: () => void
}
const LocationsMenuModal = ({isOpen, onClose, onSiteAdd, onLocationAdd}: LocationSettingProps) => {
  const {t} = useTranslation('locations')

  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <AnchorModal anchorMode={'right'} isOpen={isOpen} onClose={onClose}>
        <TinyModalContent data-cy="location-menu-modal">
          <Baseline>
            <IconButton
              description={t('locations:actions.addSite', 'Add Site')}
              buttonProps={{onClick: () => onSiteAdd()}}
            />

            <IconButton
              description={t('locations:actions.Add Location', 'Add Location')}
              buttonProps={{
                onClick: () => onLocationAdd()
              }}
            />
          </Baseline>
        </TinyModalContent>
      </AnchorModal>
    </>
  )
}

export default LocationsMenuModal
