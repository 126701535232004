import {OrgState} from './../Components/Views/Users/EditUserAccountSettingsDetails'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigation} from 'react-navi'
import {useAppState} from '../state'
import {User, NotificationType} from '../state/state'
import {AssetTabNavigatorProps} from '../Components/Views/Assets/types'
import {AssetType, AssetData} from '../state/rest'
import {LayoutError} from './utils'
import {v4 as uuid} from 'uuid'
import i from 'i18next'

interface PossibleFormFields {
  id: string
  firstName?: string
  lastName?: string
  email: string
  confirmEmail?: string
  phoneNumber: string
  currentPassword?: string
  newPassword?: string
  confirmNewPassword?: string
  language?: string
}
interface AdditionalPayload {
  orgState: OrgState[]
  selectedBuSites: string[]
  isManager: boolean
}
function level2role(level: string) {
  switch (level) {
    case 'chains':
      return 'CM'
    case 'brands':
      return 'BM'
    case 'locationGroups':
      return 'GM'
    case 'locations':
      return 'LM'
    case 'sites':
      return 'SM'
    default:
      return 'BU'
  }
}
function level2id(level: string) {
  switch (level) {
    case 'chains':
      return 'chainId'
    case 'brands':
      return 'brandId'
    case 'locationGroups':
      return 'locationGroupId'
    case 'locations':
      return 'locationId'
    case 'sites':
      return 'siteId'
    default:
      return 'siteId'
  }
}
export const useUserAccountDetailsForm = ({saved, backAddress}: {saved?: Partial<User>; backAddress: string}) => {
  const {state, actions, effects} = useAppState()

  const [isPasswordEdited, setPasswordEdited] = useState(false)
  const [showUploader, toggleShowUploader] = useState(false)
  const [asset, setAsset] = useState<AssetData | undefined>(saved?.photo)

  const tabContents: AssetTabNavigatorProps[] = [{title: 'Photos', assetType: AssetType.PHOTO}]

  const {navigate} = useNavigation()
  async function goBack() {
    navigate(backAddress)
  }

  const {register, watch, errors, formState, setError} = useForm<Partial<PossibleFormFields>>({
    defaultValues: saved,
    mode: 'onChange'
  })

  const form = watch()
  const create = async (payload: AdditionalPayload, selectedRole: string) => {
    try {
      await effects.userApi.createUser(
        {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          phoneNumber: form.phoneNumber ? form.phoneNumber.replace(/ /g, '') : undefined,
          password: null, // backend will autogenerate the password
          language: form.language,
          userToSite: payload.isManager
            ? payload.orgState.map(s => ({userRole: level2role(s.level), [level2id(s.level)]: s.id}))
            : payload.selectedBuSites.map(siteId => ({userRole: selectedRole, siteId}))
        },
        state.site!
      )
      goBack()
    } catch (e) {
      console.error(e)
      await i.loadNamespaces('users')
      actions.addNotification({
        id: uuid(),
        type: NotificationType.ERROR,
        title: i.t('users:errors.unableToCreateUser', 'Could not create a new user'),
        description: i.t('users:errors.unableToCreateUserDescription', 'Check if the email address is already in use.'),
        visible: true,
        hideAfterDelay: 6000
      })
    }
  }
  const update = async (payload: AdditionalPayload, selectedRole: string) => {
    try {
      await actions.updateAccountSettings({
        id: saved?.id,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phoneNumber: form.phoneNumber ? form.phoneNumber.replace(/ /g, '') : undefined,
        photo: asset,
        language: form.language,
        userToSite: payload.isManager
          ? payload.orgState.map(s => ({userRole: level2role(s.level), [level2id(s.level)]: s.id}))
          : payload.selectedBuSites.map(siteId => ({userRole: selectedRole, siteId}))
      })

      const currentPassword: string | undefined = form.currentPassword
      const newPassword: string | undefined = form.newPassword
      const confirmNewPassword: string | undefined = form.confirmNewPassword
      if (isPasswordEdited) {
        if (isPasswordFieldsFilledValid({currentPassword, newPassword, confirmNewPassword})) {
          try {
            await actions.changePassword({currentPassword, newPassword})
          } catch (err) {
            await i.loadNamespaces('auth')
            const code = err.code || ''
            console.error('changePassword error', err)
            if (code === 'LimitExceededException') {
              // too many attempts, try again after some time
              setError(
                'currentPassword',
                'custom',
                i.t('auth:authenticationError.tooManyAttempts', 'Too many attempts, please try again later')
              )
            } else if (code === 'NotAuthorizedException') {
              // wrong current password
              setError(
                'currentPassword',
                'custom',
                i.t('auth:authenticationError.invalidCurrentPassword', 'Invalid current password')
              )
            } else {
              setError(
                'currentPassword',
                'custom',
                i.t('auth:authenticationError.passwordChangeError', 'Unknown error during password change')
              )
            }
            return
          }
        } else {
          throw new LayoutError('password validation failed')
        }
      }

      goBack()
    } catch (err) {
      console.error(`Error updating user account settings, reason: ${err.message}`, err)
    }
  }
  const cancel = () => {
    goBack()
  }
  const remove = async (id: string) => {
    // TODO error handling
    await effects.userApi.removeUser(id)
    goBack()
  }
  const handleResetPassword = async () => {
    if (saved && saved.id) {
      await actions.resetPassword(saved.id)
    }
  }

  const handleAddAsset = (asset?: AssetData) => {
    if (asset) {
      setAsset(asset)
      toggleShowUploader(!showUploader)
    }
  }

  const onAssetSaved = (asset?: AssetData) => {
    setAsset(asset)
  }

  return {
    handleResetPassword,
    register,
    create,
    update,
    cancel,
    isPasswordEdited,
    setPasswordEdited,
    showUploader,
    toggleShowUploader,
    tabContents,
    handleAddAsset,
    asset,
    form,
    errors,
    remove,
    onAssetSaved,
    hasErrors: !formState.isValid
  }
}

const isPasswordFieldsFilledValid: (passwords: {
  currentPassword: string | undefined
  newPassword: string | undefined
  confirmNewPassword: string | undefined
}) => boolean = (passwords: {
  currentPassword: string | undefined
  newPassword: string | undefined
  confirmNewPassword: string | undefined
}): boolean => {
  return (passwords.currentPassword &&
    passwords.newPassword &&
    passwords.newPassword.length >= 8 &&
    passwords.confirmNewPassword &&
    passwords.newPassword === passwords.confirmNewPassword) as boolean
}
