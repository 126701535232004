import React, {FC, useEffect} from 'react'
import {MainContent, MainLayoutWithoutStretch} from '../../../Layout/Layout'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'
import {GoBackHeaderTitle, HeaderRow} from '../../../Molecules/ViewComponents/ViewHeader'
import {Baseline, Grid, InvisibleContainer} from '../../../Layout/Grid'
import {P, Text} from '../../../Atoms/Typography'
import {BigLabelInput, LabelTextArea, Select} from '../../../Atoms/Forms'
import {useForm} from 'react-hook-form'
import {useAppState} from '../../../../state'
import {Button, ButtonRowGrid, ButtonRowWrap, IconButton} from '../../../Atoms/Buttons'
import {IconDelete} from '../../../../Assets/Icons/TinyIcons'
import {useNavigation} from 'react-navi'
import {InstructionsDocument} from '../../../../state/instructions/state'

const AddFolderFormGrid = ({t, register, errors}: any) => {
  return (
    <Grid gap="2rem">
      <Baseline>
        <BigLabelInput
          required
          placeholder={t('documents:placeholders.folderName', 'Folder name')}
          labelText={t('documents:labels.folderName', 'Folder name')}
          name="name"
          id="name"
          ref={register({
            required: `${t('common:validation.nameRequired', 'Name field is required')}`
          })}
          errorMsg={!!errors.name ? (errors.name as any).message : undefined}
        />
        <LabelTextArea
          labelText={t('documents:labels.description', 'Description')}
          name="description"
          id="description"
          ref={register({required: false})}
        />
      </Baseline>
    </Grid>
  )
}

interface ButtonRowProps {
  isEditing: boolean
  remove: any
  documentCount: number
  t: any
  cancel: any
  hasErrors: boolean
}

const ButtonRow = ({isEditing, remove, documentCount, t, cancel, hasErrors}: ButtonRowProps) => {
  return (
    <ButtonRowWrap>
      {isEditing && (
        <IconButton
          buttonProps={{onClick: event => remove(event), disabled: documentCount > 0}}
          icon={<IconDelete />}
          description={t('documents:actions.deleteFolder', 'Delete folder')}
        />
      )}
      <ButtonRowGrid>
        <Button variant="secondary" negative onClick={() => cancel()}>
          {t('common:buttons.cancel', 'Cancel')}
        </Button>
        <Button variant="secondary" disabled={hasErrors} type="submit">
          {t('common:buttons.save', 'Save')}
        </Button>
      </ButtonRowGrid>
    </ButtonRowWrap>
  )
}

interface EditInstructionsFolderProps {
  id?: string
}

interface FormData {
  name: string
  description: string
}

export const EditInstructionsFolder: FC<EditInstructionsFolderProps> = ({id}) => {
  const {state, actions} = useAppState()
  const {navigate} = useNavigation()
  const {t} = useTranslation('documents')
  const {register, handleSubmit, errors, watch, reset, formState} = useForm<FormData>({
    mode: 'onChange'
  })
  const isEditing = !!id
  const documents: any = state.v1.instructions.documents
  const documentCount = id
    ? documents.filter((document: InstructionsDocument) => {
        return document.documentFolder!.id === id
      }).length
    : 0
  const hasErrors = !formState.isValid
  const cancel = () => {
    navigate('/instructions/folders')
  }
  const remove = async (event: any) => {
    event.preventDefault()
    await actions.v1.instructions.deleteInstructionsFolder(id as string)
    navigate('/instructions/folders')
  }

  const onSubmit = async (data: FormData) => {
    try {
      if (id) {
        await actions.v1.instructions.updateInstructionsFolder({id, ...data})
      } else {
        await actions.v1.instructions.createInstructionsFolder(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      navigate('/instructions/folders')
    }
  }

  useEffect(() => {
    const setup = async () => {
      if (id) {
        const folder = await actions.v1.instructions.getInstructionsFolder(id)
        reset(folder)
      }
    }

    setup()
  }, [reset, actions.v1.instructions, state.v1.instructions, id])

  return (
    <MainLayoutWithoutStretch>
      {isEditing ? (
        <Helmet title={t('common:routes.editInstructionsFolder', 'Edit Instructions folder')} />
      ) : (
        <Helmet title={t('common:routes.createInstructionsFolder', 'Create Instructions folder')} />
      )}
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            path={'/instructions/folders'}
            label={
              isEditing
                ? t('documents:labels.editFolder', 'Edit folder')
                : t('documents:labels.newFolder', 'New folder')
            }
            backLabel={t('documents:instructions.foldertitle', 'Instructions folders')}
          />
        </HeaderRow>

        <InvisibleContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Baseline>
              {!isEditing && (
                <Text level={2}>{t('documents:instructions.labels.addFoldersTitle', 'Add a folder.')}</Text>
              )}
              <AddFolderFormGrid t={t} register={register} errors={errors} />
              {isEditing && documentCount > 0 && (
                <P>{t('documents:messages.folderMustBeEmptyDelete', 'To delete a folder, folder must be empty.')}</P>
              )}
              <ButtonRow
                cancel={cancel}
                documentCount={documentCount}
                hasErrors={hasErrors}
                isEditing={isEditing}
                remove={remove}
                t={t}
              />
            </Baseline>
          </form>
        </InvisibleContainer>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}
