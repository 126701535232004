import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MainLayoutWithoutStretch, MainContent} from '../../Layout/Layout'
import {Baseline, TitleRow, Row, Grid, InvisibleContainer} from '../../Layout/Grid'
import {useNavigation} from 'react-navi'
import {useAppState} from '../../../state'
import {TextButton, Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {IconDelete} from '../../../Assets/Icons/TinyIcons'
import {BigLabelInput, Select} from '../../Atoms/Forms'
import {GoBackHeaderTitle} from '../../Molecules/ViewComponents/ViewHeader'
import IconSelector from '../../Organisms/IconSelect/IconSelector'
import {Helmet} from 'react-navi-helmet-async'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../../../state/state'
import {TaskGroup} from '../../../state/settings/taskGroups/state'
import {UmbrellaCategory} from '../../../state/home/site/state'

interface TaskGroupProps {
  id?: string
}

export default function EditTaskGroup({id}: TaskGroupProps) {
  const nav = useNavigation()
  const {t} = useTranslation('tasks')
  const {state, actions} = useAppState()

  const getTaskGroupOrCreateNew = (id?: string): Omit<TaskGroup, 'id'> => {
    const newGroup = {
      id: undefined,
      name: '',
      icon: 'TIME',
      umbrellaCategory: UmbrellaCategory.OTHER,
      fixed: false,
      removable: true
    }

    if (!id) {
      return newGroup
    }
    const existingGroup = state.v1.settings.taskGroups.data.find(group => group.id === id)
    return existingGroup !== undefined ? existingGroup : newGroup
  }

  const group = getTaskGroupOrCreateNew(id)

  const [localGroup, setGroup] = useState(group)

  const handleIconSelect = (key: string) => {
    setGroup({...localGroup, icon: key})
  }
  const goBack = () => nav.navigate('/edit/taskGroups/')
  const handleSave = () => {
    actions
      .createTaskGroup(localGroup)
      .catch(() => {
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('tasks:msg.createTaskGroupError', 'Could not create task group'),
          description: t('tasks:msg.createTaskGroupErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      })
      .then(goBack)
  }
  const handleUpdate = () => {
    actions
      .updateTaskGroup({
        id: id!,
        name: localGroup.name,
        icon: localGroup.icon,
        umbrellaCategory: localGroup.umbrellaCategory,
        fixed: false
      })
      .catch(() => {
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('tasks:msg.updateTaskGroupError', 'Could not update task group'),
          description: t('tasks:msg.updateTaskGroupErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      })
      .then(goBack)
  }
  const handleDelete = () => {
    actions
      .deleteTaskGroup(id!)
      .catch(() => {
        actions.addNotification({
          id: uuid(),
          type: NotificationType.ERROR,
          title: t('tasks:msg.deleteTaskGroupError', 'Could not delete task group'),
          description: t('tasks:msg.deleteTaskGroupErrorDescription', ''),
          visible: true,
          hideAfterDelay: 6000
        })
      })
      .then(goBack)
  }
  return (
    <MainLayoutWithoutStretch>
      {!!id ? (
        <Helmet title={t('common:routes.editTaskGroup', 'Edit task group')} />
      ) : (
        <Helmet title={t('common:routes.createTaskGroup', 'Create task group')} />
      )}
      <MainContent variant="white">
        <Baseline>
          <TitleRow>
            <GoBackHeaderTitle
              label={
                !!id
                  ? t('tasks:labels.editTaskGroup', 'Edit task group')
                  : t('tasks:labels.newTaskGroup', 'Add task group')
              }
              path={'/edit/taskGroups'}
              backLabel={t('tasks:labels.taskGroupSettings', 'Task groups')}
            />
          </TitleRow>
          <InvisibleContainer data-cy="task-group-form-field">
            <Grid>
              <BigLabelInput
                placeholder={t('tasks:taskGroupName', 'Task group name')}
                name="name"
                id="name"
                labelText={t('tasks:taskGroupName', 'Task group name')}
                value={localGroup.name}
                onChange={e => setGroup({...localGroup, name: e.target.value})}
                disabled={localGroup.fixed}
              ></BigLabelInput>
              <div className="empty"></div>
              <IconSelector onChange={handleIconSelect} iconKey={localGroup.icon} />
              <Select
                returnId
                nativeProps={{
                  name: 'taskGroupUmbrella',
                  onChange(e) {
                    setGroup({...localGroup, umbrellaCategory: e.target.value as UmbrellaCategory})
                  },
                  value: localGroup.umbrellaCategory
                }}
                id="taskGroupUmbrella"
                label={t('tasks:umbrellaCategory.label', 'Task group category')}
                options={[
                  {
                    option: t('tasks:umbrellaCategory.cooking', 'Cooking'),
                    id: UmbrellaCategory.COOKING
                  },
                  {
                    option: t('tasks:umbrellaCategory.cleaning', 'Cleaning'),
                    id: UmbrellaCategory.CLEANING
                  },
                  {
                    option: t('tasks:umbrellaCategory.waste', 'Waste'),
                    id: UmbrellaCategory.WASTE
                  },
                  {
                    option: t('tasks:umbrellaCategory.other', 'Other'),
                    id: UmbrellaCategory.OTHER
                  }
                ]}
              />
            </Grid>
            <ButtonRowWrap>
              {!!id && (
                <TextButton onClick={handleDelete} disabled={!localGroup.removable}>
                  <Row align="center">
                    <IconDelete />
                    {t('tasks:actions.deleteTaskGroup', 'Delete task group')}
                  </Row>
                </TextButton>
              )}
              <ButtonRowGrid>
                <Button negative variant="secondary" onClick={goBack}>
                  {t('common:buttons.cancel', 'Cancel')}
                </Button>
                <Button
                  disabled={!localGroup.name.length || localGroup.fixed}
                  variant="secondary"
                  onClick={() => (id ? handleUpdate() : handleSave())}
                  data-cy="save-taskgroup"
                >
                  {t('common:buttons.save', 'Save')}
                </Button>
              </ButtonRowGrid>
            </ButtonRowWrap>
          </InvisibleContainer>
        </Baseline>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}
