import React from 'react'
import {useTranslation} from 'react-i18next'
import styled, {css} from 'styled-components'

import {Number, Heading} from '../../Atoms/Typography'
import {ProgressBar} from '../../Atoms/ProgressBar'
import {colors} from '../../../sharedComponents/colors'

const TaskInfoContainer = styled.div<ProgressBarProps>`
  text-align: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  display: inline-grid;
  grid-template-rows: 9.5rem 1rem;
  grid-row-gap: 0.5rem;

  ${props =>
    props.variant === 'quick' &&
    css`
      grid-template-rows: 5.5rem 1.5rem;
      grid-row-gap: 0.75;
    `}
`

const ProgressBarContainer = styled.div<ProgressBarProps>`
  position: relative;
  width: 9.5rem;
  height: 9.5rem;
  margin: auto;

  ${props =>
    props.variant === 'quick' &&
    css`
      width: 5.5rem;
      height: 5.5rem;
    `}
`

interface ProgressBarProps {
  variant?: keyof TaskInfoVariants
}
const ProgressBarContent = styled.div<ProgressBarProps>`
  display: inline-grid;
  justify-content: center;
  align-items: center;

  ${props =>
    props.variant === 'default' &&
    css`
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      grid-row-gap: 0.5rem;
      grid-column-gap: 0.25rem;

      & > *:first-child {
        grid-column: 1 / 3;
        grid-row: 1;
      }
    `}

  ${props =>
    props.variant === 'quick' &&
    css`
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
    `}
`

type TaskInfoVariants = {
  default: string
  quick: string
}
interface TasksInfoProps {
  tasks: number
  variant?: keyof TaskInfoVariants
}
const LateTasksInfo = ({tasks, variant}: TasksInfoProps) => {
  const {t} = useTranslation('home')
  const getPercentage = () => {
    if (tasks > 0) {
      return 100
    }
    return 0
  }

  return (
    <TaskInfoContainer variant={variant}>
      <ProgressBarContainer variant={variant}>
        <ProgressBar
          percentage={getPercentage()}
          pathColor={colors.brand.signalOrange}
          trailColor={colors.system.lightGrey_10}
        >
          <ProgressBarContent variant={variant}>
            {variant === 'default' ? <Number level={2}>{tasks}</Number> : null}
            {variant === 'quick' ? <Number level={4}>{tasks}</Number> : null}
          </ProgressBarContent>
        </ProgressBar>
      </ProgressBarContainer>
      {variant === 'default' ? <Heading level={6}>{t('home:labels.lateTasks', 'Late tasks')}</Heading> : null}
      {variant === 'quick' ? <Heading level={5}>{t('home:labels.lateTasks', 'Late tasks')}</Heading> : null}
    </TaskInfoContainer>
  )
}
LateTasksInfo.defaultProps = {
  variant: 'default'
}

export default LateTasksInfo
