import {AsyncAction} from 'overmind'
import {PAGE_LIMIT} from '../../pages/tasks'
import {FilterOptions} from './effects'
import {TaskType} from '../../pages/tasks/index'
import {TaskListItemData} from '../home/site/state'
import {checkInProgressAutomaticCoolingTask} from '../../pages/tasks/utils'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../../state/state'
import i from 'i18next'

export const getTasksForSite: AsyncAction<any, any> = async (
  {state, effects},
  params: {siteId: string; limit?: number; limitLate?: number; onlyRefreshData?: boolean}
) => {
  // Do NOT reload the page if it is just for updating date from websocket message(event, task)
  if (!params.onlyRefreshData) {
    state.v1.tasks.initialLoading = true
  }
  state.v1.tasks.initialLoadingError = null
  try {
    const response = await effects.v1.tasks.tasksApi.getTasksForSite(
      params.siteId,
      params.limit || PAGE_LIMIT,
      params.limitLate || PAGE_LIMIT
    )
    const {completedTasks, todoTasks, filters} = response
    state.v1.tasks = {
      ...state.v1.tasks,
      todoTasks: {
        ...state.v1.tasks.todoTasks,
        data: todoTasks
      },
      completedTasks: {
        ...state.v1.tasks.completedTasks,
        data: completedTasks
      },
      filters: {...filters, userAccounts: filters.completedBy}
    }
  } catch (err) {
    state.v1.tasks.initialLoadingError = err as Error
  } finally {
    state.v1.tasks.initialLoading = false
  }
}

export const getTasksForSiteWithPagination: AsyncAction<any, any> = async (
  {state, effects},
  params: {type: TaskType; siteId: string; options: FilterOptions}
) => {
  const tasksTypeByState = params.options.completed ? 'completedTasks' : 'todoTasks'
  state.v1.tasks[tasksTypeByState].loadingMoreTasks = true
  state.v1.tasks[tasksTypeByState].error = null
  let response
  try {
    //TODO check the below logic for the todo tasks (late and today)
    if (params.type === TaskType.TODAY || params.type === TaskType.LATE) {
      response = await effects.v1.tasks.tasksApi.getMoreLateOrTodayTasksForSite(
        params.siteId,
        params.options,
        params.type
      )
      if (params.type === TaskType.TODAY) {
        state.v1.tasks.todoTasks.data.todaysTasks.count = response.count
        state.v1.tasks.todoTasks.data.todaysTasks.tasks = [
          ...state.v1.tasks.todoTasks.data.todaysTasks.tasks,
          ...response.tasks
        ]
      } else {
        state.v1.tasks.todoTasks.data.lateTasks.count = response.count
        state.v1.tasks.todoTasks.data.lateTasks.tasks = [
          ...state.v1.tasks.todoTasks.data.lateTasks.tasks,
          ...response.tasks
        ]
      }
    } else {
      response = await effects.v1.tasks.tasksApi.getTasksForSiteWithPagination(params.siteId, params.options)
      if (params.options.offset === 0) {
        state.v1.tasks[tasksTypeByState].data = response
      } else {
        if (tasksTypeByState === 'completedTasks') {
          state.v1.tasks[tasksTypeByState].data.tasks = [...state.v1.tasks.completedTasks.data.tasks, ...response.tasks]
        } else {
          state.v1.tasks[tasksTypeByState].data.lateTasks.tasks = [
            ...state.v1.tasks.todoTasks.data.lateTasks.tasks,
            ...response.lateTasks.tasks
          ]
          state.v1.tasks[tasksTypeByState].data.todaysTasks.tasks = [
            ...state.v1.tasks.todoTasks.data.todaysTasks.tasks,
            ...response.todaysTasks.tasks
          ]
        }
      }
    }
  } catch (err) {
    state.v1.tasks[tasksTypeByState].error = err as Error
  } finally {
    state.v1.tasks[tasksTypeByState].loadingMoreTasks = false
  }
}

export const updateInProgressAutomaticCoolingTasksForSite: AsyncAction<any, any> = async (
  {state, effects},
  params: {siteId: string}
) => {
  try {
    const inProgressTasks = state.v1.tasks.todoTasks.data.inProgressTasks.tasks
    const inProgressAutomaticCoolingTasksExist = inProgressTasks.filter(checkInProgressAutomaticCoolingTask).length > 0
    if (!inProgressAutomaticCoolingTasksExist) {
      return
    }
    const response = await effects.v1.tasks.tasksApi.getInProgressTasksForSite(params.siteId, 0, 50, [
      'automaticCooling'
    ])
    const inProgressAutomaticCoolingTasks: TaskListItemData[] = response.tasks
    const inProgressAutomaticCoolingTasksMap = Object.assign(
      {},
      ...inProgressAutomaticCoolingTasks.map(task => ({[task.eventId!]: task}))
    ) as {[eventId: string]: TaskListItemData}
    state.v1.tasks.todoTasks.data.inProgressTasks.tasks = inProgressTasks.map(task => {
      const inProgressAutomaticCoolingTask = inProgressAutomaticCoolingTasksMap[task.eventId!]
      return inProgressAutomaticCoolingTask ?? task
    })
  } catch (err) {}
}

export const deleteTaskEvent: AsyncAction<any, void> = async (
  {actions, state, effects},
  params: {taskId: string; eventId: string}
) => {
  try {
    await effects.v1.tasks.tasksApi.deleteTaskEvent(params.taskId, params.eventId, state.site!.id)
    await actions.v1.tasks.getTasksForSite({siteId: state.site!.id, onlyRefreshData: true})
  } catch (err) {
    await i.loadNamespaces('msg')
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('msg:deleteEventError', 'Unable to delete task'),
      description: i.t('msg:deleteEventErrorDescription', 'Failed to delete task'),
      visible: true,
      hideAfterDelay: 6000
    })
  }
}
