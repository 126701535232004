import React, {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigation} from 'react-navi'
import {Helmet} from 'react-navi-helmet-async'
import {DocumentFolderType} from '../../../../state/documents/state'
import {useForm} from 'react-hook-form'

import {IconDelete} from '../../../../Assets/Icons/TinyIcons'
import {Button, IconButton, ButtonRowWrap, ButtonRowGrid} from '../../../Atoms/Buttons'
import {BigLabelInput, LabelTextArea, Select} from '../../../Atoms/Forms'
import {P, Text} from '../../../Atoms/Typography'
import {Baseline, InvisibleContainer, Grid} from '../../../Layout/Grid'
import {MainLayoutWithoutStretch, MainContent} from '../../../Layout/Layout'
import {GoBackHeaderTitle, HeaderRow} from '../../../Molecules/ViewComponents/ViewHeader'
import {useAppState} from '../../../../state'

interface EditDocumentFolderProps {
  id?: string
}

interface FormData {
  type: DocumentFolderType
  name: string
  description: string
  url?: string
}

const EditDocumentFolder: FC<EditDocumentFolderProps> = ({id}): any => {
  const {state, actions} = useAppState()
  const {t} = useTranslation('documents')
  const {navigate} = useNavigation()
  const {register, handleSubmit, errors, watch, reset, formState} = useForm<FormData>({
    mode: 'onChange'
  })

  // Create a new folder if id is not provided
  const isEditing = !!id

  // TODO return document count of a folder in API call
  const documents = state.v1.documents.documents
  const documentCount = id
    ? documents.filter(document => {
        return document.documentFolder!.id === id
      }).length
    : 0

  // Watch all variables in the form
  const folderType = watch('type')

  const typeChangeForbidden = isEditing && folderType === DocumentFolderType.Internal && documentCount > 0
  const hasErrors = !formState.isValid

  const goBack = () => {
    navigate('/documents/folders')
  }

  const remove = async (event: any) => {
    event.preventDefault()
    await actions.v1.documents.deleteDocumentFolder(id as string)
    goBack()
  }

  const onSubmit = async (data: FormData) => {
    const submittedData = {
      chain: {id: state.selectedChainId},
      ...data
    }
    try {
      if (id) {
        // Update existing folder
        await actions.v1.documents.updateDocumentFolder({
          id,
          ...submittedData
        })
      } else {
        // Create new folder
        await actions.v1.documents.createDocumentFolder(submittedData)
      }
    } catch (err) {
    } finally {
      goBack()
    }
  }

  const cancel = () => {
    goBack()
  }

  useEffect(() => {
    const setup = async () => {
      if (id) {
        await actions.v1.documents.getDocuments()
        const folder = (await actions.v1.documents.getDocumentFolder(id)) as any
        reset(folder) // asynchronously reset form values
      }
    }
    setup()
  }, [reset, actions.v1.documents, state.v1.documents, id])

  return (
    <MainLayoutWithoutStretch>
      {isEditing ? (
        <Helmet title={t('common:routes.editDocumentFolder', 'Edit document folder')} />
      ) : (
        <Helmet title={t('common:routes.createDocumentFolder', 'Create document folder')} />
      )}
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            path={'/documents/folders'}
            label={
              isEditing
                ? t('documents:labels.editFolder', 'Edit folder')
                : t('documents:labels.newFolder', 'New folder')
            }
            backLabel={t('documents:documentFolders', 'Document folders')}
          />
        </HeaderRow>
        <InvisibleContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Baseline>
              {isEditing ? (
                undefined
              ) : (
                <Text level={2}>{t('documents:labels.addFoldersTitle', 'Add a folder or a link to a folder.')}</Text>
              )}
              <Grid gap="2rem">
                <Baseline>
                  <Select
                    required
                    returnId={true}
                    label={t('documents:labels.folderType', 'Folder type')}
                    id="type"
                    options={[
                      {
                        id: DocumentFolderType.Internal,
                        option: t('documents:documentFolderTypes.internalDocumentFolder', 'Internal document folder')
                      },
                      {
                        id: DocumentFolderType.External,
                        option: t('documents:documentFolderTypes.linkedDocumentFolder', 'Linked document folder')
                      }
                    ]}
                    emptyStr={t('documents:labels.selectFolderType', 'Select folder type')}
                    ref={register({required: true})}
                    nativeProps={{name: 'type', disabled: typeChangeForbidden}}
                  />
                  {typeChangeForbidden && (
                    <P>
                      {t(
                        'documents:messages.folderMustBeEmptyEdit',
                        `To change the folder type, the folder must be empty.`
                      )}
                    </P>
                  )}
                  <BigLabelInput
                    required
                    placeholder={t('documents:placeholders.folderName', 'Folder name')}
                    labelText={t('documents:labels.folderName', 'Folder name')}
                    name="name"
                    id="name"
                    ref={register({
                      required: `${t('common:validation.nameRequired', 'Name field is required')}`
                    })}
                    errorMsg={!!errors.name ? (errors.name as any).message : undefined}
                  />
                  {folderType === DocumentFolderType.External ? (
                    <LabelTextArea
                      required
                      placeholder={t('documents:placeholders.folderLink', 'Folder link')}
                      labelText={t('documents:labels.link', 'Link')}
                      name="url"
                      id="url"
                      ref={register({
                        required: `${t('common:validation.linkFieldRequired', 'Link field is required')}`
                      })}
                      errorMsg={!!errors.url ? (errors.url as any).message : undefined}
                    />
                  ) : null}
                  <LabelTextArea
                    labelText={t('documents:labels.description', 'Description')}
                    name="description"
                    id="description"
                    ref={register({required: false})}
                  />
                </Baseline>
                <Baseline>{/* this side is left intentionally blank */}</Baseline>
              </Grid>
              {isEditing && documentCount > 0 && (
                <P>{t('documents:messages.folderMustBeEmptyDelete', 'To delete a folder, folder must be empty.')}</P>
              )}
              <ButtonRowWrap>
                {isEditing && (
                  <IconButton
                    buttonProps={{onClick: event => remove(event), disabled: documentCount > 0}}
                    icon={<IconDelete />}
                    description={t('documents:actions.deleteFolder', 'Delete folder')}
                  />
                )}
                <ButtonRowGrid>
                  <Button variant="secondary" negative onClick={() => cancel()}>
                    {t('common:buttons.cancel', 'Cancel')}
                  </Button>
                  <Button variant="secondary" disabled={hasErrors} type="submit">
                    {t('common:buttons.save', 'Save')}
                  </Button>
                </ButtonRowGrid>
              </ButtonRowWrap>
            </Baseline>
          </form>
        </InvisibleContainer>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

export default EditDocumentFolder
