import React, {useState, Fragment, forwardRef, Ref} from 'react'
import {useTranslation} from 'react-i18next'
import {IconLink} from '../../../Assets/Icons/TinyIcons'
import {Baseline} from '../../Layout/Grid'
import {IconText} from '../../Atoms/IconText'
import {LabelInput} from '../../Atoms/Forms'

const TaskLinks = forwardRef(
  ({hasLinks, hasErrors}: {hasLinks?: boolean; hasErrors?: boolean}, ref: Ref<HTMLInputElement>) => {
    const [addingLink, setAddingLink] = useState(!!hasLinks)
    const {t} = useTranslation('tasks')
    return (
      <Baseline>
        <IconText
          icon={<IconLink />}
          text={
            addingLink
              ? t('tasks:actions.removeLink', 'Remove link')
              : t('tasks:actions.addLink', 'Add a link')
          }
          onClick={() => setAddingLink(!addingLink)}
        />
        {addingLink ? (
          <Fragment>
            <LabelInput
              name="linkDescription"
              id="linkDescription"
              labelText={t('tasks:labels.linkDescription', 'Link description')}
              ref={ref}
              errorMsg={hasErrors ? t('common:validation.invalidValue', 'Invalid or missing value') : undefined}
            ></LabelInput>
            <LabelInput
              name="linkUrl"
              id="linkUrl"
              labelText={t('tasks:labels.linkAddress', 'Link address')}
              ref={ref}
              errorMsg={hasErrors ? t('common:validation.invalidValue', 'Invalid or missing value') : undefined}
            ></LabelInput>
          </Fragment>
        ) : null}
      </Baseline>
    )
  }
)

export default TaskLinks
