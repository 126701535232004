import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconConfirmation = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 44.625C35.3911 44.625 44.625 35.3911 44.625 24C44.625 12.6089 35.3911 3.375 24 3.375C12.6089 3.375 3.375 12.6089 3.375 24C3.375 35.3911 12.6089 44.625 24 44.625ZM24 46C36.1505 46 46 36.1505 46 24C46 11.8495 36.1505 2 24 2C11.8495 2 2 11.8495 2 24C2 36.1505 11.8495 46 24 46Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 14.9874L19.6718 34L10 23.9663L11.0217 22.979L19.6718 31.9528L36.9784 14L38 14.9874Z"
      />
    </svg>
  )
}
