import {baseApi} from '../rest'

import {Document, DocumentFolder} from './state'

export interface DocumentResponse {
  items: Document[]
}
export interface DocumentFolderResponse {
  items: DocumentFolder[]
}

export const documentsApi = {
  ...baseApi,
  async getDocuments() {
    const path = '/documents'
    const req = await this.doRequest<DocumentResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async getDocument(id: string) {
    const req = await this.doRequest<Document>({path: `/documents/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async createDocument(payload: Partial<Document>) {
    const req = await this.doRequest({path: '/documents', payload})
    const resp = await req.post()
    return resp
  },
  async deleteDocument(id: string) {
    const req = await this.doRequest({path: '/documents/' + id})
    const resp = await req.delete()
    return resp
  },
  async updateDocument(payload: Partial<Document>) {
    const req = await this.doRequest({path: '/documents/' + payload.id, payload})
    const resp = await req.put()
    return resp
  },
  async getDocumentFolders() {
    const path = '/documents/folders'
    const req = await this.doRequest<DocumentFolderResponse>({path})
    const resp = await req.get()
    return resp.data
  },
  async getDocumentFolder(id: string) {
    const req = await this.doRequest<DocumentFolder>({path: `/documents/folders/${id}`})
    const resp = await req.get()
    return resp.data
  },
  async createDocumentFolder(payload: Partial<DocumentFolder>) {
    const req = await this.doRequest({path: '/documents/folders', payload})
    const resp = await req.post()
    return resp
  },
  async deleteDocumentFolder(id: string) {
    const req = await this.doRequest({path: '/documents/folders/' + id})
    const resp = await req.delete()
    return resp
  },
  async updateDocumentFolder(payload: DocumentFolder) {
    const req = await this.doRequest({path: '/documents/folders/' + payload.id, payload})
    const resp = await req.put()
    return resp
  }
}
