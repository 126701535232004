import React, {ReactNode} from 'react'
import {ReactNodeArray} from 'prop-types'
import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'

const BadgeContainer = styled.div<BadgeProps>`
  display: inline-block;
  font-size: ${props => props.fontSize};
  line-height: 1em;
  font-weight: normal;
  /* min-width: 2.5em;
  min-height: 2.5em; */
  align-items: center;
  justify-content: center;
`
const BadgeComponent = styled.div<ThemeProps>`
  padding: 0.6em;
  border-radius: 50%;
  text-align: center;
  min-width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: ${props => badgeVariants[props.variant].bgColor};
  color: ${props => badgeVariants[props.variant].color};
`

const BadgeContent = styled.div`
  margin: 0;
`

type Theme = {
  bgColor: string
  color: string
}
type BadgeVariants = {
  default: Theme
  reverse: Theme
  main: Theme
  message: Theme
  warn: Theme
}
const badgeVariants: BadgeVariants = {
  default: {
    bgColor: `${colors.brand.cobalt}`,
    color: `${colors.system.white}`
  },
  reverse: {
    bgColor: `${colors.system.white}`,
    color: `${colors.brand.cobalt}`
  },
  main: {
    bgColor: `${colors.brand.cobalt}`,
    color: `${colors.system.white}`
  },
  message: {
    bgColor: `${colors.brand.signalOrange}`,
    color: `${colors.system.white}`
  },
  warn: {
    bgColor: `${colors.system.red}`,
    color: `${colors.system.white}`
  }
}

interface BadgeProps {
  children: ReactNode | ReactNodeArray
  fontSize?: string
  variant?: keyof BadgeVariants
}
interface ThemeProps {
  variant: keyof BadgeVariants
}

export const CircularBadge = ({children, fontSize, variant}: BadgeProps) => {
  return (
    <BadgeContainer fontSize={fontSize}>
      <BadgeComponent variant={variant || 'default'}>
        <BadgeContent>{children}</BadgeContent>
      </BadgeComponent>
    </BadgeContainer>
  )
}
CircularBadge.defaultProps = {
  fontSize: '0.75rem' /* 12px */,
  variant: 'default'
}
