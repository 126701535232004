import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'
import {Text, TextProps} from '../../atoms/Text'
import {Progress, ProgressType} from '../../atoms/Progress'
import {Tag} from '../../atoms/Tag'
import {ApplianceDataValueAndUnit} from './ApplianceDataValueAndUnit'
import {ApplianceDataProps, ApplianceDataContainer, getValueText} from './ApplianceData'

export enum ApplianceDataCycleProgressType {
  small = 'small',
  medium = 'medium'
}

export interface ApplianceDataCycleProgressProps
  extends Pick<ApplianceDataProps, 'value' | 'unit'>,
    React.HTMLAttributes<HTMLDivElement> {
  applianceDataCycleProgressType?: ApplianceDataCycleProgressType
  progressValue: number
  passedTime: number
  coolingTimeExceeded: boolean
  maxTimeSecs: number
  targetValueLabelText: string
  targetValue: number | undefined
  hasAlarm?: boolean
  compact?: boolean
}

export const ApplianceDataCycleProgress: React.FC<ApplianceDataCycleProgressProps> = ({
  value,
  unit,
  targetValue,
  targetValueLabelText,
  applianceDataCycleProgressType,
  progressValue,
  passedTime,
  coolingTimeExceeded,
  maxTimeSecs,
  hasAlarm,
  compact,
  ...rest
}) => {
  const isSmall = applianceDataCycleProgressType === ApplianceDataCycleProgressType.small
  const textSize = isSmall ? 'M' : compact ? 'L' : 'XL'
  const valueTextLevel = isSmall ? 6 : compact ? 5 : 4
  const circleColor = hasAlarm ? colors.system.red : colors.system.blue
  const timeTextProps: TextProps = isSmall
    ? {size: textSize, color: colors.brand.cobalt, light: true}
    : {size: textSize, color: circleColor, strong: true}
  const circleWidth = `${isSmall ? 5.125 : compact ? 5 : 7.5}rem`
  const borderColor = isSmall ? colors.brand.cobalt : colors.system.grey_5
  const textTime = `${coolingTimeExceeded ? '+' : ''}${passedTime < 3600 ? sToMMSS(passedTime) : sToHMMSS(passedTime)}`
  const content = (
    <ApplianceDataCycleProgressTimeAndValueContainer compact={compact}>
      <Text {...timeTextProps}>{textTime}</Text>
      <ApplianceDataCycleProgressTimeAndValueDivider backgroundColor={borderColor} />
      <ApplianceDataValueAndUnit
        value={value}
        unit={unit}
        valueTextLevel={valueTextLevel}
        unitTextSize={isSmall ? 'XS' : 'M'}
      />
    </ApplianceDataCycleProgressTimeAndValueContainer>
  )

  return (
    <ApplianceDataContainer {...rest}>
      <Progress
        progressType={ProgressType.circular}
        label={content}
        value={progressValue}
        min={0}
        max={maxTimeSecs}
        color={circleColor}
        backgroundColor={colors.system.lightGrey_10}
        strokeWidth={`${isSmall ? 0.25 : 0.125}rem`}
        circleWidth={circleWidth}
      />
      {targetValue !== undefined && (
        <StyledTag>
          <Text size={compact ? 'XS' : 'S'}>
            {targetValueLabelText} {getValueText(targetValue, unit)}
          </Text>
        </StyledTag>
      )}
    </ApplianceDataContainer>
  )
}

ApplianceDataCycleProgress.defaultProps = {
  applianceDataCycleProgressType: ApplianceDataCycleProgressType.small
}

ApplianceDataCycleProgress.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  applianceDataCycleProgressType: PropTypes.oneOf<ApplianceDataCycleProgressType>([
    ApplianceDataCycleProgressType.small,
    ApplianceDataCycleProgressType.medium
  ]),
  progressValue: PropTypes.number.isRequired,
  passedTime: PropTypes.number.isRequired,
  maxTimeSecs: PropTypes.number.isRequired
}

const ApplianceDataCycleProgressTimeAndValueContainer = styled.div<{compact?: boolean}>(
  ({compact}) => css`
    display: grid;
    grid-auto-flow: row;
    gap: ${compact ? 0.125 : 0.25}rem;
    justify-items: center;
  `
)

const ApplianceDataCycleProgressTimeAndValueDivider = styled.div<{backgroundColor?: string}>`
  width: 100%;
  height: 1px;
  background-color: ${props => props.backgroundColor || colors.system.grey_5};
`

const StyledTag = styled(Tag)`
  background: ${colors.system.lightGrey_5};
  border: none;
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.5rem;
`

const sToMMSS = (seconds: number) => {
  const m = Math.floor(seconds / 60)
  const s = seconds % 60
  const ss = s < 10 ? '0' + s : s
  const mm = m < 10 ? '0' + m : m
  return `${mm}:${ss}`
}

const sToHMMSS = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
  seconds %= 3600
  const m = Math.floor(seconds / 60)
  const s = seconds % 60
  const ss = s < 10 ? '0' + s : s
  const mm = m < 10 ? '0' + m : m
  return `${h}:${mm}:${ss}`
}
