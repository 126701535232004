import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconAddItem = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0005 3.26C12.5456 3.26 3.26 12.5456 3.26 24.0005C3.26 35.4554 12.5456 44.741 24.0005 44.741C35.4554 44.741 44.741 35.4554 44.741 24.0005C44.741 12.5456 35.4554 3.26 24.0005 3.26ZM2 24.0005C2 11.8498 11.8498 2 24.0005 2C36.1512 2 46.001 11.8498 46.001 24.0005C46.001 36.1512 36.1512 46.001 24.0005 46.001C11.8498 46.001 2 36.1512 2 24.0005ZM23.3699 23.3699V11.4291H24.6299V23.3699H36.5707V24.6299H24.6299V36.5709H23.3699V24.6299H11.429V23.3699H23.3699Z"
      />
    </svg>
  )
}
