import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconProfile = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5537 11.6797C15.5537 7.03099 19.3339 3.26 24.0007 3.26C28.6689 3.26 32.449 7.03105 32.449 11.6797C32.449 16.327 28.6689 20.0981 24.0007 20.0981C19.3338 20.0981 15.5537 16.3271 15.5537 11.6797ZM24.0007 2C18.6416 2 14.2937 6.33149 14.2937 11.6797C14.2937 17.0267 18.6417 21.3581 24.0007 21.3581C29.3609 21.3581 33.709 17.0267 33.709 11.6797C33.709 6.33143 29.361 2 24.0007 2ZM23.9995 24.0029C12.0451 24.0029 2.33671 33.5095 2.0179 45.3533L2.00049 46.0002H2.64767H45.3526H45.9998L45.9824 45.3533C45.6635 33.5095 35.9552 24.0029 23.9995 24.0029ZM44.6954 44.7402H3.30487C3.93365 33.8812 12.9467 25.2629 23.9995 25.2629C35.0536 25.2629 44.0666 33.8813 44.6954 44.7402Z"
      />
    </svg>
  )
}
