import {IFileWithMeta, IUploadParams, StatusValue} from 'react-dropzone-uploader'

import {AssetData, AssetType} from '../../../../state/rest'

export enum AssetCategory {
  task = 'TASK',
  account = 'ACCOUNT',
  document = 'DOCUMENT',
  event = 'EVENT'
}

export type UploadedAsset = {
  name: string
  path: string
  mimeType: string
  size: number
  instructions?: boolean
}

export type AssetTabNavigatorProps = {
  title: string
  assetType: AssetType
}

export interface AssetWidgetProps {
  handleRemove: (id: string) => void
  handleAdd: (asset: AssetData) => void
  savedAssets: AssetData[]
}

export interface AssetUploaderProps {
  acceptedTypes: string
  multiple: boolean
  maxFiles: number
  handleAddAsset: (props: UploadedAsset) => Promise<AssetData>
  onAssetSaved: (asset: AssetData) => void
  instructions?: boolean
}

export interface AssetUploaderHookParams {
  addAsset: (props: UploadedAsset) => Promise<AssetData>
  onAssetSaved: (asset: AssetData) => void
  instructions?: boolean
}

export interface AddAssetHookParams {
  addAsset: (props: UploadedAsset) => Promise<AssetData>
}

export interface AssetUploaderHookReturnProps {
  getUploadParams: ({meta, file}: IFileWithMeta) => Promise<IUploadParams>
  onChangeStatus: (fileWithMeta: IFileWithMeta, status: StatusValue, filesWithMeta: IFileWithMeta[]) => void
}

export interface AddAssetProps {
  title: string
  label: string
  acceptedTypes: string
  tabContents: AssetTabNavigatorProps[]
  handleClose: (close: boolean) => void
  handleAddSelected: (asset?: AssetData) => void
  assetCategory?: AssetCategory
  instructions?: boolean
}

export interface AssetsListProps {
  assets: AssetData[]
  assetType: AssetType
  onClick?: (asset: AssetData) => void
  selected?: AssetData
}
