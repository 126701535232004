import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {Text, Icon, Title} from '../../../sharedComponents/components'
import {colors} from '../../../sharedComponents/colors'

import {SmartAsset} from '../../../Components/Atoms/ImageLoader'
import {IconLink} from '../../../Assets/Icons/TinyIcons'
import {IconText} from '../../../Components/Atoms/IconText'
import {CompletedTask} from '../../../state/home/site/state'

function parseLink(url: string) {
  const reg = RegExp('^http|https')
  return reg.test(url) ? url : `https://${url}`
}

export const showTaskDetails = (data: CompletedTask) => {
  return !!(
    (data.assets && data.assets.length) ||
    (data.description && data.description.length) ||
    (data.instructions && data.instructions.length) ||
    (data.links && data.links.length)
  )
}
interface TaskInstructionProps {
  data: CompletedTask
}
const TaskInstruction = ({data}: TaskInstructionProps) => {
  const {t} = useTranslation('tasks')
  return showTaskDetails(data) ? (
    <>
      {data.assets ? data.assets.map(a => <SmartAsset key={a.id} asset={a} />) : null}
      <>
        {data.instructions && (
          <div>
            <Title level={6} strong style={{margin: 'auto'}}>
              {t('tasks:labels.howToDo', 'How to do')}
            </Title>
            <Text data-cy="modal-task-instructions" size="M" color={colors.system.grey_50}>
              {data.instructions}
            </Text>
          </div>
        )}
        {data.description && (
          <div>
            <Title level={6} strong style={{margin: 'auto'}}>
              {t('tasks:labels.whatYouNeed', 'What you need')}
            </Title>
            <Text data-cy="modal-task-description" size="M" color={colors.system.grey_50}>
              {data.description}
            </Text>
          </div>
        )}

        {!!data.links && data.links.length > 0 && (
          <IconText
            icon={<IconLink />}
            text={
              <a
                style={{color: colors.brand.cobalt}}
                href={parseLink(data.links[0].url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.links[0].description}
              </a>
            }
          />
        )}
      </>
    </>
  ) : (
    <NoInstructionsPlaceholder>
      <Icon type="documents" width="3rem" height="5rem" fill={colors.system.grey_1} />
      <Text size="M" strong>
        {t('tasks:instructions.unavailable.title', 'There is no instruction available for this task.')}
      </Text>
      <Text size="M">
        {t('tasks:instructions.unavailable.suggestion', 'Contact your manager for more information.')}
      </Text>
    </NoInstructionsPlaceholder>
  )
}

const NoInstructionsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`

export default TaskInstruction
