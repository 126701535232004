import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconScale = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.7429 3.25714H3.25714V44.7429H44.7429V3.25714ZM2 2V46H46V2H2Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3332 13.7641C9.33368 13.7634 9.33525 13.7607 9.33763 13.7564C9.36644 13.7048 9.53679 13.3999 9.90916 12.9563C9.98547 12.8653 10.0702 12.7686 10.164 12.667C11.682 11.023 15.5584 8.11108 23.9998 8.11108C35.7731 8.11108 38.6665 13.7641 38.6665 13.7641L30.8842 20.3333H17.1155L9.3332 13.7641ZM11.219 13.5575C11.605 13.142 12.1873 12.6125 13.0233 12.0737C14.9634 10.8234 18.3546 9.46911 23.9998 9.46911C29.6457 9.46911 33.037 10.8209 34.9769 12.0699C35.8148 12.6093 36.3976 13.1396 36.7833 13.5554L30.3624 18.9753H17.6373L11.219 13.5575Z"
      />
      <path d="M24.0001 14.222L26.1171 19.722H21.8832L24.0001 14.222Z" />
    </svg>
  )
}
