import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconCleaning = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8585 44.7349H29.2916L27.4241 23.7612L26.1501 23.8725L28.1187 46H43.0302L45 23.8725L43.726 23.7612L41.8585 44.7349Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.112 16.034C38.0542 16.034 37.1934 16.8854 37.1934 17.9316C37.1934 18.9778 38.0542 19.8292 39.112 19.8292C40.1698 19.8292 41.0306 18.9778 41.0306 17.9316C41.0306 16.8854 40.1698 16.034 39.112 16.034ZM39.112 21.0943C37.3482 21.0943 35.9143 19.6749 35.9143 17.9316C35.9143 16.1883 37.3482 14.7689 39.112 14.7689C40.8759 14.7689 42.3097 16.1883 42.3097 17.9316C42.3097 19.6749 40.8759 21.0943 39.112 21.0943Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0383 11.773C30.9805 11.773 30.1197 12.6243 30.1197 13.6706C30.1197 14.7168 30.9805 15.5682 32.0383 15.5682C33.0961 15.5682 33.9569 14.7168 33.9569 13.6706C33.9569 12.6243 33.0961 11.773 32.0383 11.773ZM32.0383 16.8333C30.2744 16.8333 28.8406 15.4138 28.8406 13.6706C28.8406 11.9273 30.2744 10.5079 32.0383 10.5079C33.8021 10.5079 35.236 11.9273 35.236 13.6706C35.236 15.4138 33.8021 16.8333 32.0383 16.8333Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5936 24.816H37.3146C37.3146 23.7698 36.4537 22.9184 35.3959 22.9184C34.3381 22.9184 33.4773 23.7698 33.4773 24.816H32.1982C32.1982 23.0727 33.6321 21.6533 35.3959 21.6533C37.1598 21.6533 38.5936 23.0727 38.5936 24.816Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0483 35.7927L20.8127 35.4663C21.74 32.043 19.6781 28.5122 16.2182 27.5962C14.5426 27.1471 12.7903 27.3786 11.2873 28.2364C9.78443 29.0954 8.71 30.4806 8.25976 32.1391L7.02417 31.8115C8.13441 27.7139 12.404 25.2761 16.5495 26.3729C20.6911 27.471 23.1585 31.6976 22.0483 35.7927Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.68711 44.7348H18.1392L20.6372 36.0741L8.08818 32.7482L4.68711 44.7348ZM19.1036 45.9999H3L7.19666 31.2023L22.2233 35.1847L19.1036 45.9999Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2685 25.8846L18.0329 25.5557L23.8182 4.20376L21.347 3.54845L15.5618 24.9029L14.3262 24.574L20.4427 2L25.3838 3.30935L19.2685 25.8846Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4268 45.5404L14.1963 45.195L17.2456 34.5721L18.4761 34.9188L15.4268 45.5404Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7857 45.5389L10.5552 45.1961L13.8475 33.6725L15.078 34.0166L11.7857 45.5389Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17707 45.4205L6.94531 45.0776L10.45 32.7722L11.6817 33.1151L8.17707 45.4205Z"
      />
    </svg>
  )
}
