import {baseApi} from '../../rest'
import {SiteHome} from './state'

export const siteHomeApi = {
  ...baseApi,
  async getSiteHome(siteId: string): Promise<SiteHome> {
    const request = await this.doRequest<SiteHome>({
      path: `/v1/home/site/${siteId}`
    })
    const response = await request.get()
    return (response as unknown) as SiteHome
  },
  async getInitialHomeData(siteId: string): Promise<any> {
    const pageLimit = {
      lateTasks: 3,
      todaysTasks: 150
    }
    if (!siteId) {
      console.error('Could not excecute Initial Home Data request because no siteId was provided')
      return
    } else {
      const request = await this.doRequest({
        path: `/v2/home/site/${siteId}?offset=0&limit=${pageLimit.todaysTasks}&offsetLate=0&limitLate=${pageLimit.lateTasks}`
      })
      const response = await request.get()
      return (response as unknown) as any
    }
  }
}
