import styled from 'styled-components'
import React, {ReactNode, ButtonHTMLAttributes, InputHTMLAttributes} from 'react'
import {ReactNodeArray} from 'prop-types'
import {colors} from '../../sharedComponents/colors'
import tiny from 'tinycolor2'
import {IconArrowRight} from '../../Assets/Icons/TinyIcons'
import {H5, Text} from './Typography'
import {Link} from 'react-navi'
import {LinkProps} from 'react-navi/dist/types/Link'

interface BaseProps {
  variant: keyof ButtonVariants
}
function negative(color: string) {
  return `
    border: 1px solid ${color};
    background-color: transparent;
    color: black;
  `
}

const IconButtonWrap = styled.button`
  border: none;
  background-color: transparent;
  display: flex;

  justify-content: center;
  align-content: center;
  align-items: center;
  justify-self: flex-start;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  .description {
    margin-left: 0.5rem;
    overflow: hidden;
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    cursor: not-allowed;
    transform: translateY(0);
  }
`
interface IconButtonProps {
  icon?: ReactNode
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>
  description?: ReactNode
}
export const IconButton = ({icon, description, buttonProps}: IconButtonProps) => {
  return (
    <IconButtonWrap {...buttonProps}>
      {icon}
      {!!description && (
        <div className="description" data-cy="icon-button">
          {description}
        </div>
      )}
    </IconButtonWrap>
  )
}

const VerticalIconButtonWrap = styled.button`
  border: none;
  background-color: transparent;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.25rem;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  > *:last-child {
    margin: 0 auto;
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    cursor: not-allowed;
    transform: translateY(0);
  }
`
interface IconButtonProps {
  icon?: ReactNode
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>
  description?: ReactNode
}
export const VerticalIconButton = ({icon, description, buttonProps}: IconButtonProps) => {
  return (
    <VerticalIconButtonWrap {...buttonProps}>
      {icon}
      {!!description && (
        <Text level={3} color={colors.brand.cobalt}>
          {description}
        </Text>
      )}
    </VerticalIconButtonWrap>
  )
}

export const BaseButton = styled.button`
  background-color: ${(props: BaseProps) => buttonVariants[props.variant]};
  border: solid 1px ${(props: BaseProps) => buttonVariants[props.variant]};
  border-radius: 24px;
  padding: 0.8rem 3rem;
  font-family: FavoritMonoStd-Regular, monospace;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.86px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }
  &.negative {
    background-color: transparent;
    border: solid 1px ${colors.brand.cobalt};
    color: ${props => (props.variant === 'tertiary' ? colors.system.white : colors.brand.cobalt)};
  }
  &:not(.negative) {
    color: ${props => (props.variant === 'tertiary' ? colors.brand.cobalt : colors.system.white)};
  }
  &:disabled {
    background: ${colors.system.grey_3};
    color: ${colors.system.lightGrey_5};
    border: solid 1px ${colors.system.grey_3};
    cursor: default;
  }
`
export const BaseButtonInput = styled.input`
  background-color: ${(props: BaseProps) => buttonVariants[props.variant]};
  color: ${colors.system.white};
  border-radius: 24px;
  padding: 1rem 3rem;
  border: 1px solid ${props => buttonVariants[props.variant]};
  font-family: FavoritMonoStd-Regular, monospace;
  font-size: 0.75rem;
  line-height: 0.8rem;
  letter-spacing: 0.86px;
  text-transform: uppercase;
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }
  &.negative {
    border: solid 1px ${props => negative(buttonVariants[props.variant])};
  }
  &:disabled {
    background: ${colors.system.grey_3};
    color: ${colors.system.lightGrey_5};
    cursor: default;
  }
`

type ButtonVariants = {
  default: string
  secondary: string
  tertiary: string
}
const buttonVariants: ButtonVariants = {
  default: colors.brand.cobalt,
  secondary: colors.brand.cobalt,
  tertiary: colors.system.white
}

interface ButtonProps {
  variant: keyof ButtonVariants
  children: ReactNode | ReactNodeArray
  negative?: boolean
}

export const Button = ({variant, negative, children, ...p}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <BaseButton variant={variant} className={negative ? 'negative' : undefined} {...p} type={p.type || 'button'}>
      {children}
    </BaseButton>
  )
}

export const FormSubmitButton = ({
  variant,
  negative,
  children,
  ...p
}: ButtonProps & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <BaseButtonInput
      type="submit"
      variant={variant}
      value={children as string}
      className={negative ? 'negative' : undefined}
      {...p}
    />
  )
}

Button.defaultProps = {
  variant: 'default',
  negative: false
}
FormSubmitButton.defaultProps = {
  variant: 'default',
  negative: false
}

const TextButtonButton = styled.button<TextButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>`
  display: block;
  font-size: 0.9rem;
  font-weight: ${props => (props.weight ? props.weight : 500)};
  line-height: 1.5rem;
  color: ${props => (props.negative ? colors.system.white : colors.brand.cobalt)};
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: ${props =>
      props.negative
        ? tiny(colors.system.white)
            .darken(10)
            .toHexString()
        : colors.system.grey_50};
  &:active {
    color: ${props =>
      props.negative
        ? tiny(colors.system.white)
            .darken(20)
            .toHexString()
        : tiny(colors.system.grey_50)
            .lighten(10)
            .toHexString()};
    transform: translateY(1px);
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`
interface TextButtonProps {
  children: ReactNode | ReactNodeArray
  negative?: boolean
  weight?: number
}
export const TextButton = ({children, negative, ...p}: TextButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <TextButtonButton negative={negative} {...p}>
      {children}
    </TextButtonButton>
  )
}
interface StyledLinkProps {
  children: ReactNode | ReactNodeArray
  negative?: string
}

// for some reason this is required for jest testing to work. Go figure...
export const StyledLink = styled(p => <Link {...p} />)<StyledLinkProps & LinkProps>`
  display: block;
  color: ${props => (props.negative ? colors.system.white : colors.brand.cobalt)};
  background-color: transparent;
  border: none;

  padding: 0;
  cursor: pointer;
  text-decoration: none;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

const MoreButtonButton = styled.button`
  max-width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }
`
const MoreTextContainer = styled.div`
  width: 100%;
  display: inline-grid;
  grid-column-gap: 0.25rem;
  grid-template-columns: minmax(auto, auto) 1.5rem;
  justify-content: center;
  align-items: center;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    display: inline-block;
    line-height: 1em;
  }
`
interface MoreButtonProps {
  hideArrowIcon?: boolean
}
export const MoreButton = ({
  hideArrowIcon,
  children,
  ...p
}: MoreButtonProps & TextButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <MoreButtonButton {...p}>
      <MoreTextContainer>
        <H5>{children}</H5>
        {hideArrowIcon ? null : <IconArrowRight />}
      </MoreTextContainer>
    </MoreButtonButton>
  )
}

const TagButtonButton = styled.button`
  background-color: ${(props: BaseProps) => buttonVariants[props.variant]};
  border-radius: 24px;
  padding: 0.25rem 0.5rem;
  border: 1px solid ${props => buttonVariants[props.variant]};
  /*font-family: 'HelveticaNeue', 'Helvetica Neue', Helvetica, Helvetica, Arial, 'FreeSans', sans-serif;*/
  font-family: FavoritMonoStd-Regular, monospace;
  font-weight: 400;
  font-size: 0.75rem; /*12px;*/
  line-height: 1rem; /*16px;*/
  letter-spacing: 0.4px;
  text-align: center;
  width: fit-content;
  color: white;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }
  &.negative {
    border: solid 1px ${props => negative(buttonVariants[props.variant])};
  }
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`
export const TagButton = ({
  variant,
  negative,
  children,
  ...p
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <TagButtonButton variant={variant} className={negative ? 'negative' : undefined} {...p} type="button">
      {children}
    </TagButtonButton>
  )
}

export const CenterButtonRow = ({children}: {children: ReactNode}) => {
  return <CenterRowWithItems>{children}</CenterRowWithItems>
}

const CenterRowWithItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    margin: 0.5rem;
  }
`

export const LeftButtonRow = ({children}: {children: ReactNode}) => {
  return <LeftRowWithItems>{children}</LeftRowWithItems>
}

const LeftRowWithItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > * {
    margin: 0.5rem;
  }
`

export const RightButtonRow = ({children}: {children: ReactNode}) => {
  return <RightRowWithItems>{children}</RightRowWithItems>
}

const RightRowWithItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin: 0.5rem;
  }
`

export const ButtonRowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`
export const ButtonRowWrap = styled.div`
  margin-top: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`
