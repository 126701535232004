import React, {useState} from 'react'
import CenteredModal, {LargeModalContent} from './Modals'
import {LabelCheckbox} from '../../Atoms/Forms'
import {Baseline, Row} from '../../Layout/Grid'
import {H1, H2, P} from '../../Atoms/Typography'
import {Button} from '../../Atoms/Buttons'

interface ConfirmModalProps {
  actions: [{action: () => void; label: string}, {action: () => void; label: string}]
  confirm?: string
  heading: string
  subHeading?: string
  content: string
  isOpen: boolean
  isLoading?: boolean
  closeModal?: () => void
}
const ConfirmModal = ({
  actions,
  confirm,
  heading,
  subHeading,
  content,
  isOpen,
  isLoading,
  closeModal
}: ConfirmModalProps) => {
  const [valid, setValid] = useState(confirm ? false : true)
  const closeParentModalWhenClickedBackground = () => {
    closeModal?.()
    setValid(confirm ? false : true)
  }
  return (
    <CenteredModal isOpen={isOpen} closeParentModal={closeParentModalWhenClickedBackground}>
      <LargeModalContent data-cy="confirm-modal">
        <Baseline margin="2rem">
          <H1>{heading}</H1>
          {subHeading && <H2>{subHeading}</H2>}
          <P>{content}</P>
          {confirm && (
            <div>
              <LabelCheckbox
                flipped={false}
                checked={valid}
                onClick={() => setValid(!valid)}
                label={confirm}
                name="confirmModal"
              />
            </div>
          )}
          <Row justify="flex-end" childMargin="1rem">
            <Button
              variant="secondary"
              negative
              onClick={() => {
                actions[0].action()
                setValid(false)
              }}
            >
              {actions[0].label}
            </Button>
            <Button
              disabled={!valid || isLoading}
              variant="secondary"
              onClick={() => {
                actions[1].action()
                setValid(false)
              }}
            >
              {actions[1].label}
            </Button>
          </Row>
        </Baseline>
      </LargeModalContent>
    </CenteredModal>
  )
}

export default ConfirmModal
