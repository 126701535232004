import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {ReactNodeArray} from 'prop-types'
import {CircularProgressbarWithChildren} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {colors} from '../../sharedComponents/colors'

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const ProgressCircle = styled(CircularProgressbarWithChildren)``

interface GradientProps {
  startColor: string
  startPercent: number
  endColor: string
  endPercent: number
  idCSS: string
  rotation: number
}
const ProgressGradient = ({
  startColor,
  startPercent: startColorPercent,
  endColor,
  endPercent: endColorPercent,
  idCSS,
  rotation
}: GradientProps) => {
  let gradientTransform = `rotate(${rotation})`
  return (
    <svg style={{height: 0, width: 0, position: 'absolute'}}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset={`${startColorPercent}%`} stopColor={startColor} />
          <stop offset={`${endColorPercent}%`} stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
ProgressGradient.defaultProps = {
  startColor: colors.brand.signalOrange,
  startPercent: 20,
  endColor: colors.brand.citrone,
  endPercent: 93,
  rotation: -30,
  strokeWidth: 4
}

interface ProgressBarProps {
  percentage: number
  strokeWidth?: number
  pathColor?: string
  pathColorStart?: string
  pathColorEnd?: string
  trailColor?: string
  children: ReactNode | ReactNodeArray
}

export const ProgressBar = ({percentage, children, pathColor, trailColor, strokeWidth}: ProgressBarProps) => {
  return (
    <ProgressBarWrapper>
      <ProgressCircle
        value={percentage}
        strokeWidth={strokeWidth ? strokeWidth : 8}
        styles={{
          path: {stroke: pathColor, height: '100%', width: '100%', strokeLinecap: 'butt'},
          trail: {stroke: trailColor, strokeLinecap: 'butt'}
        }}
      >
        {children}
      </ProgressCircle>
    </ProgressBarWrapper>
  )
}
ProgressBar.defaultProps = {
  pathColor: colors.brand.brightRed,
  trailColor: colors.system.lightGrey_10,
  strokeWidth: 4
}
