import React from 'react'
import styled from 'styled-components'
import {Button, ButtonProps} from '../../atoms/Button'
import {Text} from '../../atoms/Text'
import {Title} from '../../atoms/Title'
import {colors} from '../../../colors'

interface FilterCardProps extends ButtonProps {
  count?: number
  icon?: React.ReactNode
  title?: string
  themeColor?: string
  selected?: boolean
}

export const FilterCard: React.FC<FilterCardProps> = ({title, count, icon, themeColor, selected, ...rest}) => {
  return (
    <FilterCardContainer selected={selected} themeColor={themeColor} {...rest}>
      <ThemeLine themeColor={themeColor} selected={selected} />
      <Filter>
        <FilterInfo>
          <Title level={3} strong>
            {count}
          </Title>
          <Text size="M" strong={selected} style={{textAlign: 'left'}} light={!selected}>
            {title}
          </Text>
        </FilterInfo>
        {icon}
      </Filter>
    </FilterCardContainer>
  )
}

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

const FilterCardContainer = styled(Button)<FilterCardProps>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  display: flex;
  flex-direction: column;

  border-radius: 0.5rem;
  border: ${props => (props.selected ? `2px solid ${props.themeColor}` : `2px solid ${colors.system.grey_4}`)};
  padding: 0;
  background-color: ${props => (props.selected ? colors.system.white : 'transparent')};
  color: ${colors.brand.cobalt};

  :disabled {
    color: ${colors.brand.cobalt};
    background-color: transparent;
  }

  :hover {
    background-color: transparent;
    font-family: FavoritStd-Bold, sans-serif;
    font-weight: 700;
    border: 2px solid ${props => (!props.selected ? props.themeColor : colors.system.grey_4)};

    div:first-child {
      background-color: transparent;
    }
  }

  :focus {
    outline: none;
    box-shadow: none;
    border: ${props => (props.selected ? '2px' : '2px')} solid
      ${props => (props.selected ? props.themeColor : colors.system.grey_4)};
    background-color: ${props => (props.selected ? colors.system.white : 'transparent')};
  }
`

const FilterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  justify-content: flex-end;
`

const ThemeLine = styled.div<Pick<FilterCardProps, 'themeColor' | 'selected'>>`
  height: 6.4px;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: ${props => (!props.selected && props.themeColor) || 'default'};
`
