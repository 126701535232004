import React from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'
import {Text} from '../../atoms/Text'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string
    multiline?: boolean
    bottomArea?: React.ReactNode
  }

export const Input: React.FC<InputProps> = ({label, multiline, bottomArea, required, ...rest}) => {
  return (
    <InputContainer>
      {label && (
        <label htmlFor={rest.id}>
          <Text size="S">{label + (required ? ' *' : '')}</Text>
        </label>
      )}
      <InputWithTags>
        {multiline ? <InputMultiline {...rest} /> : <InputDefault type={rest.type ?? 'text'} {...rest} />}
        {bottomArea}
      </InputWithTags>
    </InputContainer>
  )
}

const InputWithTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  border: 1px solid ${colors.system.grey_5};
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;

  &:hover {
    border: 1px solid ${colors.system.blue};
  }

  &:disabled {
    border: 1px solid red;
  }
`

const InputContainer = styled.div`
  display: grid;
  grid-template: auto auto / auto;
  gap: 0.5rem;
`

const inputStyles = css`
  outline: none;
  border: none;

  color: ${colors.brand.cobalt};
  font-size: 0.875rem; /*14px;*/
  line-height: 1.1875rem; /*19px;*/
  letter-spacing: -0.15px;

  ::placeholder {
    color: ${colors.system.grey_50};
  }

  &:disabled {
    cursor: not-allowed;
    background: transparent;
  }
`

const InputDefault = styled.input`
  ${inputStyles}
`

const InputMultiline = styled.textarea`
  ${inputStyles}
`
