import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconAudit = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8087 3.2501C10.2128 3.2501 3.24509 10.2458 3.24509 18.8763C3.24509 27.5068 10.2128 34.5025 18.8087 34.5025C27.4046 34.5025 34.3723 27.5068 34.3723 18.8763C34.3723 10.2458 27.4046 3.2501 18.8087 3.2501ZM2 18.8763C2 9.55541 9.52514 2 18.8087 2C28.0923 2 35.6174 9.55541 35.6174 18.8763C35.6174 28.1972 28.0923 35.7526 18.8087 35.7526C9.52514 35.7526 2 28.1972 2 18.8763Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1969 23.9967L27.9623 12.184L28.8427 13.0679L16.197 25.7645L9.65332 19.1959L10.5336 18.3119L16.1969 23.9967Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2395 42.4634L32.8513 31.0293L33.7317 30.1454L46.0002 42.4632L42.4791 46.0001L30.2104 33.682L31.0909 32.7981L42.4789 44.2319L44.2395 42.4634Z"
      />
    </svg>
  )
}
