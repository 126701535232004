import React from 'react'
import styled from 'styled-components'
import {Baseline} from '../../Layout/Grid'
import InfoCard from '../../Molecules/Cards/InfoCard'
import AlarmsInfo from '../../Organisms/Info/AlarmsInfo'
import LateTasksInfo from '../../Organisms/Info/LateTasksInfo'
import {CardList} from '../../Layout/Card'
import {MoreButton} from '../../Atoms/Buttons'
import {SiteItem, LocationItem} from '../../../state/home/allSites/state'
import SquareInfoCard from '../../Molecules/Cards/SquareInfoCard'

const SiteStatusContainer = styled.div`
  max-width: 21.5rem;
`

interface SiteStatusProps {
  isSoloLocation?: boolean
  site: SiteItem
  location: LocationItem
  onClick?: (site: SiteItem) => void
  onlyAlarm?: boolean
}

const SiteStatus = ({location, isSoloLocation, site, onClick, onlyAlarm}: SiteStatusProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick(site)
    }
  }

  return (
    <SiteStatusContainer>
      <Baseline>
        <MoreButton onClick={handleClick}>
          <>{isSoloLocation ? site.name : `${location.name} - ${site.name}`}</>
        </MoreButton>
        <CardList onClick={handleClick}>
          {onlyAlarm ? (
            <SquareInfoCard>
              <AlarmsInfo onClick={handleClick} alarms={site.activeAlarms} variant="quick"></AlarmsInfo>
            </SquareInfoCard>
          ) : (
            <InfoCard size={2}>
              <AlarmsInfo onClick={handleClick} alarms={site.activeAlarms} variant="quick"></AlarmsInfo>
              <LateTasksInfo tasks={site.lateTasks} variant={'quick'} />
            </InfoCard>
          )}
        </CardList>
      </Baseline>
    </SiteStatusContainer>
  )
}

export default SiteStatus
