import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconCooling = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.2983 25.729V2H24.7009V25.729H23.2983Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6104 6.54471L27.87 3.92542L28.6478 5.0023L24.3882 7.6216L23.6104 6.54471Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6226 12.944L30.9959 8.34473L31.7814 9.41683L24.4081 14.0161L23.6226 12.944Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6108 7.62149L19.3525 5.00219L20.1305 3.92542L24.3888 6.54471L23.6108 7.62149Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5906 14.0161L16.2173 9.41683L17.0028 8.34473L24.3762 12.944L23.5906 14.0161Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.7009 22.271V46H23.2983V22.271H24.7009Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3888 41.4547L20.1305 44.074L19.3525 42.9972L23.6108 40.3779L24.3888 41.4547Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3762 35.0561L17.0028 39.6554L16.2173 38.5833L23.5906 33.984L24.3762 35.0561Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3882 40.3779L28.6478 42.9972L27.87 44.0741L23.6104 41.4548L24.3882 40.3779Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4081 33.984L31.7814 38.5833L30.9959 39.6554L23.6226 35.0561L24.4081 33.984Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2729 25.4249L3 13.5604L3.70127 12.4397L25.9741 24.3042L25.2729 25.4249Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4214 15.5836L7.0918 10.8704L8.49146 10.7871L8.82107 15.5003L7.4214 15.5836Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.434 18.7732L12.8042 10.5814L14.2033 10.4899L14.833 18.6816L13.434 18.7732Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43258 16.1211L3.84476 18.215L3.2229 17.0551L7.81072 14.9612L8.43258 16.1211Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4236 19.3358L6.42055 22.927L5.80713 21.7632L13.8102 18.172L14.4236 19.3358Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7271 22.5752L45 34.4397L44.2988 35.5604L22.0259 23.6959L22.7271 22.5752Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5769 32.4158L40.9065 37.129L39.5069 37.2123L39.1772 32.4991L40.5769 32.4158Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5651 29.2269L35.1948 37.4187L33.7958 37.5103L33.166 29.3185L34.5651 29.2269Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5669 31.8776L44.1547 29.785L44.7763 30.9451L40.1884 33.0377L39.5669 31.8776Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5752 28.6642L41.5783 25.073L42.1917 26.2368L34.1886 29.828L33.5752 28.6642Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9741 23.6959L3.70127 35.5604L3 34.4397L25.2729 22.5752L25.9741 23.6959Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81072 33.0377L3.2229 30.9451L3.84446 29.785L8.43228 31.8776L7.81072 33.0377Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8102 29.828L5.80713 26.2368L6.42055 25.073L14.4236 28.6642L13.8102 29.828Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82107 32.4991L8.49146 37.2123L7.0918 37.129L7.4214 32.4158L8.82107 32.4991Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.833 29.3185L14.2033 37.5103L12.8042 37.4187L13.434 29.2269L14.833 29.3185Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0259 24.3042L44.2988 12.4397L45 13.5604L22.7271 25.4249L22.0259 24.3042Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1888 14.9612L44.7766 17.0551L44.1547 18.215L39.5669 16.1211L40.1888 14.9612Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1886 18.172L42.1917 21.7632L41.5783 22.927L33.5752 19.3358L34.1886 18.172Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1772 15.5003L39.5069 10.7871L40.9065 10.8704L40.5769 15.5836L39.1772 15.5003Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.166 18.6816L33.7958 10.4899L35.1948 10.5814L34.5651 18.7732L33.166 18.6816Z"
      />
    </svg>
  )
}
