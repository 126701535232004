import React from 'react'
import {IconCloseSmall, IconGallery} from '../../Assets/Icons/TinyIcons'
import {IconButton} from '../Atoms/Buttons'
import {useFileUpload} from '../../config/utils'
import {AssetData} from '../../state/rest'
import {TruncatedText} from '../Atoms/Typography'
import {Row} from '../Layout/Grid'
import {useTranslation} from 'react-i18next'

interface UploadProps {
  onAssetSaved: (asset?: AssetData) => void
  savedAsset?: AssetData
}

const AssetUploaderSmall = ({onAssetSaved, savedAsset}: UploadProps) => {
  // TODO! Accepts only images for now
  const openFileSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const el = document.getElementById('fileInput')!
    el.click()
  }
  const {t} = useTranslation('assets')
  const {loading, fileRef, handleUploadClick} = useFileUpload({savedAsset, cb: onAssetSaved})
  const description = loading ? (
    t('assets:labels.uploading', 'Uploading...')
  ) : !savedAsset ? (
    t('assets:labels.uploadImage', 'Upload image')
  ) : (
    <TruncatedText>{savedAsset.name}</TruncatedText>
  )

  return (
    <>
      {savedAsset ? (
        <Row childMargin="1rem" align="center">
          <IconButton
            aria-label="upload photo"
            buttonProps={{onClick: e => openFileSelect(e)}}
            icon={<IconGallery />}
            description={description}
          />
          <IconButton icon={<IconCloseSmall />} buttonProps={{onClick: () => onAssetSaved(undefined)}} />
        </Row>
      ) : (
        <Row>
          <IconButton
            aria-label="upload photo"
            buttonProps={{onClick: e => openFileSelect(e)}}
            icon={<IconGallery />}
            description={description}
          />
        </Row>
      )}
      <input
        onChange={handleUploadClick}
        style={{display: 'none'}}
        id="fileInput"
        type="file"
        name="fileInput"
        accept="image/*"
        ref={fileRef}
      />
    </>
  )
}

export default AssetUploaderSmall
