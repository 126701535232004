import React, {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'

export interface TabsProps {
  children: (boolean | null | undefined | React.ReactElement<TabPaneProps>)[]
  defaultActiveKey?: string
  activeKey?: string
  tabBarExtraContent?: {left?: React.ReactNode; right?: React.ReactNode} // Extra content in tab bar
  onChangeKey?: (activeKey: string) => void //Callback executed when active tab is changed
}

export interface TabPaneProps {
  tab: React.ReactNode // Show text in TabPane's head
  tabKey: string // TabPane's key
  invisible?: boolean
  children?: React.ReactNode
}

const TabsContainer = styled.div<Pick<TabsProps, 'tabBarExtraContent'>>`
  width: 100%;

  header {
    display: flex;
    border-bottom: 1px solid ${colors.system.grey_5};
    justify-content: ${props => (props.tabBarExtraContent ? 'normal' : 'center')};
    align-items: center;
  }

  .tab {
    list-style: none;
    width: ${props => (props.tabBarExtraContent ? 'max-content' : '100%')};

    :hover {
      cursor: pointer;
    }
  }

  .extraContent {
    padding: 0.5rem;
    display: contents;
    border-bottom: 1px solid ${colors.system.grey_5};
  }

  .extraContent.right {
    text-align: right;
  }

  ul.tabs {
    display: flex;
    flex: auto;
    ${props =>
      !props.tabBarExtraContent &&
      css`
        width: 100%;
      `};
  }

  ul.tabs li a {
    text-decoration: none;
    height: 100%;
    display: block;
    text-align: center;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }

  section[aria-hidden='true'] {
    display: none;
  }
  ul.tabs li a[aria-selected='true'] {
    border-bottom: 2px solid ${colors.brand.cobalt};

    * {
      // may not the best way to override child font family
      font-family: FavoritStd-Bold !important;
    }
  }
  ul.tabs li a:focus {
    outline: none;
  }
`

export const Tabs: React.FC<TabsProps> = ({
  children,
  defaultActiveKey,
  activeKey,
  tabBarExtraContent,
  onChangeKey,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState(activeKey || defaultActiveKey)

  useEffect(() => {
    if (activeKey) {
      setActiveTab(activeKey)
    }
  }, [activeKey])
  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <TabsContainer tabBarExtraContent={tabBarExtraContent} {...rest}>
      <header>
        {tabBarExtraContent?.left && <div className="extraContent left">{tabBarExtraContent.left}</div>}
        <ul className="tabs">
          {children.map(
            child =>
              typeof child !== 'boolean' &&
              child?.props &&
              !child.props.invisible && (
                <li className="tab" role="presentation" key={child.props.tabKey}>
                  <a
                    aria-selected={activeTab === child.props.tabKey}
                    onClick={() => {
                      setActiveTab(child.props.tabKey)
                      onChangeKey?.(child.props.tabKey)
                    }}
                    data-cy={`tab-${child.props.tabKey}`}
                  >
                    {child.props.tab}
                  </a>
                </li>
              )
          )}
        </ul>
        {tabBarExtraContent?.right && <div className="extraContent right">{tabBarExtraContent.right}</div>}
      </header>
      <section role="tabpanel">
        {children.find(child => typeof child !== 'boolean' && child?.props.tabKey === activeTab)}
      </section>
    </TabsContainer>
  )
}

export const TabPane: React.FC<TabPaneProps> = ({tab: _tab, tabKey, ...rest}) => {
  return <div key={tabKey} {...rest} />
}
