import React from 'react'
import {useNavigation} from 'react-navi'
import {useAppState} from '../../../state'
import {Message} from '../../../state/messages/state'
import {A11yListContainer, ListItem} from '../../../Components/Atoms/Lists'
import TripletComponent from '../../../Components/Molecules/ListComponents/TripletComponent'
import {getHumanizedDateString} from '../../../Components/Atoms/Utils'

const MessageSettingsList = () => {
  const {state} = useAppState()
  const messages = state.v1.messages.messages

  return (
    <A11yListContainer>
      {messages.length
        ? messages.map(message => {
            return <MessageListItem key={message.id} message={message} />
          })
        : null}
    </A11yListContainer>
  )
}

interface MessageListItemProps {
  message: Message
}

const MessageListItem = ({message}: MessageListItemProps) => {
  const nav = useNavigation()
  const {state} = useAppState()
  const locale = state.site?.locale || 'fi-FI'

  return (
    <ListItem
      onClick={() => {
        nav.navigate(`/settings/messages/${message.id}`)
      }}
    >
      <TripletComponent
        title={message.heading}
        captions={[
          getHumanizedDateString(new Date(message.startDate), locale),
          getHumanizedDateString(new Date(message.endDate), locale)
        ]}
      />
    </ListItem>
  )
}

export default MessageSettingsList
