import React from 'react'
import {useTranslation} from 'react-i18next'
import {LabelCheckbox, Label} from '../../../Atoms/Forms'
import styled from 'styled-components'
import {AdjustableContainer} from '../../../Layout/Layout'
import {getAlarmMethodName} from './AlarmSettingsUtils'

const AlarmMethodsWrapper = styled.div`
  margin: 3rem 0 1rem 0;
`

interface AlarmMethodsProps {
  controller: any
}

const AlarmMethodWidget = ({controller: amc}: AlarmMethodsProps) => {
  const {t} = useTranslation('alarms')

  return amc.methodOptions && amc.methodOptions.length ? (
    <AlarmMethodsWrapper>
      <Label required htmlFor={'method-select'}>
        {t('alarms:labels.messageType', 'Message type')}
      </Label>
      <AdjustableContainer id={'method-select'} padding="1rem 0rem">
        {amc.methodOptions.map((method: string) => (
          <div key={method}>
            <LabelCheckbox
              // disable push notification option until it's available
              // disabled={method === AlarmMethod.PUSH}
              flipped={false}
              checked={amc.selected.includes(method)}
              label={getAlarmMethodName(method)}
              name={method}
              onClick={() => amc.selectAlarmMethod(method)}
            />
          </div>
        ))}
      </AdjustableContainer>
    </AlarmMethodsWrapper>
  ) : null
}

export default AlarmMethodWidget
