import React from 'react'
import {colors} from '../../../../colors'
import {SharedIconProps} from '../Icon'

export const IconArrowheadRightSmall = (props: SharedIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props} fill="none">
      <path d="M7 2L17 12L7 22" stroke={props.fill || colors.brand.cobalt} />
    </svg>
  )
}
