import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconGateway = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 11.6832C40.3755 5.70543 32.5974 2 24 2C15.401 2 7.62278 5.7054 2 11.6833L2.89641 12.5372C8.30324 6.78895 15.7643 3.24221 24 3.24221C32.234 3.24221 39.6951 6.78892 45.1037 12.5373L46 11.6832ZM24 10.1335C29.9312 10.1335 35.3166 12.2582 39.2241 15.7102L38.4098 16.6438C34.7337 13.3961 29.6398 11.3758 24 11.3758C18.3604 11.3758 13.2685 13.3961 9.59018 16.6439L8.77613 15.7101C12.6855 12.2583 18.0688 10.1335 24 10.1335ZM24 18.2671C26.7931 18.2671 29.3275 19.3682 31.1637 21.1526L30.3063 22.0462C28.6954 20.4807 26.4665 19.5093 24 19.5093C21.5337 19.5093 19.3056 20.4806 17.6937 22.0463L16.8365 21.1525C18.6735 19.3683 21.2069 18.2671 24 18.2671ZM19.8464 32.4441C19.8464 34.7523 21.7048 36.6242 23.9993 36.6242C26.2942 36.6242 28.1537 34.7518 28.1537 32.4441C28.1537 30.1351 26.2944 28.264 23.9993 28.264C21.7046 28.264 19.8464 30.1346 19.8464 32.4441ZM18.6121 32.4441C18.6121 35.4378 21.0226 37.8665 23.9993 37.8665L23.305 37.8665L23.305 46L24.5393 46L24.5393 37.8665L23.9993 37.8665C26.9759 37.8665 29.388 35.4378 29.388 32.4441C29.388 29.4488 26.9759 27.0217 23.9993 27.0217C21.0226 27.0217 18.6121 29.4488 18.6121 32.4441Z"
      />
    </svg>
  )
}
