import {UmbrellaCategory} from '../../../state/home/site/state'

export interface TaskGroup {
  id: string
  name: string
  icon: string
  umbrellaCategory: UmbrellaCategory
  fixed: boolean
  removable: boolean
}

interface TaskGroupSettings {
  data: TaskGroup[]
  pending: boolean
  error: Error | null
}

export const state: TaskGroupSettings = {
  data: [],
  pending: false,
  error: null
}
