import React, {useEffect, useState} from 'react'

import {Loader as PageLoader} from '../../Routes'
import {NoAppliancePlaceHolder} from '../appliances/appliances'
import {useAppState} from '../../state'
import styled from 'styled-components'
import {colors} from '../../sharedComponents/colors'
import QuickStatus from '../../Components/Views/QuickStatus/QuickStatus'
import TodoTasksList from '../../Components/Views/Info/TodoTasksList'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'
import {ErrorMessage} from '../../Components/Molecules/Errors/ErrorMessage'
import {AppliancesList, getOrderedAppliances} from './AppliancesList'

const INFOSCREEN_DATA_POLLINTERVAL_MS = 15000
const INFOSCREEN_TASKS_ALARMS_POLLINTERVAL_MS = 30000

export const InfoViewOverviewPage = () => {
  const {t} = useTranslation('appliances')
  const {state, actions} = useAppState()
  const [scale, setScale] = useState(1)

  useEffect(() => {
    // 1016px is the default best height for benchmarking
    setScale(window.innerHeight / 1016)
  }, [])

  const siteId = state.site?.id
  const appliances = state.v1.appliances.appliances.data.appliances
  const appliancesError = state.v1.appliances.appliances.error
  const {error} = state.v1.home.site

  useEffect(() => {
    const fetchData = async () => {
      if (!siteId) {
        return
      }
      // TODO: create a new endpoint for info screen
      await actions.v1.home.site.getHomePageForSite({siteId, shouldUpdatePending: true}) // this endpoint is missing completed tasks count
      await actions.v1.home.site.getInitialHomeData({siteId, onlyRefreshData: true}) // this endpoint is missing active alarms count
    }

    fetchData()
    const intervalId = window.setInterval(() => {
      fetchData()
    }, INFOSCREEN_TASKS_ALARMS_POLLINTERVAL_MS)
    return () => {
      window.clearInterval(intervalId)
    }
  }, [actions.v1.appliances, siteId])
  useEffect(() => {
    const fetchData = async () => {
      if (!siteId) {
        return
      }
      await actions.v1.appliances.getAppliancesForSite({siteId})

      const fetchedAppliances = state.v1.appliances.appliances.data.appliances
      if (!fetchedAppliances) {
        return
      }
    }

    fetchData()
    const intervalId = window.setInterval(() => {
      fetchData()
    }, INFOSCREEN_DATA_POLLINTERVAL_MS)
    return () => {
      window.clearInterval(intervalId)
    }
  }, [actions.v1.appliances, siteId])

  useEffect(() => {
    if (state.appUpdated) {
      console.info('detected state.appUpdated, reload in 10 seconds...')
      setTimeout(() => {
        window.location.reload()
      }, 10 * 1000)
    }
  }, [state.appUpdated])

  const showAppliances = () => {
    if (!appliances) {
      return <PageLoader show={!appliances} />
    } else if (!appliances && appliancesError) {
      return <ErrorMessage message={appliancesError.message} />
    } else if (appliances.length === 0) {
      return <NoAppliancePlaceHolder />
    } else {
      const activeAppliances = getOrderedAppliances(appliances)
      return (
        <ListLayout scale={scale} style={{justifyContent: 'center'}}>
          <AppliancesList
            appliances={activeAppliances}
            scale={scale}
            rows={2}
            width={window.innerWidth * 0.67}
            paginationPosition="bottom"
          />
        </ListLayout>
      )
    }
  }

  if (error) {
    return <ErrorMessage message={error.message} />
  } else if (siteId === undefined) {
    return <ErrorMessage message="Missing site id" />
  } else {
    return (
      <InfoMainLayout>
        <Helmet title={t('common:routes.infoScreen', 'Info Screen')} />
        <InfoStatus>
          <QuickStatus scale={scale} />
        </InfoStatus>
        <InfoTasks>
          <TodoTasksList scale={scale} />
          <InfoTasksMore />
        </InfoTasks>
        <InfoAppliances>{showAppliances()}</InfoAppliances>
      </InfoMainLayout>
    )
  }
}

const InfoMainLayout = styled.section`
  display: grid;
  width: 100%;
  grid-template-rows: 32vh calc(100vh - 32vh - 10vh);
  grid-template-columns: calc(100vw - 33vw) 33vw;
  grid-template-areas:
    'status tasks'
    'appliances tasks';
`

export const InfoStatus = styled.div`
  background-color: ${colors.system.white};
  border-right: 1px solid ${colors.system.lightGrey_5};
  grid-area: status;
  position: relative;
`

export const InfoTasks = styled.div`
  background-color: ${colors.system.white};
  grid-area: tasks;
  overflow-y: hidden;
  position: relative;
`

export const InfoTasksMore = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 2rem 0;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`

export const InfoAppliances = styled.div`
  box-sizing: content-box;
  overflow: hidden;
  grid-area: appliances;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

interface PaginationIndicatorProps {
  activePage: number
}

export const PaginationIndicator = styled.div<PaginationIndicatorProps>`
  display: flex;
  justify-content: center;

  > * {
    margin-right: 0.5rem;
    height: 0.5rem;
    width: 6rem;
    border: 1px solid ${colors.system.grey_1};
    border-radius: 0.25rem;
    background-color: ${colors.system.grey_1};
  }

  span:nth-child(${props => props.activePage}) {
    background-color: ${colors.brand.cobalt};
    border: 1px solid ${colors.brand.cobalt};
  }
`

interface ListLayoutProp {
  scale: number
}

export const ListLayout = styled.div<ListLayoutProp>`
  height: 90vh;
  width: 100%;
  gap: 2rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  transform: ${props => `scale(${props.scale})`};
  transform-origin: 0 0;
  width: ${props => `${100 / props.scale}%`};
  height: ${props => `${100 / props.scale}%`};
`

export const ListLayoutAppliances = styled(ListLayout)`
  position: fixed;
`
