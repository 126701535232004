import React from 'react'
import styled from 'styled-components'
import {ListItem} from '../../Atoms/Lists'
import DoubleComponent from '../../Molecules/ListComponents/DoubleComponent'

const SettingsListItemWrapper = styled(ListItem)`
  min-height: 88px;
  margin-left: 3rem;
`

interface SettingsListItemProps {
  icon: React.ReactNode
  title: string
  description: string
  onClick?: (e: React.MouseEvent) => void
}

const SettingsListItem = ({icon, title, description, onClick}: SettingsListItemProps) => {
  return (
    <SettingsListItemWrapper onClick={onClick} icon={icon}>
      <DoubleComponent title={title} description={description} />
    </SettingsListItemWrapper>
  )
}

export default SettingsListItem
