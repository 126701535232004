import {SuspendedPeriod} from '../../state/settings/suspendedPeriods/state'

export const isSiteSuspended = (suspendedPeriods: SuspendedPeriod[], date: Date) => {
  for (const period of suspendedPeriods) {
    const start = new Date(period.startAt)
    const end = new Date(period.endAt)
    if (start <= date && end >= date) {
      return true
    }
  }
  return false
}
