import React, {ReactNode} from 'react'
import styled from 'styled-components'

import {colors} from '../../../sharedComponents/colors'
import {Baseline} from '../../Layout/Grid'
import {CGIBrownLogo} from '../../../Assets/Icons/BrandIcons'
import {H1, P} from '../../Atoms/Typography'
import {Button, LeftButtonRow} from '../../Atoms/Buttons'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'

interface ErrorProps {
  status?: number
}

const ErrorWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.system.lightGrey_5};
  padding-top: 3rem;
`

const ErrorContainer = styled.div`
  background-color: white;
  min-height: 80%;
  svg {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
`
const ErrorContent = styled.div`
  background-color: white;
  max-width: 400px;
  margin-left: 20%;
  ${H1} {
    font-size: 3rem;
    line-height: 1;
    margin: 0;
  }
  a {
    /*font-family: 'HelveticaNeue-Medium', 'Helvetica Neue Medium', Helvetica, Arial, 'FreeSans', sans-serif;*/
    font-family: FavoritMonoStd-Regular, monospace;
    font-weight: 500;
    color: ${colors.brand.signalOrange};
    font-size: 14px;
    letter-spacing: 0.1px;
    text-align: center;
    line-height: 25px;
  }
`
// TODO: make this component show other errors than 404
const Errors400 = ({status}: ErrorProps) => {
  const {t} = useTranslation()
  return (
    <ErrorWrapper>
      <Helmet title={t('common:routes.notFound', '404 Not found')} />
      <ErrorContainer>
        <ErrorContent>
          <CGIBrownLogo />
          <Baseline>
            <H1>{`404`}</H1>
            <H1>{`Page not found`}</H1>
            <P>{`Looks like this page does not exist. Please go back to the home page, or contact our support at `}</P>
            <P>
              <a href="mailto:servicedesk.cgiaromi.fi@cgi.com">servicedesk.cgiaromi.fi@cgi.com</a>
            </P>
            <LeftButtonRow>
              <Button variant="secondary" onClick={() => (window.location.href = '/')}>
                {`Go home`}
              </Button>
            </LeftButtonRow>
          </Baseline>
        </ErrorContent>
      </ErrorContainer>
    </ErrorWrapper>
  )
}

export const SystemDown = ({status}: ErrorProps) => {
  return (
    <ErrorWrapper>
      <ErrorContainer>
        <ErrorContent>
          <CGIBrownLogo width="13em" />
          <Baseline>
            <H1>{`Chefstein is under maintenance`}</H1>
            <P>{`Sorry for the inconvenience. Please try again later.`}</P>
          </Baseline>
        </ErrorContent>
      </ErrorContainer>
    </ErrorWrapper>
  )
}

interface ErrorPageProps {
  heading: string
  paragraph: string
  cta?: ReactNode
}
export const ErrorPage = ({heading, paragraph, cta}: ErrorPageProps) => {
  return (
    <ErrorWrapper>
      <ErrorContainer>
        <ErrorContent>
          <CGIBrownLogo width="13em" />
          <Baseline>
            <H1>{heading}</H1>
            <P>{paragraph}</P>
            {cta}
          </Baseline>
        </ErrorContent>
      </ErrorContainer>
    </ErrorWrapper>
  )
}

export default Errors400
