import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconFolderLinked = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63 2H2V2.63V45.371V46.001H2.63H45.371H46.001V45.371V7.65835V7.02835H45.371H19.2331L14.3893 2.18452L14.2048 2H13.9438H2.63ZM3.26 15.8282V3.26H13.6828L18.5267 8.10383L18.7112 8.28835H18.9721H44.741V15.8709L3.26 15.8282ZM3.26 17.0882V44.741H44.741V17.1309L3.26 17.0882ZM17.5278 30.1368C15.8418 30.1368 14.4594 31.5192 14.4594 33.2052C14.4594 34.8923 15.8416 36.2735 17.5278 36.2735H23.6926C25.3787 36.2735 26.7609 34.8923 26.7609 33.2052C26.7609 31.5192 25.3786 30.1368 23.6926 30.1368H22.8101V28.8768H23.6926C26.0745 28.8768 28.0209 30.8233 28.0209 33.2052C28.0209 35.5885 26.0743 37.5335 23.6926 37.5335H17.5278C15.146 37.5335 13.1994 35.5885 13.1994 33.2052C13.1994 30.8233 15.1459 28.8768 17.5278 28.8768H18.4103V30.1368H17.5278ZM21.2392 29.5072C21.2392 27.8212 22.6215 26.4388 24.3075 26.4388H30.4723C32.1583 26.4388 33.5407 27.8212 33.5407 29.5072C33.5407 31.1932 32.1583 32.5755 30.4723 32.5755H29.5898V33.8355H30.4723C32.8542 33.8355 34.8007 31.8891 34.8007 29.5072C34.8007 27.1253 32.8542 25.1788 30.4723 25.1788H24.3075C21.9256 25.1788 19.9792 27.1253 19.9792 29.5072C19.9792 31.8891 21.9256 33.8355 24.3075 33.8355H25.19V32.5755H24.3075C22.6215 32.5755 21.2392 31.1932 21.2392 29.5072Z"
      />
    </svg>
  )
}
