import React, {useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Baseline} from '../../../Layout/Grid'
import {Recipient} from '../../../../state/rest'
import RecipientModal from './RecipientModal'
import {LabelInput} from '../../../Atoms/Forms'

import {getRecipientsNames} from './AlarmSettingsUtils'

interface AlarmMethodsProps {
  controller: any
}

const AlarmRecipientsWidget = ({controller: uc}: AlarmMethodsProps) => {
  const {t} = useTranslation('alarms')
  const [isOpen, setIsOpen] = useState(false)

  const handleSave = (newRes: Recipient[], newExtRes: Recipient[]) => {
    setIsOpen(false)
    uc.handleSave(newRes, newExtRes)
  }

  return uc ? (
    <div>
      <Baseline>
        <LabelInput
          id="selected-recipients"
          name="recipients"
          type="text"
          readOnly
          value={getRecipientsNames([...uc.recipients, ...uc.extRecipients], 5)}
          labelText={t('alarms:labels.recipient', 'Who will receive alarm messages?')}
          onClick={() => setIsOpen(s => !s)}
        />
      </Baseline>
      <RecipientModal
        isOpen={isOpen}
        onSave={(newRes: Recipient[], newExtRes: Recipient[]) => handleSave(newRes, newExtRes)}
        onCancel={() => setIsOpen(false)}
        recipients={uc.recipients}
        extRecipients={uc.extRecipients}
      />
    </div>
  ) : null
}

export default AlarmRecipientsWidget
