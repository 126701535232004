import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {Button, IconButton} from '../../../Atoms/Buttons'
import {IconClose} from '../../../../Assets/Icons/TinyIcons'
import {Row, Grid} from '../../../Layout/Grid'
import {useForm} from 'react-hook-form'
import {Heading} from '../../../Atoms/Typography'
import {LabelInput} from '../../../Atoms/Forms'
import LanguageSelect, {useLanguageSelect} from '../../../Organisms/Language/LanguageSelect'
import {valueExists} from '../../../../config/utils'

import {Recipient} from '../../../../state/rest'

interface FormFields {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  language?: string
}

interface FormProps {
  onCancel: () => void
  onAdd: (recipient: Recipient) => void
}

const AddRecipientForm = ({onCancel, onAdd}: FormProps) => {
  const {t} = useTranslation('alarms')

  const fc = useForm<FormFields>({
    mode: 'onChange'
  })

  const lc = useLanguageSelect()
  const {email, phoneNumber} = fc.watch()

  const handleAdd = useCallback(() => {
    const form = fc.watch()
    if (onAdd) {
      onAdd({
        firstName: form.firstName!,
        lastName: form.lastName!,
        email: form.email!,
        phoneNumber: form.phoneNumber!.replace(/ /g, ''),
        language: lc.selectedLanguage
      } as Recipient)
    }
  }, [onAdd, lc.selectedLanguage])

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  useEffect(() => {
    // trigger validation for the other field to clear error message
    if (email) {
      fc.triggerValidation(phoneNumber)
    } else if (phoneNumber) {
      fc.triggerValidation(email)
    }
  }, [email, phoneNumber])

  return (
    <>
      <Row justify={'space-between'} align={'center'} margin={'1rem 0'}>
        <Heading level={5}>{t('alarms:labels.newRecipient', 'New recipient')}</Heading>
        <IconButton buttonProps={{onClick: () => handleCancel()}} icon={<IconClose />} />
      </Row>
      <Grid gap={'1rem'} vgap={'1rem'}>
        <LabelInput
          required
          labelText={t('common:general.firstName', 'First name')}
          placeholder={t('common:general.firstName', 'First name')}
          name="firstName"
          id="firstName"
          ref={fc.register({required: true})}
          errorMsg={
            !!fc.errors.firstName ? t('common:validation.firstNameMissing', 'First name is mandatory') : undefined
          }
        />
        <LabelInput
          required
          labelText={t('common:general.lastName', 'Last name')}
          placeholder={t('common:general.lastName', 'Last name')}
          name="lastName"
          id="lastName"
          ref={fc.register({required: true})}
          errorMsg={!!fc.errors.lastName ? t('common:validation.lastNameMissing', 'Last name is mandatory') : undefined}
        />
        <LabelInput
          type="email"
          labelText={t('common:general.email', 'Email')}
          placeholder={t('common:general.email', 'Email')}
          name="email"
          id="email"
          ref={fc.register({
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
              message: `${t('common:validation.emailInvalid', 'Invalid email address')}`
            },
            validate: {
              phoneOrMail: value => {
                if (valueExists(value) || valueExists(phoneNumber)) {
                  return true
                } else {
                  return t(
                    'common:validation.emailPhoneMissing',
                    'Email address or phone number is mandatory'
                  ) as string
                }
              }
            }
          })}
          errorMsg={!!fc.errors.email ? fc.errors.email!.message : undefined}
        />
        <LabelInput
          placeholder={t('alarms:placeholders.phoneNumber', 'Phone number')}
          labelText={t('alarms:labels.phoneNumber', 'Phone number')}
          name="phoneNumber"
          id="phoneNumber"
          ref={fc.register({
            validate: {
              phoneOrMail: value => {
                if (valueExists(value) || valueExists(email)) {
                  return true
                } else {
                  return t(
                    'common:validation.emailPhoneMissing',
                    'Email address or phone number is mandatory'
                  ) as string
                }
              },
              isPhoneNumber: value => {
                let valueToTest = value.replace(/ /g, '')
                const regex = /^(\+){1}(\d){7,15}$/i
                if (!!!valueExists(valueToTest) || regex.test(valueToTest)) {
                  return true
                } else {
                  return `${t(
                    'validation.phoneFormat',
                    'Phone number must be in international format and start with + character'
                  )}`
                }
              }
            }
          })}
          errorMsg={!!fc.errors.phoneNumber ? fc.errors.phoneNumber!.message : undefined}
        />
        <LanguageSelect controller={lc} />
      </Grid>
      <Row justify={'flex-end'} align={'center'} margin={'1.5rem 0'}>
        <Button variant="secondary" negative disabled={!fc.formState.isValid} onClick={() => handleAdd()}>
          {t('alarms:actions.addRecipient', 'Add')}
        </Button>
      </Row>
    </>
  )
}

export default AddRecipientForm
