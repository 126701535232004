import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import {InvisibleContainer} from '../../Layout/Grid'
import {Flex} from '../../Layout/Grid'
import {Heading} from '../../Atoms/Typography'
import SiteStatus from '../QuickStatus/SiteStatus'
import {OrgLevelType} from '../../../state/state'
import {useAppState} from '../../../state'
import {SiteItem, AllSitesFilters} from '../../../state/home/allSites/state'
import {Loader} from '../../../Routes'
import {colors} from '../../../sharedComponents/colors'
import {useTranslation} from 'react-i18next'
import {TabPane, Tabs, Text} from '../../../sharedComponents/components'

const LocationGroupContainer = styled.div`
  > * {
    margin-bottom: 0.5rem;
  }
`
interface AllSitesViewProps {
  onlyAlarm?: boolean
}

const AllSitesView = ({onlyAlarm}: AllSitesViewProps) => {
  const {state, actions} = useAppState()
  const {t} = useTranslation(['home', 'locations'])

  useEffect(() => {
    actions.v1.home.allSites.getAllSitesOverview()
  }, [actions.v1.home])

  const handleClick = (site: SiteItem) => {
    actions.updateOrgLevel({id: site.id, name: site.name, type: OrgLevelType.SITE})
  }

  const [selectedTabKey, setSelectedTabKey] = useState<AllSitesFilters>(AllSitesFilters.NAME)
  const onTabChange = (tabKey: string) => {
    const key = tabKey as AllSitesFilters
    setSelectedTabKey(key)
    actions.v1.home.allSites.sortAllSitesOverview({filter: key})
  }

  return (
    <>
      <InvisibleContainer>
        <FilterPane>
          <FilterHeading>
            <Heading level={3}>{t('home:labels.orderBy', 'Order by')}</Heading>
          </FilterHeading>
          <FilterPickerTabs activeKey={selectedTabKey} onChangeKey={onTabChange}>
            <TabPane
              tab={<Text size="S">{t('locations:labels.siteName', 'Site name')}</Text>}
              tabKey={AllSitesFilters.NAME}
            />
            <TabPane
              tab={<Text size="S">{t('home:labels.lateTasks', 'Late tasks')}</Text>}
              tabKey={AllSitesFilters.LATE_TASKS}
            />
            <TabPane
              tab={<Text size="S">{t('home:labels.alarmsTitle', 'Active alarms')}</Text>}
              tabKey={AllSitesFilters.ACTIVE_ALARMS}
            />
          </FilterPickerTabs>
        </FilterPane>
        <Loader show={state.v1.home.allSites.pending} />
        {state.v1.home.allSites.overview.map(locationGroup => (
          <LocationGroupContainer key={locationGroup.id}>
            {locationGroup.visible && <Heading level={3}>{locationGroup.name}</Heading>}
            {locationGroup.locations.map(location => (
              <Flex key={location.id} wrap="wrap" childMargin="0.5rem">
                {location.sites.map(site => (
                  <SiteStatus
                    key={site.id}
                    isSoloLocation={locationGroup.locations.length === 1}
                    location={location}
                    site={site}
                    onClick={handleClick}
                    onlyAlarm={onlyAlarm}
                  />
                ))}
              </Flex>
            ))}
          </LocationGroupContainer>
        ))}
      </InvisibleContainer>
    </>
  )
}

const FilterPickerTabs = styled(Tabs)`
  display: flex;
  flex: 1;
  flex-grow: 0;
  justify-content: flex-end;
  padding-right: 24px;
  header {
    border: 1px solid ${colors.system.grey_5};
    border-radius: 0.5rem;
    padding: 0 0.25rem;

    ul.tabs li.tab a {
        padding: 0.5rem 1rem;

        span {
          white-space: pre;
        }
      }
    }
  }
`
const FilterPane = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  text-align: center;
`
const FilterHeading = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
`

export default AllSitesView
