import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {Text} from '../../../Atoms/Typography'
import {colors} from '../../../../sharedComponents/colors'
import {LabelInput} from '../../../Atoms/Forms'
import {Row} from '../../../Layout/Grid'

const AlarmNotificationDelayWrapper = styled.div`
  > * {
    margin-bottom: 1rem;
  }
`

const AlarmNotificationDelay = ({controller: fc}: any) => {
  const {t} = useTranslation('alarms')

  return (
    <AlarmNotificationDelayWrapper>
      <Row childMargin="1rem" align="flex-end">
        <LabelInput
          id="delayInMin"
          name="delayInMin"
          type="number"
          labelText={t('alarms:setting.delay.labels', 'Message delay')}
          defaultValue={0}
          min={0}
          max={1440}
          ref={fc.register({
            min: 0,
            max: 1440
          })}
          errorMsg={
            fc.errors.delayInMin
              ? t('alarms:setting.delay.input.error', 'The delay should be between 0 and 1440')
              : undefined
          }
        />
        <Text level={2} color={colors.brand.cobalt}>
          {t('alarms:setting.delay.unit', 'min')}
        </Text>
      </Row>
      <Text level={3} color={colors.system.grey_1}>
        {t(
          'alarms:setting.delay.disclaimer',
          'This delay applies only to messages from storage and freezer appliance alarms.'
        )}
      </Text>
    </AlarmNotificationDelayWrapper>
  )
}

export default AlarmNotificationDelay
