import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconReceiving = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.809 5.45776H40.3143L43.1302 8.2334V30.4172L40.3143 33.1928H17.809L14.9932 30.4172V8.2334L17.809 5.45776ZM18.3373 6.71491L16.2685 8.75413V29.8965L18.3373 31.9357H39.7861L41.8548 29.8965V8.75413L39.7861 6.71491H18.3373Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.37682 3.25714H2.6377V2H9.65219V32.8H8.37682V3.25714Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.0003 39.7143H15.3916V38.4572H46.0003V39.7143Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.01449 33.4285C5.84412 33.4285 3.27536 35.9606 3.27536 39.0857C3.27536 42.2108 5.84412 44.7428 9.01449 44.7428C12.1837 44.7428 14.7536 42.2107 14.7536 39.0857C14.7536 35.9607 12.1837 33.4285 9.01449 33.4285ZM2 39.0857C2 35.2663 5.13976 32.1714 9.01449 32.1714C12.8879 32.1714 16.029 35.2662 16.029 39.0857C16.029 42.9051 12.8879 46 9.01449 46C5.13976 46 2 42.9051 2 39.0857Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.4238 10.2142V6.08569H29.6992V10.2142H28.4238Z" />
    </svg>
  )
}
