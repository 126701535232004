import {baseApi} from '../../rest'
import {TaskSettings} from './state'

interface TaskSettingsReponse {
  [siteName: string]: {
    [taskGroupName: string]: TaskSettingsRow[]
  }
}

interface TaskSettingsRow {
  id: string
  name: string
  icon: string
  sites: string[]
}

export const settingsApi = {
  ...baseApi,
  async getTaskSettings(): Promise<TaskSettings> {
    const request = await this.doRequest<TaskSettingsReponse>({
      path: '/v1/settings/tasks'
    })
    const response = await request.get()
    return (response as unknown) as TaskSettings
  }
}
