export interface AllSitesOverview {
  locationGroups: LocationGroupItem[]
}

export interface LocationGroupItem {
  id: string
  name: string
  visible: boolean
  locations: LocationItem[]
}

export interface LocationItem {
  id: string
  name: string
  visible: boolean
  sites: SiteItem[]
}

export interface SiteItem {
  id: string
  name: string
  activeAlarms: number
  lateTasks: number
}

export interface HomeState {
  overview: LocationGroupItem[]
  pending: boolean
  error: Error | null
}

export const state: HomeState = {
  overview: [],
  pending: false,
  error: null
}

export enum AllSitesFilters {
  NAME = 'name',
  LATE_TASKS = 'lateTasks',
  ACTIVE_ALARMS = 'activeAlarms'
}
