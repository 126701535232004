import React, {useState, useEffect} from 'react'
import {AssetData} from '../../state/rest'
import styled from 'styled-components'
import {IconProfile} from '../../Assets/Icons/LargeIcons'
import {colors} from '../../sharedComponents/colors'
import {useAppState} from '../../state'

const LargeProfileImgContainer = styled.div`
  height: 5rem;
  width: 5rem;
`
const RoundedImageContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
`
const PlaceholderImageContainer = styled(RoundedImageContainer)`
  background-color: ${colors.brand.cobalt};
  > svg {
    height: 75%;
    width: 75%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
const SmartImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const LargeProfileImageFromAsset = ({asset}: {asset: AssetData}) => {
  return (
    <LargeProfileImgContainer>
      <RoundedProfileImage asset={asset} />
    </LargeProfileImgContainer>
  )
}

export const RoundedProfileImage = ({asset}: {asset: AssetData}) => {
  if (asset && asset.assetMimeType && asset.assetMimeType.includes('image')) {
    return (
      <RoundedImageContainer>
        <SmartProfileImage id={asset.id} />
      </RoundedImageContainer>
    )
  }
  return <RoundedProfileImagePlaceholder />
}

const RoundedProfileImagePlaceholder = () => {
  return (
    <PlaceholderImageContainer>
      <IconProfile color={colors.system.white} />
    </PlaceholderImageContainer>
  )
}

interface SmartImageProps {
  id: string
}
export const SmartProfileImage = ({id}: SmartImageProps) => {
  const {actions} = useAppState()
  const [url, setUrl] = useState<string | null>(null)
  useEffect(() => {
    try {
      actions.getDownloadUrl(id).then(d => setUrl(d.downloadURL))
    } catch (e) {
      console.error(e)
    }
  }, [id, actions])

  return url ? <SmartImage src={url} alt="profile image" /> : <RoundedProfileImagePlaceholder />
}
