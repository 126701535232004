import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconManual = (props: SharedIconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8016 4.3782C37.4152 2.96532 35.1671 2.96532 33.7807 4.3782L30.7441 7.47031L40.6283 17.5352L43.6653 14.4427C45.0518 13.0308 45.0518 10.7413 43.6653 9.3295L38.8016 4.3782ZM8.87803 29.7362L29.8285 8.40266L39.7126 18.4676L18.7622 39.8012L14.5176 35.479L35.4512 14.1626L34.5356 13.2302L13.602 34.5466L8.87803 29.7362ZM4.72807 41.2626L3.6677 44.3624L6.86785 43.4424L4.72807 41.2626ZM5.2012 39.8796L8.21443 42.9491L8.06901 43.0971L17.5041 40.3848L8.25145 30.9629L5.2012 39.8796ZM39.7176 3.44622C37.8255 1.51799 34.7571 1.51793 32.8649 3.44604L7.54 29.234C7.47101 29.3043 7.4185 29.3895 7.38641 29.4833L2.03605 45.1238C1.95665 45.3559 2.01063 45.6138 2.17611 45.7927C2.34158 45.9716 2.59106 46.042 2.82335 45.9752L18.9728 41.3326C19.0794 41.302 19.1765 41.244 19.2547 41.1643L44.5809 15.375C46.473 13.4483 46.473 10.3239 44.5809 8.39715L39.7176 3.44622Z"
      />
    </svg>
  )
}
