import React from 'react'
import {SharedIconProps} from '../Icon'

export const IconCheckedbox = (props: SharedIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3V21H19.2675V12.9039H18.325V20.0184H2.94255V3.98253H18.325V7.63854L10.8411 15.4389L5.76078 10.143L5.09251 10.8406L10.8411 16.8252L22 5.19105L21.3308 4.50426L19.2675 6.656V3H2Z"
      />
    </svg>
  )
}
