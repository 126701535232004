import {differenceInHours} from 'date-fns'

// For sensor data, we only allow 30 days of data to be fetched at a time
const THIRTY_DAYS_IN_HOURS = 30 * 24

export const checkPeriodValidity = (startDate: Date, endDate: Date, sensorData?: boolean) => {
  if (startDate && endDate && startDate <= endDate) {
    if (sensorData) return differenceInHours(endDate, startDate) <= THIRTY_DAYS_IN_HOURS
    return true
  }
  return false
}
