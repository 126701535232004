import React, {useState, useCallback} from 'react'
import styled from 'styled-components'
import i18n from 'i18next'

import {Select} from '../../Atoms/Forms'
import {UserLanguage} from '../../../state/rest'
import {H5} from '../../Atoms/Typography'
import {CircularBadge} from '../../Atoms/Badge'

interface Props {
  controller: any
}
const LanguageSelect = ({controller}: Props): any => {
  const fc = controller
  return (
    <Select
      key={`language_select`}
      nativeProps={{
        name: 'language',
        onChange(e) {
          fc.selectLanguage(fc.languageOptions.find((o: any) => o.option === e.target.value)!.id)
        },
        value: fc.selectedLanguage.id
      }}
      id="language"
      options={fc.languageOptions}
    />
  )
}

const LoginLanguageContainer = styled.div`
  text-align: right;
  margin: 2rem;
`

export const LoginLanguageSelect = () => {
  const defaultLang = UserLanguage.en
  const [lang, setLang] = useState(defaultLang)
  const handleLoginLang = () => {
    if (lang === UserLanguage.en) {
      setLang(UserLanguage.fi)
      i18n.changeLanguage(UserLanguage.fi)
    } else if (lang === UserLanguage.fi) {
      setLang(UserLanguage.sv)
      i18n.changeLanguage(UserLanguage.sv)
    } else {
      setLang(UserLanguage.en)
      i18n.changeLanguage(UserLanguage.en)
    }
  }

  return (
    <LoginLanguageContainer>
      <CircularBadge fontSize="1rem" variant="reverse">
        <H5 style={{cursor: 'pointer', textTransform: 'uppercase'}} onClick={handleLoginLang}>
          {lang}
        </H5>
      </CircularBadge>
    </LoginLanguageContainer>
  )
}

export function useLanguageSelect(defaultOption?: string, saved?: string) {
  const languageOptions = Object.values(UserLanguage).map(id => ({option: getLanguageName(id), id: id}))
  const defaultIndex = Math.max(
    languageOptions.findIndex(df => df.id === defaultOption),
    0
  )
  const [selected, setSelected] = useState(saved ? saved : languageOptions[defaultIndex].id)

  const selectMethod = useCallback((id: string) => {
    setSelected(id)
  }, [])

  return {selectedLanguage: selected, selectLanguage: selectMethod, languageOptions}
}

function getLanguageName(languageKey: string) {
  switch (languageKey) {
    case UserLanguage.fi: {
      return i18n.t('common:general.finnish', 'Finnish')
    }
    case UserLanguage.sv: {
      return i18n.t('common:general.swedish', 'Swedish')
    }
    default: {
      return i18n.t('common:general.english', 'English')
    }
  }
}

export default LanguageSelect
