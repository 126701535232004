import qs from 'query-string'
import {baseApi} from '../rest'
import {TaskType} from '../../pages/tasks/index'

export interface FilterOptions {
  limit: number
  offset: number
  completed: boolean
  taskTypes?: string
  completedBy?: string
  taskGroups?: string[]
  from?: string
  to?: string
  media?: boolean
  succeededMeasurements?: boolean
  failedMeasurements?: boolean
}

export const tasksApi = {
  ...baseApi,
  async getTasksForSite(siteId: string, limit: number, limitLate: number): Promise<any> {
    const request = await this.doRequest<any>({
      path: `/v1/tasks/site/${siteId}?offset=0&limit=${limit}&limitLate=${limitLate}&offsetLate=0&completed=true`
    })
    const response = await request.get()
    return (response as unknown) as any
  },
  async getTasksForSiteWithPagination(siteId: string, options?: FilterOptions): Promise<any> {
    const qstring = options ? qs.stringify(options, {arrayFormat: 'comma'}) : undefined
    const path = qstring ? `/v1/tasks/site/${siteId}/paging?${qstring}` : `/v1/tasks/site/${siteId}/paging`
    const request = await this.doRequest<any>({path})
    const response = await request.get()
    return (response as unknown) as any
  },
  async getMoreLateOrTodayTasksForSite(siteId: string, options?: FilterOptions, type?: TaskType): Promise<any> {
    const qstring = options ? qs.stringify(options, {arrayFormat: 'comma'}) : undefined
    const taskType = type === TaskType.LATE ? 'late' : 'today'
    const path = qstring ? `/v1/tasks/site/${siteId}/${taskType}?${qstring}` : `/v1/tasks/site/${siteId}/${taskType}`
    const request = await this.doRequest<any>({path})
    const response = await request.get()
    return (response as unknown) as any
  },
  async getInProgressTasksForSite(siteId: string, offset: number, limit: number, taskTypes: string[]): Promise<any> {
    const request = await this.doRequest<any>({
      path: `/v1/tasks/site/${siteId}/inProgress?offset=${offset}&limit=${limit}&taskTypes=${taskTypes.join()}`
    })
    const response = await request.get()
    return (response as unknown) as any
  },
  async deleteTaskEvent(taskId: string, eventId: string, siteId: string): Promise<any> {
    const request = await this.doRequest<any>({
      path: `/v1/events/${eventId}?taskId=${taskId}`,
      site: siteId
    })
    const response = await request.delete()
    return (response as unknown) as any
  }
}
