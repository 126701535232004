import {baseApi} from '../rest'
import {TaskTemplate} from './state'

export const tasksFromTemplatesApi = {
  ...baseApi,
  async getTaskTemplates(siteIds: string, language: string) {
    const req = await this.doRequest<any>({
      path: `/v1/taskTemplates?siteIds=${siteIds}&language=${language}`
    })
    const resp = (await req.get()) as unknown
    return resp as TaskTemplate
  },
  async createTasksFromTemplate(payload: TaskTemplate) {
    const req = await this.doRequest<any>({
      path: `/v1/taskTemplates`,
      payload
    })
    const resp = await req.post()
    return resp
  }
}
